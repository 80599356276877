import React, {useEffect, useState, useRef} from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { FormattedMessage, useIntl } from "react-intl";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import Datetime from "react-datetime";
import moment from "moment";

import "react-datetime/css/react-datetime.css";
import { Margin } from "@mui/icons-material";


const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

const SinglePackageItemAdSetDetaille = ({
  data: {
    name,
    type,
    packagesPub,
    n_adsets,
    budget,
    targeting_packages,
    exclusion_audiences,
    exclusionInterests,
    start_time,
    end_time
  },
  keywords,
  excluded_audiences_packages,
  excluded_interests_packages,
  handleRemoveItem,
  index,
  setAlertMessage,

}) => {
  
  const budgetTimer = useRef(null);
  const [nAdsetsInput, setNAdsetsInput] = useState(n_adsets)
  const [budgetInput, setBudgetInput] = useState(budget)
  const [expanded, setExpanded] = useState(false)
  const dispatch = useDispatch();

  const {
    informationsGenerales: { dateDebut, dateFin },
  } = useSelector((state) => state.creationCampagne);

  const excAudiCent = [...exclusion_audiences, ...exclusionInterests];
  const intl = useIntl();

  const changeNbrAdset = (e, i) => {
    //this should be an error instead of this
    if (e.target.value < 1) {
      toast.error("Veuillez insérer une valeur minimale de 1");
    } else if (e.target.value > 6) {
      toast.error("Veuillez insérer une valeur maximale de 6");
    } else {
      dispatch({
        type: "CHANGE_NUMBER_ADSET",
        payload: { index: i, value: parseInt(e.target.value) },
      });
    }
  };

  const changeStartTime = (val, i) => {
    const newDate = val ? val : "";
    dispatch({
      type: "CHANGE_START_TIME_ADSET",
      payload: { index: i, value: newDate },
    });
  };

  const changeEndTime = (val, i) => {
    const newDate = val ? val : "";
    dispatch({
      type: "CHANGE_END_TIME_ADSET",
      payload: { index: i, value: newDate },
    });
  };

  function renderView(mode, renderDefault, type, index) {
    if (mode === "time") return renderDefault();

    return (
      <div className="wrapper">
        {renderDefault()}
        <div
          className="controls"
          style={{
            width: "100%",
            padding: "10px",
            color: "red",
            textAlign: "end",
          }}
        >
          <p
            onClick={() => goToToday(type, index)}
            style={{
              padding: "10px",
              color: "#007aff",
              cursor: "pointer",
            }}
          >
            <FormattedMessage
              defaultMessage="Aujourd'hui"
              id="create_campaign_infos_cal_btn"
            />
          </p>
        </div>
      </div>
    );
  }

  const goToToday = (type, index) => {
    const today = moment();
    type === "start" && changeStartTime(today, index);
    type === "end" && changeEndTime(today, index);
  };

  const {
    informationsGenerales: { budget: campaignBudget },
  } = useSelector((state) => state.creationCampagne);

  const changeBudgetAdset = (e, i) => {
    clearTimeout(budgetTimer.current)
    if (campaignBudget) {
      setBudgetInput("");
      setAlertMessage(true);
    } else {
      budgetTimer.current = setTimeout(()=>{
        dispatch({
          type: "CHANGE_BUDGET_ADSET",
          payload: { index: i, value: parseFloat(e.target.value.replace(",",".")) }});
        setBudgetInput(parseFloat(e.target.value.replace(",",".")))
        }, 500);
    }
  };

  const handlePackagesPubChange = (event, selectedOptions) => {
    dispatch({
      type:"SET_PACKAGES_PUB_ADSETS_DETAILLE",
      payload: {index, packagesPub:selectedOptions}
    })
  }

  const handleExclusionsChange = (event, selectedOptions) => {
    dispatch({
      type:"SET_EXCLUSIONS_ADSETS_DETAILLE",
      payload: {index, exclusions:selectedOptions}
    })
  }
  
  const deletePackagePub = (name) => {
    if (packagesPub.length > 1) {
      dispatch({
        type: "REMOVE_PACKAGE_PUB_ADSETS_DETAILLE",
        payload: { name, index },
      });
    } else {
      dispatch({
        type: "SET_ALERT_GENERAL_ERROR_MESSAGE",
        payload: "Sélectionner au moins 1 package de pub par adset",
      });
      dispatch({ type: "SHOW_ALERT_GENERAL_ERROR_MESSAGE" });
    }
  };

  const removePackageExclusion = (id) => {
    if(exclusion_audiences.find(audiencePackage=> audiencePackage.id==id)){
      dispatch({
        type: "REMOVE_EXCLUSION_AUDI_ADSETS_DETAILLE",
        payload: { id, index },
      });
    }
    else {
      dispatch({
        type: "REMOVE_EXCLUSION_CENTRE_ADSETS_DETAILLE",
        payload: { id, index },
      });
    }
  };

  // disable past dates
  const yesterday = moment().subtract(1, "day");
  const disablePastDt = (current) => {
    return current.isAfter(yesterday);
  };


  useEffect(()=>{
    setBudgetInput(budget);
  }, [budget])

  return (
    <div className="single_package_container">
      <div className="single_package">
        <h5 className="title">
          <span className="circle"></span> {name}
        </h5>
        <div className="nbr_adets">
          <input
            id="number_without_step"
            type="text"
            inputMode="numeric"
            value={nAdsetsInput}
            onBlur={(e) => changeNbrAdset(e, index)}
            onChange={(e) => setNAdsetsInput(e.target.value)}
            className={`number number_1 ${
              type === "audience" || type === "ciblage_large" ? "disabled" : ""
            }`}
            disabled={
              type === "audience" || type === "ciblage_large" ? true : false
            }
          />
        </div>
        <div className="package_geo_demo">
          <div className="ciblages">
            {targeting_packages && (
              <div className="ciblage">
                <p>{targeting_packages ? targeting_packages.name : ""}</p>
              </div>
            )}
          </div>
        </div>
        <div className="package_geo_demo">
          <div className="ciblages">
            {packagesPub && (
              !expanded && packagesPub.length > 2 ? (
                <>
                  {packagesPub.slice(0, 2).map((item) => (
                    <div className="ciblage" key={item}>
                      <p 
                        className="truncate" 
                        title={item}
                        >
                        {item.length > 15 ? item.slice(0, 12) + "..." : item}
                      </p>
                      <i
                        onClick={() => deletePackagePub(item)}
                        className="fas fa-times"
                      ></i>
                    </div>
                  ))}
                  <div className="length">
                    <span>+{packagesPub.length - 2}</span>
                  </div>
                </>
                ):(
                packagesPub.map((item) => (
                  <div className="ciblage" key={item}>
                    <p className="truncate" title={item}>{item.length > 15 ? item.slice(0, 12) + "..." : item}</p>
                    <i
                      onClick={() => deletePackagePub(item)}
                      className="fas fa-times"
                    ></i>
                  </div>
                ))
              )
            )}
            <Autocomplete
              multiple
              disableCloseOnSelect
              options={keywords}
              value={packagesPub}
              onChange={handlePackagesPubChange}
              renderTags={() => null}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option} style={{ flex: 1, padding:5}}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    checked={selected}
                  />
                  {option}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  //label="Select items"
                  placeholder="Select..."
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: null, // Remove clear and open buttons
                  }}
                />
              )}
            />
          </div>
        </div>
        <div className="package_geo_demo">
          <div className="ciblages">
            {excAudiCent && (
              !expanded && excAudiCent.length > 2 ? (
                <>
                  {excAudiCent.slice(0, 2).map((item) => (
                    <div className="ciblage" key={item.id}>
                      <p 
                        className="truncate" 
                        title={item.name}
                        >
                        {item.name.length > 15 ? item.name.slice(0, 12) + "..." : item.name}
                      </p>
                      <i
                        onClick={() => removePackageExclusion(item.id)}
                        className="fas fa-times"
                      ></i>
                    </div>
                  ))}
                  <div className="length">
                    <span>+{excAudiCent.length - 2}</span>
                  </div>
                </>
                ):(
                  excAudiCent.map((item) => (
                  <div className="ciblage" key={item.id}>
                    <p 
                      className="truncate" 
                      title={item.name}
                      >
                      {item.name.length > 15 ? item.name.slice(0, 12) + "..." : item.name}
                    </p>
                    <i
                      onClick={() => removePackageExclusion(item.id)}
                      className="fas fa-times"
                    ></i>
                  </div>
                ))
              )
            )}
            <Autocomplete
              multiple
              disableCloseOnSelect
              options={[...excluded_audiences_packages, ...excluded_interests_packages]}
              value={excAudiCent}
              onChange={handleExclusionsChange}
              isOptionEqualToValue={(option, value)=> option.id===value.id}
              getOptionLabel={(option) => option.name}
              renderTags={() => null}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option.id} style={{ flex: 1, padding:5}}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  //label="Select items"
                  placeholder="Select..."
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: null, // Remove clear and open buttons
                  }}
                />
              )}
            />
          </div>
        </div>
        <div className="dates-adset">
          <div
            className={`input_group`}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span htmlFor="" style={{ color: "#e83e8c", marginRight: "4px" }}>
              <FormattedMessage
                defaultMessage="Début"
                id="create_campaign_adset_detail_date_debut"
              />
            </span>
            <Datetime
              inputProps={{
                placeholder: intl.formatMessage({
                  defaultMessage: "Maintenant",
                  id: "create_campaign_infos_generales_date_debut_placeholder",
                }),
              }}
              value={start_time ? start_time : dateDebut}
              name="start_time"
              utc={true}
              onChange={(val) => changeStartTime(val, index)}
              isValidDate={disablePastDt}
              renderView={(mode, renderDefault) =>
                renderView(mode, renderDefault, "start", index)
              }
            />
          </div>
          <div
            className="input_group"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span htmlFor="" style={{ color: "#e83e8c", marginRight: "10px" }}>
              <FormattedMessage
                defaultMessage="Fin"
                id="create_campaign_adset_detail_date_fin"
              />
            </span>
            <Datetime
              inputProps={{
                placeholder: intl.formatMessage({
                  defaultMessage: "Continu",
                  id: "create_campaign_infos_generales_date_fin_placeholder",
                }),
              }}
              value={end_time ? end_time : dateFin}
              name="end_time"
              utc={true}
              onChange={(val) => changeEndTime(val, index)}
              isValidDate={disablePastDt}
              renderView={(mode, renderDefault) =>
                renderView(mode, renderDefault, "end", index)
              }
            />
          </div>
        </div>
        <div className="budget_adset">
          <input
            className="number"
            type="text"
            inputMode="decimal"
            value={budgetInput}
            onInput={(e)=>setBudgetInput(e.target.value)}
            onChange={(e)=>changeBudgetAdset(e, index)}
          />
        </div>
        <i
          className="fas fa-trash"
          style={{ color: "#e83e8c", marginTop: "5px" }}
          onClick={handleRemoveItem}
        ></i>
      </div>
      {(packagesPub.length > 2 || exclusion_audiences.length > 2 || exclusionInterests.length >2 )&& (
        <div className="expand-button" 
          onClick={e => setExpanded((!expanded))}
          >
          <i className={`fas fa-chevron-${expanded ? "up" : "down"}`}></i>
        </div>
      )}
    </div>
  );
};

export default SinglePackageItemAdSetDetaille;
