import moment from "moment";

const initialState = {
  contrôleBudget: {
    alerts: [],
    alertId: "",
    status: false,
    name: "",
    target_budget: "",
    date_start: "",
    date_end: "",
    campagnes: [],
    threshold: "",
    ad_pause_threshold: "",
    pagination: {
      count: 10,
      startIndex: 0,
      endIndex: 10,
    },
  },

  alertObjectifs: {
    objectifs: [],
    objectifId: "",
    status: true,
    target: 0,
    threshold: "",
    metrics: "",
    restart_date: "",
    selectedCampaigns: [],
    all_campaigns: true,
    pagination: {
      count: 10,
      startIndex: 0,
      endIndex: 10,
    },
  },
  alertsPreferences: {},
};

export const alertsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_ALERT_DATA":
      return {
        ...state,
        contrôleBudget: {
          ...state.contrôleBudget,
          [action.payload.name]: action.payload.value,
        },
      };
    case "ADD_OBJECTIF_DATA":
      return {
        ...state,
        alertObjectifs: {
          ...state.alertObjectifs,
          [action.payload.name]: action.payload.value,
        },
      };
    case "SET_STATUS":
      const { alertId } = action.payload;
      const updatedAlerts = state.contrôleBudget.alerts.map((alert) => {
        if (alert._id === alertId) {
          return { ...alert, status: !alert.status }; // Create a new object with the updated status
        }
        return alert; // Return the original object for other alerts
      });
      return {
        ...state,
        contrôleBudget: {
          ...state.contrôleBudget,
          alerts: updatedAlerts,
        },
      };
    case "SET_STATUS_OBJECTIF":
      const { objectifId } = action.payload;
      const updatedObjectifs = state.alertObjectifs.objectifs.map(
        (objectif) => {
          if (objectif._id === objectifId) {
            return { ...objectif, status: !objectif.status }; // Create a new object with the updated status
          }
          return objectif; // Return the original object for other alerts
        }
      );
      return {
        ...state,
        alertObjectifs: {
          ...state.alertObjectifs,
          objectifs: updatedObjectifs,
        },
      };
    case "SET_CAMPAIGNS_SELECTION_OBJECTIF":
      return {
        ...state,
        alertObjectifs: {
          ...state.alertObjectifs,
          all_campaigns: !state.alertObjectifs.all_campaigns,
          selectedCampaigns: state.alertObjectifs.all_campaigns
            ? state.alertObjectifs.selectedCampaigns
            : [],
        },
      };
    case "SET_CAMPAIGNS":
      return {
        ...state,
        contrôleBudget: {
          ...state.contrôleBudget,
          campagnes: action.payload,
        },
      };
    case "SET_CAMPAIGNS_OBJECTIF":
      return {
        ...state,
        alertObjectifs: {
          ...state.alertObjectifs,
          selectedCampaigns: action.payload,
        },
      };
    case "ADD_NEW_ALERT":
      return {
        ...state,
        contrôleBudget: {
          ...state.contrôleBudget,
          alerts: [...state.contrôleBudget.alerts, action.payload],
        },
      };
    case "ADD_NEW_ALERT_OBJECTIF":
      return {
        ...state,
        alertObjectifs: {
          ...state.alertObjectifs,
          objectifs: [...state.alertObjectifs.objectifs, action.payload],
        },
      };
    case "SET_ALERTS":
      return {
        ...state,
        contrôleBudget: {
          ...state.contrôleBudget,
          alerts: action.payload,
        },
      };
    case "SET_ALERTS_OBJECTIF":
      return {
        ...state,
        alertObjectifs: {
          ...state.alertObjectifs,
          objectifs: action.payload,
        },
      };
    case "EDIT_ALERT":
      const alert = action.payload;
      return {
        ...state,
        contrôleBudget: {
          ...state.contrôleBudget,
          alertId: alert._id,
          status: alert.status,
          name: alert.name,
          target_budget: alert.target_budget,
          date_start: moment(alert.date_start).utc().format("YYYY-MM-DD"),
          date_end: moment(alert.date_end).utc().format("YYYY-MM-DD"),
          campagnes: alert.campaign,
          threshold: alert.threshold,
          ad_pause_threshold: alert.ad_pause_threshold,
        },
      };
    case "EDIT_ALERT_OBJECTIF":
      const objectif = action.payload;
      return {
        ...state,
        alertObjectifs: {
          ...state.alertObjectifs,
          objectifId: objectif._id,
          status: objectif.status,
          metrics: objectif.metrics,
          target: objectif.target,
          selectedCampaigns: objectif.campaigns,
          threshold: objectif.threshold,
          all_campaigns: objectif.all_campaigns,
        },
      };
    case "CLEAR_ALERT_OBJECTIF":
      return {
        ...state,
        alertObjectifs: {
          ...state.alertObjectifs,
          objectifId: "",
          status: "",
          metrics: "",
          target: "",
          selectedCampaigns: [],
          threshold: "",
        },
      };
    case "REMOVE_CAMPAIGN_FROM_ALERT":
      const result = state.contrôleBudget.alerts.map((item) => {
        if (item._id === action.payload.alertId) {
          item.campaign = item.campaign.filter(
            (campaign) => campaign.id !== action.payload.campId
          );
        }
        return item;
      });

      return {
        ...state,
        contrôleBudget: {
          ...state.contrôleBudget,
          alerts: result,
        },
      };
    case "REMOVE_ALERT":
      const updateAlerts = state.contrôleBudget.alerts.filter(
        (item) => item._id !== action.payload
      );

      return {
        ...state,
        contrôleBudget: {
          ...state.contrôleBudget,
          alerts: updateAlerts,
        },
      };
    case "REMOVE_CAMPAIGN_FROM_OBJECTIF":
      const result1 = state.alertObjectifs.objectifs.map((item) => {
        if (item._id === action.payload.objectifId) {
          item.campaigns = item.campaigns.filter(
            (campaign) => campaign.id !== action.payload.campId
          );
          item.all_campaigns = item.campaigns.length === 0 ? true : false;
        }
        return item;
      });

      return {
        ...state,
        alertObjectifs: {
          ...state.alertObjectifs,
          objectifs: result1,
        },
      };
    case "SET_END_INDEX_PAGINATION_ALERTS_OBJECTIF":
      return {
        ...state,
        alertObjectifs: {
          ...state.alertObjectifs,
          pagination: {
            ...state.alertObjectifs.pagination,
            endIndex: parseInt(action.payload),
            startIndex:
              parseInt(action.payload) - state.alertObjectifs.pagination.count,
          },
        },
      };
    case "SET_END_INDEX_PAGINATION_CONTROL_BUDGET":
      return {
        ...state,
        contrôleBudget: {
          ...state.contrôleBudget,
          pagination: {
            ...state.contrôleBudget.pagination,
            endIndex: parseInt(action.payload),
            startIndex:
              parseInt(action.payload) - state.contrôleBudget.pagination.count,
          },
        },
      };
    case "GET_ALERTS_PREFERENCES":
      return {
        ...state,
        alertsPreferences: action.payload,
      };
    case "ADD_PREFERENCES_DATA":
      return {
        ...state,
        alertsPreferences: {
          ...state.alertsPreferences,
          [action.payload.name]: !state.alertsPreferences[action.payload.name],
        },
      };
    default:
      return state;
  }
};
