import React, { useState } from "react";
import Switch from "@mui/material/Switch";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loader3 from "../../../../components/Loader3/Loader3";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import ImageItem from "./ImageItem/ImageItem";
import Pagination from "@mui/material/Pagination";
import { FormattedMessage } from "react-intl";

const Image = ({ getImages, loader, compagnId, toggleLeftMenu }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);

  const [showTags, setShowTags] = useState(false);

  const {
    image: {
      filteredImages,
      images,
      pagination: { startIndex, endIndex, count },
    },
  } = useSelector(state => state.creationPackagePublicite);

  const handleChangePage = (event, value) => {
    event.stopPropagation();

    dispatch({
      type: "SET_END_INDEX_PAGINATION_IMAGES",
      payload: value * count,
    });
  };

  const addImage = () => {
    navigate(
      `/creation-package-pub/ajout-ressource${
        compagnId ? `?campaign_id=${compagnId}` : ""
      }`
    );
  };

  const leftContainer = document.querySelector(
    ".creation_package_pub_screen .right_content_main .row.row_1 .left"
  );
  const rightContainer = document.querySelector(
    ".creation_package_pub_screen .right_content_main .row.row_1 .right"
  );

  const blocButtons = document.querySelector(".buttons_appairer_save");

  useEffect(() => {
    if (toggle) {
      if (blocButtons) {
        blocButtons.style.display = "none";
      }
      if (leftContainer) {
        leftContainer.style.width = !toggleLeftMenu ? "80%" : "95%";
        // leftContainer.style.width = "95%";
        leftContainer.style.height = "100%";
        leftContainer.style.position = "absolute";
        leftContainer.style.top = "60px";
        leftContainer.style.right = "0";
      }

      if (rightContainer) {
        rightContainer.style.display = "none";
      }

      const topBloc = document.querySelector(
        ".creation_package_pub_screen .right_content_main .row.row_1 .left .title_inputs"
      );

      if (topBloc) {
        topBloc.style.display = "none";
      }
    } else {
      if (blocButtons) {
        blocButtons.style.display = "flex";
      }

      if (leftContainer) {
        leftContainer.style.width = !toggleLeftMenu ? "80%" : "95%";
        // leftContainer.style.width = "80%";
        leftContainer.style.height = "auto";
        leftContainer.style.position = "static";
        leftContainer.style.top = "unset";
        leftContainer.style.right = "unset";
      }

      if (rightContainer) {
        rightContainer.style.display = "flex";
        rightContainer.style.width = "30%";
      }

      const topBloc = document.querySelector(
        ".creation_package_pub_screen .right_content_main .row.row_1 .left .title_inputs"
      );

      if (topBloc) {
        topBloc.style.display = "block";
      }
    }
  }, [toggle]);

  const { adaccountId } = useSelector(state => state.auth.user);
  // const {
  //   multimedia: { filteredimages: images, loaderImages: loader },
  // } = useSelector(state => state.campaignConfigurationFile);

  // act_1391256887616069

  const filterImages = e => {
    dispatch({
      type: "FILTER_IMAGES_CREATION_PACKAGE_PUBLICTE",
      payload: e.target.value,
    });
  };

  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!searchParams.get("keep-data")) {
      getImages(adaccountId);
    }
  }, []);

  const imagesToShow = filteredImages.slice(startIndex, endIndex);
  const selectedImages = images.filter(img => img && img.isSelected);

  useEffect(() => {
    if (toggle && selectedImages.length > 0) {
      dispatch({ type: "SHOW_SELECTED_IMAGES_FIRST" });
    }
  }, [toggle]);

  return (
    <section className='section section_image'>
      <header
        onClick={() => {
          setToggle(prevState => !prevState)
        }}
        className='section_header'
      >
        <div className='df'>
          <h3 className='section_header_title'>
            <FormattedMessage
              defaultMessage='Image'
              id='creation_package_pub_section_main_title'
            />

            {selectedImages.length > 0 && (
              <div className='nbr'>
                {selectedImages.map(img => (
                  <img src={img.url} alt='' />
                ))}
                <span className='circle'>{selectedImages.length}</span>
              </div>
            )}
          </h3>
        </div>
        <i className={`fas fa-chevron-${toggle ? "up" : "down"}`}></i>
      </header>
      {toggle && (
        <div className='section_content'>
          <div className='search_tags'>
            <div className='search'>
              <i className='fas fa-search'></i>
              <input
                onChange={filterImages}
                type='search'
                name=''
                id=''
                placeholder='Recherche par nom'
              />
            </div>
            <div className='tags'>
              <i className='fas fa-info-circle'></i>
              <p>Tags</p>
              <Switch
                onChange={() => setShowTags(prevState => !prevState)}
                checked={showTags}
              />
            </div>
          </div>
          <div className='list_images'>
            {loader ? (
              <Loader3 />
            ) : imagesToShow && imagesToShow.length > 0 ? (
              imagesToShow.map((image, i) => (
                <ImageItem
                  key={i}
                  getImages={getImages}
                  showTags={showTags}
                  image={image}
                  compagnId={compagnId}
                />
              ))
            ) : (
              <h4>
                <FormattedMessage
                  defaultMessage="Aucune image pour l'instant"
                  id='creation_package_pub_section_image_no_data'
                />
              </h4>
            )}

            <div
              onClick={addImage}
              className='image_item add_image_item'
              style={{ height: "20.9rem" }}
            >
              <img src='/add_image_icon.png' alt='' />
            </div>
          </div>
          <div className='pagination'>
            <Pagination
              count={parseInt(images.length / count)}
              onChange={handleChangePage}
            />
          </div>
        </div>
      )}
    </section>
  );
};

export default Image;
