import { filterByNames, getUniqueListBy } from "../../utils";

const initialState = {
  loading_ref_campaign: false,
  load_ref: true,
  campaignTypes: [],
  formattedCampaignTypes: [],
  callToActions: [],
  catalogFormats: [],
  tags: [],
  tagToEdit: "",
  geoDemoPackages : [],
  audiencesPackagePub: [],
  customAudiences: [],
  centresInteretPackagePub: [],
  accounts : [],
  instagramAccounts : [],
  pixels: [],
  pixelEvents: [],
  catalogs: [],
  attributions: [],
  adAccountConfiguredCustomers: []
};

export const creationCampaignRefVariables = (state = initialState, action) => {
  switch (action.type) {
    case "CLEAR_CAMPAIGN_REF_VARIABLES":
      return initialState;
    case "LOADING_CAMPAIGN_REF_VARIABLES":
      return {
        ...state,
        loading_ref_campaign: action.payload
      }
    case "CAMPAIGN_REF_VARIABLES_LOADED":
      return {
        ...state,
        load_ref: false
      }  
    case "STORE_INSTAGRAM_ACCOUNTS":
    return {
      ...state,
      instagramAccounts: action.payload,
      };
    case "STORE_FACEBOOK_ACCOUNTS":
      let accounts = action.payload.sort((a, b) =>
                      a.name.localeCompare(b.name)
                      );
      return {
        ...state,
        accounts: accounts,
      };        
    case "STORE_TAGS":
      //processing to select unique tags
      //it should not be useful tho
      let tags = action.payload[0].keywords.filter((item) => item.length !== 0)
      tags = [... new Set(tags)]
      return {
        ...state,
        tags: tags,
      };
    case "SET_TAGS_TO_EDIT":
      return {
        ...state,
        tagToEdit: action.payload,
      };
    case "STORE_PACKAGES_GEO_DEMO":
      return {
        ...state,
        geoDemoPackages: action.payload
      };
    case "GET_CUSTOM_AUDIENCES":
      return {
        ...state,
        customAudiences: action.payload,
      };
    case "STORE_AUDIENCES_PACKAGE":
      return {
        ...state,
        audiencesPackagePub: filterByNames(
          getUniqueListBy(action.payload, "name")
        ),
      };
    case "STORE_INTERESTS_PACKAGE":
      return {
        ...state,
        centresInteretPackagePub: action.payload,
      };
    case "STORE_CONFIGURED_CUSTOMERS":
      return {
        ...state,
        adAccountConfiguredCustomers: action.payload,
      };
    case "SET_PIXELS":
      return {
        ...state,
        pixels: action.payload,
      };
    case "SET_CATALOGS":
      return {
        ...state,
        catalogs: action.payload,
      };
    case "SET_ATTRIBUTIONS":
      return {
        ...state,
        attributions: action.payload,
      };
    case "STORE_CATALOG_FORMATS":
      return {
        ...state,
        catalogFormats: action.payload,
      };
    case "STORE_CALLS_TO_ACTION":
        return {
          ...state,
          callToActions: action.payload,
        };
    case "STORE_CAMPAIGN_TYPES":
      let campaignTypes = action.payload
      const formattedCampaignTypes = Object.entries(campaignTypes).reduce(
        //add to group every element that has the same type
        (acc, [key, value]) => {
          const [type, label] = key.split(" - ");
          //javascript typo where key is the label of the argument
          //so here "label" and "value"
          const option = { label, value };
          const group = acc.find((group) => group.type === type);
          if (group) {
            group.options.push(option);
          } else {
            acc.push({ type, options: [option] });
          }
        return acc;
        }, []);
      return {
        ...state,
        campaignTypes: campaignTypes,
        formattedCampaignTypes: formattedCampaignTypes
      }
    case "GET_PIXELS_EVENTS_REFERENCE":
      return {
        ...state,
        pixelEvents: action.payload,
      };
    default:
      return state;
  }
};
