import { sortByField } from "../../utils";

const initialState = {
  insights: [],
  filteredinsights: [],
  insightsDetailCampagne: [],
  filteredInsightsDetailCampagne: [],
  loader: false,
  singleCampaignConfigurations: [],
  events: [],
  sevents: [],
};

export const insightsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SORT_ASCENDING_BY_KEY_COLUMN":
      const column = action.payload;

      return {
        ...state,
        filteredinsights: sortByField(state.filteredinsights, column, false),
      };
    case "SORT_DESCENDING_BY_KEY_COLUMN":
      const field = action.payload;

      return {
        ...state,
        filteredinsights: sortByField(state.filteredinsights, field, true),
      };
    case "SET_COUTS_COLONNES_DYNAMIQUES":
      let events = state.events;
      let eventsToAdd = [];

      const showCoutsColonnesDynamiques = action.payload;

      const lang = navigator.language.split(/[-_]/)[0];

      if (showCoutsColonnesDynamiques) {
        const filteredEvents = events.filter(
          (obj) => obj.name && !obj.name.endsWith("_value")
        );
        eventsToAdd = filteredEvents.map((event) => ({
          ...event,
          id: `cost_per_${event.id}`,
          name:
            lang === "fr" ? `Cout par ${event.name}` : `Cost per ${event.name}`,
        }));

        events = [...events, ...eventsToAdd];
      } else {
        events = state.events.filter(
          (event) => !event.id.startsWith("cost_per_")
        );

        events = [...events];
      }

      return {
        ...state,
        events: events,
      };

    case "TOGGLE_EVENT_LISTE_CAMPAGNE":
      return {
        ...state,
        events: state.events.map((event) => {
          if (event.uniq_id === action.payload) {
            return { ...event, isChecked: !event.isChecked };
          } else return event;
        }),
      };
    case "TOGGLE_EVENT_LISTE_CAMPAGNE_METADATA":
      const savedEvents = action.payload.userSavedEvents;
      const eventsList = action.payload.events;
      const t = eventsList.map((e) => {
        const exe = savedEvents.find((ex) => ex.id === e.id);
        if (exe) {
          return { ...exe, isChecked: true };
        } else return e;
      });
      return {
        ...state,
        events: t,
      };
    case "GET_EVENTS_LISTE_CAMPAGNES_FAIL":
      return {
        ...state,
        events: [],
      };
    case "GET_EVENTS_LISTE_CAMPAGNES_SUCCESS":
      return {
        ...state,
        events: action.payload,
      };
    case "GET_SINGLE_CAMPAIGN_CONFIGURATIONS":
      return {
        ...state,
        singleCampaignConfigurations: action.payload,
      };
    case "SET_LOADER_INSIGHTS":
      return {
        ...state,
        loader: true,
      };
    case "UNSET_LOADER_INSIGHTS":
      return {
        ...state,
        loader: false,
      };
    case "FILTER_INSIGHTS_DATE_DEBUT":
      return {
        ...state,
        filteredinsights: state.insights.filter(
          (insight) => new Date(insight.date_start) >= new Date(action.payload)
        ),
      };
    case "FILTER_INSIGHTS_DATE_FIN":
      return {
        ...state,
        filteredinsights: state.insights.filter(
          (insight) => new Date(insight.date_stop) <= new Date(action.payload)
        ),
      };
    case "TOGGLE_INSIGHT_IS_SELECTED_AND_PLACEIT_FIRST":
      let insightSelected = state.filteredInsightsDetailCampagne.find(
        (elem) => elem.campaign_id === action.payload
      );
      insightSelected = {
        ...insightSelected,
        isSelected: true,
      };

      return {
        ...state,
        filteredInsightsDetailCampagne: [
          insightSelected,
          ...state.filteredInsightsDetailCampagne
            .filter((elem) => elem.campaign_id !== action.payload)
            .map((elem) => ({ ...elem, isSelected: false })),
        ],
      };
    case "GET_INSIGHTS":
      return {
        ...state,
        insights: action.payload,
        filteredinsights: action.payload,
        insightsDetailCampagne: action.payload,
        filteredInsightsDetailCampagne: action.payload,
      };
    case "FELTER_SINGLE_CAMPAIGN_CONFIGURATION":
      const newItem = action.payload;
      const newSingleCampaignConfigurations =
        state.singleCampaignConfigurations.filter((item) => item !== newItem);
      return {
        ...state,
        singleCampaignConfigurations: newSingleCampaignConfigurations,
      };
    case "UPDATE_INSIGHTS":
      const insts = action.payload;
      return {
        ...state,
        insights: insts,
      };
    case "ATTACH_INSIGHTS_TO_CONFIGURATION":
      const newInsights = state.insights.map((insight) => {
        const matchingItem = state.singleCampaignConfigurations.find(
          (item) => item.facebook_campaign_id === insight.campaign_id
        );
        // matchingItem && matchingItem.draft
        if (matchingItem) {
          insight["singleCampaignConfigurationId"] = matchingItem.id;
          insight["draft"] = matchingItem.draft;
          const newSingleCampaignConfigurations =
            state.singleCampaignConfigurations.filter(
              (item) => item !== matchingItem
            );
        }
      });
      return {
        ...state,
        filteredinsights: newInsights,
        singleCampaignConfigurations: newSingleCampaignConfigurations,
      };
    default:
      return state;
  }
};
