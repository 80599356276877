import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "./style.css";
import { importFBLogin } from "../../utils";
import axiosInstance from "../../store/axios.instance";
import { useNavigate } from "react-router-dom";
import Loader3 from "../Loader3/Loader3";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

const PopupSessionExpired = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  const {
    user: { sub: userId },
  } = useAuth0();

  const proceedAndContinue = () => {
    setLoader(true);

    window.FB.login(
      async response => {
        try {
          const accessToken = response.authResponse.accessToken;

          const resCheckAccessToken = await axiosInstance.post(
            `/connected_client?facebook_token=${accessToken}&dev=${
              process.env.NODE_ENV === "development" ? true : false
            }`
          );

          if (resCheckAccessToken.data) {
            dispatch({
              type: "STORE_USER_DATA_AUTH",
              payload: resCheckAccessToken.data,
            });

            localStorage.setItem(
              "userData",
              JSON.stringify(resCheckAccessToken.data)
            );

            dispatch({
              type: "STORE_ADS_ACCOUNTS",
              payload: resCheckAccessToken.data.adaccounts,
            });

            localStorage.setItem(
              "adaccounts",
              JSON.stringify(resCheckAccessToken.data.adaccounts)
            );

            const result = await axios.post(
              `https://sensego.eu.auth0.com/oauth/token`,
              {
                client_id: "C7odUNsG6740jGRDBhEPMWNjN01OEzW4",
                client_secret:
                  "1uqFm5WtnF5B-kVWa16HlBBbG0Sni25TZeGloL4lJ73Bcd3SDgxcao9USfdS-N6p",
                audience: "https://sensego.eu.auth0.com/api/v2/",
                grant_type: "client_credentials",
              }
            );

            const accessToken = result.data.access_token;

            const headers = {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            };

            await fetch(`https://sensego.eu.auth0.com/api/v2/users/${userId}`, {
              method: "PATCH",
              headers,
              body: JSON.stringify({ user_metadata: resCheckAccessToken.data }),
            });

            setTimeout(() => {
              setLoader(false);
              dispatch({ type: "HIDE_POPUP_SESSION_EXPIRED" });
              navigate("/campagne/comptes-actifs");
            }, 500);
          }
        } catch (error) {
          setLoader(false);
          console.log(error);
        }
      },
      {
        scope:
          "public_profile,email,ads_read,ads_read,ads_management,pages_read_engagement,pages_show_list,business_management,catalog_management,read_insights,instagram_manage_insights,pages_read_user_content,instagram_basic",
        // enable_profile_selector: "true",
        auth_type: "rerequest",
      }
    );
  };

  const fermerPopup = () => {
    dispatch({ type: "HIDE_POPUP_SESSION_EXPIRED" });
  };

  useEffect(() => {
    importFBLogin();
  }, []);

  return (
    <div className='popup_session_expired_facebook'>
      <div className='content'>
        {loader ? (
          <div>
            <h2>Mise à jour des informations de l'utilisateur</h2>
            <Loader3 />
          </div>
        ) : (
          <>
            <h2>Votre session a expirée, veuillez vous reconnecter</h2>
            <div className='buttons'>
              <button onClick={proceedAndContinue} className='success'>
                Me reconnecter
              </button>
              <button className='danger' onClick={fermerPopup}>
                Fermer
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PopupSessionExpired;
