import React, { useEffect, useState } from "react";
import Switch from "@mui/material/Switch";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loader3 from "../../../../components/Loader3/Loader3";
import { useDispatch, useSelector } from "react-redux";
import VideoItem from "./VideoItem/VideoItem";
import Pagination from "@mui/material/Pagination";
import { FormattedMessage } from "react-intl";

const Video = ({ getVideos, loader, compagnId, toggleLeftMenu }) => {
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false);
  const dispatch = useDispatch();
  const [showTags, setShowTags] = useState(false);
  const { adaccountId } = useSelector(state => state.auth.user);

  const {
    video: {
      filteredVideos,
      videos,
      pagination: { startIndex, endIndex, count },
    },
  } = useSelector(state => state.creationPackagePublicite);

  const handleChangePage = (event, value) => {
    event.stopPropagation();

    dispatch({
      type: "SET_END_INDEX_PAGINATION_VIDEOS",
      payload: value * count,
    });
  };

  const addVideo = () => {
    navigate(
      `/creation-package-pub/ajout-ressource${
        compagnId ? `?campaign_id=${compagnId}` : ""
      }`
    );
  };

  const leftContainer = document.querySelector(
    ".creation_package_pub_screen .right_content_main .row.row_1 .left"
  );
  const rightContainer = document.querySelector(
    ".creation_package_pub_screen .right_content_main .row.row_1 .right"
  );

  const imagesSection = document.querySelector(
    ".creation_package_pub_screen .right_content_main .sections .section:first-child"
  );

  const blocButtons = document.querySelector(".buttons_appairer_save");

  useEffect(() => {
    if (toggle) {
      if (blocButtons) {
        blocButtons.style.display = "none";
      }

      if (leftContainer) {
        leftContainer.style.height = !toggleLeftMenu ? "80%" : "95%";
        // leftContainer.style.width = "80%";
        leftContainer.style.height = "100%";
        leftContainer.style.position = "absolute";
        leftContainer.style.top = "60px";
        leftContainer.style.right = "0";
      }

      if (rightContainer) {
        rightContainer.style.display = "none";
      }

      const topBloc = document.querySelector(
        ".creation_package_pub_screen .right_content_main .row.row_1 .left .title_inputs"
      );

      if (topBloc) {
        topBloc.style.display = "none";
      }

      if (imagesSection) {
        imagesSection.style.display = "none";
      }
    } else {
      if (blocButtons) {
        blocButtons.style.display = "flex";
      }

      if (leftContainer) {
        leftContainer.style.height = !toggleLeftMenu ? "80%" : "95%";
        // leftContainer.style.width = "65%";
        leftContainer.style.height = "auto";
        leftContainer.style.position = "static";
        leftContainer.style.top = "unset";
        leftContainer.style.right = "unset";
      }

      if (rightContainer) {
        rightContainer.style.width = "30%";
        rightContainer.style.display = "flex";
      }

      const topBloc = document.querySelector(
        ".creation_package_pub_screen .right_content_main .row.row_1 .left .title_inputs"
      );

      if (topBloc) {
        topBloc.style.display = "block";
      }

      if (imagesSection) {
        imagesSection.style.display = "block";
      }
    }
  }, [toggle]);

  const filterVideos = e => {
    dispatch({
      type: "FILTER_VIDEOS_CREATION_PACKAGE_PUBLICTE",
      payload: e.target.value,
    });
  };

  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!searchParams.get("keep-data")) {
      getVideos(adaccountId);
    }
  }, []);

  const videosToShow = filteredVideos.slice(startIndex, endIndex);
  const selectedVideos = videos.filter(vid => vid && vid.isSelected);

  useEffect(() => {
    if (toggle && selectedVideos.length > 0) {
      dispatch({ type: "SHOW_SELECTED_VIDEOS_FIRST" });
    }
  }, [toggle]);

  return (
    <section className='section section_video'>
      <header
        onClick={() => setToggle(prevState => !prevState)}
        className='section_header'
      >
        <div className='df'>
          <h3 className='section_header_title'>
            <FormattedMessage
              defaultMessage='Video'
              id='creation_package_pub_section_video_1'
            />

            {selectedVideos.length > 0 && (
              <div className='nbr' style={{ marginLeft: 5 }}>
                <span className='circle'>{selectedVideos.length}</span>
              </div>
            )}
          </h3>
        </div>
        <i className={`fas fa-chevron-${toggle ? "up" : "down"}`}></i>
      </header>
      {toggle && (
        <div className='section_content'>
          <div className='search_tags'>
            <div className='search'>
              <i className='fas fa-search'></i>
              <input
                onChange={filterVideos}
                type='search'
                name=''
                id=''
                placeholder='Recherche par nom'
              />
            </div>
            <div className='tags'>
              <i className='fas fa-info-circle'></i>
              <p>Tags</p>
              <Switch
                onChange={() => setShowTags(prevState => !prevState)}
                checked={showTags}
              />
            </div>
          </div>
          <div className='list_videos'>
            {loader ? (
              <Loader3 />
            ) : videosToShow && videosToShow.length > 0 ? (
              videosToShow.map(video => (
                <VideoItem
                  getVideos={getVideos}
                  key={video.id}
                  showTags={showTags}
                  video={video}
                />
              ))
            ) : (
              <h4>
                <FormattedMessage
                  defaultMessage="Aucune vidéo pour l'instant"
                  id='creation_package_pub_section_video_2'
                />
              </h4>
            )}

            <div
              onClick={addVideo}
              className='video_item add_image_item'
              style={{ height: "15.4rem" }}
            >
              <img src='/add_image_icon.png' alt='' />
            </div>
          </div>
          <div className='pagination'>
            {/* {paginationElements.map(item => (
              <span>{item}</span>
            ))} */}
            <Pagination
              count={parseInt(videos.length / count) + 1}
              onChange={handleChangePage}
            />
          </div>
        </div>
      )}
    </section>
  );
};

export default Video;
