import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Loader3 from "../../components/Loader3/Loader3";
import axios from "axios";
import { getUniqueListBy } from "../../utils";
import { toast } from "react-toastify";
import axiosInstance from "../../store/axios.instance";
import Loader from "../../components/Loader/Loader";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { getCentresInteret } from "../../store/actions/utils/campaignCreation";
import { getSafeLastPage, forgetLastPage } from "../../store/actions/utils/usualDispatches";
import { Chip, ListSubheader } from "@mui/material";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function formatSize(lower, upper) {
  if (!lower || lower===-1){
    return "Unavailable";
  }    
  return lower.toLocaleString() + " - " + upper.toLocaleString()
}

const InterestPackage = ({updateMode}) => {
  const dispatch = useDispatch();
  const [suggestions, setSuggestions] = useState([]);
  const [interests, setInterests] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loader3, setLoader3] = useState(false);
  const [namePackage, setNamePackage] = useState("");
  const [storedInterests, setStoredInterests] = useState([]);
  const [storedSuggestions, setStoredSuggestions] = useState([]);
  const [emptyNameError, setEmptyNameError] = useState(false);
  const [interestError, setInterestError] = useState(false);
  const [packageId, setPackageId] = useState("");


  const getParams = new URLSearchParams(window.location.search);
  const interestsType = getParams.get("interests_type");//normal or exclusion in campaign
  const isFirstRender = useRef(true);
  
  const params = useParams();

  const intl = useIntl();
  const {centresInteretPackagePub} = useSelector((state)=> state.creationCampagneRef) 
  const {file_configuration_id} = useSelector((state) => state.creationCampagne);
  const {adaccountId } = useSelector((state) => state.auth.user);
  const {lastPage } = useSelector((state) => state.utils);
  

  const {
    userData: { token: accessToken },
  } = useSelector((state) => state.auth.user);

  const getInterests = (q, limit=50) => {
    setLoader(true);
    axios
      .get(
        `${
          process.env.REACT_APP_GRAPH_API
        }/${adaccountId}/targetingsearch?access_token=${
          accessToken
        }&q=${q}&limit=${limit}&type=adinterestvalid`
      )
      .then((res) => {
        setInterests(res.data.data);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const getSuggestions = async (q, limit=50) => {
    return axios
      .get(
        `${
          process.env.REACT_APP_GRAPH_API
        }/${adaccountId}/targetingsuggestions?access_token=${
          accessToken
          // dev_token
          // process.env.NODE_ENV === "development" ? dev_token : accessToken
        }&limit=${limit}&type=interests&targeting_list=${JSON.stringify(q)}`
      )
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const clearData = () =>{
    setPackageId("");
    setNamePackage("");
    setInterests([]);
    setSuggestions([]);
  }

  const handleChangeName = (e, newItem) => {
    if(newItem && newItem.name){
      const formattedInterests = newItem.interests.map((interest)=>(
        {name: interest.name, id:interest.id, type:interest.kind})
      );
      setPackageId(newItem.id);
      handleChangeCentresInteret(null, formattedInterests)
    }
    newItem
    ? setEmptyNameError(false)
    : setEmptyNameError(true);
  }

  const handleChangeCentresInteret = (event, newValue) => {
    setStoredInterests(newValue);
    const names = newValue.map((val) => ({ id: val.id, type: val.type }));
    let promises = []
    setLoader(true)
    for(let i=0;i*10<names.length;i = i+10){
      promises.push(getSuggestions(names.slice(i, i+10)))
    }
    
    Promise.all(promises)
      .then(results => {
        const allSuggestions = [];
        results.forEach(suggestions=>allSuggestions.push(...suggestions));
        const forbiddenIds = new Set(storedInterests.map(interest=>interest.id));
        const dicoSuggestions = {};
        allSuggestions.forEach(suggestion=>{
          if(!dicoSuggestions[suggestion.id] && !forbiddenIds.has(suggestion.id) ){
            dicoSuggestions[suggestion.id] = suggestion
          };
        })
        setSuggestions(Object.values(dicoSuggestions)) ;
        setLoader(false)
      })
      .catch(error => {
        setLoader(false)
        console.error(error)
      }); 
  };

  const incrementInterests = () => {
    if(storedSuggestions.length){
      const allInterests = [...storedInterests, ...storedSuggestions]
      setStoredSuggestions([])
      handleChangeCentresInteret(null, allInterests)
    }
  }

  const navigate = useNavigate();

  function formatPackageToSend(){
    let interestToSend = [...storedInterests, ...storedSuggestions];
      interestToSend = getUniqueListBy(interestToSend, "id");

      interestToSend = interestToSend.map((item) => ({
        id: item.id,
        name: item.name,
        kind: item.type,
      }));
      const dataToSend = {
        name: namePackage,
        interests: interestToSend,
      };
    return dataToSend
  }

  const savePackageCentreInterest = () => {
    setLoader3(true);
    const dataToSend = formatPackageToSend();
    return axiosInstance
      .put(`/${adaccountId}/interests_package`, dataToSend)
      .then((res) => {
        if (interestsType === "exclusion") {
          dispatch({
            type: "ADD_PACKAGE_INTEREST_EXCLUSION",
            payload: res.data.data,
          });
        } else {
          dispatch({
            type: "ADD_PACKAGE_INTEREST",
            payload: res.data.data,
          });
        }
        toast.success("Package centre intérêt créé");
        setLoader3(false);
        return true
      })
      .catch(() => {
        toast.error(
          "Une erreur s'est produite, veuillez réessayer plus tard"
        );
        setLoader3(false);
        return false
      });
    };

  const updatePackageCentreInterest = () => {
    setLoader3(true);
    const dataToSend = formatPackageToSend()

    return axiosInstance
      .post(
        `/${adaccountId}/interests_package/${packageId}`,
        dataToSend
      )
      .then((res) => {
        if(!updateMode){
          if (interestsType === "exclusion") {
            dispatch({
              type: "ADD_PACKAGE_INTEREST_EXCLUSION",
              payload: res.data.data,
            });
          } else {
            dispatch({
              type: "ADD_PACKAGE_INTEREST",
              payload: res.data.data,
            });
          }
        }
        toast.success("Package centre intérêt créé");
        setLoader3(false);
        return true
      })
      .catch(() => {
        toast.error(
          "Une erreur s'est produite, veuillez réessayer plus tard"
        );
        setLoader3(false);
        return false
      });
  };

  const handleSave = (e) => {
    e.preventDefault();
    if (namePackage.includes("/")) {
      toast.error(
        intl.formatMessage({
          defaultMessage: "Le caractère / est interdit !",
          id: "character_slash_forbidden_WDy529",
        })
      );
    }

    if (namePackage && storedInterests.length > 0) {
      const savePromise = !packageId
        ? savePackageCentreInterest()
        : updatePackageCentreInterest();
      savePromise.then((success) =>{
        if(success){
          let goTo = getSafeLastPage(lastPage, file_configuration_id);
          dispatch(forgetLastPage());
          navigate(goTo);
          getCentresInteret(dispatch, adaccountId);
        }
      })
    } else {
      !namePackage
        ? setEmptyNameError(false):setEmptyNameError(true)
      storedInterests.length === 0
        ? setInterestError(false):interestError(true)
      // toast.error("Veuillez au moins remplir le nom et les centres interest");
    }
  };

  const handleOnInputChangeCentresInteret = (event, newValue) => {
    if (newValue.length > 0) {
      getInterests(newValue);
    }
  };


  useEffect(() => {
    document.addEventListener("keydown", function (event) {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    });
  }, []);

  useEffect(()=>{
    if (params.id){
      setPackageId(params.id);
      const packageToLoad = centresInteretPackagePub.find(
      interestPackage=>interestPackage.id===params.id)
      if (packageToLoad){
        setNamePackage(packageToLoad.name)
        handleChangeName(null, packageToLoad)
      }
    }
  }, [centresInteretPackagePub])

  useEffect(() => {
    if (isFirstRender.current){
      if (!centresInteretPackagePub.length){
        getCentresInteret(dispatch, adaccountId);
      }
      isFirstRender.current = false;
    }
    else {
      clearData();
      getCentresInteret(dispatch, adaccountId);
    }
  }, [adaccountId]);

  return (
    <section className="informations_generales section section_centre_interet">
      <header
        className="section_header"
      >
        <div className="df">
          <h3 className="section_header_title">
            <FormattedMessage
              defaultMessage="Package Centre d'intérêt"
              id="screen_creation_interest_package_1"
            />
          </h3>
        </div>
      </header>
      {loader3 && <Loader message="Création du package en cours" />}
      <div className="informations_generales_content">
        <form action="">
          <div
            className="row"
            style={{
              marginBottom: 30,
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <div
              className={`input_group ${emptyNameError && " inputError"}`}
              style={{ marginBottom: 0 }}
            >
              <label style={{ marginBottom: 10 }} htmlFor="">
                <FormattedMessage
                  defaultMessage="Nom du package"
                  id="screen_creation_interest_package_2"
                />
              </label>
              {loader3 ? (<Loader3 />) : (
                <div className="input_group centre_interet">
                  <Autocomplete
                    name="nom-package-audience"
                    id="autocomplete-standard"
                    disabled={updateMode}
                    options={centresInteretPackagePub}
                    value={namePackage}
                    onInputChange={(e,newInput)=>{
                      setNamePackage(newInput)}}
                    onChange={handleChangeName}
                    getOptionLabel={(option) => option.name || option}
                    isOptionEqualToValue={(option, value) => option.name === value}
                    clearOnBlur={false}
                    freeSolo
                    style={{
                      width: "100%",
                      borderBottom: emptyNameError && "1px solid red",
                    }}
                    renderInput={(params) => {
                      //way to replace the base function of clicking on the cross of a textfield
                      //simplerway might be to construct it by hand
                      let modifiedEndAdornment = params.InputProps.endAdornment;
                      if (params.InputProps.endAdornment && params.InputProps.endAdornment.props && params.InputProps.endAdornment.props.children) {
                        const children = React.Children.toArray(params.InputProps.endAdornment.props.children);
                        const modifiedChildren = children.map(child => {
                          if (child.props.title==="Clear" && child.props.onClick) {
                            return React.cloneElement(child, {
                              onClick: (event) => {
                                if (typeof child.props.onClick === 'function') {
                                  child.props.onClick(event);
                                }
                                clearData();
                              }
                            });
                          }
                          return child
                        });
                        modifiedEndAdornment = React.cloneElement(
                          params.InputProps.endAdornment,
                          {},
                          modifiedChildren
                        );
                      }

                      return (
                        <TextField variant="standard" 
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                          endAdornment: modifiedEndAdornment}}
                      />)     
                    }}
                  />
                </div>
              )}
            </div>
            <div className="messageError">
              {emptyNameError && (
                <FormattedMessage
                  defaultMessage="Le nom du package est obligatoire"
                  id="screen_creation_package_geo_demo_error_msg_1"
                />
              )}
            </div>
          </div>
          <div className="row" style={{ marginBottom: 30 }}>
            <div style={{ flexDirection: "column", alignItems: "start" }}>
              <div
                className={`input_group input_group_autocomplete ${
                  interestError && "inputError"
                }`}
                style={{ marginBottom: 0, width: "100%" }}
              >
                <label htmlFor="">
                  <FormattedMessage
                    defaultMessage="Centres d'intérêt"
                    id="screen_creation_interest_package_3"
                  />
                </label>
                <Autocomplete
                  value={storedInterests}
                  multiple
                  onInputChange={handleOnInputChangeCentresInteret}
                  onChange={handleChangeCentresInteret}
                  id="checkboxes-tags-demo"
                  options={interests}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.name}
                  getOptionKey={(option)=>option.id+option.type}
                  isOptionEqualToValue={(option, value) => option.id === value.id && option.type===value.type}
                  renderTags={(value, getTagProps) => {
                    const groups = {};
                    value.map((item, index) => {
                      item.index = index
                      if(groups[item.type]){
                        groups[item.type].push(item)
                      }
                      else {
                        groups[item.type] = [item]
                      }
                    })
                    return ( 
                      <div style={{ display: "flex", flexDirection: "column", flexWrap: "nowrap"}}>
                        {Object.entries(groups).map(([group, subInterests])=>{
                          const displayedInterests = subInterests.map((option)=>(
                            <Chip
                              key={option.id+option.type}
                              label={option.name}
                              value={option}
                              {...getTagProps({ index:option.index })}
                            />
                            )
                          )
                          return (
                            <div key={group} style={{ display: "flex", flexDirection: "column", flexWrap: "nowrap"}}>
                              <ListSubheader style={{ color:"#292c55", fontWeight: 'bold'}}>{group}</ListSubheader>
                              <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
                                {displayedInterests}
                              </div>
                            </div>
                          );
                        })
                      }
                      </div>
                    );           
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}  style={{ display: "flex", justifyContent: "space-between"}}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        <div style={{ textAlign: "left" }}>{option.name} <br /> {option.type}</div>
                      </div>
                      <div style={{ marginLeft: 30, color: '#40458A', textAnchor:'end', textAlign:"end"}}>
                        Size: {formatSize(option.audience_size_lower_bound, option.audience_size_upper_bound)}
                      </div>
                      
                    </li>
                  )}
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      style={{
                        borderBottom: "1px solid rgba(0, 0, 0, 0.4)",
                      }}
                      {...params}
                    />
                  )}
                />
              </div>
              <span className="messageError">
                {interestError && (
                  <FormattedMessage
                    defaultMessage="Veuillez choisir un centres d'intérêt"
                    id="screen_creation_package_geo_demo_error_msg_2"
                  />
                )}
              </span>
            </div>
          </div>
          {storedInterests.length > 0 && (
            <div className="row">
              <div
                className="input_group input_group_autocomplete"
                style={{ marginBottom: 0, width: "100%" }}
              >
                {loader ? (
                  <Loader3 />
                ) : (
                  <React.Fragment>
                    <label htmlFor="">
                      <FormattedMessage
                        defaultMessage="Suggestions"
                        id="screen_creation_interest_package_4"
                      />
                    </label>
                    <Autocomplete
                      multiple
                      //value={storedSuggestions}
                      onChange={(event, newValue)=>{
                        setStoredSuggestions(newValue)
                      }}
                      onClose={(event) => {
                        incrementInterests();
                      }}
                      id="checkboxes-tags-demo"
                      options={suggestions}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.name}
                      getOptionKey={(option)=>option.id+option.type}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          <div>{option.name} <br /> {option.type}</div>
                          <div style={{ marginLeft: 30, color: '#40458A', textAnchor:'end', textAlign:"end"}}>
                            Size: {formatSize(option.audience_size_lower_bound, option.audience_size_upper_bound)}
                      </div>
                        </li>
                      )}
                      style={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField
                          style={{
                            borderBottom: "1px solid rgba(0, 0, 0, 0.4)",
                          }}
                          {...params}
                        />
                      )}
                    />
                  </React.Fragment>
                )}
              </div>
            </div>
          )}

          <div className="d-fe">
            <button onClick={handleSave}>
              <FormattedMessage
                defaultMessage="Enregistrer"
                id="screen_creation_interest_package_5"
              />
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default InterestPackage;
