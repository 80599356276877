import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import RenderTagOption from "../../../components/RenderTagOption/RenderTagOption";
import { saveCampaignNextPage } from "../../../store/actions/utils/campaignCreation";
import { FormattedMessage, useIntl } from "react-intl";
import ChoicePageFacebookAndPageInstagram from "../../../components/ChoicePageFacebookAndPageInstagram/ChoicePageFacebookAndPageInstagram";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const PackagePublicite = ({
  }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [toggle, setToggle] = useState(false);
  const [ignoredPackages, setIgnoredPackages] = useState([])
  const keywords = useSelector((state) => state.creationCampagne.keywords);
  const packagePubliciteDesactive = useSelector((state) => state.creationCampagne.packagePubliciteDesactive);
  const packagePubliciteMiseAJour = useSelector((state) => state.creationCampagne.packagePubliciteMiseAJour);
  const tags = useSelector(state => state.creationCampagneRef.tags);
  
  const handleChangeTagMiseAJour = (event, newValue) => {
     //diff newValue.diff(packagePubliciteMiseAJour)
     let packagesSet = new Set(packagePubliciteMiseAJour)
     let newPackages = newValue.filter(package_crea=>!packagesSet.has(package_crea))
     newPackages.forEach(package_crea=>dispatch({type:"ADD_PACKAGE_PUB_UPDATE", payload:package_crea}))
     //diff packagePubliciteMiseAJour.diff(newValue)
     packagesSet = new Set(newValue)
     newPackages = packagePubliciteMiseAJour.filter(package_crea=>!packagesSet.has(package_crea))
     newPackages.forEach(package_crea=>dispatch({type:"REMOVE_PACKAGE_PUB_UPDATE", payload:package_crea})) 
  };

  const handleChangeTagDesactive = (event, newValue) => {
    dispatch({
      type: "HANDLE_CHANGE_PACKAGE_PUB_DESACTIVE",
      payload: newValue,
    });
  };

  useEffect(()=>{
    let setDiff = new Set([...packagePubliciteDesactive, ...packagePubliciteMiseAJour]);
    setIgnoredPackages(keywords.filter(packagePub=>!setDiff.has(packagePub)));
  }, [keywords, packagePubliciteDesactive, packagePubliciteMiseAJour])

  const intl = useIntl();

  return (
    <section className="informations_generales section">
      <header
        onClick={() => {
          setToggle((prevState) => !prevState);
        }}
        className="section_header"
      >
        <div className="df">
          <h3 className="section_header_title">
            <FormattedMessage
              defaultMessage="Package de publicité"
              id="create_campaign_package_pub_title"
            />
          </h3>
          {keywords.length > 0 ? (
            <img src="/step_checked.png" alt="Etape vérifiée" />
          ) : null}
        </div>
        <i className={`fas fa-chevron-${!toggle ? "down" : "up"}`}></i>
      </header>
      {toggle && (
        <div className="informations_generales_content">
          <form action="">
          <ChoicePageFacebookAndPageInstagram disabled={true}/>
            <div style={{ marginBottom: "1.5rem" }}>
              <label htmlFor="" style={{ color: "#E83E8C" }}>
                Mis à jour
              </label>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <i
                style={{ marginRight: 5, color: "#E83E8C" }}
                className="fas fa-search"
              ></i>
              <Autocomplete
                multiple
                value={packagePubliciteMiseAJour}
                id="checkboxes-tags-demo"
                onChange={handleChangeTagMiseAJour}
                options={tags}
                disableCloseOnSelect
                getOptionLabel={(option) => option}
                renderTags={(options) =>
                  options.map((option) => (
                    <RenderTagOption
                      option={option}
                      updatingCreas={true}
                    />
                  ))
                }
                renderOption={(props, option, { selected }) => (
                  <li {...props} style={{ flex: 1 }}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option}
                  </li>
                )}
                style={{ width: 500 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={intl.formatMessage({
                      id: "creation_campagne_package_publicite_search_package",
                      defaultMessage: "Chercher un package de publicité",
                    })}
                  />
                )}
              />
            </div>
            <div className="add" style={{ marginTop: 0 }}>
              <div className="fe">
                <button
                  onClick={() => {
                    dispatch({type:"SET_STATE_PROTECT_DETAILLED_ADSETS",payload:true});
                    saveCampaignNextPage(dispatch, location);
                    navigate(`/creation-package-pub`);
                  }}
                >
                  <i className="fas fa-plus"></i>
                  <FormattedMessage
                    defaultMessage="Ajouter"
                    id="creation_campagne_package_add_btn"
                  />
                </button>
              </div>
            </div>
          </form>
          <form action="">
            <div style={{ marginBottom: "1rem" }}>
              <label htmlFor="" style={{ color: "#E83E8C" }}>
                Désactivé
              </label>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "1rem",
              }}
            >
              <i
                style={{ marginRight: 5, color: "#E83E8C" }}
                className="fas fa-search"
              ></i>
              <Autocomplete
                multiple
                value={packagePubliciteDesactive}
                id="checkboxes-tags-demo"
                onChange={handleChangeTagDesactive}
                options={keywords}
                disableCloseOnSelect
                getOptionLabel={(option) => option}
                renderTags={(options) =>
                  options.map((option) => (
                    <RenderTagOption
                      option={option}
                      deactivatingCreas={true}
                    />
                  ))
                }
                renderOption={(props, option, { selected }) => (
                  <li {...props} style={{ flex: 1 }}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option}
                  </li>
                )}
                style={{ width: 500 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={intl.formatMessage({
                      id: "creation_campagne_package_publicite_search_package",
                      defaultMessage: "Chercher un package de publicité",
                    })}
                  />
                )}
              />
            </div>
          </form>
          <form action="">
            <div style={{ marginBottom: "1.5rem" }}>
              <label htmlFor="" style={{ color: "#E83E8C" }}>
                Non modifié
              </label>
            </div>
            <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
              <i
                style={{ marginRight: 5, color: "#E83E8C" }}
                className="fas fa-search"
              ></i>
              {ignoredPackages && ignoredPackages.length > 0
                ? ignoredPackages.map((p, i) => (
                    <RenderTagOption
                      key={i}
                      option={p}
                      disabledActions={true}
                    />
                  ))
                : ""}
            </div>
          </form>
        </div>
      )}
    </section>
  );
};

export default PackagePublicite;
