import React, { useState } from "react";
import Loader from "../../components/Loader/Loader";
import axiosInstance from "../../store/axios.instance";
import "./style.css";

import ZonesGeographiquesLangues from "./Sections/ZonesGeographiquesLangues";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import LeftMenu from "../../components/LeftMenu/LeftMenu";
import { toast } from "react-toastify";
import TopRightMenu from "../../components/TopRightMenu/TopRightMenu";
import { FormattedMessage, useIntl } from "react-intl";
import { useEffect } from "react";
import RightContainer from "../../components/RightContainer/RightContainer";
import { getSafeLastPage,setToggleLeftMenu, forgetLastPage} from "../../store/actions/utils/usualDispatches";
import { getPackagesGeoDemo } from "../../store/actions/utils/campaignCreation";

const CreationPackageCiblageDemographique = ({ updateMode, mainTitle }) => {
  const navigate = useNavigate();
  const params = useParams();

  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const { toggleLeftMenu, lastPage } = useSelector((state) => state.utils);
  const { file_configuration_id } = useSelector((state) => state.creationCampagne);


  const { adaccountId } = useSelector((state) => state.auth.user);

  const intl = useIntl();

  const {
    packageGeoDemo: {
      name,
      ageMin,
      ageMax,
      genre,
      languesChoisies,
      typeLocalisation,
      pays,
      villes,
      villesExclues,
      regions,
      regionsExclues,
    },
  } = useSelector((state) => state.geodemo);

  const savePackageGeoDemo = () => {
    setLoader(true);
    //all this part should be modularized with update probably
    let packageCiblageGeoDemo = {
      name,
      age_max: parseInt(ageMax),
      age_min: parseInt(ageMin),
      countries: pays.map((item) => item.key),
      regions: regions.map((item) => {
        delete item.id;

        return {
          ...item,
        };
      }),
      cities: villes.map((item) => {
        delete item.id;

        return {
          ...item,
          distance_unit: "kilometer",
        };
      }),
      excluded_cities: villesExclues.map((item) => {
        delete item.id;

        return {
          ...item,
          distance_unit: "kilometer",
        };
      }),
      excluded_regions: regionsExclues.map((item) => {
        delete item.id;

        return {
          ...item,
        };
      }),
      locales: languesChoisies.map((item) => item.key),
      location_types: JSON.parse(typeLocalisation),
    };

    if (genre !== "Tous") {
      packageCiblageGeoDemo["genders"] = JSON.parse(genre);
    }

    return axiosInstance
      .put(`/${adaccountId}/geo_demo_package`, packageCiblageGeoDemo)
      .then((res) => {
        dispatch({
          type: "SET_PACKAGE_GEO_DEMO",
          payload: res.data.data[1],
        });
        toast.success("Package ciblage géo démo crée");
        setLoader(false);
        return true
      })
      .catch(() => {
        dispatch({
          type: "SET_ALERT_GENERAL_ERROR_MESSAGE",
          payload: "Une erreur s'est produite, veuillez réessayer plus tard",
        });
        dispatch({ type: "SHOW_ALERT_GENERAL_ERROR_MESSAGE" });
        setLoader(false);
        return false
      });
  };

  const currentPackegeGeoDemoId = params.id;

  const updatePackageGeoDemo = () => {
    setLoader(true);

    let packageCiblageGeoDemo = {
      name,
      age_max: parseInt(ageMax),
      age_min: parseInt(ageMin),
      countries: pays.map((item) => item.key),
      regions: regions.map((item) => {
        delete item.id;

        return {
          ...item,
        };
      }),
      cities: villes.map((item) => {
        delete item.id;

        return {
          ...item,
          distance_unit: "kilometer",
        };
      }),
      excluded_cities: villesExclues.map((item) => {
        delete item.id;

        return {
          ...item,
          distance_unit: "kilometer",
        };
      }),
      excluded_regions: regionsExclues.map((item) => {
        delete item.id;

        return {
          ...item,
        };
      }),
      locales: languesChoisies.map((item) => item.key),
      location_types: JSON.parse(typeLocalisation),
    };

    if (genre !== "Tous") {
      packageCiblageGeoDemo["genders"] = JSON.parse(genre);
    }
    return axiosInstance
      .post(
        `/${adaccountId}/geo_demo_package/${currentPackegeGeoDemoId}`,
        packageCiblageGeoDemo
      )
      .then((res) => {
        toast.success("Package ciblage géo démo mis à jour");
        setLoader(false);
        return true
    })
      .catch(() => {
        dispatch({
          type: "SET_ALERT_GENERAL_ERROR_MESSAGE",
          payload: "Une erreur s'est produite, veuillez réessayer plus tard",
        });
        dispatch({ type: "SHOW_ALERT_GENERAL_ERROR_MESSAGE" });
        setLoader(false);
        return false
      });
    };

  const handleSave = () => {
    if (name.includes("/")) {
      toast.error(
        intl.formatMessage({
          defaultMessage: "Le caractère / est interdit !",
          id: "character_slash_forbidden_WDy529",
        })
      );
    }

    if (
      name &&
      ageMin &&
      ageMax &&
      genre &&
      typeLocalisation &&
      (villes.length > 0 || pays.length > 0 || regions.length > 0)
    ) {
      const promiseSave = updateMode
        ? updatePackageGeoDemo()
        : savePackageGeoDemo();
      
      promiseSave.then((success)=>{
        if(success){
          dispatch({ type: "CLEAR_PACKAGE_GEO_DEMO" });
          let goTo = getSafeLastPage(lastPage, file_configuration_id);
          dispatch(forgetLastPage());
          navigate(goTo);
          getPackagesGeoDemo(dispatch, adaccountId);
        }
      })
    } else {
      villes.length === 0 &&
        pays.length === 0 &&
        regions.length === 0 &&
        dispatch({ type: "SET_EMPTY_ZONES_GEOS" });
      !name
        ? dispatch({ type: "SET_EMPTY_NAME" })
        : dispatch({ type: "CLEAN_EMPTY_NAME" });
    }
  };

  useEffect(() => {
    // if (updateMode) {
    //   getPackagesGeoDemo();
    // }
  }, []);

  return (
    <div className="screen_creation_package_ciblage_demographique">
      <LeftMenu />
      <RightContainer>
        <header className="right_content_header">
          <div className="back_title">
            {toggleLeftMenu ? (
              <i
                onClick={() => dispatch(setToggleLeftMenu((prevState) => !prevState))}
                style={{ marginRight: 30, cursor: "pointer" }}
                className="fas fa-bars fa-2x"
              ></i>
            ) : null}
            <div className="back" onClick={() =>{
                dispatch({type:"SET_STATE_PROTECT_DETAILLED_ADSETS",payload:true});
                dispatch({ type: "CLEAR_PACKAGE_GEO_DEMO" });
                let goTo = getSafeLastPage(lastPage, file_configuration_id);
                dispatch(forgetLastPage());
                navigate(goTo);
                }
              }>
              <img src="/left-arrow 2.png" alt="Retour" />
            </div>
            <h1 className="right_content_header_title">
              {updateMode ? (
                mainTitle
              ) : (
                <FormattedMessage
                  defaultMessage="Ajouter un ciblage géo-démographique"
                  id="screen_creation_package_geo_demo_1"
                />
              )}
            </h1>
          </div>
          <div className="client">
            <TopRightMenu />
          </div>
        </header>
        {loader && (
          <Loader
            message={intl.formatMessage({
              defaultMessage:
                "Création du package ciblage géo-démographique en cours...",
              id: "loader_message_13",
            })}
          />
        )}
        <div className="right_content_main">
          <div className="sections">
            <ZonesGeographiquesLangues
              updateMode={updateMode}
            />
          </div>

          <div className="d-fe">
            <button onClick={handleSave}>
              <FormattedMessage
                defaultMessage="Enregistrer"
                id="screen_creation_package_geo_demo_2"
              />
            </button>
          </div>
        </div>
      </RightContainer>
    </div>
  );
};

export default CreationPackageCiblageDemographique;
