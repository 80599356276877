import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import axiosInstance from '../../../../store/axios.instance'
import { toast } from "react-toastify";
import AdItem from "./AdItem";

const PublicitesExistantes = ({ compagnId, nomPackage }) => {
  const [toggle, setToggle] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { publicitesExistantes } = useSelector(
    state => state.creationPackagePublicite
  );
  const { adaccountId } = useSelector(
    state => state.auth.user
  );

  const getPublicitesExistantes = async () => {
    if(!adaccountId){
      toast.error("Aucun compte publicitaire choisi !")
      return;
    }

    const result = await axiosInstance.get(`${adaccountId}/CreatedAds`)

    if(result.status === 200) {
      dispatch({
        type:"GET_PUBLICITES_EXISTANTES_CREATION_PACKAGE_PUBLICITE", 
        payload:nomPackage.length > 0
          ? result.data
              .filter(item => item.keywords.includes(nomPackage))
              .map(item => ({ ...item, isSelected: true }))
          : result.data.map(item => ({ ...item, isSelected: false })),
      })
    }
  }

  useEffect(() => {
    getPublicitesExistantes()

    if (nomPackage.length === 0) {
      setToggle(false);
    }
  }, [])

  const selectedAds = publicitesExistantes.filter(ad => ad && ad.isSelected)

  return (
    <section className='section section_publicites_existantes' style={
      !nomPackage
        ? { backgroundColor: "lightgrey", cursor: "not-allowed" }
        : {}} >
      <header
      style={!nomPackage ? { cursor: "not-allowed" } : {}}
        onClick={() => {
          if (nomPackage) setToggle(prevState => !prevState);
        }}
        className='section_header'
      >
        <div className='df'>
          <h3 className='section_header_title'>
            <FormattedMessage
              defaultMessage='Utiliser une publicité existante'
              id='creation_package_pub_section_publicites_existantes_Sdf654'
            />
            {selectedAds.length > 0 && (
              <span className='nbr'>{selectedAds.length}</span>
            )}
          </h3>
        </div>
        <i className={`fas fa-chevron-${toggle ? "up" : "down"}`}></i>
      </header>
      {toggle && (
        <div className='section_content section_content_publicites_existantes'>
          <div className='liste_publicites_existantes'>
            {publicitesExistantes.length > 0 &&
              publicitesExistantes.map(pub => (
                <AdItem pub={pub} key={pub.id} getPublicitesExistantes={getPublicitesExistantes} />
              ))}
            {/* <div className='publicite_existante'>
              <h4 className='publicite_existante_title'>Patisserie blanc</h4>
              <img src='/pub_existante.png' alt='Patisserie blanc' />
              <div className='interactions'>
                <p>
                  <i className='far fa-thumbs-up'></i>
                  J'aime
                </p>
                <p>
                  <i className='far fa-comment-alt'></i>
                  Commenter
                </p>
                <p>
                  <i className='fas fa-share'></i>
                  Partager
                </p>
              </div>
            </div> */}
            <div
              className='add_publicite_existante'
              onClick={() => compagnId ? navigate(`/selectionner-publicites-existantes?campaign_id=${compagnId}`) : navigate("/selectionner-publicites-existantes")}
            >
              <img src='/add_carousel_icon.png' alt='' />
            </div>
          </div>
        </div>
      )}
    </section>
  );
};
export default PublicitesExistantes;
