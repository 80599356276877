import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import LoginScreen from "./pages/Login/Login";
import CreationCampagne from "./pages/CreationCampagne/CreationCampagne";
import CreationAudience from "./pages/CreationAudience/CreationAudience";
import CreationPackagePubScreen from "./pages/CreationPackagePubScreen/CreationPackagePubScreen";
import Parametres from "./pages/Campagne/Parametres/Parametres";
import AjouterCompte from "./pages/Campagne/AjouterCompte/AjouterCompte";
import ChoisirCompte from "./pages/Campagne/ChoisirCompte/ChoisirCompte";
import ConfigurerStrategie from "./pages/Campagne/ConfigurerStrategie/ConfigurerStrategie";
import ConfigurerStrategie2 from "./pages/Campagne/ConfigurerStrategie2/ConfigurerStrategie2";
import ComptesActifs from "./pages/Campagne/ComptesActifs/ComptesActifs";
import Listecampagnes from "./pages/Campagne/ListeCampagnes/ListeCampagnes";
import DetailCampagne from "./pages/Campagne/DetailCampagne/DetailCampagne";
import ConfirmationCreationCampagne from "./pages/Campagne/ConfirmationCreationCampagne/ConfirmationCreationCampagne";
import CreationPackageCentreInteret from "./pages/CreationPackageCentreInteret/CreationPackageCentreInteret";
import CreationPackageCiblageDemographique from "./pages/CreationPackageCiblageDemographique/CreationPackageCiblageDemographique";
import RedirectIfNotFBConnected from "./components/RedirectIfNotFBConnected/RedirectIfNotFBConnected";
import MessageNotConnectedFB from "./components/MessageNotConnectedFB/MessageNotConnectedFB";
import { useSelector } from "react-redux";
import ErrorMessage from "./components/ErrorMessage/ErrorMessage";
import CreationPackagePubAjoutImageVideoScreen from "./pages/CreationPackagePubAjoutImageVideo/CreationPackagePubAjoutImageVideo";
import CreationCarousel from "./pages/CreationCarousel/CreationCarousel";
import CreationPost from "./pages/CreationPost/CreationPost";
import PriorisationEvenments from "./pages/PriorisationEvenments/PriorisationEvenments";
import ConfigurationMonitoring from "./pages/ConfigurationMonitoring/ConfigurationMonitoring";
import { useIntl } from "react-intl";
import BranchementMonitoringCampagne from "./pages/BranchementMonitoringCampagne/BranchementMonitoringCampagne";
import Alertes from "./pages/Alertes/Alertes";
import SelectionnerPublicitesExistantes from "./pages/SelectionnerPublicitesExistantes/SelectionnerPublicitesExistantes";
import ChoisirPages from "./pages/ChoisirPages/ChoisirPages";
import ComptesActifsTiktok from "./pages/Campagne/ComptesActifsTiktok/ComptesActifsTiktok";
import UpdatePub from "./pages/CreationCampagne/UpdatePub";

function App() {
  const { showMessageNotConnectedFB, showGeneralError } = useSelector(
    (state) => state.utils
  );

  const intl = useIntl();

  return (
    <>
      <ToastContainer />
      <Router>
        {showMessageNotConnectedFB && <MessageNotConnectedFB />}
        {showGeneralError && <ErrorMessage />}
        <Routes>
          <Route
            path="/choisir-page"
            element={
              <PrivateRoute>
                <RedirectIfNotFBConnected>
                  <ChoisirPages />
                </RedirectIfNotFBConnected>
              </PrivateRoute>
            }
          />
          <Route
            path="/selectionner-publicites-existantes"
            element={
              <PrivateRoute>
                <RedirectIfNotFBConnected>
                  <SelectionnerPublicitesExistantes />
                </RedirectIfNotFBConnected>
              </PrivateRoute>
            }
          />
          <Route
            path="/alertes"
            element={
              <PrivateRoute>
                <RedirectIfNotFBConnected>
                  <Alertes />
                </RedirectIfNotFBConnected>
              </PrivateRoute>
            }
          />
          <Route
            path="/branchement-monitoring-campagne"
            element={
              <PrivateRoute>
                <RedirectIfNotFBConnected>
                  <BranchementMonitoringCampagne />
                </RedirectIfNotFBConnected>
              </PrivateRoute>
            }
          />
          <Route
            path="/configuration-monitoring"
            element={
              <PrivateRoute>
                <RedirectIfNotFBConnected>
                  <ConfigurationMonitoring />
                </RedirectIfNotFBConnected>
              </PrivateRoute>
            }
          />
          <Route
            path="/priorisation-evenements"
            element={
              <PrivateRoute>
                <PriorisationEvenments />
              </PrivateRoute>
            }
          />
          <Route
            path="/creation-post"
            element={
              <PrivateRoute>
                <RedirectIfNotFBConnected>
                  <CreationPost />
                </RedirectIfNotFBConnected>
              </PrivateRoute>
            }
          />
          <Route
            path="/modification-carousel/:carouselId"
            element={
              <PrivateRoute>
                <RedirectIfNotFBConnected>
                  <CreationCarousel updateMode />
                </RedirectIfNotFBConnected>
              </PrivateRoute>
            }
          />
          <Route
            path="/creation-carousel"
            element={
              <PrivateRoute>
                <RedirectIfNotFBConnected>
                  <CreationCarousel />
                </RedirectIfNotFBConnected>
              </PrivateRoute>
            }
          />
          <Route
            path="/creation-package-ciblage-demographique"
            element={
              <PrivateRoute>
                <RedirectIfNotFBConnected>
                  <CreationPackageCiblageDemographique />
                </RedirectIfNotFBConnected>
              </PrivateRoute>
            }
          />
          <Route
            path="/edit-package-ciblage-demographique/edit/:id"
            element={
              <PrivateRoute>
                <RedirectIfNotFBConnected>
                  <CreationPackageCiblageDemographique
                    updateMode
                    mainTitle={intl.formatMessage({
                      defaultMessage: "Modifier package géo-démographique",
                      id: "edit_demographique_screen_main_title",
                    })}
                  />
                </RedirectIfNotFBConnected>
              </PrivateRoute>
            }
          />
          <Route
            path="/creation-package-pub/ajout-ressource"
            element={
              <PrivateRoute>
                <CreationPackagePubAjoutImageVideoScreen />
              </PrivateRoute>
            }
          />
          <Route
            path="/creation-package-pub"
            element={
              <PrivateRoute>
                <RedirectIfNotFBConnected>
                  <CreationPackagePubScreen />
                </RedirectIfNotFBConnected>
              </PrivateRoute>
            }
          />
          <Route
            path="/campagne/configurer-strategie"
            element={
              <PrivateRoute>
                <ConfigurerStrategie />
              </PrivateRoute>
            }
          />
          <Route
            path="/campagne/configurer-strategie-2"
            element={
              <PrivateRoute>
                <ConfigurerStrategie2 />
              </PrivateRoute>
            }
          />
          <Route
            path="/campagne/create/confirm"
            element={
              <PrivateRoute>
                <ConfirmationCreationCampagne />
              </PrivateRoute>
            }
          />
          <Route
            path="/campagne/update-pub/confirm/"
            element={
              <PrivateRoute>
                <ConfirmationCreationCampagne updateCreas={true} />
              </PrivateRoute>
            }
          />
          <Route
            path="/creation-campagne/package-centre-interet"
            element={
              <PrivateRoute>
                <RedirectIfNotFBConnected>
                  <CreationPackageCentreInteret />
                </RedirectIfNotFBConnected>
              </PrivateRoute>
            }
          />
          <Route
            path="/creation-campagne/package-centre-interet/edit/:id"
            element={
              <PrivateRoute>
                <RedirectIfNotFBConnected>
                  <CreationPackageCentreInteret
                    updateMode
                    mainTitle={intl.formatMessage({
                      defaultMessage: "Modifier package centre interet",
                      id: "edit_centre_interet_screen_main_title",
                    })}
                  />
                </RedirectIfNotFBConnected>
              </PrivateRoute>
            }
          />
          <Route
            path="/campagne/edit/:id"
            element={
              <PrivateRoute>
                <CreationCampagne
                  mainTitle={intl.formatMessage({
                    defaultMessage: "Modifier une campagne",
                    id: "edit_campaign_screen_main_title",
                  })}
                />
              </PrivateRoute>
            }
          />
          <Route
            path="/campagne/details/:id"
            element={
              <PrivateRoute>
                <DetailCampagne />
              </PrivateRoute>
            }
          />
          <Route
            path="/campagne/liste"
            element={
              <PrivateRoute>
                <Listecampagnes />
              </PrivateRoute>
            }
          />
          <Route
            path="/campagne/comptes-actifs"
            element={
              <PrivateRoute>
                <ComptesActifs />
              </PrivateRoute>
            }
          />
          <Route
            path="/campagne/comptes-actifs-tiktok"
            element={
              <PrivateRoute>
                <ComptesActifsTiktok />
              </PrivateRoute>
            }
          />
          <Route
            path="/campagne/choisir-compte"
            element={
              <PrivateRoute>
                <ChoisirCompte />
              </PrivateRoute>
            }
          />
          <Route
            path="/campagne/ajouter-compte"
            element={
              <PrivateRoute>
                <AjouterCompte />
              </PrivateRoute>
            }
          />
          <Route
            path="/campagne/parametres"
            element={
              <PrivateRoute>
                <Parametres />
              </PrivateRoute>
            }
          />
          <Route
            path="/creation-audience"
            element={
              <PrivateRoute>
                <CreationAudience />
              </PrivateRoute>
            }
          />
          <Route
            path="/creation-audience/edit/:id"
            element={
              <PrivateRoute>
                <CreationAudience
                  updateMode
                  mainTitle={intl.formatMessage({
                    defaultMessage: "Modifier package audience",
                    id: "edit_audience_screen_main_title",
                  })}
                />
              </PrivateRoute>
            }
          />
          <Route
            path="/campagne/update-pub/:id"
            element={
              <PrivateRoute>
                <RedirectIfNotFBConnected>
                  <UpdatePub
                    mainTitle={intl.formatMessage({
                      defaultMessage: "Mettre à jour les publicités",
                      id: "update_pub_screen_main_title",
                    })}
                  />
                </RedirectIfNotFBConnected>
              </PrivateRoute>
            }
          />
          <Route
            path="/creation-campagne"
            element={
              <PrivateRoute>
                <RedirectIfNotFBConnected>
                  <CreationCampagne
                    mainTitle={intl.formatMessage({
                      defaultMessage: "Créer une campagne",
                      id: "create_campaign_screen_main_title",
                    })}
                  />
                </RedirectIfNotFBConnected>
              </PrivateRoute>
            }
          />
          <Route path="/" element={<LoginScreen />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
