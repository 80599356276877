import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import LeftMenu from "../../../components/LeftMenu/LeftMenu";
import "./style.css";
import Loader from "../../../components/Loader3/Loader3";
import InsightsItemDetailsCampagne from "../../../components/InsightsItemDetailsCampagne/InsightsItemDetailsCampagne";
import FilterInsights from "../../../components/FilterInsights/FilterInsights";
import { FormattedMessage } from "react-intl";
import axiosInstance from "../../../store/axios.instance";
import TopPublicites from "../../../components/Reports/TopPublicites";
import TopAudiences from "../../../components/Reports/TopAudiences";
import TopInterests from "../../../components/Reports/TopInterests";
import Loader3 from "../../../components/Loader3/Loader3";
import { estObjetVide, filter } from "../../../utils";
import RightContainer from "../../../components/RightContainer/RightContainer";
import OptimisationRealisee from "../../../components/OptimisationRealisee/OptimisationRealisee";
import TabularReport from "../../../components/Reports/TabularReport";
import { FlipToBack } from "@mui/icons-material";

const DetailCampagne = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { toggleLeftMenu } = useSelector((state) => state.utils);

  const [imagesAndVideosReport, setImagesAndVideosReport] = useState([]);
  const [tabularReports, setTabularReports]= useState({})
  const [selectedCampaign, setSelectedCampaign] = useState(null)
  const firstRender = useRef(true);
  const {id: campaign_id } = useParams()

  const setToggleLeftMenu = () => {
    dispatch({ type: "SET_TOGGLE_LEFT_MENU" });
  };


  const { adaccountName: adAccountName, adaccountId } = useSelector(
    (state) => state.auth.user
  );
  const { filteredInsightsDetailCampagne: insights, loader } = useSelector(
    (state) => state.insights
  );
  const foundCampaign = insights.find(campaign => campaign.campaign_id === campaign_id);
                    
  function filter_report(report, combination){
      return report.filter((row)=> row.combination === combination)
  }
  function sort_report(report, column, ascending=true){
    if (ascending){
      return report.sort((a, b) => a[column] - b[column])
    }
    else{
      return report.sort((a, b) => b[column] - a[column])
    }
  }
  
  const getStats = async (id) => {
    try {
      dispatch({ type: "CLEAR_REPORTS_STATS_DETAILLEES" });

      dispatch({ type: "SET_LOADER_REPORTS_STATS_DETAILLEES" });

      const today = new Date();
      const dateAujourdhui = today.toISOString().slice(0, 10);

      const yesterday = new Date(today);
      yesterday.setDate(yesterday.getDate() - 1);
      const dateHier = yesterday.toISOString().slice(0, 10);

      const account = adaccountId.split("_")[1];

      let response = await axiosInstance.get(
        `/${adaccountId}/report/front_${account}_${dateAujourdhui}/${id}`
      );

      if (response.status === 200 && response.data === null) {
          response = await axiosInstance.get(
            `/${adaccountId}/report/front_${account}_${dateHier}/${id}`
          );
      }
      if (response.status === 200 && response.data !== null){
        const report = response.data.report;

        if (!estObjetVide(report)) {
          const values = Object.values(report);

          const reports = values[0];

          const imagesReport = filter_report(reports, "['image_asset']");
          const videosReport = filter_report(reports, "['video_asset']");
          setImagesAndVideosReport([...imagesReport, ...videosReport]);

          const tableReports = {}
          const combinations = [
            "['body_asset']", "['title_asset']", "['link_url_asset']",
            "['age']", "['gender']", "['publisher_platform']", "['audience_type']",
            "['country']"
          ]
          combinations.forEach(combination =>{
            tableReports[combination.slice(2,combination.length-2)] = filter_report(reports, combination)
          })
          tableReports["age_gender"] = [...tableReports.gender, ...tableReports.age];
          const targetingCategories = [
            "['interests']", "['college_years']", "['education_statuses']", "['education_majors']",
          "['education_schools']", "['work_positions']", "['work_employers']", "['industries']",
          "['life_events']", "['income']", "['interested_in']", "['home_value']","['relationship_statuses']",
          "['family_statuses']", "['custom_audiences']", "['behaviors']", "['connections']", 
          "['friends_of_connections']"]
          let targetingReport = targetingCategories.reduce((acc, category)=>{
            acc.push(...filter_report(reports, category));
            return acc;
          }, []);
          //need to make deep copies for the next
          targetingReport = targetingReport.map(row=>{
            row = Object.assign({}, row);
            row.comb_value = {...row.comb_value};
            row.comb_value[0] = row.comb_value[0].split("name")[1].replace(/[^a-zA-Z\s]+/g, '');
            return row;
          })
          
          tableReports['detailled_targeting'] = targetingReport
          setTabularReports(tableReports);

          const scoredImagesReports = imagesReport.filter(
            (report) => report && report.hasOwnProperty("score")
          );

          const topThreeScoredImagesReports = scoredImagesReports
            .sort((a, b) => b.score - a.score)
            .slice(0, 3);

          const weakThreeScoredImagesReports = scoredImagesReports
            .sort((a, b) => a.score - b.score)
            .slice(0, 3);

          const audiencesReports = reports.filter(
            (report) =>
              report.combination && report.combination === "['audience_type']"
          );
          const interestsReports = reports.filter(
            (report) =>
              report.combination && report.combination === "['interests']"
          );
          // trier les objets par rate_per_inline_link_clicks en ordre décroissant
          const topTroisimagesReports = imagesReport
            .sort(
              (a, b) =>
                b["Rate per inline_link_clicks"] -
                a["Rate per inline_link_clicks"]
            )
            .slice(0, 3);
          const topTroisaudiencesReports = audiencesReports
            .sort(
              (a, b) =>
                b["Rate per inline_link_clicks"] -
                a["Rate per inline_link_clicks"]
            )
            .slice(0, 3);
          const topTroisinterestsReports = interestsReports
            .sort(
              (a, b) =>
                b["Rate per inline_link_clicks"] -
                a["Rate per inline_link_clicks"]
            )
            .slice(0, 3);

          dispatch({
            type: "SET_REPORTS_STATS_DETAILLEES",
            payload: {
              topTroisimagesReports,
              topTroisaudiencesReports,
              topTroisinterestsReports,
              topThreeScoredImagesReports,
              weakThreeScoredImagesReports,
            },
          });

          setTimeout(() => {
            dispatch({ type: "UNSET_LOADER_REPORTS_STATS_DETAILLEES" });
          }, 500);
        } else {
          setTimeout(() => {
            dispatch({ type: "UNSET_LOADER_REPORTS_STATS_DETAILLEES" });
          }, 500);
        }
      }
    } catch (error) {
      console.log(error);
      setTimeout(() => {
        dispatch({ type: "UNSET_LOADER_REPORTS_STATS_DETAILLEES" });
      }, 500);
    }
  };

  const { loader: loaderGetStats } = useSelector(
    (state) => state.rapportStatistiquesDetailllees
  );
  const getAlerts = async (campaignId) => {
    try {
      dispatch({ type: "SET_LOADER_REPORTS_STATS_DETAILLEES" });
      // console.log("campaignId", campaignId);
      const response = await axiosInstance.get(
        `/${adaccountId}/${campaignId}/alert?level=ad,adset,campaign`
      );
      if (response.status === 200) {
        dispatch({
          type: "SET_REPORTS_STATS_DETAILLEES_ALERTS",
          payload: {
            alerts: response.data,
          },
        });
        setTimeout(() => {
          dispatch({ type: "UNSET_LOADER_REPORTS_STATS_DETAILLEES" });
        }, 500);
      }
    } catch (error) {
      console.log(error);
      setTimeout(() => {
        dispatch({ type: "UNSET_LOADER_REPORTS_STATS_DETAILLEES" });
      }, 500);
    }
  };

  useEffect(() => {
    if (adaccountId && firstRender.current) {
      firstRender.current = false;
      setSelectedCampaign(campaign_id);
    }
    else {
      setSelectedCampaign(null);
      setImagesAndVideosReport([]);
      setTabularReports({});
    }
  }, [adaccountId]);



  useEffect(() => {
    if (selectedCampaign){
      getStats(selectedCampaign);
      getAlerts(selectedCampaign)
    }
  }, [selectedCampaign])

  useEffect(()=>{
    //terrible solution but couldn't find better to manage height of stories being < max-width
  
    if(toggleLeftMenu){
      document.documentElement.style.setProperty("--container-width", "66vw")
    }
    else{
      document.documentElement.style.setProperty("--container-width", "55.5vw")
    }
  }, [toggleLeftMenu])
  
  return (
    <div className="ecran_detail_campagne">
      <LeftMenu />
      <RightContainer>
        <header className="right_content_header">
          <div className="back_title">
            {toggleLeftMenu ? (
              <i
                onClick={() => setToggleLeftMenu((prevState) => !prevState)}
                style={{ marginRight: 30, cursor: "pointer" }}
                className="fas fa-bars fa-2x"
              ></i>
            ) : null}
            <h1 className="right_content_header_title">
              <FormattedMessage
                defaultMessage="Liste des campagnes"//wtf not the right title or missing 
                id="detail_campagne_screen_1"
              />
            </h1>
          </div>
          <div className="create_campaign" style={{ display: "flex" }}>
            <button onClick={() => navigate("/creation-campagne")}>
              <FormattedMessage
                defaultMessage=" Créer une campagne"
                id="detail_campagne_screen_2"
              />
            </button>
          </div>
        </header>
        <div className="right_content_main">
          <FilterInsights />
          <div className="row">
            <div className="column_1">
              <header className="column_header">
                <img
                  onClick={() => navigate("/campagne/liste")}
                  src="/icons/left_arrow.png"
                  alt=""
                />
                <p>
                  <FormattedMessage
                    defaultMessage="Revenir sur ma liste"
                    id="detail_campagne_screen_3"
                  />
                </p>
              </header>
              <section className="meta section">
                <h2 className="meta_title">
                  <img src="/icons/meta_campagne.png" alt="" />
                  {adAccountName && adAccountName}
                </h2>
                <div className="listing">
                  {loader ? (
                    <Loader />
                  ) : (
                    [//sorting the campaign we come from here
                      ...(foundCampaign? [foundCampaign]:[]),
                      ...insights.filter(campaign=> campaign.campaign_id!=campaign_id)
                    ].map((item) => (
                      <InsightsItemDetailsCampagne
                        item={item}
                        key={item.campaign_id}
                        setSelectedCampaign={setSelectedCampaign}
                        isSelected={selectedCampaign===item.campaign_id}
                      />
                    ))
                  )}
                </div>
              </section>
            </div>
            <div className="column_2">
              <div className="column_2_container">
                {loaderGetStats ? <Loader3 /> : <OptimisationRealisee />}
                {loaderGetStats ? <Loader3 /> : <TopPublicites report={imagesAndVideosReport}/>}
                {loaderGetStats ? <Loader3 /> : 
                  <TabularReport 
                    name = "titre"
                    title={<FormattedMessage defaultMessage="Performance des titres" id="detail_campagne_screen_10"/>} 
                    report={tabularReports.title_asset}
                  />
                }
                {loaderGetStats ? <Loader3 /> : 
                  <TabularReport 
                    name = "Texte"
                    title={<FormattedMessage defaultMessage="Performance des texte" id="detail_campagne_screen_11"/>} 
                    report={tabularReports.body_asset}
                  />
                }
                {loaderGetStats ? <Loader3 /> : 
                  <TabularReport 
                    name = "Segment"
                    title={<FormattedMessage defaultMessage="Performance par âge et genre" id="detail_campagne_screen_16"/>} 
                    report={tabularReports.age_gender}
                  />
                }
                {loaderGetStats ? <Loader3 /> : 
                  <TabularReport 
                    name = "Ciblage"
                    title={<FormattedMessage defaultMessage="Performance détaillée des ciblages" id="detail_campagne_screen_15"/>} 
                    report={tabularReports.detailled_targeting}
                  />
                }
                <div className="row">
                  {loaderGetStats ? <Loader3 /> : <TopAudiences />}
                  {loaderGetStats ? <Loader3 /> : <TopInterests />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </RightContainer>
    </div>
  );
};

export default DetailCampagne;
