import React, { useState } from "react";
import Switch from "@mui/material/Switch";
import { useNavigate } from "react-router-dom";
import Loader3 from "../Loader3/Loader3";
import { useDispatch, useSelector } from "react-redux";
import ImageItem from "./ImageItem";
import Pagination from "@mui/material/Pagination";

const Image = ({ hideAddButton, hideTags }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const [loader, setLoader] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [showTags, setShowTags] = useState(false);

  const {
    image: {
      filteredImages,
      images,
      pagination: { startIndex, endIndex, count },
    },
  } = useSelector(state => state.appairerVisuels);

  const handleChangePage = (event, value) => {
    event.stopPropagation();

    dispatch({
      type: "SET_END_INDEX_PAGINATION_IMAGES_APPARIEMENT_VISUELS",
      payload: value * count,
    });
  };

  const addImage = () => {
    navigate("/creation-package-pub/ajout-ressource");
  };
  // const {
  //   multimedia: { filteredimages: images, loaderImages: loader },
  // } = useSelector(state => state.campaignConfigurationFile);

  // act_1391256887616069

  const filterImages = e => {
    dispatch({
      type: "FILTER_IMAGES_APPARIEMENT_VISUELS",
      payload: e.target.value,
    });
  };

  const imagesToShow = filteredImages.slice(startIndex, endIndex);

  return (
    <section className='section section_image'>
      <header
        onClick={() => setToggle(prevState => !prevState)}
        className='section_header'
      >
        <div className='df'>
          <h3 className='section_header_title'>Image</h3>
        </div>
        <i className={`fas fa-chevron-${toggle ? "up" : "down"}`}></i>
      </header>
      {toggle && (
        <div className='section_content'>
          <div className='search_tags'>
            <div className='search'>
              <i className='fas fa-search'></i>
              <input
                onChange={filterImages}
                type='search'
                name=''
                id=''
                placeholder='Search'
              />
            </div>
            {!hideTags && (
              <div className='tags'>
                <i className='fas fa-info-circle'></i>
                <p>Tags</p>
                <Switch
                  onChange={() => setShowTags(prevState => !prevState)}
                  checked={showTags}
                />
              </div>
            )}
          </div>
          <div className='list_images'>
            {loader ? (
              <Loader3 />
            ) : imagesToShow && imagesToShow.length > 0 ? (
              imagesToShow.map(image => (
                <ImageItem key={image.id} showTags={showTags} image={image} />
              ))
            ) : (
              <h4>Aucune image pour l'instant</h4>
            )}

            {!hideAddButton && (
              <div onClick={addImage} className='image_item add_image_item'>
                <img src='/add_image_icon.png' alt='' />
              </div>
            )}
          </div>
          <div className='pagination'>
            {/* {paginationElements.map(item => (
              <span>{item}</span>
            ))} */}
            <Pagination
              count={parseInt(images.length / count)}
              onChange={handleChangePage}
            />
          </div>
        </div>
      )}
    </section>
  );
};

export default Image;
