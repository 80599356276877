import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loader3 from "../../../../components/Loader3/Loader3";
import axiosInstance from "../../../../store/axios.instance";
import Pagination from "@mui/material/Pagination";
import BodyItem from "./BodyItem";
import { autoExpand } from "../../../../utils";
import { useSearchParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import Switch from "@mui/material/Switch";
import SearchAsset from "../../../../components/SearchAsset/SearchAsset";

const Texte = ({ getBodies, loaderBodies }) => {
  const [toggle, setToggle] = useState(false);
  const [newText, setNewText] = useState("");
  const [showTags, setShowTags] = useState(false);
  // eslint-disable-next-line
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");

  const handleNewTextChange = e => {
    setNewText(e.target.value);
  };

  const saveNewText = e => {
    if (e.key === "Enter" && e.shiftKey) {
      e.preventDefault();
      setNewText(e.target.value + "\n");
    }
  };

  const saveNewTextOnClick = e => {
    if (!newText) {
      toast.error("Veuillez insérer une valeur non vide");
      return;
    }

    const body = [
      {
        content: newText,
        keywords: [],
      },
    ];

    axiosInstance
      .put(`/${adaccountId}/AdBodies`, body)
      .then(result => {
        dispatch({
          type: "CREATE_BODY_CREATION_PACKAGE_PUBLICTE_SUCCESS",
          payload: result.data[0],
        });

        toast.success("Texte crée avec succès");

        setLoader(false);
      })
      .catch(err => {
        dispatch({ type: "CREATE_BODY_CREATION_PACKAGE_PUBLICTE_FAIL" });
        console.log(err);
        setLoader(false);
      });

    setNewText("");
  };

  const {
    texte: {
      textes,
      pagination: { startIndex, endIndex, count },
    },
    originalData: { textes: textesOriginaux },
  } = useSelector(state => state.creationPackagePublicite);

  const { adaccountId } = useSelector(state => state.auth.user);

  const handleChangePage = (event, value) => {
    event.stopPropagation();

    dispatch({
      type: "SET_END_INDEX_PAGINATION_BODIES",
      payload: value * count,
    });
  };

  const bodiesToShow = textes.slice(startIndex, endIndex);

  const selectedBodies = textesOriginaux.filter(
    item => item && item.isSelected
  );
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!searchParams.get("keep-data")) {
      getBodies(adaccountId);
    }
  }, []);

  useEffect(() => {
    if (toggle && selectedBodies.length > 0) {
      dispatch({ type: "SHOW_SELECTED_BODIES_FIRST" });
    }
  }, [toggle]);

  const searchBodies = e => {
    setSearchValue(e.target.value);

    dispatch({ type: "SEARCH_BODIES", payload: e.target.value });
  };

  return (
    <section className='section section_texte'>
      <header
        onClick={() => setToggle(prevState => !prevState)}
        className='section_header'
      >
        <div className='df'>
          <h3 className='section_header_title'>
            <FormattedMessage
              defaultMessage='Texte'
              id='creation_package_pub_section_texte_1'
            />
            {selectedBodies.length > 0 && (
              <span className='nbr'>{selectedBodies.length}</span>
            )}
          </h3>
        </div>
        <i className={`fas fa-chevron-${toggle ? "up" : "down"}`}></i>
      </header>
      {toggle && (
        <div className='section_content'>
          <SearchAsset value={searchValue} onChange={searchBodies} />
          <div className='wrapper'>
            <div className='add_text'>
              <textarea
                onInput={autoExpand}
                value={newText}
                onKeyDown={saveNewText}
                onChange={handleNewTextChange}
                placeholder='Ajouter un texte'
              ></textarea>
              {/* <input
                value={newText}
                onKeyDown={saveNewText}
                onChange={handleNewTextChange}
                placeholder='Ajouter un texte'
                type='text'
              /> */}
              <i onClick={saveNewTextOnClick} className='fas fa-plus'></i>
            </div>
            <div className='tags'>
              <i className='fas fa-info-circle'></i>
              <p>Tags</p>
              <Switch
                onChange={() => setShowTags(prevState => !prevState)}
                checked={showTags}
              />
            </div>
          </div>
          <div className='list_bodies'>
            {loaderBodies ? (
              <Loader3 />
            ) : bodiesToShow && bodiesToShow.length > 0 ? (
              bodiesToShow.map((texte, i) => (
                <BodyItem
                  key={i}
                  texte={texte}
                  getBodies={getBodies}
                  showTags={showTags}
                />
              ))
            ) : (
              <h6>
                <FormattedMessage
                  defaultMessage="Aucun texte pour l'instant"
                  id='creation_package_pub_section_texte_2'
                />
              </h6>
            )}
          </div>

          <div
            style={{ marginTop: 20, display: "flex", justifyContent: "center" }}
          >
            <Pagination
              count={parseInt(textes.length / count)}
              onChange={handleChangePage}
            />
          </div>
        </div>
      )}
    </section>
  );
};

export default Texte;
