import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../store/axios.instance";
import "./style.css";

import moment from "moment";

const ModalFilterInsightsByDate = () => {
  const [dateDebut, setDateDebut] = useState("");
  const [dateFin, setDateFin] = useState("");

  const { adaccountId } = useSelector((state) => state.auth.user);

  const getInsights = (startDate, endDate) => {
    // setLoader(true);
    dispatch({ type: "SET_LOADER_INSIGHTS" });
    dispatch({
      type: "SET_FILTER_DATES_LIST_CAMPAIGNS",
      payload: { startDate, endDate },
    });

    axiosInstance
      // .get(`/act_615025483640827/insights?since=${startDate}&until=${endDate}`)
      .get(
        `/${adaccountId}/insights?since=${startDate}&until=${endDate}&level=campaign`
      )
      // .get(`/all_accounts/insights?user_id=${user_id}`)
      .then((res) => {
        dispatch({ type: "GET_INSIGHTS", payload: res.data });
        // setLoader(false);
        dispatch({ type: "UNSET_LOADER_INSIGHTS" });
      })
      .catch((err) => console.log(err));
  };

  const enregistrerEtFiltrer = () => {
    if (dateDebut && dateFin) {
      // Fermer la modale
      dispatch({ type: "CLOSE_MODAL_FILTER_INSIGHTS_BY_DATE" });

      // Afficher et filtrer les insights
      getInsights(dateDebut, dateFin);
    }
  };

  const dispatch = useDispatch();

  const fermerLaModale = () => {
    // Fermer la modale
    dispatch({ type: "CLOSE_MODAL_FILTER_INSIGHTS_BY_DATE" });
  };

  const today = new Date();
  today.setDate(today.getDate() - 1);

  return (
    <div className="modal_filter_insights_by_date">
      <div className="content">
        <h1>Filtrer par date</h1>
        <div className="dates">
          <div className="date_input date_debut">
            <h2>Date de début</h2>
            <input
              onChange={(e) => setDateDebut(e.target.value)}
              value={dateDebut}
              type="date"
              max={today.toISOString().split("T")[0]}
            />
          </div>
          <div className="date_input date_fin">
            <h2>Date de fin</h2>
            <input
              onChange={(e) => setDateFin(e.target.value)}
              value={dateFin}
              type="date"
              max={today.toISOString().split("T")[0]}
            />
          </div>
        </div>
        <div className="buttons">
          <button
            title="la mise à jour prend quelques secondes"
            onClick={enregistrerEtFiltrer}
            className="success"
          >
            Enregistrer
          </button>
          <button onClick={fermerLaModale} className="danger">
            Annuler
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalFilterInsightsByDate;
