export const CHECK_IF_CLIENT_EXISTS_IN_DB_SUCCESS =
  "CHECK_IF_CLIENT_EXISTS_IN_DB_SUCCESS";
export const CHECK_IF_CLIENT_EXISTS_IN_DB_FAIL =
  "CHECK_IF_CLIENT_EXISTS_IN_DB_FAIL";
export const SET_LOADER_CLIENTS = "SET_LOADER_CLIENTS";
export const GET_ADACCOUNTS_SUCCESS = "GET_ADACCOUNTS_SUCCESS";
export const GET_ADACCOUNTS_FAIL = "GET_ADACCOUNTS_FAIL";
export const SET_LOADER_ADACCOUNTS = "SET_LOADER_ADACCOUNTS";
export const FILTER_AD_ACCOUNTS = "FILTER_AD_ACCOUNTS";
export const SAVE_ADACCOUNT_ID = "SAVE_ADACCOUNT_ID";
export const CHECK_IF_ACCOUNT_EXISTS_IN_DB_SUCCESS =
  "CHECK_IF_ACCOUNT_EXISTS_IN_DB_SUCCESS";
export const CHECK_IF_ACCOUNT_EXISTS_IN_DB_FAIL =
  "CHECK_IF_ACCOUNT_EXISTS_IN_DB_FAIL";
export const AJOUT_PSEUDO = "AJOUT_PSEUDO";
export const SUPPRESSION_PSEUDO = "SUPPRESSION_PSEUDO";
export const AJOUT_SECTEUR = "AJOUT_SECTEUR";
export const SUPPRESSION_SECTEUR = "SUPPRESSION_SECTEUR";
export const GET_CLIENTS = "GET_CLIENTS";
export const SUBMIT_CLIENT_DATA = "SUBMIT_CLIENT_DATA";
export const SUBMIT_CLIENT_DATA_LOADER = "SUBMIT_CLIENT_DATA_LOADER";
export const SUBMIT_CLIENT_DATA_SUCCESS = "SUBMIT_CLIENT_DATA_SUCCESS";
export const SUBMIT_CLIENT_DATA_FAIL = "SUBMIT_CLIENT_DATA_FAIL";
export const CLEAR_ACCOUNT_ID = "CLEAR_ACCOUNT_ID";
export const CLEAR_ALIASES = "CLEAR_ALIASES";
export const CLEAR_SECTORS = "CLEAR_SECTORS";
export const SET_IS_SELECTED_ADACCOUNT = "SET_IS_SELECTED_ADACCOUNT";
export const GET_ID_AND_COPY_FILE_CAMPAGNE_CONFIGURATION =
  "GET_ID_AND_COPY_FILE_CAMPAGNE_CONFIGURATION";
export const COPY_FILE_CAMPAGNE_CONFIGURATION =
  "COPY_FILE_CAMPAGNE_CONFIGURATION";

export const GET_AD_CAMPAIGNS_FILES_LOADER = "GET_AD_CAMPAIGNS_FILES_LOADER";
export const CREATE_AD_CAMPAIGNS_CONFIGURATION_SUCESS =
  "CREATE_AD_CAMPAIGNS_CONFIGURATION_SUCESS";
export const DUPLICATE_AD_CAMPAIGNS_CONFIGURATION_SUCESS =
  "DUPLICATE_AD_CAMPAIGNS_CONFIGURATION_SUCESS";
export const CREATE_AD_CAMPAIGNS_CONFIGURATION_FAIL =
  "CREATE_AD_CAMPAIGNS_CONFIGURATION_FAIL";
export const DUPLICATE_AD_CAMPAIGNS_CONFIGURATION_FAIL =
  "DUPLICATE_AD_CAMPAIGNS_CONFIGURATION_FAIL";
export const TOGGLE_IS_SELECTED_ADCAMPAIGN_FILE =
  "TOGGLE_IS_SELECTED_ADCAMPAIGN_FILE";
export const CLEAR_SELECTED_FILES = "CLEAR_SELECTED_FILES";
export const CLEAR_AD_ACCOUNT_ID = "CLEAR_AD_ACCOUNT_ID";
export const GET_AD_CAMPAIGNS_FILES_SUCCESS = "GET_AD_CAMPAIGNS_FILES_SUCCESS";
export const GET_AD_CAMPAIGNS_FILES_FAIL = "GET_AD_CAMPAIGNS_FILES_FAIL";
export const ADD_TO_SELECTED_FILES = "ADD_TO_SELECTED_FILES";
export const DELETE_FROM_SELECTED_FILES = "DELETE_FROM_SELECTED_FILES";
export const DELETE_CAMPAIGN_CONFIGURATION_FILE_SUCCESS =
  "DELETE_CAMPAIGN_CONFIGURATION_FILE_SUCCESS";
export const DELETE_CAMPAIGN_CONFIGURATION_FILE_FAIL =
  "DELETE_CAMPAIGN_CONFIGURATION_FILE_FAIL";
export const DELETE_CAMPAIGN_CONFIGURATIONS_FILE_SUCCESS =
  "DELETE_CAMPAIGN_CONFIGURATIONS_FILE_SUCCESS";
export const DELETE_CAMPAIGN_CONFIGURATIONS_FILE_FAIL =
  "DELETE_CAMPAIGN_CONFIGURATIONS_FILE_FAIL";
export const ADD_FILE_CAMPAGNE_CONFIGURATION =
  "ADD_FILE_CAMPAGNE_CONFIGURATION";
export const SWITCH_ADACCOUNT_ID_CURRENT_USER =
  "SWITCH_ADACCOUNT_ID_CURRENT_USER";

export const INSERT_TAG = "INSERT_TAG";
export const DELETE_TAG = "DELETE_TAG";
export const INSERT_TEXT = "INSERT_TEXT";
export const DELETE_TEXT = "DELETE_TEXT";
export const INSERT_TITLE = "INSERT_TITLE";
export const DELETE_TITLE = "DELETE_TITLE";
export const FILTER_RESSOURCES = "FILTER_RESSOURCES";
export const UNFILTER_RESSOURCES = "UNFILTER_RESSOURCES";
export const EXPAND_BODY_TEXT = "EXPAND_BODY_TEXT";
export const APPLY_TAGS = "APPLY_TAGS";
export const ADD_OR_REMOVE_FROM_SELECTED_TAGS =
  "ADD_OR_REMOVE_FROM_SELECTED_TAGS";
export const INSERT_LINK = "INSERT_LINK";
export const DELETE_LINK = "DELETE_LINK";
export const TOGGLE_CHECK_IMAGE = "TOGGLE_CHECK_IMAGE";
export const TOGGLE_CHECK_VIDEO = "TOGGLE_CHECK_VIDEO";
export const TOGGLE_CHECK_BODY = "TOGGLE_CHECK_BODY";
export const TOGGLE_CHECK_TITLE = "TOGGLE_CHECK_TITLE";
export const TOGGLE_CHECK_LINK = "TOGGLE_CHECK_LINK";
export const TOGGLE_CHECK_DESCRIPTION = "TOGGLE_CHECK_DESCRIPTION";
export const SAVE_TAG_LOCAL_RESSOURCE = "SAVE_TAG_LOCAL_RESSOURCE";
export const UPDATE_TITLE_TEXT_WITH_NEW_VALUE =
  "UPDATE_TITLE_TEXT_WITH_NEW_VALUE";
export const ORDER_TAGS_ALPHA = "ORDER_TAGS_ALPHA";
export const MODIFY_DISPLAY_LINK_TEXT = "MODIFY_DISPLAY_LINK_TEXT";
export const DELETE_TAG_LOCAL_RESSOURCE = "DELETE_TAG_LOCAL_RESSOURCE";
export const UPDATE_DISPLAY_LINK_TEXT_WITH_NEW_VALUE =
  "UPDATE_DISPLAY_LINK_TEXT_WITH_NEW_VALUE";
export const CONSTRUCT_CAMPAIGNS_SUCCESS = "CONSTRUCT_CAMPAIGNS_SUCCESS";
export const LOGOUT = "LOGOUT";
export const LOGIN = "LOGIN";
export const UPDATE_BODY_TEXT_WITH_NEW_VALUE =
  "UPDATE_BODY_TEXT_WITH_NEW_VALUE";
export const UPDATE_LINK_TEXT_WITH_NEW_VALUE =
  "UPDATE_LINK_TEXT_WITH_NEW_VALUE";
export const UPDATE_DESCRIPTION_TEXT_WITH_NEW_VALUE =
  "UPDATE_DESCRIPTION_TEXT_WITH_NEW_VALUE";
export const CONSTRUCT_CAMPAIGNS_FAIL = "CONSTRUCT_CAMPAIGNS_FAIL";
export const INSERT_DESCRIPTION = "INSERT_DESCRIPTION";
export const DELETE_DESCRIPTION = "DELETE_DESCRIPTION";
export const MODIFY_BODY_TEXT = "MODIFY_BODY_TEXT";
export const MODIFY_DESCRIPTION_TEXT = "MODIFY_DESCRIPTION_TEXT";
export const MODIFY_TITLE_TEXT = "MODIFY_TITLE_TEXT";
export const MODIFY_LINK_TEXT = "MODIFY_LINK_TEXT";
export const SET_LOADER_MULTIMEDIA_IMAGES = "SET_LOADER_MULTIMEDIA_IMAGES";
export const SET_LOADER_MULTIMEDIA_VIDEOS = "SET_LOADER_MULTIMEDIA_VIDEOS";
export const LOAD_DATA_CONFIGURATION_FILE = "LOAD_DATA_CONFIGURATION_FILE";
export const GET_IMAGES_CAMPAIGN_CONFIGURATION_FILE =
  "GET_IMAGES_CAMPAIGN_CONFIGURATION_FILE";
export const GET_VIDEOS_CAMPAIGN_CONFIGURATION_FILE =
  "GET_VIDEOS_CAMPAIGN_CONFIGURATION_FILE";
export const SAVE_CAMPAIGN_CONFIG_FILE_DATA_SUCCESS =
  "SAVE_CAMPAIGN_CONFIG_FILE_DATA_SUCCESS";
export const SAVE_CAMPAIGN_CONFIG_FILE_DATA_FAIL =
  "SAVE_CAMPAIGN_CONFIG_FILE_DATA_FAIL";
