import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loader3 from "../../../../components/Loader3/Loader3";
import axiosInstance from "../../../../store/axios.instance";
import CtaItem from "./CtaItem";
import Switch from "@mui/material/Switch";

const CallToAction = ({
  getCtas,
  loaderCtas,
  getVariablesReference,
  loaderVariablesReference,
}) => {
  const [toggle, setToggle] = useState(false);
  const [showTags, setShowTags] = useState(false);

  const [newCTA, setNewCTA] = useState("");
  const dispatch = useDispatch();

  const handleNewCTAChange = e => {
    setNewCTA(e.target.value);
  };
  // eslint-disable-next-line
  const [loader, setLoader] = useState(false);

  const {
    callToActions ,
  } = useSelector(state => state.creationCampagneRef);

  const { adaccountId } = useSelector(state => state.auth.user);
  const {
    ctas,
    originalData: { ctas: ctasOriginaux },
  } = useSelector(state => state.creationPackagePublicite);

  const selectedCtas = ctasOriginaux.filter(cta => cta && cta.isSelected);
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!searchParams.get("keep-data")) {
      getCtas(adaccountId);
    }

    getVariablesReference(dispatch);
  }, []);

  // Types de campagne
  const callToActionsKeys = Object.keys(callToActions);
  const callToActionsValues = Object.values(callToActions);

  let finalCtas = [];
  // eslint-disable-next-line
  callToActionsKeys.map((key, index) => {
    finalCtas.push({ key: key, value: callToActionsValues[index] });
  });

  useEffect(() => {
    if (toggle && selectedCtas.length > 0) {
      dispatch({ type: "SHOW_SELECTED_CTAS_FIRST" });
    }
  }, [toggle]);

  const saveNewCTAOnClick = e => {
    if (!newCTA) {
      toast.error("Veuillez insérer une valeur non vide");
      return;
    }

    const existe = ctas.some(element => element.content === newCTA);

    if (existe) {
      toast.error("Le call to action existe déja dans la liste !");
      return;
    }

    const dataToSend = {
      content: newCTA,
      keywords: [],
    };

    setLoader(true);

    axiosInstance
      .put(`/${adaccountId}/call_to_action`, dataToSend)
      .then(res => {
        dispatch({
          type: "PUT_CTAS_CREATION_PACKAGE_PUBLICTE_SUCCESS",
          payload: res.data.data,
        });

        toast.success("Cta crée avec succès");

        setLoader(false);
      })
      .catch(err => {
        dispatch({ type: "PUT_CTAS_CREATION_PACKAGE_PUBLICTE_FAIL" });
        console.log(err);
        setLoader(false);
      });

    setNewCTA("");
  };

  return (
    <section className='section section_texte'>
      <header
        onClick={() => setToggle(prevState => !prevState)}
        className='section_header'
      >
        <div className='df'>
          <h3 className='section_header_title'>
            <FormattedMessage
              defaultMessage='Call To Action'
              id='creation_package_pub_section_cta_1'
            />

            {selectedCtas.length > 0 && (
              <span className='nbr'>{selectedCtas.length}</span>
            )}
          </h3>
        </div>
        <i className={`fas fa-chevron-${toggle ? "up" : "down"}`}></i>
      </header>
      {toggle && (
        <div className='section_content'>
          <div className='wrapper'>
            <div className='add_text'>
              {loaderVariablesReference ? (
                <Loader3 />
              ) : (
                <select
                  style={{
                    // minWidth: 160,
                    padding: 5,
                    border: "none",
                    outline: "none",
                  }}
                  value={newCTA}
                  onChange={handleNewCTAChange}
                >
                  {finalCtas &&
                    finalCtas.length > 0 &&
                    finalCtas.map(cta => (
                      <option value={cta.value}>{cta.key}</option>
                    ))}
                </select>
              )}
              <i onClick={saveNewCTAOnClick} className='fas fa-plus'></i>
            </div>
            <div className='tags'>
              <i className='fas fa-info-circle'></i>
              <p>Tags</p>
              <Switch
                onChange={() => setShowTags(prevState => !prevState)}
                checked={showTags}
              />
            </div>
          </div>
          <div className='list_texts'>
            {loaderCtas ? (
              <Loader3 />
            ) : ctas && ctas.length > 0 ? (
              ctas.map((texte, i) => (
                <CtaItem
                  key={i}
                  texte={texte}
                  getCtas={getCtas}
                  showTags={showTags}
                />
              ))
            ) : (
              <h6>
                <FormattedMessage
                  defaultMessage="Aucun cta pour l'instant"
                  id='creation_package_pub_section_cta_2'
                />
              </h6>
            )}
          </div>
        </div>
      )}
    </section>
  );
};

export default CallToAction;
