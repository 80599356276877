import { sortByKeywords } from "../../utils";

const initialState = {
  image: {
    images: [],
    filteredImages: [],
    pagination: {
      count: 10,
      startIndex: 0,
      endIndex: 10,
    },
  },
  video: {
    videos: [],
    filteredVideos: [],
    pagination: {
      count: 10,
      startIndex: 0,
      endIndex: 10,
    },
  },
  visuels: [],
  contenuParDefaut: {
    texte: null,
    titre: null,
    description: null,
    lien: null,
    cta: null,
  },
};

export const carouselReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CLEAR_DATA_UPDATE_CAROUSEL":
      return {
        ...state,
        visuels: [],
        contenuParDefaut: {
          texte: null,
          titre: null,
          description: null,
          lien: null,
          cta: null,
        },
      };
    case "SET_CAROUSEL_TO_UPDATE":
      const { title, description, call_to_action, body, link, carousel_cards } =
        action.payload;

      const newVisuels = carousel_cards.map((item) => {
        return {
          ...item,
          titre: item.title,
          description: item.description,
          lien: item.link,
          callToAction: item.call_to_action,
        };
      });

      return {
        ...state,
        contenuParDefaut: {
          ...state.contenuParDefaut,
          texte: body,
          titre: title,
          description: description,
          lien: link,
          cta: call_to_action,
        },
        visuels: newVisuels,
        video: {
          ...state.video,
          filteredVideos: state.video.filteredVideos.map((vid) => {
            let vidObj = vid;
            // eslint-disable-next-line
            newVisuels.map((visuel) => {
              if (vid.id === visuel.id) {
                vidObj = { ...vidObj, isSelected: true };
              }
            });

            return vidObj;
          }),
          videos: state.video.videos.map((vid) => {
            let vidObj = vid;
            // eslint-disable-next-line
            newVisuels.map((visuel) => {
              if (vid.id === visuel.id) {
                vidObj = { ...vidObj, isSelected: true };
              }
            });

            return vidObj;
          }),
        },
        image: {
          ...state.image,
          filteredImages: state.image.filteredImages.map((img) => {
            let imgObj = img;
            // eslint-disable-next-line
            newVisuels.map((visuel) => {
              if (img.id === visuel.id) {
                imgObj = { ...imgObj, isSelected: true };
              }
            });

            return imgObj;
          }),
          images: state.image.images.map((img) => {
            let imgObj = img;
            // eslint-disable-next-line
            newVisuels.map((visuel) => {
              if (img.id === visuel.id) {
                imgObj = { ...imgObj, isSelected: true };
              }
            });

            return imgObj;
          }),
        },
      };
    case "ADD_VIDEO_TO_LIST_ASSETS_VIDEOS":
      const videoToAdd = {
        ...action.payload,
      };

      return {
        ...state,
        video: {
          ...state.video,
          videos: [videoToAdd, ...state.video.videos],
          filteredVideos: [videoToAdd, ...state.video.filteredVideos],
        },
      };
    case "ADD_IMAGE_TO_LIST_ASSETS_IMAGES":
      const imageToAdd = {
        ...action.payload,
      };

      return {
        ...state,
        image: {
          ...state.image,
          images: [imageToAdd, ...state.image.images],
          filteredImages: [imageToAdd, ...state.image.filteredImages],
        },
      };
    case "UPDATE_ORDER_LIST_VISUELS":
      return {
        ...state,
        visuels: action.payload,
      };
    case "DELETE_VISUEL_CREATION_CAROUSEL":
      return {
        ...state,
        visuels: state.visuels.filter((visu) => visu.id !== action.payload),
        video: {
          ...state.video,
          filteredVideos: state.video.filteredVideos.map((vid) => {
            if (vid.id === action.payload) {
              return { ...vid, isSelected: false };
            } else return vid;
          }),
          videos: state.video.videos.map((vid) => {
            if (vid.id === action.payload) {
              return { ...vid, isSelected: false };
            } else return vid;
          }),
        },
        image: {
          ...state.image,
          filteredImages: state.image.filteredImages.map((img) => {
            if (img.id === action.payload) {
              return { ...img, isSelected: false };
            } else return img;
          }),
          images: state.image.images.map((img) => {
            if (img.id === action.payload) {
              return { ...img, isSelected: false };
            } else return img;
          }),
        },
      };
    case "CLEAR_VISUELS_CREATION_CAROUSEL":
      return {
        ...state,
        visuels: [],
        video: {
          ...state.video,
          filteredVideos: state.video.filteredVideos.map((vid) => {
            return { ...vid, isSelected: false };
          }),
          videos: state.video.videos.map((vid) => {
            return { ...vid, isSelected: false };
          }),
        },
        image: {
          ...state.image,
          filteredImages: state.image.filteredImages.map((img) => {
            return { ...img, isSelected: false };
          }),
          images: state.image.images.map((img) => {
            return { ...img, isSelected: false };
          }),
        },
        contenuParDefaut: {
          texte: null,
          titre: null,
          description: null,
          lien: null,
          cta: null,
        },
      };
    case "SET_CONTENT_DEFAULT_CAROUSEL":
      return {
        ...state,
        visuels: state.visuels.map((item) => {
          if (
            action.payload.name === "titre" ||
            action.payload.name === "description" ||
            action.payload.name === "lien"
          ) {
            return {
              ...item,
              [action.payload.name]: action.payload.value.content,
            };
          } else {
            return {
              ...item,
              callToAction: action.payload.value,
            };
          }
        }),
        contenuParDefaut: {
          ...state.contenuParDefaut,
          [action.payload.name]: action.payload.value,
        },
      };
    case "SET_TEXT_VISUEL_CAROUSEL":
      return {
        ...state,
        visuels: state.visuels.map((visuel) => {
          if (visuel.id === action.payload.id) {
            return {
              ...visuel,
              [action.payload.name]: action.payload.value,
            };
          } else return visuel;
        }),
      };
    case "FILTER_IMAGES_CAROUSEL":
      return {
        ...state,
        image: {
          ...state.image,
          // eslint-disable-next-line
          filteredImages: action.payload
            ? // eslint-disable-next-line
              state.image.images.filter((img) => {
                if (img.name) {
                  return img.name
                    .toLowerCase()
                    .includes(action.payload.toLowerCase());
                }
              })
            : state.image.images,
        },
      };
    case "FILTER_VIDEOS_CAROUSEL":
      return {
        ...state,
        video: {
          ...state.video,
          // eslint-disable-next-line
          filteredVideos: action.payload
            ? // eslint-disable-next-line
              state.video.videos.filter((vid) => {
                if (vid.title) {
                  return vid.title
                    .toLowerCase()
                    .includes(action.payload.toLowerCase());
                }
              })
            : state.video.videos,
        },
      };
    case "SET_END_INDEX_PAGINATION_IMAGES_CAROUSEL":
      return {
        ...state,
        image: {
          ...state.image,
          pagination: {
            ...state.image.pagination,
            endIndex: parseInt(action.payload),
            startIndex: parseInt(action.payload) - state.image.pagination.count,
          },
        },
      };
    case "SET_END_INDEX_PAGINATION_VIDEOS_CAROUSEL":
      return {
        ...state,
        video: {
          ...state.video,
          pagination: {
            ...state.video.pagination,
            endIndex: parseInt(action.payload),
            startIndex: parseInt(action.payload) - state.video.pagination.count,
          },
        },
      };
    case "DELETE_VISUEL_CAROUSEL":
      return {
        ...state,
        visuels: state.visuels.filter((visuel) => visuel.id !== action.payload),
      };
    case "ADD_VISUEL_CAROUSEL":
      const { titre, lien, cta, description: desc } = action.contenuParDefaut;
      return {
        ...state,
        visuels: [
          ...state.visuels,
          {
            ...action.payload,
            titre: titre ? titre.content : "",
            description: desc ? desc.content : "",
            lien: lien ? lien.content : "",
            callToAction: cta ? cta : "",
          },
        ],
      };
    case "SHOW_SELECTED_IMAGES_FIRST_CAROUSEL":
      const selectedImages = state.image.filteredImages.filter(
        (img) => img.isSelected
      );
      const notSelectedImages = state.image.filteredImages.filter(
        (img) => !img.isSelected
      );

      return {
        ...state,
        image: {
          ...state.image,
          filteredImages: [...selectedImages, ...notSelectedImages],
        },
      };
    case "TOGGLE_SELECTED_IMAGE_CAROUSEL":
      return {
        ...state,
        image: {
          ...state.image,
          filteredImages: state.image.filteredImages.map((img) => {
            if (img.id === action.payload) {
              return { ...img, isSelected: !img.isSelected };
            } else return img;
          }),
          images: state.image.images.map((img) => {
            if (img.id === action.payload) {
              return { ...img, isSelected: !img.isSelected };
            } else return img;
          }),
        },
      };

    case "SHOW_SELECTED_VIDEOS_FIRST_CAROUSEL":
      const selectedVideos = state.video.filteredVideos.filter(
        (img) => img.isSelected
      );
      const notSelectedVideos = state.video.filteredVideos.filter(
        (img) => !img.isSelected
      );

      return {
        ...state,
        video: {
          ...state.video,
          filteredVideos: [...selectedVideos, ...notSelectedVideos],
        },
      };
    case "TOGGLE_SELECTED_VIDEO_CAROUSEL":
      return {
        ...state,
        video: {
          ...state.video,
          filteredVideos: state.video.filteredVideos.map((vid) => {
            if (vid.id === action.payload) {
              return { ...vid, isSelected: !vid.isSelected };
            } else return vid;
          }),
          videos: state.video.videos.map((vid) => {
            if (vid.id === action.payload) {
              return { ...vid, isSelected: !vid.isSelected };
            } else return vid;
          }),
        },
      };
    case "GET_IMAGES_CAROUSEL_SECTION":
      return {
        ...state,
        image: {
          ...state.image,
          images: sortByKeywords(action.payload),
          filteredImages: sortByKeywords(action.payload),
        },
      };
    case "GET_VIDEOS_CAROUSEL_SECTION":
      return {
        ...state,
        video: {
          ...state.video,
          videos: sortByKeywords(action.payload),
          filteredVideos: sortByKeywords(action.payload),
        },
      };
    default:
      return state;
  }
};
