import React from "react";

import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "./style.css";
import { toast } from "react-toastify";
import axiosInstance from "../../store/axios.instance";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const CarouselItem = ({ carousel, getCarousels }) => {
  const { name, id: carouselId, carousel_cards, isSelected } = carousel;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { adaccountId } = useSelector(state => state.auth.user);

  const deleteCarousel = () => {
    if (window.confirm("Voulez-vous vraiment supprimer ce carousel ?")) {
      axiosInstance
        .delete(`/${adaccountId}/AdCarousels/${carouselId}`)
        .then(() => {
          setTimeout(() => {
            toast.success("Carousel supprimé !");
            getCarousels();
          }, 1000);
        })
        .catch(err => {
          dispatch({
            type: "SET_ALERT_GENERAL_ERROR_MESSAGE",
            payload: err.message,
          });
          dispatch({ type: "SHOW_ALERT_GENERAL_ERROR_MESSAGE" });
        });
    }
  };

  const updateCarousel = () => {
    dispatch({ type: "SET_CAROUSEL_TO_UPDATE", payload: carousel });
    navigate(`/modification-carousel/${carouselId}`);
  };

  const handleChangeUpdateName = e => {
    dispatch({
      type: "CHANGE_NAME_CAROUSEL_ITEM",
      payload: {
        id: carouselId,
        value: e.target.value,
      },
    });
  };

  return (
    <div className='single_contenu_apercu_carousel'>
      <div className='facebook_screenshot screenshot_carousel'>
        <header className={`entete_fcb ${isSelected ? "selected" : ""}`}>
          {/* <h4 className='entete_title'>{name}</h4> */}
          <input type='text' value={name} onChange={handleChangeUpdateName} />
          <div className='buttons'>
            <i
              onClick={updateCarousel}
              style={{ marginRight: 10 }}
              className='far fa-edit'
            ></i>
            {/* <img
              src='/close_icon.png'
              onClick={deleteCarousel}
              alt='Supprimer le carousel'
            /> */}
            <i onClick={deleteCarousel} className='fas fa-times'></i>
          </div>
        </header>
        <div className='liste_ressources_screenshot_facebook'>
          <Swiper
            slidesPerView='auto'
            spaceBetween={10}
            // modules={[Pagination]}
            // pagination={{ clickable: true }}
          >
            {carousel_cards.length > 0 &&
              carousel_cards.map(carousel => (
                <SwiperSlide>
                  <div className='ressource_image_item'>
                    {carousel.url ? (
                      <img
                        className='main_img'
                        src={carousel.url}
                        alt='Screenshot'
                      />
                    ) : (
                      <video
                        src={`${carousel.source}#t=1`}
                        className='main_video'
                      ></video>
                    )}

                    <div className='text'>
                      <h5>{carousel.name}</h5>
                      {carousel.call_to_action && (
                        <a
                          rel='noopener noreferrer'
                          target='_blank'
                          href={carousel.link}
                        >
                          {carousel.call_to_action.replace("_", " ")}
                        </a>
                      )}
                    </div>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
        <footer className='screenshot_footer'>
          <div className='screenshot_footer_text'>
            <p className='screenshot_footer_text_title'>
              {/* {titre.length > 55 ? titre.substring(0, 50) + " ..." : titre} */}
            </p>
          </div>
          <div className='screenshot_footer_cta'>
            {/* <button>{boutonChoisi}</button> */}
          </div>
        </footer>
        <div className='interactions'>
          <p>
            <i className='far fa-thumbs-up'></i>
            J'aime
          </p>
          <p>
            <i className='far fa-comment-alt'></i>
            Commenter
          </p>
          <p>
            <i className='fas fa-share'></i>
            Partager
          </p>
        </div>
      </div>
    </div>
  );
};

export default CarouselItem;
