import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import "./style.css";

const RenderTagDict = ({ value, option, onChange, editTag }) => {
  return (
    <div className='render_tags_option'>
      <p>{option.name}</p>
      <span
        className='circle'
        onClick={() => editTag(option)}
      >
        <EditIcon fontSize='30' />
      </span>
      <span className='circle' onClick={()=>onChange(null,
        value.filter(item=>item.id!==option.id))}>
        <CloseIcon fontSize='30' />
      </span>
    </div>
  );
};
export default RenderTagDict;
