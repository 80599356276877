import React from "react";
import "./style.css";
import { useSelector } from "react-redux";
import LeftMenu from "../../components/LeftMenu/LeftMenu";
import { useNavigate } from "react-router-dom";
import CentreInteret from "./CentreInteret";
import TopRightMenu from "../../components/TopRightMenu/TopRightMenu";
import { FormattedMessage } from "react-intl";
import RightContainer from "../../components/RightContainer/RightContainer";
import { getSafeLastPage, setToggleLeftMenu, forgetLastPage} from "../../store/actions/utils/usualDispatches";
import { useDispatch } from "react-redux";

const CreationPackageCentreInteret = ({ updateMode, mainTitle }) => {
  // eslint-disable-next-line
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const { toggleLeftMenu, lastPage } = useSelector((state) => state.utils);
  const { file_configuration_id } = useSelector((state) => state.creationCampagne);

  return (
    <div className="screen_creation_package_centre_interet">
      <LeftMenu />
      <RightContainer>
        <header className="right_content_header">
          <div className="back_title">
            {toggleLeftMenu ? (
              <i
                onClick={() => dispatch(setToggleLeftMenu((prevState) => !prevState))}
                style={{ marginRight: 30, cursor: "pointer" }}
                className="fas fa-bars fa-2x"
              ></i>
            ) : null}
            <div className="back" onClick={() =>{
                dispatch({type:"SET_STATE_PROTECT_DETAILLED_ADSETS",payload:true});
                let goTo = getSafeLastPage(lastPage, file_configuration_id);
                dispatch(forgetLastPage());
                navigate(goTo);
                }
              }>
              <img src="/left-arrow 2.png" alt="Retour" />
            </div>
            <h1 className="right_content_header_title">
              {!updateMode ? (
                <FormattedMessage
                  defaultMessage="Créer un package centre d'intérêt"
                  id="screen_creation_package_centre_interet_1"
                />
              ) : (
                mainTitle
              )}
            </h1>
          </div>
          <div className="client">
            {/* <img src='/logo-laduree.png' alt='client' /> */}

            {/* <img src='/verified.png' alt='' /> */}
            <TopRightMenu />
          </div>
        </header>
        <div className="right_content_main">
          <div className="sections">
            <CentreInteret
              updateMode={updateMode}
            />
          </div>
        </div>
      </RightContainer>
    </div>
  );
};

export default CreationPackageCentreInteret;
