import React, { useEffect, useRef, useState } from "react";
import store from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import ListSubheader from "@mui/material/ListSubheader";
import axiosInstance from "../../../store/axios.instance";
import { toast } from "react-toastify";
import { FormattedMessage, useIntl } from "react-intl";
import Datetime from "react-datetime";
import { getPixelEvents } from "../../../store/actions/utils/campaignCreation";
import moment from "moment";
import { getVariablesReference } from "../../../store/actions/utils/campaignCreation";
import GenericSection from "./genericSection";

import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import "react-datetime/css/react-datetime.css";
import axios from "axios";
import BaseAlertMessage from "../../../components/AlertMessages/BaseAlertMessage";
import { Stroller } from "@mui/icons-material";


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CurrencyContainerSection = () => {
  const currencies = [
    {
      value: "USD",
      label: "$",
    },
    {
      value: "EUR",
      label: "€",
    },
    {
      value: "GBP",
      label: "£"
    },
    {
      value: "BTC",
      label: "฿",
    },
    {
      value: "JPY",
      label: "¥",
    },
  ];
  return (
  //The currency should be found in the current adaccount
  <div className="container_currency_input">
    <TextField
      style={{ width: 60 }}
      id="outlined-select-currency"
      select
      defaultValue="EUR"
      disabled={true}
    >
      {currencies.map((option) => (
        <MenuItem
          style={{ marginRight: 10 }}
          key={option.value}
          value={option.value}
        >
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  </div>
);}

const InformationsGenerales = ({
  advancedMode,//necessary to know if we want to set attributio
  setAdvancedMode, //want to set to try with certain types
  disabledMode, //use it for update-ads where all is protected but can still look at the fields
}) => {
  
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const [toggle, setToggle] = useState(false);
  const [filteredPixels, setFilteredPixels] = useState([]);
  const [filteredApplications, setFilteredApplications] = useState([]);
  const [filteredAttributions, setFilteredAttributions] = useState([]);
  const [filteredPixelEvents, setFilteredPixelEvents] = useState([])
  const [disablePixel, setDisablePixel] = useState(false);
  const [allConfigs, setAllConfigs] = useState([]);
  const [showAlertType, setShowAlertType] = useState(false)
  const [showAlertBudget, setShowAlertBudget] = useState(false)
  const [alertEvent, setAlertEvent] = useState(null)
  const [errorEndDate, setErrorEndDate]= useState(false);
  const intl = useIntl();

  const { adaccountId } = useSelector((state) => state.auth.user);
  const {
    formattedCampaignTypes,
    pixelEvents,
    pixels,
    attributions, 
    catalogs,
    adAccountConfiguredCustomers
  } = useSelector((state) => state.creationCampagneRef);

  //TODO renaming and improving that
  const { isEmptyName, isEmptyType, isPexelRequierd } =
    useSelector((state) => state.validation);

  const {
    nomCampagne,
    type,
    dateDebut,
    dateFin,
    budget,
    budget_mode,
    tag,
    objectif,
    pixel,
    application,
    product_catalog_id,
    attribution_spec
  } = useSelector((state) => state.creationCampagne.informationsGenerales);
  const audiencesAndInterests = useRef();
  audiencesAndInterests.current = useSelector((state) => state.creationCampagne.audiencesAndInterests)
  const campaign_id = useSelector((state) => state.creationCampagne.campaign_id); 
  const advantagePlus = useSelector((state) => state.creationCampagne.advantagePlus);
  const budgetTimer = useRef(null);
  const [inputBudget, setInputBudget] = useState(budget);
  
  const changeBudgetMode = (e)=> {
    dispatch({
      type: "HANDLE_CHANGE_INFO_GENERALE",
      payload: {name:"budget_mode", "value": e.target.value},
    });
  };

  function fillpixelsandapplications(pixels, allowed_applications=true, allowed_pixels=true){
    let pixelsToShow = [];
    let applicationsToShow = [];
    if (advantagePlus){
      if (allowed_pixels && "pixels" in pixels){
        pixelsToShow = pixels["pixels"].map(item=>({...item, kind:"Pixel"}));
      }
      if (allowed_applications && "applications" in pixels){
        applicationsToShow = pixels["applications"].map(item=>({...item, kind:"Application"}));
      }
    }
    else {
      if (allowed_pixels && "pixels" in pixels){
        pixelsToShow.push(...pixels["pixels"].map(item=>({...item, kind:"Pixel"})));
      }
      if (allowed_applications && "applications" in pixels){
        pixelsToShow.push(...pixels["applications"].map(item=>({...item, kind:"Application"})));
      }
    }
    setFilteredPixels(pixelsToShow);
    setFilteredApplications(applicationsToShow);
  }
  //to rewrite all of that
  const getConfigs = (attributeName="optimization_goal", attributeValue) => {
    // optimization_goal OFFSITE_CONVERSIONS
    axiosInstance
      .get(
        `/configuration_with_attributes?attribute_name=${attributeName}&attribute_value=${attributeValue}`
      )
      .then((res) => {
        let configs = res.data
        setAllConfigs(configs);
      })
      .catch(() => {
        // toast.error("Une erreur s'est produite, veuillez réessayer plus tard");
        // setLoader(false);
      });
  };

  const filterPixels = () => {
    let allowed_applications = false
    let allowed_pixels = false
    let mandatory_pixel = false
    let mandatory_application = false
    if (allConfigs) {
      //not really accurate for some instances where there are multiple lines
      // for example leads (maybe we consider only on facebook ?)
      // or if for the same objective i have multiple lines 
      //one with promoted object and the other not
      //btw here we can have something with promoted_object abset that puts true to everything
      // then another line with page_id 
      allConfigs.forEach(row =>{
        if('promoted_object' in row){
            if (row['promoted_object'].includes("application_id")){
              if(type === "ADVANTAGE_APP"){
                mandatory_application = true;
              }
              else {
                mandatory_pixel = true
              }
              allowed_applications=true;
            }
            if (row['promoted_object'].includes("pixel_id")){
              mandatory_pixel = true
              allowed_pixels=true;
            }
          }
        else {
          allowed_applications = true;
          allowed_pixels = true;
        }
      });
      fillpixelsandapplications(
        pixels, allowed_applications, allowed_pixels);
    }
    else {
      fillpixelsandapplications(pixels);
    }
    if(mandatory_pixel){
      dispatch({type:"ADD_MANDATORY_FIELD", payload:"pixel"})
    }
    else{
      dispatch({type:"REMOVE_MANDATORY_FIELD", payload:"pixel"})
    }
    if(mandatory_application){
      dispatch({type:"ADD_MANDATORY_FIELD", payload:"application"})
    }
    else{
      dispatch({type:"REMOVE_MANDATORY_FIELD", payload:"application"})
    }
  }

  const filterAttributionsPixelEvents = () => {
    if(pixel){
      let pixel_type = "pixel_id"
      if (pixels['applications'].some(item=> item.id == pixel.id)){
        pixel_type = "application_id"
      }
      let allowed_attributions = new Set();
      let allowed_objectives = new Set();
      if (allConfigs){
        allConfigs.forEach((config)=>{
          if (("promoted_object" in config && config["promoted_object"].includes(pixel_type))|| !("promoted_object" in config)){
            if ('attribution_spec' in config){
              config['attribution_spec'].forEach((attribution)=>allowed_attributions.add(JSON.stringify(attribution)));
            }
            else {

              Object.keys(attributions).forEach((attribution) => allowed_attributions.add(JSON.stringify(attributions[attribution])));
            }
            if ('custom_event_type' in config){
              config['custom_event_type'].forEach((event_type)=>allowed_objectives.add(event_type));
            }
            else {
              pixelEvents.forEach((event)=>allowed_objectives.add(event['custom_event_type']));
            }
          }
        })
      }
      else {
        Object.keys(attributions).forEach((attribution) => allowed_attributions.add(JSON.stringify(attributions[attribution])));
        pixelEvents.forEach((event)=>allowed_objectives.add(event['custom_event_type']));
      }
      let selectedAttributions = []
      Object.keys(attributions).forEach((attribution) => {
        if (allowed_attributions.has(JSON.stringify(attributions[attribution]))){
          selectedAttributions.push({"name":attribution, "value":attributions[attribution]});
        }
      });
      setFilteredAttributions(selectedAttributions);
      let selectedEvents = []
      pixelEvents.forEach((event) => {
        if (allowed_objectives.has(event['custom_event_type'])){
          selectedEvents.push(event);
        }
      });
      setFilteredPixelEvents(selectedEvents);
    }
  }

  const proceedAlertBudget = (result, e) => {
    if (result){
      dispatch({ type: "SET_BUDGET_ADSETS_ZERO" });
      dispatch({
        type: "HANDLE_CHANGE_INFO_GENERALE",
        payload: { name: e.target.name, value: parseFloat(e.target.value.replace(",","."))},
      })
    }
    setShowAlertBudget(false);
    setAlertEvent(null)
  };

  function proceedAlertType(result, e){
    if (result) {
      dispatch({
        type: "HANDLE_CHANGE_INFO_GENERALE",
        payload: { name: e.target.name, value: e.target.value },
      });
      dispatch({ type: "CLEAR_DATA_CAMPAGNE_ADVANTAGE" });
      dispatch({ type: "ENABLE_ADVANTAGE_PLUS" });
    }
    setShowAlertType(false);
    setAlertEvent(null);
  }

  const handleChangeType = (e) => {
    dispatch({type:"SET_PIXEL_VAL", payload:""})
    dispatch({
      type: "HANDLE_CHANGE_INFO_GENERALE",
      payload: { name: "objectif", value: "" },
    });
    dispatch({
      type: "HANDLE_CHANGE_INFO_GENERALE",
      payload: { name: "attribution_spec", value: "" },
    });
    if (e.target.value === "APP_INSTALLS") {
      setAdvancedMode(true);
      //force targeting mobiles
      dispatch({
        type: "HANDLE_CHANGE_CONFIGURATION",
        payload: {
          name: "devices",
          value: [{ name: "mobile", value: "mobile" }],
        },
      });
    } 
    else if (type === "APP_INSTALLS"){
      //if last type was APP_INSTALLS reset devices, probably should also reset user_os but w/e
      dispatch({
        type: "HANDLE_CHANGE_CONFIGURATION",
        payload: {
          name: "devices",
          value: [],
        },
      });
    }
    const advantage = ["ADVANTAGE_VALUE", "ADVANTAGE_APP"];
    
    if (
      advantage.includes(e.target.value) && !advantage.includes(type)
    ) {
      setAlertEvent(e)
      setShowAlertType(true)
    } else {
      if (advantage.includes(type)){
        dispatch({ type: "DISABLE_ADVANTAGE_PLUS" });
        dispatch({
          type: "HANDLE_CHANGE_CIBLAGE_LARGE",
          payload: false,
        });
      }
      dispatch({
        type: "HANDLE_CHANGE_INFO_GENERALE",
        payload: { name: e.target.name, value: e.target.value },
      });
    }
  }

  const handleChangeAutoComplete = (name, value) => {
    if (["application", "pixel"].includes(name)){
      dispatch({
        type: "HANDLE_CHANGE_INFO_GENERALE",
        payload: { name: "objectif", value: "" },
      });
    }
    dispatch({
      type: "HANDLE_CHANGE_INFO_GENERALE",
      payload: { name: name, value: value },
    });
  }

  const handleChangeBudget = (e)=>{
    clearTimeout(budgetTimer.current)
    let totalBudget = 0;
    if (audiencesAndInterests.current.length > 0) {
      totalBudget = audiencesAndInterests.current.reduce(
        (acc, adset) => acc + adset.budget,
        0
      );
    }
    if (totalBudget > 0) {
      setAlertEvent(e)
      setShowAlertBudget(true)
    }
    else {
      const currentBudget = parseFloat(e.target.value.replace(",","."))
      budgetTimer.current = setTimeout(()=>{
        if (currentBudget){ 
          dispatch({
            type: "HANDLE_CHANGE_INFO_GENERALE",
            payload: { name: "budget", value: currentBudget }});
          //reset the other budget because they can't be >0 at the same time
        }
        else{
          toast.error("Budget défini invalide");
        }
      }, 1000);
    }
  }

  const handleChange = (e) => {
    dispatch({
      type: "HANDLE_CHANGE_INFO_GENERALE",
      payload: { name: e.target.name, value: e.target.value },
    });
  };

  const handleChangeDateDebut = (val) => {
    const newDate = val ? val : "";
    if (!newDate) {
      dispatch({ type: "REMOVE_ALL_DATES_ADSETS_DETAILLE" });
    }
    dispatch({
      type: "HANDLE_CHANGE_INFO_GENERALE",
      payload: { name: "dateDebut", value: newDate },
    });
  };

  const handleChangeDateFin = (val) => {
    const newDate = val ? val : "";

    if (!newDate) {
      dispatch({ type: "REMOVE_ALL_DATES_ADSETS_DETAILLE" });
    }

    if (newDate && dateDebut) {
      if (newDate < dateDebut) {
        setErrorEndDate(true)
        toast.warning("La date de fin doit être supérieure a la date actuelle");
        return;
      } else {
        setErrorEndDate(false)
      }
    }
    dispatch({
      type: "HANDLE_CHANGE_INFO_GENERALE",
      payload: { name: "dateFin", value: newDate },
    });
  };
  
  const {
    userData: { token: accessToken },
  } = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (!disabledMode) {
      getVariablesReference(dispatch);
    }
  }, []);

  useEffect(() => {
    if (type) {
      //todo here change mandatory_fields
      getConfigs(undefined, type);
    }
    type === "ADVANTAGE_APP" ? setDisablePixel(true) : setDisablePixel(false);
  }, [type]);

  useEffect(() => {
    setInputBudget(budget)
  }, [budget])

  useEffect(() => {
    filterPixels();
  }, [allConfigs, pixels])

  useEffect(() => {
    if (pixel){
      getPixelEvents(dispatch, adaccountId, pixel.id);
      dispatch({type:"ADD_MANDATORY_FIELD", payload:"objectif"});
      if(pixel["kind"]==="Application"){
        dispatch({type:"ADD_MANDATORY_FIELD", payload:"useros"});
      }
    }
    else{
      dispatch({type:"REMOVE_MANDATORY_FIELD", payload:"objectif"});
      dispatch({type:"REMOVE_MANDATORY_FIELD", payload:"useros"});
    }
  }, [pixel]);

  useEffect(()=> {
    if (pixelEvents){
      filterAttributionsPixelEvents();
    }
  }, [pixelEvents])

  const renderTypesSelectGroup = (formattedTypes) => {
    const items = formattedTypes.options.map((o) => {
      return (
        <MenuItem key={o.value} value={o.value}>
          {o.label}
        </MenuItem>
      );
    });
    return [<ListSubheader>{formattedTypes.type}</ListSubheader>, items];
  };

  const notInPast = (current) => {
    return current.isAfter(moment().subtract(1, "day"));
  };

  function renderView(mode, renderDefault, type) {
    if (mode === "time") return renderDefault();

    return (
      <div className="wrapper">
        {renderDefault()}
        <div
          className="controls"
          style={{
            width: "100%",
            padding: "10px",
            color: "red",
            textAlign: "end",
          }}
        >
          <button
            type="button"
            onClick={() => goToToday(type)}
            style={{
              padding: "10px",
              color: "#007aff",
            }}
          >
            <FormattedMessage
              defaultMessage="Aujourd'hui"
              id="create_campaign_infos_cal_btn"
            />
          </button>
        </div>
      </div>
    );
  }

  const goToToday = (type) => {
    const today = moment();
    type === "start" && handleChangeDateDebut(today);
    type === "end" && handleChangeDateFin(today);
  };

  const dateStartInputElement = useRef(null);
  const dateEndInputElement = useRef(null);

  const DateInputSection = (
    {formattedName, defaultPlaceholder, 
    varRef, name, dateVariable,handleChange, 
    validator, dispatchMessage} ) => (
    <div
    //this condition is weird
    //className={`input_group ${
    //  lifetime ? dateDebut === "" && "inputError" : ""
    //}`}
    className={"input_group"}
    style={{ marginBottom: 0 }}
    >
      <label htmlFor="">
        {formattedName}
      </label>
      <div
        style={{
          width: "100%",
          display: "flex",
          cursor: "pointer",
        }}
      >
        <Datetime
          inputProps={{
            placeholder: defaultPlaceholder,
          }}
          ref={varRef}
          value={dateVariable}
          name={name}
          onChange={handleChange}
          isValidDate={validator}
          utc={true}
          disabled={campaign_id.length>0}
          renderView={(mode, renderDefault) =>
            renderView(mode, renderDefault, "start")
          }
          closeOnSelect
        />
        <i
          onClick={(e) => {
            dispatch({ type: dispatchMessage});
            dispatch({
              type: "REMOVE_ALL_DATES_ADSETS_DETAILLE",
            });
            //this one doesn't work with end
            dateStartInputElement.current.state.inputValue = "";
          }}
          className="fas fa-times"
          style={{marginTop: "8px", position:"relative", right: "10px", fontSize:"14px"}}
          // width={10}
          // height={10}
          // alt=""
          ></i>
      </div>
    </div>
  )

  const PixelSection = () => (
    <div className={`input_group`}>
      <label htmlFor="" style={{ marginBottom: 0 }}>
        Pixel
      </label>
        <FormControl fullWidth>
          <Autocomplete
            name="pixel"
            id="autocomplete-standard"
            onChange={(e, value) => handleChangeAutoComplete("pixel", value)}
            value={pixel}
            groupBy={(option) => option.kind}
            options={filteredPixels}
            disabled={filteredPixels.length===0||Boolean(campaign_id)}
            style={{
              borderBottom: isPexelRequierd && "1px solid red",
            }}
            getOptionLabel={(option) => option.name||""}
            isOptionEqualToValue={(option,value) => option.id === value.id}
            renderInput={(params) => (
              <TextField variant="standard" {...params} />
            )}
            //renderOption={renderPixelsSelectGroup}
          />
        </FormControl>
      <span className="messageError">
        {isPexelRequierd && (
          <FormattedMessage
            defaultMessage="Pixel obligatoire"
            id="create_campaign_infos_generales_pixel_error"
          />
        )}
      </span>
    </div>
  )

  const AdvantageCustomerBudgetSection = () =>{
    const [input, setInput] = useState("")
    
    const existing_customer_budget_percentage = useSelector(
      (state) => state.creationCampagne.informationsGenerales.existing_customer_budget_percentage);

    const timer = useRef(null);
  
    const handleChangeCustomerBudgetPercentage = (e) => {
      clearTimeout(timer.current) 
      const percentage = parseInt(e.target.value)
      timer.current = setTimeout(()=>{
        if (
            !isNaN(percentage) &&
            percentage >= 0 &&
            percentage <= 100
          ) {
            dispatch({
              type: "HANDLE_CHANGE_INFO_GENERALE",
              payload: { name: e.target.name, value: percentage },
            });
        } else {
            toast.warning("La valeur doit être comprise entre 0 et 100");
            setInput(existing_customer_budget_percentage)
        }
      }, 500)
    } 

    useEffect(() => {
      setInput(existing_customer_budget_percentage)
    }, [existing_customer_budget_percentage])
    return(
    <div className="row">
    <div
      className={`input_group ${!isEmptyName && "inputError"}`}
      style={{ marginBottom: 0 }}
    >
      <label style={{ marginBottom: 0 }} htmlFor="">
        <FormattedMessage
          defaultMessage="% budget maximum alloué aux clients existants"
          id="create_campaign_infos_generales_customer_budget_percentage"
        />
      </label>
      <input
        name="existing_customer_budget_percentage"
        type="text"
        inputMode="decimal"
        onChange={handleChangeCustomerBudgetPercentage}
        onInput={(e)=>setInput(e.target.value)}
        value={input}
      />
    </div>
    <div className="input_group">
      <label style={{ marginBottom: 0 }} htmlFor="">
        <FormattedMessage
          defaultMessage="Audience clients existants"
          id="create_campaign_infos_generales_customer_budget"
        />
      </label>
      {adAccountConfiguredCustomers &&
        adAccountConfiguredCustomers.map((ecbp) => (
          <div
            key={ecbp._id}
            style={{
              padding: "0.5rem 1rem",
              margin: "4px 0",
              background: "#F5F5F5",
              borderRadius: "5px",
            }}
          >
            {" "}
            {ecbp.name}
          </div>
        ))}
      </div>
    </div>
  )}

  function CountriesLanguagesSection () {
    const [reqCountries, setCountries] = useState([]);
    const [langues, setLangues] = useState([]);
    const countries = useSelector(
      (state) => state.creationCampagne.informationsGenerales.countries);
    const languesChoisies = useSelector(
      (state) => state.creationCampagne.informationsGenerales.languesChoisies);

    const getCountries = (query) => {
      axios
        .get(
          `${process.env.REACT_APP_GRAPH_API}/search?access_token=${accessToken}&location_types=["country"]&type=adgeolocation&q=${query}`
        )
        .then((result) => {
          setCountries(result.data.data);
        })
        .catch((err) => console.log(err));
    };
  
    const onInputChangeCountries = (event, newValue) => {
      if (newValue.length > 0) {
        getCountries(newValue);
      }
    };
  
    const handleChangeCountries = (e, newValue) => {
      dispatch({
        type: "HANDLE_CHANGE_INFO_COUNTRIES",
        payload: newValue,
      });
    };
  
    const getLangues = (query) => {
      axios
        .get(
          `${process.env.REACT_APP_GRAPH_API}/search?access_token=${accessToken}&type=adlocale&q=${query}`
        )
        .then((result) => {
          setLangues(result.data.data);
        })
        .catch((err) => console.log(err));
    };
  
    const onInputChangeLangues = (event, newValue) => {
      if (newValue.length > 0) {
        getLangues(newValue);
      }
    };
  
    const onChangeLangues = (event, newValue) => {
      dispatch({
        type: "HANDLE_CHANGE_INFO_LANGUAGE",
        payload: newValue,
      });
    };
  
    return (
      <div className="row">
        <div className={`input_group objectif`}>
          <label htmlFor="">
            <FormattedMessage
              defaultMessage="Pays"
              id="screen_creation_package_geo_demo_14"
            />
          </label>
          <Autocomplete
            value={countries}
            noOptionsText="trouver des pays"
            multiple
            onChange={handleChangeCountries}
            onInputChange={onInputChangeCountries}
            id="checkboxes-tags-demo"
            options={reqCountries}
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.key === value.key}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </li>
            )}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField variant="standard" {...params} />
            )}
          />
        </div>
        <div className="input_group objectif">
          <label htmlFor="">
            <FormattedMessage
              defaultMessage="Langues"
              id="screen_creation_package_geo_demo_10"
            />
          </label>
          <Autocomplete
            value={languesChoisies}
            // noOptionsText='trouver des langues'
            multiple
            onChange={onChangeLangues}
            onInputChange={onInputChangeLangues}
            id="checkboxes-tags-demo"
            options={langues}
            disabled={type === "ADVANTAGE_VALUE"}
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.key === option.value}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </li>
            )}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                placeholder={intl.formatMessage({
                  defaultMessage: "Toutes les langues",
                  id: "zones_geo_all_languages_WqA123",
                })}
                variant="standard"
                {...params}
              />
            )}
          />
        </div>
      </div>);
  }

  return (
    <GenericSection
      disabledMode={disabledMode}
      toggle={toggle}
      setToggle={setToggle}
      title={
        <FormattedMessage
        defaultMessage="Informations Générales"
        id="create_campaign_infos_generales_title"
        />}
      test_validation={
        (nomCampagne && type) ||
        (nomCampagne && type === "APP_INSTALLS" && pixel)}
        content={
          <form action="">
            {showAlertType && (
              <BaseAlertMessage 
                message={
                  `Attention, choisir un type de campagne Advantage +
                   réinitialise vos paramètres incompatibles avec ce type de campagne.

                   Voulez-vous continuer ?`}
                event={alertEvent}
                callback={proceedAlertType}
                />
            )}
            {showAlertBudget && (
              <BaseAlertMessage 
                message={
                  `Attention, en définissant un budget au niveau de la campagne
                   vos budgets par adsets seront supprimés.

                   Voulez-vous continuer ?`}
                event={alertEvent}
                callback={proceedAlertBudget}
                />
            )}
            <div className="row">
              <div style={{ flexDirection: "column", alignItems: "start" }}>
                <div
                  className={`input_group ${!isEmptyName && "inputError"}`}
                  style={{ marginBottom: 0 }}
                >
                  <label style={{ marginBottom: 0 }} htmlFor="">
                    <FormattedMessage
                      defaultMessage="Nom de campagne"
                      id="create_campaign_infos_generales_name_campaign"
                    />
                  </label>
                  <input
                    value={nomCampagne}
                    name="nomCampagne"
                    onChange={handleChange}
                    //onBlur={(e) => saveSingleCompaign(e.target.value)}
                    type="text"
                  />
                </div>
                {!isEmptyName && (
                  <span className="messageError">
                    <FormattedMessage
                      defaultMessage="Nom de campagne obligatoire"
                      id="create_campaign_infos_generales_name_campaign_error"
                    />
                  </span>
                )}
              </div>
              <div style={{ flexDirection: "column", alignItems: "start" }}>
                <div
                  className={`input_group ${!isEmptyType && "inputError"}`}
                  style={{ marginBottom: 0 }}
                >
                  <label htmlFor="">Type</label>
                  {/* <input name='type' onChange={handleChange} type='text' /> */}
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={handleChangeType}
                      variant="standard"
                      name="type"
                      value={type}
                      disabled={campaign_id.length>0}
                    >
                      {formattedCampaignTypes.map((d) => renderTypesSelectGroup(d))}
                    </Select>
                  </FormControl>
                </div>
                <span className="messageError">
                  {!isEmptyType && (
                    <FormattedMessage
                      defaultMessage="Type obligatoire"
                      id="create_campaign_infos_generales_type_error"
                    />
                  )}
                </span>
              </div>
            </div>
            <div className="date_budget">
              <div className="left">
                <div style={{ flexDirection: "column", alignItems: "start" }}>
                  <DateInputSection
                    formattedName={<FormattedMessage
                                      defaultMessage="Date de début"
                                      id="create_campaign_infos_generales_date_debut"/>}
                    defaultPlaceholder={intl.formatMessage({
                                        defaultMessage: "Maintenant",
                                        id: "create_campaign_infos_generales_date_debut_placeholder",
                                        })}
                    varRef={dateStartInputElement}
                    name="dateDebut"
                    dateVariable={dateDebut}
                    handleChange={handleChangeDateDebut}
                    validator={notInPast}
                    dispatchMessage="REMOVE_START_DATE"
                                      />
                  {/*Only for end date.
                  budget_mode === "lifetime"
                    ? dateDebut === "" && (
                        <span className="messageError">
                          <FormattedMessage
                            defaultMessage="Date de début obligatoire"
                            id="create_campaign_infos_generales_date_de_début_error"
                          />
                        </span>
                      )
                    : ""*/}
                </div>
                <div style={{ flexDirection: "column", alignItems: "start" }}>
                  <DateInputSection
                      formattedName={<FormattedMessage
                                    defaultMessage="Date de fin"
                                    id="create_campaign_infos_generales_date_fin"
                                    />}
                      defaultPlaceholder={intl.formatMessage({
                                          defaultMessage: "Continu",
                                          id: "create_campaign_infos_generales_date_fin_placeholder",
                                        })}
                      varRef={dateEndInputElement}
                      name="dateFin"
                      dateVariable={dateFin}
                      handleChange={handleChangeDateFin}
                      validator={notInPast}
                      dispatchMessage="REMOVE_END_DATE"
                    />
                    {budget_mode === "lifetime" && (
                      (dateFin === "" || errorEndDate) && (
                        <span className="messageError">
                          {errorEndDate ? (
                            <FormattedMessage
                              defaultMessage={`La date de fin doit être supérieure a la date actuelle`}
                              id="create_campaign_infos_generales_date_de_fin_error_invalid"
                            />
                          ) : (
                            <FormattedMessage
                              defaultMessage={`Date de fin obligatoire`}
                              id="create_campaign_infos_generales_date_de_fin_error"
                            />
                          )}
                        </span>
                      )
                    )}
                </div>
              </div>
              <div className="right">
                <p>Budget</p>
                <div className="row">
                  {/*<div className="input_group">
                    <label htmlFor="">Lifetime</label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                        paddingBottom: 5,
                        borderBottom: "1px solid rgba(0, 0, 0, 0.4)",
                      }}
                    >
                      <CurrencyContainerSection />
                      <input
                        style={{ borderBottom: "none" }}
                        name="lifetime"
                        type="text"
                        inputMode="decimal"
                        className="input_currency"
                        disabled={Boolean(daily) && campaign_id.length>0}
                        onInput={(e)=>setLifeTimeBudget(e.target.value)}
                        onChange={(e)=>handleChangeBudget(e)}
                        value={lifetimeBudget}
                      />
                    </div>
                  </div>
                  */}
                  <div className="input_group">
                    <label htmlFor="">Mode</label>
                    <select 
                      value={budget_mode} 
                      onChange={changeBudgetMode} 
                      disabled={campaign_id.length>0}
                      >
                      <option value='daily'>Daily</option>
                      <option value='lifetime'>Lifetime</option>
                    </select>
                  </div>
                  <div className="input_group">
                    <label htmlFor="">Montant</label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                        paddingBottom: 5,
                        borderBottom: "1px solid rgba(0, 0, 0, 0.4)",
                      }}
                    >
                      <CurrencyContainerSection/>
                      <input
                        style={{ borderBottom: "none" }}
                        name="budget"
                        type="text"
                        inputMode="decimal"
                        className="input_currency"
                        onInput={(e)=>setInputBudget(e.target.value)}
                        onChange={(e)=>handleChangeBudget(e)}
                        value={inputBudget}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {type === "ADVANTAGE_VALUE" ? (
              <AdvantageCustomerBudgetSection/>
            ) : (
              ""
            )}
            <div className="row">
              <div className="input_group">
                <label style={{ marginBottom: 0 }} htmlFor="">
                  TAG
                </label>
                <input
                  value={tag}
                  type="text"
                  name="tag"
                  onChange={handleChange}
                />
              </div>
              <PixelSection/>
            </div>
            <div className="row">
              {type === "ADVANTAGE_VALUE" ? (
                  <div className={`input_group objectif`}>
                    <label htmlFor="" style={{ marginBottom: 0 }}>
                      Application
                    </label>
                      <FormControl fullWidth>
                        <Autocomplete
                          name="application"
                          id="autocomplete-standard"
                          onChange={(e, value) => handleChangeAutoComplete("application", value)}
                          value={application}
                          groupBy={(option) => option.kind}
                          options={filteredApplications}
                          disabled={filteredApplications.length===0||Boolean(campaign_id)}
                          style={{
                            borderBottom: isPexelRequierd && "1px solid red",
                          }}
                          getOptionLabel={(option) => option.name||""}
                          isOptionEqualToValue={(option,value) => option.id === value.id}
                          renderInput={(params) => (
                            <TextField variant="standard" {...params} />
                          )}
                          //renderOption={renderPixelsSelectGroup}
                        />
                      </FormControl>
                    <span className="messageError">
                      {isPexelRequierd && (
                        <FormattedMessage
                          defaultMessage="Pixel obligatoire"
                          id="create_campaign_infos_generales_pixel_error"
                        />
                      )}
                    </span>
                  </div>
              ) : (
                ""
              )}
              <div className="input_group objectif">
                <label htmlFor="">
                  <FormattedMessage
                    defaultMessage="Objectif"
                    id="create_campaign_infos_generales_objectif"
                  />
                </label>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                  <Autocomplete
                    name="objectif"
                    id="autocomplete-standard"
                    onChange={(e, value) => handleChangeAutoComplete("objectif", value)}
                    value={objectif}
                    options={filteredPixelEvents}
                    disabled={filteredPixelEvents.length===0}
                    getOptionLabel={(option) => (option && option.name) || ''}
                    isOptionEqualToValue={(option, value) => option.unique_spec === value.unique_spec}
                    renderInput={(params) => (
                      <TextField variant="standard" {...params} />
                    )}
                  />
                </FormControl>
              </div>
            </div>
            {advancedMode && (
              <div className="row">
                <div className="input_group objectif">
                  <label htmlFor="" style={{ marginBottom: 0 }}>
                    <FormattedMessage
                      defaultMessage="Attribution"
                      id="create_campaign_infos_generales_attribution"
                    />
                  </label>
                  <FormControl fullWidth>
                    <Autocomplete
                      name="attribution_spec"
                      id="autocomplete-standard"
                      onChange={(e, value) => handleChangeAutoComplete("attribution_spec", value?value.value:"")}
                      value={attribution_spec ? filteredAttributions.find(
                        attribution => JSON.stringify(attribution.value) === JSON.stringify(attribution_spec)): 
                        { value: "", name: "" }}
                      options={filteredAttributions}
                      disabled={filteredAttributions.length===0}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option,value) => JSON.stringify(option.value) === JSON.stringify(value.value)}
                      renderInput={(params) => (
                        <TextField variant="standard" {...params} />
                      )}
                    />
                  </FormControl>
                </div>
                <div className="input_group objectif">
                  <label htmlFor="" style={{ marginBottom: 0 }}>
                    <FormattedMessage
                      defaultMessage="Catalogue"
                      id="create_campaign_infos_generales_catalogue"
                    />
                  </label>
                  <FormControl fullWidth>
                    <Autocomplete
                        name="product_catalog_id"
                        id="autocomplete-standard"
                        onChange={(e, value) => handleChangeAutoComplete("product_catalog_id", value?parseInt(value.id):"")}
                        value={product_catalog_id ? catalogs.find(
                          catalog => catalog.id === String(product_catalog_id)) : { id: "", name: "" }}
                        options={catalogs}
                        disabled={catalogs.length===0||Boolean(campaign_id)}
                        getOptionLabel={(option) => option.name}
                        isOptionEqualToValue={(option,value) => option.id === value.id}
                        renderInput={(params) => (
                          <TextField variant="standard" {...params} />
                        )}
                      />
                  </FormControl>
                </div>
              </div>
            )}
            {
            advantagePlus ? 
              (<CountriesLanguagesSection />) : 
              ("")
            }
          </form>
        }
    />
  );
};

export default InformationsGenerales;
