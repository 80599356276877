import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LeftMenu from "../../components/LeftMenu/LeftMenu";
import "./style.css";
import Loader3 from "../../components/Loader3/Loader3";
import { FormattedMessage, useIntl } from "react-intl";
import SelectPages from "../../components/SelectPages/SelectPages";
import TopRightMenu from "../../components/TopRightMenu/TopRightMenu";
import RightContainer from "../../components/RightContainer/RightContainer";
// This is totally wrong I don't unserstand why we look at adAccounts here
// this makes no sense next step should be validating to facebook the pages chosen
// What is should do is reask auth to get the real list of pages and revalidate those pages with auth
// Not at all what it is doing there
// Let the version Cleaned need to fix that later

const ChoisirPages = () => {
  const navigate = useNavigate();
  const [loader2, setLoader2] = useState(false);
  const dispatch = useDispatch();
  const { toggleLeftMenu } = useSelector(state => state.utils);

  const setToggleLeftMenu = () => {
    dispatch({ type: "SET_TOGGLE_LEFT_MENU" });
  };


  const nextStep = () => {};

  return (
    <div className='ecran_campagne_choisir_compte'>
      <LeftMenu />
      <RightContainer>
        <header className='right_content_header'>
          <div className='back_title'>
            {toggleLeftMenu ? (
              <i
                onClick={() => setToggleLeftMenu(prevState => !prevState)}
                style={{ marginRight: 30, cursor: "pointer" }}
                className='fas fa-bars fa-2x'
              ></i>
            ) : null}
            <h1 className='right_content_header_title'>
              <FormattedMessage
                defaultMessage='Choisissez votre page'
                id='choose-page-main-title'
              />
            </h1>
          </div>

          <TopRightMenu />
        </header>
        {/* I think this is useless can't understand why is it existing loader was always false
        {loader && (
          <Loader
            message={intl.formatMessage({
              defaultMessage: "Ajout en cours...",
              id: "loader_message_4",
            })}
          />
          )}*/}
        <div className='right_content_main'>
          <div className='steps'>
            <div className='step '>
              <p>
                <FormattedMessage
                  defaultMessage='Paramètres'
                  id='add-account-title'
                />
              </p>
            </div>
            <img src='/fleche-step.png' alt='Next Step' />
            <div className='step '>
              <p>
                <FormattedMessage
                  defaultMessage="Ajout d'un compte"
                  id='add-account-step-add-account'
                />
              </p>
            </div>
            <img src='/fleche-step.png' alt='Next Step' />
            <div className='step active'>
              <p>
                <FormattedMessage
                  defaultMessage='Choisir une page'
                  id='add-page-choose-account'
                />
              </p>
            </div>
            <img src='/fleche-step.png' alt='Next Step' />
            <div className='step'>
              <p>
                <FormattedMessage
                  defaultMessage='Configuration'
                  id='add-account-configuration'
                />
              </p>
            </div>
          </div>
          <div className='choix_reseau_social'>
            <div className='choix_reseau_social_content'>
              {false ? (
                <div style={{ marginBottom: 50 }}>
                  <Loader3 />
                </div>
              ) : (
                <div className='choixcompteads_meta'>
                  <header>
                    <h5>
                      <FormattedMessage
                        defaultMessage='Choisissez une ou plusieurs pages'
                        id='choose-page-choose-ad-page'
                      />
                    </h5>
                    <img src='/meta.png' alt='meta' />
                  </header>
                  {loader2 ? (
                    <Loader3 />
                  ) : (
                    <SelectPages/>
                  )}
                </div>
              )}
              <div className='navigation'>
                <button
                  onClick={() => navigate("/campagne/ajouter-compte")}
                  className='outline'
                >
                  <FormattedMessage
                    defaultMessage='Précédent'
                    id='add-account-previous'
                  />
                </button>
                <button onClick={nextStep} className='plain'>
                  <FormattedMessage
                    defaultMessage='Suivant'
                    id='add-account-next'
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </RightContainer>
    </div>
  );
};

export default ChoisirPages;
