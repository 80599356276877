import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loader3 from "../../../../components/Loader3/Loader3";
import axiosInstance from "../../../../store/axios.instance";
import { autoExpand } from "../../../../utils";
import Switch from "@mui/material/Switch";
import DescItem from "./DescItem";
import SearchAsset from "../../../../components/SearchAsset/SearchAsset";

const Description = ({ getDescriptions, loaderDescriptions }) => {
  const [toggle, setToggle] = useState(false);
  const [newDescription, setNewDescription] = useState("");
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const [loader, setLoader] = useState(false);
  const [showTags, setShowTags] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const handleNewDescriptionChange = e => {
    setNewDescription(e.target.value);
  };

  const saveNewDescription = e => {
    if (e.key === "Enter" && e.shiftKey) {
      e.preventDefault();
      setNewDescription(e.target.value + "\n");
    }
  };

  const saveNewDescriptionOnClick = e => {
    if (!newDescription) {
      toast.error("Veuillez insérer une valeur non vide");
      return;
    }

    const newDesc = {
      content: newDescription,
      keywords: [],
      monitoring_tags: null,
    };

    axiosInstance
      .put(`/${adaccountId}/description`, newDesc)
      .then(result => {
        dispatch({
          type: "CREATE_DESCRIPTION_CREATION_PACKAGE_PUBLICTE_SUCCESS",
          payload: result.data.data,
        });

        toast.success("Description crée avec succès");

        setLoader(false);
      })
      .catch(err => {
        console.log(err);
        toast.error("y a eu une erreur lors de la création de la description");
        setLoader(false);
      });

    setNewDescription("");
  };

  const {
    descriptions,
    originalData: { descriptions: descriptionsOriginales },
  } = useSelector(state => state.creationPackagePublicite);

  const { adaccountId } = useSelector(state => state.auth.user);

  const selectedDescriptions = descriptionsOriginales.filter(
    item => item && item.isSelected
  );
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!searchParams.get("keep-data")) {
      getDescriptions(adaccountId);
    }
  }, []);

  useEffect(() => {
    if (toggle && selectedDescriptions.length > 0) {
      dispatch({ type: "SHOW_SELECTED_DESCRIPTIONS_FIRST" });
    }
  }, [toggle]);

  const searchDescriptions = e => {
    setSearchValue(e.target.value);

    dispatch({ type: "SEARCH_DESCRIPTIONS", payload: e.target.value });
  };

  return (
    <section className='section section_description'>
      <header
        onClick={() => setToggle(prevState => !prevState)}
        className='section_header'
      >
        <div className='df'>
          <h3 className='section_header_title'>
            <FormattedMessage
              defaultMessage='Description'
              id='creation_package_pub_section_description_1'
            />

            {selectedDescriptions.length > 0 && (
              <span className='nbr'>{selectedDescriptions.length}</span>
            )}
          </h3>
        </div>
        <i className={`fas fa-chevron-${toggle ? "up" : "down"}`}></i>
      </header>
      {toggle && (
        <div className='section_content'>
          <SearchAsset value={searchValue} onChange={searchDescriptions} />
          <div className='wrapper'>
            <div className='add_text'>
              <textarea
                onInput={autoExpand}
                value={newDescription}
                onKeyDown={saveNewDescription}
                onChange={handleNewDescriptionChange}
                placeholder='Ajouter une description'
              ></textarea>
              <i
                onClick={saveNewDescriptionOnClick}
                className='fas fa-plus'
              ></i>
            </div>
            <div className='tags'>
              <i className='fas fa-info-circle'></i>
              <p>Tags</p>
              <Switch
                onChange={() => setShowTags(prevState => !prevState)}
                checked={showTags}
              />
            </div>
          </div>
          <div className='list_texts'>
            {loaderDescriptions ? (
              <Loader3 />
            ) : descriptions && descriptions.length > 0 ? (
              descriptions.map((description, i) => (
                <DescItem
                  key={i}
                  description={description}
                  getDescriptions={getDescriptions}
                  showTags={showTags}
                />
              ))
            ) : (
              <h6>
                <FormattedMessage
                  defaultMessage="Aucune description pour l'instant"
                  id='creation_package_pub_section_description_2'
                />
              </h6>
            )}
          </div>
        </div>
      )}
    </section>
  );
};

export default Description;
