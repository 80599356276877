import React, {useEffect} from "react";
import "./style.css";

const BaseAlertMessage = ({message, callback, event=null}) => {
  useEffect(() => {
    const confirmBtn = document.getElementById('base_alert_confirm');
    const cancelBtn = document.getElementById('base_alert_cancel');
    const messageElement = document.getElementById('base_alert_content');

    // Set the message
    if (Array.isArray(message)) {
      messageElement.innerHTML = ""
      // If message is an array, render each FormattedMessage component
      message.forEach((msg, index) => {
        const div = document.createElement('div');
        div.textContent = msg;
        messageElement.appendChild(div);
        // Add a line break after each message, except for the last one
        if (index < message.length - 1) {
          messageElement.appendChild(document.createElement('br'));
        }
      });
    } else {
      // If message is a single FormattedMessage component, render it directly
      messageElement.textContent = message;;
    }
    //messageElement.textContent = message;
    //.replace(/\n/g, "<br>")
    // Add event listeners to the buttons
    confirmBtn.addEventListener('click', () => {
      // Call the callback with true when confirm button is clicked
      callback(true, event);
    });

    cancelBtn.addEventListener('click', () => {
      // Call the callback with false when cancel button is clicked
      callback(false, event);
    });

    // Clean up event listeners when the component unmounts
    return () => {
      confirmBtn.removeEventListener('click', () => {});
      cancelBtn.removeEventListener('click', () => {});
    };
  }, [callback, message, event]);
  
  
  return (
    <section id="base_alert_window" className="alert_section">
      <div className="sub_section">
        <header className="sub_section_header" >
            <h3 className="sub_section_header_title">
              {"Confirmation"}
            </h3>
          </header>
          <div className='alert_msg_config_adset'>
            <div className='content'>
              <div id="base_alert_content"></div>
              <div className='buttons'>
                <button onClick={(e)=>{}} id="base_alert_confirm" className='success'>
                  Confirmer
                </button>
                <button onClick={(e)=>{}} id="base_alert_cancel" className='danger'>
                  Annuler
                </button>
              </div>
            </div>
          </div>
      </div>
    </section>
  );
};

export default BaseAlertMessage;
