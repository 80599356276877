const initialState = {
  ressources: [],
};

export const ressourcesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CLEAR_RESSOURCES":
      return {
        ...state,
        ressources: [],
      };
    case "SET_ALL_RESSOURCES":
      return {
        ...state,
        ressources: [...state.ressources, ...action.payload],
      };
    case "REMOVE_RESSOURCE":
      return {
        ...state,
        ressources: state.ressources.filter(
          (elem, index) => index !== action.payload
        ),
      };
    default:
      return state;
  }
};
