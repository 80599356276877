const initialState = {
  campagnes: [],
  adsets: [],
  publicites: [],
  selectedCampaign: "",
  selectedAdset: "",
};

export const publicitesExistantesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CLEAR_PUBLICITES_EXISTANTES":
      return {
        ...state,
        publicites: [],
        selectedCampaign: "",
        selectedAdset: "",
      }
    case "TOGGLE_SELECT_PUBLICITE_PUBLICITES_EXISTANTES":
      return {
        ...state,
        publicites: state.publicites.map(item => {
          if (item.id === action.payload) {
            return { ...item, isSelected: !item.isSelected };
          } else {
            return item;
          }
        }),
      };
    case "CLEAR_CAMPAIGNS_PUBLICITES_EXISTANTES":
      return {
        ...state,
        publicites: [],
      };
    case "GET_ADS_PUBLICITES_EXISTANTES":
      return {
        ...state,
        publicites: action.payload.map(item => ({
          ...item,
          isSelected: false,
        })),
      };
    case "GET_ADSETS_PUBLICITES_EXISTANTES":
      return {
        ...state,
        adsets: action.payload,
      };
    case "SET_SELECTED_CAMPAIGN_PUBLICITES_EXISTANTES":
      return {
        ...state,
        selectedCampaign: action.payload,
      };
    case "SET_SELECTED_ADSET_PUBLICITES_EXISTANTES":
      return {
        ...state,
        selectedAdset: action.payload,
      };
    case "GET_CAMPAIGNS_PUBLICITES_EXISTANTES":
      return {
        ...state,
        campagnes: action.payload,
      };
    default:
      return state;
  }
};
