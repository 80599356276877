import React from "react";
import { useDispatch } from "react-redux";

const CampaignMonitoring = ({ monitoring, item }) => {
  const dispatch = useDispatch();

  const handleChangeAdsets = (e, campId) => {
    dispatch({ type: "SET_START_SETTING_STRATEGY_MONITORING_CAMPAGNE" });
    dispatch({
      type: "CHANGE_ADSETS_CAMPAIGN_BRANCHEMENT_MONITORING_CAMPAGNE",
      payload: {
        monitoringId: monitoring.id,
        campaignId: campId,
        value: e.target.checked,
      },
    });
  };

  const handleChangeAds = (e, campId) => {
    dispatch({ type: "SET_START_SETTING_STRATEGY_MONITORING_CAMPAGNE" });

    dispatch({
      type: "CHANGE_ADS_CAMPAIGN_BRANCHEMENT_MONITORING_CAMPAGNE",
      payload: {
        monitoringId: monitoring.id,
        campaignId: campId,
        value: e.target.checked,
      },
    });
  };

  const deleteCampaign = (id) => {
    dispatch({ type: "SET_START_SETTING_STRATEGY_MONITORING_CAMPAGNE" });

    dispatch({
      type: "DELETE_CAMPAIGNS_TO_MONITORING_MONITORING_CAMPAIGNE",
      payload: {
        monitoringId: monitoring.id,
        campaignId: id,
      },
    });
  };

  const setShowTopSection = (monitoringId, campaignId) => {
    dispatch({
      type: "TOGGLE_TOP_SECTION_CAMPAIGN_BRANCHEMENT_MONITORING_CAMPAGNE",
      payload: {
        monitoringId,
        campaignId,
      },
    });
  };

  return (
    <div
      onClick={() => setShowTopSection(monitoring.id, item.id)}
      key={item.id}
      className={`campaign ${
        !item.monitor_ads && !item.monitor_adsets ? "hachuree" : ""
      }`}
    >
      {item.showTopSection && (
        <div className="top">
          <div className="checkboxes">
            <div className="adsets">
              <input
                onClick={(e) => e.stopPropagation()}
                checked={item.monitor_adsets}
                onChange={(e) => handleChangeAdsets(e, item.id)}
                type="checkbox"
                name=""
                id="adsets_checkbox"
              />
              <label
                onClick={(e) => e.stopPropagation()}
                htmlFor="adsets_checkbox"
              >
                Adsets
              </label>
            </div>
            <div className="adsets">
              <input
                onClick={(e) => e.stopPropagation()}
                checked={item.monitor_ads}
                onChange={(e) => handleChangeAds(e, item.id)}
                type="checkbox"
                name=""
                id="ads_checkbox"
              />
              <label
                onClick={(e) => e.stopPropagation()}
                htmlFor="ads_checkbox"
              >
                Ads
              </label>
            </div>
          </div>
        </div>
      )}
      <div className="bottom">
        <h3 className="campaign_name">
          {item.name} &nbsp;
          {item.monitor_ads || item.monitor_adsets ? (
            <span className="bold">
              {`${
                item.monitor_adsets
                  ? item.monitor_ads
                    ? "(adsets, ads)"
                    : "(adsets)"
                  : "(ads)"
              }`}
            </span>
          ) : null}
        </h3>
        {/* <i
                          onClick={() => editCampaign(item)}
                          className='fas fa-edit'
                        ></i> */}
        <i onClick={() => deleteCampaign(item.id)} className="fas fa-times"></i>
      </div>
    </div>
  );
};
export default CampaignMonitoring;
