import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LeftMenu from "../../components/LeftMenu/LeftMenu";

import "./style.css";
import { FormattedMessage } from "react-intl";
import TopRightMenu from "../../components/TopRightMenu/TopRightMenu";
import SingleMonitoring from "./SingleMonitoring";
import axiosInstance from "../../store/axios.instance";
import Loader3 from "../../components/Loader3/Loader3";
import RightContainer from "../../components/RightContainer/RightContainer";
import { toast } from "react-toastify";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import StepsMonitoring from "../../components/StepsComponents/StepsMonitoring";

const ConfigurationMonitoring = () => {
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const { toggleLeftMenu } = useSelector((state) => state.utils);
  const navigate = useNavigate();

  const setToggleLeftMenu = () => {
    dispatch({ type: "SET_TOGGLE_LEFT_MENU" });
  };

  const { adaccountId } = useSelector((state) => state.auth.user);

  const lang = navigator.language.split(/[-_]/)[0];
  const uppercasedLang = lang.toUpperCase();

  const getMonitorings = async (adaccount) => {
    try {
      setLoader(true);

      const response = await axiosInstance.get(`/${adaccount}/monitoring`);

      if (response.status === 200) {
        dispatch({ type: "GET_MONITORINGS", payload: response.data });

        const result = await axiosInstance.get(
          `/${adaccount}/monitoring_events?lang=${uppercasedLang}`
        );

        if (result.status === 200) {
          // Success
          dispatch({
            type: "GET_EVENEMENTS_PRIORISATION_EVENEMENTS",
            payload: result.data,
          });

          setTimeout(() => {
            setLoader(false);
          }, 500);
        }
      }
    } catch (error) {
      setTimeout(() => {
        setLoader(false);
      }, 500);
    }
  };

  useEffect(() => {
    if (adaccountId) {
      getMonitorings(adaccountId);
    }
  }, []);

  const { monitorings } = useSelector((state) => state.configurationMonitoring);

  const creerNouvelleStrategy = async () => {
    if (!adaccountId) {
      toast.error("Vous devez sélectionner un compte avant de continuer");
      return;
    }

    try {
      setLoader(true);

      await axiosInstance.put(`/${adaccountId}/monitoring`, {
        name: "New monitoring",
        equivalency: {},
        default_apply_on: [],
      });

      setTimeout(() => {
        setLoader(false);
        getMonitorings(adaccountId);
      }, 500);

      // setTimeout(() => {
      //   dispatch({
      //     type: "UPDATE_SINGLE_MONITORING_ADD_EVENT",
      //     payload: res.data.data.id,
      //   });
      // }, 1000);
    } catch (error) {
      setTimeout(() => {
        setLoader(false);
      }, 500);
    }
  };

  const { connectedAccounts } = useSelector((state) => state.auth.user);

  const {
    userData: { id: user_id },
  } = useSelector((state) => state.auth.user);

  const [loaderConnectedClients, setLoaderConnectedClients] = useState(false);

  useEffect(() => {
    getMonitorings(adaccountId);
  }, [adaccountId]);

  const { userData } = useSelector((state) => state.auth.user);

  const getCampaigns = async () => {
    try {
      setLoader(true);

      const response = await axios.get(
        `${
          process.env.REACT_APP_GRAPH_API
        }/${adaccountId}/campaigns?fields=name&access_token=${
          userData.token
          // userData.dev_token
          // process.env.NODE_ENV === "development"
          //   ? userData.dev_token
          //   : userData.token
        }&limit=1000`
      );

      if (response.status === 200) {
        dispatch({
          type: "GET_CAMPAIGNS_CONFIG_MONITORING",
          payload: response.data.data.sort(function (a, b) {
            var nomA = a.name.toUpperCase(); // ignorer la casse
            var nomB = b.name.toUpperCase(); // ignorer la casse
            if (nomA < nomB) {
              return -1;
            }
            if (nomA > nomB) {
              return 1;
            }

            // les noms sont égaux
            return 0;
          }),
        });

        setTimeout(() => {
          setLoader(false);
        }, 500);
      } else {
        setTimeout(() => {
          setLoader(false);
        }, 500);
      }
    } catch (error) {
      console.log(error);
      setTimeout(() => {
        setLoader(false);
      }, 500);
    }
  };

  useEffect(() => {
    getCampaigns();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="ecran_configuration_monitoring">
      <LeftMenu />
      <RightContainer>
        <header className="right_content_header">
          <div className="back_title" style={{ flex: "1" }}>
            {toggleLeftMenu ? (
              <i
                onClick={() => setToggleLeftMenu((prevState) => !prevState)}
                style={{ marginRight: 30, cursor: "pointer" }}
                className="fas fa-bars fa-2x"
              ></i>
            ) : null}
            <h1 className="right_content_header_title">
              <FormattedMessage
                defaultMessage="Configurez votre stratégie"
                id="screen_configuration_monitoring_1"
              />
            </h1>
          </div>
          <TopRightMenu />
        </header>
        <div className="right_content_main">
          <StepsMonitoring activeStep="strategies" />
          <div className="configurer_strategie">
            <div className="configurer_strategie_content">
              <div className="evenements_a_optimiser">
                <div className="row">
                  <h2 className="evenements_a_optimiser_title">
                    <FormattedMessage
                      defaultMessage="Stratégies d'optimisation"
                      id="screen_configuration_monitoring_2"
                    />
                  </h2>
                  <button onClick={creerNouvelleStrategy}>
                    <FormattedMessage
                      defaultMessage="Nouvelle stratégie"
                      id="screen_configuration_monitoring_3"
                    />
                  </button>
                </div>
                <div className="evenements_a_optimiser_content">
                  <div className="events">
                    {loader ? (
                      <Loader3 />
                    ) : monitorings && monitorings.length > 0 ? (
                      monitorings.map((item) => (
                        <SingleMonitoring
                          getMonitorings={getMonitorings}
                          item={item}
                          key={item.id}
                        />
                      ))
                    ) : (
                      <p>
                        <FormattedMessage
                          defaultMessage="Aucune stratégie pour l'instant"
                          id="screen_priorisation_events_wqa3790"
                        />
                      </p>
                    )}

                    <div className="dfe">
                      <button
                        style={{ marginLeft: 10 }}
                        onClick={() =>
                          navigate("/branchement-monitoring-campagne")
                        }
                      >
                        <FormattedMessage
                          defaultMessage="Suivant"
                          id="screen_priorisation_events_5"
                        />
                      </button>
                    </div>
                  </div>
                  <div className="choix_strategie">
                    <div className="choix_strategie_box">
                      <span className="round">?</span>
                      <h4 className="choix_strategie_title">
                        <FormattedMessage
                          defaultMessage="Choix de votre stratégie"
                          id="screen_configuration_monitoring_4"
                        />
                      </h4>
                      <p>
                        <FormattedMessage
                          defaultMessage="Les poids définis pour les évènements du tunnel permettent au moteur d'optimiser plusieurs objectifs en même temps"
                          id="screen_configuration_monitoring_5"
                        />
                      </p>
                    </div>

                    <div className="choix_strategie_box">
                      <span className="round">?</span>
                      <h4 className="historique_title">
                        <FormattedMessage
                          defaultMessage="Règles d'application de la stratégie"
                          id="screen_configuration_monitoring_6"
                        />
                      </h4>
                      <p>
                        <FormattedMessage
                          defaultMessage="Votre stratégie, optimise les dépenses : des adsets pour les campagnes dont le nom est contenu dans l'une des
                chaîne de caractères du champ ciblage des ads pour les
                campagnes dont le nom est contenu dans l'une des chaiînes
                de caractères ddu champ publicité"
                          id="screen_configuration_monitoring_7"
                        />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </RightContainer>
    </div>
  );
};

export default ConfigurationMonitoring;
