import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import axiosInstance from "../../../../store/axios.instance";
import { useSearchParams } from "react-router-dom";
import { valeurExiste } from "../../../../utils";

const LinkItem = ({ texte, getLiens, showTags }) => {
  const [newTag, setNewTag] = useState("");
  const { adaccountId } = useSelector(state => state.auth.user);
  // eslint-disable-next-line
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();

  const deleteText = id => {
    if (window.confirm("Voulez-vous vraiment supprimer ce texte ?")) {
      // dispatch({
      //   type: "DELETE_TEXTE_CREATION_PACKAGE_PUB",
      //   payload: title,
      // });

      axiosInstance
        .delete(`/${adaccountId}/link/${id}`)
        .then(result => {
          toast.success("Lien supprimée avec succès");

          getLiens(adaccountId);

          setLoader(false);
        })
        .catch(err => {
          console.log(err);
          toast.error(
            "y a eu une erreur lors de la création de la description"
          );
          setLoader(false);
        });
    }
  };

  const handleChangeNewTag = e => {
    setNewTag(e.target.value);
  };

  const saveNewTag = (e, link) => {
    if (e.key === "Enter") {
      if (!newTag) {
        toast.error("Veuillez insérer un tag");
        return;
      }

      setLoader(true);

      const dataToSend = { ...link, keywords: [...link.keywords, newTag] };

      axiosInstance
        .post(`/${adaccountId}/link/${link.id}`, dataToSend)
        .then(() => {
          dispatch({
            type: "UPDATE_LINK_CREATION_PACKAGE_PUBLICTE_SUCCESS",
          });

          toast.success("Tag ajouté avec succès");

          setLoader(false);

          dispatch({
            type: "ADD_TAG_LINK_LOCALLY",
            payload: { id: link.id, tag: newTag },
          });
        })
        .catch(err => {
          dispatch({ type: "UPDATE_LINK_CREATION_PACKAGE_PUBLICTE_FAIL" });
          console.log(err);
          setLoader(false);
        });

      setNewTag("");
    }
  };

  const deleteTag = (lien, tag) => {
    if (window.confirm("Voulez-vous vraiment supprimer ce tag ?")) {
      setLoader(true);

      const dataToSend = {
        ...lien,
        keywords: lien.keywords.filter(item => item !== tag),
      };

      axiosInstance
        .post(`/${adaccountId}/link/${lien.id}`, dataToSend)
        .then(() => {
          dispatch({
            type: "DELETE_TAG_LIEN_CREATION_PACKAGE_PUB",
            payload: {
              id: lien.id,
              tag,
            },
          });

          toast.success("Tag supprimé avec succès");

          setLoader(false);
        })
        .catch(err => {
          dispatch({ type: "UPDATE_LINK_CREATION_PACKAGE_PUBLICTE_FAIL" });
          console.log(err);
          setLoader(false);
        });
    }
  };
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();

  const tagToEdit = searchParams.get("tagToEdit");

  const { nomPackage } = useSelector(state => state.creationPackagePublicite);

  const toggleSelectItem = id => {
    if (tagToEdit && texte.isSelected) {
      if (valeurExiste(texte.keywords, nomPackage)) {
        const dataToSend = {
          ...texte,
          keywords: texte.keywords.filter(item => item !== nomPackage),
        };

        axiosInstance
          .post(`/${adaccountId}/link/${texte.id}`, dataToSend)
          .then(() => {
            dispatch({
              type: "DELETE_TAG_LIEN_CREATION_PACKAGE_PUB",
              payload: {
                id: texte.id,
                tag: nomPackage,
              },
            });
          })
          .catch(err => {
            console.log(err);
          });
      }
    }

    dispatch({ type: "TOGGLE_SELECT_LINK", payload: id });
  };

  return (
    <div
      className={`body ${texte.isSelected ? "selected" : ""}`}
      onClick={() => toggleSelectItem(texte.id)}
    >
      <i
        onClick={e => {
          e.stopPropagation();
          deleteText(texte.id);
        }}
        className='fas close fa-times'
      ></i>
      <h5 className='title'>{texte.content}</h5>
      {showTags && texte.keywords && (
        <React.Fragment>
          <div className='tags'>
            {texte.keywords.length > 0 ? (
              texte.keywords.map((tg, i) => (
                <div key={i} className='tag'>
                  <p>{tg}</p>
                  <i
                    onClick={e => {
                      e.stopPropagation();
                      deleteTag(texte, tg);
                    }}
                    className='fas fa-times'
                  ></i>
                </div>
              ))
            ) : (
              <h6>Aucun tag pour l'instant</h6>
            )}
          </div>
          <div className='add_tag' onClick={e => e.stopPropagation()}>
            <input
              onKeyDown={e => {
                saveNewTag(e, texte);
              }}
              value={newTag}
              onChange={handleChangeNewTag}
              type='search'
              name=''
              id=''
              placeholder='Tags'
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default LinkItem;
