import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import AssociatedAssetsCmp from "../../../../../components/AssociatedAssetsCmp/AssociatedAssetsCmp";
import Loader3 from "../../../../../components/Loader3/Loader3";
import axiosInstance from "../../../../../store/axios.instance";
import { estObjetVide, valeurExiste } from "../../../../../utils";
import { useSearchParams } from "react-router-dom";

const VideoItem = ({ video, showTags }) => {
  const [loader, setLoader] = useState(false);
  const [newTag, setNewTag] = useState("");
  const { adaccountId } = useSelector(state => state.auth.user);
  const dispatch = useDispatch();

  const handleSaveTag = e => {
    if (newTag) {
      if (e.key === "Enter") {
        // Add new keyword to video in the database
        setLoader(true);

        const dataToSend = [
          {
            id: video.id,
            title: video.title,
            keywords: [newTag],
          },
        ];

        axiosInstance
          .post(`/${adaccountId}/AdVideos/addkeywords`, dataToSend)
          .then(res => {
            setTimeout(() => {
              toast.success("Tag ajouté avec succès");
              setLoader(false);
              setNewTag("");

              // getVideos(adaccountId);
              dispatch({
                type: "ADD_TAG_VIDEO_ITEM_LOCALLY",
                payload: { videoId: video.id, tagToAdd: newTag },
              });
            }, 300);
          })
          .catch(err => {
            console.log(err);
            setLoader(false);
          });
      }
    }
  };

  const deleteTag = tag => {
    if (window.confirm("Voulez-vous vraiment supprimer ce tag ?")) {
      const dataToSend = [
        {
          id: video.id,
          title: video.title,
          keywords: video.keywords.filter(tg => tg !== tag),
        },
      ];

      axiosInstance
        .post(`/${adaccountId}/AdVideos/newkeywords`, dataToSend)
        .then(res => {
          setTimeout(() => {
            toast.success("Tag supprimé avec succès");
            setNewTag("");

            // Get videos
            // getVideos(adaccountId);

            dispatch({
              type: "REMOVE_TAG_VIDEO_ITEM_LOCALLY",
              payload: { videoId: video.id, tagToDelete: tag },
            });
          }, 300);
        })
        .catch(err => {
          console.log(err);
        });
    }
  };
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();

  const tagToEdit = searchParams.get("tagToEdit");

  const { nomPackage } = useSelector(state => state.creationPackagePublicite);

  const setSelectedVideo = id => {
    if (tagToEdit && video.isSelected) {
      if (valeurExiste(video.keywords, nomPackage)) {
        const dataToSend = [
          {
            id: video.id,
            title: video.title,
            keywords: video.keywords.filter(tg => tg !== nomPackage),
          },
        ];

        axiosInstance
          .post(`/${adaccountId}/AdVideos/newkeywords`, dataToSend)
          .then(res => {
            dispatch({
              type: "REMOVE_TAG_VIDEO_ITEM_LOCALLY",
              payload: { videoId: video.id, tagToDelete: nomPackage },
            });
          })
          .catch(err => {
            console.log(err);
          });
      }
    }

    dispatch({
      type: "TOGGLE_SELECTED_VIDEO_CREATION_PACKAGE_PUB",
      payload: id,
    });
  };

  return (
    <div
      onClick={() => setSelectedVideo(video.id)}
      className={`video_item ${video.isSelected && "selected"}`}
      style={showTags ? { width: "48%" } : {}}
    >
      <div className='left' style={!showTags ? { width: "100%" } : {}}>
        <div className='image_title'>
          <h4>
            {video.title.length < 25
              ? video.title
              : `${video.title.slice(0, 25)}...`}
          </h4>
          {video.associated_assets && !estObjetVide(video.associated_assets) ? (
            <AssociatedAssetsCmp />
          ) : null}
        </div>
        <div className='image'>
          <video
            onClick={e => e.preventDefault()}
            controls
            src={video.source}
          ></video>
        </div>
      </div>
      <div
        className='video_item_right'
        style={showTags ? { display: "flex" } : { display: "none" }}
      >
        <div className='tags' onClick={e => e.stopPropagation()}>
          {video.keywords &&
            video.keywords.length > 0 &&
            video.keywords.map((tag, i) => (
              <div key={i} className='tag'>
                <p>{tag}</p>
                <i onClick={() => deleteTag(tag)} className='fas fa-times'></i>
              </div>
            ))}
        </div>
        <div className='add_tag' onClick={e => e.stopPropagation()}>
          {loader ? (
            <Loader3 />
          ) : (
            <input
              type='text'
              onChange={e => setNewTag(e.target.value)}
              onKeyUp={handleSaveTag}
              value={newTag}
              placeholder='Tag...'
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default VideoItem;
