import React from "react";
import "./style.css";
import { useIntl } from "react-intl";

const SearchAsset = ({ value, onChange }) => {
  const intl = useIntl();

  return (
    <div className='search_asset'>
      <i class='fas fa-search'></i>
      <input
        type='search'
        value={value}
        onChange={onChange}
        placeholder={intl.formatMessage({
          id: "search_asset_cmp_rechercher_dans_les_elements_146_DeH",
          defaultMessage: "Rechercher dans les éléments",
        })}
      />
    </div>
  );
};

export default SearchAsset;
