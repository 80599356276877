import React from "react";
import { useDispatch } from "react-redux";
import { estObjetVide } from "../../utils";
import AssociatedAssetsCmp from "../AssociatedAssetsCmp/AssociatedAssetsCmp";

const VideoItem = ({ video, showTags }) => {
  const dispatch = useDispatch();

  const setSelectedCarre = video => {
    dispatch({ type: "SET_VIDEO_CARRE_APPAIRER_VISUELS", payload: video });
  };

  const setSelectedStory = video => {
    dispatch({ type: "SET_VIDEO_STORY_APPAIRER_VISUELS", payload: video });
  };

  return (
    <div
      className={`video_item ${video.isSelected && "selected"}`}
      style={showTags ? { width: "48%" } : {}}
    >
      <div className='left' style={!showTags ? { width: "100%" } : {}}>
        <div className='image_title'>
          <h4 className='title'>
            {video.title.length < 25
              ? video.title
              : `${video.title.slice(0, 25)}...`}
          </h4>
          {video.associated_assets && !estObjetVide(video.associated_assets) ? (
            <AssociatedAssetsCmp />
          ) : null}
        </div>
        <div className='video'>
          <video controls src={video.source}></video>
        </div>
      </div>

      <div className='video_item_actions'>
        <button onClick={() => setSelectedCarre(video)}>
          Appairer visuel carré
        </button>
        <button onClick={() => setSelectedStory(video)}>
          Appairer visuel story
        </button>
      </div>
    </div>
  );
};

export default VideoItem;
