import React, { useEffect, useState } from "react";
import Loader from "../../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import "./style.css";
import ImageCarousel from "../../components/Carousels/Image";
import VideoCarousel from "../../components/Carousels/Video";
import LeftMenu from "../../components/LeftMenu/LeftMenu";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axiosInstance from "../../store/axios.instance";
import TopRightMenu from "../../components/TopRightMenu/TopRightMenu";
import { generateRandomNumber, inverseCleValeur } from "../../utils";
import VisuelDroiteCarousel from "../../components/VisuelDroiteCarousel/VisuelDroiteCarousel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FormattedMessage, useIntl } from "react-intl";
import RightContainer from "../../components/RightContainer/RightContainer";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CreationCarousel = ({ updateMode }) => {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { toggleLeftMenu } = useSelector((state) => state.utils);

  const { visuels, contenuParDefaut } = useSelector((state) => state.carousel);

  // console.log("visuels", visuels)

  const {
    texte: { textes },
    titre: { titres },
    ctas,
    liens,
    descriptions,
  } = useSelector((state) => state.creationPackagePublicite);

  const setToggleLeftMenu = () => {
    dispatch({ type: "SET_TOGGLE_LEFT_MENU" });
  };

  const getParams = new URLSearchParams(window.location.search);
  const compagnId = getParams.get("campaign_id");

  const { nomPackage } = useSelector((state) => state.creationPackagePublicite);
  const { adaccountId } = useSelector((state) => state.auth.user);

  const [multiShareOptimized, setMultiShareOptimized] = useState(false);
  const [carouselToVideo, setCarouselToVideo] = useState(false);

  const creerCarousel = () => {
    const { titre, description, lien, cta, texte } = contenuParDefaut;

    if (nomPackage) {
      if (visuels.length > 0) {
        if (visuels.length >= 2 && visuels.length <= 10) {
          setLoader(true);

          let visualsToSend = [];

          visualsToSend = visuels.map((visuel) => {
            delete visuel.titre;
            delete visuel.title;

            let objVisuel = {
              id: visuel.id,
              name: titre ? titre.content : visuel.name,
              description: description
                ? description.content
                : visuel.description,
              link: lien ? lien.content : visuel.lien,
              call_to_action: cta ? cta.content : visuel.callToAction,
            };

            if (visuel.hasOwnProperty("hash")) {
              objVisuel["hash"] = visuel.hash;
            }

            if (visuel.hasOwnProperty("url")) {
              objVisuel["url"] = visuel.url;
            }

            if (visuel.hasOwnProperty("source")) {
              objVisuel["source"] = visuel.source;
            }

            return objVisuel;
          });

          const carouselObject = {
            id: carouselId,
            account_id: adaccountId,
            name: `carousel-${generateRandomNumber()}`,
            keywords: [nomPackage],
            body: texte ? texte.content : "",
            title: titre ? titre.content : "",
            description: description ? description.content : "",
            link: lien ? lien.content : "",
            call_to_action: cta ? cta.content : "",
            multi_share_optimized: multiShareOptimized,
            carousel_to_video: carouselToVideo,
            carousel_cards: visualsToSend,
          };

          // console.log(JSON.stringify(carouselObject, null, 2));
          // update the carousel, send request and redirect to creation package pub screen
          axiosInstance
            .put(`/${adaccountId}/AdCarousels`, carouselObject)
            .then((result) => {
              // console.log("Carousel: ", result.data);
              setTimeout(() => {
                setLoader(false);
                compagnId
                  ? navigate(
                      `/creation-package-pub?keep-data=true&campaign_id=${compagnId}`
                    )
                  : navigate("/creation-package-pub?keep-data=true");
                // navigate("/creation-package-pub?keep-data=true");
                toast.success("Carousel mis à jour !");
                clearVisuels();
              }, 500);
            })
            .catch((err) => {
              console.log(err);
              dispatch({
                type: "SET_ALERT_GENERAL_ERROR_MESSAGE",
                payload: err.message,
              });
              dispatch({ type: "SHOW_ALERT_GENERAL_ERROR_MESSAGE" });
              setLoader(false);
            });
        } else {
          dispatch({
            type: "SET_ALERT_GENERAL_ERROR_MESSAGE",
            payload:
              "Le nombre de visuels doit être supérieur ou égale à deux, et inférieur ou égale à dix",
          });
          dispatch({ type: "SHOW_ALERT_GENERAL_ERROR_MESSAGE" });
        }
      } else {
        dispatch({
          type: "SET_ALERT_GENERAL_ERROR_MESSAGE",
          payload: "Aucun visuel choisi !",
        });
        dispatch({ type: "SHOW_ALERT_GENERAL_ERROR_MESSAGE" });
      }
    } else {
      dispatch({
        type: "SET_ALERT_GENERAL_ERROR_MESSAGE",
        payload: "Veuillez d'abord remplir un nom de package publicité",
      });
      dispatch({ type: "SHOW_ALERT_GENERAL_ERROR_MESSAGE" });
    }
  };

  const params = useParams();

  const carouselId = params.carouselId;

  const updateCarousel = () => {
    const { titre, description, lien, cta, texte } = contenuParDefaut;

    if (nomPackage) {
      if (visuels.length > 0) {
        // console.log("visuels.length", visuels.length)
        if (visuels.length >= 2 && visuels.length <= 10) {
          if (carouselId) {
            setLoader(true);

            let visualsToSend = [];

            visualsToSend = visuels.map((visuel) => {
              delete visuel.titre;
              delete visuel.title;

              let objVisuel = {
                id: visuel.id,
                name: titre ? titre.content : visuel.name,
                description: description
                  ? description.content
                  : visuel.description,
                link: lien ? lien.content : visuel.lien,
                call_to_action: cta ? cta.content : visuel.callToAction,
              };

              if (visuel.hasOwnProperty("hash")) {
                objVisuel["hash"] = visuel.hash;
              }

              if (visuel.hasOwnProperty("url")) {
                objVisuel["url"] = visuel.url;
              }

              if (visuel.hasOwnProperty("source")) {
                objVisuel["source"] = visuel.source;
              }

              return objVisuel;
            });

            const carouselObject = {
              id: carouselId,
              account_id: adaccountId,
              name: `carousel-${generateRandomNumber()}`,
              keywords: [nomPackage],
              body: texte ? texte.content : "",
              title: titre ? titre.content : "",
              description: description ? description.content : "",
              link: lien ? lien.content : "",
              call_to_action: cta ? cta.content : "",
              multi_share_optimized: multiShareOptimized,
              carousel_to_video: carouselToVideo,
              carousel_cards: visualsToSend,
            };

            // console.log(JSON.stringify(carouselObject, null, 2));
            // console.log("carouselObject", carouselObject);
            // update the carousel, send request and redirect to creation package pub screen
            axiosInstance
              .post(`/${adaccountId}/AdCarousels`, carouselObject)
              .then((result) => {
                // console.log("result", result);
                setTimeout(() => {
                  setLoader(false);
                  compagnId
                    ? navigate(
                        `/creation-package-pub?keep-data=true&campaign_id=${compagnId}`
                      )
                    : navigate("/creation-package-pub?keep-data=true");
                  // navigate("/creation-package-pub?keep-data=true");
                  toast.success("Carousel mis à jour !");
                  clearVisuels();
                }, 500);
              })
              .catch((err) => {
                console.log(err);
                dispatch({
                  type: "SET_ALERT_GENERAL_ERROR_MESSAGE",
                  payload: err.message,
                });
                dispatch({ type: "SHOW_ALERT_GENERAL_ERROR_MESSAGE" });
                setLoader(false);
              });
          } else {
            dispatch({
              type: "SET_ALERT_GENERAL_ERROR_MESSAGE",
              payload: "Carousel id non valide !",
            });
            dispatch({ type: "SHOW_ALERT_GENERAL_ERROR_MESSAGE" });
          }
        } else {
          dispatch({
            type: "SET_ALERT_GENERAL_ERROR_MESSAGE",
            payload:
              "Le nombre de visuels doit être supérieur ou égale à deux, et inférieur ou égale à dix",
          });
          dispatch({ type: "SHOW_ALERT_GENERAL_ERROR_MESSAGE" });
        }
      } else {
        dispatch({
          type: "SET_ALERT_GENERAL_ERROR_MESSAGE",
          payload: "Aucun visuel choisi !",
        });
        dispatch({ type: "SHOW_ALERT_GENERAL_ERROR_MESSAGE" });
      }
    } else {
      dispatch({
        type: "SET_ALERT_GENERAL_ERROR_MESSAGE",
        payload: "Veuillez d'abord remplir un nom de package publicité",
      });
      dispatch({ type: "SHOW_ALERT_GENERAL_ERROR_MESSAGE" });
    }
  };

  const clearVisuels = () => {
    dispatch({ type: "CLEAR_VISUELS_CREATION_CAROUSEL" });
  };

  useEffect(() => {
    document.addEventListener("keydown", function (event) {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    });
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  // Function to update list on drop
  const handleDrop = (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    var updatedList = [...visuels];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    // Update State
    dispatch({ type: "UPDATE_ORDER_LIST_VISUELS", payload: updatedList });
  };

  const intl = useIntl();

  // const { texte, titre, description, lien, cta } = contenuParDefaut;
  const {
    contenuParDefaut: { texte, titre, description, lien, cta },
  } = useSelector((state) => state.carousel);
  // console.log("texte",texte,"titre", titre, "description", description,"lien", lien,"cta", cta)

  useEffect(() => {
    if (updateMode) {
      return () => {
        dispatch({ type: "CLEAR_DATA_UPDATE_CAROUSEL" });
      };
    }
  }, []);

  const {
    callToActions,
  } = useSelector((state) => state.creationCampagneRef);

  const invertedCtas = inverseCleValeur(callToActions);

  return (
    <div className="creation_carousel_screen">
      <LeftMenu />
      <RightContainer>
        <header className="right_content_header">
          <div className="back_title">
            {toggleLeftMenu ? (
              <i
                onClick={() => setToggleLeftMenu((prevState) => !prevState)}
                style={{ marginRight: 30, cursor: "pointer" }}
                className="fas fa-bars fa-2x"
              ></i>
            ) : null}
            <div className="back" onClick={() => navigate(-1)}>
              <img src="/left-arrow 2.png" alt="Retour" />
            </div>
            <h1 className="right_content_header_title">
              <FormattedMessage
                defaultMessage="Créer une campagne"
                id="creation_carousel_screen_1"
              />
            </h1>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="client">
              <TopRightMenu />
            </div>
          </div>
        </header>
        {loader && (
          <Loader
            message={intl.formatMessage({
              defaultMessage: "Opération en cours...",
              id: "loader_message_10",
            })}
          />
        )}
        <div className="right_content_main">
          <div className="main_section_carousel">
            <div className="boxed">
              <header className="main__header">
                <p>
                  {updateMode ? (
                    <FormattedMessage
                      defaultMessage="Modifier un carousel"
                      id="creation_carousel_screen_21"
                    />
                  ) : (
                    <FormattedMessage
                      defaultMessage="Construire un carousel"
                      id="creation_carousel_screen_2"
                    />
                  )}
                </p>
                <i
                  className="fas fa-times"
                  onClick={() => {
                    compagnId
                      ? navigate(
                          `/creation-package-pub?keep-data=true&campaign_id=${compagnId}`
                        )
                      : navigate("/creation-package-pub?keep-data=true");
                    // navigate("/creation-package-pub?keep-data=true");
                  }}
                ></i>
              </header>
              <div className="main__content">
                <div className="main__content_left">
                  <ImageCarousel hideTags hideAddButton />
                  <VideoCarousel hideTags hideAddButton />

                  <div className="contenu_par_default">
                    <p>
                      <FormattedMessage
                        defaultMessage="Texte principal"
                        id="creation_carousel_screen_3"
                      />
                    </p>
                    <Autocomplete
                      defaultValue={texte}
                      // Value={texte || ''}
                      noOptionsText="aucune option"
                      freeSolo
                      onInputChange={(event, newValue) => {
                        dispatch({
                          type: "SET_CONTENT_DEFAULT_CAROUSEL",
                          payload: {
                            name: "texte",
                            value: { content: newValue },
                          },
                        });
                      }}
                      onChange={(event, newValue) => {
                        dispatch({
                          type: "SET_CONTENT_DEFAULT_CAROUSEL",
                          payload: {
                            name: "texte",
                            value: newValue,
                          },
                        });
                      }}
                      id="checkboxes-tags-demo"
                      options={textes}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.content || option}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.content}
                        </li>
                      )}
                      style={{ width: 400 }}
                      renderInput={(params) => (
                        <TextField multiline {...params} variant="standard" />
                      )}
                    />
                    <p>
                      <FormattedMessage
                        defaultMessage="Titre par défaut "
                        id="creation_carousel_screen_4"
                      />

                      <span className="titre_defaut">?</span>
                    </p>
                    <Autocomplete
                      defaultValue={titre}
                      noOptionsText="aucune option"
                      // value={audiences}
                      onChange={(event, newValue) => {
                        dispatch({
                          type: "SET_CONTENT_DEFAULT_CAROUSEL",
                          payload: {
                            name: "titre",
                            value: newValue,
                          },
                        });
                      }}
                      freeSolo
                      onInputChange={(event, newValue) => {
                        dispatch({
                          type: "SET_CONTENT_DEFAULT_CAROUSEL",
                          payload: {
                            name: "titre",
                            value: { content: newValue },
                          },
                        });
                      }}
                      id="checkboxes-tags-demo"
                      options={titres}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.content || option}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.content}
                        </li>
                      )}
                      style={{ width: 400 }}
                      renderInput={(params) => (
                        <TextField multiline {...params} variant="standard" />
                      )}
                    />
                    <p>
                      <FormattedMessage
                        defaultMessage="Description par défaut"
                        id="creation_carousel_screen_5"
                      />
                      <span className="description_defaut">?</span>
                    </p>
                    <Autocomplete
                      defaultValue={description}
                      // value={description || ''}
                      noOptionsText="aucune option"
                      // value={audiences}
                      onChange={(event, newValue) => {
                        dispatch({
                          type: "SET_CONTENT_DEFAULT_CAROUSEL",
                          payload: {
                            name: "description",
                            value: newValue,
                          },
                        });
                      }}
                      freeSolo
                      onInputChange={(event, newValue) => {
                        dispatch({
                          type: "SET_CONTENT_DEFAULT_CAROUSEL",
                          payload: {
                            name: "description",
                            value: { content: newValue },
                          },
                        });
                      }}
                      id="checkboxes-tags-demo"
                      options={descriptions}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.content || option}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.content}
                        </li>
                      )}
                      style={{ width: 400 }}
                      renderInput={(params) => (
                        <TextField multiline {...params} variant="standard" />
                      )}
                    />
                    <p>
                      <FormattedMessage
                        defaultMessage="Lien par défaut"
                        id="creation_carousel_screen_6"
                      />
                      <span className="lien_defaut">?</span>
                    </p>
                    <Autocomplete
                      defaultValue={lien}
                      noOptionsText="aucune option"
                      // value={audiences}
                      onChange={(event, newValue) => {
                        dispatch({
                          type: "SET_CONTENT_DEFAULT_CAROUSEL",
                          payload: {
                            name: "lien",
                            value: newValue,
                          },
                        });
                      }}
                      freeSolo
                      onInputChange={(event, newValue) => {
                        dispatch({
                          type: "SET_CONTENT_DEFAULT_CAROUSEL",
                          payload: {
                            name: "lien",
                            value: { content: newValue },
                          },
                        });
                      }}
                      id="checkboxes-tags-demo"
                      options={liens}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.content || option}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.content}
                        </li>
                      )}
                      style={{ width: 400 }}
                      renderInput={(params) => (
                        <TextField multiline {...params} variant="standard" />
                      )}
                    />
                    <p>
                      <FormattedMessage
                        defaultMessage="Call to action par défaut"
                        id="creation_carousel_screen_7"
                      />
                      <span className="cta_defaut">?</span>
                    </p>
                    <Autocomplete
                      defaultValue={cta}
                      // value={cta}
                      noOptionsText="aucune option"
                      // value={audiences}
                      onChange={(event, newValue) => {
                        dispatch({
                          type: "SET_CONTENT_DEFAULT_CAROUSEL",
                          payload: {
                            name: "cta",
                            value: newValue,
                          },
                        });
                      }}
                      freeSolo
                      onInputChange={(event, newValue) => {
                        dispatch({
                          type: "SET_CONTENT_DEFAULT_CAROUSEL",
                          payload: {
                            name: "cta",
                            value: { content: newValue },
                          },
                        });
                      }}
                      id="checkboxes-tags-demo"
                      options={ctas}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.content || option}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {invertedCtas[option.content]}
                        </li>
                      )}
                      style={{ width: 400 }}
                      renderInput={(params) => (
                        <TextField multiline {...params} variant="standard" />
                      )}
                    />

                    <div className="checks">
                      <div className="check_line">
                        <label htmlFor="transformVid">
                          <input
                            onChange={() => {
                              setCarouselToVideo((prevState) => !prevState);
                            }}
                            checked={carouselToVideo}
                            type="checkbox"
                            name=""
                            id="transformVid"
                          />
                          <FormattedMessage
                            defaultMessage="Transformer automatiquement le carousel en video"
                            id="creation_carousel_screen_8"
                          />
                        </label>
                        <span className="carousel_video">?</span>
                      </div>

                      <div className="check_line">
                        <label htmlFor="optimiserOrdre">
                          <input
                            onChange={() => {
                              setMultiShareOptimized((prevState) => !prevState);
                            }}
                            checked={multiShareOptimized}
                            type="checkbox"
                            name=""
                            id="optimiserOrdre"
                          />
                          <FormattedMessage
                            defaultMessage="Optimiser l’ordre des visuels pour améliorer la performance"
                            id="creation_carousel_screen_9"
                          />
                        </label>
                        <span className="ordre_visuel">?</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="main__content_right">
                  <div className="scrollable">
                    {visuels.length > 0 && (
                      <DragDropContext onDragEnd={handleDrop}>
                        <Droppable
                          direction="vertical"
                          droppableId="list-container"
                        >
                          {(provided) => (
                            <div
                              className="list-container"
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {visuels &&
                                visuels.length > 0 &&
                                visuels.map((visuel, index) => (
                                  <Draggable
                                    key={visuel.id}
                                    draggableId={visuel.id}
                                    index={index}
                                  >
                                    {(provided) => (
                                      <div
                                        className="item-container"
                                        ref={provided.innerRef}
                                        {...provided.dragHandleProps}
                                        {...provided.draggableProps}
                                      >
                                        <VisuelDroiteCarousel visuel={visuel} />
                                      </div>
                                    )}
                                  </Draggable>
                                ))}

                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    )}
                    {visuels.length === 0 && (
                      <React.Fragment>
                        <div
                          className="carre default"
                          style={{ position: "relative" }}
                        >
                          <h4 className="title">
                            <FormattedMessage
                              defaultMessage="Visuel"
                              id="creation_carousel_screen_10"
                            />
                          </h4>
                          <div className="element">
                            <div className="center">
                              <img
                                className="default"
                                src="/drag_asset.png"
                                alt=""
                              />
                              <h5>
                                <FormattedMessage
                                  defaultMessage="Asset 1:1"
                                  id="creation_carousel_screen_11"
                                />
                              </h5>
                            </div>
                          </div>
                          <div className="text">
                            <input
                              className="block"
                              type="text"
                              placeholder="Titre"
                              name="titre"
                            />
                            <input
                              className="block"
                              type="text"
                              placeholder="Description"
                              name="description"
                            />
                            <div className="inline">
                              <input
                                type="text"
                                placeholder="Lien"
                                name="lien"
                              />
                              <select
                                placeholder="Call to action"
                                name="callToAction"
                              >
                                <option value="">
                                  <FormattedMessage
                                    defaultMessage="Type de bouton"
                                    id="creation_carousel_screen_12"
                                  />
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div
                          className="carre default"
                          style={{ position: "relative" }}
                        >
                          <h4 className="title">
                            <FormattedMessage
                              defaultMessage="Visuel"
                              id="creation_carousel_screen_13"
                            />
                          </h4>
                          <div className="element">
                            <div className="center">
                              <img
                                className="default"
                                src="/drag_asset.png"
                                alt=""
                              />
                              <h5>
                                <FormattedMessage
                                  defaultMessage="Asset 1:1"
                                  id="creation_carousel_screen_14"
                                />
                              </h5>
                            </div>
                          </div>
                          <div className="text">
                            <input
                              className="block"
                              type="text"
                              placeholder="Titre"
                              name="titre"
                            />
                            <input
                              className="block"
                              type="text"
                              placeholder="Description"
                              name="description"
                            />
                            <div className="inline">
                              <input
                                type="text"
                                placeholder="Lien"
                                name="lien"
                              />
                              <select
                                placeholder="Call to action"
                                name="callToAction"
                              >
                                <option value="">
                                  <FormattedMessage
                                    defaultMessage="Type de bouton"
                                    id="creation_carousel_screen_15"
                                  />
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                    {visuels.length === 1 && (
                      <div
                        className="carre default"
                        style={{ position: "relative" }}
                      >
                        <h4 className="title">
                          <FormattedMessage
                            defaultMessage="Visuel"
                            id="creation_carousel_screen_16"
                          />
                        </h4>
                        <div className="element">
                          <div className="center">
                            <img
                              className="default"
                              src="/drag_asset.png"
                              alt=""
                            />
                            <h5>
                              <FormattedMessage
                                defaultMessage="Asset 1:1"
                                id="creation_carousel_screen_17"
                              />
                            </h5>
                          </div>
                        </div>
                        <div className="text">
                          <input
                            className="block"
                            type="text"
                            placeholder="Titre"
                            name="titre"
                          />
                          <input
                            className="block"
                            type="text"
                            placeholder="Description"
                            name="description"
                          />
                          <div className="inline">
                            <input type="text" placeholder="Lien" name="lien" />
                            <select
                              placeholder="Call to action"
                              name="callToAction"
                            >
                              <option value="">
                                <FormattedMessage
                                  defaultMessage="Type de bouton"
                                  id="creation_carousel_screen_18"
                                />
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="button_container">
                    {visuels.length > 0 && (
                      <button onClick={clearVisuels} className="cancel">
                        <FormattedMessage
                          defaultMessage="Annuler"
                          id="creation_carousel_screen_19"
                        />
                      </button>
                    )}
                    <button
                      disabled={visuels.length === 0}
                      onClick={updateMode ? updateCarousel : creerCarousel}
                      className={`${visuels.length !== 0 ? "active" : ""}`}
                    >
                      <FormattedMessage
                        defaultMessage="Valider"
                        id="creation_carousel_screen_20"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </RightContainer>
    </div>
  );
};

export default CreationCarousel;
