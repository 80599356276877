import React, {useState, useEffect} from "react";
// import { useDispatch } from "react-redux";
import { importFBLogin } from "../../utils";

const ListItem = ({ acc: { id, name, isSelected, profilePictureUrl }, toggleSelectItem }) => {  

  const [profilImg, setProfilImg] = useState("");

  useEffect(() => { 
    importFBLogin();
  }, [])

  useEffect(() => {
    if (id) {
      setTimeout(() => {
        window.FB.api(
          `/${id}/picture`,
          {
              "redirect": "0"
          },
          function (response) {
            if (response && !response.error) {
              /* handle the result */
              setProfilImg(response.data.url);
            }
          }
        );;
      }, 500)
    }
  }, [id])

  return (
    <div
      className={`list_item ${isSelected ? "selected" : ""}`}
      onClick={() => toggleSelectItem(id)}
    >
      <div className='left'>
        <input type='checkbox' name='' id='' checked={isSelected} />
        <div className='account'>
          <img loading='lazy' width='30' src={profilImg} alt='' />
          <p>{name}</p>
        </div>
      </div>
    </div>
  );
};
export default ListItem;
