import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import ListItem from "./ListItem";
import "./style.css";

const SelectAdAccounts = () => {
  const dispatch = useDispatch();

  const { filteredIntersectClients: accounts } = useSelector(
    state => state.selectAdaccount
  );

  const searchAdaccounts = e => {
    dispatch({
      type: "FILTER_INTERSECT_CLIENTS",
      payload: e.target.value,
    });
  };

  return (
    <div className='screen_choose_account_select_ad_account'>
      <div className='icon_search_box'>
        <i className='fas fa-search'></i>
        <input
          onChange={searchAdaccounts}
          placeholder='Search...'
          type='search'
          name=''
          id=''
        />
      </div>

      {accounts &&
        (accounts.length > 0 ? (
          <div className='liste_intersect_client'>
            {accounts.map(acc => (
              <ListItem key={acc.id} acc={acc} />
            ))}
          </div>
        ) : (
          <h4 style={{ marginTop: 20, textAlign: "center" }}>
            Aucun compte pour l'instant
          </h4>
        ))}
    </div>
  );
};

export default SelectAdAccounts;
