import {
  LOGIN,
  LOGOUT,
  SAVE_ADACCOUNT_ID,
  SWITCH_ADACCOUNT_ID_CURRENT_USER,
} from "../types";

const initialState = {
  user: {
    adaccountListCampaignsId: "",
    adaccountId: localStorage.getItem("adaccountId")
      ? localStorage.getItem("adaccountId")
      : "",
    adaccountName: localStorage.getItem("adaccountName")
      ? localStorage.getItem("adaccountName")
      : "",
    isConnected: false,
    adAccountChoosen: null,
    fbAuthObject: {
      userID: "",
    },
    // Arezki
    //user_id: "1169275623970390",
    businesses: [],
    adaccounts: localStorage.getItem("adaccounts")
      ? JSON.parse(localStorage.getItem("adaccounts"))
      : [],
    connectedAccounts: [],
    userData: localStorage.getItem("userData")
      ? JSON.parse(localStorage.getItem("userData"))
      : null,
  },
  auth0User: localStorage.getItem("userAuth0Connected")
    ? JSON.parse(localStorage.getItem("userAuth0Connected"))
    : null,
  auth0CompleteUserInfos: localStorage.getItem("completeUserInfosAuth0")
    ? JSON.parse(localStorage.getItem("completeUserInfosAuth0"))
    : null,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SWITCH_ACTIVE_ADACCOUNT_LIST_CAMPAIGNS":
      return {
        ...state,
        user: {
          ...state.user,
          adaccountListCampaignsId: action.payload,
        },
      };
    case "SAVE_ID_COMPTE_PRINCIPAL":
      return {
        ...state,
        user: {
          ...state.user,
          adaccountId: action.payload,
        },
      };
    case "SAVE_NAME_COMPTE_PRINCIPAL":
      return {
        ...state,
        user: {
          ...state.user,
          adaccountName: action.payload,
        },
      };
    case "STORE_USER_DATA_AUTH":
      return {
        ...state,
        user: {
          ...state.user,
          userData: action.payload,
        },
      };
    case "SET_BUSINESSES_AFTER_FB_LOGIN":
      return {
        ...state,
        user: {
          ...state.user,
          businesses: action.payload,
        },
      };
    case "STORE_AUTH0_COMPLETE_USER_INFOS":
      return {
        ...state,
        auth0CompleteUserInfos: action.payload,
      };
    case "STORE_AUTH0_USER_INFOS":
      return {
        ...state,
        auth0User: action.payload,
      };
    case "STORE_CONNECTED_ACCOUNTS":
      //intersection of connected accounts
      const connectedAccounts = action.payload.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      return {
        ...state,
        user: {
          ...state.user,
          connectedAccounts,
        },
      };
    case "STORE_ADS_ACCOUNTS":
      //all user accounts
      return {
        ...state,
        user: {
          ...state.user,
          adaccounts: action.payload,
        },
      };
    case "SET_FB_AUTH_OBJECT_CONNECTED_USER":
      return {
        ...state,
        user: {
          ...state.user,
          fbAuthObject: action.payload,
        },
      };
    case "SET_ADACCOUNT_CHOOSEN_AUTH":
      return {
        ...state,
        user: {
          ...state.user,
          adaccountId: action.payload,
        },
      };
    case "SET_ACCOUNT_CREATION_CAMPAGNE":
      return {
        ...state,
        user: {
          ...state.user,
          accountCreationCampaign: action.payload,
        },
      };
    case SWITCH_ADACCOUNT_ID_CURRENT_USER:
      return {
        ...state,
        user: {
          ...state.user,
          adaccountId: action.payload,
        },
      };
    case SAVE_ADACCOUNT_ID:
      return {
        ...state,
        user: {
          ...state.user,
          adaccountId: action.payload,
        },
      };
    case LOGIN:
      return {
        ...state,
        user: {
          ...state.user,
          isConnected: true,
        },
      };
    case LOGOUT:
      return {
        ...state,
        user: {
          ...state.user,
          isConnected: false,
        },
      };
    default:
      return state;
  }
};
