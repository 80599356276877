import React, { useEffect, useState, useRef } from "react";
import store from "../../store";
import Switch from "@mui/material/Switch";

import "./style.css";

import InformationsGenerales from "./Sections/InformationsGenerales";
import PackagePublicite from "./Sections/PackagePublicite";
import PackageGeodemographique from "./Sections/PackageGeodemographique";
import Ciblage from "./Sections/Ciblage";
import ConfigurationAdsetDetaille from "./Sections/ConfigurationAdsetDetaille";
import Configuration from "./Sections/Configuration";

import { useDispatch, useSelector } from "react-redux";
import LeftMenu from "../../components/LeftMenu/LeftMenu";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {setToggleLeftMenu} from "../../store/actions/utils/usualDispatches"
import TopRightMenu from "../../components/TopRightMenu/TopRightMenu";
import { FormattedMessage, useIntl } from "react-intl";
import Loader3 from "../../components/Loader3/Loader3";
import RightContainer from "../../components/RightContainer/RightContainer";
import { get_campaign_data, saveCampaignNextPage, safe_load_file_configuration,
         isAdvanced } from "../../store/actions/utils/campaignCreation";


const label = { inputProps: { "aria-label": "Switch demo" } };
//TODO Put control to prevent iOS 14+ Having MORE THAN 5 ADSETS


const CreationCampagne = ({ mainTitle}) => {
  const isFirstRender = useRef(true);
  const dispatch = useDispatch();

  const loading_campaign = useSelector((state) => state.creationCampagne.loading_campaign);
  const file_configuration_id = useSelector((state) => state.creationCampagne.file_configuration_id);
  const advantagePlus = useSelector((state) => state.creationCampagne.advantagePlus);
  const mandatory_fields = useSelector((state) => state.creationCampagne.mandatory_fields);
  const load_campaign = useSelector((state) => state.creationCampagne.load_campaign);

  const location = useLocation();
  const { loading_ref_campaign, load_ref } = useSelector(state=> state.creationCampagneRef);
  const [advancedMode, setAdvancedMode] = useState(false);
  
  const navigate = useNavigate();

  const adaccountId = useSelector((state) => state.auth.user.adaccountId);
  const user_id = useSelector((state) => state.auth.user.userData.id);
  const  toggleLeftMenu = useSelector((state) => state.utils.toggleLeftMenu);

  // mkay
  const notValidInfoGeneral = useSelector((state) => state.validation.notValidInfoGeneral);

  const intl = useIntl();

  const [fieldsError, setFieldsError] = useState([])
  const [textsError, setTextsError] = useState([])

  const testValidBudget = () => {
    const state = store.getState()
    const fieldsToAdd = []
    const errorsToAdd = []
    if(!state.creationCampagne.informationsGenerales.budget){
      let hasBudget = false;
      let missingBudget = !state.creationCampagne.audiencesAndInterests.length;
      let missingEndDate = false;
      state.creationCampagne.audiencesAndInterests.forEach(adset =>{
        hasBudget = hasBudget || adset.budget>0
        missingBudget = missingBudget || !adset.budget;
        if(state.creationCampagne.adset_budget_mode === "lifetime"){
          missingEndDate = missingEndDate || !adset.end_time
        }
      })
      if(hasBudget && missingBudget){
        fieldsToAdd.push('detailled_adsets')
        errorsToAdd.push(
          "Budget par adset: Certains adsets n'ont pas de budget défini")
      }
      else if(missingBudget){
        fieldsToAdd.push("budget")
        errorsToAdd.push(
          "Au moins un budget doit être défini au niveau de la campagne ou pour chacun des adsets")
      }
      if(missingEndDate){
        fieldsToAdd.push("dateFin")
        errorsToAdd.push(
          "Date de fin par adset: Certains adsets n'ont pas de date de fin alors que les budgets sont définis pour la durée de la campagne"
        )
      }
    }
    if(state.creationCampagne.informationsGenerales.budget_mode=== "lifetime" && !state.creationCampagne.informationsGenerales.dateFin){
      fieldsToAdd.push("dateFin")
      errorsToAdd.push(
        "Une date de fin doit être définie pour un budget défini pour la durée de la campagne")
    }
    return [fieldsToAdd, errorsToAdd]
  }

  const testFieldsFilled = () => {
    const state = store.getState()
    const fieldsToAdd = []
    const errorMessages = {
      "nomCampagne": [state.creationCampagne.informationsGenerales.nomCampagne, "Un <strong>nom de campagne</strong> doit être défini", "field"],
      "type": [state.creationCampagne.informationsGenerales.type, "Vous devez selectionner un <strong>type de campagne</strong>", "field"],
      "facebookAccountChosen": [state.creationCampagne.facebookAccountChosen, "Une <strong>page facebook</strong> par défaut doit être selectionnée", "field"],
      "instagramAccountChosen": [state.creationCampagne.instagramAccountChosen,"Une <strong>page instagram</strong> par défaut doit être selectionnée", "field"],
      "keywords": [state.creationCampagne.keywords, "Au moins un <strong>package de publicités</strong> doit être ajouté", "list"],
      "targeting_packages": [state.creationCampagne.targeting_packages, "Au moins un <strong>ciblage géo-demographique</strong> doit être ajouté", "list"],
      "countries": [state.creationCampagne.informationsGenerales.countries, "Au moins <strong>un pays ciblé</strong> doit être ajouté", "list"],
      "useros": [state.creationCampagne.configuration.useros, "Pour la promotion d'une application un <strong>OS à cibler</strong> doit être selectionné", "field"],
      "pixel": [state.creationCampagne.informationsGenerales.pixel, "Un <strong>pixel ou une application</strong> de tracking doit être selectionné", "field"],
      "application": [state.creationCampagne.informationsGenerales.application, "Une <strong>application à promouvoir</strong> doit être selectionnée", "field"],
      "ciblage": [null, "Au moins <strong>un ciblage</strong> doit être configuré (ciblage large, audiences ou interêts)", ""],
      "number_adsets": [null, "Une campagne IOS 14+ ne peut contenir plus de <strong>5 adsets</strong>", ""],
      "adset_creas": [state.creationCampagne.audiencesAndInterests, "Au moins un <strong>package de publicités</strong> doit être défini pour chaque adset", ""],
      "objectif": [state.creationCampagne.informationsGenerales.objectif, "Avec une application ou un pixel défini, vous devez selectionner <strong>un objectif</strong>", "field"]
    }
    const errorsToAdd = []
    mandatory_fields.forEach(field => {
      const [fieldValue, fieldError, fieldType] = errorMessages[field]
      if ((fieldType === "field" && !fieldValue) || 
          (fieldType === "list" && !fieldValue.length)){ 
        fieldsToAdd.push(field)
        errorsToAdd.push(fieldError)
      }
    });
    if (!advantagePlus){
      if(!state.creationCampagne.large && !state.creationCampagne.interests_packages.length && !state.creationCampagne.audiences_packages.length){
        fieldsToAdd.push("ciblage")
        errorsToAdd.push(errorMessages['ciblage'][1])
      }
      let missingCreas = false;
      state.creationCampagne.audiencesAndInterests.forEach(adset =>{
        missingCreas = missingCreas || !adset.packagesPub.length 
      })
      if (missingCreas){
        fieldsToAdd.push("detailled_adsets")
        errorsToAdd.push(errorMessages["adset_creas"][1])
      }
    }
    return [fieldsToAdd, errorsToAdd]
  }

  const testErrors = () => {
    const [fieldsBudget,errorsBudget] = testValidBudget();
    const [fieldsMissing, errorsFields] = testFieldsFilled();
    setTextsError([...errorsBudget, ...errorsFields])
    setFieldsError([...fieldsBudget, fieldsMissing])
    if (errorsBudget.length || errorsFields.length){
      return true
    }
    return false
  }


  useEffect(() => {
    if (isFirstRender.current){
      isFirstRender.current = false;
    }
    else{
      dispatch({ type: "CLEAR_ALL_INPUTS_CREATION_CAMPAIGN"});
      dispatch({type: "CLEAR_CAMPAIGN_REF_VARIABLES"});
      navigate("/creation-campagne");
    }
  }, [adaccountId]);

  useEffect(() => {
    if (load_campaign && file_configuration_id){
      safe_load_file_configuration(dispatch, adaccountId, file_configuration_id);
    }
  }, [load_campaign])

  useEffect(() => {
    if (load_ref){
      get_campaign_data(dispatch, adaccountId, user_id, true);
    }
  }, [load_ref])
  
  useEffect(()=> {
    const state = store.getState()
    const  {
      placements, 
      useros, 
      devices, 
      wireless } = state.creationCampagne.configuration;
    const {type, attribution_spec, product_catalog_id} = state.creationCampagne.informationsGenerales;
    setAdvancedMode(isAdvanced(type, attribution_spec, product_catalog_id, placements, useros, devices, wireless))
  }, [loading_campaign])

  return (
    <div className="screen_creation_campagne">
      <LeftMenu />
      <RightContainer>
        <header className="right_content_header">
          <div className="back_title">
            {toggleLeftMenu ? (
              <i
                onClick={() => dispatch(setToggleLeftMenu((prevState) => !prevState))}
                style={{ marginRight: 30, cursor: "pointer" }}
                className="fas fa-bars fa-2x"
              ></i>
            ) : null}
            <div
              className="back"
              onClick={() => {
                navigate("/campagne/liste");
                const state = store.getState()
                saveCampaignNextPage(dispatch, "");
                dispatch({ type: "CLEAR_ALL_INPUTS_CREATION_CAMPAIGN" });
              }}
            >
              <img src="/left-arrow 2.png" alt="Retour" />
            </div>
            <h1 className="right_content_header_title">{mainTitle}</h1>
          </div>
          <div className="client">
            <TopRightMenu redirectBack="/creation-campagne" />
          </div>
        </header>
        <div className="right_content_main">
          <div className="client_advanced_mode">

            <div className="advanced_mode">
              <h3 className="advanced_mode_title">
                <FormattedMessage
                  defaultMessage="Avancé"
                  id="create_campaign_screen_advaned_mode"
                />
              </h3>
              <Switch
                onChange={() => setAdvancedMode((prevState) => !prevState)}
                checked={advancedMode}
                {...label}
              />
            </div>
          </div>
          {loading_campaign||loading_ref_campaign ? (
            <Loader3 />
          ) : (
            <div className="sections">
              <section id="errors">
              {!!textsError.length && 
               <div style={{backgroundColor: "white", border: "1px solid red" }}>
                  <header style={{ paddingTop: "20px" , paddingLeft:"20px", paddingBottom:"10px"}}>
                    <h3 style={{color: "red"}}>Champs erronés</h3>
                  </header>
                  <div className="bullet-points" style={{ padding: "10px", lineHeight:"1.5" }}>
                    {textsError.map((item, index) => (
                      <div key={index}>
                        <span style={{color: "red"}}>&#8226;</span> {/* Bullet point */}
                        <span dangerouslySetInnerHTML={{ __html: item }}></span> {/* Item from the list */}
                      </div>
                    ))}
                  </div>
                </div>
              }
              </section>
              <div
                className={`${notValidInfoGeneral && "blockError"}`}//need to have something with all
                style={{ margin: "30px 0" }}
              >
                <InformationsGenerales
                  disabledMode={false}
                  advancedMode={advancedMode}
                  setAdvancedMode={setAdvancedMode}
                />
              </div>
              <div
                className={`${fieldsError.includes("keywords") && "blockError"}`}//missing accounts and stuff
                style={{ margin: "30px 0" }}
              >
                <PackagePublicite
                  disabledMode={false}
                />
              </div>
              <div
                className={`${fieldsError.includes("targeting_packages") && "blockError"}`}
                style={{ margin: "30px 0" }}
              >
                <PackageGeodemographique
                  disabledMode={advantagePlus}
                  />
              </div>
              <div
                className={`${
                  fieldsError.includes("ciblage") &&
                  "blockError"
                }`}
                style={{ margin: "30px 0" }}//i need to know what is this type it should be in ciblage
              >
                <Ciblage
                  disabledMode={advantagePlus}
                />
              </div>
              <div
                className={`${
                  fieldsError.includes("detailled_adsets") &&
                  "blockError"
                }`}
                style={{ margin: "30px 0" }}//i need to know what is this type it should be in ciblage
              >
                <ConfigurationAdsetDetaille
                  disabledMode={advantagePlus}
                />
              </div>
              {advancedMode && (
                <div
                  className={`${
                    fieldsError.includes("useros") &&
                    "blockError"
                  }`}
                  style={{ margin: "30px 0" }}//i need to know what is this type it should be in ciblage
                >
                  <Configuration
                    disabledMode={false}
                  />
                </div>
              )}
            </div>
          )}

          <div className="d-fe">
            <button
              disabled={false}//TODO change the button into leading to issues 
              style={
                false
                  ? { cursor: "not-allowed", backgroundColor: " " }
                  : {}
              }
              onClick={() => {
                if(testErrors()){
                  var element = document.getElementById("errors");
                  if (element) {
                    element.scrollIntoView({
                      behavior: "smooth",
                      block: "center"
                    });
                  }
                }
                else{
                  saveCampaignNextPage(dispatch, location)
                    .then((result)=>{
                      if (result){
                        dispatch({type:"SET_STATE_PROTECT_DETAILLED_ADSETS",payload:true});
                        navigate("/campagne/create/confirm");
                      }
                      else{
                        toast.error("Fichier de configuration de campagne invalide")
                      }
                    })
                  }
              }}
            >
              <FormattedMessage
                defaultMessage="Enregistrer"
                id="create_campaign_screen_save_button"
              />
            </button>
          </div>
        </div>
      </RightContainer>
    </div>
  );
};

export default CreationCampagne;
