import store from "../../../store"
import axios from "axios";
import axiosInstance from "../../axios.instance";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { relativeTimeRounding } from "moment";
import { saveCurrentPage } from "./usualDispatches";

export function formatPlacements(placements, useros, devices, wireless){
    let data = {
        publisher_platforms: [],
        facebook_positions: [],
        instagram_positions: [],
        messenger_positions: [],
        audience_network_positions: [],
        user_os: [],
        device_platforms: [],
        wireless_carrier: []
    };

    if (placements.length > 0) {
        placements.forEach((placement) => {
        if (placement.name === "facebook") {
            data.facebook_positions.push(placement.position);
        } else if (placement.name === "instagram") {
            data.instagram_positions.push(placement.position);
        } else if (placement.name === "messenger") {
            data.messenger_positions.push(placement.position);
        } else if (placement.name === "audience_network") {
            data.audience_network_positions.push(placement.position);
        }
        // Add the publisher platform to the list of publisher platforms if it doesn't already exist
        if (!data.publisher_platforms.includes(placement.value)) {
            data.publisher_platforms.push(placement.value);
        }
        });
    }

    // Add the user_os value to the user_os array
    if (useros ) {
        data.user_os.push(useros.value);
        }

    // Add the device value to the devices array
    if (devices.length > 0) {
        devices.map((d) => data.device_platforms.push(d.value));
    }

    // Add the wireless value to the wireless_carrier array if it is equal to "wireless"
    //this makes no sense storing wireless to store Wifi later
    // todo fix that Wifi is stored at once using the referential data in wireless

    if (wireless) {
        data.wireless_carrier.push("Wifi");
    }
    let formattedData = {}
    Object.keys(data).forEach(
      (key)=>{
        if(data[key].length >0){ 
          formattedData[key] = data[key]}
        }
    );
    return formattedData
}

export function isAdvanced(type, attribution_spec, catalog_id, placements, useros, devices, wireless){
    let advancedMode = placements.length > 0 || useros || devices.length > 0 || wireless.length > 0
    advancedMode = advancedMode || type === "APP_INSTALLS"//maybe AVANTAGE_APP to check
    advancedMode = advancedMode || catalog_id || attribution_spec;
    return advancedMode
}

const lang = navigator.language.substring(0, 2).toUpperCase();
  
function getRowLang(data, lang){
  if (lang === "FR"){
      return data.find(row => row.lang === lang).names;
  }
  else {return data.find(row => row.lang === "EN").names;}
}

export async function getDBData(dispatch, endpoint, dispatch_msg="", lang=""){
    return axiosInstance
      .get(endpoint)
      .then((res) => {
        let data;
          if (lang) {
            data = getRowLang(res.data, lang);
          }
          else {
            data=res.data;
          }
          if(dispatch_msg){
            dispatch({ type: dispatch_msg, payload: data });
          }
          return data;
      })
      .catch((err) => {
        toast.error("Données manquantes");
        console.log(err);
      });
}

export const getVariablesReference = (dispatch) => {
  const types_dispatchs = [
    ['ATTRIBUTION_WINDOWS', "SET_ATTRIBUTIONS"],
    ["CATALOG_FORMAT", "STORE_CATALOG_FORMATS"],
    ["CALL_TO_ACTION", "STORE_CALLS_TO_ACTION"],
    ["CAMPAIGN_TYPE", "STORE_CAMPAIGN_TYPES"]
  ]
  types_dispatchs.forEach(([type, dispatch_msg]) => {
    getDBData(dispatch, `/facebook_variables_reference?var_type=${type}`, dispatch_msg, lang);
  })
};

export const getConnectedAccounts = (dispatch, user_id) => {
    getDBData(dispatch, `/intersect_client/${user_id}`, "STORE_CONNECTED_ACCOUNTS")
};

export const getTags = (dispatch, adacc) => {
    return getDBData(dispatch, `/${adacc}/Tags`, "STORE_TAGS")  
  };

export const getAudiences = (dispatch, adacc) => {
    return getDBData(dispatch, `/${adacc}/audiences_package`, "STORE_AUDIENCES_PACKAGE")
}

export const getCentresInteret = (dispatch, adacc) => {
    return getDBData(dispatch, `/${adacc}/interests_package`, "STORE_INTERESTS_PACKAGE")
};

export const getPackagesGeoDemo = (dispatch, adacc) => {
    return getDBData(dispatch, `/${adacc}/geo_demo_package`, "STORE_PACKAGES_GEO_DEMO")
};

const getCatalogs = (dispatch, adacc, user_id)=> {
    getDBData(dispatch, `/${adacc}/catalogs?user_id=${user_id}`, "SET_CATALOGS")
};

const getAdvantageCustomers = (dispatch, adacc) => {
    getDBData(dispatch, `/${adacc}/existing_customers`, "STORE_CONFIGURED_CUSTOMERS")
}

const getPixels = (dispatch, adacc) => {
  return getDBData(dispatch, `/${adacc}/pixels`, "SET_PIXELS")
};

export const getPixelEvents = (dispatch, adaccountId, pixelId) => {
  return getDBData(dispatch, `/${adaccountId}/pixels/${pixelId}/eventconversions?lang=${lang}`,"GET_PIXELS_EVENTS_REFERENCE")
};

export const getFBPages = (dispatch, adaccountId, user_id) => {
  return getDBData(dispatch, `${adaccountId}/pages?user_id=${user_id}`, "STORE_FACEBOOK_ACCOUNTS")
 };

export const getInstagramAccounts = (dispatch, pageId) => {
  if (pageId){
    return axiosInstance
      .get(`/${pageId}/instagram_accounts`)
      .then((res) => {
        // console.log(res.data);
        // Check if connected_instagram_account exists
        let dataToStore = null;

        if (res.data.instagram_accounts) {
          let data = res.data.instagram_accounts.data;
          dataToStore = data.map(item=>({id:item.id, name: item.username, instagram_accounts: true }));
        } else {
          if (res.data.page_backed_instagram_accounts) {
            let data = res.data.page_backed_instagram_accounts.data;

            data = data[0];

            const { id } = data;

            dataToStore = [
              {
                id,
                name: "Votre page sélectionnée",
                page_backed_instagram_accounts: true,
              },
            ];
          }
        }
        dispatch({ type: "STORE_INSTAGRAM_ACCOUNTS", payload: dataToStore });
        return dataToStore
      })
      .catch(() => {
        toast.error("Données manquantes");
      });
    }
  else{
    return Promise.resolve([]);
  }
};

export const get_campaign_data = (dispatch, adacc, user_id, force=false) => {
  const state_campaign_ref = store.getState().creationCampagneRef
  const {
    loading_ref_campaign,
    formattedCampaignTypes,
    accounts,
    pixels,
    catalogs,
    tags,
    geoDemoPackages,
    centresInteretPackagePub
  } = state_campaign_ref;
  if (!loading_ref_campaign){
    dispatch({type:"LOADING_CAMPAIGN_REF_VARIABLES", payload:true});
    //prevent from loading too many times for no reason
    if (force || !accounts.length){
      getFBPages(dispatch, adacc, user_id);
    }
    if(!formattedCampaignTypes.length){
      getVariablesReference(dispatch);
    }
    if(force || !pixels.length){
      getPixels(dispatch, adacc);
    }
    if(force || !catalogs.length){
      getCatalogs(dispatch, adacc, user_id);
    }
    if(force || (!tags.length || !centresInteretPackagePub.length || !geoDemoPackages.length)){
      getAdvantageCustomers(dispatch, adacc);
      getTags(dispatch, adacc);
      getPackagesGeoDemo(dispatch, adacc);
      getAudiences(dispatch, adacc);
      getCentresInteret(dispatch, adacc);
    }
    dispatch({type:"LOADING_CAMPAIGN_REF_VARIABLES", payload:false});
    dispatch({type:"CAMPAIGN_REF_VARIABLES_LOADED"})
  }
}

/* 
this part is loading from an existing campaign to initiate file
i guess it will be done in backend instead of here

const getFbAndInstaAccounts = async () => {
    const modifyCampaignId = params.id;
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_GRAPH_API
        }/${modifyCampaignId}/adsets?fields=adcreatives{actor_id,instagram_actor_id}&access_token=${
          accessToken
          // process.env.NODE_ENV === "development" ? dev_token : accessToken
        }`
      );

      if (response.status === 200) {
        const { data } = response.data;
        const { actor_id, instagram_actor_id } = data[0].adcreatives.data[0];

        if (actor_id && instagram_actor_id) {
          fbAccounts.map((acc) => {
            if (actor_id && acc.id == actor_id) {
              dispatch({
                type: "STORE_FACEBOOK_ACCOUNT_CHOSEN",
                payload: acc,
              });

              const instAccId = instagram_actor_id;
              dispatch({
                type: "STORE_INSTAGRAM_ACCOUNT_CHOSEN",
                payload: instagram_actor_id,
              });
              axiosInstance
                .get(`/${acc.id}/instagram_accounts`)
                .then((res) => {
                  // Check if connected_instagram_account exists
                  let dataToStore = null;

                  if (res.data.instagram_accounts) {
                    let data = res.data.instagram_accounts.data;

                    data = data[0];

                    const { id, username } = data;

                    dataToStore = [
                      { id, name: username, instagram_accounts: true },
                    ];
                  } else {
                    if (res.data.page_backed_instagram_accounts) {
                      let data = res.data.page_backed_instagram_accounts.data;

                      data = data[0];

                      const { id } = data;

                      dataToStore = [
                        {
                          id,
                          name: "Votre page sélectionnée",
                          page_backed_instagram_accounts: true,
                        },
                      ];
                    }
                  }
                  dataToStore.map((acc) => {
                    if (acc.id == instAccId) {
                      dispatch({
                        type: "STORE_INSTAGRAM_ACCOUNT_CHOSEN",
                        payload: acc,
                      });
                    }
                  });

                  dispatch({
                    type: "STORE_INSTAGRAM_ACCOUNTS",
                    payload: dataToStore,
                  });
                })
                .catch((err) => {
                  console.log(err);
                  toast.error(
                    "Une erreur s'est produite, veuillez réessayer plus tard"
                  );
                });
            }
          });
        }
      }
    } catch (error) {
      console.log("FB Graph seeor: ", error);
    }
  };

  const getDailyFBGraph = async () => {
    const modifyCampaignId = params.id;
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_GRAPH_API
        }/${modifyCampaignId}?fields=daily_budget,lifetime_budget&access_token=${
          accessToken
          // process.env.NODE_ENV === "development" ? dev_token : accessToken
        }`
      );

      if (response.status === 200) {
        const data = response.data;

        if (data.hasOwnProperty("lifetime_budget")) {
          dispatch({
            type: "HANDLE_CHANGE_INFO_GENERALE",
            payload: { name: "lifetime", value: data.lifetime_budget / 100 },
          });
        }
        if (data.hasOwnProperty("daily_budget")) {
          dispatch({
            type: "HANDLE_CHANGE_INFO_GENERALE",
            payload: { name: "daily", value: data.daily_budget / 100 },
          });
        }
      }
    } catch (error) {
      console.log("FB Graph seeor: ", error);
    }
  };

  const getTypeFbGraph = async () => {
    const modifyCampaignId = params.id;
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_GRAPH_API
        }/${modifyCampaignId}/adsets?fields=optimization_goal&access_token=${
          accessToken
          // process.env.NODE_ENV === "development" ? dev_token : accessToken
        }`
      );

      if (response.status === 200) {
        const { data } = response.data;
        if (data[0].hasOwnProperty("optimization_goal")) {
          dispatch({
            type: "HANDLE_CHANGE_INFO_GENERALE",
            payload: { name: "type", value: data[0]["optimization_goal"] },
          });
        }
      }
    } catch (error) {
      console.log("FB Graph seeor: ", error);
    }
  };

  const getPixelFbGraph = async () => {
    const modifyCampaignId = params.id;
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_GRAPH_API
        }/${modifyCampaignId}/adsets?fields=promoted_object&access_token=${
          accessToken
          // process.env.NODE_ENV === "development" ? dev_token : accessToken
        }`
      );

      if (response.status === 200) {
        const { data } = response.data;
        const promoted_object = data[0]["promoted_object"];
        if (promoted_object.hasOwnProperty("pixel_id")) {
          dispatch({
            type: "HANDLE_CHANGE_INFO_GENERALE",
            payload: { name: "pixel", value: promoted_object.pixel_id },
          });
        }

        if (promoted_object.hasOwnProperty("application_id")) {
          dispatch({
            type: "HANDLE_CHANGE_INFO_GENERALE",
            payload: { name: "pixel", value: promoted_object.application_id },
          });
        }

        if (promoted_object.hasOwnProperty("custom_event_type")) {
          dispatch({
            type: "HANDLE_CHANGE_INFO_GENERALE",
            payload: {
              name: "objectif",
              value: promoted_object.custom_event_type,
            },
          });
        }
      }
    } catch (error) {
      console.log("FB Graph seeor: ", error);
    }
  };

  const getProductCatalogIdFbGraph = async () => {
    const modifyCampaignId = params.id;
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_GRAPH_API
        }/${modifyCampaignId}?fields=promoted_object&access_token=${
          accessToken
          // process.env.NODE_ENV === "development" ? dev_token : accessToken
        }`
      );

      if (response.status === 200) {
        const data = response.data;
        const promoted_object = data.promoted_object;
        if (
          promoted_object &&
          promoted_object.hasOwnProperty("product_catalog_id")
        ) {
          dispatch({
            type: "HANDLE_CHANGE_INFO_GENERALE",
            payload: {
              name: "product_catalog_id",
              value: promoted_object.product_catalog_id,
            },
          });
        }
      }
    } catch (error) {
      console.log("FB Graph seeor: ", error);
    }
  };

  
  */

  //just kept for detailled adset atm
const formatGeoDeo = () => {
    const targeting_packages = []
    //this one feels weird
    let targetingPackages = null;
    if (targeting_packages && targeting_packages.length > 0) {
      targetingPackages = targeting_packages.map((item) => {
        let packageGD = item;

        if (item.regions) {
          packageGD["regions"] = item.regions.map((elem) => {
            delete elem.id;
            return elem;
          });
        }
        if (item.cities) {
          packageGD["cities"] = item.cities.map((elem) => {
            delete elem.id;
            return elem;
          });
        }
        if (item.excluded_cities) {
          packageGD["excluded_cities"] = item.excluded_cities.map((elem) => {
            delete elem.id;
            return elem;
          });
        }
        if (item.excluded_regions) {
          packageGD["excluded_regions"] = item.excluded_regions.map(
            (elem) => {
              delete elem.id;
              return elem;
            }
          );
        }

        return packageGD;
      });
    }

};

let var_target_dispatch = [
  ["nomCampagne", "campaign", "HANDLE_CHANGE_INFO_GENERALE"],
  ["file_configuration_id", "id", null],
  ["type", "campaign_type", "HANDLE_CHANGE_INFO_GENERALE"],
  //["dateDebut", "start_time", "HANDLE_CHANGE_INFO_GENERALE"],
  //["dateFin", "end_time", "HANDLE_CHANGE_INFO_GENERALE"],
  ["application", "application", "HANDLE_CHANGE_INFO_GENERALE"],
  ["product_catalog_id", "product_catalog_id", "HANDLE_CHANGE_INFO_GENERALE"],
  ["campaign_id", "campaign_id", "SET_CAMPAIGN_ID"],
  //["budget", "daily_budget", "HANDLE_CHANGE_INFO_GENERALE"],
  //["budget", "lifetime_budget", "HANDLE_CHANGE_INFO_GENERALE"],
  ["attribution_spec", "attribution_spec", "HANDLE_CHANGE_INFO_GENERALE"],
  //[countries, "countries"]
  ["existing_customer_budget_percentage", "existing_customer_budget_percentage", "HANDLE_CHANGE_INFO_GENERALE"],//not sure this works
  ["keywords", "keywords", "STORE_PUB_PACKAGES"],
  ["packagePubliciteMiseAJour", "updated_creas", "HANDLE_CHANGE_PACKAGE_PUB_MISE_A_JOUR"],
  ["packagePubliciteDesactive", "deprecated_creas", "HANDLE_CHANGE_PACKAGE_PUB_DESACTIVE"],
  ["targeting_packages", "targeting_packages", "SET_PACKAGE_GEO_DEMO"], //specific formating it seems
  ["large", "broad", "HANDLE_CHANGE_CIBLAGE_LARGE"],
  //missing list centres interests, list audiences, check list package geodemo
  ["audiences_packages", "audiences_packages", "HANDLE_CHANGE_AUDIENCE"],
  ["interests_packages","interests_packages", "HANDLE_CHANGE_CENTRE_INTERET"]
]

async function update_or_create_file_configuration(dispatch, adaccountId, file_configuration_id,author, campaign_id, single_campaign_configuration){
  if (file_configuration_id){
    return axiosInstance
    .post(
      `/${adaccountId}/single_campaign_configuration/${file_configuration_id}?current_author=${author}`,
      single_campaign_configuration
    )
    .then(() => {
      console.log("campaign file updated");
      return true
      // getSingleCampaignDetails();
    })
    .catch((err) => {
      console.error("Error in compaign update", err);
    });
  }
  else {
    single_campaign_configuration['author'] = author;
    if (campaign_id){
      single_campaign_configuration['facebook_campaign_id'] = campaign_id;
    }
    return axiosInstance
      .put(`/${adaccountId}/single_campaign_configuration`, single_campaign_configuration)
      .then((res) => {
        const data = res.data
          //we are creating a file but data is already saved into it, not need to reload it later
          dispatch({type: "CAMPAIGN_LOADED"})
          dispatch({
            type: "SET_FILE_ID",
            payload: data['result'],
          });
          return new Promise((resolve)=>{
            setTimeout(()=>{
              resolve(data);
            }, 200)
          })
      })
      .catch((err) => {
        console.log("erreur create single campaign configuration", err);
      });
    }
}

export async function saveSingleCampaign(dispatch){
  const state = store.getState()
  const {user: {adaccountId}, auth0User: {nickname: author}} = state.auth;
  const {
    file_configuration_id,
    informationsGenerales: {
      nomCampagne,
      type,
      dateDebut,
      dateFin,
      objectif,
      pixel,
      countries,
      languesChoisies,
      budget,
      budget_mode,
    },
    excluded_audiences_packages,
    excluded_interests_packages,
    targeting_packages,
    configuration: {
      placements,
      useros,
      devices,
      wireless,
    },
    advantagePlus,
    campaign_id,
    facebookAccountChosen,
    instagramAccountChosen,
    audiencesAndInterests,
    adset_budget_mode
    } = state.creationCampagne;
  if (nomCampagne){
    let campaign_config = {}
    var_target_dispatch.forEach(([stateKey, key, dispatch_msg])=>{
      if (dispatch_msg==="HANDLE_CHANGE_INFO_GENERALE"){
        if (state.creationCampagne.informationsGenerales[stateKey]){
          campaign_config[key] = state.creationCampagne.informationsGenerales[stateKey]
        }
      }
      else {
        if (state.creationCampagne[stateKey]){
          campaign_config[key] = state.creationCampagne[stateKey];
        }
      }
    })
    if (budget){
      campaign_config[`${budget_mode}_budget`] = budget
    }
    if (dateDebut){
      campaign_config["start_time"] = dateDebut.toISOString().substring(
        0, dateDebut.toISOString().length - 5)
    }
    if (dateFin){
      campaign_config["end_time"] = dateFin.toISOString().substring(
        0, dateFin.toISOString().length - 5)
    }
    if (countries.length>0) {
      //this is the issue with your package of countries
      // should be only used in the case of advantage
      if (targeting_packages.length>0){
        //test if the countries have changed
        let ref_countries = [...new Set(targeting_packages[0]['countries'])];
        let new_countries = [...new Set(countries.map((c) => c.key))];
        function arraysEqual(a, b) {
          if (a.length !== b.length) return false;
          for (let i = 0; i < a.length; i++) {
            if (a[i] !== b[i]) return false;
          }
          return true;
        }
        if (! arraysEqual(ref_countries,new_countries)){
          campaign_config['targeting_packages']= [
            {
              countries: new_countries,
              id: uuidv4(),
              name: "advantage",
              ...(type === "ADVANTAGE_APP" && {
                locales: languesChoisies.map((l) => l.key),
              }),
            },
          ];
        }
      }
      else{
        campaign_config['targeting_packages']= [
          {
            countries: countries.map(country=> country.key),
            id: uuidv4(),
            name: "advantage",
            ...(type === "ADVANTAGE_APP" && {
              locales: languesChoisies.map((l) => l.key),
            }),
          },
        ];
      }
    }
    const actor_ids = [];
    if (facebookAccountChosen){
      actor_ids.push({
        "actor_id":parseInt(facebookAccountChosen["id"]),
        "type":["generic"]
      });
    }
    const instagram_actor_ids = [];
    if(instagramAccountChosen){
      instagram_actor_ids.push({
        "instagram_actor_id":parseInt(instagramAccountChosen["id"]),
        "type":["generic"]
      });
    }
    let formattedPixels = []
    if (pixel){
      // campaign_config["application"] = pixel;
      if (pixel["kind"] === "Application"){
        campaign_config["application"] = pixel; //test if that passes construction
      //   // {
      //   //   id:pixel['id'],
      //   //   object_store_urls:pixel["object_store_urls"],
      //   //   name:pixel["name"]
      //   // }
      }
      else {
        formattedPixels.push({
          "pixel":pixel["id"],
          "type":["generic"]
        })
      }
    }
    if (objectif){
      campaign_config['objective'] = objectif['custom_event_type'];
      if ("rule" in objectif){
        campaign_config['pixel_rule'] = objectif['rule'];
      }
    }
    campaign_config['placements'] = formatPlacements(placements, useros, devices, wireless)
    if(excluded_audiences_packages.length>0 || excluded_interests_packages.length>0){
      campaign_config['exclusions'] = {
        "audiences_packages": excluded_audiences_packages,
        "interests_packages": excluded_interests_packages
      }
    }
    let adsetsDetailToSend = audiencesAndInterests.map((elem) => {
      const { exclusionInterests, exclusion_audiences, packagesPub,budget, start_time, end_time,...rest } =
        elem;
      if (budget){
        rest[`${adset_budget_mode}_budget`] = budget
      } 

      rest["start_time"] = start_time
        ? start_time.toISOString().substring(0, start_time.toISOString().length - 5)
        : "";
      rest["end_time"] = end_time
        ? end_time.toISOString().substring(0, end_time.toISOString().length - 5)
        : "";
      
      delete rest.type;
      
      return {
        ...rest,
        package_creas: packagesPub,
        exclusions: {
          audiences_packages: exclusion_audiences,
          interests_packages: exclusionInterests,
        },
      };
    });
    campaign_config['detailled_adset'] = adsetsDetailToSend;

    const single_campaign_configuration = {
      name: nomCampagne,
      data: {
        campaign_configurations: [campaign_config],
        pixels: formattedPixels,
        actor_ids: actor_ids,
        instagram_actor_ids: instagram_actor_ids,
      },
      //author:
      //  facebookAccountChosen.global_brand_page_name ||
      //  facebookAccountChosen.name,
      //modified_by:username,//to find where it is
        //facebookAccountChosen.global_brand_page_name ||
        //facebookAccountChosen.name,
    };
    return await update_or_create_file_configuration(dispatch, adaccountId, file_configuration_id, author, campaign_id, single_campaign_configuration);
  }
  return Promise.reject(new Error('The campaign must have a name'));
}

export async function saveCampaignNextPage(dispatch, location){
  return await saveSingleCampaign(dispatch)
    .then(()=>{
      dispatch(
        saveCurrentPage(location));
      return true;
    })
    .catch(error=>{
      //todo should toast something and maybe return something
      console.log(error)
      return false;
    })
}

export const tempo_loading_data = async (data, data_name) =>{
  let i =0
  while(i<10 && !data.length){
    i+=1;
    await new Promise(resolve => setTimeout(resolve, 200));
  }
  if(!data.length){
    console.log(`${data_name} never loaded`);
  }
}

async function loadSingleCampaign(dispatch, file_configuration){
  const state = store.getState();
  const {loading_campaign, advantagePlus} =  state.creationCampagne;
  const {adaccountId, 
    userData: {token: accessToken, id: user_id} } = state.auth.user;
  if (!loading_campaign){
    dispatch({type:"LOADING_CAMPAIGN", payload:true});
    const campaign_config = file_configuration['data']['campaign_configurations'][0];
    var_target_dispatch.forEach(([field, key, dispatch_msg])=>{
      if (key in campaign_config && dispatch_msg ){
        if (dispatch_msg==="HANDLE_CHANGE_INFO_GENERALE"){
          dispatch({type: dispatch_msg, payload: {
            "name":field, "value":campaign_config[key]}});
        }
        else{
          dispatch({type:dispatch_msg, payload:campaign_config[key]});
        }
      }
    })
    if("lifetime_budget" in campaign_config && campaign_config['lifetime_budget']){
      dispatch({
        type: "HANDLE_CHANGE_INFO_GENERALE", 
        payload: {"name":"budget", "value":campaign_config['lifetime_budget']}})
      dispatch({
        type: "HANDLE_CHANGE_INFO_GENERALE", 
        payload: {"name":"budget_mode", "value":"lifetime"}})
    }
    else if ("daily_budget" in campaign_config){
      dispatch({
        type: "HANDLE_CHANGE_INFO_GENERALE", 
        payload: {"name":"budget", "value":campaign_config['daily_budget']}
      })
    }
    if("start_time" in campaign_config && campaign_config['start_time']){
      dispatch({
        type: "HANDLE_CHANGE_INFO_GENERALE", 
        payload: {"name":"dateDebut", 
        "value":new Date(campaign_config['start_time']+"Z")}})
    }
    if("end_time" in campaign_config && campaign_config['end_time']){
      dispatch({
        type: "HANDLE_CHANGE_INFO_GENERALE", 
        payload: {"name":"dateFin", 
        "value":new Date(campaign_config['end_time']+"Z")}})
    }
    
    if ("campaign_type" in campaign_config && ['ADVANTAGE_VALUE', 'ADVANTAGE_APP'].includes(campaign_config["campaign_type"])){ 
      dispatch({type:"ENABLE_ADVANTAGE_PLUS"});
    }
    //tobecleaned when not necessary
    if ('placements' in campaign_config){
      if('user_os' in campaign_config['placements'] && campaign_config['placements']['user_os'].length){
        dispatch(
          {type:"HANDLE_CHANGE_CONFIGURATION", 
          payload:{
            name:"useros", 
            value:{
              name:campaign_config['placements']['user_os'][0], 
              value:campaign_config['placements']['user_os'][0]
            }
          }})
      }
      if('wireless_carrier' in campaign_config['placements'] && campaign_config['placements']['wireless_carrier'].length){
        dispatch(
          {type:"HANDLE_CHANGE_CONFIGURATION", 
          payload: {name:"wireless", value:campaign_config['placements']['wireless_carrier'][0]}})   
      }
      if('device_plateforms' in campaign_config['placements'] && campaign_config['placements']['device_plateforms'].length){
        dispatch(
          {type:"HANDLE_CHANGE_CONFIGURATION", 
          payload: {name:"devices", 
          value:campaign_config['placements']['device_plateforms'].map(
            (device)=>({name:device, value:device}))}})   
      }
      if ("publisher_platforms" in campaign_config['placements'] && 
        campaign_config['placements']['publisher_platforms'].length){
          dispatch(
            {type:"HANDLE_CHANGE_CONFIGURATION", 
            payload:{name:"publisherPlateforms", 
            value:campaign_config['placements']['publisher_platforms'].map(
              (platform)=>({name:platform, value:platform}))}})   
      }
      const plateform_positions = {
        "facebook_positions":"facebook",
        "messenger_positions":"messenger",
        "instagram_positions":"instagram",
        "audience_network_positions": "audience_network"
      }
      let placements = [];
      for (let [key, platform] of Object.entries(plateform_positions)){
        if(key in campaign_config['placements'] && platform['placements'][key].length){
          platform['placements'][key].forEach((position)=>{
            placements.push({
              name: platform,
              value: platform,
              position: position,
            });
          });
        }
      }
      if (placements.length){
        dispatch(
          {type:"HANDLE_CHANGE_CONFIGURATION", payload:{name:"placements", value:placements}});
      }
    }

    if('exclusions' in campaign_config && campaign_config['exclusions']['audiences_packages'].length){
      dispatch({
        type:"HANDLE_CHANGE_EXCLUSION_AUDIENCE",
        payload: campaign_config['exclusions']['audiences_packages']});
    }
    if('exclusions' in campaign_config && campaign_config['exclusions']['interests_packages'].length){
      dispatch({
        type:"HANDLE_CHANGE_EXCLUSION_CENTRE_INTERET",
        payload: campaign_config['exclusions']['interests_packages']});
    }
    if (["ADVANTAGE_VALUE", "ADVANTAGE_APP"].includes(campaign_config['campaign_type'])){
      if ('targeting_packages' in campaign_config && 
          campaign_config['targeting_packages'].length>0){
        //need to download all the countries and find the right ones
        if ('countries' in campaign_config['targeting_packages'][0] && 
          campaign_config['targeting_packages'][0]['countries'].length>0){
          axios
            .get(
              `${process.env.REACT_APP_GRAPH_API}/search?access_token=${accessToken}&location_types=["country"]&type=adgeolocation&limit=1000`
            )
            .then((result) => {
              const countries = campaign_config['targeting_packages'][0]['countries']
                .map((c) => result.data.data.find((country) => country.key === c));
              dispatch({
                type: "HANDLE_CHANGE_INFO_COUNTRIES",
                payload: countries,
              });
            })
            .catch((err) => console.log(err));
        }
        //same for locales
        if ('locales' in campaign_config['targeting_packages'][0] &&
          campaign_config['targeting_packages'][0]['locales'].length>0){
          axios
            .get(
              `${process.env.REACT_APP_GRAPH_API}/search?access_token=${accessToken}&type=adlocale&limit=1000`
                )
            .then((result) => {
              const langues =
              campaign_config['targeting_packages'][0]['locales'].map(
                (l) => result.data.data.find((langue) => langue.key === l));
              dispatch({
                type: "HANDLE_CHANGE_INFO_LANGUAGE",
                payload: langues,
              });
            })
            .catch((err) => console.log(err));
        }
      }
      else if ('countries' in campaign_config && campaign_config['countries'].length){
        axios
            .get(
              `${process.env.REACT_APP_GRAPH_API}/search?access_token=${accessToken}&location_types=["country"]&type=adgeolocation&limit=1000`
            )
            .then((result) => {
              const countries = campaign_config['countries']
                .map((c) => result.data.data.find((country) => country.key === c));
              dispatch({
                type: "HANDLE_CHANGE_INFO_COUNTRIES",
                payload: countries,
              });
            })
            .catch((err) => console.log(err));
      }
    }
  
    
    if (file_configuration['data'].actor_ids && 
        file_configuration['data']['actor_ids'].length){
      getFBPages(dispatch, adaccountId, user_id)
      .then((accounts) => {
          const facebookAccount = accounts.find(account => account.id === file_configuration['data']['actor_ids'][0]['actor_id'].toString());
          dispatch({type:"STORE_FACEBOOK_ACCOUNT_CHOSEN", payload:facebookAccount});
        }
      )
      if (file_configuration['data'].instagram_actor_ids && 
          file_configuration['data']['instagram_actor_ids'].length){
        getInstagramAccounts(dispatch,file_configuration['data']['actor_ids'][0]['actor_id'])
          .then(instagramAccounts =>{
            const instagramAccount = instagramAccounts.find(
              account => account.id === file_configuration['data']['instagram_actor_ids'][0]['instagram_actor_id'].toString());
            dispatch({type:"STORE_INSTAGRAM_ACCOUNT_CHOSEN", payload:instagramAccount});    
          })
        }
    }
    if ((file_configuration['data'].pixels && 
        file_configuration['data']['pixels'].length)||campaign_config["application"]){
      getPixels(dispatch, adaccountId)
        .then((pixels)=>{
          if (file_configuration['data'].pixels && 
            file_configuration['data']['pixels'].length){
            const pixel = pixels["pixels"].find(pix => pix.id === file_configuration['data']['pixels'][0]['pixel'].toString());
            if(pixel){
              pixel.kind = "Pixel"
              dispatch({type:"HANDLE_CHANGE_INFO_GENERALE", payload:{name:"pixel", value:pixel}})  
              }
            }
          if(campaign_config["application"] && Object.keys(campaign_config["application"]).length){
            const pixel = pixels["applications"].find(pix => pix.id === campaign_config["application"]["id"]);
            pixel.kind = "Application"
            if (["ADVANTAGE_APP", "ADVANTAGE_VALUE"].includes(campaign_config['type'])){
              dispatch({type:"HANDLE_CHANGE_INFO_GENERALE", payload:{name:"application", value:pixel}})
              }
            else { 
              dispatch({type:"HANDLE_CHANGE_INFO_GENERALE", payload:{name:"pixel", value:pixel}})  
            }
          }
          })
      if ("objective" in campaign_config){
        let idpixel = ""
        if (file_configuration['data'].pixels && file_configuration['data']['pixels'].length){
          idpixel = file_configuration['data']['pixels'][0]['pixel'];
        }
        else {
          idpixel=campaign_config["application"]["id"];
        }
        getPixelEvents(dispatch, adaccountId, idpixel)
          .then(pixelEvents=> {
            const selected_events = pixelEvents.filter(event=>event['custom_event_type']===campaign_config['objective']);
            let event;
            if ('pixel_rule' in campaign_config){
              event = selected_events.find(event=>event['rule']===campaign_config['pixel_rule']);
            }
            else {
              event = selected_events.find(event => !('rule' in event));
            }
            dispatch({type:"HANDLE_CHANGE_INFO_GENERALE", payload:{name:"objectif", value:event}});
          })
      }    
    }
    const audiences_packages = await getAudiences(dispatch, adaccountId)
    const interests_packages = await getCentresInteret(dispatch, adaccountId)
    if ("detailled_adset" in campaign_config && campaign_config['detailled_adset'].length){
      let adsetBudgetMode = "daily";
      let detailledAdset = campaign_config['detailled_adset'].map((adset)=>{
        if (adset['start_time']){
          adset['start_time'] = new Date(adset['start_time']+"Z")
        }
        if (adset['end_time']){
          adset['end_time'] = new Date(adset['end_time']+"Z");
        }
        if ("broad" in adset){
          adset['type'] = "ciblage_large"
        }
        else if ("audiences_package" in adset ){
          adset['type'] = "audience"
        }
        else{
          adset['type'] = "interest"
        }
        if (!("name" in adset)){
          //TODO remove this when all old files are deleted
          if(adset['type'] === "ciblage_large"){
            adset['name']= "ciblage large"
          }
          else if(adset['type'] === "audience"){
            adset['name'] = audiences_packages.find(audience => audience.id === adset["audiences_package"])["name"]
          }
          else if(adset['type'] === "interest"){
            adset['name'] = interests_packages.find(interests => interests.id === adset["interests_package"])["name"]
         
          }
        }
        if (adset['exclusions']['audiences_packages']){
          adset['exclusion_audiences'] = adset['exclusions']['audiences_packages'];
          //TODO remove this when all old files are deleted
          adset['exclusion_audiences'].forEach(current_audience=>{
            if (!("name" in current_audience)){
              current_audience["name"] = audiences_packages.find(audience => audience.id === current_audience['id'])["name"]
            }
          })
          
        }
        if (adset['exclusions']['interests_packages']){
          adset['exclusionInterests'] = adset['exclusions']['interests_packages'];
          //TODO remove this when all old files are deleted
          adset['exclusionInterests'].forEach(current_interests=>{
            if (!("name" in current_interests)){
              current_interests["name"] = interests_packages.find(interests => interests.id === current_interests['id'])["name"]
            }
          })
        }
        if ("lifetime_budget" in adset && adset["lifetime_budget"]){
          adset['budget'] = adset["lifetime_budget"]
          adsetBudgetMode = "lifetime"
        }
        else if ("daily_budget" in adset && adset["daily_budget"]){
          adset['budget'] = adset["daily_budget"]
        }
        else {
          adset['budget'] = ""
        }
        adset["packagesPub"] = adset["package_creas"];
        delete adset.package_creas;
        delete adset.exclusions;
        return adset;
      });
      if (!advantagePlus){
        dispatch({type:"SET_STATE_PROTECT_DETAILLED_ADSETS", payload:true});
        dispatch({type:"SET_DAILY_OR_LIFETIME_BUDGET_ADSETS", payload: adsetBudgetMode})
        dispatch({type:"SET_AUDIENCES_AND_INTERESTS", payload:detailledAdset});
      }
    }
    dispatch({type:"LOADING_CAMPAIGN", payload:false});
    dispatch({type:"CAMPAIGN_LOADED"})
  }
}

export function safe_load_file_configuration(dispatch, adaccountId, file_configuration_id){
  axiosInstance
    .get(`/${adaccountId}/single_campaign_configuration/${file_configuration_id}`)
    .then((res) => {
      const file_configuration = res.data;
      if ((file_configuration.hasOwnProperty("draft")&& !file_configuration.draft)||
        (!file_configuration.hasOwnProperty("draft"))) {
        delete file_configuration['id'];  
        axiosInstance
          .put(`/${adaccountId}/single_campaign_configuration`,file_configuration)
          .then((res) =>{
            dispatch({type:"SET_FILE_ID", payload:res.data["result"]});
          })
        }
        loadSingleCampaign(dispatch, file_configuration);
      }
    );
}