import React from "react";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const PopupFenetreAvertissementMonitoringCampagne = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    activateNavigationPopupFenetreAvertissementMonitoringCampagne,
    navigateTo,
  } = useSelector(state => state.utils);

  const continuer = () => {
    if (activateNavigationPopupFenetreAvertissementMonitoringCampagne) {
      if (navigateTo) {
        navigate(navigateTo);
      }
    }

    dispatch({ type: "HIDE_POPUP_AVERTISSEMENT_MONITORING_CAMPAGNE" });
    dispatch({ type: "UNSET_START_SETTING_STRATEGY_MONITORING_CAMPAGNE" });
  };

  const cancel = () => {
    dispatch({ type: "HIDE_POPUP_AVERTISSEMENT_MONITORING_CAMPAGNE" });
  };

  return (
    <div className='fenetre_avertissement_monitoring_campagne'>
      <div className='content'>
        <h3 className='title'>
          Attention si vous n'enregistrez pas, vos modifications seront perdues.
        </h3>
        <div className='buttons'>
          <button onClick={cancel} className='cancel'>
            Annuler
          </button>
          <button onClick={continuer} className='submit'>
            Continuer
          </button>
        </div>
      </div>
    </div>
  );
};

export default PopupFenetreAvertissementMonitoringCampagne;
