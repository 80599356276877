import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SinglePackageItemAdSetDetaille from "../../../components/SinglePackageItemAdSetDetaille/SinglePackageItemAdSetDetaille";
import { FormattedMessage } from "react-intl";
import BaseAlertMessage from "../../../components/AlertMessages/BaseAlertMessage";
import GenericSection from "./genericSection";

const ConfigurationAdsetDetaille = ({ disabledMode }) => {
  //todo maybe add a new mode
  // to protect partially detailled adset
  // and to count the numbers of adsets if IOS14+
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [alertBudgetAdset, setAlertBudgetAdset] = useState(false)
  const updateTimer = useRef(null);

  const {
    keywords, packagePubliciteMiseAJour, targeting_packages, large,
    interests_packages, excluded_interests_packages ,
    audiences_packages, excluded_audiences_packages,
    informationsGenerales: { dateDebut, dateFin},
    loading_campaign, advantagePlus,
    protectedDetailledAdsets,audiencesAndInterests,
    adset_budget_mode
  } = useSelector(state => state.creationCampagne);

  const getCentresInteretEtAudiences = async () => {
    let detailledAdsets = [];
    if (targeting_packages.length){
      targeting_packages.forEach((targeting_package)=>{
        let default_detailledAdset = {
          packagesPub: keywords,
          n_adsets: 1,
          budget: "",
          start_time: dateDebut, 
          end_time: dateFin,
          targeting_packages: {id:targeting_package.id,name:targeting_package.name},
          exclusion_audiences: excluded_audiences_packages.map(
            (audience_package)=>({"name": audience_package.name, "id": audience_package.id})),
          exclusionInterests: excluded_interests_packages.map(
            (interests_package)=>({"name": interests_package.name, "id": interests_package.id})),
        }
        if (large) {
           detailledAdsets.push({
            broad:true,
            name: "ciblage large",
            type: "ciblage_large",
            ...default_detailledAdset
          });
        }
      
        audiences_packages.forEach((item) => (
          detailledAdsets.push({
            name:item.name,
            "audiences_package":item.id,
            type: "audience",
            ...default_detailledAdset
        })));

        interests_packages.forEach((item) => (
          detailledAdsets.push({
            name: item.name,
            "interests_package":item.id,
            type: "interest",
            ...default_detailledAdset
          }))
        );
      })
    }
    const sortedDetailledAdsets = detailledAdsets.sort((a, b) => {
      //put ciblage large first then audience then interest
      if (a.type === "ciblage_large") {
        return -1;
      }
      else if (b.type === "ciblage_large") {
        return 1;
      }
      else if (a.type === "audience" ){
        return -1
      }
      else if (b.type === "audience"){
        return 1
      }
      return 0;
    });
    if (!advantagePlus) {
      dispatch({type:"SET_AUDIENCES_AND_INTERESTS", payload:sortedDetailledAdsets});
    }
  }
  

  const changeBudgetDailyLifetime = e => {
    dispatch({
      type: "SET_DAILY_OR_LIFETIME_BUDGET_ADSETS",
      payload: e.target.value,
    });
  };

  const proceedAndContinue = (result, e) => {
    if(result){
      dispatch({ type: "SET_BUDGET_CAMPAGNE_ZERO" });
    }
    setAlertBudgetAdset(false);
  };

  useEffect(() => {
    //prevent multiple updates leading to issues with protected state in async
    clearTimeout(updateTimer.current)
    updateTimer.current = setTimeout(()=>{
      if(!loading_campaign && !protectedDetailledAdsets){
        getCentresInteretEtAudiences();
      }
      else{
        dispatch(({type:"SET_STATE_PROTECT_DETAILLED_ADSETS",payload:false}))
      }
    },500)
  }, [
    audiences_packages,
    interests_packages,
    targeting_packages,
    large
  ]);

  const handleRemoveItem = (index) => {
    dispatch({ type: "REMOVE_LINE_ADSETS_DETAILLE", payload: index });
  };

  return (
    <GenericSection
      disabledMode={disabledMode}
      toggle={toggle}
      setToggle={setToggle}
      title={<FormattedMessage
        defaultMessage='Configuration détaillée des adsets'
        id='create_campaign_configuration_adset_detaille_title'
      />}
      test_validation={targeting_packages.length > 0}
      content={
        <div className='ads_detaille'>
          {alertBudgetAdset &&(
            <BaseAlertMessage 
            message={
              `Attention: Définir un budget par adset n'est pas compatible 
                avec un budget défini au niveau de la campagne.
                Le budget de la campagne sera mis à 0.

                Voulez-vous continuer ?`}
            callback={proceedAndContinue}
            />
          )}
          <header className='ads_detaille_header'>
            <p>
              <FormattedMessage
                defaultMessage='Ciblages'
                id='create_campaign_configuration_adset_detaille_ciblages'
              />
            </p>
            <p>
              <FormattedMessage
                defaultMessage='Nombre adsets'
                id='create_campaign_configuration_adset_detaille_nbr_adset'
              />
            </p>
            <p>
              <FormattedMessage
                defaultMessage='Ciblage geo-demo'
                id='create_campaign_configuration_adset_detaille_ciblage_geo_demo'
              />
            </p>
            <p>
              <FormattedMessage
                defaultMessage='Package de publicité'
                id='create_campaign_configuration_adset_detaille_package_pub'
              />
            </p>
            <p>
              <FormattedMessage
                defaultMessage='Exclusions'
                id='create_campaign_configuration_adset_detaille_exclusions'
              />
            </p>
            <p>
              <FormattedMessage
                defaultMessage='Date'
                id='create_campaign_adset_detail_date'
              />
            </p>
            <p>
              <FormattedMessage
                defaultMessage='Budget adset'
                id='create_campaign_configuration_adset_detaille_budget_adset'
              />
              <select value={adset_budget_mode} onChange={changeBudgetDailyLifetime}>
                <option value='daily'>Daily</option>
                <option value='lifetime'>Lifetime</option>
              </select>
            </p>
            <p></p>
          </header>
          <div className='ads_detaille_content'>
            {audiencesAndInterests && audiencesAndInterests.length > 0 ? (
            <React.Fragment>
                {audiencesAndInterests.map((item, index) => (
                <SinglePackageItemAdSetDetaille
                  key={index}
                  data={item}
                  index={index}
                  keywords={keywords.concat(packagePubliciteMiseAJour)}
                  excluded_audiences_packages={excluded_audiences_packages}
                  excluded_interests_packages={excluded_interests_packages}
                  handleRemoveItem={() => handleRemoveItem(index)}
                  setAlertMessage={setAlertBudgetAdset}
                />
              ))}
              <button className="sticky-button" onClick={getCentresInteretEtAudiences} title="Reconstuire toutes les combinaisons d'adsets avec tous les élements">Reset</button>
            </React.Fragment>
          ) : (
            <p style={{ padding: "20px", textAlign: "center" }}>
              <FormattedMessage
                defaultMessage="Aucun ciblage sélectionné pour l'instant"
                id='create_campaign_configuration_adset_detaille_no_data'
              />
            </p>
            )}
          </div>
        </div>
      }
      />
  );
};

export default ConfigurationAdsetDetaille;
