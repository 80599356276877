import React, { useEffect, useState } from "react";
import Loader from "../../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import "./style.css";
import LeftMenu from "../../components/LeftMenu/LeftMenu";
import { useNavigate } from "react-router-dom";
import TopRightMenu from "../../components/TopRightMenu/TopRightMenu";
import Loader3 from "../../components/Loader3/Loader3";
import axios from "axios";
import Pagination from "@mui/material/Pagination";
import PostFacebook from "../../components/postsASponsoriser/PostFacebook/PostFacebook";
import PostInstagram from "../../components/postsASponsoriser/PostInstagram/PostInstagram";
import axiosInstance from "../../store/axios.instance";
import { toast } from "react-toastify";
import { FormattedMessage, useIntl } from "react-intl";
import RightContainer from "../../components/RightContainer/RightContainer";

const CreationCarousel = () => {
  const [loader, setLoader] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { toggleLeftMenu } = useSelector(state => state.utils);

  const getParams = new URLSearchParams(window.location.search);
  const compagnId = getParams.get('campaign_id');

  const setToggleLeftMenu = () => {
    dispatch({ type: "SET_TOGGLE_LEFT_MENU" });
  };

  useEffect(() => {
    document.addEventListener("keydown", function (event) {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    });
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const { facebookAccountChosen, instagramAccountChosen } = useSelector(
    state => state.auth.user
  );

  const getPosts = () => {
    setLoader(true);

    axios
      .get(
        `${process.env.REACT_APP_GRAPH_API}/${facebookAccountChosen.id}/posts?fields=keywords,full_picture,message,created_time,story,attachments&access_token=${facebookAccountChosen.access_token}`
      )
      .then(result => {
        dispatch({
          type: "GET_FACEBOOK_POSTS_AJOUTER_POST",
          payload: result.data.data.map(post => ({
            ...post,
            source_id: facebookAccountChosen.id,
          })),
        });

        setLoader(false);
      })
      .catch(err => {
        dispatch({
          type: "SET_ALERT_GENERAL_ERROR_MESSAGE",
          payload: err.message,
        });
        dispatch({ type: "SHOW_ALERT_GENERAL_ERROR_MESSAGE" });

        setLoader(false);
      });
  };

  const checkIfInstagramAccountIsValid = () => {
    axios
      .get(
        `${process.env.REACT_APP_GRAPH_API}/${facebookAccountChosen.id}?fields=connected_instagram_account{id,username}&access_token=${facebookAccountChosen.access_token}`
      )
      .then(result => {
        if (result.data.connected_instagram_account) {
          const { username, id: instaId } =
            result.data.connected_instagram_account;

          if (username === instagramAccountChosen.name) {
            axios
              .get(
                `${process.env.REACT_APP_GRAPH_API}/${instaId}/media?access_token=${facebookAccountChosen.access_token}&fields=caption,permalink,media_url,media_type`
              )
              .then(result => {
                dispatch({
                  type: "GET_INSTAGRAM_POSTS_AJOUTER_POST",
                  payload: result.data.data.map(post => ({
                    ...post,
                    source_id: instagramAccountChosen.id,
                  })),
                });
              })
              .catch(err => console.log(err));
          }
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    if(facebookAccountChosen){
      getPosts();

      if(instagramAccountChosen){
        checkIfInstagramAccountIsValid();
      }
    }
  }, []);

  const {
    postsFacebook: {
      data: postsFacebook,
      pagination: {
        startIndex: startIndexFb,
        endIndex: endIndexFb,
        count: countFb,
      },
    },
    postsInstagram: {
      data: postsInstagram,
      pagination: {
        startIndex: startIndexInsta,
        endIndex: endIndexInsta,
        count: countInsta,
      },
    },
  } = useSelector(state => state.creationPackagePublicite);

  const postsFacebookToShow = postsFacebook.slice(startIndexFb, endIndexFb);
  const postsInstagramToShow = postsInstagram.slice(
    startIndexInsta,
    endIndexInsta
  );

  const handleChangePageFacebook = (event, value) => {
    event.stopPropagation();

    dispatch({
      type: "SET_END_INDEX_PAGINATION_POSTS_FACEBOOK",
      payload: value * countFb,
    });
  };
  const handleChangePageInstagram = (event, value) => {
    event.stopPropagation();

    dispatch({
      type: "SET_END_INDEX_PAGINATION_POSTS_INSTAGRAM",
      payload: value * countInsta,
    });
  };

  const [socialTypeActive, setSocialTypeActive] = useState("facebook");
  const { adaccountId } = useSelector(state => state.auth.user);
  const { nomPackage } = useSelector(state => state.creationPackagePublicite);

  const saveSelectedPosts = () => {
    const selectedPostsFacebook = postsFacebook.filter(post => post.isSelected);
    const selectedPostsInstagram = postsInstagram.filter(
      post => post.isSelected
    );

    let finalSponsoredPostsToCreate = [
      ...selectedPostsFacebook,
      ...selectedPostsInstagram,
    ];

    if (finalSponsoredPostsToCreate.length > 0) {
      setLoader2(true);

      Promise.all(
        finalSponsoredPostsToCreate.map(async post => {
          let postToSend = { ...post, keywords: [nomPackage] };

          delete postToSend.isSelected;

          // console.log("postToSend:", JSON.stringify(postToSend));

          try {
            await axiosInstance.put(
              `${adaccountId}/SponsoredPosts`,
              postToSend
            );

            setLoader2(false);

            toast.success("Post crée !");

            compagnId ? navigate(`/creation-package-pub?keep-data=true&campaign_id=${compagnId}`) : navigate("/creation-package-pub?keep-data=true");
            dispatch({ type: "UNSELECT_ALL_POSTS" });
          } catch (error) {
            console.log(error);
            dispatch({ type: "UNSELECT_ALL_POSTS" });
            setLoader2(false);
          }
        })
      );
    } else {
      dispatch({
        type: "SET_ALERT_GENERAL_ERROR_MESSAGE",
        payload: "Vous devez sélectionner au moins un post !",
      });
      dispatch({ type: "SHOW_ALERT_GENERAL_ERROR_MESSAGE" });
    }
  };

  const intl = useIntl();

  return (
    <div className='creation_post_screen'>
      <LeftMenu />
      <RightContainer>
        <header className='right_content_header'>
          <div className='back_title'>
            {toggleLeftMenu ? (
              <i
                onClick={() => setToggleLeftMenu(prevState => !prevState)}
                style={{ marginRight: 30, cursor: "pointer" }}
                className='fas fa-bars fa-2x'
              ></i>
            ) : null}
            <div className='back' onClick={() => navigate(-1)}>
              <img src='/left-arrow 2.png' alt='Retour' />
            </div>
            <h1 className='right_content_header_title'>
              <FormattedMessage
                defaultMessage='Créer une campagne'
                id='screen_creation_sponsoredpost_1'
              />
            </h1>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className='client'>
              <TopRightMenu />
            </div>
          </div>
        </header>
        {loader2 && (
          <Loader
            message={intl.formatMessage({
              defaultMessage: "Création du post en cours...",
              id: "loader_message_14",
            })}
          />
        )}
        <div className='right_content_main'>
          <div className='main_section_add_post'>
            <div className='boxed'>
              <header className='main__header'>
                <p>
                  <FormattedMessage
                    defaultMessage='Ajouter un post'
                    id='screen_creation_sponsoredpost_2'
                  />
                </p>
                <i
                  className='fas fa-times'
                  onClick={() => {
                    compagnId ? navigate(`/creation-package-pub?keep-data=true&campaign_id=${compagnId}`) : navigate("/creation-package-pub?keep-data=true");
                    // navigate("/creation-package-pub?keep-data=true");
                  }}
                ></i>
              </header>
              <div className='main__content'>
                <div className='category_post'>
                  <div
                    onClick={() => {
                      setSocialTypeActive("facebook");
                      dispatch({
                        type: "CLEAR_PAGINATION_POSTS_FACEBOOK_INSTAGRAM",
                      });
                    }}
                    className={`facebook ${
                      socialTypeActive === "facebook" ? "active" : ""
                    }`}
                  >
                    <FormattedMessage
                      defaultMessage='Facebook'
                      id='screen_creation_sponsoredpost_3'
                    />
                  </div>
                  <div
                    onClick={() => {
                      setSocialTypeActive("instagram");
                      dispatch({
                        type: "CLEAR_PAGINATION_POSTS_FACEBOOK_INSTAGRAM",
                      });
                    }}
                    className={`instagram ${
                      socialTypeActive === "instagram" ? "active" : ""
                    }`}
                  >
                    <FormattedMessage
                      defaultMessage='Instagram'
                      id='screen_creation_sponsoredpost_4'
                    />
                  </div>
                </div>
                {socialTypeActive === "facebook" && (
                  <div className='list_posts'>
                    {loader ? (
                      <Loader3 />
                    ) : (
                      postsFacebookToShow.map(post => (
                        <PostFacebook key={post.id} post={post} />
                      ))
                    )}
                  </div>
                )}
                {socialTypeActive === "instagram" && (
                  <div className='list_posts'>
                    {loader ? (
                      <Loader3 />
                    ) : (
                      postsInstagramToShow.map(post => (
                        <PostInstagram key={post.id} post={post} />
                      ))
                    )}
                  </div>
                )}
                <div className='pagination'>
                  <Pagination
                    count={
                      socialTypeActive === "instagram"
                        ? parseInt(postsInstagram.length / countInsta) + 1
                        : parseInt(postsFacebook.length / countFb) + 1
                    }
                    onChange={
                      socialTypeActive === "instagram"
                        ? handleChangePageInstagram
                        : handleChangePageFacebook
                    }
                  />
                </div>

                <div className='actions'>
                  <button onClick={saveSelectedPosts}>
                    <FormattedMessage
                      defaultMessage='Suivant'
                      id='screen_creation_sponsoredpost_5'
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </RightContainer>
    </div>
  );
};

export default CreationCarousel;
