import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LeftMenu from "../../../components/LeftMenu/LeftMenu";
import "./style.css";
import { FormattedMessage, useIntl } from "react-intl";
import Loader from "../../../components/Loader/Loader";
import RightContainer from "../../../components/RightContainer/RightContainer";

const Parametres = () => {
  const dispatch = useDispatch();
  const { toggleLeftMenu } = useSelector(state => state.utils);
  // eslint-disable-next-line
  const [loader3, setLoader3] = useState(false);

  const setToggleLeftMenu = () => {
    dispatch({ type: "SET_TOGGLE_LEFT_MENU" });
  };

  const navigate = useNavigate();

  const navigateToAddAccountScreen = () => {
    navigate("/campagne/ajouter-compte");
  };

  const intl = useIntl();

  return (
    <div className='ecran_campagne_parametres'>
      <LeftMenu />
      <RightContainer>
        <header className='right_content_header'>
          <div className='back_title'>
            {toggleLeftMenu ? (
              <i
                onClick={() => setToggleLeftMenu(prevState => !prevState)}
                style={{ marginRight: 30, cursor: "pointer" }}
                className='fas fa-bars fa-2x'
              ></i>
            ) : null}
            <h1 className='right_content_header_title'>
              <FormattedMessage
                defaultMessage='Paramètres'
                id='screen_params_'
              />
            </h1>
          </div>
        </header>
        {loader3 && (
          <Loader
            message={intl.formatMessage({
              defaultMessage: "Connexion de l'utilisateur",
              id: "loader_message_6",
            })}
          />
        )}
        <div className='right_content_main'>
          <div className='steps'>
            <div className='step active'>
              <p>
                <FormattedMessage
                  defaultMessage='Paramètres'
                  id='add-account-title'
                />
              </p>
            </div>
            <img src='/fleche-step.png' alt='Next Step' />
            <div className='step '>
              <p>
                <FormattedMessage
                  defaultMessage="Ajout d'un compte"
                  id='add-account-step-add-account'
                />
              </p>
            </div>
            <img src='/fleche-step.png' alt='Next Step' />
            <div className='step'>
              <p>
                <FormattedMessage
                  defaultMessage='Choisir un compte'
                  id='add-account-choose-account'
                />
              </p>
            </div>
            <img src='/fleche-step.png' alt='Next Step' />
            <div className='step'>
              <p>
                <FormattedMessage
                  defaultMessage='Configuration'
                  id='add-account-configuration'
                />
              </p>
            </div>
          </div>
          <div className='compte'>
            <div className='compte_content'>
              <h3>
                <FormattedMessage
                  defaultMessage='Vous n’avez pas de compte connecté'
                  id='screen_params_1'
                />
              </h3>
              <p>
                <FormattedMessage
                  defaultMessage='Merci de connecter au moins un compte Meta / TikTok avant de lancer une campagne'
                  id='screen_params_2'
                />
              </p>
              <button onClick={navigateToAddAccountScreen}>
                <FormattedMessage
                  defaultMessage='Ajouter un compte'
                  id='screen_params_3'
                />
              </button>
            </div>
          </div>
        </div>
      </RightContainer>
    </div>
  );
};

export default Parametres;
