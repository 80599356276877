import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import AssociatedAssetsCmp from "../../../../../components/AssociatedAssetsCmp/AssociatedAssetsCmp";
import Loader3 from "../../../../../components/Loader3/Loader3";
import axiosInstance from "../../../../../store/axios.instance";
import { estObjetVide } from "../../../../../utils";
import { useSearchParams } from "react-router-dom";
import { valeurExiste } from "../../../../../utils";

const ImageItem = ({ image, showTags }) => {
  const { adaccountId } = useSelector(state => state.auth.user);
  const [loader, setLoader] = useState(false);
  const [newTag, setNewTag] = useState("");
  const dispatch = useDispatch();

  const handleSaveTag = e => {
    if (newTag) {
      if (e.key === "Enter") {
        // Add new keyword to image in the database
        setLoader(true);

        const dataToSend = [
          {
            hash: image.hash,
            name: image.name,
            keywords: [newTag],
          },
        ];

        axiosInstance
          .post(`/${adaccountId}/AdImages/addkeywords`, dataToSend)
          .then(res => {
            setTimeout(() => {
              toast.success("Tag ajouté avec succès");
              setLoader(false);
              setNewTag("");

              // getImages(adaccountId);
              dispatch({
                type: "ADD_TAG_IMAGE_ITEM_LOCALLY",
                payload: { imageId: image.id, tagToAdd: newTag },
              });
            }, 500);
          })
          .catch(err => {
            console.log(err);
            setLoader(false);
          });
      }
    }
  };

  const deleteTag = tag => {
    if (window.confirm("Voulez-vous vraiment supprimer ce tag ?")) {
      const dataToSend = [
        {
          hash: image.hash,
          name: image.name,
          keywords: image.keywords.filter(tg => tg !== tag),
        },
      ];

      axiosInstance
        .post(`/${adaccountId}/AdImages/newkeywords`, dataToSend)
        .then(res => {
          setTimeout(() => {
            toast.success("Tag supprimé avec succès");
            setNewTag("");

            // getImages(adaccountId);
            dispatch({
              type: "REMOVE_TAG_IMAGE_ITEM_LOCALLY",
              payload: { imageId: image.id, tagToDelete: tag },
            });
          }, 500);
        })
        .catch(err => {
          console.log(err);
        });
    }
  };
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();

  const tagToEdit = searchParams.get("tagToEdit");

  const { nomPackage } = useSelector(state => state.creationPackagePublicite);

  const setSelectedImage = id => {
    if (tagToEdit && image.isSelected) {
      if (valeurExiste(image.keywords, nomPackage)) {
        const dataToSend = [
          {
            hash: image.hash,
            name: image.name,
            keywords: image.keywords.filter(tg => tg !== nomPackage),
          },
        ];

        axiosInstance
          .post(`/${adaccountId}/AdImages/newkeywords`, dataToSend)
          .then(res => {
            dispatch({
              type: "REMOVE_TAG_IMAGE_ITEM_LOCALLY",
              payload: { imageId: image.id, tagToDelete: nomPackage },
            });
          })
          .catch(err => {
            console.log(err);
          });
      }
    }

    dispatch({
      type: "TOGGLE_SELECTED_IMAGE_CREATION_PACKAGE_PUB",
      payload: id,
    });
  };

  return (
    <div
      onClick={() => setSelectedImage(image.id)}
      className={`image_item ${image.isSelected && "selected"}`}
      style={showTags ? { width: "48%" } : {}}
    >
      <div className='left' style={!showTags ? { width: "100%" } : {}}>
        <div className='image_title'>
          <h4>
            {image.name.length < 30
              ? image.name
              : `${image.name.slice(0, 30)}...`}
          </h4>
          {image.associated_assets && !estObjetVide(image.associated_assets) ? (
            <AssociatedAssetsCmp />
          ) : null}
        </div>
        <div className='image'>
          <img src={image.url} alt={image.name} style={{ maxWidth: "100%", maxHeight: "100%" }}/>
        </div>
      </div>
      <div
        className='image_item_right'
        style={showTags ? { display: "flex" } : { display: "none" }}
      >
        <div className='tags' onClick={e => e.stopPropagation()}>
          {image.keywords &&
            image.keywords.length > 0 &&
            image.keywords.map((tag, i) => (
              <div key={i} className='tag'>
                <p>{tag}</p>
                <i onClick={() => deleteTag(tag)} className='fas fa-times'></i>
              </div>
            ))}
        </div>
        <div className='add_tag' onClick={e => e.stopPropagation()}>
          {loader ? (
            <Loader3 />
          ) : (
            <input
              type='text'
              onChange={e => setNewTag(e.target.value)}
              onKeyUp={handleSaveTag}
              value={newTag}
              placeholder='Tag...'
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ImageItem;
