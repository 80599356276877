import {legacy_createStore as createStore, compose, combineReducers, applyMiddleware } from "redux";
// import { configureStore } from '@reduxjs/toolkit' //never could make it compile
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { clientsReducer } from "./reducers/clientsReducer";
import { pseudosReducer } from "./reducers/pseudosReducer";
import { authReducer } from "./reducers/authReducer";
import { campaignConfigurationFileReducer } from "./reducers/campaignConfigurationFileReducer";
import { creationCampagneReducer } from "./reducers/creationCampagne";
import { creationCampaignRefVariables } from "./reducers/creationCampaignRefVariables";
import { creationPackagePubliciteReducer } from "./reducers/creationPackagePubliciteReducer";
import { utilsReducer } from "./reducers/utilsReducer";
import { insightsReducer } from "./reducers/insightsReducer";
import { creationPackageGeoDemoReducer } from "./reducers/creationPackageGeoDemoReducer";
import { ressourcesReducer } from "./reducers/importerRessourcesReducer";
import { appairerVisuelsReducer } from "./reducers/appairerVisuelsReducer";
import { carouselReducer } from "./reducers/carouselReducer";
import { selectAdaccountReducer } from "./reducers/selectAdaccount";
import { priorisationEvenementsReducer } from "./reducers/priorisationEvents";
import { configurationMonitoringReducer } from "./reducers/configurationMonitoring";

import { validationReducer } from "./reducers/validationReducer";
import { rapportStatistiquesDetaillleesReducer } from "./reducers/rapportStatistiquesDetailllees";
import { monitoringCampagneReducer } from "./reducers/monitoringCampagne";
import { publicitesExistantesReducer } from "./reducers/publicitesExistantes";
import { configurationRefReducer } from "./reducers/refvariablesConfigurations"

import { alertsReducer } from './reducers/alertesReducer';

// Ajouter les reducers ici...
const reducer = combineReducers(
  {
  clients: clientsReducer,
  pseudos: pseudosReducer,
  auth: authReducer,
  campaignConfigurationFile: campaignConfigurationFileReducer,//not sure what it does
  creationCampagne: creationCampagneReducer,
  creationCampagneRef: creationCampaignRefVariables,
  creationPackagePublicite: creationPackagePubliciteReducer,//maybe useful for some of it
  utils: utilsReducer,
  insights: insightsReducer,
  geodemo: creationPackageGeoDemoReducer,//useless
  ressources: ressourcesReducer,
  appairerVisuels: appairerVisuelsReducer, //probably useless unless we lose focus while uloading
  carousel: carouselReducer,//probably useless unless we lose focus while uloading
  selectAdaccount: selectAdaccountReducer,//should be auth.user reducer
  priorisationEvenements: priorisationEvenementsReducer,//useless
  configurationMonitoring: configurationMonitoringReducer,
  validation: validationReducer,
  rapportStatistiquesDetailllees: rapportStatistiquesDetaillleesReducer,
  monitoringCampagne: monitoringCampagneReducer,
  publicitesExistantes: publicitesExistantesReducer,//probably useless unless we lose focus while requesting ads?
  alerts: alertsReducer,
  configuration: configurationRefReducer
}
);

const initialState = {};

// const store = configureStore({reducer:reducer})

const middleware = [thunk];
const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
