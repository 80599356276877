const initialState = {
  filteredIntersectClients: [],
  intersectClients: [],
};

export const selectAdaccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case "TOGGLE_COMPTE_PRINCIPAL_ADACCOUNT":
      return {
        ...state,
        filteredIntersectClients: state.filteredIntersectClients.map(item => {
          if (item.id === action.payload) {
            return { ...item, comptePrincipal: !item.comptePrincipal };
          } else {
            return { ...item, comptePrincipal: false };
          }
        }),
        intersectClients: state.intersectClients.map(item => {
          if (item.id === action.payload) {
            return { ...item, comptePrincipal: !item.comptePrincipal };
          } else {
            return { ...item, comptePrincipal: false };
          }
        }),
      };
    case "TOGGLE_SELECT_ADACCOUNT":
      return {
        ...state,
        filteredIntersectClients: state.filteredIntersectClients.map(item => {
          if (item.id === action.payload) {
            return { ...item, isSelected: !item.isSelected };
          } else {
            return item;
          }
        }),
        intersectClients: state.intersectClients.map(item => {
          if (item.id === action.payload) {
            return { ...item, isSelected: !item.isSelected };
          } else {
            return item;
          }
        }),
      };
    case "FILTER_INTERSECT_CLIENTS":
      return {
        ...state,
        filteredIntersectClients: action.payload
          ? state.intersectClients.filter(item =>
              item.name.toLowerCase().includes(action.payload.toLowerCase())
            )
          : state.intersectClients,
      };
    case "GET_INTERSECT_CLIENTS":
      return {
        ...state,
        filteredIntersectClients: action.payload.map(item => ({
          ...item,
          isSelected: false,
          comptePrincipal: false,
        })),
        intersectClients: action.payload.map(item => ({
          ...item,
          isSelected: false,
          comptePrincipal: false,
        })),
      };
    default:
      return state;
  }
};
