import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import "./style.css";

const PostFacebook = ({ post }) => {
  const { adaccountName } = useSelector(state => state.auth.user);

  const dispatch = useDispatch();

  const selectItem = () => {
    dispatch({ type: "TOGGLE_SELECT_POST_FACEBOOK", payload: post.id });
  };

  return (
    <div
      className={`facebook_screenshot screenshot ${
        post.isSelected ? "selected" : ""
      }`}
      onClick={selectItem}
    >
      <div
        className='account'
        style={post.isSelected ? { backgroundColor: "#e83e8c" } : {}}
      >
        <div className='left'>
          <div className='image'>
            {/* <img src='/logo.png' alt='Creadster' /> */}
            <i className='fas fa-user-circle'></i>
          </div>
          <div className='text'>
            <h5>{adaccountName}</h5>
          </div>
        </div>
        <div className='right'>
          <i className='fas fa-ellipsis-h'></i>
          <i className='fas fa-times'></i>
        </div>
      </div>
      <div className='pxt'>
        <h4 className='title'>
          {/* {titre.length > 40 ? titre.slice(0, 40) + "..." : titre} */}
          {/* {titre} */}
        </h4>
        <div className='description' id='texte-facebook-screenshot'>
          <p>
            {post.message && post.message.length > 150
              ? post.message.slice(0, 150) + "..."
              : post.message}
          </p>
        </div>
      </div>
      {post.attachments &&
        post.attachments.data &&
        post.attachments.data[0] &&
        post.attachments.data[0].media &&
        post.attachments.data[0].media.image &&
        post.attachments.data[0].media.image.src && (
          <img
            className='main_img'
            src={post.attachments.data[0].media.image.src}
            alt='Screenshot'
          />
        )}

      <div className='subattachments'>
        {post.attachments &&
          post.attachments.data &&
          post.attachments.data[0] &&
          post.attachments.data[0].subattachments &&
          post.attachments.data[0].subattachments.data.length > 0 &&
          post.attachments.data[0].subattachments.data
            .slice(0, 3)
            .map(item => (
              <img
                src={item.media.image.src}
                alt=''
                className={`subattachment ${
                  post.attachments.data[0].subattachments.data.length === 2
                    ? "w-50"
                    : ""
                }`}
              />
            ))}

        {post.attachments &&
          post.attachments.data &&
          post.attachments.data[0] &&
          post.attachments.data[0].subattachments &&
          post.attachments.data[0].subattachments.data &&
          post.attachments.data[0].subattachments.data.length > 3 && (
            <span className='nbr_subattachments_left'>
              +{post.attachments.data[0].subattachments.data.length - 3}
            </span>
          )}
      </div>

      <div className='commentaires_partages'>
        <div className='gauche'>
          <div className='like'>
            <i className='fas fa-thumbs-up'></i>
          </div>
          <div className='love'>
            <i className='fas fa-heart'></i>
          </div>
        </div>
        <div className='droite'>
          <p>commentaires</p>
          <p>partages</p>
        </div>
      </div>
      <div className='interactions'>
        <p>
          <i className='far fa-thumbs-up'></i>
          J'aime
        </p>
        <p>
          <i className='far fa-comment-alt'></i>
          Commenter
        </p>
        <p>
          <i className='fas fa-share'></i>
          Partager
        </p>
      </div>
    </div>
  );
};

export default PostFacebook;
