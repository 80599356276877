import React, { useState } from "react";
import {useSelector } from "react-redux";

import ListItem from "./ListItem";
import "./style.css";


const SelectPages = () => {
  //still not understanding what should be done here
  // selector is wrong so desactivating it now there is nothing
  const { accounts } = []//useSelector(state => state.auth.user.userData);
  const [pages, setPages] = useState(accounts);

  const searchPages = e => {
    setPages(
      accounts.filter(
        item =>
          item && item.name.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  const toggleSelectItem = id => {
    setPages(prevState =>
      prevState.map(item => {
        if (item.id === id) {
          return {
            ...item,
            isSelected: !item.isSelected,
          };
        } else return item;
      })
    );
  };


  return (
    <div className='screen_choose_page_select_ad_page'>
      <div className='icon_search_box'>
        <i className='fas fa-search'></i>
        <input
          onChange={searchPages}
          placeholder='Search...'
          type='search'
          name=''
          id=''
        />
      </div>

      {pages &&
        (pages.length > 0 ? (
          <div className='liste_intersect_client'>
            {pages.map(acc => (
              <ListItem
                toggleSelectItem={() => toggleSelectItem(acc.id)}
                key={acc.id}
                acc={acc}
              />
            ))}
          </div>
        ) : (
          <h4 style={{ marginTop: 20, textAlign: "center" }}>
            Aucun compte pour l'instant
          </h4>
        ))}
    </div>
  );
};

export default SelectPages;
