import React from "react";
import { useSelector } from "react-redux";

const RightContainer = ({ children }) => {
  const { toggleLeftMenu } = useSelector(state => state.utils);

  return (
    <div
      className='right_content'
      style={!toggleLeftMenu ? { width: "80%" } : { width: "95%" }}
    >
      {children}
    </div>
  );
};
export default RightContainer;
