import { v4 as uuidv4 } from "uuid";
import {
  addOrRemoveId,
  filterByKeywords,
  removeDuplicatesByKey,
  valeurExiste,
} from "../../utils";

const initialState = {
  nomPackage: "",
  tags: [],
  image: {
    selectedImages: [],
    images: [],
    filteredImages: [],
    pagination: {
      count: 10,
      startIndex: 0,
      endIndex: 10,
    },
  },
  video: {
    selectedVideos: [],
    videos: [],
    filteredVideos: [],
    pagination: {
      count: 10,
      startIndex: 0,
      endIndex: 10,
    },
  },
  texte: {
    textes: [],
    pagination: {
      count: 4,
      startIndex: 0,
      endIndex: 4,
    },
  },
  descriptions: [],
  titre: {
    titres: [],
    pagination: {
      count: 6,
      startIndex: 0,
      endIndex: 6,
    },
  },
  utms: [],
  ctas: [],
  liens: [],
  carousels: [],
  postsFacebook: {
    data: [],
    pagination: {
      count: 7,
      startIndex: 0,
      endIndex: 7,
    },
  },
  postsInstagram: {
    data: [],
    pagination: {
      count: 7,
      startIndex: 0,
      endIndex: 7,
    },
  },
  sponsoredPosts: [],
  originalData: {
    carousels: [],
    postsFacebook: [],
    postsInstagram: [],
    utms: [],
    ctas: [],
    liens: [],
    titres: [],
    descriptions: [],
    textes: [],
    sponsoredPosts: [],
    publicitesExistantes: [],
  },
  emptyNameError: false,
  emptyContentError: false,
  emptyImageError: false,
  emptyVideoError: false,
  emptyCorousetError: false,
  emptyCtasError: false,
  emptyPasError: false,
  publicitesExistantes: [],
  catalogs: [],
  catalog: "",
  product_sets: [],
  product_set: "",
  catalogformats: [],
  catalog_crea_id: "",
};

export const creationPackagePubliciteReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "DELETE_TITLE_CREATION_PACKAGE_PUB_SUCCESS":
      return {
        ...state,
        titre: {
          ...state.titre,
          titres: state.titre.titres.filter(
            (titre) => titre.id !== action.payload
          ),
        },
        originalData: {
          ...state.originalData,
          titres: state.originalData.titres.filter(
            (titre) => titre.id !== action.payload
          ),
        },
      };
    case "GET_PUBLICITES_EXISTANTES_CREATION_PACKAGE_PUBLICITE":
      return {
        ...state,
        publicitesExistantes: action.payload,
        originalData: {
          ...state.originalData,
          publicitesExistantes: action.payload,
        },
      };
    case "DELETE_SINGLE_PUBLICITE_EXISTANTE_CREATION_PACKAGE_PUB":
      return {
        ...state,
        publicitesExistantes: state.publicitesExistantes.filter(
          (pub) => pub.id !== action.payload
        ),
      };
    case "SAVE_PUBLICITES_EXISTANTES_CREATION_PACKAGE_PUB":
      return {
        ...state,
        publicitesExistantes: action.payload,
      };
    case "CLEAR_CREATION_PACKAGE_PUB":
      return {
        ...state,
        nomPackage: "",
        tags: [],
        image: {
          ...state.image,
          selectedImages: [],
          images: [],
          filteredImages: [],
          pagination: {
            ...state.image.pagination,
            count: 10,
            startIndex: 0,
            endIndex: 10,
          },
        },
        video: {
          ...state.video,
          selectedVideos: [],
          videos: [],
          filteredVideos: [],
          pagination: {
            ...state.video.pagination,
            count: 10,
            startIndex: 0,
            endIndex: 10,
          },
        },
        texte: {
          ...state.texte,
          textes: [],
          pagination: {
            ...state.texte.pagination,
            count: 4,
            startIndex: 0,
            endIndex: 4,
          },
        },
        descriptions: [],
        titre: {
          ...state.titre,
          titres: [],
          pagination: {
            ...state.titre.pagination,
            count: 6,
            startIndex: 0,
            endIndex: 6,
          },
        },
        utms: [],
        ctas: [],
        liens: [],
        carousels: [],
        postsFacebook: {
          ...state.postsFacebook,
          data: [],
          pagination: {
            ...state.postsFacebook.pagination,
            count: 7,
            startIndex: 0,
            endIndex: 7,
          },
        },
        postsInstagram: {
          ...state.postsInstagram,
          data: [],
          pagination: {
            ...state.postsInstagram.pagination,
            count: 7,
            startIndex: 0,
            endIndex: 7,
          },
        },
        sponsoredPosts: [],
        originalData: {
          ...state.originalData,
          carousels: [],
          postsFacebook: [],
          postsInstagram: [],
          utms: [],
          ctas: [],
          liens: [],
          titres: [],
          descriptions: [],
          textes: [],
          sponsoredPosts: [],
        },
        emptyNameError: false,
        emptyContentError: false,
        emptyImageError: false,
        emptyVideoError: false,
        emptyCorousetError: false,
        emptyPasError: false,
        catalogs: [],
        catalog: "",
        product_sets: [],
        product_set: "",
        catalogformats: [],
        catalog_crea_id: "",
      };
    case "DELETE_TEXTE_CREATION_PACKAGE_PUB_SUCCESS":
      return {
        ...state,
        texte: {
          ...state.texte,
          textes: state.texte.textes.filter(
            (item) => item.id !== action.payload
          ),
        },
        originalData: {
          ...state.originalData,
          textes: state.originalData.textes.filter(
            (item) => item.id !== action.payload
          ),
        },
      };
    case "SEARCH_UTMS":
      return {
        ...state,
        utms: action.payload
          ? state.originalData.utms.filter(
              (item) =>
                item &&
                item.content &&
                item.content
                  .toLowerCase()
                  .includes(action.payload.toLowerCase())
            )
          : state.originalData.utms,
      };
    case "SEARCH_LINKS":
      return {
        ...state,
        liens: action.payload
          ? state.originalData.liens.filter(
              (item) =>
                item &&
                item.content &&
                item.content
                  .toLowerCase()
                  .includes(action.payload.toLowerCase())
            )
          : state.originalData.liens,
      };
    case "SEARCH_DESCRIPTIONS":
      return {
        ...state,
        descriptions: action.payload
          ? state.originalData.descriptions.filter(
              (item) =>
                item &&
                item.content &&
                item.content
                  .toLowerCase()
                  .includes(action.payload.toLowerCase())
            )
          : state.originalData.descriptions,
      };
    case "SEARCH_BODIES":
      return {
        ...state,
        texte: {
          ...state.texte,
          textes: action.payload
            ? state.originalData.textes.filter(
                (texte) =>
                  texte &&
                  texte.content &&
                  texte.content
                    .toLowerCase()
                    .includes(action.payload.toLowerCase())
              )
            : state.originalData.textes,
        },
      };
    case "SEARCH_TITLES":
      return {
        ...state,
        titre: {
          ...state.titre,
          titres: action.payload
            ? state.originalData.titres.filter(
                (titre) =>
                  titre &&
                  titre.content &&
                  titre.content
                    .toLowerCase()
                    .includes(action.payload.toLowerCase())
              )
            : state.originalData.titres,
        },
      };
    case "CLEAR_SELECTED_VIDEOS":
      return {
        ...state,
        video: {
          ...state.video,
          selectedVideos: [],
        },
      };
    case "CLEAR_SELECTED_IMAGES":
      return {
        ...state,
        image: {
          ...state.image,
          selectedImages: [],
        },
      };
    case "CHANGE_NAME_CAROUSEL_ITEM":
      return {
        ...state,
        carousels: state.carousels.map((car) => {
          if (car.id === action.payload.id) {
            return {
              ...car,
              name: action.payload.value,
            };
          } else return car;
        }),
      };
    case "GET_SELECTED_VIDEOS_AND_SHOW_THEM_IN_LIST_VIDEOS":
      const videosArr = action.payload.map((element) =>
        state.video.selectedVideos.includes(element.id)
          ? { ...element, isSelected: true }
          : { ...element, isSelected: false }
      );
      const selectedVideosArr = videosArr.filter(
        (vid) => vid && vid.isSelected
      );
      const notSelectedVideosArr = videosArr.filter(
        (vid) => vid && !vid.isSelected
      );

      return {
        ...state,
        video: {
          ...state.video,
          videos: [...selectedVideosArr, ...notSelectedVideosArr],
          filteredVideos: [...selectedVideosArr, ...notSelectedVideosArr],
        },
      };

    case "GET_SELECTED_IMAGES_AND_SHOW_THEM_IN_LIST_IMAGES":
      const imagesArr = action.payload.map((element) =>
        state.image.selectedImages.includes(element.id)
          ? { ...element, isSelected: true }
          : { ...element, isSelected: false }
      );
      const selectedImagesArr = imagesArr.filter(
        (img) => img && img.isSelected
      );
      const notSelectedImagesArr = imagesArr.filter(
        (img) => img && !img.isSelected
      );

      return {
        ...state,
        image: {
          ...state.image,
          images: [...selectedImagesArr, ...notSelectedImagesArr],
          filteredImages: [...selectedImagesArr, ...notSelectedImagesArr],
        },
      };
    case "ADD_VIDEO_TO_LIST_ASSETS_VIDEOS":
      const videoToAdd = {
        ...action.payload,
        isSelected: true,
      };

      return {
        ...state,
        video: {
          ...state.video,
          videos: [videoToAdd, ...state.video.videos],
          filteredVideos: [videoToAdd, ...state.video.filteredVideos],
          selectedVideos: [...state.video.selectedVideos, videoToAdd.id],
        },
      };
    case "ADD_IMAGE_TO_LIST_ASSETS_IMAGES":
      const imageToAdd = {
        ...action.payload,
        isSelected: true,
      };

      const oldImages = [...state.image.images];
      const oldSelectedImages = [...state.image.selectedImages];
      const oldFilteredImages = [imageToAdd, ...state.image.filteredImages];

      const uniqueImages = oldImages.reduce((accumulator, currentImage) => {
        if (
          currentImage.id !== imageToAdd.id &&
          !accumulator.some((img) => img.id === currentImage.id)
        ) {
          accumulator.push(currentImage);
        }
        return accumulator;
      }, []);

      const uniqueFilteredImages = oldFilteredImages.reduce(
        (accumulator, currentImage) => {
          if (
            currentImage.id !== imageToAdd.id &&
            !accumulator.some((img) => img.id === currentImage.id)
          ) {
            accumulator.push(currentImage);
          }
          return accumulator;
        },
        []
      );

      const uniqueSelectedImages = oldSelectedImages.reduce(
        (accumulator, currentId) => {
          if (
            currentId !== imageToAdd.id &&
            !accumulator.some((id) => id === currentId)
          ) {
            accumulator.push(currentId);
          }
          return accumulator;
        },
        []
      );

      return {
        ...state,
        image: {
          ...state.image,
          images: [imageToAdd, ...uniqueImages],
          filteredImages: [imageToAdd, ...uniqueFilteredImages],
          selectedImages: [...uniqueSelectedImages, imageToAdd.id],

          // images: [imageToAdd, ...state.image.images],
          // filteredImages: [imageToAdd, ...state.image.filteredImages],
          // selectedImages: [...state.image.selectedImages, imageToAdd.id],
        },
      };
    case "UNSELECT_ALL_ASSETS":
      return {
        ...state,
        originalData: {
          ...state.originalData,
          utms: state.originalData.utms.map((elem) => ({
            ...elem,
            isSelected: false,
          })),
          ctas: state.originalData.ctas.map((elem) => ({
            ...elem,
            isSelected: false,
          })),
          liens: state.originalData.liens.map((elem) => ({
            ...elem,
            isSelected: false,
          })),
          titres: state.originalData.titres.map((elem) => ({
            ...elem,
            isSelected: false,
          })),
          descriptions: state.originalData.descriptions.map((elem) => ({
            ...elem,
            isSelected: false,
          })),
          textes: state.originalData.textes.map((elem) => ({
            ...elem,
            isSelected: false,
          })),
        },
        image: {
          ...state.image,
          filteredImages: state.image.filteredImages.map((elem) => ({
            ...elem,
            isSelected: false,
          })),
          images: state.image.images.map((elem) => ({
            ...elem,
            isSelected: false,
          })),
        },
        video: {
          ...state.video,
          filteredVideos: state.video.filteredVideos.map((elem) => ({
            ...elem,
            isSelected: false,
          })),
          videos: state.video.videos.map((elem) => ({
            ...elem,
            isSelected: false,
          })),
        },

        utms: state.utms.map((elem) => ({ ...elem, isSelected: false })),

        ctas: state.ctas.map((elem) => ({ ...elem, isSelected: false })),

        liens: state.liens.map((elem) => ({ ...elem, isSelected: false })),

        descriptions: state.descriptions.map((elem) => ({
          ...elem,
          isSelected: false,
        })),

        titre: {
          ...state.titre,
          titres: state.titre.titres.map((elem) => ({
            ...elem,
            isSelected: false,
          })),
        },

        texte: {
          ...state.texte,
          textes: state.texte.textes.map((elem) => ({
            ...elem,
            isSelected: false,
          })),
        },
      };

    case "SELECT_ALL_ASSETS_WHICH_HAS_ENTERED_TAG_IN_KEYWORDS":
      const selectedTag = action.payload;

      return {
        ...state,
        originalData: {
          ...state.originalData,
          utms: state.originalData.utms.map((elem) => {
            if (elem.hasOwnProperty("keywords")) {
              if (valeurExiste(elem.keywords, selectedTag)) {
                return {
                  ...elem,
                  isSelected: true,
                };
              } else return { ...elem, isSelected: false };
            } else return { ...elem, isSelected: false };
          }),
          ctas: state.originalData.ctas.map((elem) => {
            if (elem.hasOwnProperty("keywords")) {
              if (valeurExiste(elem.keywords, selectedTag)) {
                return {
                  ...elem,
                  isSelected: true,
                };
              } else return { ...elem, isSelected: false };
            } else return { ...elem, isSelected: false };
          }),
          liens: state.originalData.liens.map((elem) => {
            if (elem.hasOwnProperty("keywords")) {
              if (valeurExiste(elem.keywords, selectedTag)) {
                return {
                  ...elem,
                  isSelected: true,
                };
              } else return { ...elem, isSelected: false };
            } else return { ...elem, isSelected: false };
          }),
          titres: state.originalData.titres.map((elem) => {
            if (elem.hasOwnProperty("keywords")) {
              if (valeurExiste(elem.keywords, selectedTag)) {
                return {
                  ...elem,
                  isSelected: true,
                };
              } else return { ...elem, isSelected: false };
            } else return { ...elem, isSelected: false };
          }),
          descriptions: state.originalData.descriptions.map((elem) => {
            if (elem.hasOwnProperty("keywords")) {
              if (valeurExiste(elem.keywords, selectedTag)) {
                return {
                  ...elem,
                  isSelected: true,
                };
              } else return { ...elem, isSelected: false };
            } else return { ...elem, isSelected: false };
          }),
          textes: state.originalData.textes.map((elem) => {
            if (elem.hasOwnProperty("keywords")) {
              if (valeurExiste(elem.keywords, selectedTag)) {
                return {
                  ...elem,
                  isSelected: true,
                };
              } else return { ...elem, isSelected: false };
            } else return { ...elem, isSelected: false };
          }),
        },
        image: {
          ...state.image,
          filteredImages: state.image.filteredImages.map((elem) => {
            if (elem.hasOwnProperty("keywords")) {
              if (valeurExiste(elem.keywords, selectedTag)) {
                return {
                  ...elem,
                  isSelected: true,
                };
              } else return { ...elem, isSelected: false };
            } else return { ...elem, isSelected: false };
          }),
          images: state.image.images.map((elem) => {
            if (elem.hasOwnProperty("keywords")) {
              if (valeurExiste(elem.keywords, selectedTag)) {
                return {
                  ...elem,
                  isSelected: true,
                };
              } else return { ...elem, isSelected: false };
            } else return { ...elem, isSelected: false };
          }),
        },
        video: {
          ...state.video,
          filteredVideos: state.video.filteredVideos.map((elem) => {
            if (elem.hasOwnProperty("keywords")) {
              if (valeurExiste(elem.keywords, selectedTag)) {
                return {
                  ...elem,
                  isSelected: true,
                };
              } else return { ...elem, isSelected: false };
            } else return { ...elem, isSelected: false };
          }),
          videos: state.video.videos.map((elem) => {
            if (elem.hasOwnProperty("keywords")) {
              if (valeurExiste(elem.keywords, selectedTag)) {
                return {
                  ...elem,
                  isSelected: true,
                };
              } else return { ...elem, isSelected: false };
            } else return { ...elem, isSelected: false };
          }),
        },

        utms: state.utms.map((elem) => {
          if (elem.hasOwnProperty("keywords")) {
            if (valeurExiste(elem.keywords, selectedTag)) {
              return {
                ...elem,
                isSelected: true,
              };
            } else return { ...elem, isSelected: false };
          } else return { ...elem, isSelected: false };
        }),

        ctas: state.ctas.map((elem) => {
          if (elem.hasOwnProperty("keywords")) {
            if (valeurExiste(elem.keywords, selectedTag)) {
              return {
                ...elem,
                isSelected: true,
              };
            } else return { ...elem, isSelected: false };
          } else return { ...elem, isSelected: false };
        }),

        liens: state.liens.map((elem) => {
          if (elem.hasOwnProperty("keywords")) {
            if (valeurExiste(elem.keywords, selectedTag)) {
              return {
                ...elem,
                isSelected: true,
              };
            } else return { ...elem, isSelected: false };
          } else return { ...elem, isSelected: false };
        }),

        descriptions: state.descriptions.map((elem) => {
          if (elem.hasOwnProperty("keywords")) {
            if (valeurExiste(elem.keywords, selectedTag)) {
              return {
                ...elem,
                isSelected: true,
              };
            } else return { ...elem, isSelected: false };
          } else return { ...elem, isSelected: false };
        }),

        titre: {
          ...state.titre,
          titres: state.titre.titres.map((elem) => {
            if (elem.hasOwnProperty("keywords")) {
              if (valeurExiste(elem.keywords, selectedTag)) {
                return {
                  ...elem,
                  isSelected: true,
                };
              } else return { ...elem, isSelected: false };
            } else return { ...elem, isSelected: false };
          }),
        },

        texte: {
          ...state.texte,
          textes: state.texte.textes.map((elem) => {
            if (elem.hasOwnProperty("keywords")) {
              if (valeurExiste(elem.keywords, selectedTag)) {
                return {
                  ...elem,
                  isSelected: true,
                };
              } else return { ...elem, isSelected: false };
            } else return { ...elem, isSelected: false };
          }),
        },
      };
    case "DELETE_TAG_CTA_CREATION_PACKAGE_PUB":
      return {
        ...state,
        ctas: state.ctas.map((item) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              keywords: item.keywords.filter(
                (key) => key !== action.payload.tag
              ),
            };
          } else return item;
        }),
        originalData: {
          ...state.originalData,
          ctas: state.originalData.ctas.map((item) => {
            if (item.id === action.payload.id) {
              return {
                ...item,
                keywords: item.keywords.filter(
                  (key) => key !== action.payload.tag
                ),
              };
            } else return item;
          }),
        },
      };
    case "DELETE_TAG_UTM_CREATION_PACKAGE_PUB":
      return {
        ...state,
        utms: state.utms.map((item) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              keywords: item.keywords.filter(
                (key) => key !== action.payload.tag
              ),
            };
          } else return item;
        }),
        originalData: {
          ...state.originalData,
          utms: state.originalData.utms.map((item) => {
            if (item.id === action.payload.id) {
              return {
                ...item,
                keywords: item.keywords.filter(
                  (key) => key !== action.payload.tag
                ),
              };
            } else return item;
          }),
        },
      };
    case "DELETE_TAG_LIEN_CREATION_PACKAGE_PUB":
      return {
        ...state,
        liens: state.liens.map((item) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              keywords: item.keywords.filter(
                (key) => key !== action.payload.tag
              ),
            };
          } else return item;
        }),
        originalData: {
          ...state.originalData,
          liens: state.originalData.liens.map((item) => {
            if (item.id === action.payload.id) {
              return {
                ...item,
                keywords: item.keywords.filter(
                  (key) => key !== action.payload.tag
                ),
              };
            } else return item;
          }),
        },
      };
    case "DELETE_TAG_DESCRIPTION_CREATION_PACKAGE_PUB":
      return {
        ...state,
        descriptions: state.descriptions.map((item) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              keywords: item.keywords.filter(
                (key) => key !== action.payload.tag
              ),
            };
          } else return item;
        }),
        originalData: {
          ...state.originalData,
          descriptions: state.originalData.descriptions.map((item) => {
            if (item.id === action.payload.id) {
              return {
                ...item,
                keywords: item.keywords.filter(
                  (key) => key !== action.payload.tag
                ),
              };
            } else return item;
          }),
        },
      };
    case "DELETE_TAG_TEXTE_CREATION_PACKAGE_PUB":
      return {
        ...state,
        texte: {
          ...state.texte,
          textes: state.texte.textes.map((item) => {
            if (item.id === action.payload.id) {
              return {
                ...item,
                keywords: item.keywords.filter(
                  (key) => key !== action.payload.tag
                ),
              };
            } else return item;
          }),
        },
        originalData: {
          ...state.originalData,
          textes: state.originalData.textes.map((item) => {
            if (item.id === action.payload.id) {
              return {
                ...item,
                keywords: item.keywords.filter(
                  (key) => key !== action.payload.tag
                ),
              };
            } else return item;
          }),
        },
      };

    case "DELETE_TAG_TITLE_CREATION_PACKAGE_PUB":
      return {
        ...state,
        titre: {
          ...state.titre,
          titres: state.titre.titres.map((item) => {
            if (item.id === action.payload.id) {
              return {
                ...item,
                keywords: item.keywords.filter(
                  (key) => key !== action.payload.tag
                ),
              };
            } else return item;
          }),
        },
        originalData: {
          ...state.originalData,
          titres: state.originalData.titres.map((item) => {
            if (item.id === action.payload.id) {
              return {
                ...item,
                keywords: item.keywords.filter(
                  (key) => key !== action.payload.tag
                ),
              };
            } else return item;
          }),
        },
      };
    case "UNSELECT_ALL_POSTS":
      return {
        ...state,
        originalData: {
          ...state.originalData,
          postsInstagram: state.originalData.postsInstagram.map((item) => {
            return { ...item, isSelected: false };
          }),
          postsFacebook: state.originalData.postsFacebook.map((item) => {
            return { ...item, isSelected: false };
          }),
        },
        postsInstagram: {
          ...state.postsInstagram,
          data: state.postsInstagram.data.map((item) => {
            return { ...item, isSelected: false };
          }),
        },
        postsFacebook: {
          ...state.postsFacebook,
          data: state.postsFacebook.data.map((item) => {
            return { ...item, isSelected: false };
          }),
        },
      };

    case "CLEAR_PAGINATION_POSTS_FACEBOOK_INSTAGRAM":
      return {
        ...state,
        postsFacebook: {
          ...state.postsFacebook,
          pagination: {
            count: 7,
            startIndex: 0,
            endIndex: 7,
          },
        },
        postsInstagram: {
          ...state.postsInstagram,
          pagination: {
            count: 7,
            startIndex: 0,
            endIndex: 7,
          },
        },
      };
    case "TOGGLE_SELECT_POST_FACEBOOK":
      return {
        ...state,
        originalData: {
          ...state.originalData,
          postsFacebook: state.originalData.postsFacebook.map((item) => {
            if (item.id === action.payload) {
              return { ...item, isSelected: !item.isSelected };
            } else {
              return item;
            }
          }),
        },
        postsFacebook: {
          ...state.postsFacebook,
          data: state.postsFacebook.data.map((item) => {
            if (item.id === action.payload) {
              return { ...item, isSelected: !item.isSelected };
            } else {
              return item;
            }
          }),
        },
      };
    case "TOGGLE_SELECT_POST_INSTAGRAM":
      return {
        ...state,
        originalData: {
          ...state.originalData,
          postsInstagram: state.originalData.postsInstagram.map((item) => {
            if (item.id === action.payload) {
              return { ...item, isSelected: !item.isSelected };
            } else {
              return item;
            }
          }),
        },
        postsInstagram: {
          ...state.postsInstagram,
          data: state.postsInstagram.data.map((item) => {
            if (item.id === action.payload) {
              return { ...item, isSelected: !item.isSelected };
            } else {
              return item;
            }
          }),
        },
      };

    case "GET_SPONSORED_POSTS_CREATION_PACKAGE_PUB":
      return {
        ...state,
        sponsoredPosts: state.nomPackage
          ? action.payload
              .filter((item) => valeurExiste(item.keywords, state.nomPackage))
              .map((item) => ({ ...item, isSelected: true }))
          : action.payload,
        originalData: {
          ...state.originalData,
          sponsoredPosts: state.nomPackage
            ? action.payload
                .filter((item) => valeurExiste(item.keywords, state.nomPackage))
                .map((item) => ({ ...item, isSelected: true }))
            : action.payload,
        },
      };
    // case "ADD_INSTAGRAM_POSTS_TO_SPONSORED_POSTS":
    //   return {
    //     ...state,
    //     posts: {
    //       ...state.posts,
    //       data: [...state.posts.data, ...action.payload],
    //     },
    //     originalData: {
    //       ...state.originalData,
    //       posts: [...state.posts.data, ...action.payload],
    //     },
    //   };
    case "SET_END_INDEX_PAGINATION_POSTS_FACEBOOK":
      return {
        ...state,
        postsFacebook: {
          ...state.postsFacebook,
          pagination: {
            ...state.postsFacebook.pagination,
            endIndex: parseInt(action.payload),
            startIndex:
              parseInt(action.payload) - state.postsFacebook.pagination.count,
          },
        },
      };
    case "SET_END_INDEX_PAGINATION_POSTS_INSTAGRAM":
      return {
        ...state,
        postsInstagram: {
          ...state.postsInstagram,
          pagination: {
            ...state.postsInstagram.pagination,
            endIndex: parseInt(action.payload),
            startIndex:
              parseInt(action.payload) - state.postsInstagram.pagination.count,
          },
        },
      };
    case "GET_INSTAGRAM_POSTS_AJOUTER_POST":
      return {
        ...state,
        postsInstagram: {
          ...state.postsInstagram,
          data: removeDuplicatesByKey(
            [...state.postsInstagram.data, ...action.payload],
            "caption"
          ),
        },
        originalData: {
          ...state.originalData,
          postsInstagram: removeDuplicatesByKey(
            [...state.postsInstagram.data, ...action.payload],
            "caption"
          ),
        },
      };
    case "GET_FACEBOOK_POSTS_AJOUTER_POST":
      return {
        ...state,
        postsFacebook: {
          ...state.postsFacebook,
          // data: removeDuplicatesByKey(
          //   [...state.postsFacebook.data, ...action.payload],
          //   "message"
          // ),
          data: action.payload,
        },
        originalData: {
          ...state.originalData,
          // postsFacebook: removeDuplicatesByKey(
          //   [...state.postsFacebook.data, ...action.payload],
          //   "message"
          // ),
          postsFacebook: action.payload,
        },
      };
    case "GET_CAROUSELS_CREATION_PACKAGE_PUB":
      return {
        ...state,
        carousels: action.payload,
        originalData: {
          ...state.originalData,
          carousels: action.payload,
        },
      };
    case "ADD_TAG_CTA_LOCALLY":
      return {
        ...state,
        ctas: state.ctas.map((item) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              keywords: item.keywords
                ? [...item.keywords, action.payload.tag]
                : [action.payload.tag],
            };
          } else return item;
        }),
      };
    case "ADD_TAG_UTM_LOCALLY":
      return {
        ...state,
        utms: state.utms.map((item) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              keywords: item.keywords
                ? [...item.keywords, action.payload.tag]
                : [action.payload.tag],
            };
          } else return item;
        }),
      };
    case "ADD_TAG_LINK_LOCALLY":
      return {
        ...state,
        liens: state.liens.map((item) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              keywords: item.keywords
                ? [...item.keywords, action.payload.tag]
                : [action.payload.tag],
            };
          } else return item;
        }),
      };
    case "ADD_TAG_DESCRIPTION_LOCALLY":
      return {
        ...state,
        descriptions: state.descriptions.map((item) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              keywords: item.keywords
                ? [...item.keywords, action.payload.tag]
                : [action.payload.tag],
            };
          } else return item;
        }),
      };
    case "ADD_TAG_BODY_LOCALLY":
      return {
        ...state,
        texte: {
          ...state.texte,
          textes: state.texte.textes.map((item) => {
            if (item.id === action.payload.id) {
              return {
                ...item,
                keywords: item.keywords
                  ? [...item.keywords, action.payload.tag]
                  : [action.payload.tag],
              };
            } else return item;
          }),
        },
      };
    case "ADD_TAG_TITLE_LOCALLY":
      return {
        ...state,
        titre: {
          ...state.titre,
          titres: state.titre.titres.map((title) => {
            if (title.id === action.payload.id) {
              return {
                ...title,
                keywords: title.keywords
                  ? [...title.keywords, action.payload.tag]
                  : [action.payload.tag],
              };
            } else return title;
          }),
        },
      };

    case "PUT_CTAS_CREATION_PACKAGE_PUBLICTE_SUCCESS":
      return {
        ...state,
        ctas: [action.payload, ...state.ctas],
        originalData: {
          ...state.originalData,
          ctas: [action.payload, ...state.originalData.ctas],
        },
      };
    case "PUT_UTM_CREATION_PACKAGE_PUBLICTE_SUCCESS":
      return {
        ...state,
        utms: [action.payload, ...state.utms],
        originalData: {
          ...state.originalData,
          utms: [action.payload, ...state.originalData.utms],
        },
      };
    case "CREATE_LINK_CREATION_PACKAGE_PUBLICTE_SUCCESS":
      return {
        ...state,
        liens: [action.payload, ...state.liens],
        originalData: {
          ...state.originalData,
          liens: [action.payload, ...state.originalData.liens],
        },
      };
    case "CREATE_DESCRIPTION_CREATION_PACKAGE_PUBLICTE_SUCCESS":
      return {
        ...state,
        descriptions: [action.payload, ...state.descriptions],
        originalData: {
          ...state.originalData,
          descriptions: [action.payload, ...state.originalData.descriptions],
        },
      };
    case "CREATE_BODY_CREATION_PACKAGE_PUBLICTE_SUCCESS":
      return {
        ...state,
        texte: {
          ...state.texte,
          textes: [action.payload, ...state.texte.textes],
        },
        originalData: {
          ...state.originalData,
          textes: [action.payload, ...state.originalData.textes],
        },
      };
    case "CREATE_TITLE_CREATION_PACKAGE_PUBLICTE_SUCCESS":
      return {
        ...state,
        titre: {
          ...state.titre,
          titres: [action.payload, ...state.titre.titres],
        },
        originalData: {
          ...state.originalData,
          titres: [action.payload, ...state.originalData.titres],
        },
      };

    case "DELETE_CTA_LOCALLY":
      return {
        ...state,
        ctas: state.ctas.filter((cta) => cta.id !== action.payload),
      };
    case "ADD_TAG_VIDEO_ITEM_LOCALLY":
      return {
        ...state,
        video: {
          ...state.video,
          filteredVideos: state.video.filteredVideos.map((vid) => {
            if (vid.id === action.payload.videoId) {
              return {
                ...vid,
                keywords: [action.payload.tagToAdd, ...vid.keywords],
              };
            } else return vid;
          }),
        },
      };
    case "ADD_TAG_IMAGE_ITEM_LOCALLY":
      return {
        ...state,
        image: {
          ...state.image,
          filteredImages: state.image.filteredImages.map((img) => {
            if (img.id === action.payload.imageId) {
              return {
                ...img,
                keywords: [action.payload.tagToAdd, ...img.keywords],
              };
            } else return img;
          }),
        },
      };

    case "REMOVE_TAG_VIDEO_ITEM_LOCALLY":
      const { videoId, tagToDelete: tagToDeleteVideo } = action.payload;

      return {
        ...state,
        video: {
          ...state.video,
          filteredVideos: state.video.filteredVideos.map((vid) => {
            if (vid.id === videoId) {
              return {
                ...vid,
                keywords: vid.keywords.filter((tg) => tg !== tagToDeleteVideo),
              };
            } else return vid;
          }),
        },
      };
    case "REMOVE_TAG_IMAGE_ITEM_LOCALLY":
      const { imageId, tagToDelete } = action.payload;

      return {
        ...state,
        image: {
          ...state.image,
          filteredImages: state.image.filteredImages.map((img) => {
            if (img.id === imageId) {
              return {
                ...img,
                keywords: img.keywords.filter((tg) => tg !== tagToDelete),
              };
            } else return img;
          }),
        },
      };
    case "FILTER_ALL_ASSETS_BY_KEYWORDS":
      const keywords = action.payload;

      return {
        ...state,
        image: {
          ...state.image,
          filteredImages:
            keywords.length > 0
              ? filterByKeywords(state.image.images, keywords)
              : state.image.images,
        },
        video: {
          ...state.video,
          filteredVideos:
            keywords.length > 0
              ? filterByKeywords(state.video.videos, keywords)
              : state.video.videos,
        },

        utms:
          keywords.length > 0
            ? filterByKeywords(state.originalData.utms, keywords)
            : state.originalData.utms,

        ctas:
          keywords.length > 0
            ? filterByKeywords(state.originalData.ctas, keywords)
            : state.originalData.ctas,

        liens:
          keywords.length > 0
            ? filterByKeywords(state.originalData.liens, keywords)
            : state.originalData.liens,

        descriptions:
          keywords.length > 0
            ? filterByKeywords(state.originalData.descriptions, keywords)
            : state.originalData.descriptions,

        titre: {
          ...state.titre,
          titres:
            keywords.length > 0
              ? filterByKeywords(state.originalData.titres, keywords)
              : state.originalData.titres,
        },

        texte: {
          ...state.texte,
          textes:
            keywords.length > 0
              ? filterByKeywords(state.originalData.textes, keywords)
              : state.originalData.textes,
        },
      };
    case "SHOW_SELECTED_CTAS_FIRST":
      const selectedCTAS = state.ctas.filter((cta) => cta && cta.isSelected);
      const notSelectedCTAS = state.ctas.filter(
        (cta) => cta && !cta.isSelected
      );

      return {
        ...state,
        ctas: [...selectedCTAS, ...notSelectedCTAS],
      };
    case "TOGGLE_SELECT_CTA":
      return {
        ...state,
        originalData: {
          ...state.originalData,
          ctas: state.originalData.ctas.map((item) => {
            if (item.id === action.payload) {
              return { ...item, isSelected: !item.isSelected };
            } else {
              return item;
            }
          }),
        },
        ctas: state.ctas.map((item) => {
          if (item.id === action.payload) {
            return { ...item, isSelected: !item.isSelected };
          } else {
            return item;
          }
        }),
      };

    case "SHOW_SELECTED_DESCRIPTIONS_FIRST":
      const selectedDescriptions = state.descriptions.filter(
        (cta) => cta && cta.isSelected
      );
      const notSelectedDescriptions = state.descriptions.filter(
        (cta) => cta && !cta.isSelected
      );

      return {
        ...state,
        descriptions: [...selectedDescriptions, ...notSelectedDescriptions],
      };
    case "TOGGLE_SELECT_DESCRIPTION":
      return {
        ...state,
        originalData: {
          ...state.originalData,
          descriptions: state.originalData.descriptions.map((item) => {
            if (item.id === action.payload) {
              return { ...item, isSelected: !item.isSelected };
            } else {
              return item;
            }
          }),
        },
        descriptions: state.descriptions.map((item) => {
          if (item.id === action.payload) {
            return { ...item, isSelected: !item.isSelected };
          } else {
            return item;
          }
        }),
      };

    case "SHOW_SELECTED_LIENS_FIRST":
      const selectedLiens = state.liens.filter(
        (lien) => lien && lien.isSelected
      );
      const notSelectedLiens = state.liens.filter(
        (lien) => lien && !lien.isSelected
      );

      return {
        ...state,
        liens: [...selectedLiens, ...notSelectedLiens],
      };

    case "TOGGLE_SELECT_LINK":
      return {
        ...state,
        originalData: {
          ...state.originalData,
          liens: state.originalData.liens.map((item) => {
            if (item.id === action.payload) {
              return { ...item, isSelected: !item.isSelected };
            } else {
              return item;
            }
          }),
        },
        liens: state.liens.map((item) => {
          if (item.id === action.payload) {
            return { ...item, isSelected: !item.isSelected };
          } else {
            return item;
          }
        }),
      };

    case "SHOW_SELECTED_UTMS_FIRST":
      const selectedUtms = state.utms.filter((utm) => utm && utm.isSelected);
      const notSelectedUtms = state.utms.filter(
        (utm) => utm && !utm.isSelected
      );

      return {
        ...state,
        utms: [...selectedUtms, ...notSelectedUtms],
      };

    case "TOGGLE_SELECT_UTM":
      return {
        ...state,
        originalData: {
          ...state.originalData,
          utms: state.originalData.utms.map((item) => {
            if (item.id === action.payload) {
              return { ...item, isSelected: !item.isSelected };
            } else {
              return item;
            }
          }),
        },
        utms: state.utms.map((item) => {
          if (item.id === action.payload) {
            return { ...item, isSelected: !item.isSelected };
          } else {
            return item;
          }
        }),
      };

    case "SHOW_SELECTED_BODIES_FIRST":
      const selectedBodies = state.texte.textes.filter(
        (body) => body && body.isSelected
      );
      const notSelectedBodies = state.texte.textes.filter(
        (body) => body && !body.isSelected
      );

      return {
        ...state,
        texte: {
          ...state.texte,
          textes: [...selectedBodies, ...notSelectedBodies],
        },
      };

    case "TOGGLE_SELECT_TEXTE":
      return {
        ...state,
        originalData: {
          ...state.originalData,
          textes: state.originalData.textes.map((item) => {
            if (item.id === action.payload) {
              return { ...item, isSelected: !item.isSelected };
            } else {
              return item;
            }
          }),
        },
        texte: {
          ...state.texte,
          textes: state.texte.textes.map((item) => {
            if (item.id === action.payload) {
              return { ...item, isSelected: !item.isSelected };
            } else {
              return item;
            }
          }),
        },
      };

    case "SHOW_SELECTED_TITLES_FIRST":
      const selectedTitles = state.titre.titres.filter(
        (title) => title && title.isSelected
      );
      const notSelectedTitles = state.titre.titres.filter(
        (title) => title && !title.isSelected
      );

      return {
        ...state,
        titre: {
          ...state.titre,
          titres: [...selectedTitles, ...notSelectedTitles],
        },
      };

    case "TOGGLE_SELECT_TITRE":
      return {
        ...state,
        originalData: {
          ...state.originalData,
          titres: state.originalData.titres.map((item) => {
            if (item.id === action.payload) {
              return { ...item, isSelected: !item.isSelected };
            } else {
              return item;
            }
          }),
        },
        titre: {
          ...state.titre,
          titres: state.titre.titres.map((item) => {
            if (item.id === action.payload) {
              return { ...item, isSelected: !item.isSelected };
            } else {
              return item;
            }
          }),
        },
      };
    case "SET_END_INDEX_PAGINATION_BODIES":
      return {
        ...state,
        texte: {
          ...state.texte,
          pagination: {
            ...state.texte.pagination,
            endIndex: parseInt(action.payload),
            startIndex: parseInt(action.payload) - state.texte.pagination.count,
          },
        },
      };

    case "SET_END_INDEX_PAGINATION_TITLES":
      return {
        ...state,
        titre: {
          ...state.titre,
          pagination: {
            ...state.titre.pagination,
            endIndex: parseInt(action.payload),
            startIndex: parseInt(action.payload) - state.titre.pagination.count,
          },
        },
      };

    case "SHOW_SELECTED_IMAGES_FIRST":
      const selectedImages = state.image.filteredImages.filter(
        (img) => img && img.isSelected
      );
      const notSelectedImages = state.image.filteredImages.filter(
        (img) => img && !img.isSelected
      );

      const selectedImagesId = selectedImages.map((img) => img.id);
      return {
        ...state,
        image: {
          ...state.image,
          filteredImages: [...selectedImages, ...notSelectedImages],
          selectedImages: [...selectedImagesId],
        },
      };
    case "TOGGLE_SELECTED_IMAGE_CREATION_PACKAGE_PUB":
      return {
        ...state,
        image: {
          ...state.image,
          selectedImages: addOrRemoveId(
            action.payload,
            state.image.selectedImages
          ),
          filteredImages: state.image.filteredImages.map((img) => {
            if (img.id === action.payload) {
              return { ...img, isSelected: !img.isSelected };
            } else return img;
          }),
          images: state.image.images.map((img) => {
            if (img.id === action.payload) {
              return { ...img, isSelected: !img.isSelected };
            } else return img;
          }),
        },
      };

    case "SHOW_SELECTED_VIDEOS_FIRST":
      const selectedVideos = state.video.filteredVideos.filter(
        (img) => img && img.isSelected
      );
      const notSelectedVideos = state.video.filteredVideos.filter(
        (img) => img && !img.isSelected
      );

      return {
        ...state,
        video: {
          ...state.video,
          filteredVideos: [...selectedVideos, ...notSelectedVideos],
        },
      };
    case "TOGGLE_SELECTED_VIDEO_CREATION_PACKAGE_PUB":
      return {
        ...state,
        selectedVideos: addOrRemoveId(
          action.payload,
          state.video.selectedVideos
        ),
        video: {
          ...state.video,
          filteredVideos: state.video.filteredVideos.map((vid) => {
            if (vid.id === action.payload) {
              return { ...vid, isSelected: !vid.isSelected };
            } else return vid;
          }),
          videos: state.video.videos.map((vid) => {
            if (vid.id === action.payload) {
              return { ...vid, isSelected: !vid.isSelected };
            } else return vid;
          }),
        },
      };
    case "GET_CTAS_CREATION_PACKAGE_PUBLICTE_SUCCESS":
      const ctas = action.payload;
      return {
        ...state,
        ctas: ctas.map((item) => ({ ...item })),
        originalData: {
          ...state.originalData,
          ctas: ctas.map((item) => ({ ...item })),
        },
      };
    case "GET_LIENS_CREATION_PACKAGE_PUBLICTE_SUCCESS":
      const liens = action.payload;
      return {
        ...state,
        liens: liens.map((item) => ({ ...item })),
        originalData: {
          ...state.originalData,
          liens: liens.map((item) => ({ ...item })),
        },
      };
    case "GET_UTMS_CREATION_PACKAGE_PUBLICTE_SUCCESS":
      const utms = action.payload;
      return {
        ...state,
        utms: utms.map((item) => ({ ...item })),
        originalData: {
          ...state.originalData,
          utms: utms.map((item) => ({ ...item })),
        },
      };
    case "GET_DESCRIPTIONS_CREATION_PACKAGE_PUBLICTE_SUCCESS":
      const descs = action.payload;
      return {
        ...state,
        descriptions: descs.map((item) => ({ ...item })),
        originalData: {
          ...state.originalData,
          descriptions: descs.map((item) => ({ ...item })),
        },
      };
    case "GET_BODIES_CREATION_PACKAGE_PUBLICTE_SUCCESS":
      const bodies = action.payload;
      return {
        ...state,
        texte: {
          ...state.texte,
          textes: bodies.map((item) => ({ ...item })),
        },
        originalData: {
          ...state.originalData,
          textes: bodies.map((item) => ({ ...item })),
        },
      };
    case "GET_TITLES_CREATION_PACKAGE_PUBLICTE_SUCCESS":
      const titles = action.payload;
      return {
        ...state,
        titre: {
          ...state.titre,
          // eslint-disable-next-line
          titres: titles.map((item) => {
            if (item !== null) return { ...item };
          }),
        },
        originalData: {
          ...state.originalData,
          // eslint-disable-next-line
          titres: titles.map((item) => {
            if (item !== null) return { ...item };
          }),
        },
      };
    case "CHANGE_TAGS_CREATION_PACKAGE_PUB":
      return {
        ...state,
        tags: action.payload,
        image: {
          ...state.image,
          filteredImages: action.payload
            ? state.image.images.filter((img) =>
                img.keywords.includes(action.payload)
              )
            : state.image.images,
        },
        video: {
          ...state.video,
          filteredVideos: action.payload
            ? state.video.videos.filter((vid) =>
                vid.keywords.includes(action.payload)
              )
            : state.video.videos,
        },
        // eslint-disable-next-line
        utms: action.payload
          ? // eslint-disable-next-line
            state.originalData.utms.filter((item) => {
              if (item.keywords) {
                if (item.keywords.includes(action.payload)) return true;
              }
            })
          : state.originalData.utms,
        // eslint-disable-next-line
        ctas: action.payload
          ? // eslint-disable-next-line
            state.originalData.ctas.filter((item) => {
              if (item.keywords) {
                if (item.keywords.includes(action.payload)) return true;
              }
            })
          : state.originalData.ctas,
        // eslint-disable-next-line
        liens: action.payload
          ? // eslint-disable-next-line
            state.originalData.liens.filter((item) => {
              if (item.keywords) {
                if (item.keywords.includes(action.payload)) return true;
              }
            })
          : state.originalData.liens,
        // eslint-disable-next-line
        descriptions: action.payload
          ? // eslint-disable-next-line
            state.originalData.descriptions.filter((item) => {
              if (item.keywords) {
                if (item.keywords.includes(action.payload)) return true;
              }
            })
          : state.originalData.descriptions,
        titre: {
          ...state.titre,
          // eslint-disable-next-line
          titres: action.payload
            ? // eslint-disable-next-line
              state.originalData.titres.filter((item) => {
                if (item.keywords) {
                  if (item.keywords.includes(action.payload)) return true;
                }
              })
            : state.originalData.titres,
        },
        texte: {
          ...state.texte,
          // eslint-disable-next-line
          textes: action.payload
            ? // eslint-disable-next-line
              state.originalData.textes.filter((item) => {
                if (item.keywords) {
                  if (item.keywords.includes(action.payload)) return true;
                }
              })
            : state.originalData.textes,
        },
      };
    case "CHANGE_NOM_PACKAGE_CREATION_PACKAGE_PUB":
      return {
        ...state,
        nomPackage: action.payload,
        carousels: action.payload
          ? state.originalData.carousels
              .filter((item) => item.keywords.includes(action.payload))
              .map((item) => ({ ...item, isSelected: true }))
          : [],
        sponsoredPosts: action.payload
          ? state.originalData.sponsoredPosts
              .filter((item) => item.keywords.includes(action.payload))
              .map((item) => ({ ...item, isSelected: true }))
          : [],
        publicitesExistantes: action.payload
          ? state.originalData.publicitesExistantes
              .filter((item) => item.keywords.includes(action.payload))
              .map((item) => ({ ...item, isSelected: true }))
          : [],
      };
    case "CHANGE_NOM_PACKAGE_EDIT_PACKAGE_PUB":
      return {
        ...state,
        nomPackage: action.payload,
      };
    case "SAVE_TAG_DESCRIPTION_CREATION_PACKAGE_PUB":
      return {
        ...state,
        descriptions: state.descriptions.map((description) => {
          if (description.id === action.payload.id) {
            // eslint-disable-next-line
            return {
              ...description,
              tags: [...description.tags, action.payload.tag],
            };
          } else return description;
        }),
      };
    case "SAVE_TAG_TEXTE_CREATION_PACKAGE_PUB":
      return {
        ...state,
        textes: state.textes.map((texte) => {
          // eslint-disable-next-line
          if (texte.id === action.payload.id) {
            return {
              ...texte,
              tags: [...texte.tags, action.payload.tag],
            };
          } else return texte;
        }),
      };
    case "DELETE_DESCRIPTION_CREATION_PACKAGE_PUB":
      return {
        ...state,
        descriptions: state.descriptions.filter(
          (description) => description.id !== action.payload
        ),
      };
    case "SAVE_DESCRIPTION_CREATION_PACKAGE_PUB":
      return {
        ...state,
        descriptions: [
          ...state.descriptions,
          { id: uuidv4(), body: action.payload, tags: ["test1", "test2"] },
        ],
      };

    case "DELETE_TEXTE_CREATION_PACKAGE_PUB":
      return {
        ...state,
        textes: state.textes.filter((texte) => texte.id !== action.payload),
      };
    case "SAVE_TEXTE_CREATION_PACKAGE_PUB":
      return {
        ...state,
        textes: [
          ...state.textes,
          { id: uuidv4(), title: action.payload, tags: ["test1", "test2"] },
        ],
      };
    case "SET_END_INDEX_PAGINATION_IMAGES":
      return {
        ...state,
        image: {
          ...state.image,
          pagination: {
            ...state.image.pagination,
            endIndex: parseInt(action.payload),
            startIndex: parseInt(action.payload) - state.image.pagination.count,
          },
        },
      };
    case "SET_END_INDEX_PAGINATION_VIDEOS":
      return {
        ...state,
        video: {
          ...state.video,
          pagination: {
            ...state.video.pagination,
            endIndex: parseInt(action.payload),
            startIndex: parseInt(action.payload) - state.video.pagination.count,
          },
        },
      };
    case "GET_IMAGES_CREATION_PACKAGE_PUBLICTE_SUCCESS":
      const images = action.payload;
      return {
        ...state,
        image: {
          ...state.image,
          images: images,
          filteredImages: images,
        },
      };
    case "GET_VIDEOS_CREATION_PACKAGE_PUBLICTE_SUCCESS":
      const videos = action.payload;

      return {
        ...state,
        video: {
          ...state.video,
          videos: videos,
          filteredVideos: videos,
        },
      };
    case "FILTER_IMAGES_CREATION_PACKAGE_PUBLICTE":
      return {
        ...state,
        image: {
          ...state.image,
          filteredImages: action.payload
            ? // eslint-disable-next-line
              state.image.images.filter((img) => {
                if (img.name) {
                  return img.name
                    .toLowerCase()
                    .includes(action.payload.toLowerCase());
                }
              })
            : state.image.images,
        },
      };
    case "FILTER_VIDEOS_CREATION_PACKAGE_PUBLICTE":
      return {
        ...state,
        video: {
          ...state.video,
          filteredVideos: action.payload
            ? // eslint-disable-next-line
              state.video.videos.filter((vid) => {
                if (vid.title) {
                  return vid.title
                    .toLowerCase()
                    .includes(action.payload.toLowerCase());
                }
              })
            : state.video.videos,
        },
      };
    case "SET_EMPTY_NAME":
      return {
        ...state,
        emptyNameError: true,
      };
    case "CLEAN_EMPTY_NAME":
      return {
        ...state,
        emptyNameError: false,
      };
    case "SET_EMPTY_IMAGE":
      return {
        ...state,
        emptyImageError: true,
      };
    case "CLEAN_EMPTY_IMAGE":
      return {
        ...state,
        emptyImageError: false,
      };
    case "SET_EMPTY_VIDEO":
      return {
        ...state,
        emptyVideoError: true,
      };
    case "CLEAN_EMPTY_VIDEO":
      return {
        ...state,
        emptyVideoError: false,
      };
    case "SET_EMPTY_CAROUSEL":
      return {
        ...state,
        emptyCorousetError: true,
      };
    case "SET_EMPTY_CTAS":
      return {
        ...state,
        emptyCtasError: true,
      };
    case "CLEAN_ERROR_MSG":
      return {
        ...state,
        [action.payload.name]: false,
      };
    case "CLEAN_EMPTY_CAROUSEL":
      return {
        ...state,
        emptyCorousetError: false,
      };
    case "SET_EMPTY_PAS":
      return {
        ...state,
        emptyPasError: true,
      };
    case "CLEAN_EMPTY_PAS":
      return {
        ...state,
        emptyPasError: false,
      };
    case "GET_CATALOGS_PACKAGE_PUB":
      return {
        ...state,
        catalogs: action.payload,
      };
    case "SET_CATALOG_PACKAGE_PUB":
      return {
        ...state,
        catalog: action.payload,
      };
    case "GET_PRODUCT_SETS_PACKAGE_PUB":
      return {
        ...state,
        product_sets: action.payload,
      };
    case "SET_PRODUCT_SET_PACKAGE_PUB":
      return {
        ...state,
        product_set: action.payload,
      };
    case "SET_CATALOG_FORMATS_PACKAGE_PUB":
      let formats = [...state.catalogformats];
      if (!formats.includes(action.payload)) {
        formats.push(action.payload);
      } else {
        formats = formats.filter((i) => i !== action.payload);
      }

      return {
        ...state,
        catalogformats: formats,
      };
    case "CLEAR_CATALOG_FORMATS_PACKAGE_PUB":
      return {
        ...state,
        catalogformats: [],
      };
    case "SET_CATALOG_PACKAGE_PUB_ID":
      return {
        ...state,
        catalog_crea_id: action.payload,
      };
    default:
      return state;
  }
};
