
export const setToggleLeftMenu = () => {
    return { type: "SET_TOGGLE_LEFT_MENU" };
  };

export const saveCurrentPage = (location) => {
    const currentRoute = location.pathname;
    return { type: "SET_LAST_PAGE", payload:currentRoute};
  };

export const forgetLastPage = () => {
  return {type:"SET_LAST_PAGE", payload:""};
}

export const getSafeLastPage = (lastPage, file_configuration_id) => {
  if (lastPage){
    if (lastPage === "/creation-campagne" && file_configuration_id){
      return `/campagne/edit/${encodeURIComponent(file_configuration_id)}`
    }
    return lastPage
  }
  else{
    return -1
  }
}
