import React, { useState, useEffect } from "react";
// import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { toast } from "react-toastify";

import axiosInstance from "../../../store/axios.instance";
import Loader3 from "../../../components/Loader3/Loader3";

const Parametrages = () => {
  const [toggle, setToggle] = useState(false);
  const [loaderAlertsPreferences, setLoaderAlertsPreferences] = useState(false);
  const [loaderConnectedClients, setLoaderConnectedClients] = useState(false);
  const dispatch = useDispatch();

  const {
    adaccountId,
    connectedAccounts,
    userData: { id: user_id },
  } = useSelector((state) => state.auth.user);

  const { alertsPreferences } = useSelector((state) => state.alerts);

  const getAllAlertsPreferences = () => {
    setLoaderAlertsPreferences(true);
    axiosInstance
      .get(`/${adaccountId}/AlertsPreferences`)
      .then((res) => {
        // console.log(res.data);
        dispatch({
          type: "GET_ALERTS_PREFERENCES",
          payload: res.data,
        });

        setLoaderAlertsPreferences(false);
      })
      .catch((err) => {
        console.log(err);
        setLoaderAlertsPreferences(false);
        toast.error("Une erreur s'est produite, veuillez réessayer plus tard");
      });
  };

  const handleUpdateAlertsPreferences = () => {
    if (!adaccountId) {
      return;
    }
    setLoaderAlertsPreferences(true);
    axiosInstance
      .put(`/${adaccountId}/AlertsPreferences`, alertsPreferences)
      .then((res) => {
        // console.log(res.data);
        // dispatch({
        //   type: "GET_ALERTS_PREFERENCES",
        //   payload: res.data,
        // });

        setLoaderAlertsPreferences(false);
      })
      .catch((err) => {
        // console.log(err);
        setLoaderAlertsPreferences(false);
        // toast.error("Une erreur s'est produite, veuillez réessayer plus tard");
      });
  };

  // const selectAccount = (e) => {
  //   // Récupère l'élément select
  //   var select = e.target;
  //   // Récupère l'index de l'option sélectionnée
  //   var index = select.selectedIndex;
  //   // Récupère l'option sélectionnée
  //   var optionSelectionnee = select.options[index];
  //   // Récupère l'objet à partir de l'attribut data-object de l'option sélectionnée
  //   var compteSelectionne = JSON.parse(
  //     optionSelectionnee.getAttribute("data-object")
  //   );

  //   dispatch({
  //     type: "SET_ADACCOUNT_CHOOSEN_AUTH",
  //     payload: compteSelectionne.account,
  //   });

  //   dispatch({
  //     type: "SAVE_NAME_COMPTE_PRINCIPAL",
  //     payload: compteSelectionne.name,
  //   });

  //   localStorage.setItem("adaccountId", compteSelectionne.account);
  //   localStorage.setItem("adaccountName", compteSelectionne.name);

  //   dispatch({ type: "CLEAR_ALL_INPUTS_CREATION_CAMPAIGN" });
  //   dispatch({ type: "CLEAR_PACKAGE_GEO_DEMO" });
  // };

  const getConnectedAccounts = () => {
    setLoaderConnectedClients(true);

    axiosInstance
      .get(`/intersect_client/${user_id}`)
      .then((res) => {
        setTimeout(() => {
          dispatch({ type: "STORE_CONNECTED_ACCOUNTS", payload: res.data });
          setLoaderConnectedClients(false);
        }, 500);
      })
      .catch((err) => {
        console.log(err);
        setLoaderConnectedClients(false);
      });
  };

  const handleChange = (e) => {
    dispatch({
      type: "ADD_PREFERENCES_DATA",
      payload: {
        name: e.target.name,
        value: e.target.value,
      },
    });

    // setTimeout(() => {
    //   console.log("alertsPreferences", alertsPreferences);
    //   handleUpdateAlertsPreferences();
    // }, 1000);
  };

  useEffect(() => {
    getConnectedAccounts();
  }, []);

  useEffect(() => {
    handleUpdateAlertsPreferences();
  }, [alertsPreferences]);

  useEffect(() => {
    if (adaccountId) {
      getAllAlertsPreferences(adaccountId);
      dispatch({ type: "CLEAR_CAMPAIGN_REF_VARIABLES" });
      dispatch({ type: "CLEAR_PACKAGE_GEO_DEMO" });
    }
  }, [adaccountId]);

  return (
    <div className="section">
      <header
        className="section_header"
        onClick={() => setToggle((prevState) => !prevState)}
      >
        <h3 className="section_header_title">Paramétrage Alertes</h3>
        <i className={`fas fa-chevron-${toggle ? "up" : "down"}`}></i>
      </header>
      {toggle && (
        <div className="section_content parametrages">
          <div className="selecteur_compte_button">
            <div className="selecteur_compte">
              {/*{loaderConnectedClients ? (
                <Loader3 />
              ) : (
                <select value={adaccountId} onChange={selectAccount}>
                  <option value="">Sélectionner un compte</option>
                  {connectedAccounts.map((ac) => (
                    <option data-object={JSON.stringify(ac)} value={ac.account}>
                      {ac.name}
                    </option>
                  ))}
                </select>
                  )}*/}
            </div>
          </div>
          <div className="tableau">
            {loaderAlertsPreferences ? (
              <Loader3 />
            ) : (
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div className="row">
                        <p>Alertes liées aux créas</p>
                        <input
                          type="checkbox"
                          name="creations"
                          id="creations"
                          checked={
                            alertsPreferences && alertsPreferences.creations
                          }
                          onClick={handleChange}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="row">
                        <p>Alertes liées aux budgets</p>
                        <input
                          type="checkbox"
                          name="budgets"
                          id="budgets"
                          checked={
                            alertsPreferences && alertsPreferences.budgets
                          }
                          onClick={handleChange}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="row">
                        <p>Alertes liées aux audiences</p>
                        <input
                          type="checkbox"
                          name="audiences"
                          id="audiences"
                          checked={
                            alertsPreferences && alertsPreferences.audiences
                          }
                          onClick={handleChange}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="row">
                        <p>Alertes liées aux pixel/tracking</p>
                        <input
                          type="checkbox"
                          name="tracking"
                          id="tracking"
                          checked={
                            alertsPreferences && alertsPreferences.tracking
                          }
                          onClick={handleChange}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="row">
                        <p>Alertes liées aux performances</p>
                        <input
                          type="checkbox"
                          name="performances"
                          id="performances"
                          checked={
                            alertsPreferences && alertsPreferences.performances
                          }
                          onClick={handleChange}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="row">
                        <p>Alertes liées aux objectifs</p>
                        <input
                          type="checkbox"
                          name="objectifs"
                          id="objectifs"
                          checked={
                            alertsPreferences && alertsPreferences.objectifs
                          }
                          onClick={handleChange}
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Parametrages;
