import { AJOUT_PSEUDO, CLEAR_ALIASES, SUPPRESSION_PSEUDO } from "../types";

const initialState = {
  pseudos: [],
};

export const pseudosReducer = (state = initialState, action) => {
  switch (action.type) {
    case AJOUT_PSEUDO:
      return {
        ...state,
        pseudos: [...state.pseudos, action.payload],
      };
    case SUPPRESSION_PSEUDO:
      return {
        ...state,
        pseudos: state.pseudos.filter(secteur => secteur !== action.payload),
      };
    case CLEAR_ALIASES:
      return {
        ...state,
        pseudos: [],
      };
    default:
      return state;
  }
};
