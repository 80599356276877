import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loader3 from "../../../../components/Loader3/Loader3";
import CarouselItem from "../../../../components/CarouselItem/CarouselItem";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const Carousels = ({
  nomPackage,
  getCarousels,
  loaderCarousels,
  compagnId,
}) => {
  const [toggle, setToggle] = useState(false);
  const navigate = useNavigate();

  const { carousels } = useSelector(state => state.creationPackagePublicite);

  const { adaccountId } = useSelector(state => state.auth.user);

  // console.log('carousels compagnId', compagnId)

  useEffect(() => {
    getCarousels(adaccountId);

    if (nomPackage.length === 0) {
      setToggle(false);
    }
  }, [nomPackage]);

  const selectedCarousels = carousels.filter(car => car && car.isSelected);

  return (
    <section
      className='section section_texte section_carousel'
      style={
        !nomPackage
          ? { backgroundColor: "lightgrey", cursor: "not-allowed" }
          : {}
      }
    >
      <header
        style={!nomPackage ? { cursor: "not-allowed" } : {}}
        onClick={() => {
          if (nomPackage) setToggle(prevState => !prevState);
        }}
        className='section_header'
      >
        <div className='df'>
          <h3 className='section_header_title'>
            <FormattedMessage
              defaultMessage='Carousels'
              id='creation_package_pub_section_carousels'
            />
          </h3>

          {selectedCarousels && selectedCarousels.length > 0 && (
            <span className='nbr'>{selectedCarousels.length}</span>
          )}
        </div>

        <i className={`fas fa-chevron-${toggle ? "up" : "down"}`}></i>
      </header>
      {toggle && (
        <div className='section_content'>
          <div className='search_tags'>
            <div className='search'>
              <i className='fas fa-search'></i>
              <input
                // onChange={filterVideos}
                type='search'
                name=''
                id=''
                placeholder='Search'
              />
            </div>
          </div>
          <div className='list_carousels'>
            {loaderCarousels ? (
              <Loader3 />
            ) : (
              carousels.length > 0 &&
              carousels.map(carousel => (
                <CarouselItem
                  getCarousels={getCarousels}
                  key={carousel.id}
                  carousel={carousel}
                />
              ))
            )}

            <div
              className='add_carousel'
              onClick={() =>
                compagnId
                  ? navigate(`/creation-carousel?campaign_id=${compagnId}`)
                  : navigate("/creation-carousel")
              }
            >
              <img src='/add_carousel_icon.png' alt='' />
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Carousels;
