import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import axios from "axios";
import Alert from "@mui/material/Alert";
import ZonesGeoElement from "../../../components/ZonesGeoElement/ZonesGeoElement";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import axiosInstance from "../../../store/axios.instance";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ZonesGeographiquesLangues = ({ updateMode }) => {
  const dispatch = useDispatch();
  const [toggleZonesGeo, setToggleZonesGeo] = useState(true);
  const [langues, setLangues] = useState([]);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [regions, setRegions] = useState([]);
  const [citiesExclues, setCitiesExclues] = useState([]);
  const [regionsExclues, setRegionsExclues] = useState([]);
  const [errorAgeMin, setErrorAgeMin] = useState(false);
  const [errorAgeMax, setErrorAgeMax] = useState(false);
  const params = useParams();

  const {
    packageGeoDemo: {
      villes: selectedCities,
      villesExclues: selectedCitiesExclues,
      regions: selectedRegions,
      regionsExclues: selectedRegionsExclues,
      pays,
      name,
      ageMin,
      ageMax,
      genre,
      typeLocalisation,
      languesChoisies,
    },
    packageGeoDemoData,
  } = useSelector((state) => state.geodemo);
  const { emptyNameError, zonesGéosError } = useSelector(
    (state) => state.utils
  );
  
  const currentPackegeGeoDemoId = params.id;

  const handleChange = (e) => {
    dispatch({
      type: "SET_INPUT_PACKAGE_GEO_DEMO",
      payload: { name: e.target.name, value: e.target.value },
    });
  };

  const handleChangeName = (e) => {
    dispatch({
      type: "SET_INPUT_PACKAGE_GEO_DEMO",
      payload: { name: "name", value: e.target.value },
    });

    dispatch({
      type: "CHANGE_GEO_DEMO_NAME",
      payload: {
        name: e.target.value,
        id: currentPackegeGeoDemoId,
      },
    });
  };

  const handleChangeAgeMin = (e) => {
    if (e.target.value < 13) {
      setErrorAgeMin(true);
    } else {
      setErrorAgeMin(false);
      // Faire le traitement
    }

    dispatch({
      type: "SET_INPUT_PACKAGE_GEO_DEMO",
      payload: { name: "ageMin", value: e.target.value },
    });
  };

  const handleChangeAgeMax = (e) => {
    if (e.target.value > 65) {
      setErrorAgeMax(true);
    } else {
      setErrorAgeMax(false);
      // Faire le traitement
      dispatch({
        type: "SET_INPUT_PACKAGE_GEO_DEMO",
        payload: { name: "ageMax", value: e.target.value },
      });
    }
  };

  const toggleZonesGeoContainer = () => {
    setToggleZonesGeo((prevState) => !prevState);
  };

  const onInputChangeLangues = (event, newValue) => {
    if (newValue.length > 0) {
      getLangues(newValue);
    }
  };

  const onChangeGenre = (event) => {
    dispatch({
      type: "SET_INPUT_PACKAGE_GEO_DEMO",
      payload: { name: "genre", value: event.target.value },
    });
  };

  const onChangeLangues = (event, newValue) => {
    dispatch({
      type: "SET_INPUT_PACKAGE_GEO_DEMO",
      payload: { name: "languesChoisies", value: newValue },
    });
  };

  const onInputChangeCountries = (event, newValue) => {
    if (newValue.length > 0) {
      getCountries(newValue);
    }
  };
  const onInputChangeCities = (event, newValue) => {
    if (newValue.length > 0) {
      getCities(newValue);
    }
  };
  const onInputChangeRegions = (event, newValue) => {
    if (newValue.length > 0) {
      getRegions(newValue);
    }
  };
  const onInputChangeCitiesExclues = (event, newValue) => {
    if (newValue.length > 0) {
      getCitiesExclues(newValue);
    }
  };
  const onInputChangeRegionsExclues = (event, newValue) => {
    if (newValue.length > 0) {
      getRegionsExclues(newValue);
    }
  };

  const {
    userData: { token: accessToken },
  } = useSelector((state) => state.auth.user);

  const getLangues = (query) => {
    axios
      .get(
        `${process.env.REACT_APP_GRAPH_API}/search?access_token=${
          accessToken
          // dev_token
          // process.env.NODE_ENV === "development" ? dev_token : accessToken
        }&type=adlocale&q=${query}`
      )
      .then((result) => {
        setLangues(result.data.data);
      })
      .catch((err) => console.log(err));
  };

  const getLanguesInUpdate = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_GRAPH_API}/search?access_token=${
          accessToken
          // dev_token
          // process.env.NODE_ENV === "development" ? dev_token : accessToken
        }&type=adlocale&limit=500&q=`
      )
      .then((result) => {
        if (packageGeoDemoData.locales) {
          const lng = [];
          // eslint-disable-next-line
          packageGeoDemoData.locales.map((val) => {
            lng.push(result.data.data.find((l) => l.key === val));
          });
          dispatch({
            type: "EDIT_PACKAGE_GEO_DEMO_DATA",
            payload: { name: "languesChoisies", value: lng },
          });
        }
      })
      .catch((err) => console.log(err));
  };

  const getCountries = (query) => {
    axios
      .get(
        `${process.env.REACT_APP_GRAPH_API}/search?access_token=${
          accessToken
          // dev_token
          // process.env.NODE_ENV === "development" ? dev_token : accessToken
        }&location_types=["country"]&type=adgeolocation&q=${query}`
      )
      .then((result) => {
        setCountries(result.data.data);
        dispatch({ type: "CLEAN_EMPTY_ZONES_GEOS" });
      })
      .catch((err) => console.log(err));
  };

  const getCountriesInUpdate = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_GRAPH_API}/search?access_token=${
          accessToken
          // dev_token
          // process.env.NODE_ENV === "development" ? dev_token : accessToken
        }&location_types=["country"]&type=adgeolocation&limit=1000&q=`
      )
      .then((result) => {
        if (packageGeoDemoData.countries) {
          const pays = [];
          // eslint-disable-next-line
          packageGeoDemoData.countries.map((p) => {
            pays.push(result.data.data.find((l) => l.key === p));
          });
          dispatch({
            type: "EDIT_PACKAGE_GEO_DEMO_DATA",
            payload: { name: "pays", value: pays },
          });
        }
      })
      .catch((err) => console.log(err));
  };

  const getCities = (query) => {
    axios
      .get(
        `${process.env.REACT_APP_GRAPH_API}/search?access_token=${
          accessToken
          // dev_token
          // process.env.NODE_ENV === "development" ? dev_token : accessToken
        }&location_types=["city"]&type=adgeolocation&q=${query}`
      )
      .then((result) => {
        setCities(result.data.data.filter(location=>location.type==="city"));
        dispatch({ type: "CLEAN_EMPTY_ZONES_GEOS" });
      })
      .catch((err) => console.log(err));
  };

  const getCitiesInUpdate = () => {
    if (packageGeoDemoData.cities) {
      setCities(packageGeoDemoData.cities);
      dispatch({
        type: "EDIT_PACKAGE_GEO_DEMO_DATA",
        payload: { name: "villes", value: packageGeoDemoData.cities },
      });
    }
  };

  const getRegions = (query) => {
    axios
      .get(
        `${process.env.REACT_APP_GRAPH_API}/search?access_token=${
          accessToken
          // dev_token
          // process.env.NODE_ENV === "development" ? dev_token : accessToken
        }&location_types=["region"]&type=adgeolocation&q=${query}`
      )
      .then((result) => {
        setRegions(result.data.data.filter(location => location.type === "region"));
        dispatch({ type: "CLEAN_EMPTY_ZONES_GEOS" });
      })
      .catch((err) => console.log(err));
  };

  const getRegionsInUpdate = () => {
    if (packageGeoDemoData.regions) {
      setRegions(packageGeoDemoData.regions);
      dispatch({
        type: "EDIT_PACKAGE_GEO_DEMO_DATA",
        payload: { name: "regions", value: packageGeoDemoData.regions },
      });
    }
  };

  const getCitiesExclues = (query) => {
    axios
      .get(
        `${process.env.REACT_APP_GRAPH_API}/search?access_token=${
          accessToken
          // dev_token
          // process.env.NODE_ENV === "development" ? dev_token : accessToken
        }&location_types=["city"]&type=adgeolocation&q=${query}`
      )
      .then((result) => {
        setCitiesExclues(result.data.data);
      })
      .catch((err) => console.log(err));
  };

  const getCitiesExcluesInUpdate = () => {
    if (packageGeoDemoData.excluded_cities) {
      setCitiesExclues(packageGeoDemoData.excluded_cities);
      dispatch({
        type: "EDIT_PACKAGE_GEO_DEMO_DATA",
        payload: {
          name: "villesExclues",
          value: packageGeoDemoData.excluded_cities,
        },
      });
    }
  };

  const getRegionsExclues = (query) => {
    axios
      .get(
        `${process.env.REACT_APP_GRAPH_API}/search?access_token=${
          accessToken
          // dev_token
          // process.env.NODE_ENV === "development" ? dev_token : accessToken
        }&location_types=["region"]&type=adgeolocation&q=${query}`
      )
      .then((result) => {
        setRegionsExclues(result.data.data);
      })
      .catch((err) => console.log(err));
  };

  const getRegionsExcluesInUpdate = async () => {
    if (packageGeoDemoData.excluded_regions) {
      setRegionsExclues(packageGeoDemoData.excluded_regions);
      dispatch({
        type: "EDIT_PACKAGE_GEO_DEMO_DATA",
        payload: {
          name: "regionsExclues",
          value: packageGeoDemoData.excluded_regions,
        },
      });
    }
  };

  const handleChangeRegionsExclues = (event, newValue) => {
    dispatch({
      type: "SET_INPUT_PACKAGE_GEO_DEMO",
      payload: { name: "regionsExclues", value: newValue },
    });
  };

  const handleChangeCitiesExclues = (event, newValue) => {
    dispatch({
      type: "SET_INPUT_PACKAGE_GEO_DEMO",
      payload: { name: "villesExclues", value: newValue },
    });
  };

  const handleChangeRegions = (event, newValue) => {
    dispatch({
      type: "SET_INPUT_PACKAGE_GEO_DEMO",
      payload: { name: "regions", value: newValue },
    });
  };

  const handleChangeCities = (event, newValue) => {
    dispatch({
      type: "SET_INPUT_PACKAGE_GEO_DEMO",
      payload: { name: "villes", value: newValue },
    });
  };

  const handleChangeCountries = (event, newValue) => {
    dispatch({
      type: "SET_INPUT_PACKAGE_GEO_DEMO",
      payload: { name: "pays", value: newValue },
    });
  };

  const { adaccountId } = useSelector((state) => state.auth.user);

  const getPackagesGeoDemo = async () => {
    const res = await axiosInstance.get(
      `/${adaccountId}/geo_demo_package/${currentPackegeGeoDemoId}`
    );
    if (res.status === 200) {
      dispatch({
        type: "GET_PACKAGE_GEO_DEMO_DATA",
        payload: res.data,
      });
      dispatch({
        type: "EDIT_PACKAGE_GEO_DEMO_DATA",
        payload: { name: "name", value: res.data.name },
      });
      dispatch({
        type: "EDIT_PACKAGE_GEO_DEMO_DATA",
        payload: { name: "ageMin", value: res.data.age_min },
      });
      dispatch({
        type: "EDIT_PACKAGE_GEO_DEMO_DATA",
        payload: { name: "ageMax", value: res.data.age_max },
      });
      dispatch({
        type: "EDIT_PACKAGE_GEO_DEMO_DATA",
        payload: { name: "genre", value: JSON.stringify(res.data.genders) },
      });
      dispatch({
        type: "EDIT_PACKAGE_GEO_DEMO_DATA",
        payload: {
          name: "typeLocalisation",
          value: JSON.stringify(res.data.location_types),
        },
      });
    } else {
      dispatch({
        type: "SET_ALERT_GENERAL_ERROR_MESSAGE",
        payload: "Une erreur inattendue est survenue !",
      });
      dispatch({ type: "SHOW_ALERT_GENERAL_ERROR_MESSAGE" });
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", function (event) {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    });
    if (updateMode) {
      getPackagesGeoDemo();
    }
  }, []);

  useEffect(() => {
    if (updateMode) {
      getLanguesInUpdate();
      getCountriesInUpdate();
      getCitiesInUpdate();
      getRegionsInUpdate();
      getCitiesExcluesInUpdate();
      getRegionsExcluesInUpdate();
    }
  }, [packageGeoDemoData]);

  
  const intl = useIntl();

  return (
    <section className="informations_generales section">
      <header
        // onClick={() => setToggle(prevState => !prevState)}
        className="section_header"
      >
        <div className="df">
          <h3 className="section_header_title">
            <FormattedMessage
              defaultMessage="Ciblage géo/demo"
              id="screen_creation_package_geo_demo_3"
            />
          </h3>
        </div>
        {/* <i className={`fas fa-chevron-${!toggle ? "down" : "up"}`}></i> */}
      </header>
      <div className="informations_generales_content">
        <form action="">
          <div
            className="row"
            style={{ flexDirection: "column", alignItems: "start" }}
          >
            <div className={`input_group ${emptyNameError && " inputError"}`}>
              <label htmlFor="">
                <FormattedMessage
                  defaultMessage="Nom du package"
                  id="screen_creation_package_geo_demo_4"
                />
              </label>
              <input type="text" onChange={handleChangeName} value={name} />
            </div>
            <div className="messageError">
              {emptyNameError && (
                <FormattedMessage
                  defaultMessage="Le nom est obligatoire"
                  id="screen_creation_package_geo_demo_error_msg_1"
                />
              )}
            </div>
          </div>
          <div className="row" style={{ alignItems: "flex-start" }}>
            <div className="input_group">
              <label htmlFor="" style={{ color: "#000" }}>
                <FormattedMessage
                  defaultMessage="Age et genre"
                  id="screen_creation_package_geo_demo_xcv123"
                />
              </label>
              <div className="inputs_container">
                <div className="input">
                  <label style={{ marginBottom: 13 }} htmlFor="">
                    <FormattedMessage
                      defaultMessage="Age Min"
                      id="screen_creation_package_geo_demo_ert74"
                    />
                  </label>
                  <input
                    value={ageMin}
                    type="number"
                    onChange={handleChangeAgeMin}
                  />
                </div>
                <div className="input">
                  <label style={{ marginBottom: 13 }} htmlFor="">
                    <FormattedMessage
                      defaultMessage="Age Max"
                      id="screen_creation_package_geo_demo_789nbv"
                    />
                  </label>
                  <input
                    value={ageMax}
                    type="number"
                    onChange={handleChangeAgeMax}
                  />
                </div>
                <div className="input input_3">
                  <label htmlFor="">
                    <FormattedMessage
                      defaultMessage="Genre"
                      id="screen_creation_package_geo_demo_6"
                    />
                  </label>
                  <FormControl
                    variant="standard"
                    sx={{ m: 1, minWidth: 120 }}
                  >
                    <Select
                      name="pays"
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      onChange={onChangeGenre}
                      value={genre}
                    >
                      <MenuItem value="Tous">
                        <FormattedMessage
                          defaultMessage="Tous"
                          id="screen_creation_package_geo_demo_7"
                        />
                      </MenuItem>
                      <MenuItem value="[1]">
                        <FormattedMessage
                          defaultMessage="Hommes"
                          id="screen_creation_package_geo_demo_8"
                        />
                      </MenuItem>
                      <MenuItem value="[2]">
                        <FormattedMessage
                          defaultMessage="Femmes"
                          id="screen_creation_package_geo_demo_9"
                        />
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
            <div className="input_group">
              <label htmlFor="" style={{ color: "#000", marginBottom: 35 }}>
                <FormattedMessage
                  defaultMessage="Langues"
                  id="screen_creation_package_geo_demo_10"
                />
              </label>
              <Autocomplete
                value={languesChoisies}
                noOptionsText="trouver des langues"
                multiple
                onChange={onChangeLangues}
                onInputChange={onInputChangeLangues}
                id="checkboxes-tags-demo"
                options={langues}
                disableCloseOnSelect
                getOptionLabel={(option) => option.name}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )}
                style={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    placeholder={intl.formatMessage({
                      defaultMessage: "Toutes les langues",
                      id: "zones_geo_all_languages_WqA123",
                    })}
                    variant="standard"
                    {...params}
                  />
                )}
              />
            </div>
          </div>
          {errorAgeMin && (
            <Alert severity="error">
              <FormattedMessage
                defaultMessage="Age Min doit être supérieur à 13 ans"
                id="screen_creation_package_geo_demo_11"
              />
            </Alert>
          )}
          {errorAgeMax && (
            <Alert severity="error">
              <FormattedMessage
                defaultMessage="Age Max doit être inférieur à 65 ans"
                id="screen_creation_package_geo_demo_12"
              />
            </Alert>
          )}
          <h4 className="title_zones_geo">
            <FormattedMessage
              defaultMessage="Zones géographiques"
              id="screen_creation_package_geo_demo_13"
            />

            <i
              onClick={toggleZonesGeoContainer}
              className={`fas fa-chevron-${!toggleZonesGeo ? "down" : "up"}`}
            ></i>
          </h4>
          <div className="selected_elements">
            {selectedCities.length > 0 &&
              selectedCities.map((city) => (
                <ZonesGeoElement type="ville" data={city} />
              ))}
            {selectedRegions.length > 0 &&
              selectedRegions.map((region) => (
                <ZonesGeoElement type="region" data={region} />
              ))}
            {selectedCitiesExclues.length > 0 &&
              selectedCitiesExclues.map((city) => (
                <ZonesGeoElement excluded type="villeExclue" data={city} />
              ))}
            {selectedRegionsExclues.length > 0 &&
              selectedRegionsExclues.map((region) => (
                <ZonesGeoElement excluded data={region} type="regionExclue" />
              ))}
          </div>
          {toggleZonesGeo && (
            <div className="zones_geo_container">
              <div className="row">
                <div style={{ flexDirection: "column", alignItems: "start" }}>
                  <div
                    className={`input_group objectif ${
                      zonesGéosError && "inputError"
                    }`}
                    style={{ width: "100%" }}
                  >
                    <label htmlFor="">
                      <FormattedMessage
                        defaultMessage="Pays"
                        id="screen_creation_package_geo_demo_14"
                      />
                    </label>
                    <Autocomplete
                      value={pays}
                      noOptionsText="trouver des pays"
                      multiple
                      onChange={handleChangeCountries}
                      onInputChange={onInputChangeCountries}
                      id="checkboxes-tags-demo"
                      options={countries}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name}
                        </li>
                      )}
                      style={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField variant="standard" {...params} />
                      )}
                    />
                  </div>
                  <span className="messageError">
                    {zonesGéosError && (
                      <FormattedMessage
                        defaultMessage="Veuillez choisir l'un des éléments Pays, Région, Ville"
                        id="screen_creation_package_geo_demo_error_msg_2"
                      />
                    )}
                  </span>
                </div>
                <div className="input_group objectif">
                  <label htmlFor="">
                    <FormattedMessage
                      defaultMessage="Type de localisation"
                      id="screen_creation_package_geo_demo_15"
                    />
                  </label>
                  <FormControl
                    variant="standard"
                    sx={{ m: 1, minWidth: 120 }}
                  >
                    <Select
                      name="typeLocalisation"
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      onChange={handleChange}
                      value={typeLocalisation}
                    >
                      <MenuItem value='["home","recent"]'>
                        <FormattedMessage
                          defaultMessage="Personnes habitant ou étant récemment passé par ici"
                          id="screen_creation_package_geo_demo_16"
                        />
                      </MenuItem>
                      <MenuItem value='["home"]'>
                        <FormattedMessage
                          defaultMessage="Personnes habitant ici"
                          id="screen_creation_package_geo_demo_17"
                        />
                      </MenuItem>
                      <MenuItem value='["recent"]'>
                        <FormattedMessage
                          defaultMessage="Personnes passées par ici"
                          id="screen_creation_package_geo_demo_18"
                        />
                      </MenuItem>
                      <MenuItem value='["travel_in"]'>
                        <FormattedMessage
                          defaultMessage="Personnes ayant récemment voyagé ici"
                          id="screen_creation_package_geo_demo_19"
                        />
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="row">
                <div className="input_group objectif">
                  <label htmlFor="">
                    <FormattedMessage
                      defaultMessage="Villes"
                      id="screen_creation_package_geo_demo_20"
                    />
                  </label>
                  <Autocomplete
                    // value={updateMode ? selectedCities : ""}
                    defaultValue={updateMode && selectedCities}
                    noOptionsText="trouver des villes"
                    multiple
                    onChange={handleChangeCities}
                    onInputChange={onInputChangeCities}
                    id="checkboxes-tags-demo"
                    options={cities}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {`${option.name}, ${option.region}, ${option.country_name}`}
                      </li>
                    )}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField variant="standard" {...params} />
                    )}
                  />
                </div>
                <div className="input_group objectif">
                  <label htmlFor="">
                    <FormattedMessage
                      defaultMessage="Régions"
                      id="screen_creation_package_geo_demo_21"
                    />
                  </label>
                  <Autocomplete
                    // value={selectedRegions}
                    defaultValue={updateMode && selectedRegions}
                    noOptionsText="trouver des régions"
                    multiple
                    onChange={handleChangeRegions}
                    onInputChange={onInputChangeRegions}
                    id="checkboxes-tags-demo"
                    options={regions}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.name}
                      </li>
                    )}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField variant="standard" {...params} />
                    )}
                  />
                </div>
              </div>
              <div className="row">
                <div className="input_group objectif">
                  <label htmlFor="">
                    <FormattedMessage
                      defaultMessage="Villes exclues"
                      id="screen_creation_package_geo_demo_22"
                    />
                  </label>
                  <Autocomplete
                    // value={selectedCitiesExclues}
                    defaultValue={updateMode && selectedCitiesExclues}
                    noOptionsText="trouver des villes"
                    multiple
                    onChange={handleChangeCitiesExclues}
                    onInputChange={onInputChangeCitiesExclues}
                    id="checkboxes-tags-demo"
                    options={citiesExclues}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {`${option.name}, ${option.region}, ${option.country_name}`}
                      </li>
                    )}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField variant="standard" {...params} />
                    )}
                  />
                </div>
                <div className="input_group objectif">
                  <label htmlFor="">
                    <FormattedMessage
                      defaultMessage="Régions exclues"
                      id="screen_creation_package_geo_demo_23"
                    />
                  </label>
                  <Autocomplete
                    // value={selectedRegionsExclues}
                    defaultValue={updateMode && selectedRegionsExclues}
                    noOptionsText="trouver des régions"
                    multiple
                    onChange={handleChangeRegionsExclues}
                    onInputChange={onInputChangeRegionsExclues}
                    id="checkboxes-tags-demo"
                    options={regionsExclues}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.name}
                      </li>
                    )}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField variant="standard" {...params} />
                    )}
                  />
                </div>
              </div>
            </div>
          )}
        </form>
      </div>
    </section>
  );
};

export default ZonesGeographiquesLangues;
