import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../store/axios.instance";
import { FormattedMessage } from "react-intl";
import "./style.css";
import { getEvenements } from "../../store/actions/listeCampagnes";
import { Menu, MenuItem } from "@mui/material";
//this code is terrible

const FilterInsights = () => {
  const dispatch = useDispatch();
  const [accounts, setAccounts] = useState([]);
  const [loader2, setLoader2] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  // eslint-disable-next-line
  const [date, setDate] = React.useState("");

  const { adaccountId } = useSelector((state) => state.auth.user);
  const { filteredinsights: insights, singleCampaignConfigurations } =
    useSelector((state) => state.insights);

  const open = Boolean(anchorEl);
  const handleClickButton = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    dates: { startDate, endDate },
  } = useSelector((state) => state.utils);

  const handleChangeDate = (value) => {
    setDate(value);
    handleClose();

    const options = [30, 14, 7];

    if (value === "Choisir des dates" || !options.includes(value)) {
      dispatch({ type: "SHOW_MODAL_FILTER_INSIGHTS_BY_DATE" });
    } else {
      dispatch({ type: "SET_LOADER_INSIGHTS" });

      const nbrJours = parseInt(value);
      const time_interval = `${nbrJours}d`;

      axiosInstance
        .get(
          `/${adaccountId}/precomputed_insights?level=campaign&date_range=${time_interval}`
        )
        .then((res) => {
          setTimeout(() => {
            dispatch({ type: "GET_INSIGHTS", payload: res.data });
            // setLoader(false);
            dispatch({ type: "UNSET_LOADER_INSIGHTS" });
          }, 2000);
        })
        .catch((err) => console.log(err));
    }
  };

  const getInsights = (ad_acc) => {
    // setLoader(true);
    dispatch({ type: "SET_LOADER_INSIGHTS" });
    dispatch({ type: "GET_INSIGHTS", payload: [] });

    axiosInstance
      .get(`/${ad_acc}/precomputed_insights?level=campaign`)
      .then((res) => {
        dispatch({ type: "GET_INSIGHTS", payload: res.data });
        // console.log(res.data);

        // Get insights details campagne
        dispatch({
          type: "GET_INSIGHTS_DETAIL_CAMPAGNE",
          payload: res.data,
        });

        // cb();
      })
      .catch((err) => console.log(err));
  };

  const getSingleCampaignConfigurations = (ad_acc) => {
    axiosInstance
      .get(
        `/${ad_acc}/single_campaign_configuration?fields=id&fields=name&fields=data&fields=author&fields=draft&fields=facebook_campaign_id`
      )
      .then((res) => {
        dispatch({
          type: "GET_SINGLE_CAMPAIGN_CONFIGURATIONS",
          payload: res.data,
        });

        setTimeout(() => {
          dispatch({ type: "UNSET_LOADER_INSIGHTS" });
        }, 500);
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: "SET_ALERT_GENERAL_ERROR_MESSAGE",
          payload: err.message,
        });
        dispatch({ type: "SHOW_ALERT_GENERAL_ERROR_MESSAGE" });
      });
  };

  const lang = navigator.language.split(/[-_]/)[0];
  const uppercasedLang = lang.toUpperCase();

  const filterByAdaccount = (e) => {
    dispatch({ type: "CLEAR_ALL_INPUTS_CREATION_CAMPAIGN" });
    dispatch({ type: "CLEAR_CAMPAIGN_REF_VARIABLES" });
    getInsights(e.target.value);
    getSingleCampaignConfigurations(e.target.value);

    insights.map((insight) => {
      const matchingItem = singleCampaignConfigurations.find(
        (item) => item.facebook_campaign_id === insight.campaign_id
      );
      if (matchingItem) {
        // console.log(matchingItem);
        insight["singleCampaignConfigurationId"] = matchingItem.id;
        insight["draft"] = matchingItem.draft;
        dispatch({
          type: "FELTER_SINGLE_CAMPAIGN_CONFIGURATION",
          payload: matchingItem,
        });
      }
    });

    dispatch(getEvenements(e.target.value, uppercasedLang));

    // Switch actual active adaccount
    dispatch({
      type: "SAVE_ID_COMPTE_PRINCIPAL",
      payload: e.target.value,
    });

    if (accounts && accounts.length > 0) {
      const comptePrincipal = accounts.find((acc) => acc.id === e.target.value);

      if (comptePrincipal) {
        const nameComptePrincipal = comptePrincipal.name;

        dispatch({
          type: "SAVE_NAME_COMPTE_PRINCIPAL",
          payload: nameComptePrincipal,
        });

        localStorage.setItem("adaccountName", nameComptePrincipal);
      }
    }

    localStorage.setItem("adaccountId", e.target.value);

    dispatch({ type: "CLEAR_COUTS_COLONNES_DYNAMIQUES" });
  };

  const {
    userData: { id: user_id },
  } = useSelector((state) => state.auth.user);

  const getIntersectAccounts = () => {
    setLoader2(true);

    axiosInstance
      .get(`/intersect_client/${user_id}`)
      .then((res) => {
        let intersect_clients = res.data.map((item) => {
          item.id = item.account;
          delete item.account;

          return item;
        });

        intersect_clients = intersect_clients.sort(function (a, b) {
          var nameA = a.name.toUpperCase(); // ignore la casse
          var nameB = b.name.toUpperCase(); // ignore la casse
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          // les noms sont égaux
          return 0;
        });

        setAccounts(intersect_clients);
        // dispatch({
        //   type: "SWITCH_ADACCOUNT_ID_CURRENT_USER",
        //   payload: intersect_clients[0].id,
        // });
        // getInsights(intersect_clients[0].id);
        // getSingleCampaignConfigurations(intersect_clients[0].id);

        setTimeout(() => {
          setLoader2(false);
        }, 500);
      })
      .catch((err) => {
        console.log(err);
        setLoader2(false);
      });
  };

  const shosDateHandler = () => {
    handleClose();
  };

  useEffect(() => {
    getIntersectAccounts();
  }, []);

  return (
    <div className="filtres">
      <p>
        <FormattedMessage
          defaultMessage="Filtrer par"
          id="cmp_filter_insights_title_one"
        />
      </p>

      <p
        className="filter_date"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClickButton}
      >
        {date && date !== "Choisir des dates"
          ? date + " derniers jours"
          : startDate || endDate
          ? startDate + "/" + endDate
          : "Filtrer"}
      </p>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={() => handleChangeDate(30)}>
          <FormattedMessage
            defaultMessage="30 derniers jours"
            id="cmp_filter_insights_30days"
          />
        </MenuItem>
        <MenuItem onClick={() => handleChangeDate(14)}>
          <FormattedMessage
            defaultMessage="14 derniers jours"
            id="cmp_filter_insights_14days"
          />
        </MenuItem>
        <MenuItem onClick={() => handleChangeDate(7)}>
          <FormattedMessage
            defaultMessage="7 derniers jours"
            id="cmp_filter_insights_7days"
          />
        </MenuItem>
        {startDate && endDate ? (
          <MenuItem onClick={() => handleChangeDate("Choisir des dates")}>
            {startDate} / {endDate}
          </MenuItem>
        ) : (
          <MenuItem onClick={() => handleChangeDate("Choisir des dates")}>
            <FormattedMessage
              defaultMessage="Choisir des dates"
              id="cmp_filter_insights_choose_dates"
            />
          </MenuItem>
        )}
      </Menu>
      <div className="compte_client border_rd">
        {loader2 ? (
          <p>Loading ...</p>
        ) : (
          <select
            onChange={filterByAdaccount}
            name=""
            id=""
            defaultValue={adaccountId}
          >
            <option value="">
              <FormattedMessage
                defaultMessage="Compte client"
                id="cmp_filter_insights_client_account"
              />
            </option>
            {accounts &&
              accounts.length > 0 &&
              accounts.map((adc) => (
                <option key={adc.id} value={adc.id}>
                  {adc.name}
                </option>
              ))}
          </select>
        )}
      </div>
    </div>
  );
};

export default FilterInsights;
