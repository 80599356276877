import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LeftMenu from "../../components/LeftMenu/LeftMenu";
import "./style.css";
import { FormattedMessage } from "react-intl";
import TopRightMenu from "../../components/TopRightMenu/TopRightMenu";
import Event from "./Event";
import axiosInstance from "../../store/axios.instance";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import StepsMonitoring from "../../components/StepsComponents/StepsMonitoring";
import Loader3 from "../../components/Loader3/Loader3";
import RightContainer from "../../components/RightContainer/RightContainer";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const PriorisationEvenments = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [loader3, setLoader3] = useState(false);
  const [loaderConnectedClients, setLoaderConnectedClients] = useState(false);
  const { toggleLeftMenu } = useSelector((state) => state.utils);

  const setToggleLeftMenu = () => {
    dispatch({ type: "SET_TOGGLE_LEFT_MENU" });
  };

  const { adaccountId } = useSelector((state) => state.auth.user);

  const lang = navigator.language.split(/[-_]/)[0];
  const uppercasedLang = lang.toUpperCase();

  const getEvenements = async (cb, adaccount) => {
    // console.log("getEvenements...");
    setLoader3(true);
    setLoader(true);

    const result = await axiosInstance.get(
      `/${adaccount}/monitoring_events?lang=${uppercasedLang}`
    );

    if (result.status === 200) {
      // Success
      dispatch({
        type: "GET_EVENEMENTS_PRIORISATION_EVENEMENTS",
        payload: result.data,
      });

      setTimeout(() => {
        setLoader(false);

        cb();
      }, 500);
    }
  };

  const { liste } = useSelector((state) => state.priorisationEvenements);
  const { connectedAccounts } = useSelector((state) => state.auth.user);

  const createNewItem = () => {
    // Get the highest priority
    // Add to it one to add new event
    const maxPriority = liste.reduce((max, objet) => {
      return objet.priority > max ? objet.priority : max;
    }, -1);

    dispatch({
      type: "CREATE_NEW_ELEMENT_PRIORISATION_EVENEMENTS",
      payload: {
        uniq_id: uuidv4(),
        id: null,
        priority: maxPriority + 1,
        pixel_custom_conversion: null,
        event_name: null,
      },
    });
  };

  const nextStep = () => {
    navigate("/configuration-monitoring");
  };

  const savePriorisation = async () => {
    if (!adaccountId) {
      toast.error("Vous devez sélectionner un compte avant de continuer");
      return;
    }

    if (liste.length > 0) {
      setLoader2(true);

      let newListe = [];

      newListe = liste.map((item) => {
        return {
          name: item.event_name.id,
          priority: item.priority,
          source: item.pixel_custom_conversion.name,
        };
      });

      // Création dans le cas où n'existe pas
      // Modification dans le cas où ça existe
      const priorisationExiste = await axiosInstance.get(
        `/${adaccountId}/priorisation`
      );

      if (priorisationExiste.status === 200) {
        if (priorisationExiste.data === null) {
          // Création
          axiosInstance
            .put(`/${adaccountId}/priorisation`, {
              events: newListe,
            })
            .then(() => {
              setTimeout(() => {
                toast.success("Priorisation crée !");
                setLoader2(false);
                navigate("/configuration-monitoring");
              }, 500);
            })
            .catch((err) => {
              console.log(err);
              setLoader2(false);
            });
        } else {
          // Modification
          axiosInstance
            .post(`/${adaccountId}/priorisation`, {
              events: newListe,
            })
            .then(() => {
              setTimeout(() => {
                toast.success("Priorisation modifiée !");
                setLoader2(false);
                navigate("/configuration-monitoring");
              }, 500);
            })
            .catch((err) => {
              console.log(err);
              setLoader2(false);
            });
        }
      }
    } else {
      dispatch({
        type: "SET_ALERT_GENERAL_ERROR_MESSAGE",
        payload: "Vous devez insérer au moins un évènement !",
      });
      dispatch({ type: "SHOW_ALERT_GENERAL_ERROR_MESSAGE" });
    }
  };

  const getPriorisation = async (adaccount) => {
    try {
      // console.log("getPriorisation...");
      setLoader3(true);

      const response = await axiosInstance.get(`/${adaccount}/priorisation`);

      if (response.status === 200) {
        dispatch({ type: "GET_PRIORISATION", payload: response.data });
        setTimeout(() => {
          setLoader3(false);
        }, 500);
      }
    } catch (error) {
      setTimeout(() => {
        setLoader3(false);
      }, 500);
    }
  };

  const {
    userData: { id: user_id },
  } = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (adaccountId) {
      getEvenements(() => {
        getPriorisation(adaccountId);
      }, adaccountId);
    }
  }, []);

  useEffect(() => {
    getEvenements(() => {
      getPriorisation(adaccountId);
    }, adaccountId);
  }, [adaccountId]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  // Function to update list on drop
  const handleDrop = (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    var updatedList = [...liste];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    // Update State
    dispatch({
      type: "UPDATE_ORDER_LIST_EVENTS_PRIORISATION",
      payload: updatedList,
    });
  };

  return (
    <div className="ecran_priorisation_evenments">
      <LeftMenu />
      <RightContainer>
        <header className="right_content_header">
          <div className="back_title">
            {toggleLeftMenu ? (
              <i
                onClick={() => setToggleLeftMenu((prevState) => !prevState)}
                style={{ marginRight: 30, cursor: "pointer" }}
                className="fas fa-bars fa-2x"
              ></i>
            ) : null}
            <h1 className="right_content_header_title">
              <FormattedMessage
                defaultMessage="Priorisation des évènements"
                id="screen_priorisation_events_1"
              />
            </h1>
          </div>
          <TopRightMenu />
        </header>
        <div className="right_content_main">
          <StepsMonitoring activeStep="priorisation" />
          <div className="main__content">
            <p className="main_para">
              <FormattedMessage
                defaultMessage="Sélectionnez vos événements / conversions personnalisées et triez-les par priorité.
                Nous utiliserons ces priorités par défaut pour optimiser vos campagnes"
                id="screen_priorisation_events_2"
              />
            </p>

            <div className="table_content">
              <header className="table_content_header">
                <div className="left">
                  {/*<p>
                    <FormattedMessage
                      defaultMessage="Sélectionner un compte"
                      id="screen_priorisation_events_3"
                    />
                  </p>
                  {loaderConnectedClients ? (
                    <Loader3 />
                  ) : (
                    <select value={adaccountId} onChange={selectAccount}>
                      <option value="">
                        <FormattedMessage
                          defaultMessage="Sélecionner un compte"
                          id="select_account_ghuio7397"
                        />
                      </option>
                      {connectedAccounts.map((ac) => (
                        <option
                          data-object={JSON.stringify(ac)}
                          value={ac.account}
                        >
                          {ac.name}
                        </option>
                      ))}
                    </select>
                  )}*/}
                </div>
                <button onClick={createNewItem}>
                  +
                  <FormattedMessage
                    defaultMessage="Ajouter un évènement"
                    id="screen_priorisation_events_4"
                  />
                </button>
              </header>
              <div className="table">
                <div className="head">
                  <p>
                    Priority <span className="priority_defaut">?</span>
                  </p>
                  <p>Pixel/Custom conversion</p>
                  <p>
                    Event name <span className="event_defaut">?</span>
                  </p>
                  <p>
                    <FormattedMessage
                      defaultMessage="Opération"
                      id="screen_priorisation_events_11"
                    />
                  </p>
                </div>
                <div className="body">
                  {loader3 ? (
                    <Loader3 />
                  ) : liste.length > 0 ? (
                    <>
                      <DragDropContext onDragEnd={handleDrop}>
                        <Droppable
                          direction="vertical"
                          droppableId="list-container"
                        >
                          {(provided) => (
                            <div
                              className="list-container"
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {liste.map((item, index) => (
                                <Draggable
                                  key={item.uniq_id}
                                  draggableId={item.uniq_id}
                                  index={index}
                                >
                                  {(provided) => (
                                    <div
                                      className="item-container"
                                      ref={provided.innerRef}
                                      {...provided.dragHandleProps}
                                      {...provided.draggableProps}
                                    >
                                      <Event
                                        liste={liste}
                                        length={liste.length}
                                        index={index}
                                        getPriorisation={getPriorisation}
                                        loader={loader}
                                        key={item.id}
                                        item={item}
                                      />
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </>
                  ) : (
                    <p style={{ textAlign: "center", marginTop: 30 }}>
                      Aucun évènement pour l'instant
                    </p>
                  )}
                </div>
                {/* <div className='add_event'>
                  {createNew && <EventToCreate />}
                </div> */}
              </div>
            </div>

            <div className="dfe">
              {/* <button onClick={() => navigate("/configuration-monitoring")}> */}
              <button style={{ marginRight: 10 }} onClick={savePriorisation}>
                <FormattedMessage
                  defaultMessage="Enregistrer"
                  id="single_monitoring_save_123_dfhkjrtyhjk"
                />
              </button>
              {loader2 ? (
                <Loader3 />
              ) : (
                <button onClick={nextStep}>
                  <FormattedMessage
                    defaultMessage="Suivant"
                    id="screen_priorisation_events_5"
                  />
                </button>
              )}
            </div>
          </div>
        </div>
      </RightContainer>
    </div>
  );
};

export default PriorisationEvenments;
