import React from "react";
import { useDispatch } from "react-redux";
import axiosInstance from "../axios.instance";


const initialState = {
    loaded_data: false,
    publisher_platforms: [],
    device_platforms: [],
    user_os: [],
    wireless_carrier: "",
    platform_positions: [],
    platform_positions_formatted: []
}

export const configurationRefReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_CONFIGURATION_REFERENCE":
            return {
                ...state,
                publisher_platforms: action.payload.publisher_platforms,
                device_platforms: action.payload.device_platforms,
                user_os: action.payload.user_os,
                wireless_carrier: action.payload.wireless_carrier,
                platform_positions: action.payload.platform_positions,
                platform_positions_formatted: action.payload.platform_positions_formatted,
                loaded_data: true,
            }
    default:
        return state;
    }
}
