import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { importFBLogin } from "../../utils";
import axios from "axios";
import { useSelector } from "react-redux";

const ListItem = ({ acc: { id, name, comptePrincipal, isSelected } }) => {
  const dispatch = useDispatch();

  const toggleSelectItem = () => {
    dispatch({ type: "TOGGLE_SELECT_ADACCOUNT", payload: id });
  };

  const rendreComptePrincipal = (e) => {
    e.stopPropagation();
    dispatch({ type: "TOGGLE_COMPTE_PRINCIPAL_ADACCOUNT", payload: id });

    // console.log("comptePrincipal:", comptePrincipal);
    if (!comptePrincipal) {
      dispatch({ type: "SET_ADACCOUNT_CHOOSEN_AUTH", payload: id });
      dispatch({ type: "SAVE_NAME_COMPTE_PRINCIPAL", payload: name });
    }
  };

  const [profilImg, setProfilImg] = useState("");

  const {
    userData: { token: accessToken, dev_token },
  } = useSelector((state) => state.auth.user);

  useEffect(() => {
    importFBLogin();
  }, []);

  useEffect(() => {
    if (id) {
      setTimeout(async () => {
        try {
          const response = await axios.get(
            `${
              process.env.REACT_APP_GRAPH_API
            }/${id}/adimages?fields=url_128&access_token=${
              accessToken
              // process.env.NODE_ENV === "development" ? dev_token : accessToken
            }`
          );

          if (response.status === 200) {
            if (response.data.data && response.data.data.length > 0) {
              setProfilImg(response.data.data[0].url_128);
            }
            // console.log(response.data.data[0]);
          } else {
          }
        } catch (error) {
          console.log(error);
        }
      }, 500);
    }
  }, [id]);

  return (
    <div
      className={`list_item ${isSelected ? "selected" : ""}`}
      onClick={() => toggleSelectItem(id)}
    >
      <div className="left">
        <input type="checkbox" name="" id="" checked={isSelected} />
        <div className="account">
          <img
            loading="lazy"
            src={profilImg ? profilImg : "/user_icon.png"}
            alt=""
            width="32"
            height="32"
            style={{ borderRadius: "100%" }}
          />
          <p>{name}</p>
        </div>
      </div>
      <div className="right">
        <button
          onClick={rendreComptePrincipal}
          className={`${comptePrincipal ? "active" : ""}`}
        >
          Compte principal
        </button>
      </div>
    </div>
  );
};
export default ListItem;
