import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import axiosInstance from "../../store/axios.instance";
import { useSelector } from "react-redux";
import Loader3 from "../Loader3/Loader3";
import DataTable from "react-data-table-component";

const AlertesListeCampagnes = () => {
  const [alerts, setAlerts] = useState([]);
  const [alertsFormated, setAlertsFormated] = useState([]);
  const [loader, setLoader] = useState(false);

  const [columns, setColumns] = useState([]);
  const [alertsTable, setAlertsTable] = useState([]);

  const { adaccountId } = useSelector((state) => state.auth.user);

  let data;
  const getAlerts = async (adacc) => {
    try {
      setLoader(true);
      setColumns([]);
      setAlertsTable([]);

      const response = await axiosInstance.get(`/${adacc}/alert?level=account`);

      if (response.status === 200) {
        setAlerts(response.data);
        setLoader(false);

        if (response.data && response.data.length > 0) {
          const desiredOrder = [
            "created_time",
            "priority",
            "campaign_name",
            "adset_name",
            "ad_name",
            "sentence_EN",
          ];
          const sortedKeys = desiredOrder.concat(
            Object.keys(response.data[0]).filter(
              (key) => !desiredOrder.includes(key)
            )
          );

          const allColumns = sortedKeys.map((key) => {
            return {
              name: key,
              selector: (row) => row[key],
              sortable: true,
            };
          });

          allColumns.push({
            name: "Action",
            sortable: false,
            selector: (row) => row["Action"],
          });
          const includedColumns = [
            "Alert",
            "Date",
            "Priorité",
            "Campagne",
            "Adset",
            "Ad",
            "Action",
          ];

          const columns = allColumns.filter((column) => {
            if (column.name === "created_time") {
              column.id = "1";
              column.name = "Date";
              column.width = "80px";
            }
            if (column.name === "campaign_name") {
              column.name = "Campagne";
              column.width = "80px";
            }
            if (column.name === "adset_name") {
              column.name = "Adset";
              column.width = "80px";
            }
            if (column.name === "ad_name") {
              column.name = "Ad";
              column.width = "80px";
            }
            if (column.name === "priority") {
              column.name = "Priorité";
              column.width = "80px";
            }
            if (column.name === "Action") {
              column.name = "Action";
              column.width = "100px";
            }
            if (column.name === "sentence_EN") {
              column.id = 1;
              column.name = "Alert";
              column.cell = (row) => (
                <div
                  dangerouslySetInnerHTML={{
                    __html: row.sentence_EN,
                  }}
                />
              );
            }
            return includedColumns.includes(column.name);
          });

          setColumns(columns);

          const data = response.data.map((alert) => {
            const newDate = new Date(alert.created_time);
            // Get month and day as two-digit strings
            const month = (newDate.getMonth() + 1).toString().padStart(2, "0");
            const day = newDate.getDate().toString().padStart(2, "0");

            // Combine them to create the "mm/dd" format
            const formattedDate = `${month}/${day}`;
            return {
              created_time: formattedDate,
              priority: alert.priority,
              campaign_name: alert.campaign_name.map((c) => (
                <span title={c}> {c} </span>
              )),
              ad_name: alert.ad_name.map((c) => <span title={c}> {c} </span>),
              adset_name: alert.adset_name.map((c) => (
                <span title={c}> {c} </span>
              )),
              sentence_EN: replaceVideoWithLink(
                alert.sentence_EN,
                alert.video_url,
                alert.video_url.length
              ),
              Action: (
                <div
                  style={{
                    width: "100px",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i
                    onClick={evaluateAlert}
                    style={{
                      color: "#e83e8c",
                      cursor: "pointer",
                      fontSize: "16px",
                    }}
                    className="fas fa-thumbs-up"
                  ></i>
                  <i
                    onClick={evaluateAlert}
                    style={{
                      color: "#e83e8c",
                      cursor: "pointer",
                      fontSize: "16px",
                    }}
                    className="fas fa-thumbs-down"
                  ></i>
                  <i
                    onClick={removeAlert}
                    style={{
                      color: "#e83e8c",
                      cursor: "pointer",
                      fontSize: "16px",
                    }}
                    className="fa fa-close"
                  ></i>
                </div>
              ),
            };
          });
          setAlertsTable(data);
        }
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const removeAlert = () => {
    console.log("remove");
  };

  const evaluateAlert = () => {
    console.log("evaluate");
  };

  function replaceVideoWithLink(text, videoUrls, len) {
    let indexx = 0;
    return text.replace(/video/g, (match, index) => {
      const videoUrl = videoUrls[indexx];
      indexx++; // Increment the index for the next occurrence
      return indexx <= len
        ? `<a href='${videoUrl}' target='_blank'>video${indexx}</a>`
        : "video";
    });
  }

  useEffect(() => {
    const renderedData = alerts.map((item) => ({
      ...item,
      sentence_EN: replaceVideoWithLink(
        item.sentence_EN,
        item.video_url,
        item.video_url.length
      ),
    }));
    setAlertsFormated(renderedData);
  }, [alerts]);

  useEffect(() => {
    if (adaccountId) getAlerts(adaccountId);
  }, [adaccountId]);

  return (
    <div className="informations_compte">
      <header>
        <h6 className="title">
          <FormattedMessage
            defaultMessage="INFORMATIONS SUR LE COMPTE"
            id="screen-liste-campagne-infos-account"
          />
        </h6>
      </header>
      <div className="content">
        {loader ? (
          <Loader3 />
        ) : alertsFormated.length !== 0 ? (
          <DataTable
            columns={columns}
            data={alertsTable}
            pagination
            // customStyles={customStyles}
            striped
            highlightOnHover
            // dense
            fixedHeader
            fixedHeaderScrollHeight
          />
        ) : (
          <p style={{ textAlign: "center" }}>Aucune alerte pour l'instant</p>
        )}
      </div>
    </div>
  );
};
export default AlertesListeCampagnes;

// <ul>
//             {alertsFormated.map((alert) => (
//               <li>
//                 <p dangerouslySetInnerHTML={{ __html: alert.sentence_EN }}></p>
//               </li>
//             ))}
//           </ul>
