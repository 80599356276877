import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import axiosInstance from "../../../../store/axios.instance";
import Loader3 from "../../../../components/Loader3/Loader3";
import Pagination from "@mui/material/Pagination";
import TitleItem from "./TitleItem";
import { autoExpand } from "../../../../utils";
import { useSearchParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import Switch from "@mui/material/Switch";
import SearchAsset from "../../../../components/SearchAsset/SearchAsset";

const Titre = ({ getTitles, loaderTitles }) => {
  const [toggle, setToggle] = useState(false);
  const [newText, setNewText] = useState("");
  // eslint-disable-next-line
  const [loader, setLoader] = useState(false);
  const [showTags, setShowTags] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const dispatch = useDispatch();

  const handleChangePage = (event, value) => {
    event.stopPropagation();

    dispatch({
      type: "SET_END_INDEX_PAGINATION_TITLES",
      payload: value * count,
    });
  };

  const handleNewTextChange = (e) => {
    setNewText(e.target.value);
    // console.log(e.target.value);
  };

  const saveNewText = (e) => {
    // if (e.key === "Enter") {
    //   setLoader(true);

    //   if (!newText) {
    //     toast.error("Veuillez insérer une valeur non vide");
    //     return;
    //   }

    //   const body = [
    //     {
    //       content: newText,
    //       keywords: [],
    //     },
    //   ];

    //   axiosInstance
    //     .put(`/${adaccountId}/AdTitles`, body)
    //     .then(res => {
    //       setTimeout(() => {
    //         dispatch({
    //           type: "CREATE_TITLE_CREATION_PACKAGE_PUBLICTE_SUCCESS",
    //           payload: res.data[0],
    //         });

    //         toast.success("Titre crée avec succès");

    //         setLoader(false);
    //       }, 500);
    //     })
    //     .catch(err => {
    //       dispatch({ type: "CREATE_TITLE_CREATION_PACKAGE_PUBLICTE_FAIL" });
    //       console.log(err);
    //       setLoader(false);
    //     });

    //   setNewText("");
    // }

    if (e.key === "Enter" && e.shiftKey) {
      e.preventDefault();
      setNewText(e.target.value + "\n");
      // console.log(e.target.value + "\n");
    }
  };

  const saveNewTextOnClick = (e) => {
    if (!newText) {
      toast.error("Veuillez insérer une valeur non vide");
      return;
    }

    const body = [
      {
        content: newText,
        keywords: [],
      },
    ];

    axiosInstance
      .put(`/${adaccountId}/AdTitles`, body)
      .then((res) => {
        setTimeout(() => {
          dispatch({
            type: "CREATE_TITLE_CREATION_PACKAGE_PUBLICTE_SUCCESS",
            payload: res.data[0],
          });

          toast.success("Titre crée avec succès");

          setLoader(false);
        }, 500);
      })
      .catch((err) => {
        dispatch({ type: "CREATE_TITLE_CREATION_PACKAGE_PUBLICTE_FAIL" });
        console.log(err);
        setLoader(false);
      });

    setNewText("");
  };

  const {
    titre: {
      titres,
      pagination: { startIndex, endIndex, count },
    },
    originalData: { titres: titresOriginaux },
  } = useSelector((state) => state.creationPackagePublicite);

  const { adaccountId } = useSelector((state) => state.auth.user);

  const titlesToShow = titres.slice(startIndex, endIndex);

  const selectedTitles = titresOriginaux.filter(
    (item) => item && item.isSelected
  );
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!searchParams.get("keep-data")) {
      getTitles(adaccountId);
    }
  }, []);

  useEffect(() => {
    if (toggle && selectedTitles.length > 0) {
      dispatch({ type: "SHOW_SELECTED_TITLES_FIRST" });
    }
  }, [toggle]);

  const searchTitles = (e) => {
    setSearchValue(e.target.value);

    dispatch({ type: "SEARCH_TITLES", payload: e.target.value });
  };

  return (
    <section className="section section_texte">
      <header
        onClick={() => setToggle((prevState) => !prevState)}
        className="section_header"
      >
        <div className="df">
          <h3 className="section_header_title">
            <FormattedMessage
              defaultMessage="Titre"
              id="creation_package_pub_section_titre_1"
            />

            {selectedTitles.length > 0 && (
              <span className="nbr">{selectedTitles.length}</span>
            )}
          </h3>
        </div>

        <i className={`fas fa-chevron-${toggle ? "up" : "down"}`}></i>
      </header>
      {toggle && (
        <div className="section_content">
          <SearchAsset value={searchValue} onChange={searchTitles} />
          <div className="wrapper">
            <div className="add_text">
              <textarea
                onInput={autoExpand}
                value={newText}
                onKeyDown={saveNewText}
                onChange={handleNewTextChange}
                placeholder="Ajouter un titre"
              ></textarea>
              <i onClick={saveNewTextOnClick} className="fas fa-plus"></i>
            </div>
            <div className="tags">
              <i className="fas fa-info-circle"></i>
              <p>Tags</p>
              <Switch
                onChange={() => setShowTags((prevState) => !prevState)}
                checked={showTags}
              />
            </div>
          </div>
          <div className="list_texts">
            {loaderTitles ? (
              <Loader3 />
            ) : titlesToShow && titlesToShow.length > 0 ? (
              titlesToShow.map((texte, i) => (
                <TitleItem
                  key={i}
                  getTitles={getTitles}
                  texte={texte}
                  showTags={showTags}
                />
              ))
            ) : (
              <h6>
                <FormattedMessage
                  defaultMessage="Aucun titre pour l'instant"
                  id="creation_package_pub_section_titre_2"
                />
              </h6>
            )}
          </div>

          <div
            style={{ marginTop: 20, display: "flex", justifyContent: "center" }}
          >
            <Pagination
              count={parseInt(titres.length / count) + 1}
              onChange={handleChangePage}
            />
          </div>
        </div>
      )}
    </section>
  );
};

export default Titre;
