import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LeftMenu from "../../../components/LeftMenu/LeftMenu";
import Loader3 from "../../../components/Loader3/Loader3";
import Loader from "../../../components/Loader/Loader";
import axiosInstance from "../../../store/axios.instance";
import "./style.css";
import { toast } from "react-toastify";
import TopRightMenu from "../../../components/TopRightMenu/TopRightMenu";
import BaseAlertMessage from "../../../components/AlertMessages/BaseAlertMessage";
import { FormattedMessage, useIntl } from "react-intl";
import RightContainer from "../../../components/RightContainer/RightContainer";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { getSafeLastPage, forgetLastPage } from "../../../store/actions/utils/usualDispatches";

const ConfirmationCreationCampagne = ({updateCreas=false}) => {
  const [loader, setLoader] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [data, setData] = useState(null);
  const [monitorings, setMonitorings] = useState([]);
  const [loaderMonitorings, setLoaderMonitorings] = useState(false);
  const [ad, setAd] = useState(false);
  const [adset, setAdset] = useState(false);
  const [monitoring, setMonitoring] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const dispatch = useDispatch();
  const { toggleLeftMenu, lastPage} = useSelector((state) => state.utils);
  const { file_configuration_id} = useSelector((state) => state.creationCampagne);

  const setToggleLeftMenu = () => {
    dispatch({ type: "SET_TOGGLE_LEFT_MENU" });
  };

  const navigate = useNavigate();


  const getMmagaSingle = () => {
    let endpoint = `/mmaga/single/${file_configuration_id}`
    if (updateCreas){
      endpoint = `/mmaga/update_creas/${file_configuration_id}`
    }
    setLoader(true);

    axiosInstance
      .get(endpoint)
      .then((result) => {
        setData(result.data);
        setTimeout(() => {
          setLoader(false);
        }, 500);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
    // }
  };

  const intl = useIntl();

  const createCampaign = (result, e) => {
    if(result){
      if (file_configuration_id) {
        if (ad || adset) {
          if (!monitoring) {
            toast.error(
              intl.formatMessage({
                id: "decocher_monitoring_campagne_selectionner_strategie_128_uYg",
                defaultMessage:
                  "Décochez le monitoring de la campagne ou selectionnez une stratégie",
              })
            );
            return;
          }
        }

        setLoader2(true);
        let endpoint = `/mmaga/single/${file_configuration_id}`
        if (updateCreas){
          endpoint = `/mmaga/update_creas/${file_configuration_id}`
        }
    
        axiosInstance
          .put(endpoint)
          // .put(`/mmaga/single/458276181663445_[SE][FR] TEST – v1_30`)
          .then((file_configuration) => {
            setTimeout(() => {
              // Ajouter un message de confirmation une fois la publication de la campagne effectuée
              dispatch({
                type: "SET_ALERT_GENERAL_ERROR_MESSAGE",
                payload: "Publication de la campagne effectuée",
              });
              dispatch({ type: "SHOW_ALERT_GENERAL_ERROR_MESSAGE" });

              setLoader2(false);
              dispatch(forgetLastPage());
              navigate("/campagne/liste");

              // une fois que la campagne est publiée, vider tous les champs de la campagne
              dispatch({ type: "CLEAR_ALL_INPUTS_CREATION_CAMPAIGN" });
              dispatch({ type: "CLEAR_CAMPAIGN_REF_VARIABLES" });
    
              if (monitoring) {
                axiosInstance.post(`${adaccountId}/monitoring`, [
                  {
                    id: monitoring.id,
                    campaigns: [
                      ...monitoring.campaigns,
                      {
                        id: file_configuration.data.facebook_campaign_id,
                        name: data.name,
                        monitor_ads: ad,
                        monitor_adsets: adset,
                      },
                    ],
                  },
                ]);
              }
            }, 500);
          })
          .catch((err) => {
            console.log(err);
            setLoader2(false);
          });
          
      }
    }
    setShowConfirmation(false);
  };

  const navigateToScreenModifierCampagne = () => {
    let goTo = getSafeLastPage(lastPage, file_configuration_id);
    dispatch(forgetLastPage());
    navigate(goTo);
  };

  const { adaccountId } = useSelector((state) => state.auth.user);


  //another function that is somewhere else too i guess
  const getMonitorings = async (adaccount) => {
    try {
      setLoaderMonitorings(true);

      const response = await axiosInstance.get(`/${adaccount}/monitoring`);

      if (response.status === 200) {
        setMonitorings(response.data);

        setTimeout(() => {
          setLoaderMonitorings(false);
        }, 500);
      }
    } catch (error) {
      setTimeout(() => {
        setLoaderMonitorings(false);
      }, 500);
    }
  };

  const handleChangeMonitoring = (e, newValue) => {
    // console.log(newValue);
    setMonitoring(newValue);
  };

  useEffect(() => {
    getMonitorings(adaccountId);
    getMmagaSingle();
  }, []);


  //do something similar for any type and for adsets too
  const DisplayDeprecatedAds = ({ adset }) => {
    const [showTags, setShowTags] = useState(false);
    if (!adset.ads.some((ad)=>ad.action === "deprecated"))
     return
    return (
      <div className="wrapper">
        <div className="header">
          <span>
            <h3> Publicités désactivées </h3>
          </span>
          <span
            className="toggle_span"
            onClick={() => setShowTags((prevState) => !prevState)}
          >
            {showTags ? (
              <i className="fa fa-chevron-up"></i>
            ) : (
              <i className="fa fa-chevron-down"></i>
            )}
          </span>
        </div>
        <div className="td_content">
          <div style={showTags ? {} : { display: "none" }}>
            {adset.ads.map((ad) => (
              ad.action === "deprecated" && (
                <div className="ligne" key={ad.id}>
                  <p>{ad.name}</p>
                  <div className="number_see">
                    {/*<span className='number'>1</span>*/}
                    <a
                      style={{
                        marginLeft: "2rem",
                        color: "#e83e8c",
                      }}
                      href={`${ad.preview_shareable_link}`}
                      target="_blank"
                    >
                      <i className="far fa-eye"></i>
                    </a>
                  </div>
                </div>
              )
            ))}
          </div>
        </div>
      </div>
    );
  };


  return (
    <div className="ecran_creation_campagne_confirmation">
      <LeftMenu />
      <RightContainer>
        <header className="right_content_header">
          <div className="back_title">
            {toggleLeftMenu ? (
              <i
                onClick={() => setToggleLeftMenu((prevState) => !prevState)}
                style={{ marginRight: 30, cursor: "pointer" }}
                className="fas fa-bars fa-2x"
              ></i>
            ) : null}
            <div className="back">
              <img
                style={{ cursor: "pointer" }}
                onClick={navigateToScreenModifierCampagne}
                src="/left-arrow 2.png"
                alt="Retour"
              />
            </div>
            <h1 className="right_content_header_title">Confirmation</h1>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <TopRightMenu />
          </div>
        </header>
        {loader2 && (//TODO l message depending is updating_creas, or if campaign already existed
          <Loader
            message={intl.formatMessage({
              defaultMessage:
                "Création et lancement de la campagne en cours...",
              id: "loader_message_5",
            })}
          />
        )}
        {showConfirmation && (
          <BaseAlertMessage 
                message={
                  `La campagne sera construite sur Facebook Business manager

                   Voulez-vous continuer ?`}
                callback={createCampaign}
                />)}
        <div className="right_content_main">
          <div className="configurer_strategie">
            <div className="configurer_strategie_content">
              <div className="performance_objectif_conversion">
                <div className="performances_historique">
                  {loader ? (
                    <Loader3 />
                  ) : (
                    <React.Fragment>
                      <div className="row">
                        <div className="left">
                          <h3 className="performance_objectif_conversion_title">
                            {data && data.name}
                          </h3>
                          <div className="infobulle_img_container">
                            <img src="/info_icon.png" alt="" />
                            <div className="infobulle">
                              <p>
                                {/* Lorem ipsum dolor sit amet consectetur
                                adipisicing elit. Iste possimus quisquam atque
                                pariatur, dolorem ipsam necessitatibus tenetur
                                sed, quo autem libero? Reprehenderit eos numquam
                                ipsa consequuntur quibusdam pariatur, neque
                                laudantium.*/}
                              </p>
                            </div>
                          </div>
                        </div>
                        <button
                          style={{ cursor: "pointer" }}
                          onClick={navigateToScreenModifierCampagne}
                        >
                          Modifier
                        </button>
                      </div>
                      <div className="performances">
                        <table>
                          <thead>
                            <tr>
                              <th>Ad sets</th>
                              <th>Ads</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data &&
                              data.adsets.map((adset) => (
                                <tr>
                                  <td>
                                    <span className="round"></span> {adset.name}
                                  </td>
                                  <td className="second">
                                    <div className="td_content">
                                      {adset.ads.map((ad) => (
                                        <React.Fragment>
                                          {ad.action === "created" && (
                                            <div className="ligne" key={ad.id}>
                                              <p style={{ margin: 0 }}>{ad.name}</p>
                                              <div className="number_see">
                                                {/*<span className='number'>1</span>*/}
                                                <a
                                                  style={{
                                                    marginLeft: "2rem",
                                                  }}
                                                  href={`${ad.preview_shareable_link}`}
                                                  target="_blank"
                                                >
                                                  <i className="far fa-eye"></i>
                                                </a>
                                              </div>
                                            </div>
                                          )}
                                          {ad.action === "unchanged" && (
                                            <div className="ligne" key={ad.id}>
                                              <p style={{ color: "#aaa" }}>
                                                {ad.name}
                                              </p>
                                              <div className="number_see">
                                                {/*<span className='number'>1</span>*/}
                                                <a
                                                  style={{
                                                    marginLeft: "2rem",
                                                  }}
                                                  href={`${ad.preview_shareable_link}`}
                                                  target="_blank"
                                                >
                                                  <i className="far fa-eye"></i>
                                                </a>
                                              </div>
                                            </div>
                                          )}
                                        </React.Fragment>
                                      ))}
                                    </div>
                                    <DisplayDeprecatedAds adset={adset} />
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                        <div className="monitoring">
                          <div className="ad">
                            {/* <p>Ad</p> */}
                            {loaderMonitorings ? (
                              <Loader3 />
                            ) : (
                              <Autocomplete
                                onChange={handleChangeMonitoring}
                                disablePortal
                                id="combo-box-demo"
                                options={monitorings}
                                sx={{ width: 300 }}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    label={
                                      <FormattedMessage
                                        defaultMessage="Stratégie d'optimisation"
                                        id="screen-campagne-confirmation-strategy"
                                      />
                                    }
                                  />
                                )}
                              />
                            )}
                          </div>
                          <div className="monitor">
                            <p>Ad</p>
                            <input
                              type="checkbox"
                              checked={ad}
                              onChange={(e) => setAd(e.target.checked)}
                            />
                          </div>
                          <div className="adset">
                            <p>Adset</p>
                            <input
                              type="checkbox"
                              checked={adset}
                              onChange={(e) => setAdset(e.target.checked)}
                            />
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                  <div className="dfe">
                    <button
                      onClick={() => setShowConfirmation(true)}
                      // disabled={!data}
                      className="save"
                    >
                      <FormattedMessage defaultMessage="Publier" id="publish" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </RightContainer>
    </div>
  );
};

export default ConfirmationCreationCampagne;
