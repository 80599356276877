import { v4 as uuidv4 } from "uuid";

const initialState = {
  evenements: [],
  liste: [],
};

export const priorisationEvenementsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_ORDER_LIST_EVENTS_PRIORISATION":
      return {
        ...state,
        liste: action.payload.map((item, index) => ({
          ...item,
          priority: index + 1,
        })),
      };

    case "CLEAR_LISTE_PRIORISATION_EVENEMENTS":
      return {
        ...state,
        liste: [],
      };
    case "GET_PRIORISATION":
      return {
        ...state,
        liste: action.payload.events.map(item => {
          const evenements = state.evenements;
          const evenement = evenements.find(event => event.id === item.name);

          return {
            uniq_id: uuidv4(),
            id: item.id,
            priority: item.priority,
            pixel_custom_conversion: {
              name: item.source,
            },
            event_name: {
              id: item.name,
              name: evenement && evenement.name,
            },
          };
        }),
      };
    case "SET_EVENT_NAME_ITEM_LISTE_PRIORISATION_EVENEMENTS":
      let valuePixelCustomConversion = null;

      if (
        action.payload.value &&
        action.payload.value.hasOwnProperty("data_sources")
      ) {
        valuePixelCustomConversion = action.payload.value.data_sources[0];
      } else {
        valuePixelCustomConversion = { name: "all_pixels" };
      }

      return {
        ...state,
        liste: state.liste.map(item => {
          if (item.uniq_id === action.payload.id) {
            return {
              ...item,
              event_name: action.payload.value,
              pixel_custom_conversion: valuePixelCustomConversion,
            };
          } else return item;
        }),
      };
    case "SET_PIXEL_CUSTOM_CONVERSION_ITEM_LISTE_PRIORISATION_EVENEMENTS":
      return {
        ...state,
        liste: state.liste.map(item => {
          if (item.uniq_id === action.payload.id) {
            return {
              ...item,
              pixel_custom_conversion: action.payload.value,
            };
          } else return item;
        }),
      };
    case "CREATE_NEW_ELEMENT_PRIORISATION_EVENEMENTS":
      return {
        ...state,
        liste: [...state.liste, action.payload],
      };
    case "DELETE_ITEM_LISTE_PRIORISATION_EVENEMENTS":
      return {
        ...state,
        liste: state.liste.filter(item => item.uniq_id !== action.payload),
      };
    case "GET_EVENEMENTS_PRIORISATION_EVENEMENTS":
      return {
        ...state,
        evenements: action.payload,
      };
    default:
      return state;
  }
};
