import React from "react";
import { useDispatch, useSelector } from "react-redux";
import LeftMenu from "../../../components/LeftMenu/LeftMenu";
import Slider from "@mui/material/Slider";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import "./style.css";
import RightContainer from "../../../components/RightContainer/RightContainer";

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Films = () => [
  { label: "The Shawshank Redemption", year: 1994 },
  { label: "The Godfather", year: 1972 },
  { label: "The Godfather: Part II", year: 1974 },
  { label: "The Dark Knight", year: 2008 },
];

const ConfigurerStrategie2 = () => {
  const dispatch = useDispatch();
  const { toggleLeftMenu } = useSelector(state => state.utils);

  const setToggleLeftMenu = () => {
    dispatch({ type: "SET_TOGGLE_LEFT_MENU" });
  };

  const logoutFunc = () => {
    // logout({ returnTo: window.location.origin });
  };

  return (
    <div className='ecran_campagne_configurer_strategie_2'>
      <LeftMenu />
      <RightContainer>
        <header className='right_content_header'>
          <div className='back_title'>
            {toggleLeftMenu ? (
              <i
                onClick={() => setToggleLeftMenu(prevState => !prevState)}
                style={{ marginRight: 30, cursor: "pointer" }}
                className='fas fa-bars fa-2x'
              ></i>
            ) : null}
            {/* <div className='back'>
              <Link to='/creation-campagne'>
                <img src='/left-arrow 2.png' alt='Retour' />
              </Link>
            </div> */}
            <h1 className='right_content_header_title'>
              Configurez votre stratégie
            </h1>
          </div>
          <div style={{ display: "flex" }}>
            <img src='/meta.png' alt='meta' style={{ marginRight: 30 }} />
            <div className='client' onClick={logoutFunc}>
              <img src='/logo-laduree.png' alt='client' />
              <h2 className='client_title'>Ladurée</h2>
              <img src='/verified.png' alt='' />
            </div>
          </div>
        </header>
        <div className='right_content_main'>
          <div className='steps'>
            <div className='step '>
              <p>Paramètres</p>
            </div>
            <img src='/fleche-step.png' alt='Next Step' />
            <div className='step '>
              <p>Ajout d'un compte</p>
            </div>
            <img src='/fleche-step.png' alt='Next Step' />
            <div className='step '>
              <p>Choisir un compte</p>
            </div>
            <img src='/fleche-step.png' alt='Next Step' />
            <div className='step active'>
              <p>Configuration</p>
            </div>
          </div>
          <div className='configurer_strategie'>
            <div className='configurer_strategie_content'>
              <div className='evenements_a_optimiser'>
                <h2 className='evenements_a_optimiser_title'>
                  Evènements à Optimiser
                </h2>
                <div className='evenements_a_optimiser_content'>
                  <div className='events'>
                    <div className='details'>
                      <div className='searh_add'>
                        <div className='left'>
                          <i className='fas fa-search'></i>
                          <div className='autocomplete'>
                            <Autocomplete
                              disablePortal
                              id='combo-box-demo'
                              options={top100Films()}
                              sx={{ width: 300 }}
                              renderInput={params => <TextField {...params} />}
                            />
                          </div>
                        </div>
                        <div className='right'>
                          <button>Ajouter +</button>
                        </div>
                      </div>
                      <table>
                        <thead>
                          <tr>
                            <th>Nom</th>
                            <th style={{ textAlign: "center" }}>Poids</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <span className='circle'></span> View Content
                            </td>
                            <td className='color_green'>
                              <Slider
                                defaultValue={30}
                                aria-label='range slider'
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span className='circle'></span> Add to cart
                            </td>
                            <td className='color_green'>
                              <Slider
                                defaultValue={60}
                                aria-label='range slider'
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span className='circle'></span> Initiate Checkout
                            </td>
                            <td className='color_green'>
                              <Slider
                                defaultValue={50}
                                aria-label='range slider'
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span className='circle'></span> Purchase
                            </td>
                            <td className='color_green'>
                              <Slider
                                defaultValue={80}
                                aria-label='range slider'
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className='choix_strategie'>
                    <span className='round'>?</span>
                    <h4 className='choix_strategie_title'>
                      Choix de votre stratégie
                    </h4>
                    <p>
                      Les poids définis pour les évènements du tunnel permettent
                      au moteur d’optimiser plusieurs objectifs en même temps
                    </p>
                  </div>
                </div>
              </div>
              <div className='performance_objectif_conversion'>
                <h3 className='performance_objectif_conversion_title'>
                  Performance par objectif de conversion
                </h3>
                <div className='performances_historique'>
                  <div className='performances'>
                    <table>
                      <thead>
                        <tr>
                          <th>Nom</th>
                          <th>Clic</th>
                          <th>Visit</th>
                          <th>Achat</th>
                          <th>
                            <i className='fas fa-redo-alt'></i>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span className='circle'></span> View Content
                          </td>
                          <td className='color_green'>350</td>
                          <td>300</td>
                          <td>12</td>
                        </tr>
                        <tr>
                          <td>
                            <span className='circle'></span> Add to cart
                          </td>
                          <td className='color_green'>433</td>
                          <td>150</td>
                          <td>20</td>
                        </tr>
                        <tr>
                          <td>
                            <span className='circle'></span> Initiate Checkout
                          </td>
                          <td className='color_green'>535</td>
                          <td>127</td>
                          <td>34</td>
                        </tr>
                        <tr>
                          <td>
                            <span className='circle'></span> Purchase
                          </td>
                          <td className='color_green'>450</td>
                          <td>203</td>
                          <td>55</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className='historique'>
                    <span className='round'>?</span>
                    <h4 className='historique_title'>
                      Historique de performance
                    </h4>
                    <p>
                      En fonction de l’objectif principal de vos campagnes,la
                      performance peut varier significativement Chosissez
                      l’objectif qui correspond à vos enjeux actuels
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </RightContainer>
    </div>
  );
};

export default ConfigurerStrategie2;
