import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import LeftMenu from "../../../components/LeftMenu/LeftMenu";
import { toast } from "react-toastify";
import { estObjetVide, importFBLogin } from "../../../utils";
import { LOGIN } from "../../../store/types";
import Loader from "../../../components/Loader/Loader";
import axiosInstance from "../../../store/axios.instance";
import { useAuth0 } from "@auth0/auth0-react";
import "./style.css";
import axios from "axios";
import { FormattedMessage, useIntl } from "react-intl";
import TopRightMenu from "../../../components/TopRightMenu/TopRightMenu";
import RightContainer from "../../../components/RightContainer/RightContainer";
import PopupSessionExpired from "../../../components/PopupSessionExpired/PopupSessionExpired";

const AjouterCompte = () => {
  const dispatch = useDispatch();
  const { toggleLeftMenu } = useSelector((state) => state.utils);
  const [socialNetworkChoosen, setSocialNetworkChoosen] = useState("");
  // eslint-disable-next-line
  const [isFBConnected, setIsFBConnected] = useState(false);
  // eslint-disable-next-line
  const [loader, setLoader] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [loader3, setLoader3] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const action = searchParams.get("action");
  const reconnectMeta = searchParams.get("reconnect");

  const { showPopupSessionExpired } = useSelector((state) => state.utils);

  const {
    user: { sub: userId },
  } = useAuth0();

  const setToggleLeftMenu = () => {
    dispatch({ type: "SET_TOGGLE_LEFT_MENU" });
  };

  const setSelectedSocialNetwork = (social) => {
    setSocialNetworkChoosen(social);
    // Get ad accounts
    if (social && social === "meta") {
      checkIfUserHasData(userId);
      localStorage.setItem("selectedOption", "meta");
    } else {
      handleTiktokLogin();
      localStorage.setItem("selectedOption", "tiktok");
    }
  };

  const navigate = useNavigate();

  // get sync with the user FB status : connected or not ?
  // const handleFBStatusChange = FBAuthObject => {
  //   console.log("statusChange Event happened", FBAuthObject);
  // };

  const creerNouvelUtilisateurSensego = async (facebook_token) => {
    const res = await axiosInstance.put(
      `/connected_client?facebook_token=${facebook_token}&dev=${
        process.env.NODE_ENV === "development" ? true : false
      }`
    );
    // const res = await axiosInstance.put(
    //   `/connected_client?facebook_token=${facebook_token}&dev=true`
    // );

    dispatch({ type: "STORE_USER_DATA_AUTH", payload: res.data });
    localStorage.setItem("userData", JSON.stringify(res.data));

    // Store adaccounts
    dispatch({
      type: "STORE_ADS_ACCOUNTS",
      payload: res.data.adaccounts,
    });

    localStorage.setItem("adaccounts", JSON.stringify(res.data.adaccounts));

    return res.data;
  };

  const getTokenAuth0AndStoreDataInAuth0 = async (data, id) => {
    const result = await axios.post(
      `https://sensego.eu.auth0.com/oauth/token`,
      {
        client_id: process.env.REACT_APP_AUTH0_CLIENT_ID2,
        client_secret: process.env.REACT_APP_AUTH0_CLIENT_SECRET,
        audience: "https://sensego.eu.auth0.com/api/v2/",
        grant_type: "client_credentials",
      }
    );

    const accessToken = result.data.access_token;

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };

    const response = await fetch(
      `https://sensego.eu.auth0.com/api/v2/users/${id}`,
      {
        method: "PATCH",
        headers,
        body: JSON.stringify({ user_metadata: data }),
      }
    );

    if (!response.ok) {
      throw new Error(`Error setting user metadata: ${response.statusText}`);
    }
  };

  // const logout = () => {
  //   console.log("logout");
  //   window.FB.getLoginStatus(function(response) {
  //     if (response.status === 'connected') {
  //       console.log('User is logged in and has a valid access token')
  //      // Call your logout function here
  //     } else {
  //       console.log('User is not logged in')
  //       // Handle the case accordingly
  //     }
  //   });
  //   // window.FB.logout(function (response) {
  //   //   if (response.error) {
  //   //     console.log("Error logging out:", response.error);
  //   //   } else {
  //   //     console.log("Facebook logout response:", response);
  //   //   }
  //   // });
  // };

  const handleFBlogin = () => {
    const getBusinesses = () => {
      return window.FB.api(
        "me/businesses?fields=owned_product_catalogs,client_product_catalogs",
        "GET",
        (res) => {
          dispatch({
            type: "SET_BUSINESSES_AFTER_FB_LOGIN",
            payload: res.data,
          });
        }
      );
    };
    
    const fbApiCallGetAccounts = () => {
      return window.FB.api(
        "/me/accounts?limit=1000&fields=global_brand_page_name,access_token,id",
        "GET",
        async (res) => {
          dispatch({ type: "SET_ACCOUNTS_AFTER_FB_LOGIN", payload: res.data });
          localStorage.setItem("facebookPages", JSON.stringify(res.data));

          const accounts = res.data;
          let instagramAccounts = [];
          await Promise.all(
            // eslint-disable-next-line
            accounts.map((acc) => {
              window.FB.api(
                `/${acc.id}/?fields=connected_instagram_account,instagram_accounts&access_token=${acc.access_token}`,
                "GET",
                (res) => {
                  instagramAccounts.push(res);
                }
              );
            })
          );

          dispatch({
            type: "SET_INSTAGRAM_ACCOUNTS_AFTER_FB_LOGIN",
            payload: instagramAccounts,
          });

          localStorage.setItem(
            "instagramPages",
            JSON.stringify(instagramAccounts)
          );
        }
      );
    };
    // First declare the API Call
    const FBApiCall = () => {
      let responses = [];
      let adaccounts = [];

      const FBReceive = (response) => {
        if (response && !response.errors) {
          if (response.data) {
            responses.push(response);
            adaccounts = [...adaccounts, ...response.data];

            if (response.hasOwnProperty("paging") && response.paging.next) {
              window.FB.api(
                response.paging.next, // get the next page
                "GET",
                FBReceive
              );
            } else {
              responses[0].data = adaccounts;

              if (adaccounts) {
                dispatch({
                  type: "STORE_ADS_ACCOUNTS",
                  payload: responses[0].data,
                });

                localStorage.setItem("adaccounts", JSON.stringify(adaccounts));

                dispatch({ type: LOGIN });
              }
            }
          }
        }
      };

      return window.FB.api(
        "/me/adaccounts",
        "GET",
        {
          fields: "name,email,picture,adaccounts{name,created_time}",
        },
        FBReceive
      );
    };
    // Now execute this FBApiCall :
    // if user is not connected, wrap it inside FB.login call
    // if (!isFBConnected)

    // else FBApiCall();

    window.FB.login(
      async (response) => {
        FBApiCall();
        //fbApiCallGetAccounts();
        //getBusinesses();

        setLoader2(true);

        // console.log("response.authResponse.accessToken", response.authResponse.accessToken);

        setTimeout(async () => {
          const datatoStoreInAuth0 = await creerNouvelUtilisateurSensego(
            response.authResponse.accessToken
          );

          await getTokenAuth0AndStoreDataInAuth0(datatoStoreInAuth0, userId);

          dispatch({
            type: "SET_FB_AUTH_OBJECT_CONNECTED_USER",
            payload: response.authResponse,
          });

          setIsFBConnected(true);

          setLoader2(false);

          navigate("/campagne/comptes-actifs");
        }, 500);
      },
      {
        scope:
          "public_profile,email,ads_read,ads_read,ads_management,pages_read_engagement,pages_show_list,business_management,catalog_management,read_insights,instagram_manage_insights,pages_read_user_content,instagram_basic",
        enable_profile_selector: reconnectMeta ? "true" : "false",
        auth_type: "rerequest",
      }
    );
  };

  const setCookie = (name, value, days) => {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  };

  const nextStep = () => {
    if (!socialNetworkChoosen) {
    } else {
      // setLoader(true);

      // Get ad accounts
      if (socialNetworkChoosen && socialNetworkChoosen === "meta") {
        handleFBlogin();
      } else {
        handleTiktokLogin();
      }
    }
  };

  const connectExistedUser = async (id, facebookToken) => {
    try {
      await axiosInstance.post(
        `/connected_client?facebook_token=${facebookToken}`
      );
      const res = await axiosInstance.get(
        `/connected_client/${id}?facebook_token=${facebookToken}`
      );

      if (res.data) {
        dispatch({ type: "STORE_USER_DATA_AUTH", payload: res.data });

        localStorage.setItem("userData", JSON.stringify(res.data));

        // Store adaccounts
        dispatch({
          type: "STORE_ADS_ACCOUNTS",
          payload: res.data.adaccounts,
        });

        localStorage.setItem("adaccounts", JSON.stringify(res.data.adaccounts));

        setTimeout(() => {
          setLoader3(false);
          // navigate("/campagne/comptes-actifs");
          navigate("/campagne/liste");
        }, 500);
      } else {
        setLoader3(false);
      }
    } catch (error) {
      if (error.response.status === 498) {
        // Session expired
        // Cacher le loader
        setLoader3(false);

        // dispatch({type:"HIDE_POPUP_SESSION_EXPIRED"})
        // navigate("/campagne/ajouter-compte?action=meta")
        dispatch({ type: "SHOW_POPUP_SESSION_EXPIRED" });
      }
    }
  };

  function isEmptyObject(obj) {
    return Object.keys(obj).length === 0;
  }

  const checkIfUserHasData = async (id) => {
    setLoader3(true);

    const result1 = await axios.post(
      `https://sensego.eu.auth0.com/oauth/token`,
      {
        client_id: process.env.REACT_APP_AUTH0_CLIENT_ID2,
        client_secret: process.env.REACT_APP_AUTH0_CLIENT_SECRET,
        audience: "https://sensego.eu.auth0.com/api/v2/",
        grant_type: "client_credentials",
      }
    );

    const accessToken = result1.data.access_token;

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };

    let response = await fetch(
      `https://sensego.eu.auth0.com/api/v2/users/${id}`,
      {
        method: "GET",
        headers,
      }
    );

    response = await response.json();

    if (response.user_metadata) {
      const userMetaData = response.user_metadata;

      if (!estObjetVide(userMetaData)) {
        // Get access token of connected client
        axiosInstance
          .get(`/connected_client/${userMetaData.id}`)
          .then((result) => {
            if (result.data) {
              let token = "";
              // if (
              //   result.data.hasOwnProperty("token") &&
              //   result.data.hasOwnProperty("dev_token")
              // ) {
              //   if (process.env.NODE_ENV === "development") {
              //     token = result.data.dev_token;
              //   } else {
              //     token = result.data.token;
              //   }
              // } else if (
              //   !result.data.hasOwnProperty("token") &&
              //   result.data.hasOwnProperty("dev_token")
              // ) {
              //   token = result.data.dev_token;
              // } else if (
              //   result.data.hasOwnProperty("token") &&
              //   !result.data.hasOwnProperty("dev_token")
              // ) {
              //   token = result.data.token;
              // }

              if (result.data.hasOwnProperty("token")) {
                token = result.data.token;
              }
              connectExistedUser(userMetaData.id, token);
            } else {
              console.log(" Redemander la connexion");
              // Redemander la connexion
              // Si des données utilisateurs sont stockées sur Auth0 mais que la requete GET /connected_client/{user_id} ne renvoie rien → redemander la connexion facebook
              window.FB.login(
                async (response) => {
                  try {
                    const accessToken = response.authResponse.accessToken;

                    const resCheckAccessToken = await axiosInstance.post(
                      `/connected_client?facebook_token=${accessToken}`
                    );

                    // &dev=${
                    // process.env.NODE_ENV === "development" ? true : false
                    // }

                    if (resCheckAccessToken.data) {
                      dispatch({
                        type: "STORE_USER_DATA_AUTH",
                        payload: resCheckAccessToken.data,
                      });

                      localStorage.setItem(
                        "userData",
                        JSON.stringify(resCheckAccessToken.data)
                      );

                      // Store adaccounts
                      dispatch({
                        type: "STORE_ADS_ACCOUNTS",
                        payload: resCheckAccessToken.data.adaccounts,
                      });

                      localStorage.setItem(
                        "adaccounts",
                        JSON.stringify(resCheckAccessToken.data.adaccounts)
                      );

                      const result = await axios.post(
                        `https://sensego.eu.auth0.com/oauth/token`,
                        {
                          client_id: process.env.REACT_APP_AUTH0_CLIENT_ID2,
                          client_secret:
                            process.env.REACT_APP_AUTH0_CLIENT_SECRET,
                          audience: "https://sensego.eu.auth0.com/api/v2/",
                          grant_type: "client_credentials",
                        }
                      );

                      const accessToken = result.data.access_token;

                      const headers = {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                      };

                      await fetch(
                        `https://sensego.eu.auth0.com/api/v2/users/${userId}`,
                        {
                          method: "PATCH",
                          headers,
                          body: JSON.stringify({
                            user_metadata: resCheckAccessToken.data,
                          }),
                        }
                      );

                      setTimeout(() => {
                        setLoader3(false);
                        navigate("/campagne/comptes-actifs");
                      }, 500);
                    }
                  } catch (error) {
                    setLoader3(false);
                    console.log(error);
                  }
                },
                {
                  scope:
                    "public_profile,email,ads_read,ads_read,ads_management,pages_read_engagement,pages_show_list,business_management,catalog_management,read_insights,instagram_manage_insights,pages_read_user_content,instagram_basic",
                  enable_profile_selector: "true",
                  auth_type: "rerequest",
                }
              );
            }
          })
          .catch((err) => {
            setLoader3(false);
            console.log(err);
          });
      } else {
        // Si des données utilisateurs sont stockées sur Auth0 mais que la requete GET /connected_client/{user_id} ne renvoie rien → redemander la connexion facebook
        window.FB.login(
          async (response) => {
            try {
              const accessToken = response.authResponse.accessToken;

              const resCheckAccessToken = await axiosInstance.post(
                `/connected_client?facebook_token=${accessToken}`
              );

              // &dev=${
              // process.env.NODE_ENV === "development" ? true : false
              // }

              if (resCheckAccessToken.data) {
                dispatch({
                  type: "STORE_USER_DATA_AUTH",
                  payload: resCheckAccessToken.data,
                });

                localStorage.setItem(
                  "userData",
                  JSON.stringify(resCheckAccessToken.data)
                );

                // Store adaccounts
                dispatch({
                  type: "STORE_ADS_ACCOUNTS",
                  payload: resCheckAccessToken.data.adaccounts,
                });

                localStorage.setItem(
                  "adaccounts",
                  JSON.stringify(resCheckAccessToken.data.adaccounts)
                );

                const result = await axios.post(
                  `https://sensego.eu.auth0.com/oauth/token`,
                  {
                    client_id: process.env.REACT_APP_AUTH0_CLIENT_ID2,
                    client_secret: process.env.REACT_APP_AUTH0_CLIENT_SECRET,
                    audience: "https://sensego.eu.auth0.com/api/v2/",
                    grant_type: "client_credentials",
                  }
                );

                const accessToken = result.data.access_token;

                const headers = {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${accessToken}`,
                };

                await fetch(
                  `https://sensego.eu.auth0.com/api/v2/users/${userId}`,
                  {
                    method: "PATCH",
                    headers,
                    body: JSON.stringify({
                      user_metadata: resCheckAccessToken.data,
                    }),
                  }
                );

                setTimeout(() => {
                  setLoader3(false);
                  navigate("/campagne/comptes-actifs");
                }, 500);
              }
            } catch (error) {
              setLoader3(false);
              console.log(error);
            }
          },
          {
            scope:
              "public_profile,email,ads_read,ads_read,ads_management,pages_read_engagement,pages_show_list,business_management,catalog_management,read_insights,instagram_manage_insights,pages_read_user_content,instagram_basic",
            enable_profile_selector: "true",
            auth_type: "rerequest",
          }
        );
        // setLoader3(false);
      }
    } else {
      nextStep();
      setLoader3(false);
    }
  };

  const handleTiktokLogin = async () => {
    const csrfState = Math.random().toString(36).substring(2);
    setCookie("csrfState", csrfState, 1);
    window.open(
      `https://ads.tiktok.com/marketing_api/auth?app_id=7200592454066634753&redirect_uri=https%3A%2F%2Fmmaga-staging.herokuapp.com%2Fcampagne%2Fajouter-compte&rid=oz4xeljfbs`
    );
  };
  // "https://open-api.tiktok.com/oauth/access_token?client_key=7200592454066634753&client_secret=e009c5b1537b0ed94555933fcbf5500a064b646f&code=97bdd95664586b5be02a1224c44c068bd6b5e10e&grant_type=authorization_code"
  const getTiktokAccessToken = async (auth_code, code) => {
    if (auth_code && code) {
      const options = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          secret: process.env.REACT_APP_TIKTOK_CLIENT_SECRET,
          app_id: process.env.REACT_APP_TIKTOK_CLIENT_ID,
          auth_code: code,
        }),
      };

      fetch("/open_api/v1.3/oauth2/access_token/", options)
        .then((response) => response.json())
        .then((response) => {
          dispatch({ type: "SAVE_TIKTOK_AUTH_DATA", payload: response.data });
          localStorage.setItem("tiktokUserData", JSON.stringify(response.data));
        })
        .catch((err) =>
          toast.error("Une erreur est survenue veuillez réessayer!")
        );
    }
  };

  const checkIfUserData = async (id) => {
    setLoader3(true);

    const result1 = await axios.post(
      `https://sensego.eu.auth0.com/oauth/token`,
      {
        client_id: process.env.REACT_APP_AUTH0_CLIENT_ID2,
        client_secret: process.env.REACT_APP_AUTH0_CLIENT_SECRET,
        audience: "https://sensego.eu.auth0.com/api/v2/",
        grant_type: "client_credentials",
      }
    );

    const accessToken = result1.data.access_token;

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };

    let response = await fetch(
      `https://sensego.eu.auth0.com/api/v2/users/${id}`,
      {
        method: "GET",
        headers,
      }
    );

    response = await response.json();

    if (response.user_metadata) {
      const userMetaData = response.user_metadata;

      if (!estObjetVide(userMetaData)) {
        // Get access token of connected client
        axiosInstance
          .get(`/connected_client/${userMetaData.id}`)
          .then((result) => {
            if (result.data) {
              let token = "";

              if (result.data.hasOwnProperty("token")) {
                token = result.data.token;
              }
              connectExistedUser(userMetaData.id, token);
            } else {
              setLoader3(false);
            }
          })
          .catch((err) => {
            setLoader3(false);
            console.log(err);
          });
      } else {
        setLoader3(false);
      }
    } else {
      setLoader3(false);
    }
  };

  useEffect(() => {
    checkIfUserData(userId);
  }, []);

  useEffect(() => {
    importFBLogin();
    if (socialNetworkChoosen && socialNetworkChoosen === "meta") {
      checkIfUserHasData(userId);
    }
    if (socialNetworkChoosen && socialNetworkChoosen === "tiktok") {
      handleTiktokLogin();
    }
    // checkIfUserHasData(userId);
  }, [socialNetworkChoosen]);

  const intl = useIntl();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params) {
      const auth_code = params.get("auth_code");
      const code = params.get("code");
      setTimeout(() => {
        getTiktokAccessToken(auth_code, code);
      }, 500);
      // console.log("auth_code", auth_code);
      // console.log("code", code);
    }
  }, []);

  return (
    <div className="ecran_campagne_ajouter_compte">
      {showPopupSessionExpired && <PopupSessionExpired />}
      {loader && (
        <Loader
          message={intl.formatMessage({
            defaultMessage: "Chargement des comptes ads en cours...",
            id: "loader_message_1",
          })}
        />
      )}
      {loader2 && (
        <Loader
          message={intl.formatMessage({
            defaultMessage: "Création du nouvel utilisateur en cours...",
            id: "loader_message_2",
          })}
        />
      )}
      {loader3 && (
        <Loader
          message={intl.formatMessage({
            defaultMessage: "Vérification en cours...",
            id: "loader_message_3",
          })}
        />
      )}
      {/* {loader3 && <Loader message="Connexion de l'utilisateur" />} */}
      <LeftMenu />
      <RightContainer>
        <header className="right_content_header">
          <div className="back_title" style={{ flex: 1 }}>
            <h1 className="right_content_header_title">
              <FormattedMessage
                defaultMessage="Ajouter un compte"
                id="add-account-title"
              />
            </h1>
          </div>
          {/* <TopRightMenu /> */}
        </header>
        <div className="right_content_main">
          <div className="steps">
            <div className="step ">
              <p>
                <FormattedMessage
                  defaultMessage="Paramètres"
                  id="add-account-step-params"
                />
              </p>
            </div>
            <img src="/fleche-step.png" alt="Next Step" />
            <div className="step active">
              <p>
                <FormattedMessage
                  defaultMessage="Ajout d'un compte"
                  id="add-account-step-add-account"
                />
              </p>
            </div>
            <img src="/fleche-step.png" alt="Next Step" />
            <div className="step">
              <p>
                <FormattedMessage
                  defaultMessage="Choisir un compte"
                  id="add-account-choose-account"
                />
              </p>
            </div>
            <img src="/fleche-step.png" alt="Next Step" />
            <div className="step">
              <p>
                <FormattedMessage
                  defaultMessage="Configuration"
                  id="add-account-configuration"
                />
              </p>
            </div>
          </div>
          <div className="choix_reseau_social">
            <div className="choix_reseau_social_content">
              <h3>
                <FormattedMessage
                  defaultMessage="Sélectionner un réseau social"
                  id="add-account-select-social-network"
                />
              </h3>
              <div className="meta_tiktok">
                <img
                  className={`${
                    (socialNetworkChoosen === "meta" && "selected") ||
                    (action === "meta" && "selectedMeta")
                  }`}
                  onClick={() => setSelectedSocialNetwork("meta")}
                  src="/meta.png"
                  alt="Meta"
                />
                <img
                  className={`${
                    socialNetworkChoosen === "tiktok" && "selected"
                  }`}
                  onClick={() => setSelectedSocialNetwork("tiktok")}
                  src="/tiktok.png"
                  alt="Tiktok"
                />
              </div>
              {/*<div className="navigation">
                <button
                  className="outline"
                  onClick={() => navigate("/campagne/parametres")}
                >
                  <FormattedMessage
                    defaultMessage="Précédent"
                    id="add-account-previous"
                  />
                </button>
                <button onClick={nextStep}>
                  <FormattedMessage
                    defaultMessage="Suivant"
                    id="add-account-next"
                  />
                </button>
                </div>*/}
            </div>
          </div>
        </div>
      </RightContainer>
    </div>
  );
};

export default AjouterCompte;
