import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loader3 from "../../../../components/Loader3/Loader3";
import axiosInstance from "../../../../store/axios.instance";
import { autoExpand, isValidUrl } from "../../../../utils";
import LinkItem from "./LinkItem";
import Switch from "@mui/material/Switch";
import SearchAsset from "../../../../components/SearchAsset/SearchAsset";

const Lien = ({ getLiens, loaderLiens }) => {
  const [toggle, setToggle] = useState(false);
  const [newText, setNewText] = useState("");
  const [showTags, setShowTags] = useState(false);
  // eslint-disable-next-line
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();

  const handleNewTextChange = e => {
    setNewText(e.target.value);
  };

  const saveNewText = e => {
    if (e.key === "Enter" && e.shiftKey) {
      e.preventDefault();
      setNewText(e.target.value + "\n");
    }
  };

  const saveNewTextOnClick = e => {
    if (!newText) {
      toast.error("Veuillez insérer une valeur non vide");
      return;
    }

    if (newText.includes("facebook.com")) {
      dispatch({
        type: "SET_ALERT_GENERAL_ERROR_MESSAGE",
        payload: "Cette valeur est interdite !",
      });
      dispatch({ type: "SHOW_ALERT_GENERAL_ERROR_MESSAGE" });
    } else {
      if (isValidUrl(newText)) {
        const newLink = {
          content: newText,
          keywords: [],
          monitoring_tags: null,
        };

        axiosInstance
          .put(`/${adaccountId}/link`, newLink)
          .then(result => {
            dispatch({
              type: "CREATE_LINK_CREATION_PACKAGE_PUBLICTE_SUCCESS",
              payload: result.data.data,
            });

            toast.success("Lien crée avec succès");

            setLoader(false);
          })
          .catch(err => {
            console.log(err);
            toast.error("y a eu une erreur lors de la création du lien");
            setLoader(false);
          });

        setNewText("");
      } else {
        dispatch({
          type: "SET_ALERT_GENERAL_ERROR_MESSAGE",
          payload:
            "Le lien n'est pas au bon format. Voila un exemple valide: http://example.com",
        });
        dispatch({ type: "SHOW_ALERT_GENERAL_ERROR_MESSAGE" });
      }
    }
  };

  const {
    liens,
    originalData: { liens: liensOriginaux },
  } = useSelector(state => state.creationPackagePublicite);

  const { adaccountId } = useSelector(state => state.auth.user);

  const selectedLiens = liensOriginaux.filter(item => item && item.isSelected);
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    if (!searchParams.get("keep-data")) {
      getLiens(adaccountId);
    }
  }, []);

  useEffect(() => {
    if (toggle && selectedLiens.length > 0) {
      dispatch({ type: "SHOW_SELECTED_LIENS_FIRST" });
    }
  }, [toggle]);

  const searchLiens = e => {
    setSearchValue(e.target.value);

    dispatch({ type: "SEARCH_LINKS", payload: e.target.value });
  };

  return (
    <section className='section section_texte'>
      <header
        onClick={() => setToggle(prevState => !prevState)}
        className='section_header'
      >
        <div className='df'>
          <h3 className='section_header_title'>
            <FormattedMessage
              defaultMessage='Lien'
              id='creation_package_pub_section_lien_1'
            />

            {selectedLiens.length > 0 && (
              <span className='nbr'>{selectedLiens.length}</span>
            )}
          </h3>
        </div>
        <i className={`fas fa-chevron-${toggle ? "up" : "down"}`}></i>
      </header>
      {toggle && (
        <div className='section_content'>
          <SearchAsset value={searchValue} onChange={searchLiens} />
          <div className='wrapper'>
            <div className='add_text'>
              <textarea
                onInput={autoExpand}
                value={newText}
                onKeyDown={saveNewText}
                onChange={handleNewTextChange}
                placeholder='Ajouter un lien'
              ></textarea>
              <i onClick={saveNewTextOnClick} className='fas fa-plus'></i>
            </div>
            <div className='tags'>
              <i className='fas fa-info-circle'></i>
              <p>Tags</p>
              <Switch
                onChange={() => setShowTags(prevState => !prevState)}
                checked={showTags}
              />
            </div>
          </div>
          <div className='list_bodies'>
            {loaderLiens ? (
              <Loader3 />
            ) : liens && liens.length > 0 ? (
              liens.map((texte, i) => (
                <LinkItem
                  key={i}
                  texte={texte}
                  getLiens={getLiens}
                  showTags={showTags}
                />
              ))
            ) : (
              <h6>
                <FormattedMessage
                  defaultMessage="Aucun lien pour l'instant"
                  id='creation_package_pub_section_lien_2'
                />
              </h6>
            )}
          </div>
        </div>
      )}
    </section>
  );
};

export default Lien;
