import React from 'react';
import StepsBase from './StepsBase';

const StepsMonitoring = ({activeStep}) => {
  
  const steps = [
    // {
    //   id: 'parametres',
    //   labelId: 'add-account-title',
    //   defaultMessage: 'Paramètres',
    //   path: '/campagne/comptes-actifs',
    // },
    {
      id: 'priorisation',
      labelId: 'screen_priorisation_events_8',
      defaultMessage: 'Priorisation des évènements',
      path: '/priorisation-evenements',
    },
    {
      id: 'strategies',
      labelId: 'step_strategies',
      defaultMessage: 'Stratégies',
      path: '/configuration-monitoring',
    },
    {
        id:"branchement",
        labelId:"branchement_des_strategies_aze345",
        defaultMessage: "Branchement des stratégies",
        path:"/branchement-monitoring-campagne"
    }
  ];

  return (
    <StepsBase
        steps={steps}
        activeStep={activeStep}
    />
  );
};

export default StepsMonitoring;