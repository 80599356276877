import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LeftMenu from "../../../components/LeftMenu/LeftMenu";
import Loader from "../../../components/Loader3/Loader3";
import "./style.css";
import InsightsItem from "../../../components/InsightsItemListeCampagne/InsightsItemListeCampagne";
import FilterInsights from "../../../components/FilterInsights/FilterInsights";
import ModalFilterInsightsByDate from "../../../components/ModalFilterInsightsByDate/ModalFilterInsightsByDate";
import { FormattedMessage } from "react-intl";
import SingleCampaignConfiguration from "../../../components/SingleCampaignConfiguration/SingleCampaignConfiguration";
import RightContainer from "../../../components/RightContainer/RightContainer";
import axiosInstance from "../../../store/axios.instance";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { getEvenements } from "../../../store/actions/listeCampagnes";
import SortColumn from "../../../components/SortColumn/SortColumn";
import useDraggableScroll from "use-draggable-scroll";
import AlertesListeCampagnes from "../../../components/AlertesListeCampagnes/AlertesListeCampagnes";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { toast } from "react-toastify";
import { getConnectedAccounts } from "../../../store/actions/utils/campaignCreation";

const Listecampagnes = () => {

  const [showMetaSection, setShowMetaSection] = useState(true);
  const [userMetaData, setUserMetaData] = useState(null);
  const [userSavedEvents, setUserSavedEvents] = useState([]);
  const [token, setToken] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    user: { sub: userId },
  } = useAuth0();
  const { toggleLeftMenu } = useSelector((state) => state.utils);

  const {
    userData: { id: user_id },
  } = useSelector((state) => state.auth.user);

  const setToggleLeftMenu = () => {
    dispatch({ type: "SET_TOGGLE_LEFT_MENU" });
  };


  const navigateToCreateCampaignScreen = () => {
    dispatch({ type: "CLEAR_ALL_INPUTS_CREATION_CAMPAIGN" });
    navigate("/creation-campagne");
  };

  const navigateToViewCampaignDetailsScreen = (id) => {
    navigate("/campagne/details/" + id);
  };

  const baseNavigateToEditCampaign = (id) => {
    dispatch({ type: "CLEAR_ALL_INPUTS_CREATION_CAMPAIGN" });
    dispatch({
      type: "SET_FILE_ID",
      payload: id,
    });
    navigate(
      `/campagne/edit/${encodeURIComponent(
        id
      )}`
    );
  }

  const navigateToEditPubScreen = async (file_id, facebook_campaign_id) => {
    if (!facebook_campaign_id){
      toast.error(
        `Impossible de mettre à jour les publicités 
         si la campagne n'existe pas sur Meta`
        )
    }
    else{
      dispatch({ type: "CLEAR_ALL_INPUTS_CREATION_CAMPAIGN" });
      dispatch({
        type: "SET_FILE_ID",
        payload: file_id,
      });
      navigate(
        `/campagne/update-pub/${encodeURIComponent(file_id)}`
      );
    }
  };


  const { adaccountName, adaccountId } = useSelector(
    (state) => state.auth.user
  );
  const {
    filteredinsights: insights,
    loader,
    singleCampaignConfigurations,
    events,
  } = useSelector((state) => state.insights);
  const { showModalFilterInsightsByDate, showCoutsColonnesDynamiques } =
    useSelector((state) => state.utils);

  const getInsights = (ad_acc) => {
    // setLoader(true);
    dispatch({ type: "SET_LOADER_INSIGHTS" });

    axiosInstance
      .get(`/${ad_acc}/precomputed_insights?level=campaign`)
      // .get(`/all_accounts/insights?user_id=${user_id}`)
      .then((res) => {
        // Get insights liste campagne
        dispatch({ type: "GET_INSIGHTS", payload: res.data });
        // dispatch({ type: "ATTACH_INSIGHTS_TO_CONFIGURATION" });
        // Get insights details campagne
        // dispatch({
        //   type: "GET_INSIGHTS_DETAIL_CAMPAGNE",
        //   payload: res.data,
        // });
        // setLoader(false);

        // Persist to localStorage
        // localStorage.setItem("insights", JSON.stringify(res.data));
      })
      .catch((err) => console.log(err));
  };

  const getSingleCampaignConfigurations = (ad_acc) => {
    axiosInstance
      .get(
        `/${ad_acc}/single_campaign_configuration?fields=id&fields=name&fields=data&fields=author&fields=draft&fields=facebook_campaign_id`
      )
      .then((res) => {
        dispatch({
          type: "GET_SINGLE_CAMPAIGN_CONFIGURATIONS",
          payload: res.data,
        });

        dispatch({ type: "UNSET_LOADER_INSIGHTS" });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: "SET_ALERT_GENERAL_ERROR_MESSAGE",
          payload: err.message,
        });
        dispatch({ type: "SHOW_ALERT_GENERAL_ERROR_MESSAGE" });
      });
  };

  useEffect(() => {
    setTimeout(() => {
      insights.map((insight) => {
        const matchingItem = singleCampaignConfigurations.find(
          (item) => item.facebook_campaign_id === insight.campaign_id
        );
        // matchingItem && matchingItem.draft
        if (matchingItem) {
          insight["singleCampaignConfigurationId"] = matchingItem.id;
          insight["draft"] = matchingItem.draft;
          dispatch({
            type: "FELTER_SINGLE_CAMPAIGN_CONFIGURATION",
            payload: matchingItem,
          });
        }
      });
    }, 1000);
  }, [insights, singleCampaignConfigurations]);

  useEffect(() => {
    if (adaccountId) {
      getInsights(adaccountId);
      getSingleCampaignConfigurations(adaccountId);
    } else {
      axiosInstance
        .get(`/intersect_client/${user_id}`)
        .then((res) => {
          let intersect_clients = res.data.map((item) => {
            item.id = item.account;
            delete item.account;

            return item;
          });

          intersect_clients = intersect_clients.sort(function (a, b) {
            var nameA = a.name.toUpperCase(); // ignore la casse
            var nameB = b.name.toUpperCase(); // ignore la casse
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // les noms sont égaux
            return 0;
          });

          if (intersect_clients.length > 0) {
            const firstAccountId = intersect_clients[0].id;
            const firstAccountName = intersect_clients[0].name;
            getInsights(firstAccountId);
            getSingleCampaignConfigurations(firstAccountId);

            dispatch({
              type: "SAVE_NAME_COMPTE_PRINCIPAL",
              payload: firstAccountName,
            });

            dispatch({
              type: "SAVE_ID_COMPTE_PRINCIPAL",
              payload: firstAccountId,
            });
          }
        })
        .catch((err) => console.log(err));
    }
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const lang = navigator.language.split(/[-_]/)[0];
  const uppercasedLang = lang.toUpperCase();

  useEffect(() => {
    if (adaccountId) dispatch(getEvenements(adaccountId, uppercasedLang));
  }, []);

  const selectEvent = (id) => {
    dispatch({ type: "TOGGLE_EVENT_LISTE_CAMPAGNE", payload: id });
    const e = events.filter((ev) => ev.uniq_id === id);
    // console.log("e", e);
    setTimeout(() => {
      saveSelectedEvents(e);
    }, 1000);
  };

  const selectedEvents = events.filter((event) => event && event.isChecked);

  const afficherLesCoutsColonnesDynamiques = (e) => {
    dispatch({
      type: "SET_COUTS_COLONNES_DYNAMIQUES",
      payload: !showCoutsColonnesDynamiques,
    });
  };

  // When scrolling down, scroll table to right
  const dataElement = useRef();
  // const dataElementCurrent = dataElement.current;
  // if (dataElementCurrent) {
  //   const slider = dataElementCurrent;

  //   slider.addEventListener("mousedown", e => {
  //     setIsDown(true);
  //     slider.classList.add("active");
  //     setStartX(e.pageX - slider.offsetLeft);
  //     setScrollLeft(slider.scrollLeft);
  //   });
  //   slider.addEventListener("mouseleave", () => {
  //     setIsDown(false);
  //     slider.classList.remove("active");
  //   });
  //   slider.addEventListener("mouseup", () => {
  //     setIsDown(false);
  //     slider.classList.remove("active");
  //   });
  //   slider.addEventListener("mousemove", e => {
  //     if (!isDown) return;
  //     e.preventDefault();
  //     const x = e.pageX - slider.offsetLeft;
  //     const walk = (x - startX) * 3; //scroll-fast
  //     slider.scrollLeft = scrollLeft - walk;
  //     console.log(walk);
  //     setIsDown(false);
  //   });
  // }
  // const { openModel } = useSelector((state) => state.creationCampagne);
  const { onMouseDown } = useDraggableScroll(dataElement);

  const getUserMetaData = async () => {
    const result = await axios.post(
      `https://sensego.eu.auth0.com/oauth/token`,
      {
        client_id: process.env.REACT_APP_AUTH0_CLIENT_ID2,
        client_secret: process.env.REACT_APP_AUTH0_CLIENT_SECRET,
        audience: "https://sensego.eu.auth0.com/api/v2/",
        grant_type: "client_credentials",
      }
    );

    const accessToken = result.data.access_token;
    setToken(accessToken);

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };

    let userData = await fetch(
      `https://sensego.eu.auth0.com/api/v2/users/${userId}`,
      {
        method: "GET",
        headers,
        // body: JSON.stringify({ user_metadata: data }),
      }
    );

    userData = await userData.json();

    // console.log(userData);
    if (
      userData &&
      userData.user_metadata &&
      userData.user_metadata.selectedEvents
    ) {
      setUserSavedEvents(userData.user_metadata.selectedEvents);
    }
  };

  useEffect(() => {
    dispatch({
      type: "TOGGLE_EVENT_LISTE_CAMPAGNE_METADATA",
      payload: {
        userSavedEvents: userSavedEvents,
        events: events,
      },
    });
  }, [userSavedEvents]);

  useEffect(() => {
    getUserMetaData();
    getConnectedAccounts(dispatch, user_id)
  }, []);

  const saveSelectedEvents = async (e) => {
    const result = await axios.post(
      `https://sensego.eu.auth0.com/oauth/token`,
      {
        client_id: process.env.REACT_APP_AUTH0_CLIENT_ID2,
        client_secret: process.env.REACT_APP_AUTH0_CLIENT_SECRET,
        audience: "https://sensego.eu.auth0.com/api/v2/",
        grant_type: "client_credentials",
      }
    );

    const accessToken = result.data.access_token;
    setToken(accessToken);

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };

    let response = await fetch(
      `https://sensego.eu.auth0.com/api/v2/users/${userId}`,
      {
        method: "GET",
        headers,
        // body: JSON.stringify({ user_metadata: data }),
      }
    );

    response = await response.json();

    response.user_metadata.selectedEvents = events.filter(
      (e) => e.isChecked === true
    );
    if (!e[0].isChecked) {
      response.user_metadata.selectedEvents.push({ ...e[0], isChecked: true });
    } else {
      const filteredSelectedEvents =
        response.user_metadata.selectedEvents.filter(
          (event) => event.id !== e[0].id
        );
      response.user_metadata.selectedEvents = filteredSelectedEvents;
    }
    // return;
    let res = await fetch(
      `https://sensego.eu.auth0.com/api/v2/users/${userId}`,
      {
        method: "PATCH",
        headers,
        body: JSON.stringify({ user_metadata: response.user_metadata }),
      }
    );

    //  response.user_metadata

    if (!res.ok) {
      throw new Error(`Error setting user metadata: ${response.statusText}`);
    }

    res = await res.json();
  };

  return (
    <div className="ecran_liste_campagnes">
      <LeftMenu />
      <RightContainer>
        <header className="right_content_header">
          <div className="back_title">
            {toggleLeftMenu ? (
              <i
                onClick={() => setToggleLeftMenu((prevState) => !prevState)}
                style={{ marginRight: 30, cursor: "pointer" }}
                className="fas fa-bars fa-2x"
              ></i>
            ) : null}
            <h1 className="right_content_header_title">
              <FormattedMessage
                defaultMessage="Liste des campagnes"
                id="screen-liste-campagne-campaign-list"
              />
            </h1>
          </div>
          <div className="create_campaign" style={{ display: "flex" }}>
            <button onClick={navigateToCreateCampaignScreen}>
              <FormattedMessage
                defaultMessage="Créer une campagne"
                id="screen-liste-campagne-create-campaign"
              />
            </button>
          </div>
        </header>
        <div className="right_content_main">
          <FilterInsights />
          <div className="data" onMouseDown={onMouseDown} ref={dataElement}>
            {loader ? (
              <Loader />
            ) : (
              <table>
                <thead>
                  <tr>
                    <th
                      className="first_status"
                      style={{ border: "none" }}
                    ></th>
                    <th className="first name_campaign">
                      <FormattedMessage
                        defaultMessage="Campagne"
                        id="screen-liste-campagne-campagne"
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        defaultMessage="Status"
                        id="screen-liste-campagne-status"
                      />
                    </th>
                    <th>
                      <div className="df">
                        <FormattedMessage
                          defaultMessage="Dépenses"
                          id="screen-liste-campagne-depenses"
                        />
                        <SortColumn column="spend" />
                      </div>
                    </th>
                    <th>
                      <div className="df">
                        <FormattedMessage
                          defaultMessage="Impressions"
                          id="screen-liste-campagne-impressions"
                        />
                        <SortColumn column="impressions" />
                      </div>
                    </th>
                    {/* <th></th> */}
                    {/* <th>ROAS</th> */}
                    {selectedEvents &&
                      selectedEvents.length > 0 &&
                      selectedEvents.map((event, i) => (
                        <th key={i}>
                          <div className="df">
                            {event.name}
                            <SortColumn column={event.id} />
                          </div>
                        </th>
                      ))}
                    <th className="last">
                      {events.length > 0 && (
                        <div>
                          <div className="dynamic_columns">
                            <img
                              style={{ cursor: "pointer" }}
                              onClick={handleClick}
                              src="/add_columns.png"
                              alt=""
                            />
                            <Menu
                              id="basic-menu"
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleClose}
                              MenuListProps={{
                                "aria-labelledby": "basic-button",
                              }}
                            >
                              {events.map((event) => (
                                <MenuItem
                                  key={event.uniq_id}
                                  onClick={() => {
                                    selectEvent(event.uniq_id);
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    checked={event.isChecked}
                                  />
                                  &nbsp; {event.name}
                                </MenuItem>
                              ))}
                            </Menu>
                          </div>
                          <div className="couts">
                            <p>Afficher les coûts</p>
                            <input
                              checked={showCoutsColonnesDynamiques}
                              type="checkbox"
                              onChange={afficherLesCoutsColonnesDynamiques}
                            />
                          </div>
                        </div>
                      )}
                    </th>
                  </tr>
                </thead>
                <thead>
                  <tr
                    className="meta"
                    onClick={() =>
                      setShowMetaSection((prevState) => !prevState)
                    }
                  >
                    <td colSpan={parseInt(selectedEvents.length + 8)}>
                      <header>
                        <div className="left">
                          <img src="/icons/meta_campagne.png" alt="" />
                          {adaccountName && (
                            <h5 className="title">{adaccountName}</h5>
                          )}
                        </div>
                        <div className="right">
                          <i
                            className={`fas fa-chevron-${
                              showMetaSection ? "up" : "down"
                            }`}
                          ></i>
                        </div>
                      </header>
                    </td>
                  </tr>
                </thead>
                {showMetaSection && (
                  <React.Fragment>
                    <tbody>
                      {insights &&
                        insights.length > 0 &&
                        insights.map((item, i) => (
                          <InsightsItem
                            navigateToViewCampaignDetailsScreen={
                              navigateToViewCampaignDetailsScreen
                            }
                            baseNavigateToEditCampaign={
                              baseNavigateToEditCampaign
                            }
                            insight={item}
                            getSingleCampaignConfigurations={
                              getSingleCampaignConfigurations
                            }
                            navigateToEditPubScreen={navigateToEditPubScreen}
                          />
                        ))}

                      {singleCampaignConfigurations &&
                        singleCampaignConfigurations.length > 0 &&
                        singleCampaignConfigurations.map((item, i) => (
                          <SingleCampaignConfiguration
                            campaign={item}
                            baseNavigateToEditCampaign={
                              baseNavigateToEditCampaign
                            }
                            getInsights={getInsights}
                            getSingleCampaignConfigurations={
                              getSingleCampaignConfigurations
                            }
                            navigateToEditPubScreen={navigateToEditPubScreen}
                          />
                        ))}
                    </tbody>
                  </React.Fragment>
                )}
              </table>
            )}
          </div>
          <AlertesListeCampagnes />
        </div>
        {showModalFilterInsightsByDate && <ModalFilterInsightsByDate />}
      </RightContainer>
    </div>
  );
};

export default Listecampagnes;
