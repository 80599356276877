import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import RenderTagOption from "../../../components/RenderTagOption/RenderTagOption";
import ChoicePageFacebookAndPageInstagram from "../../../components/ChoicePageFacebookAndPageInstagram/ChoicePageFacebookAndPageInstagram";

import GenericSection from "./genericSection"
import { FormattedMessage, useIntl } from "react-intl";
import { saveCampaignNextPage } from "../../../store/actions/utils/campaignCreation";

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

const PackagePublicite = ({ disabledMode }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const packages = useSelector(state => state.creationCampagne.keywords);
  const tags = useSelector(state => state.creationCampagneRef.tags);

  const [toggle, setToggle] = React.useState(false);
 
  const handleChangeTag = (event, newValue) => {
    //diff newValue.diff(packages)
    let packagesSet = new Set(packages)
    let newPackages = newValue.filter(package_crea=>!packagesSet.has(package_crea))
    newPackages.forEach(package_crea=>dispatch({type:"ADD_PACKAGE_PUB", payload:package_crea}))
    //diff packages.diff(newValue)
    packagesSet = new Set(newValue)
    newPackages = packages.filter(package_crea=>!packagesSet.has(package_crea))
    newPackages.forEach(package_crea=>dispatch({type:"REMOVE_PACKAGE_PUB", payload:package_crea}))
  };
  
  const intl = useIntl();

  return (
    <GenericSection
      disabledMode={disabledMode}
      toggle={toggle}
      setToggle={setToggle}
      title={
        <FormattedMessage
          defaultMessage='Package de publicité'
          id='create_campaign_package_pub_title'
        />
      }
      test_validation={packages.length > 0}
      content={
        <form action=''>
          <ChoicePageFacebookAndPageInstagram />
          <div style={{ display: "flex", alignItems: "center" }}>
            <i
              style={{ marginRight: 5, color: "#E83E8C" }}
              className='fas fa-search'
            ></i>
            <Autocomplete
              multiple
              value={packages}
              id='checkboxes-tags-demo'
              onChange={handleChangeTag}
              //onBlur={() => updateSingleCompaign()}
              options={tags}
              disableCloseOnSelect
              getOptionLabel={option => option}
              renderTags={options =>
                options.map(option => <RenderTagOption option={option} />)
              }
              renderOption={(props, option, { selected }) => (
                <li {...props} style={{ flex: 1 }}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </li>
              )}
              style={{ width: 500 }}
              renderInput={params => (
                <TextField
                  {...params}
                  label={intl.formatMessage({
                    id: "creation_campagne_package_publicite_search_package",
                    defaultMessage: "Chercher un package de publicité",
                  })}
                />
              )}
            />
          </div>
          <div className='add' style={{ marginTop: 0 }}>
            <div className='fe'>
              <button onClick={() => {
                dispatch({type:"SET_STATE_PROTECT_DETAILLED_ADSETS",payload:true});
                saveCampaignNextPage(dispatch, location);
                navigate(`/creation-package-pub`);
                }}>
                <i className='fas fa-plus'></i>
                <FormattedMessage
                  defaultMessage='Ajouter'
                  id='creation_campagne_package_add_btn'
                />
              </button>
            </div>
          </div>
        </form>
      }
    />
  );
};

export default PackagePublicite;
