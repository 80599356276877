const initialState = {
  alerts: [],
  topTroisimagesReports: [],
  topTroisaudiencesReports: [],
  topTroisinterestsReports: [],
  topThreeScoredImagesReports: [],
  weakThreeScoredImagesReports: [],
  loader: false,
};

export const rapportStatistiquesDetaillleesReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "CLEAR_REPORTS_STATS_DETAILLEES":
      return {
        ...state,
        alerts: [],
        topTroisimagesReports: [],
        topTroisaudiencesReports: [],
        topTroisinterestsReports: [],
        topThreeScoredImagesReports: [],
        weakThreeScoredImagesReports: [],
      };
    case "UNSET_LOADER_REPORTS_STATS_DETAILLEES":
      return {
        ...state,
        loader: false,
      };
    case "SET_LOADER_REPORTS_STATS_DETAILLEES":
      return {
        ...state,
        loader: true,
      };
    case "SET_REPORTS_STATS_DETAILLEES":
      const {
        topTroisimagesReports,
        topTroisaudiencesReports,
        topTroisinterestsReports,
        topThreeScoredImagesReports,
        weakThreeScoredImagesReports,
      } = action.payload;

      return {
        ...state,
        topTroisimagesReports,
        topTroisaudiencesReports,
        topTroisinterestsReports,
        topThreeScoredImagesReports,
        weakThreeScoredImagesReports,
      };
    case "SET_REPORTS_STATS_DETAILLEES_ALERTS":
      const { alerts } = action.payload;

      return {
        ...state,
        alerts,
      };
    default:
      return state;
  }
};
