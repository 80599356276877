import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { estObjetVide } from "../../utils";
import AssociatedAssetsCmp from "../AssociatedAssetsCmp/AssociatedAssetsCmp";

const ImageItem = ({ image, showTags }) => {
  const dispatch = useDispatch();

  const { visuels, contenuParDefaut } = useSelector(state => state.carousel);

  const selectImage = id => {
    if (visuels.length < 10) {
      if (!image.isSelected) {
        dispatch({ type: "ADD_VISUEL_CAROUSEL", payload: image, "contenuParDefaut": contenuParDefaut });
      } else {
        dispatch({ type: "DELETE_VISUEL_CAROUSEL", payload: id });
      }

      dispatch({
        type: "TOGGLE_SELECTED_IMAGE_CAROUSEL",
        payload: id,
      });

      dispatch({ type: "SHOW_SELECTED_IMAGES_FIRST_CAROUSEL" });
    } else {
      dispatch({
        type: "SET_ALERT_GENERAL_ERROR_MESSAGE",
        payload: "Vous avez atteint le nombre max de visuels permis !",
      });
      dispatch({ type: "SHOW_ALERT_GENERAL_ERROR_MESSAGE" });
    }
  };

  return (
    <div
      onClick={() => selectImage(image.id)}
      className={`image_item ${image.isSelected && "selected"}`}
      style={showTags ? { width: "48%" } : {}}
    >
      <div className='left' style={!showTags ? { width: "100%" } : {}}>
        <div className='image_title'>
          <h4 className='title'>
            {image.name.length < 30
              ? image.name
              : `${image.name.slice(0, 30)}...`}
          </h4>
          {image.associated_assets && !estObjetVide(image.associated_assets) ? (
            <AssociatedAssetsCmp />
          ) : null}
        </div>
        <div className='image'>
          <img src={image.url} alt={image.name} />
        </div>
      </div>
    </div>
  );
};

export default ImageItem;
