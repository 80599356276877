import React from "react";



const GenericSection = (
    {
      disabledMode,
      toggle,//specific to the section
      setToggle,//same
      title,
      test_validation,
      content
    }) => (
      <section className="informations_generales section">
        <header
          onClick={() => {
            setToggle((prevState) => !prevState);
          }}
          className="section_header"
        >
          {disabledMode && <div className="mode_disabled"></div>}
          <div className="df">
            <h3 className="section_header_title">
              {title}
            </h3>
            {test_validation ? (
              <img src="/step_checked.png" alt="Etape vérifiée" />
            ) : null}
          </div>
          <i className={`fas fa-chevron-${!toggle ? "down" : "up"}`}></i>
        </header>
        {toggle && (
          <div className="informations_generales_content">
            {disabledMode && <div className="mode_disabled_no_cursor"></div>}
            {content}
          </div>)}
      </section>
    )

export default GenericSection;