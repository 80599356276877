import React, { useEffect, useState } from "react";
import "./style.css";
import LeftMenu from "../../components/LeftMenu/LeftMenu";
import TopRightMenu from "../../components/TopRightMenu/TopRightMenu";
import { FormattedMessage, useIntl } from "react-intl";
import RightContainer from "../../components/RightContainer/RightContainer";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ApercuSimple from "./Apercus/ApercuSimple";
import axios from "axios";
import Loader3 from "../../components/Loader3/Loader3";
import { toast } from "react-toastify";
import axiosInstance from "../../store/axios.instance";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const SelectionnerPublicitesExistantes = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loaderCampaigns, setLoaderCampaigns] = useState(false);
  const [loaderAdsets, setLoaderAdsets] = useState(false);
  const [loaderPublicites, setLoaderPublicites] = useState(false);

  const { toggleLeftMenu } = useSelector(state => state.utils);

  const getParams = new URLSearchParams(window.location.search);
  const compagnId = getParams.get("campaign_id");

  const setToggleLeftMenu = () => {
    dispatch({ type: "SET_TOGGLE_LEFT_MENU" });
  };

  const { userData } = useSelector(state => state.auth.user);
  const { adaccountId } = useSelector(state => state.auth.user);
  const { campagnes, adsets, publicites } = useSelector(
    state => state.publicitesExistantes
  );

  const getCampaigns = async adacc => {
    try {
      setLoaderCampaigns(true);

      const response = await axios.get(
        `${
          process.env.REACT_APP_GRAPH_API
        }/${adacc}/campaigns?fields=name&access_token=${
          userData.token
          // userData.dev_token
          // process.env.NODE_ENV === "development"
          //   ? userData.dev_token
          //   : userData.token
        }&limit=1000`
      );

      if (response.status === 200) {
        dispatch({
          type: "GET_CAMPAIGNS_PUBLICITES_EXISTANTES",
          payload: response.data.data.sort(function (a, b) {
            var nomA = a.name.toUpperCase(); // ignorer la casse
            var nomB = b.name.toUpperCase(); // ignorer la casse
            if (nomA < nomB) {
              return -1;
            }
            if (nomA > nomB) {
              return 1;
            }

            // les noms sont égaux
            return 0;
          }),
        });

        setTimeout(() => {
          setLoaderCampaigns(false);
        }, 500);
      }
    } catch (error) {
      console.log(error);

      setTimeout(() => {
        setLoaderCampaigns(false);
      }, 500);
    }
  };

  const getAdsets = async campaignId => {
    try {
      setLoaderAdsets(true);

      const response = await axios.get(
        `${
          process.env.REACT_APP_GRAPH_API
        }/${campaignId}/adsets?fields=id,name&access_token=${
          userData.token
          // userData.dev_token
          // process.env.NODE_ENV === "development"
          //   ? userData.dev_token
          //   : userData.token
        }&limit=1000`
      );

      if (response.status === 200) {
        dispatch({
          type: "GET_ADSETS_PUBLICITES_EXISTANTES",
          payload: response.data.data,
        });

        setTimeout(() => {
          setLoaderAdsets(false);
        }, 500);
      }
    } catch (error) {
      console.log(error);

      setTimeout(() => {
        setLoaderAdsets(false);
      }, 500);
    }
  };

  const getPublicites = async adsetId => {
    try {
      setLoaderPublicites(true);

      const response = await axios.get(
        `${
          process.env.REACT_APP_GRAPH_API
        }/${adsetId}/ads?fields=id,name,creative{id,body,image_url,object_url,thumbnail_url},preview_shareable_link&access_token=${
          userData.token
          // userData.dev_token
          // process.env.NODE_ENV === "development"
          //   ? userData.dev_token
          //   : userData.token
        }&limit=1000`
      );

      if (response.status === 200) {
        dispatch({
          type: "GET_ADS_PUBLICITES_EXISTANTES",
          payload: response.data.data,
        });

        setTimeout(() => {
          setLoaderPublicites(false);
        }, 500);
      }
    } catch (error) {
      console.log(error);

      setTimeout(() => {
        setLoaderPublicites(false);
      }, 500);
    }
  };

  const handleChangeCampaign = (e, newValue) => {
    dispatch({ type: "CLEAR_CAMPAIGNS_PUBLICITES_EXISTANTES" });

    if (newValue) {
      if (newValue.id) {
        const id = newValue.id;

        dispatch({
          type: "SET_SELECTED_CAMPAIGN_PUBLICITES_EXISTANTES",
          payload: id,
        });

        getAdsets(id);
      }
    }
  };

  const handleChangeAdset = (e, newValue) => {
    if (newValue) {
      if (newValue.id) {
        const id = newValue.id;

        dispatch({
          type: "SET_SELECTED_ADSET_PUBLICITES_EXISTANTES",
          payload: id,
        });

        getPublicites(id);
      }
    }
  };

  const selectedAds = publicites.filter(item => item && item.isSelected);

  const { nomPackage } = useSelector(state => state.creationPackagePublicite);

  const savePublicitesExistantes = () => {
    if (selectedAds.length === 0) {
      toast.error("Veuillez choisir au moins une publicité existante !");
      return;
    }

    if (!nomPackage) {
      toast.error("Veuillez d'abord choisir un nom de package !");
      return;
    }

    setLoaderPublicites(true);

    const adsToSend = selectedAds.map(ad => {
      delete ad.isSelected;

      ad["keywords"] = [nomPackage];

      return ad;
    });

    console.log(JSON.stringify(adsToSend, null, 1));

    Promise.all(
      adsToSend.map(async ad => {
        await axiosInstance.put(`/${adaccountId}/CreatedAds`, ad);
      })
    )
      .then(() => {
        setTimeout(() => {
          setLoaderPublicites(false);

          toast.success("Opération réussie !");

          compagnId
            ? navigate(
                `/creation-package-pub?keep-data=true&campaign_id=${compagnId}`
              )
            : navigate("/creation-package-pub?keep-data=true");

          dispatch({ type: "CLEAR_PUBLICITES_EXISTANTES" });
        }, 500);
      })
      .catch(err => console.log(err));

    // dispatch({
    //   type: "SAVE_PUBLICITES_EXISTANTES_CREATION_PACKAGE_PUB",
    //   payload: adsToSend,
    // });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    if (adaccountId) getCampaigns(adaccountId);
  }, []);

  const intl = useIntl();

  return (
    <div className='screen_selectionner_publicites_existantes'>
      <LeftMenu />
      <RightContainer>
        <header className='right_content_header'>
          <div className='back_title'>
            {toggleLeftMenu ? (
              <i
                onClick={() => setToggleLeftMenu(prevState => !prevState)}
                style={{ marginRight: 30, cursor: "pointer" }}
                className='fas fa-bars fa-2x'
              ></i>
            ) : null}
            <div className='back' onClick={() => navigate(-1)}>
              <img src='/left-arrow 2.png' alt='Retour' />
            </div>
            <h1 className='right_content_header_title'>
              <FormattedMessage
                defaultMessage='Créer une campagne'
                id='creation_carousel_screen_1'
              />
            </h1>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className='client'>
              <TopRightMenu />
            </div>
          </div>
        </header>
        <div className='right_content_main'>
          <div className='main_section'>
            <div className='boxed'>
              <header className='main__header'>
                <p>
                  <FormattedMessage
                    defaultMessage='Selectionner des publicités existantes'
                    id='selectionner_publicites_existantes_123mLk'
                  />
                </p>
                <i
                  className='fas fa-times'
                  onClick={() => {
                    compagnId
                      ? navigate(
                          `/creation-package-pub?keep-data=true&campaign_id=${compagnId}`
                        )
                      : navigate("/creation-package-pub?keep-data=true");
                    // navigate("/creation-package-pub?keep-data=true");
                  }}
                ></i>
              </header>
              <div className='main__content'>
                <div className='comptes_ads_adsets'>
                  <div className='comptes_ads'>
                    {loaderCampaigns ? (
                      <Loader3 />
                    ) : (
                      // <select
                      //   onChange={handleChangeCampaign}
                      //   value={selectedCampaign}
                      //   name=''
                      //   id=''
                      // >
                      //   <option value=''>
                      //     <FormattedMessage
                      //       defaultMessage='choisissez une campagne'
                      //       id='selectionner_publicites_existantes_gTu532'
                      //     />
                      //   </option>
                      //   {campagnes.map(({ id, name }) => (
                      //     <option value={id}>{name}</option>
                      //   ))}
                      // </select>
                      <Autocomplete
                        onChange={handleChangeCampaign}
                        disablePortal
                        id='combo-box-demo'
                        options={campagnes}
                        getOptionLabel={option => option.name}
                        sx={{ width: 300 }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            variant='standard'
                            placeholder={intl.formatMessage({
                              id: "selectionner_publicites_existantes_gTu532",
                              defaultMessage: "choisissez une campagne",
                            })}
                          />
                        )}
                      />
                    )}
                  </div>
                  <div className='adsets'>
                    {loaderAdsets ? (
                      <Loader3 />
                    ) : (
                      // <select
                      //   onChange={handleChangeAdset}
                      //   value={selectedAdset}
                      // >
                      //   <option value='choisissez un adset'>
                      //     <FormattedMessage
                      //       defaultMessage='choisissez un adset'
                      //       id='selectionner_publicites_existantes_84h6Fc'
                      //     />
                      //   </option>
                      //   {adsets.map(({ id, name }) => (
                      //     <option value={id}>{name}</option>
                      //   ))}
                      // </select>
                      <Autocomplete
                        onChange={handleChangeAdset}
                        disablePortal
                        id='combo-box-demo'
                        options={adsets}
                        getOptionLabel={option => option.name}
                        sx={{ width: 300 }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            variant='standard'
                            placeholder={intl.formatMessage({
                              id: "selectionner_publicites_existantes_84h6Fc",
                              defaultMessage: "choisissez un adset",
                            })}
                          />
                        )}
                      />
                    )}
                  </div>
                </div>
                {loaderPublicites ? (
                  <div style={{ marginTop: 50 }}>
                    <Loader3 />
                  </div>
                ) : publicites.length === 0 ? (
                  <div className='empty_result'>
                    <img src='/empty_pubs.png' alt='' />
                    <p>
                      <FormattedMessage
                        defaultMessage='Veuillez selectionner une campagne !'
                        id='selectionner_publicites_existantes_Bd2t69'
                      />
                    </p>
                  </div>
                ) : (
                  <div className='liste_pubs_existantes'>
                    {publicites.map(pub => (
                      <ApercuSimple key={pub.id} pub={pub} />
                    ))}
                    {/* <ApercuCarousel /> */}
                  </div>
                )}
                <div className='dfe'>
                  <button
                    onClick={savePublicitesExistantes}
                    className={`${selectedAds.length === 0 ? "disabled" : ""}`}
                    disabled={selectedAds.length === 0}
                  >
                    <FormattedMessage
                      defaultMessage='Enregistrer'
                      id='create_campaign_screen_save_button'
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </RightContainer>
    </div>
  );
};

export default SelectionnerPublicitesExistantes;
