import { getUniqueListBy } from "../../utils";

const initialState = {
  file_configuration_id: "",
  adSetDetaille: [],
  informationsGenerales: {
    nomCampagne: "",
    type: "",
    dateDebut: "",
    dateFin: "",
    budget_mode: "daily",
    budget: "",
    objectif: "",
    tag: "",
    pixel: "",
    application: "",
    countries: [],
    languesChoisies: [],
    product_catalog_id: "",
    attribution_spec: '',
    existing_customer_budget_percentage: "",
  },
  keywords: [],
  packagePubliciteDesactive: [],
  packagePubliciteMiseAJour: [],
  audiences_packages: [],
  excluded_audiences_packages: [],
  large: false,
  interests_packages: [],
  excluded_interests_packages: [],
  targeting_packages: [],
  manualmode:false,
  audiencesAndInterests: [],
  adset_budget_mode: "daily",
  protectedDetailledAdsets:false,
  adsetsCampaign: [],
  configuration: {
    placements: [],
    publisherPlateforms: [],
    useros: "",
    devices: [],
    wireless: "",
  },
  advantagePlus: false,
  campaign_id: "",
  facebookAccountChosen: null,
  instagramAccountChosen: null,
  loading_campaign:false,
  load_campaign:true,
  mandatory_fields:[
    "nomCampagne", "type", "facebookAccountChosen",
    "instagramAccountChosen", "keywords","targeting_packages"
  ]
};
let updatedDetailledAdsets = [];
let idsInList = [];

export const creationCampagneReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOADING_CAMPAIGN":
      return {
        ...state,
        loading_campaign:action.payload
      };
    case "CAMPAIGN_LOADED":
      return {
        ...state,
        load_campaign:false
      }
    case "CLEAR_ACCOUNTS_CHOSEN":
      return {
      ...state,
      facebookAccountChosen: null,
      instagramAccountChosen: null,
      };
    case "STORE_FACEBOOK_ACCOUNT_CHOSEN":
      return {
        ...state,
        facebookAccountChosen: action.payload,
      };
    case "STORE_INSTAGRAM_ACCOUNT_CHOSEN":
      return {
        ...state,
        instagramAccountChosen: action.payload,
      };
    case "DELETE_GEODEMO_PACKAGE_CREATION_CAMP":
      return {
        ...state,
        targeting_packages: state.targeting_packages.filter(
          (item) => item.id !== action.payload
        ),
      };
    case "REMOVE_PACKAGE_PUB":
      updatedDetailledAdsets = state.audiencesAndInterests.slice()
      updatedDetailledAdsets.forEach((adset) => {
          adset.packagesPub = adset.packagesPub.filter((item)=>(item!==action.payload));
      });
      return {
        ...state,
        keywords: state.keywords.filter(
          (item) => item !== action.payload
        ),
        audiencesAndInterests: updatedDetailledAdsets,
      };
    case "REMOVE_PACKAGE_PUB_UPDATE":
      const packageToRemove = action.payload;
      if (! state.keywords.find((package_pub) => package_pub === packageToRemove)){
        updatedDetailledAdsets = state.audiencesAndInterests.slice()
        updatedDetailledAdsets.forEach((adset) => {
          adset.packagesPub = adset.packagesPub.filter((item)=>(item!==action.payload));
        });

      return {
          ...state,
          audiencesAndInterests: updatedDetailledAdsets,
          packagePubliciteMiseAJour: state.packagePubliciteMiseAJour.filter(
            (item) => item !== packageToRemove) 
        };
      }
      return {
        ...state,
        packagePubliciteMiseAJour: state.packagePubliciteMiseAJour.filter(
          (item) => item !== packageToRemove)
      };
    case "REMOVE_PACKAGE_PUB_DEACTIVATE":
      return {
        ...state,
        packagePubliciteDesactive: state.packagePubliciteDesactive.filter(
          (item) => item !== action.payload)
        }  
    case "SET_PIXEL_VAL":
      return {
        ...state,
        informationsGenerales: {
          ...state.informationsGenerales,
          pixel: action.payload,
        },
      };
    case "CLEAR_ALL_INPUTS_CREATION_CAMPAIGN":
      return initialState
    case "SET_PACKAGE_GEO_DEMO"://feels pretty weird
      return {
        ...state,
        targeting_packages: Array.isArray(action.payload)
          ? getUniqueListBy([...state.targeting_packages, ...action.payload], "id")
          : [...state.targeting_packages, action.payload],
      };
    case "SET_PACKAGE_GEO_DEMO_WITH_PROTECTION":
      return {
        ...state,
        targeting_packages: action.payload,
        protectedDetailledAdsets: true
      };
    case "ADD_PACKAGE_INTEREST":
      return {
        ...state,
        interests_packages: [...state.interests_packages, action.payload],
        excluded_interests_packages: state.excluded_interests_packages.filter(
          (item)=>item.id!==action.payload.id)
      };
    case "ADD_PACKAGE_INTEREST_EXCLUSION":
      updatedDetailledAdsets = state.audiencesAndInterests.slice()
      updatedDetailledAdsets.forEach((adset) => {
        if (! adset.exclusionInterests.find((item)=> (item.id===action.payload.id))){
          adset.exclusionInterests.push(action.payload);
        }
      });
      return {
          ...state,
          interests_packages: state.interests_packages.filter(
            (item)=>item.id!==action.payload.id),
          excluded_interests_packages: [
              ...state.excluded_interests_packages, action.payload, ],
          audiencesAndInterests: updatedDetailledAdsets
        };
    case "ADD_PACKAGE_AUDIENCE":
      return {
        ...state,
        audiences_packages: [...state.audiences_packages, action.payload],
        excluded_audiences_packages: state.excluded_audiences_packages.filter(
          item => item.id !== action.payload.id)
      };
    case "ADD_PACKAGE_AUDIENCE_EXCLUSION":
      updatedDetailledAdsets = state.audiencesAndInterests.slice();
      updatedDetailledAdsets.forEach((adset) => {
        if (! adset.exclusion_audiences.find((item)=> (item.id===action.payload.id))){
          adset.exclusion_audiences.push(action.payload);
        }
      });
      return {
          ...state,
          excluded_audiences_packages: [...state.excluded_audiences_packages, action.payload],
          audiences_packages: state.audiences_packages.filter(
            item => item.id !== action.payload.id),
          audiencesAndInterests: updatedDetailledAdsets,
        };
    case "ADD_PACKAGE_PUB":
      const existingPackage = state.keywords.find(
        (item) => item === action.payload
      );
      if (!existingPackage) {
        updatedDetailledAdsets = state.audiencesAndInterests.slice()
        updatedDetailledAdsets.forEach((adset) => {
            adset.packagesPub.push(action.payload);
          }
        );
        return {
          ...state,
          keywords: [...state.keywords, action.payload],
          audiencesAndInterests: updatedDetailledAdsets
        };
      }
      return state; // If the package already exists, return the current state
      
    case "ADD_PACKAGE_PUB_UPDATE":
      const existingPackagePub = state.packagePubliciteMiseAJour.find(
        (item) => item === action.payload
      );
      if (!existingPackagePub) {   
        updatedDetailledAdsets = state.audiencesAndInterests.slice();
        if (! state.keywords.find((package_pub) => package_pub === action.payload)){
          updatedDetailledAdsets.forEach((adset) => {
            adset.packagesPub.push(action.payload);
          });
        }  
        return {
          ...state,
          packagePubliciteMiseAJour: [...state.packagePubliciteMiseAJour, action.payload],
          packagePubliciteDesactive: state.packagePubliciteDesactive.filter(
            packagePub => packagePub !== action.payload),
          audiencesAndInterests: updatedDetailledAdsets
        };
      }
      return state;
    case "SET_BUDGET_CAMPAGNE_ZERO":
      return {
        ...state,
        informationsGenerales: {
          ...state.informationsGenerales,
          budget:"",
        },
      };
    case "HANDLE_CHANGE_INFO_GENERALE":
      if (action.payload.name === "pixel"){
      }
      return {
        ...state,
        informationsGenerales: {
          ...state.informationsGenerales,
          [action.payload.name]: action.payload.value,
        },
      };
    case "HANDLE_CHANGE_INFO_COUNTRIES":
      return {
        ...state,
        informationsGenerales: {
          ...state.informationsGenerales,
          countries: action.payload,
        },
      };
    case "HANDLE_CHANGE_INFO_LANGUAGE":
      return {
        ...state,
        informationsGenerales: {
          ...state.informationsGenerales,
          languesChoisies: action.payload,
        },
      };
    case "STORE_PUB_PACKAGES":
      return {
        ...state,
        keywords:action.payload,
      };
    case "HANDLE_CHANGE_PACKAGE_PUB_MISE_A_JOUR":
      const packagePubliciteMiseAJour1 = action.payload;
      const packagePubliciteDesactive1 = state.packagePubliciteDesactive.filter(
        (item) => !packagePubliciteMiseAJour1.includes(item)
      );

      return {
        ...state,
        packagePubliciteMiseAJour: packagePubliciteMiseAJour1,
        packagePubliciteDesactive: packagePubliciteDesactive1,
      };
    case "HANDLE_CHANGE_PACKAGE_PUB_DESACTIVE":
      const packagePubliciteDesactive2 = action.payload;
      const packagePubliciteMiseAJour2 = state.packagePubliciteMiseAJour.filter(
        (item) => !packagePubliciteDesactive2.includes(item)
      );
      return {
        ...state,
        packagePubliciteMiseAJour: packagePubliciteMiseAJour2,
        packagePubliciteDesactive: packagePubliciteDesactive2,
      };
    case "HANDLE_CHANGE_AUDIENCE":
      idsInList = action.payload.map(item => item.id)
      return {
        ...state,
        audiences_packages: action.payload,
        excluded_audiences_packages: state.excluded_audiences_packages.filter(
          item => !idsInList.includes(item.id)
        )
      };
    case "HANDLE_CHANGE_AUDIENCE_WITH_PROTECTION":
      idsInList = action.payload.map(item => item.id)
      return {
        ...state,
        protectedDetailledAdsets: true,
        audiences_packages: action.payload,
        excluded_audiences_packages: state.excluded_audiences_packages.filter(
          item => !idsInList.includes(item.id)
        )
      };
    case "HANDLE_CHANGE_CENTRE_INTERET":
      idsInList = action.payload.map(item => item.id)
      return {
        ...state,
        interests_packages: action.payload,
        excluded_interests_packages: state.excluded_interests_packages.filter(
          item => !idsInList.includes(item.id)
        )
      };
    case "HANDLE_CHANGE_CENTRE_INTERET_WITH_PROTECTION":
      idsInList = action.payload.map(item => item.id)
      return {
        ...state,
        protectedDetailledAdsets: true,
        interests_packages: action.payload,
        excluded_interests_packages: state.excluded_interests_packages.filter(
          item => !idsInList.includes(item.id)
        )
      };
    case "HANDLE_CHANGE_EXCLUSION_AUDIENCE":
      return {
        ...state,
        excluded_audiences_packages: action.payload,
      };
    case "HANDLE_CHANGE_EXCLUSION_CENTRE_INTERET":
      return {
        ...state,
        excluded_interests_packages: action.payload
        };
    case "HANDLE_CHANGE_CIBLAGE_LARGE":
      return {
        ...state,
        large: action.payload,
      };
    case "HANDLE_CHANGE_CONFIGURATION":
      return {
        ...state,
        configuration: {
          ...state.configuration,
          [action.payload.name]: action.payload.value,
        },
      };
    case "SET_FILE_ID":
      return {
        ...state,
        file_configuration_id: action.payload,
      };
    case "SET_CAMPAIGN_ID":
      return {
        ...state,
        campaign_id: action.payload,
      };
    case "CHANGE_AUDIENCE_NAME":
      //to be remove and need to update infos instead
      const auds = [...state.audiences_packages];

      const updatedAudiences = auds.map((aud) => {
        if (aud.id === action.payload.id) {
          return { ...aud, name: action.payload.name };
        }
        return aud;
      });

      return {
        ...state,
        audiences_packages: updatedAudiences,
      };
    case "CHANGE_INTERET_NAME":
      //to be remove and need to update infos instead
      const updatedinterests = state.interests_packages.map((intr) => {
        if (intr.id === action.payload.id) {
          return { ...intr, name: action.payload.name };
        }
        return intr;
      });

      return {
        ...state,
        interests_packages: updatedinterests,
      };
    case "CHANGE_GEO_DEMO_NAME":
      //to be remove and need to update infos instead
      const geoDemo = [...state.targeting_packages];

      const updatedGeoDemo = geoDemo.map((gd) => {
        if (gd.id === action.payload.id) {
          return { ...gd, name: action.payload.name };
        }
        return gd;
      });

      return {
        ...state,
        targeting_packages: updatedGeoDemo,
      };
    case "ENABLE_ADVANTAGE_PLUS":
      return {
        ...state,
        advantagePlus: true,
        mandatory_fields:[
          "nomCampagne", "type", "facebookAccountChosen", 
          "instagramAccountChosen", "keywords", "countries"
        ]
      };
    case "DISABLE_ADVANTAGE_PLUS":
      return {
        ...state,
        advantagePlus: false,
        mandatory_fields:[
          "nomCampagne", "type", "facebookAccountChosen", 
          "instagramAccountChosen", "keywords", "targeting_packages"
        ]
      };
    case "CLEAR_DATA_CAMPAGNE_ADVANTAGE":
      return {
        ...state,
        informationsGenerales: {
          ...state.informationsGenerales,
          existing_customer_budget_percentage: 20,
        },
        adSetDetaille: [],
        audiences_packages: [],
        excluded_audiences_packages: [],
        interests_packages: [],
        excluded_interests_packages: [],
        large: true,
        targeting_packages: []
      };
    case "REMOVE_START_DATE":
      return {
        ...state,
        informationsGenerales: {
          ...state.informationsGenerales,
          dateDebut: "",
        },
      };
    case "REMOVE_END_DATE":
      return {
        ...state,
        informationsGenerales: {
          ...state.informationsGenerales,
          dateFin: "",
        },
      };

    case "SET_DAILY_OR_LIFETIME_BUDGET_ADSETS":
      return {
        ...state,
        adset_budget_mode:action.payload
      }
    case "SET_BUDGET_ADSETS_ZERO":
      return {
        ...state,
        audiencesAndInterests: state.audiencesAndInterests.map((item) => {
          return {
            ...item,
            budget: "",
          };
        }),
      };
    case "REMOVE_PACKAGE_INTEREST_EXCLUSION":
      updatedDetailledAdsets = state.audiencesAndInterests.slice()
      updatedDetailledAdsets.forEach((adset) => {
          adset.exclusionInterests = adset.exclusionInterests.filter((item)=>(item.id!==action.payload.id));
      });
      return {
          ...state,
          audiencesAndInterests: updatedDetailledAdsets,
          excluded_interests_packages: state.excluded_interests_packages.filter(
            item => item.id!==action.payload.id
          )
        };
      
    case "REMOVE_PACKAGE_AUDIENCE_EXCLUSION":
      updatedDetailledAdsets = state.audiencesAndInterests.slice()
      updatedDetailledAdsets.forEach((adset) => {
          adset.exclusion_audiences = adset.exclusion_audiences.filter(
            (item)=>(item.id!==action.payload.id));
      });
      return {
          ...state,
          audiencesAndInterests: updatedDetailledAdsets,
          excluded_audiences_packages: state.excluded_audiences_packages.filter(
            item => item.id!==action.payload.id
          )
        };
    case "SET_AUDIENCES_AND_INTERESTS":
      return {
        ...state,
        audiencesAndInterests: action.payload,
      };
    case "SET_STATE_PROTECT_DETAILLED_ADSETS":
      return {
        ...state,
        protectedDetailledAdsets: action.payload,
      };
    case "REMOVE_LINE_ADSETS_DETAILLE":
      state.audiencesAndInterests.splice(action.payload, 1);
      return {
        ...state,
      };
    case "SET_PACKAGES_PUB_ADSETS_DETAILLE":
      updatedDetailledAdsets = [...state.audiencesAndInterests];
      updatedDetailledAdsets[action.payload.index].packagesPub = action.payload.packagesPub
      return {
        ...state,
        audiencesAndInterests: updatedDetailledAdsets,
      };
    case "REMOVE_PACKAGE_PUB_ADSETS_DETAILLE":
      updatedDetailledAdsets = [...state.audiencesAndInterests];
      const index = action.payload.index;
      const name_p = action.payload.name;
      updatedDetailledAdsets[index].packagesPub = updatedDetailledAdsets[index].packagesPub.filter(
        (item) => item !== name_p
      );
      return {
        ...state,
        audiencesAndInterests: updatedDetailledAdsets,
      };
    case "SET_EXCLUSIONS_ADSETS_DETAILLE":
      updatedDetailledAdsets = [...state.audiencesAndInterests];
      const exclusionsIds = action.payload.exclusions.map(exclusion=>exclusion.id) 
      updatedDetailledAdsets[action.payload.index].exclusion_audiences = state.excluded_audiences_packages.filter(
        (item) => exclusionsIds.includes(item.id)
      );
      updatedDetailledAdsets[action.payload.index].exclusionInterests = state.excluded_interests_packages.filter(
        (item) => exclusionsIds.includes(item.id)
      );
      return {
        ...state,
        audiencesAndInterests: updatedDetailledAdsets,
      };

    case "REMOVE_EXCLUSION_AUDI_ADSETS_DETAILLE":
      updatedDetailledAdsets = [...state.audiencesAndInterests];
      const i = action.payload.index;
      const id_excl = action.payload.id;
      updatedDetailledAdsets[i].exclusion_audiences = updatedDetailledAdsets[i].exclusion_audiences.filter(
        (item) => item.id !== id_excl
      );
      return {
        ...state,
        audiencesAndInterests: updatedDetailledAdsets,
      };
    case "REMOVE_EXCLUSION_CENTRE_ADSETS_DETAILLE":
      updatedDetailledAdsets = [...state.audiencesAndInterests];
      const ic = action.payload.index;
      const idc = action.payload.id;
      updatedDetailledAdsets[ic].exclusionInterests = updatedDetailledAdsets[ic].exclusionInterests.filter(
        (item) => item.id !== idc
      );
      return {
        ...state,
        audiencesAndInterests: updatedDetailledAdsets,
      };
    case "CHANGE_START_TIME_ADSET":
      updatedDetailledAdsets = [...state.audiencesAndInterests];
      updatedDetailledAdsets[action.payload.index].start_time = action.payload.value;
      return {
        ...state,
        audiencesAndInterests: updatedDetailledAdsets,
      };
    case "CHANGE_END_TIME_ADSET":
      updatedDetailledAdsets = [...state.audiencesAndInterests];
      updatedDetailledAdsets[action.payload.index].end_time = action.payload.value;
      return {
        ...state,
        audiencesAndInterests: updatedDetailledAdsets,
      };
    case "CHANGE_BUDGET_ADSET":
      updatedDetailledAdsets = [...state.audiencesAndInterests];
      updatedDetailledAdsets[action.payload.index].budget = action.payload.value
      return {
        ...state,
        audiencesAndInterests: updatedDetailledAdsets,
      };
    case "CHANGE_NUMBER_ADSET":
      updatedDetailledAdsets = [...state.audiencesAndInterests];
      updatedDetailledAdsets[action.payload.index].n_adsets = action.payload.value;
      return {
        ...state,
        audiencesAndInterests: updatedDetailledAdsets,
      };
    case "REMOVE_ALL_DATES_ADSETS_DETAILLE":
      updatedDetailledAdsets = [...state.audiencesAndInterests];
      const removedDates = updatedDetailledAdsets.map((ad) => ({
        ...ad,
        start_time: "",
        end_time: ""
      }));
      return {
        ...state,
        audiencesAndInterests: removedDates,
      };
    case "SET_ADSETS_NOT_SENSEGO":
      const adsets = action.payload;
      const newAdsets = adsets.map((adset) => {
        return {
          id: adset.id,
          name: adset.name,
        };
      });
      return {
        ...state,
        adsetsCampaign: newAdsets,
      };
    case "ADD_MANDATORY_FIELD":
      if (!state.mandatory_fields.includes(action.payload)){
        return {
          ...state,
          mandatory_fields: [...state.mandatory_fields, action.payload]
        };
      }
      return state;
    case "REMOVE_MANDATORY_FIELD":
      return {
        ...state,
        mandatory_fields: state.mandatory_fields.filter(
          field => field !== action.payload)
      };
    default:
      return state;
  }
};
