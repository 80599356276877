const initialState = {
  carre: null,
  story: null,
  showSection: false,
  image: {
    selectedImages: [],
    images: [],
    filteredImages: [],
    pagination: {
      count: 10,
      startIndex: 0,
      endIndex: 10,
    },
  },
  video: {
    selectedVideos: [],
    videos: [],
    filteredVideos: [],
    pagination: {
      count: 10,
      startIndex: 0,
      endIndex: 10,
    },
  },
};

export const appairerVisuelsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_VIDEO_TO_LIST_ASSETS_APPAIRER_VISUELS":
      const videoToAdd = {
        ...action.payload,
        isSelected: true,
      };

      return {
        ...state,
        video: {
          ...state.video,
          videos: [videoToAdd, ...state.video.videos],
          filteredVideos: [videoToAdd, ...state.video.filteredVideos],
        },
      };
    case "ADD_IMAGE_TO_LIST_ASSETS_APPAIRER_VISUELS":
      const imageToAdd = {
        ...action.payload,
        isSelected: true,
      };

      return {
        ...state,
        image: {
          ...state.image,
          images: [imageToAdd, ...state.image.images],
          filteredImages: [imageToAdd, ...state.image.filteredImages],
        },
      };
    case "SHOW_SELECTED_VIDEOS_APPAIRER_VISUELS":
      const videosTab = state.video.videos.map(element =>
        action.payload.includes(element.id)
          ? { ...element, isSelected: true }
          : { ...element, isSelected: false }
      );
      const selectedVideosTab = videosTab.filter(vid => vid && vid.isSelected);
      const notSelectedVideosTab = videosTab.filter(
        vid => vid && !vid.isSelected
      );

      return {
        ...state,
        video: {
          ...state.video,
          videos: [...selectedVideosTab, ...notSelectedVideosTab],
          filteredVideos: [...selectedVideosTab, ...notSelectedVideosTab],
        },
      };
    case "SHOW_SELECTED_IMAGES_APPAIRER_VISUELS":
      const imagesTab = state.image.images.map(element =>
        action.payload.includes(element.id)
          ? { ...element, isSelected: true }
          : { ...element, isSelected: false }
      );
      const selectedImagesTab = imagesTab.filter(img => img && img.isSelected);
      const notSelectedImagesTab = imagesTab.filter(
        img => img && !img.isSelected
      );

      return {
        ...state,
        image: {
          ...state.image,
          images: [...selectedImagesTab, ...notSelectedImagesTab],
          filteredImages: [...selectedImagesTab, ...notSelectedImagesTab],
        },
      };
    case "SHOW_SELECTED_ITEMS_APPAIRER_VISUELS":
      const videosArr = state.video.videos.map(element =>
        action.payload.selectedVideos.includes(element.id)
          ? { ...element, isSelected: true }
          : { ...element, isSelected: false }
      );
      const selectedVideosArr = videosArr.filter(vid => vid && vid.isSelected);
      const notSelectedVideosArr = videosArr.filter(
        vid => vid && !vid.isSelected
      );

      const imagesArr = state.image.images.map(element =>
        action.payload.selectedImages.includes(element.id)
          ? { ...element, isSelected: true }
          : { ...element, isSelected: false }
      );
      const selectedImagesArr = imagesArr.filter(img => img && img.isSelected);
      const notSelectedImagesArr = imagesArr.filter(
        img => img && !img.isSelected
      );

      return {
        ...state,
        image: {
          ...state.image,
          images: [...selectedImagesArr, ...notSelectedImagesArr],
          filteredImages: [...selectedImagesArr, ...notSelectedImagesArr],
        },
        video: {
          ...state.video,
          videos: [...selectedVideosArr, ...notSelectedVideosArr],
          filteredVideos: [...selectedVideosArr, ...notSelectedVideosArr],
        },
      };
    case "SET_END_INDEX_PAGINATION_IMAGES_APPARIEMENT_VISUELS":
      return {
        ...state,
        image: {
          ...state.image,
          pagination: {
            ...state.image.pagination,
            endIndex: parseInt(action.payload),
            startIndex: parseInt(action.payload) - state.image.pagination.count,
          },
        },
      };
    case "SET_END_INDEX_PAGINATION_VIDEOS_APPARIEMENT_VISUELS":
      return {
        ...state,
        video: {
          ...state.video,
          pagination: {
            ...state.video.pagination,
            endIndex: parseInt(action.payload),
            startIndex: parseInt(action.payload) - state.video.pagination.count,
          },
        },
      };
    case "FILTER_IMAGES_APPARIEMENT_VISUELS":
      return {
        ...state,
        image: {
          ...state.image,
          // eslint-disable-next-line
          filteredImages: action.payload
            ? // eslint-disable-next-line
              state.image.images.filter(img => {
                if (img.name) {
                  return img.name
                    .toLowerCase()
                    .includes(action.payload.toLowerCase());
                }
              })
            : state.image.images,
        },
      };
    case "FILTER_VIDEOS_APPARIEMENT_VISUELS":
      return {
        ...state,
        video: {
          ...state.video,
          // eslint-disable-next-line
          filteredVideos: action.payload
            ? // eslint-disable-next-line
              state.video.videos.filter(vid => {
                if (vid.title) {
                  return vid.title
                    .toLowerCase()
                    .includes(action.payload.toLowerCase());
                }
              })
            : state.video.videos,
        },
      };
    case "GET_IMAGES_APPARIEMENT_VISUELS_SUCCESS":
      const images = action.payload;

      return {
        ...state,
        image: {
          ...state.image,
          images: images,
          filteredImages: images,
        },
      };
    case "GET_VIDEOS_APPARIEMENT_VISUELS_SUCCESS":
      const videos = action.payload;

      return {
        ...state,
        video: {
          ...state.video,
          videos: videos,
          filteredVideos: videos,
        },
      };
    case "CLEAR_ALL_APPAIRER_VISUELS":
      return {
        ...state,
        carre: null,
        story: null,
      };
    case "HIDE_SECTION_APPAIRER_VISUELS":
      return {
        ...state,
        showSection: false,
      };
    case "SHOW_SECTION_APPAIRER_VISUELS":
      return {
        ...state,
        showSection: true,
      };
    case "DELETE_STORY_APPAIRER_VISUELS":
      return {
        ...state,
        story: null,
      };
    case "DELETE_CARRE_APPAIRER_VISUELS":
      return {
        ...state,
        carre: null,
      };
    case "SET_IMAGE_STORY_APPAIRER_VISUELS":
      return {
        ...state,
        story: { ...action.payload, type: "image" },
      };
    case "SET_IMAGE_CARRE_APPAIRER_VISUELS":
      return {
        ...state,
        carre: { ...action.payload, type: "image" },
      };
    case "SET_VIDEO_STORY_APPAIRER_VISUELS":
      return {
        ...state,
        story: { ...action.payload, type: "video" },
      };
    case "SET_VIDEO_CARRE_APPAIRER_VISUELS":
      return {
        ...state,
        carre: { ...action.payload, type: "video" },
      };
    default:
      return state;
  }
};
