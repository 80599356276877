import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import axiosInstance from "../../../../store/axios.instance";
import { valeurExiste } from "../../../../utils";
import { useSearchParams } from "react-router-dom";

const BodyItem = ({ texte, showTags }) => {
  const [newTag, setNewTag] = useState("");
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const [loader, setLoader] = useState(false);
  const { adaccountId } = useSelector((state) => state.auth.user);

  const deleteText = (text) => {
    if (window.confirm("Voulez-vous vraiment supprimer ce texte ?")) {
      axiosInstance
        .delete(`/${adaccountId}/AdBodies/${text.id}`)
        .then(() => {
          setTimeout(() => {
            dispatch({
              type: "DELETE_TEXTE_CREATION_PACKAGE_PUB_SUCCESS",
              payload: text.id,
            });

            toast.success("Texte supprimé avec succès");

            setLoader(false);
          }, 500);
        })
        .catch((err) => {
          dispatch({
            type: "DELETE_TEXTE_CREATION_PACKAGE_PUB_FAIL",
          });
          console.log(err);
          setLoader(false);
        });
    }
  };

  const handleChangeNewTag = (e) => {
    setNewTag(e.target.value);
  };

  const saveNewTag = (e, body) => {
    if (e.key === "Enter") {
      if (!newTag) {
        toast.error("Veuillez insérer un tag");
        return;
      }
      setLoader(true);

      const dataToSend = {
        content: body.content,
        keywords: [...body.keywords, newTag],
      };

      axiosInstance
        .post(`/${adaccountId}/AdBodies/addkeywords`, [dataToSend])
        .then(() => {
          dispatch({
            type: "UPDATE_BODY_CREATION_PACKAGE_PUBLICTE_SUCCESS",
          });

          toast.success("Tag ajouté avec succès");

          setLoader(false);

          dispatch({
            type: "ADD_TAG_BODY_LOCALLY",
            payload: { id: body.id, tag: newTag },
          });
        })
        .catch((err) => {
          dispatch({
            type: "UPDATE_BODY_CREATION_PACKAGE_PUBLICTE_FAIL",
          });
          console.log(err);
          setLoader(false);
        });

      setNewTag("");
    }
  };

  const deleteTag = (body, tag) => {
    if (window.confirm("Voulez-vous vraiment supprimer ce tag ?")) {
      setLoader(true);

      const dataToSend = {
        content: body.content,
        keywords: body.keywords.filter((item) => item !== tag),
      };

      axiosInstance
        .post(`/${adaccountId}/AdBodies/newkeywords`, [dataToSend])
        .then(() => {
          dispatch({
            type: "DELETE_TAG_TEXTE_CREATION_PACKAGE_PUB",
            payload: {
              id: body.id,
              tag,
            },
          });

          toast.success("Tag supprimé avec succès");

          setLoader(false);
        })
        .catch((err) => {
          dispatch({
            type: "UPDATE_BODY_CREATION_PACKAGE_PUBLICTE_FAIL",
          });
          console.log(err);
          setLoader(false);
        });
    }
  };
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();

  const tagToEdit = searchParams.get("tagToEdit");

  const { nomPackage } = useSelector((state) => state.creationPackagePublicite);

  const toggleSelectItem = (id) => {
    if (tagToEdit && texte.isSelected) {
      if (valeurExiste(texte.keywords, nomPackage)) {
        const dataToSend = {
          content: texte.content,
          keywords: texte.keywords.filter((item) => item !== nomPackage),
        };

        axiosInstance
          .post(`/${adaccountId}/AdBodies/newkeywords`, [dataToSend])
          .then(() => {
            dispatch({
              type: "DELETE_TAG_TEXTE_CREATION_PACKAGE_PUB",
              payload: {
                id: texte.id,
                tag: nomPackage,
              },
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }

    dispatch({ type: "TOGGLE_SELECT_TEXTE", payload: id });
  };

  return (
    <div
      className={`body ${texte.isSelected ? "selected" : ""}`}
      onClick={() => toggleSelectItem(texte.id)}
    >
      <i
        onClick={(e) => {
          e.stopPropagation();
          deleteText(texte);
        }}
        className="fas close fa-times"
      ></i>
      <h5>
        <pre style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>
          {texte.content}
        </pre>
      </h5>
      {showTags && texte.keywords && (
        <React.Fragment>
          <div className="tags" onClick={(e) => e.stopPropagation()}>
            {texte.keywords.length > 0 ? (
              texte.keywords.map((tg, i) => (
                <div key={i} className="tag">
                  <p>{tg}</p>
                  <i
                    onClick={(e) => {
                      deleteTag(texte, tg);
                    }}
                    className="fas fa-times"
                  ></i>
                </div>
              ))
            ) : (
              <h6>Aucun tag pour l'instant</h6>
            )}
          </div>
          <div className="add_tag" onClick={(e) => e.stopPropagation()}>
            <input
              onKeyDown={(e) => {
                saveNewTag(e, texte);
              }}
              value={newTag}
              onChange={handleChangeNewTag}
              type="search"
              name=""
              id=""
              placeholder="Tags"
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default BodyItem;
