import React, { useEffect, useState, useRef } from "react";
import Loader from "../../components/Loader/Loader";
import axiosInstance from "../../store/axios.instance";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import {
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import LeftMenu from "../../components/LeftMenu/LeftMenu";
import { toast } from "react-toastify";
import TopRightMenu from "../../components/TopRightMenu/TopRightMenu";
import { FormattedMessage, useIntl } from "react-intl";
import RightContainer from "../../components/RightContainer/RightContainer";
import Loader3 from "../../components/Loader3/Loader3";
import { getSafeLastPage, forgetLastPage } from "../../store/actions/utils/usualDispatches";
import { getDBData, getAudiences } from "../../store/actions/utils/campaignCreation";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CreationAudience = ({ updateMode, mainTitle }) => {
  function formatSize(lower, upper) {
    if (lower===-1){
      return "Unavailable";
    }    
    return lower.toLocaleString() + " - " + upper.toLocaleString()
  }
  const isFirstRender = useRef(true);
  const navigate = useNavigate();
 
  const [loaderAudiences, setLoaderAudiences] = useState(false);
  const [currentPackageId, setCurrentPackageId] = useState("")
  const [packageName, setPackageName] = useState("");
  const [blacklist, setBlackList] = useState([]);
  const [audiences, setAudiences] = useState([]);
  const [advantageAudience, setAdvantageAudience] = useState(false);
  const [emptyNameError, setEmptyNameError] = useState(false)
  const [emptyAudiencesListError, setEmptyAudiencesListError] = useState(false)
  const {audiencesPackagePub, customAudiences} = useSelector((state)=> state.creationCampagneRef) 

  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();
  const { toggleLeftMenu, lastPage } = useSelector((state) => state.utils);
  const {file_configuration_id} = useSelector((state) => state.creationCampagne);

  const setToggleLeftMenu = () => {
    dispatch({ type: "SET_TOGGLE_LEFT_MENU" });
  };

  const logoutFunc = () => {
    // logout({ returnTo: window.location.origin });
  };

  const { adaccountId } = useSelector((state) => state.auth.user);

  const [searchParams] = useSearchParams();
  const audience_type = searchParams.get("audience_type");
  const params = useParams();
  
  const clearData = () =>{
    setCurrentPackageId("");
    setPackageName("");
    setBlackList([]);
    setAudiences([]);
    setAdvantageAudience(false);
  }

  const getCustomAudiences = () => {
    setLoaderAudiences(true);
    let endpoint = `/${adaccountId}/custom_audiences`
    let fields =["id","name", "approximate_count_lower_bound", "approximate_count_upper_bound"]
    fields.forEach(
      (field, index)=>{
      if (index===0){
        endpoint = endpoint + `?fields=${field}`
      }
      else{
        endpoint = endpoint + `&fields=${field}`
      }
    })
    getDBData(dispatch, endpoint,"GET_CUSTOM_AUDIENCES")
      .then(()=> setLoaderAudiences(false))
      .catch(()=>{
        toast.error("Impossible de récupérer les audiences, veuillez réessayer plus tard")
        setLoaderAudiences(false)
      })
    };
  
  function formatPackageToSend(){
    let targetingRelaxationTypesObject = {
      lookalike: 0,
      custom_audience: 0,
    };

    if (advantageAudience) {
      targetingRelaxationTypesObject = {
        lookalike: 1,
        custom_audience: 1,
      };
    }

    let dataToSend = {
      name: packageName,
      custom_audiences: audiences.map((audience) => ({
        id: audience.id,
        name: audience.name,
      })),
      removed_audiences: blacklist.map((audience) => ({
        id: audience.id,
        name: audience.name,
      })),
      targeting_relaxation_types: targetingRelaxationTypesObject,
    };
    return dataToSend
  }

  const savePackageAudience = () => {
    setLoader(true);
    const dataToSend = formatPackageToSend()
    
    return axiosInstance
      .put(`/${adaccountId}/audiences_package`, dataToSend)
      .then((res) => {
        if(lastPage){
          if (audience_type === "exclusion") {
            dispatch({
              type: "ADD_PACKAGE_AUDIENCE_EXCLUSION",
              payload: res.data.data,
            });
          } else {
            dispatch({
              type: "ADD_PACKAGE_AUDIENCE",
              payload: res.data.data,
            });
          }
        }
        setLoader(false);
        toast.success("Package audience crée");
        return true
      })
      .catch(() => {
        toast.error(
          "Une erreur s'est produite, veuillez réessayer plus tard"
        );
        setLoader(false);
        return false
      });
  };

  const editPackageAudience = () => {
    const dataToSend = formatPackageToSend();

    return axiosInstance
      .post(
        `/${adaccountId}/audiences_package/${currentPackageId}`,
        dataToSend
      )
      .then((res) => {
        if(!updateMode && lastPage){
          if (audience_type === "exclusion") {
            dispatch({
              type: "ADD_PACKAGE_AUDIENCE_EXCLUSION",
              payload: res.data.data,
            });
          } else {
            dispatch({
              type: "ADD_PACKAGE_AUDIENCE",
              payload: res.data.data,
            });
          }
        }
        setLoader(false);
        toast.success("Package audience crée");
        return true
      })
      .catch(() => {
        toast.error(
          "Une erreur s'est produite, veuillez réessayer plus tard"
        );
        setLoader(false);
        return false
      });
   };


  const handleChangeName = (e, newItem) => {
    if(newItem && newItem.name){
      setCurrentPackageId(newItem.id);
      setAudiences(newItem.custom_audiences);
      setBlackList(newItem.removed_audiences)
      setAdvantageAudience(
        newItem.targeting_relaxation_types? 
        (newItem.targeting_relaxation_types.lookalike || 
          newItem.targeting_relaxation_types.custom_audience):false);
      //getSinglePackage(selectedAudience.id);
    }
    newItem
      ? setEmptyNameError(false)
      : setEmptyNameError(true);
  };


  const handleSave = () => {
    if (packageName.includes("/")) {
      toast.error(
        intl.formatMessage({
          defaultMessage: "Le caractère / est interdit !",
          id: "character_slash_forbidden_WDy529",
        })
      );
    }
    const promiseSave = currentPackageId
      ? editPackageAudience()
      : savePackageAudience();
    if (packageName.length > 0 && audiences.length > 0) {  
      promiseSave.then((success) =>{
        if(success){
          let goTo = getSafeLastPage(lastPage, file_configuration_id);
          dispatch(forgetLastPage());
          navigate(goTo);      
          getAudiences(dispatch, adaccountId);
        }
      })
    } else {
      packageName?
        setEmptyNameError(false):setEmptyNameError(true)
      audiences.langth > 0?
        setEmptyAudiencesListError(false):setEmptyAudiencesListError(true)
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", function (event) {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    });
  }, []);

  const intl = useIntl();

  useEffect(()=>{
    if (params.id){
      setCurrentPackageId(params.id);
      const packageToLoad = audiencesPackagePub.find(audiencePackage=> audiencePackage.id===params.id);
      if (packageToLoad){
        setPackageName(packageToLoad.name)
        handleChangeName(null, packageToLoad);
      }
    }
  }, [audiencesPackagePub])
  
  useEffect(() => {
    if (isFirstRender.current){
      if (!audiencesPackagePub.length){
        getAudiences(dispatch, adaccountId);
      }
      if (!customAudiences.length){
        getCustomAudiences(adaccountId);
      }
      isFirstRender.current = false;
    }
    else {
      clearData();
      getAudiences(dispatch, adaccountId);
      getCustomAudiences(adaccountId);
    }
  }, [adaccountId]);

  return (
    <div className="screen_creation_audience">
      <LeftMenu />
      <RightContainer>
        <header className="right_content_header">
          <div className="back_title">
            {toggleLeftMenu ? (
              <i
                onClick={() => setToggleLeftMenu((prevState) => !prevState)}
                style={{ marginRight: 30, cursor: "pointer" }}
                className="fas fa-bars fa-2x"
              ></i>
            ) : null}
            <div className="back">
              <img
                style={{ cursor: "pointer" }}
                onClick={() => {
                  dispatch({type:"SET_STATE_PROTECT_DETAILLED_ADSETS",payload:true});
                  let goTo = getSafeLastPage(lastPage, file_configuration_id);
                  dispatch(forgetLastPage());
                  navigate(goTo);
                  }}
                src="/left-arrow 2.png"
                alt="Retour"
              />
            </div>
            <h1 className="right_content_header_title">
              {!updateMode ? (
                <FormattedMessage
                  defaultMessage="Ajouter un package audience"
                  id="screen_creation_audience_1"
                />
              ) : (
                mainTitle
              )}
            </h1>
          </div>
          <div className="client" onClick={logoutFunc}>
            <TopRightMenu />
          </div>
        </header>
        {loader && (
          <Loader
            message={intl.formatMessage({
              defaultMessage: "Création du package audience en cours...",
              id: "loader_message_7",
            })}
          />
        )}
        <div className="right_content_main">
          <div className="sections">
            <section className="informations_generales section">
              <header
                className="section_header"
              >
                <div className="df">
                  <h3 className="section_header_title">
                    <FormattedMessage
                      defaultMessage="Package audience"
                      id="screen_creation_audience_4"
                    />
                  </h3>
                  {audiences.length > 0 && packageName ? (
                    <img src="/step_checked.png" alt="Etape vérifiée" />
                  ) : null}
                </div>
              </header>
              <div className="informations_generales_content">
                <form action="">
                  <div className="row">
                    <div style={{ flexDirection: "column", alignItems: "start" }}>
                      <div
                        className={`input_group ${emptyNameError && " inputError"}`}
                      >
                        <label htmlFor="">
                          <FormattedMessage
                            defaultMessage="Nom du package audience"
                            id="screen_creation_audience_5"
                          />
                        </label>
                        {loaderAudiences ? (
                          <Loader3 />
                        ) : (
                          <div className="input_group centre_interet">
                            <Autocomplete
                              name="nom-package-audience"
                              id="autocomplete-standard"
                              disabled={updateMode}
                              options={audiencesPackagePub}
                              value={packageName}
                              onInputChange={(e,newInput)=>{
                                setPackageName(newInput)}}
                              onChange={handleChangeName}
                              getOptionLabel={(option) => option.name || option}
                              isOptionEqualToValue={(option, value) => option.name === value}
                              //onBlur={handleChangeName}
                              clearOnBlur={false}
                              freeSolo
                              style={{
                                width: "100%",
                                borderBottom: emptyNameError && "1px solid red",
                              }}
                              renderInput={(params) => {
                                //way to replace the base function of clicking on the cross of a textfield
                                //simplerway might be to construct it by hand
                                let modifiedEndAdornment = params.InputProps.endAdornment;
                                if (params.InputProps.endAdornment && params.InputProps.endAdornment.props && params.InputProps.endAdornment.props.children) {
                                  const children = React.Children.toArray(params.InputProps.endAdornment.props.children);
                                  const modifiedChildren = children.map(child => {
                                    if (child.props.title==="Clear" && child.props.onClick) {
                                      return React.cloneElement(child, {
                                        onClick: (event) => {
                                          if (typeof child.props.onClick === 'function') {
                                            child.props.onClick(event);
                                          }
                                          clearData();
                                        }
                                      });
                                    }
                                    return child
                                  });
                                  modifiedEndAdornment = React.cloneElement(
                                    params.InputProps.endAdornment,
                                    {},
                                    modifiedChildren
                                  );
                                }

                                return (
                                  <TextField variant="standard" 
                                    {...params}
                                    InputProps={{
                                      ...params.InputProps,
                                     endAdornment: modifiedEndAdornment}}
                                />)     
                              }}
                            />
                          </div>
                        )}
                      </div>
                      <span className="messageError">
                        {emptyNameError && (
                          <FormattedMessage
                            defaultMessage="Nom du package audience obligatoire"
                            id="screen_creation_audience_error_msg_1"
                          />
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div style={{ flexDirection: "column", alignItems: "start" }}>
                      <div
                        className={`input_group ${
                          emptyAudiencesListError && " inputError"
                        }`}
                      >
                        <label htmlFor="">
                          <FormattedMessage
                            defaultMessage="Liste des audiences"
                            id="screen_creation_audience_6"
                          />
                        </label>
                        {loaderAudiences ? (
                          <Loader3 />
                        ) : (
                          <div className="input_group centre_interet">
                            <Autocomplete
                              value={audiences}
                              multiple
                              id="checkboxes-tags-demo"
                              onChange={(e, newAudiences)=>{
                                setAudiences(newAudiences);
                                let idAudiences = newAudiences.map(audience=>(audience.id));
                                setBlackList(blacklist.filter(audience=>!idAudiences.includes(audience.id)));
                              }}
                              options={customAudiences || []}
                              disableCloseOnSelect
                              getOptionLabel={(option) => option.name}
                              isOptionEqualToValue={(option, value) => option.id === value.id}
                              renderOption={(props, option, { selected }) => (
                                <li {...props} style={{display: "flex",
                                  justifyContent: "space-between"}}>
                                  <div style={{ display: "flex", alignItems: "center" }}>
                                    <Checkbox
                                      icon={icon}
                                      checkedIcon={checkedIcon}
                                      style={{marginRight: 8 }}
                                      checked={selected}
                                    />
                                    <div style={{ textAlign: "left" }}>{option.name}</div>
                                  </div>
                                  <div style={{ marginLeft: 30, color: '#40458A', textAnchor:'end', textAlign:"end"}}>
                                    Size: {formatSize(option.approximate_count_lower_bound, option.approximate_count_upper_bound)}
                                  </div>
                                </li>
                              )}
                              style={{ width: "100%" }}
                              renderInput={(params) => (
                                <TextField variant="standard" {...params} />
                              )}
                            />
                          </div>
                        )}
                      </div>
                      <span className="messageError">
                        {emptyAudiencesListError && (
                          <FormattedMessage
                            defaultMessage="Liste des audiences obligatoire"
                            id="screen_creation_audience_error_msg_2"
                          />
                        )}
                      </span>
                    </div>
                    <div className="input_group">
                      <label htmlFor="">
                        <FormattedMessage
                          defaultMessage="Blacklist Audiences"
                          id="screen_creation_audience_7"
                        />
                      </label>
                      {loaderAudiences ? (
                        <Loader3 />
                      ) : (
                        <Autocomplete
                          multiple
                          value={blacklist}
                          onChange={(e, newBlacklist)=>{
                            setBlackList(newBlacklist);
                            let idAudiences = newBlacklist.map(audience=>(audience.id));
                            setAudiences(audiences.filter(audience=>!idAudiences.includes(audience.id)));
                          }}
                          id="checkboxes-tags-demo"
                          options={customAudiences || []}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option.name}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.name}
                            </li>
                          )}
                          style={{ width: "100%" }}
                          renderInput={(params) => (
                            <TextField variant="standard" {...params} />
                          )}
                        />
                      )}
                    </div>
                  </div>
                  <h3 className="advantage_audience_title">Advantage audience</h3>
                  <div className="advantage_audience">
                    <input
                      checked={advantageAudience}
                      type="checkbox"
                      id="advantage_audience_checkbox"
                      onChange={(e=>setAdvantageAudience(e.target.checked))}
                    />
                    <label htmlFor="advantage_audience_checkbox">
                      <FormattedMessage
                        defaultMessage="Cibler des personnes qui ne font pas partie de vos audiences lorsque cela est susceptible d’améliorer la performance"
                        id="advantage_audience_text_WeD_6Kb"
                      />
                    </label>
                  </div>
                </form>
              </div>
          </section>
        </div>

        <div className="d-fe">
          <button onClick={handleSave}>
            <FormattedMessage
              defaultMessage="Enregistrer"
              id="screen_creation_audience_3"
            />
          </button>
        </div>
      </div>
      </RightContainer>
    </div>
  );
};

export default CreationAudience;
