import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

const TopInterests = () => {
  const { topTroisinterestsReports } = useSelector(
    (state) => state.rapportStatistiquesDetailllees
  );

  return (
    <div className="top_interets">
      <h6>
        <FormattedMessage
          defaultMessage="top 3 intérêts"
          id="detail_campagne_screen_9"
        />
      </h6>
      <ul>
        {topTroisinterestsReports && topTroisinterestsReports.length > 0 ? (
          topTroisinterestsReports.map((item, index) => {
            // const comb_value = JSON.parse(item.comb_value.replace(/'/g, '"'));
            const name = item.comb_value[0].split("name")[1].replace(/[^a-zA-Z\s]+/g, '');
            let metric_string = "";
            let metric = 0;
            if (
              item.hasOwnProperty("ROAS omni_purchase_value") &&
              Number(item["ROAS omni_purchase_value"]) > 0
            ) {
              metric = Number(item["ROAS omni_purchase_value"].toFixed(1));
              metric_string = "ROAS : " + metric;
            } else if (item.hasOwnProperty("Cost per inline_link_clicks")) {
              metric = Number(item["Cost per inline_link_clicks"]).toFixed(2);
              metric_string = "CPC : " + metric;
            }
            return (
              <li>
                <div className="left">
                  <span>{index + 1}</span> {item && item.comb_value[0] ? name : ""}
                </div>
                <div className="right">
                  <div
                    style={{
                      width: `${metric}%`,
                    }}
                    className="chart"
                  ></div>
                  <p>{metric_string}</p>
                </div>
              </li>
            );
          })
        ) : (
          <p style={{ textAlign: "center" }}>Aucune donnée disponible</p>
        )}
      </ul>
    </div>
  );
};
export default TopInterests;
