import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "./style.css";
import axios from "axios";
import { Switch } from "@mui/material";
import { toast } from "react-toastify";

import Loader3 from "../../../components/Loader3/Loader3";
import { FormattedMessage } from "react-intl";

const CampaignElements = ({ campaignId, campaignName, closeModal }) => {
  const [loader, setLoader] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [editAdIndex, setEditAdIndex] = useState(null);
  const [editInput, seteditInput] = useState(false);

  const [newAdsetName, setNewAdsetName] = useState("");
  const [newAdName, setNewAdName] = useState("");

  const [originalData, setOriginalData] = useState([]);
  const [modifiedAdsets, setModifiedAdsets] = useState(new Set());
  const [modifiedAds, setModifiedAds] = useState(new Set());
  const [modifiedNames, setModifiedNames] = useState(new Set());
  
  const addModifiedName = (id) => {
    if (!(modifiedNames.has(id))){
      const newModifiedNames = new Set([...modifiedNames, id]);
      setModifiedNames(newModifiedNames);
      // const newModifiedAds = new Set(...modifiedAds);
      // newModifiedAds.add(id);
      // setModifiedAds(newModifiedAds);
    }
  }
  const delModfiedName = (id) => {
    const newModifiedNames = new Set([...modifiedNames]);
    newModifiedNames.delete(id);
    setModifiedNames(newModifiedNames);
  }

  const[switchAdsetStates, setSwitchAdsetStates] = useState([]);
  const[switchAdsStates, setSwitchAdsStates] = useState({})

  const {
    userData: { token: accessToken },
  } = useSelector((state) => state.auth.user);

  const cancel = () => {
    closeModal();
  };

  const getCampaignElements = async (campaignId) => {
    setLoader(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_GRAPH_API}/${campaignId}/adsets?fields=adlabels,status,name&access_token=${accessToken}&limit=100`
      );
      const adsets = response.data.data;
      await Promise.all(adsets.map(async (adset) => {
        const ads = await axios.get(
          `${process.env.REACT_APP_GRAPH_API}/${adset.id}/ads?fields=ad_id,name,status,preview_shareable_link,adlabels&access_token=${accessToken}&limit=100`);
        adset.ads = ads.data.data;
        }
      ));
      // console.log("my adsets", adsets);
      setOriginalData(adsets);
      setSwitchAdsetStates(
        adsets.map((adset) =>
            !(adset.adlabels && adset.adlabels.some((label) => label.name === "sensego_deprecated"))
          // !(adset.adlabels?.some((label) => label.name === "sensego_deprecated"))
      ));
      setSwitchAdsStates(
        adsets.reduce((switchAdsStates, adset) => {
          switchAdsStates[adset.id] = adset.ads.map((ad)=>
            !(ad.adlabels && ad.adlabels.some((label) => label.name === "sensego_deprecated"))
          );
          return switchAdsStates;
        }, {})
      )
      setLoader(false);
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  };

  const maintainChanges = async (listChanges, updaterChanges, elementId) => {
    if (listChanges.has(elementId)){
      updaterChanges((prevIds) => {
        const newIds = new Set([...prevIds]);
        newIds.delete(elementId);
        return newIds;
      });
    }
    else{
      updaterChanges((prevIds) => new Set([...prevIds, elementId]));
    }
  };

  const saveAdsetNameEdit = async (index) => {
    const newData = [...originalData];
    const adset = newData[index];
    adset.name = newAdsetName;
    setOriginalData(newData);
    addModifiedName(adset.id);
    // const newModifiedAdsets = new Set(...modifiedAdsets);
    // newModifiedAdsets.add(adset.id);
    // setModifiedAdsets(newModifiedAdsets);
    setEditIndex(null);
  };

  const saveAdNameEdit = async (index, adindex)=>{
    const newData = [...originalData];
    const ad = newData[index].ads[adindex];
    ad.name = newAdName;
    setOriginalData(newData)
    addModifiedName(ad.id);
    setEditIndex(null);
    setEditAdIndex(null);
  }

  const handleSave = () => {
    originalData.map((adset, index) => {
      let adsetStatus = false;
      let adsetName = false;
      if (modifiedAdsets.has(adset.id)) {
        adsetStatus = switchAdsetStates[index]?"ACTIVE":"PAUSED";
      }
      if (modifiedNames.has(adset.id)){
        adsetName = true;
      }
      saveChanges(adset, adsetStatus, adsetName);
      adset.ads.map((ad, adIndex) => {
        let adStatus = false;
        let adName = false;
        if (modifiedAds.has(ad.id)) {
          adStatus = switchAdsStates[adset.id][adIndex]?"ACTIVE":"PAUSED";
        }
        if (modifiedNames.has(ad.id)){
          adName = true;
        }
        saveChanges(ad, adStatus, adName);
      });
    });

    closeModal();
  };

  const saveChanges = async (element, status, changedName) => {
    const toUpdate = {};
    if (status){
      let adlabels = element.adlabels || []
      if (status === "PAUSED"){
        adlabels.push({name:"sensego_deprecated"})
      }
      else {
        adlabels = adlabels.filter((label) => label.name != "sensego_deprecated"); 
      }
      toUpdate["status"] = status;
      toUpdate["adlabels"] = adlabels;
    }
    if (changedName){
      toUpdate["name"] = element.name;
    }
    if (Object.keys(toUpdate).length){
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_GRAPH_API}/${element.id}?access_token=${accessToken}`,
          toUpdate
        );
        if (response.data.success === true) {
          // console.log("success");
          toast.success("Les modifications ont été appliquées avec succès !");
        } else {
          // console.error(
          //   "Error updating adsets name:",
          //   response,
          //   response.statusText
          // );
        }
      } catch (error) {
        // console.log(error);
        toast.error(error.response.data.error.error_user_msg);
      }
    }
  };

  const handleInputChange = async (e) => {
    // console.log(e.target.name, " / ", e.target.value);
    e.target.name === "adset" && setNewAdsetName(e.target.value);
    e.target.name === "ad" && setNewAdName(e.target.value);
  };

  const changeEditMode = (index, val) => {
    setEditIndex(index);
    setNewAdsetName(val);
    seteditInput(true);
  };

  const changeEditAdMode = (index, i, value) => {
    setNewAdName(value);
    setEditIndex(index);
    setEditAdIndex(i);
    seteditInput(false);
  };

  useEffect(() => {
    getCampaignElements(campaignId);
  }, [campaignId]);

  return (
    <div className="campaign-element">
      <div className="wrapper">
        <div className="header">
          <p>Gérer les élements de {campaignName} </p>
          <i className="fas fa-times" onClick={cancel}></i>
        </div>
        <div className="content">
          {loader ? (
            <Loader3 />
          ) : (
            <div className="performances">
              <table>
                <thead>
                  <tr>
                    <th>Ad sets</th>
                    <th>Ads</th>
                  </tr>
                </thead>
                <tbody>
                  {originalData &&
                    originalData.length > 0 &&
                    originalData.map((adset, index) => (
                      <tr key={adset.id}>
                        <td>
                          <Switch
                            onChange={() =>{
                              const newSwitchStates = [...switchAdsetStates];
                              newSwitchStates[index] = !newSwitchStates[index];
                              maintainChanges(
                                modifiedAdsets,
                                setModifiedAdsets,
                                adset.id
                              );
                              setSwitchAdsetStates(newSwitchStates);
                              }
                            }
                            checked={switchAdsetStates[index]}
                            size="small"
                          />
                          {editIndex === index && editInput ? (
                            <React.Fragment>
                              <input
                                name="adset"
                                type="text"
                                value={newAdsetName}
                                onChange={(e) => handleInputChange(e)}
                              />
                              <button
                                onClick={() => saveAdsetNameEdit(index)}
                              >
                                <i className="fas fa-save"></i>
                              </button>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <span className="round">{adset.name}</span>
                              <button
                                onClick={() =>
                                  changeEditMode(index, adset.name)
                                }
                              >
                                <i className="fas fa-edit"></i>
                              </button>
                            </React.Fragment>
                          )}
                        </td>
                        <td className="second">
                          <div className="td_content">
                            {adset.ads &&
                              adset.ads.length > 0 &&
                              adset.ads.map((ad, i) => (
                                <div key={ad.id} className="ligne-elem">
                                  <Switch
                                    onChange={() =>{
                                      const newSwitchStates = {...switchAdsStates};
                                      newSwitchStates[adset.id][i] = !newSwitchStates[adset.id][i];
                                      maintainChanges(
                                        modifiedAds,
                                        setModifiedAds, 
                                        ad.id
                                      )
                                      setSwitchAdsStates(newSwitchStates);
                                    }}
                                    checked={switchAdsStates[adset.id][i]}
                                  />
                                  {editIndex === index && editAdIndex === i ? (
                                    <React.Fragment>
                                      <input
                                        name="ad"
                                        type="text"
                                        value={newAdName}
                                        onChange={(e) =>
                                          handleInputChange(e)
                                        }
                                      />
                                      <button
                                        onClick={() =>
                                          saveAdNameEdit(index, i)
                                        }
                                      >
                                        <i className="fas fa-save"></i>
                                      </button>
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <p>{ad.name}</p>
                                      <button
                                        onClick={() =>
                                          changeEditAdMode(index, i, ad.name)
                                        }
                                      >
                                        <i className="fas fa-edit"></i>
                                      </button>
                                    </React.Fragment>
                                  )}

                                  <div className="number_see">
                                    {/*<span className='number'>1</span>*/}
                                    <a
                                      style={{
                                        marginLeft: "2rem",
                                      }}
                                      href={`${ad.preview_shareable_link}`}
                                      target="_blank"
                                    >
                                      <i
                                        style={{ color: "#e83e8c" }}
                                        className="far fa-eye"
                                      ></i>
                                    </a>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
        <div className="footer">
          <button onClick={handleSave} className="save">
            <FormattedMessage
              defaultMessage="Enregistrer"
              id="create_campaign_screen_save_button"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CampaignElements;
