import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import DataTable from "react-data-table-component";
import { numberWithSpaces } from "../../utils";

const TabularReport = ({ title, name, report }) => {
  // const [alerts, setAlerts] = useState([]);
  const [columns, setColumns] = useState([]);

  const ellipsisRenderer = (row, maxLength = 80) => {
    const text = row.comb_value[0];
    const formattedText = text.replace(/\n/g, '<br>');
    if (text.length > maxLength) {
      const teststring = formattedText.substring(maxLength-2, maxLength+2);
      if (teststring.includes("<br")){
        maxLength = maxLength -3 + teststring.indexOf("<")
        }
      const truncatedText = formattedText.substring(0, maxLength) + '...';
      return (
        <div title={text} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <span dangerouslySetInnerHTML={{ __html: truncatedText }} />
        </div>
      );
    }
    return (
      <div title={text}>
        <span dangerouslySetInnerHTML={{ __html: formattedText }} />
      </div>
    );
  };
  

  useEffect(() => {
    const baseColumns = [
        {
            name:name,
            selector: row => row.comb_value[0],
            sortable: true,
            wrap: true,
            maxWidth: "30%",
            cell: row => ellipsisRenderer(row)
            // columnDefs: [
            //     {
            //       targets: 1,
            //       render: function(data, type, row) {
            //         if ( type === 'display') {
            //           return renderedData = $.fn.dataTable.render.ellipsis(10)(data, type, row);            
            //         }
            //         return data;
            //       }
            //     }
            //   ],
            //   rowCallback: function(row, data, displayNum, displayIndex, dataIndex) {
            //     $(row).find('td:eq(1)').attr('title', data[1])      
            //   }
            //example of what could be done
            // column.cell = (row) => (
            //     <div
            //       dangerouslySetInnerHTML={{
            //         __html: row.sentence_EN,
            //       }}
            //     />
        },
        {
            name: "Spend",
            maxWidth: "12%",
            sortable: true,
            selector: row => row.spend,// + " €"//to be dynamically changed
            format: row => row.spend.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2}),
        },
        {
            name: "Impressions",
            maxWidth: "12%",
            sortable: true,
            selector: row => row.impressions,
            format: row => row.impressions.toLocaleString('fr-FR',{maximumFractionDigits: 0})
        },
        {
            name: "CPM",
            maxWidth: "7%",
            sortable: true,
            selector: row => row.cpm,
            format: row => row["cpm"].toLocaleString('fr-FR',{maximumFractionDigits: 2, style: 'currency', currency: 'EUR'}),
    
        },
        {
            name: "Score",
            maxWidth: "7%",
            sortable: true,
            selector: row => row.score,
            format: row => row.score.toLocaleString('fr-FR',{maximumFractionDigits: 2})
        },
        {
            name: "CPC",
            maxWidth: "7%",
            sortable: true,
            selector: row => row["Cost per inline_link_clicks"],
            format: row => row["Cost per inline_link_clicks"].toLocaleString('fr-FR', {maximumFractionDigits: 2, style: 'currency', currency: 'EUR'}),
        },
        {
            name: "ROAS",
            maxWidth: "7%",
            sortable: true,
            selector: row => row["ROAS omni_purchase_value"],
            format: row => row["ROAS omni_purchase_value"].toLocaleString('fr-FR',{maximumFractionDigits: 2})
        },
        {
            name: "Purchase",
            maxWidth: "10%",
            sortable: true,
            selector: row => row.omni_purchase,
            format: row => row.omni_purchase.toLocaleString('fr-FR',{maximumFractionDigits: 0})
        },
        {
            name: "Cost per purchase",
            maxWidth: "10%",
            sortable: true,
            selector: row => row["Cost per omni_purchase"],
            format: row => row["Cost per omni_purchase"].toLocaleString('fr-FR', {maximumFractionDigits: 2, style: 'currency', currency: 'EUR'}),
            wrap: true,//header is not wrapping for some reasons
            style: {
                whiteSpace:"normal",
                wordBreak:"normal"
            }
        },
    ]
    if (report && report.length > 0) {
      const columns = []
      baseColumns.forEach(column=> {
        try {
            
            if(column.selector(report[0]) !==undefined){
                columns.push(column);
            }
        }
        catch (error){
            console.log(`missing ${column.name} in report`)
        }
      })
      setColumns(columns);
    }
  }, []);

  return (
    <div className="optimisation_realisee">
      <header>
        <h6 className="title">
            {title}
        </h6>
      </header>
      <div className="content">
        {report && report.length !== 0 ? (
          <DataTable
            columns={columns}
            data={report}
            pagination={report.length>25}
            paginationPerPage={25}
            // customStyles={customStyles}
            striped
            highlightOnHover
            // dense
            fixedHeader
            fixedHeaderScrollHeight
          />
        ) : (
          <p style={{ textAlign: "center" }}>Données non disponibles</p>
        )}
      </div>
    </div>
  );
};
export default TabularReport;
