const initialState = {
  toggleLeftMenu: false,
  lastPage: "",
  showModalFilterInsightsByDate: false,
  dates: { startDate: "", endDate: "" },
  showAlertMsgBudgetAdset: false,
  showMsgBudgetAdset: false,
  showAlertMsgBudgetInfosGen: false,
  showMessageNotConnectedFB: false,
  showGeneralError: false,
  generalErrorMessage: "Erreur ...",
  emptyNameError: false,
  zonesGéosError: false,
  showAlertMsgPackagePub: false,
  showPopupSessionExpired: false,
  showCoutsColonnesDynamiques: false,
  showPopupFenetreAvertissementMonitoringCampagne: false,
  activateNavigationPopupFenetreAvertissementMonitoringCampagne: false,
  navigateTo: "",
};

export const utilsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "HIDE_POPUP_AVERTISSEMENT_MONITORING_CAMPAGNE":
      return {
        ...state,
        showPopupFenetreAvertissementMonitoringCampagne: false,
        activateNavigationPopupFenetreAvertissementMonitoringCampagne: false,
      };
    case "SHOW_POPUP_AVERTISSEMENT_MONITORING_CAMPAGNE":
      return {
        ...state,
        showPopupFenetreAvertissementMonitoringCampagne: true,
      };
    case "SHOW_POPUP_AVERTISSEMENT_MONITORING_CAMPAGNE_WITH_NAVIGATING_TO_ANOTHER_SCREEN":
      return {
        ...state,
        showPopupFenetreAvertissementMonitoringCampagne: true,
        activateNavigationPopupFenetreAvertissementMonitoringCampagne: true,
        navigateTo: action.payload,
      };
    case "SET_COUTS_COLONNES_DYNAMIQUES":
      return {
        ...state,
        showCoutsColonnesDynamiques: !state.showCoutsColonnesDynamiques,
      };
    case "CLEAR_COUTS_COLONNES_DYNAMIQUES":
      return {
        ...state,
        showCoutsColonnesDynamiques: false,
      };
    case "HIDE_POPUP_SESSION_EXPIRED":
      return {
        ...state,
        showPopupSessionExpired: false,
      };
    case "SHOW_POPUP_SESSION_EXPIRED":
      return {
        ...state,
        showPopupSessionExpired: true,
      };
    case "UNSET_ALERT_GENERAL_ERROR_MESSAGE":
      return {
        ...state,
        generalErrorMessage: action.payload,
      };
    case "SET_ALERT_GENERAL_ERROR_MESSAGE":
      return {
        ...state,
        generalErrorMessage: action.payload,
      };
    case "HIDE_ALERT_GENERAL_ERROR_MESSAGE":
      return {
        ...state,
        showGeneralError: false,
      };
    case "SHOW_ALERT_GENERAL_ERROR_MESSAGE":
      return {
        ...state,
        showGeneralError: true,
      };

    case "HIDE_ALERT_MSG_NOT_CONNECTED_FB":
      return {
        ...state,
        showMessageNotConnectedFB: false,
      };
    case "SHOW_ALERT_MSG_NOT_CONNECTED_FB":
      return {
        ...state,
        showMessageNotConnectedFB: true,
      };
    case "HIDE_ALERT_MSG_BUDGET_INFOS_GEN":
      return {
        ...state,
        showAlertMsgBudgetInfosGen: false,
      };
    case "SHOW_ALERT_MSG_BUDGET_INFOS_GEN":
      return {
        ...state,
        showAlertMsgBudgetInfosGen: true,
      };
    case "HIDE_ALERT_MSG_BUDGET_ADSET":
      return {
        ...state,
        showAlertMsgBudgetAdset: false,
      };
    case "SHOW_ALERT_MSG_BUDGET_ADSET":
      return {
        ...state,
        showAlertMsgBudgetAdset: true,
      };
    case "HIDE_ALERT_MSG_PACKAGE_PUB_BACK":
      return {
        ...state,
        showAlertMsgPackagePub: false,
      };
    case "SHOW_ALERT_MSG_PACKAGE_PUB_BACK":
      return {
        ...state,
        showAlertMsgPackagePub: true,
      };
    case "SHOW_MESSAGE_MSG_BUDGET_ADSET":
      return {
        ...state,
        showMsgBudgetAdset: true,
      };
    case "HIDE_MESSAGE_MSG_BUDGET_ADSET":
      return {
        ...state,
        showMsgBudgetAdset: false,
      };
    case "CLOSE_MODAL_FILTER_INSIGHTS_BY_DATE":
      return {
        ...state,
        showModalFilterInsightsByDate: false,
      };
    case "SHOW_MODAL_FILTER_INSIGHTS_BY_DATE":
      return {
        ...state,
        showModalFilterInsightsByDate: true,
      };
    case "SET_TOGGLE_LEFT_MENU":
      return {
        ...state,
        toggleLeftMenu: !state.toggleLeftMenu,
      };
    case "SET_LAST_PAGE":
      return {
        ...state,
        lastPage: action.payload
      }
    case "SET_EMPTY_NAME":
      return {
        ...state,
        emptyNameError: true,
      };
    case "CLEAN_EMPTY_NAME":
      return {
        ...state,
        emptyNameError: false,
      };
    case "SET_EMPTY_ZONES_GEOS":
      return {
        ...state,
        zonesGéosError: true,
      };
    case "CLEAN_EMPTY_ZONES_GEOS":
      return {
        ...state,
        zonesGéosError: false,
      };
    case "SET_FILTER_DATES_LIST_CAMPAIGNS":
      return {
        ...state,
        dates: {
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
        },
      };
    default:
      return state;
  }
};
