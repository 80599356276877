import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import "./style.css";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { saveCampaignNextPage } from "../../store/actions/utils/campaignCreation";

const RenderTagOption = ({
  option,
  updatingCreas=false,
  deactivatingCreas=false,
  disabledActions=false

}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  
  const deleteTag = () => {
    if (updatingCreas){
      dispatch({ type: "REMOVE_PACKAGE_PUB_UPDATE", payload: option });
    }
    else if (deactivatingCreas){
      dispatch({ type: "REMOVE_PACKAGE_PUB_DEACTIVATE", payload: option });
    }
    else{
      dispatch({ type: "REMOVE_PACKAGE_PUB", payload: option });
    }
  };

  return (
    <div className="render_tags_option">
      <p>{option}</p>
      {!disabledActions && (
        <React.Fragment>
          {!deactivatingCreas &&
          <span
            className="circle"
            onClick={() => {
              dispatch({type:"SET_STATE_PROTECT_DETAILLED_ADSETS",payload:true});
              saveCampaignNextPage(dispatch, location);
              navigate(
                `/creation-package-pub?tagToEdit=${encodeURIComponent(option)}`,
                {
                  state: { edit: true },
                }
              );
              dispatch({
                type: "SET_TAGS_TO_EDIT",
                payload: option,
              });
            }}
          >
             <EditIcon fontSize="30" />
          </span>}
          <span className="circle" onClick={deleteTag}>
            <CloseIcon fontSize="30" />
          </span>
        </React.Fragment>
      )}
    </div>
  );
};
export default RenderTagOption;
