import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

const Loader3 = () => {
  return (
    <div style={{ padding: 20, display: "flex", justifyContent: "center" }}>
      <CircularProgress color='secondary' />
    </div>
  );
};

export default Loader3;
