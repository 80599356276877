import React from "react";
import { estObjetVide, numberWithSpaces } from "../../utils";

const InsightsItemDetailsCampagne = ({
  item: {
    campaign_name,
    spend,
    impressions,
    campaign_id,
    rate_per_inline_link_clicks,
    roas_omni_purchase_value,
  },
  setSelectedCampaign,
  isSelected=false
}) => {

  return (
    <div
      onClick={() => setSelectedCampaign(campaign_id)}
      className={`listing_item_campaign ${isSelected && "selected"}`}
    >
      <h3 className="listing_item_title">{campaign_name}</h3>
      <div className="listing_item_campaign_content">
        <div className="montant">
          <h4>montant dépensé</h4>
          <div className="value">
            <p>{numberWithSpaces(spend) + "€"}</p>
          </div>
        </div>
        <div className="impressions">
          <h4>impressions</h4>
          <div className="value">
            <p>{numberWithSpaces(impressions)}</p>
          </div>
        </div>
        <div className="ctr">
          <h4>CTR</h4>
          <div className="value">
            <p>
              {!rate_per_inline_link_clicks
                ? "-"
                : numberWithSpaces(rate_per_inline_link_clicks * 100) + "%"}
            </p>
          </div>
        </div>
        <div className="ROAS">
          <h4>ROAS</h4>
          <div className="value">
            <p>
              {!roas_omni_purchase_value
                ? "-"
                : numberWithSpaces(roas_omni_purchase_value) + "€"}
            </p>
          </div>
        </div>
      </div>
      <div className="item_campaign_actions">
        <div className="item_campaign_actions_container">
          <img src="/item_campaign/edit.png" alt="" />
          <img src="/item_campaign/view.png" alt="" />
          <img src="/item_campaign/duplicate.png" alt="" />
        </div>
      </div>
    </div>
  );
};

export default InsightsItemDetailsCampagne;
