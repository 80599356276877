import React from "react";
import axiosInstance from "../../../../store/axios.instance";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const AdItem = ({ pub, getPublicitesExistantes }) => {
  const { adaccountId } = useSelector((state) => state.auth.user);

  const deletePub = async (ad_id) => {
    // dispatch({
    //   type: "DELETE_SINGLE_PUBLICITE_EXISTANTE_CREATION_PACKAGE_PUB",
    //   payload: id,
    // });
    if (!adaccountId) {
      toast.error("Aucun compte publicitaire choisi !");
      return;
    }

    if (window.confirm("Voulez-vous vraiment supprimer cette publicité ?")) {
      try {
        const response = await axiosInstance.delete(
          `${adaccountId}/CreatedAds/${ad_id}`
        );

        if (response.status === 200) {
          toast.success("Publicité supprimée avec succès");

          getPublicitesExistantes();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const deleteTag = async (pub, tg) => {
    if (window.confirm("Voulez-vous vraiment supprimer ce tag ?")) {
      const ad = pub;
      let newkeywords = ad.keywords.filter((keyword) => keyword !== tg);

      ad["keywords"] = newkeywords;

      try {
        const responseUpdate = await axiosInstance.post(
          `/${adaccountId}/CreatedAds`,
          ad
        );

        if (responseUpdate.status === 200) {
          toast.success("Tag supprimé !");

          getPublicitesExistantes();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const { nomPackage } = useSelector((state) => state.creationPackagePublicite);

  const unSelectElement = async () => {
    if (!nomPackage) {
      return;
    }

    console.log("click");

    if (pub && pub.isSelected) {
      if (
        window.confirm(
          "En déselectionnant, vous enleverez cette publicité de la liste"
        )
      ) {
        const ad = pub;
        let newkeywords = ad.keywords.filter(
          (keyword) => keyword !== nomPackage
        );

        ad["keywords"] = newkeywords;

        try {
          const responseUpdate = await axiosInstance.post(
            `/${adaccountId}/CreatedAds`,
            ad
          );

          if (responseUpdate.status === 200) {
            toast.success("Tag supprimé !");

            setTimeout(() => {
              getPublicitesExistantes();
            }, 500);
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  return (
    <div>
      <div
        onClick={unSelectElement}
        className={`publicite_existante ${pub.isSelected ? "selected" : ""}`}
      >
        <i
          onClick={(e) => {
            e.stopPropagation();
            deletePub(pub.id);
          }}
          className="fas fa-times delete_pub"
        ></i>
        <h4 className="publicite_existante_title publicite_existante_title_1">
          Nom: {pub.name}
        </h4>
        <h5 className="publicite_existante_title">Post ID: {pub.id}</h5>
      </div>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <h6 className="publicite_existante_title">
          <a
            className="link_preview"
            rel="noopener noreferrer"
            target="_blank"
            href={pub.preview_shareable_link}
          >
            Prévisualiser la publicité
          </a>
        </h6>
      </div>
      <div className="image_container">
        <img src={pub.creative.thumbnail_url} alt="Patisserie blanc" />
      </div>
      <div className="tags">
        {pub.keywords.length > 0 ? (
          pub.keywords.map((tg, i) => (
            <div key={i} className="tag">
              <p>{tg}</p>
              <i
                onClick={(e) => {
                  e.stopPropagation();
                  deleteTag(pub, tg);
                }}
                className="fas fa-times"
              ></i>
            </div>
          ))
        ) : (
          <h6>Aucun tag pour l'instant</h6>
        )}
      </div>
    </div>
  );
};
export default AdItem;
