import React, { useState } from "react";
import Loader3 from "../Loader3/Loader3";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";
import CampaignMonitoring from "./CampaignMonitoring";
import Box from "@mui/material/Box";

const Monitoring = ({ monitoring, loaderCampaigns }) => {
  const [value, setValue] = useState([]);
  const [ads, setAds] = useState(false);
  const [adsets, setAdsets] = useState(false);
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("")

  const { campaigns } = useSelector((state) => state.configurationMonitoring);
  // Convert monitoring.campaigns to an array of names
  const namesToRemove = monitoring.campaigns.map((item) => item.name);

  // Filter campaigns to remove items with matching names
  const filteredCampaigns = campaigns.filter(
    (item) => !namesToRemove.includes(item.name)
  );

  const handleChangeCampaignToAdd = (e, newValue) => {
    //setting value when it is actually nt used and only campaigns is used doesn't really makes sense
    setValue(newValue);

    dispatch({ type: "SET_START_SETTING_STRATEGY_MONITORING_CAMPAGNE" });

    const campaignsToAdd = newValue.map((item) => ({
      id: item.id,
      name: item.name,
      monitor_ads: ads,
      monitor_adsets: adsets,
    }));

    dispatch({
      type: "ADD_CAMPAIGNS_TO_MONITORING_MONITORING_CAMPAIGNE",
      payload: {
        id: monitoring.id,
        campaignsToAdd: campaignsToAdd,
      },
    });

    setValue([]);
    setAds(false);
    setAdsets(false);
  };

  const intl = useIntl();

  const addCampaignToList = () => {
    dispatch({ type: "SET_START_SETTING_STRATEGY_MONITORING_CAMPAGNE" });

    if (value.length === 0) {
      toast.error(
        intl.formatMessage({
          defaultMessage: "Veuillez choisir une ou plusieurs campagnes",
          id: "choose_campaigns_msg_NbV570",
        })
      );
      return;
    }

    const campaignsToAdd = value.map((item) => ({
      id: uuidv4(),
      name: item,
      monitor_ads: ads,
      monitor_adsets: adsets,
    }));

    dispatch({
      type: "ADD_CAMPAIGNS_TO_MONITORING_MONITORING_CAMPAIGNE",
      payload: {
        id: monitoring.id,
        campaignsToAdd: campaignsToAdd,
      },
    });

    setValue([]);
    setAds(false);
    setAdsets(false);
  };

  return (
    <tr>
      <td>
        <div className="line">
          <span className="circle"></span> <p>{monitoring.name}</p>
        </div>
      </td>
      <td style={{ width: "60%" }}>
        <div onClick={(e) => e.stopPropagation()} className="list_campaigns">
          {loaderCampaigns ? (
            <Loader3 />
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div className="strategy_campaigns">
                {monitoring.campaigns &&
                  monitoring.campaigns.length > 0 &&
                  monitoring.campaigns.sort((a, b) => a.name.localeCompare(b.name)).map((item) => (
                    <CampaignMonitoring monitoring={monitoring} item={item} />
                  ))}
              </div>
              <div className="autocomplete_button">
                <Autocomplete
                  multiple
                  style={{ width: "95%" }}
                  onChange={handleChangeCampaignToAdd}
                  value={value}
                  disableCloseOnSelect
                  id="tags-standard"
                  options={filteredCampaigns}
                  onClose={()=>setInputValue("")}
                  inputValue={inputValue}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.id} >
                      <span>
                        {option.effective_status === 'ACTIVE' ? (
                          <span style={{ color: 'green', fontSize: '10px', marginRight: 8}}>&#x2B24;</span>
                        ) : (
                          <span style={{ color: '#ddd', fontSize: '10px', marginRight: 8}}>&#x2B24;</span>
                        )}
                      </span>
                      {option.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onChange={(event)=>setInputValue(event.target.value)}
                      variant="standard"
                      label="Choisir une campagne"
                    />
                  )}
                />
                {value.length !== 0 && (
                  <button
                    onClick={addCampaignToList}
                    className="add"
                  >
                    {intl.formatMessage({
                      defaultMessage: "Ajouter",
                      id: "add_msg_169QuY",
                    })}
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </td>
    </tr>
  );
};
export default Monitoring;
