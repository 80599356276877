import React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";

import "./style.css";
// import SocialAccounts from "../SocialAccounts/SocialAccounts";

const TopRightMenu = ({ redirectBack, disabledAccounts=false }) => {
  const navigate = useNavigate();
  const { logout } = useAuth0();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filterText, setFilterText] = React.useState("");

  const dispatch = useDispatch();

  const { connectedAccounts } = useSelector((state) => state.auth.user);
  // console.log("connectedAccounts", connectedAccounts);
  const { startSetting } = useSelector((state) => state.monitoringCampagne);

  const open = Boolean(anchorEl);

  const handleClickButton = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectAccount = (e, acc) => {
    e.preventDefault();
    console.log("startSetting", startSetting);
    if (
      window.location.pathname === "/branchement-monitoring-campagne" &&
      startSetting
    ) {
      console.log("window.location.href", window.location.pathname);
      dispatch({
        type: "SHOW_POPUP_AVERTISSEMENT_MONITORING_CAMPAGNE",
      });
      handleClose();
    } else {
      dispatch({
        type: "SET_ADACCOUNT_CHOOSEN_AUTH",
        payload: acc.account,
      });

      dispatch({
        type: "SAVE_NAME_COMPTE_PRINCIPAL",
        payload: acc.name,
      });

      localStorage.setItem("adaccountId", acc.account);
      localStorage.setItem("adaccountName", acc.name);

      handleClose();
    }
  };

  const logoutFunc = () => {
    // Clear local storage
    localStorage.clear();

    logout({ returnTo: window.location.origin });
    localStorage.removeItem("selectedOption");
  };

  const { adaccountName } = useSelector((state) => state.auth.user);


  //this is weird, it is like it is considered as two buttons opening the same menu
  //instead of having only on single clickable button holding the toggle and
  // the account name ?
  return (
    <>
      {/*<SocialAccounts />*/}
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClickButton}
        disabled={disabledAccounts}
      >
        <i style={{ color: "black" }} className="fas fa-2x fa-user-circle"></i>
        {adaccountName && <h2 className="client_title">{adaccountName}</h2>}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        autoFocus={true}
      >
        <MenuItem
          onClick={() => {
            if (redirectBack) {
              navigate(
                "/campagne/choisir-compte?redirectBackTo=creation-campagne"
              );
            } else {
              navigate("/campagne/choisir-compte");
            }
          }}
        >
          Change ad account
        </MenuItem>
        <MenuItem onClick={logoutFunc}>Logout</MenuItem>
      </Menu>
      {!disabledAccounts && <span
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClickButton}
      >
        <i className="fa fa-chevron-down"></i>
      </span>}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        disableAutoFocusItem={false}
        autoFocus={true}
        disabled={disabledAccounts}
        >
        <div className="filter-input-wrapper">
          <TextField
            id="standard-basic"
            label={<FormattedMessage
                defaultMessage="Rechercher"
                id="top_right_menu_search_account_field"
              />
            }
            variant="standard"
            value={filterText}
            onChange={(e) => {
              setFilterText(e.target.value);
            }}
            onKeyDown={(e) => {
              //https://github.com/mui/material-ui/issues/36133
              e.stopPropagation();
            }}
            className="filter-input"
          />
        </div>
        <ul className="account-items">
          {connectedAccounts
            .filter((acc) =>
              acc.name.toLowerCase().includes(filterText.toLowerCase())
            )
            .map((acc) => (
              <li
                className="account-item"
                onClick={(e) => selectAccount(e, acc)}
                key={acc.account}
              >
                <i className="fas fa-2x fa-user-circle"></i>
                <span>{acc.name}</span>
              </li>
            ))}
        </ul>
      </Menu>
    </>
  );
};

export default TopRightMenu;
