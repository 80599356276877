//FFS this should be from the database
//it depends on the language
export const existingEvents = [
  {
    id: "comment",
    name: "Commentaires sur post",
  },
  {
    id: "Like",
    name: "Like sur post",
  },
  {
    id: "inline_link_clicks",
    name: "Clics sur lien",
  },
  {
    id: "clicks",
    name: "Clics",
  },
  {
    id: "video_view",
    name: "Vue video",
  },
  {
    id: "omni_search",
    name: "Recherche",
  },
  {
    id: "omni_view_content",
    name: "Vue contenu",
  },
  {
    id: "omni_add_to_wishlist",
    name: "Ajout à la liste d'envies",
  },
  {
    id: "omni_add_to_cart",
    name: "Mise au panier",
  },
  {
    id: "omni_initiated_checkout",
    name: "Paiement initié",
  },
  {
    id: "omni_complete_registration",
    name: "Inscription terminée",
  },
  {
    id: "omni_purchase",
    name: "Achat",
  },
  {
    id: "lead",
    name: "Prospect",
  },
  {
    id: "omni_app_install",
    name: "Installation d'application",
  },
  {
    id: "omni_activate_app",
    name: "Session dans l'application",
  },
  {
    id: "omni_rate",
    name: "Evaluation de l'application",
  },
  {
    id: "omni_level_achieved",
    name: "Niveau atteint",
  },
  {
    id: "omni_achievement_unlocked",
    name: "Succès débloqué",
  },
  {
    id: "app_custom_event.fb_mobile_add_payment_info",
    name: "Ajout d'information de paiement",
  },
  {
    id: "omni_spend_credits",
    name: "Credits dépensés dans l'application",
  },
  {
    id: "omni_spend_credits_value",
    name: "Valeur des crédits dépensés",
  },
  {
    id: "omni_initiated_checkout_value",
    name: "Valeur des paiement initiés",
  },
  {
    id: "omni_purchase_value",
    name: "Valeur des achats",
  },
  {
    id: "post_reaction",
    name: "Réaction au post",
  },
  {
    id: "landing_page_view",
    name: "Vue Landing Page",
  },
  {
    id: "onsite_conversion_post_save",
    name: "Conversion post save",
  },
];

export const filter = (items, constraints) => {
  return items.filter((item) =>
    // item => item.tags.includes(constraint)
    constraints.every((constraint) =>
      item.tags.some((obj) => obj.elem === constraint)
    )
  );
};

export function getUniqueListBy(arr, key) {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
}

export const getUniqListBySimpleArr = (arr) => {
  return arr.filter((c, index) => {
    return arr.indexOf(c) === index;
  });
};

export const randomColor = () => {
  const randomBetween = (min, max) =>
    min + Math.floor(Math.random() * (max - min + 1));
  const r = randomBetween(0, 255);
  const g = randomBetween(0, 255);
  const b = randomBetween(0, 255);
  const rgb = `rgba(${r},${g},${b},0.4)`;
  const rgba = `rgba(${r},${g},${b},1)`;

  return { rgb, rgba };
};

export const stringToColour = (str) => {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var colour = "#";
  // eslint-disable-next-line
  for (var i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 0xff;
    colour += ("00" + value.toString(16)).substr(-2);
  }
  return colour;
};

export function importFBLogin(handleStatusChange) {
  // If you do not want to add a script tag on index.html
  (function (d, s, id) {
    var js,
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
      return;
    }
    js = d.createElement(s);
    js.id = id;
    js.src = "https://connect.facebook.net/us_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
  })(document, "script", "facebook-jssdk");

  // When the Facebook js loads, it looks for a function named fbAsyncInit and invokes it if it�s there.
  window.fbAsyncInit = () => {
    // use next line if you have plugins
    // window.FB.Event.subscribe('xfbml.render', finished_rendering);

    // add listener on statusChange event
    window.FB.Event.subscribe("auth.statusChange", handleStatusChange);

    window.FB.init({
      appId: 433617747478268, //process.env.REACT_APP_FBLOGIN_APP_ID,
      // appId: "1410985032761688",
      autoLogAppEvents: false,
      cookie: true,
      xfbml: false,
      version: "v17.0",
      status: true, // check login status = fire an statusChange event
    });
  };
}

export const matchStatusNameInsights = (nameInsight) => {
  switch (nameInsight) {
    case "[SE] Conversion noel 2022 – acquisition – achats – v3":
      return "status_ok";
    case "[SE] Conversion noel 2022 – Retargeting – Engagement – v3":
      return "status_ok_2";

    case "[SE] Conversion noel 2022 – Retargeting – Engagement panier – v3":
      return "status_not_ok";
    case "[SE] Conversion noel 2022 – acquisition – content – v4":
      return "status_not_ok_2";

    default:
      return "status_ok";
  }
};

export function numberWithSpaces(x) {
  if (x) {
    if (!Number.isInteger(x)) {
      var parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      if (parts.length > 1) {
        parts[1] = parts[1].substring(0, 2);
      }
      return parts.join(".");
    } else {
      const number = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      return number;
    }
  }
}

export function randomPositiveOrNegativeNumber() {
  const value = parseInt(
    Math.round((Math.random() * 10 - Math.random() * 10) * -1)
  );
  return value ? value : 1;
}

export function sortByKeywords(list) {
  // Sépare la liste en deux : les objets avec des "keywords" et ceux sans
  const withKeywords = list.filter(
    (item) => item.keywords !== undefined && item.keywords.length >= 0
  );
  const withoutKeywords = list.filter((item) => item.keywords === undefined);

  // Fusionne les deux listes triées dans l'ordre alphabétique
  const sortedList = [...withKeywords, ...withoutKeywords];

  return sortedList;
}

export function filterByKeywords(objects, tags) {
  const filteredObjects = objects.filter((object) =>
    tags.every(
      (tag) => object && object.keywords && object.keywords.includes(tag)
    )
  );
  return filteredObjects;
}

// export function filterByKeywords(objects, tag) {
//   const filteredObjects = objects.filter(object =>
//     object.keywords.some(keyword => keyword === tag)
//   );
//   return filteredObjects;
// }

// export function filterByKeywords(objList, keywords) {
//   return objList.filter(obj => {
//     // Concaténer tous les champs de l'objet en une seule chaîne de caractères
//     const objectString = Object.values(obj).join(" ").toLowerCase();

//     // Vérifier si un des mots-clés est présent dans la chaîne de caractères
//     return keywords.some(keyword =>
//       objectString.includes(keyword.toLowerCase())
//     );
//   });
// }

export function checkAdset(array) {
  for (let i = 0; i < array.length; i++) {
    if (parseInt(array[i].adset) <= 0) {
      return false;
    }
  }
  return true;
}

export function estObjetVide(objet) {
  return Object.keys(objet).length === 0 && objet.constructor === Object;
}

export function generateRandomNumber() {
  // Génère un nombre aléatoire entre 0 et 9999
  let randomNumber = Math.floor(Math.random() * 10000);

  // Convertit le nombre en chaîne de caractères
  let randomNumberString = randomNumber.toString();

  // Ajoute des zéros devant le nombre si nécessaire
  while (randomNumberString.length < 4) {
    randomNumberString = "0" + randomNumberString;
  }

  // Renvoie le nombre aléatoire à 4 chiffres
  return randomNumberString;
}

export function autoExpand(event) {
  // Reset the height to the default value to calculate the actual scroll height
  event.target.style.height = "auto";
  // Set the height to the scroll height, plus a little extra padding
  event.target.style.height = `${event.target.scrollHeight + 10}px`;
}

export function removeDuplicatesByKey(arr, key) {
  return arr.filter(
    (obj, index, self) => index === self.findIndex((t) => t[key] === obj[key])
  );
}

export function subtractObjects(mainArray, subArray, key) {
  const subSet = new Set(subArray.map((item) => item[key]));
  return mainArray.filter((item) => !subSet.has(item[key]));
}

export function valeurExiste(tableau, valeur) {
  return tableau.indexOf(valeur) !== -1;
}

export function isValidUrl(string) {
  try {
    new URL(string);
    return true;
  } catch (err) {
    return false;
  }
}

export function addOrRemoveId(id, arr) {
  const index = arr.indexOf(id);
  if (index === -1) {
    // ID does not exist in the array, so add it
    arr.push(id);
  } else {
    // ID exists in the array, so remove it
    arr.splice(index, 1);
  }

  return arr;
}

export function filterByNames(arr) {
  return arr.filter((obj) => obj.name);
}

export function inverseCleValeur(object) {
  let finalObj = {};
  const entries = Object.entries(object);
  // eslint-disable-next-line
  entries.map((entry) => {
    const key = entry[0];
    const value = entry[1];

    finalObj[value] = key;
  });

  return finalObj;
}

export const getId = (list, givenName) => {
  const elementFound = list.find((element) => element.name === givenName);

  if (elementFound) {
    return elementFound.id;
  }
};

export const getName = (list, givenId) => {
  const elementFound = list.find((element) => element.id === givenId);

  if (elementFound) {
    return elementFound.name;
  }
};

export function sortByField(list, field, ascending = true) {
  return list.sort((a, b) => {
    if (a[field] < b[field]) {
      return ascending ? -1 : 1;
    } else if (a[field] > b[field]) {
      return ascending ? 1 : -1;
    } else {
      return 0;
    }
  });
}

export const isValidNumber = (x) => {
  let val;
  typeof x === "string" ? (val = false) : (val = true);

  return val;
};
