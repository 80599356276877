import React from "react";
import { useDispatch } from "react-redux";
import { estObjetVide } from "../../utils";
import AssociatedAssetsCmp from "../AssociatedAssetsCmp/AssociatedAssetsCmp";

const ImageItem = ({ image, showTags }) => {
  const dispatch = useDispatch();

  const setSelectedCarre = image => {
    dispatch({ type: "SET_IMAGE_CARRE_APPAIRER_VISUELS", payload: image });
  };

  const setSelectedStory = image => {
    dispatch({ type: "SET_IMAGE_STORY_APPAIRER_VISUELS", payload: image });
  };

  return (
    <div
      className={`image_item ${image.isSelected && "selected"}`}
      style={showTags ? { width: "48%" } : {}}
    >
      <div className='left' style={!showTags ? { width: "100%" } : {}}>
        <div className='image_title'>
          <h4 className='title'>
            {image.name.length < 30
              ? image.name
              : `${image.name.slice(0, 30)}...`}
          </h4>
          {image.associated_assets && !estObjetVide(image.associated_assets) ? (
            <AssociatedAssetsCmp />
          ) : null}
        </div>
        <div className='image'>
          <img src={image.url} alt={image.name} />
        </div>
      </div>

      <div className='image_item_actions'>
        <button onClick={() => setSelectedCarre(image)}>
          Appairer visuel carré
        </button>
        <button onClick={() => setSelectedStory(image)}>
          Appairer visuel story
        </button>
      </div>
    </div>
  );
};

export default ImageItem;
