import { getUniqueListBy } from "../../utils";

const initialState = {
  strategiesCampagnes: [],
  startSetting: false,
};

export const monitoringCampagneReducer = (state = initialState, action) => {
  switch (action.type) {
    case "HIDE_TOP_SECTION_CAMPAIGNS_BRANCHEMENT_MONITORING_CAMPAGNE":
      return {
        ...state,
        strategiesCampagnes: state.strategiesCampagnes.map(item => {
          return {
            ...item,
            campaigns: item.campaigns.map(camp => {
              return {
                ...camp,
                showTopSection: false,
              };
            }),
          };
        }),
      };
    case "TOGGLE_TOP_SECTION_CAMPAIGN_BRANCHEMENT_MONITORING_CAMPAGNE":
      return {
        ...state,
        strategiesCampagnes: state.strategiesCampagnes.map(item => {
          if (item.id === action.payload.monitoringId) {
            return {
              ...item,
              campaigns: item.campaigns.map(camp => {
                if (camp.id === action.payload.campaignId) {
                  return {
                    ...camp,
                    showTopSection: !camp.showTopSection,
                  };
                } else
                  return {
                    ...camp,
                    showTopSection: false,
                  };
              }),
            };
          } else return item;
        }),
      };
    case "CHANGE_ADS_CAMPAIGN_BRANCHEMENT_MONITORING_CAMPAGNE":
      return {
        ...state,
        strategiesCampagnes: state.strategiesCampagnes.map(item => {
          if (item.id === action.payload.monitoringId) {
            return {
              ...item,
              campaigns: item.campaigns.map(camp => {
                if (camp.id === action.payload.campaignId) {
                  return {
                    ...camp,
                    monitor_ads: action.payload.value,
                  };
                } else return camp;
              }),
            };
          } else return item;
        }),
      };
    case "CHANGE_ADSETS_CAMPAIGN_BRANCHEMENT_MONITORING_CAMPAGNE":
      return {
        ...state,
        strategiesCampagnes: state.strategiesCampagnes.map(item => {
          if (item.id === action.payload.monitoringId) {
            return {
              ...item,
              campaigns: item.campaigns.map(camp => {
                if (camp.id === action.payload.campaignId) {
                  return {
                    ...camp,
                    monitor_adsets: action.payload.value,
                  };
                } else return camp;
              }),
            };
          } else return item;
        }),
      };
    case "EDIT_CAMPAIGN_TO_MONITORING_MONITORING_CAMPAIGNE":
      return {
        ...state,
        strategiesCampagnes: state.strategiesCampagnes.map(item => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              campaigns: item.campaigns.map(camp => {
                if (camp.name === action.payload.campaignToEdit.name) {
                  return {
                    ...camp,
                    name: action.payload.campaignToEdit.name,
                    monitor_ads: action.payload.campaignToEdit.monitor_ads,
                    monitor_adsets:
                      action.payload.campaignToEdit.monitor_adsets,
                  };
                } else return camp;
              }),
            };
          } else return item;
        }),
      };
    case "UNSET_START_SETTING_STRATEGY_MONITORING_CAMPAGNE":
      return {
        ...state,
        startSetting: false,
      };
    case "SET_START_SETTING_STRATEGY_MONITORING_CAMPAGNE":
      return {
        ...state,
        startSetting: true,
      };
    case "DELETE_CAMPAIGNS_TO_MONITORING_MONITORING_CAMPAIGNE":
      return {
        ...state,
        strategiesCampagnes: state.strategiesCampagnes.map(item => {
          if (item.id === action.payload.monitoringId) {
            return {
              ...item,
              campaigns: item.campaigns.filter(
                item => item.id !== action.payload.campaignId
              ),
            };
          } else return item;
        }),
      };
    case "ADD_CAMPAIGNS_TO_MONITORING_MONITORING_CAMPAIGNE":
      return {
        ...state,
        strategiesCampagnes: state.strategiesCampagnes.map(item => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              campaigns: getUniqueListBy(
                [...action.payload.campaignsToAdd, ...item.campaigns],
                "name"
              ),
            };
          } else return item;
        }),
      };
    case "GET_STRATEGIES_CAMPAGNES":
      return {
        ...state,
        strategiesCampagnes: action.payload.map(strategy => ({
          ...strategy,
          campaigns: strategy.campaigns.map(campaign => ({
            ...campaign,
            showTopSection: false,
          })),
        })),
      };
    default:
      return state;
  }
};
