import {
  estObjetVide,
  existingEvents,
  getName,
  getUniqueListBy,
} from "../../utils";
import axiosInstance from "../axios.instance";
import { v4 as uuidv4 } from "uuid";

export const getEvenements = (adaccount, lang) => async (dispatch) => {
  try {
    let events = [];

    const resultEvents = await axiosInstance.get(
      `/${adaccount}/monitoring_events?lang=${lang}`
    );

    if (resultEvents.status === 200) {
      events = resultEvents.data;

      events = events.map((item) => ({
        ...item,
        isChecked:
          item.id === "inline_link_clicks" || item.id === "omni_purchase"
            ? true
            : false,
        uniq_id: uuidv4(),
      }));

      const inlineLinkClickColumnExist = events.find(
        (event) => event.id === "inline_link_clicks"
      );

      const omniPurchaseValueColumnExist = events.find(
        (event) => event.id === "omni_purchase_value"
      );

      if (inlineLinkClickColumnExist) {
        events.push({
          isChecked: true,
          id: "rate_per_inline_link_clicks",
          name: "CTR",
          uniq_id: uuidv4(),
        });
      }

      if (omniPurchaseValueColumnExist) {
        events.push({
          isChecked: true,
          id: "roas_omni_purchase_value",
          name: "ROAS",
          uniq_id: uuidv4(),
        });
      }
    }

    const result1 = await axiosInstance.get(`/${adaccount}/priorisation`);

    const priorisation = result1.data;

    let eventsPriorisation = [];

    if (priorisation && !estObjetVide(priorisation)) {
      if (priorisation.hasOwnProperty("events")) {
        eventsPriorisation = priorisation.events.map((event) => event.name);

        eventsPriorisation = eventsPriorisation.map((item) => ({
          isChecked: true,
          id: item,
          name: getName(existingEvents, item),
          uniq_id: uuidv4(),
        }));
      }
    }

    dispatch({
      type: "GET_EVENTS_LISTE_CAMPAGNES_SUCCESS",
      payload: getUniqueListBy([...events, ...eventsPriorisation], "name"),
    });
  } catch (error) {
    dispatch({
      type: "GET_EVENTS_LISTE_CAMPAGNES_SUCCESS",
      payload: [],
    });
  }
};
