import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Alert from "@mui/material/Alert";

const ZonesGeoElement = ({ data, excluded, type }) => {
  const dispatch = useDispatch();
  const [errorRadius, setErrorRadius] = useState(null);

  const handleChangeInput = e => {
    const parsedIntValue = parseInt(e.target.value);

    if (parsedIntValue < 17 || parsedIntValue > 80) {
      if (parsedIntValue !== 0) {
        setErrorRadius(true);
      }
    } else {
      setErrorRadius(false);
    }

    switch (type) {
      case "ville":
        dispatch({
          type: "SET_RADIUS_VILLE_SELECTIONNE",
          payload: { id: data.id, value: parsedIntValue },
        });
        break;
      case "villeExclue":
        dispatch({
          type: "SET_RADIUS_VILLE_EXCLUE_SELECTIONNE",
          payload: { id: data.id, value: parsedIntValue },
        });
        break;
      default:
        break;
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <div className={`element ${excluded ? "excluded" : ""}`}>
        <h5 className='element_title'>{data.name}</h5>
        {(type === "ville" || type === "villeExclue") && (
          <input
            style={
              errorRadius === true
                ? { border: "1px solid red" }
                : { border: "1px solid green" }
            }
            type='number'
            value={data.radius}
            onChange={handleChangeInput}
          />
        )}
        {type !== "region" || type !== "regionExclue" ? (
          <span className='unit'>Km</span>
        ) : null}
        {/* <i onClick={deleteZone} className='fas fa-times'></i> */}
      </div>
      {errorRadius && (
        <Alert severity='error'>le rayon doit être 0, ou entre 17 et 80</Alert>
      )}
    </div>
  );
};

export default ZonesGeoElement;
