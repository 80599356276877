import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loader3 from "../../../../components/Loader3/Loader3";
import axiosInstance from "../../../../store/axios.instance";
import { autoExpand } from "../../../../utils";
import UtmItem from "./UtmItem";
import Switch from "@mui/material/Switch";
import SearchAsset from "../../../../components/SearchAsset/SearchAsset";

const UTM = ({ getUtms, loaderUtms }) => {
  const [toggle, setToggle] = useState(false);
  const [newText, setNewText] = useState("");
  const [showTags, setShowTags] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const dispatch = useDispatch();

  const handleNewTextChange = e => {
    setNewText(e.target.value);
  };

  const saveNewText = e => {
    // if (e.key === "Enter") {
    //   if (!newText) {
    //     toast.error("Veuillez insérer une valeur non vide");
    //     return;
    //   }

    //   const dataToSend = {
    //     content: newText,
    //     keywords: [],
    //   };

    //   setLoader(true);

    //   axiosInstance
    //     .put(`/${adaccountId}/utm`, dataToSend)
    //     .then(res => {
    //       dispatch({
    //         type: "PUT_UTM_CREATION_PACKAGE_PUBLICTE_SUCCESS",
    //         payload: res.data.data,
    //       });

    //       toast.success("Utm crée avec succès");

    //       setLoader(false);
    //     })
    //     .catch(err => {
    //       dispatch({ type: "PUT_UTM_CREATION_PACKAGE_PUBLICTE_FAIL" });
    //       console.log(err);
    //       setLoader(false);
    //     });

    //   setNewText("");
    // }

    if (e.key === "Enter" && e.shiftKey) {
      e.preventDefault();
      setNewText(e.target.value + "\n");
    }
  };

  const saveNewTextOnClick = e => {
    if (!newText) {
      toast.error("Veuillez insérer une valeur non vide");
      return;
    }

    const dataToSend = {
      content: newText,
      keywords: [],
    };

    setLoader(true);

    axiosInstance
      .put(`/${adaccountId}/utm`, dataToSend)
      .then(res => {
        dispatch({
          type: "PUT_UTM_CREATION_PACKAGE_PUBLICTE_SUCCESS",
          payload: res.data.data,
        });

        toast.success("Utm crée avec succès");

        setLoader(false);
      })
      .catch(err => {
        dispatch({ type: "PUT_UTM_CREATION_PACKAGE_PUBLICTE_FAIL" });
        console.log(err);
        setLoader(false);
      });

    setNewText("");
  };

  const {
    utms,
    originalData: { utms: utmsOriginaux },
  } = useSelector(state => state.creationPackagePublicite);

  const { adaccountId } = useSelector(state => state.auth.user);
  // eslint-disable-next-line
  const [loader, setLoader] = useState(false);

  const selectedUtms = utmsOriginaux.filter(item => item && item.isSelected);
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!searchParams.get("keep-data")) {
      getUtms(adaccountId);
    }
  }, []);

  useEffect(() => {
    if (toggle && selectedUtms.length > 0) {
      dispatch({ type: "SHOW_SELECTED_UTMS_FIRST" });
    }
  }, [toggle]);

  const searchUtms = e => {
    setSearchValue(e.target.value);

    dispatch({ type: "SEARCH_UTMS", payload: e.target.value });
  };

  return (
    <section className='section section_texte'>
      <header
        onClick={() => setToggle(prevState => !prevState)}
        className='section_header'
      >
        <div className='df'>
          <h3 className='section_header_title'>
            <FormattedMessage
              defaultMessage='UTM'
              id='creation_package_pub_section_utm_1'
            />

            {selectedUtms.length > 0 && (
              <span className='nbr'>{selectedUtms.length}</span>
            )}
          </h3>
        </div>
        <i className={`fas fa-chevron-${toggle ? "up" : "down"}`}></i>
      </header>
      {toggle && (
        <div className='section_content'>
          <SearchAsset value={searchValue} onChange={searchUtms} />
          <div className='wrapper'>
            <div className='add_text'>
              <textarea
                onInput={autoExpand}
                value={newText}
                onKeyDown={saveNewText}
                onChange={handleNewTextChange}
                placeholder='Ajouter un UTM'
              ></textarea>
              {/* <input
                value={newText}
                onKeyDown={saveNewText}
                onChange={handleNewTextChange}
                placeholder='Ajouter un UTM'
                type='text'
              /> */}
              <i onClick={saveNewTextOnClick} className='fas fa-plus'></i>
            </div>
            <div className='tags'>
              <i className='fas fa-info-circle'></i>
              <p>Tags</p>
              <Switch
                onChange={() => setShowTags(prevState => !prevState)}
                checked={showTags}
              />
            </div>
          </div>
          <div className='list_texts'>
            {loaderUtms ? (
              <Loader3 />
            ) : utms && utms.length > 0 ? (
              utms.map((texte, i) => (
                <UtmItem
                  key={i}
                  texte={texte}
                  getUtms={getUtms}
                  showTags={showTags}
                />
              ))
            ) : (
              <h6>
                <FormattedMessage
                  defaultMessage="Aucun UTM pour l'instant"
                  id='creation_package_pub_section_utm_2'
                />
              </h6>
            )}
          </div>
        </div>
      )}
    </section>
  );
};

export default UTM;
