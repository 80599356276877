const initialState = {
  isEmptyName: true,
  isEmptyType: true,
  isPexelRequierd: false,
  isEmptyPackagePub: false,
  isEmptyPackegeGeoDemo: false,
  companyNameCharset: false,
  notValidInfoGeneral: false,
};

export const validationReducer = (state = initialState, action) => {
  switch (action.type) {
    case "IS_VALID_NAME":
      return {
        ...state,
        isEmptyName: false,
      };
    case "IS_NOT_VALID_NAME":
      return {
        ...state,
        isEmptyName: true,
      };
    case "IS_VALID_TYPE":
      return {
        ...state,
        isEmptyType: false,
      };
    case "IS_NOT_VALID_TYPE":
      return {
        ...state,
        isEmptyType: true,
      };
    case "IS_PIXEL_REQUIERD":
      return {
        ...state,
        isPexelRequierd: true,
      };
    case "IS_NOT_PIXEL_REQUIERD":
      return {
        ...state,
        isPexelRequierd: false,
      };
    case "IS_EMPTY_PACKAGE_PUB":
      return {
        ...state,
        isEmptyPackagePub: true,
      };
    case "IS_NOT_EMPTY_PACKAGE_PUB":
      return {
        ...state,
        isEmptyPackagePub: false,
      };
    case "COMPANY_NAME_CHARSET":
      return {
        ...state,
        companyNameCharset: true,
      };
    case "IS_NOT_COMPANY_NAME_CHARSE":
      return {
        ...state,
        companyNameCharset: false,
      };
    case "IS_EMPTY_PACKAGE_GEO_DEMO":
      return {
        ...state,
        isEmptyPackegeGeoDemo: true,
      };
    case "IS_NOT_EMPTY_PACKAGE_GEO_DEMO":
      return {
        ...state,
        isEmptyPackegeGeoDemo: false,
      };
    case "IS_NOT_VALID_INFO_GENERAL_SECTION":
      return {
        ...state,
        notValidInfoGeneral: true,
      };
    case "IS_VALID_INFO_GENERAL_SECTION":
      return {
        ...state,
        notValidInfoGeneral: false,
      };

    default:
      return state;
  }
};
