import { v4 as uuidv4 } from "uuid";

const initialState = {
  campaigns: [],
  monitorings: [],
};

export const configurationMonitoringReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "UPDATE_SINGLE_MONITORING_CHANGE_NAME":
      return {
        ...state,
        monitorings: state.monitorings.map((mon) => {
          if (mon.id === action.payload.id) {
            return {
              ...mon,
              name: action.payload.value,
            };
          } else return mon;
        }),
      };
    case "UPDATE_SINGLE_MONITORING_ADD_EVENT":
      return {
        ...state,
        monitorings: state.monitorings.map((mon) => {
          if (mon.id === action.payload) {
            const newKey = `new_event_${uuidv4()}`;
            mon["equivalency"][newKey] = 1;
            return mon;
          } else return mon;
        }),
      };
    case "DELETE_SINGLE_EQUIVALENCY_MONITORING":
      return {
        ...state,
        monitorings: state.monitorings.map((mon) => {
          if (mon.id === action.payload.id) {
            const updatedEquivalency = { ...mon.equivalency };
            delete updatedEquivalency[action.payload.key];
            return { ...mon, equivalency: updatedEquivalency };
          } else {
            return mon;
          }
        }),
      };
    case "UPDATE_SINGLE_MONITORING_CHANGE_EVENT":
      return {
        ...state,
        monitorings: state.monitorings.map((mon) => {
          if (mon.id === action.payload.id) {
            const oldObj = mon.equivalency;

            const keys = Object.keys(oldObj);
            const values = Object.values(oldObj);

            const oldkey = action.payload.name;
            const newKey = action.payload.value;

            const newKeys = keys.map((key) => {
              if (key === oldkey) {
                return newKey;
              } else {
                return key;
              }
            });

            let finalEquivalencyObject = {};
            // eslint-disable-next-line
            newKeys.map((key, index) => {
              finalEquivalencyObject[key] = values[index];
            });

            return {
              ...mon,
              equivalency: finalEquivalencyObject,
            };
          } else return mon;
        }),
      };
    case "UPDATE_SINGLE_MONITORING_EQUIVALENCY_ITEM":
      return {
        ...state,
        monitorings: state.monitorings.map((mon) => {
          if (mon.id === action.payload.id) {
            return {
              ...mon,
              equivalency: {
                ...mon.equivalency,
                [action.payload.name]: action.payload.value,
              },
            };
          } else return mon;
        }),
      };
    case "UPDATE_SINGLE_MONITORING_DEFAULT_APPLY_ON":
      return {
        ...state,
        monitorings: state.monitorings.map((mon) => {
          if (mon.id === action.payload.id) {
            return {
              ...mon,
              default_apply_on: action.payload.value,
            };
          } else return mon;
        }),
      };
    case "UPDATE_SINGLE_MONITORING_COMMENT":
      return {
        ...state,
        monitorings: state.monitorings.map((mon) => {
          if (mon.id === action.payload.id) {
            return {
              ...mon,
              equivalency: {
                ...mon.equivalency,
                comment: action.payload.value,
              },
            };
          } else return mon;
        }),
      };
    case "UPDATE_SINGLE_MONITORING_OMNI_ADD_TO_CART":
      return {
        ...state,
        monitorings: state.monitorings.map((mon) => {
          if (mon.id === action.payload.id) {
            return {
              ...mon,
              equivalency: {
                ...mon.equivalency,
                omni_add_to_cart: action.payload.value,
              },
            };
          } else return mon;
        }),
      };
    case "UPDATE_SINGLE_MONITORING_OMNI_PURCHASE":
      return {
        ...state,
        monitorings: state.monitorings.map((mon) => {
          if (mon.id === action.payload.id) {
            return {
              ...mon,
              equivalency: {
                ...mon.equivalency,
                omni_purchase: action.payload.value,
              },
            };
          } else return mon;
        }),
      };
    case "UPDATE_SINGLE_MONITORING_INLINE_LINK_CLICKS":
      return {
        ...state,
        monitorings: state.monitorings.map((mon) => {
          if (mon.id === action.payload.id) {
            return {
              ...mon,
              equivalency: {
                ...mon.equivalency,
                inline_link_clicks: action.payload.value,
              },
            };
          } else return mon;
        }),
      };
    case "UPDATE_SINGLE_MONITORING_STRATEGY":
      return {
        ...state,
        monitorings: state.monitorings.map((mon) => {
          if (mon.id === action.payload.id) {
            return {
              ...mon,
              strategy: action.payload.value,
            };
          } else return mon;
        }),
      };
    case "GET_MONITORINGS":
      return {
        ...state,
        monitorings: action.payload,
      };
    case "GET_CAMPAIGNS_CONFIG_MONITORING":
      return {
        ...state,
        campaigns: action.payload,
      };
    default:
      return state;
  }
};
