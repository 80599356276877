import { v4 as uuidv4 } from "uuid";

//having a store for these variables makes no sense
//Not like we are changing pages between

const initialState = {
  packageGeoDemo: {
    name: "",
    ageMin: "18",
    ageMax: "65",
    genre: "Tous",
    languesChoisies: [],
    zonesSelectionnes: [],
    typeLocalisation: '["home","recent"]',
    pays: [],
    villes: [],
    villesExclues: [],
    regions: [],
    regionsExclues: [],
  },
  packageGeoDemoData: [],
  allLangs: [],
  allPays: [],
  allCites: [],
  allRegions: [],
};

export const creationPackageGeoDemoReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CLEAR_PACKAGE_GEO_DEMO":
      return initialState;
    case "SET_RADIUS_VILLE_SELECTIONNE":
      return {
        ...state,
        packageGeoDemo: {
          ...state.packageGeoDemo,
          villes: state.packageGeoDemo.villes.map((ville) => {
            if (ville.id === action.payload.id) {
              return { ...ville, radius: action.payload.value };
            } else return ville;
          }),
        },
      };
    case "SET_RADIUS_VILLE_EXCLUE_SELECTIONNE":
      return {
        ...state,
        packageGeoDemo: {
          ...state.packageGeoDemo,
          villesExclues: state.packageGeoDemo.villesExclues.map((ville) => {
            if (ville.id === action.payload.id) {
              return { ...ville, radius: action.payload.value };
            } else return ville;
          }),
        },
      };
    case "SET_INPUT_PACKAGE_GEO_DEMO":
      switch (action.payload.name) {
        case "regions":
        case "regionsExclues":
          return {
            ...state,
            packageGeoDemo: {
              ...state.packageGeoDemo,
              [action.payload.name]: action.payload.value.map((item) => ({
                ...item,
                id: uuidv4(),
              })),
            },
          };
        case "villes":
        case "villesExclues":
          const villes = state.packageGeoDemo.villes;
          return {
            ...state,
            packageGeoDemo: {
              ...state.packageGeoDemo,
              [action.payload.name]: action.payload.value.map((item, i) => ({
                ...item,
                radius:
                  villes.length > 0 && villes[i] !== undefined
                    ? villes[i].radius
                    : 0,
                id: uuidv4(),
              })),
            },
          };
        case "typeLocalisation":
          return {
            ...state,
            packageGeoDemo: {
              ...state.packageGeoDemo,
              // typeLocalisation: JSON.parse(action.payload.value),
              typeLocalisation: action.payload.value,
            },
          };
        default:
          return {
            ...state,
            packageGeoDemo: {
              ...state.packageGeoDemo,
              [action.payload.name]: action.payload.value,
            },
          };
      }
    case "SET_INPUT_PACKAGE_GEO_DEMO_ZONES":
      return {
        ...state,
        packageGeoDemo: {
          ...state.packageGeoDemo,
          zonesSelectionnes: [
            ...state.packageGeoDemo.zonesSelectionnes,
            action.payload,
          ],
        },
      };
    case "SET_LANGS":
      return {
        ...state,
        allLangs: action.payload,
      };
    case "SET_PAYS":
      return {
        ...state,
        allPays: action.payload,
      };
    case "SET_CITES":
      return {
        ...state,
        allCites: action.payload,
      };
    case "SET_REGIONS":
      return {
        ...state,
        allRegions: action.payload,
      };
    case "GET_PACKAGE_GEO_DEMO_DATA":
      return {
        ...state,
        packageGeoDemoData: action.payload,
      };
    case "EDIT_PACKAGE_GEO_DEMO_DATA":
      return {
        ...state,
        packageGeoDemo: {
          ...state.packageGeoDemo,
          [action.payload.name]: action.payload.value,
        },
      };
    default:
      return state;
  }
};
