import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axiosInstance from "../../store/axios.instance";
import Loader3 from "../../components/Loader3/Loader3";
import LeftMenu from "../../components/LeftMenu/LeftMenu";
import TopRightMenu from "../../components/TopRightMenu/TopRightMenu";
import { FormattedMessage } from "react-intl";
import RightContainer from "../../components/RightContainer/RightContainer";
import S3 from "react-aws-s3";

const CreationPackagePubAjoutImageVideoScreen = () => {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const { adaccountId } = useSelector((state) => state.auth.user);
  const [keywords, setKeywords] = useState([]);
  const [newTag, setNewTag] = useState("");

  const dispatch = useDispatch();
  const { toggleLeftMenu } = useSelector((state) => state.utils);

  const getParams = new URLSearchParams(window.location.search);
  const compagnId = getParams.get("campaign_id");

  const setToggleLeftMenu = () => {
    dispatch({ type: "SET_TOGGLE_LEFT_MENU" });
  };

  const S3_BUCKET = "adsterfbimages";
  const REGION = "eu-west-3";
  const ACCESS_KEY = "AKIA4CWJKSPZRYXBPEEH";
  const SECRET_ACCESS_KEY = "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";

  const config = {
    bucketName: S3_BUCKET,
    region: REGION,
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
  };

  const handleClickInputFile = () => {
    const inputFileImage = document.querySelector(
      ".creation_package_pub_add_image_screen .file_input_image"
    );

    inputFileImage.click();
  };

  const handleChangeInputFileImage = (e) => {
    let allFiles = [];
    const files = e.target.files;
    // eslint-disable-next-line
    Array.from(files).map((file) => {
      allFiles.push({
        file: file,
        url: URL.createObjectURL(file),
      });
    });

    dispatch({ type: "SET_ALL_RESSOURCES", payload: allFiles });
  };

  const handleRemove = (index) => {
    dispatch({ type: "REMOVE_RESSOURCE", payload: index });
  };

  const handleUpload = async (file) => {
    let newFileName = file.name.replace(/\..+$/, "");
    const ReactS3Client = new S3(config);
    await ReactS3Client.uploadFile(file, newFileName).then(async (data) => {
      if (data.status === 204) {
        const extension = data.key.split(".")[1];
        const filename = data.location;
        const name = data.key.split(".")[0];

        const dataToSend = [
          {
            filename,
            name,
            keywords,
            // keywords: [newTag],
          },
        ];

        // console.log("dataToSend", dataToSend);

        switch (extension) {
          case "jpg":
          case "jpeg":
          case "png":
            // Envoi image
            const image = await axiosInstance.put(
              `/${adaccountId}/AdImages`,
              dataToSend,
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );

            if (image.status === 200) {
              dispatch({
                type: "ADD_IMAGE_TO_LIST_ASSETS_IMAGES",
                payload: image.data[0],
              });
              dispatch({
                type: "ADD_IMAGE_TO_LIST_ASSETS_APPAIRER_VISUELS",
                payload: image.data[0],
              });
            }
            break;

          case "mp4":
            // Envoi vidéo
            const video = await axiosInstance.put(
              `/${adaccountId}/AdVideos`,
              dataToSend,
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );
            if (video.status === 200) {
              dispatch({
                type: "ADD_VIDEO_TO_LIST_ASSETS_VIDEOS",
                payload: video.data[0],
              });
              dispatch({
                type: "ADD_VIDEO_TO_LIST_ASSETS_APPAIRER_VISUELS",
                payload: video.data[0],
              });
            }
            break;
          default:
            break;
        }
      } else {
        toast.error("Une erreur est survenue !");
      }
    });
  };

  const deleteTag = (tag) =>
    setKeywords((prevState) => prevState.filter((tg) => tg !== tag));

  const saveTag = (e) => {
    if (e.key === "Enter") {
      if (!newTag) {
        toast.error("Veuillez insérer un tag");
        return;
      }

      setKeywords((prevState) => [...prevState, newTag]);

      setNewTag("");
    }
  };

  const { ressources } = useSelector((state) => state.ressources);

  const saveImage = async () => {
    if (ressources.length >= 1 && adaccountId) {
      setLoader(true);

      Promise.all(
        ressources.map(async (ressource) => {
          await handleUpload(ressource.file);
        })
      )
        .then(() => {
          setTimeout(() => {
            toast.success(
              "Toutes les ressources ont été uploadées avec succès"
            );
            setLoader(false);
            dispatch({ type: "CLEAR_RESSOURCES" });
            navigate(
              `/creation-package-pub?keep-data=true${
                compagnId ? `&campaign_id=${compagnId}` : ""
              }`
            );
          }, 500);
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
    } else {
      toast.error("Vous devez au moins sélectionner une image/vidéo");
    }
  };

  return (
    <div className="creation_package_pub_add_image_screen">
      <LeftMenu />
      <RightContainer>
        <header className="right_content_header">
          <div className="back_title">
            {toggleLeftMenu ? (
              <i
                onClick={() => setToggleLeftMenu((prevState) => !prevState)}
                style={{ marginRight: 30, cursor: "pointer" }}
                className="fas fa-bars fa-2x"
              ></i>
            ) : null}
            <div className="back">
              <Link
                to={`/creation-package-pub?keep-data=true${
                  compagnId ? `&campaign_id=${compagnId}` : ""
                }`}
              >
                <img src="/left-arrow 2.png" alt="Retour" />
              </Link>
            </div>
            <h1 className="right_content_header_title">
              <FormattedMessage
                defaultMessage="Importer une ou plusieurs ressources"
                id="screen_creation_package_pub_ajout_multimedia_1"
              />
            </h1>
          </div>
          <TopRightMenu />
        </header>
        <div className="right_content_main">
          <div className="contenu_principale">
            <div className="container">
              {loader ? (
                <Loader3 />
              ) : (
                <div className="importer_image">
                  <input
                    onChange={handleChangeInputFileImage}
                    type="file"
                    name=""
                    className="file_input_image"
                    id=""
                    multiple
                  />
                  <div
                    className="importer_image_btn"
                    onClick={handleClickInputFile}
                  >
                    <img src="/download.png" alt="" />
                  </div>
                  <div className="ressources">
                    {ressources &&
                      ressources.length > 0 &&
                      ressources.map((elem, index) => (
                        <div className="image_item" key={index}>
                          <i
                            onClick={() => handleRemove(index)}
                            className="fas fa-times delete"
                          ></i>
                          <div className="image_title">
                            <h4>{elem.file.name}</h4>
                          </div>
                          <div className="image">
                            {elem.file.type.includes("image") ? (
                              <img src={elem.url} alt="" />
                            ) : (
                              <video controls src={elem.url}></video>
                            )}
                          </div>
                        </div>
                      ))}
                  </div>
                  <div className="tags">
                    <h3>Tags</h3>
                    <div className="liste">
                      {keywords &&
                        keywords.length > 0 &&
                        keywords.map((tg) => (
                          <div className="tag">
                            <p>{tg}</p>
                            <i
                              onClick={() => deleteTag(tg)}
                              className="fas fa-times"
                            ></i>
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="search">
                    <input
                      value={newTag}
                      onKeyDown={saveTag}
                      onChange={(e) => setNewTag(e.target.value)}
                      type="search"
                      placeholder="new tag"
                    />
                  </div>

                  <button onClick={saveImage}>
                    <FormattedMessage
                      defaultMessage="Valider"
                      id="screen_creation_package_pub_ajout_multimedia_2"
                    />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </RightContainer>
    </div>
  );
};

export default CreationPackagePubAjoutImageVideoScreen;
