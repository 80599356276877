import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { inverseCleValeur } from "../../utils";

const VisuelDroiteCarousel = ({ visuel }) => {
  const dispatch = useDispatch();

  const setTextVisuel = e => {
    dispatch({
      type: "SET_TEXT_VISUEL_CAROUSEL",
      payload: {
        id: visuel.id,
        name: e.target.name,
        value: e.target.value,
      },
    });
  };

  const deleteVisuel = () => {
    dispatch({ type: "DELETE_VISUEL_CREATION_CAROUSEL", payload: visuel.id });
  };

  const { ctas } = useSelector(state => state.creationPackagePublicite);

  const {
    callToActions,
  } = useSelector(state => state.creationCampagneRef);

  const invertedCtas = inverseCleValeur(callToActions);

  return (
    <div className='carre' style={{ position: "relative" }}>
      <i
        onClick={deleteVisuel}
        style={{
          position: "absolute",
          right: 10,
          top: 10,
        }}
        className='fas fa-times delete_visuel'
      ></i>
      <h4 className='title'>{visuel.name ? visuel.name : visuel.title}</h4>
      <div className='element'>
        <div className='center'>
          {visuel.url ? (
            <img src={visuel.url} alt='' />
          ) : visuel.source ? (
            <video controls src={visuel.source}></video>
          ) : (
            <React.Fragment>
              <img className='default' src='/drag_asset.png' alt='' />
              <h5>Asset 1:1</h5>
            </React.Fragment>
          )}
        </div>
      </div>
      <div className='text'>
        <input
          onChange={setTextVisuel}
          className='block'
          type='text'
          placeholder='Titre'
          name='titre'
          value={visuel.titre}
        />
        <input
          onChange={setTextVisuel}
          className='block'
          type='text'
          placeholder='Description'
          name='description'
          value={visuel.description}
        />
        <div className='inline'>
          <input
            onChange={setTextVisuel}
            type='text'
            placeholder='Lien'
            name='lien'
            value={visuel.lien}
          />
          <select
            onChange={setTextVisuel}
            placeholder='Call to action'
            name='callToAction'
            value={visuel.callToAction.content}
          >
            <option value=''>Type de bouton</option>
            {ctas.length > 0 &&
              ctas.map(cta => (
                <option value={cta.content}>{invertedCtas[cta.content]}</option>
              ))}
          </select>
        </div>
      </div>
    </div>
  );
};
export default VisuelDroiteCarousel;
