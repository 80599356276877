import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Loader3 from "../Loader3/Loader3";

function PrivateRoute({ children }) {
  const { isAuthenticated, user, isLoading } = useAuth0();

  if (isLoading)
    return (
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          backgroundColor: "black",
          color: "white",
          fontSize: 40,
        }}
      >
        <Loader3 />
      </div>
    );

  if (!isAuthenticated || !user) {
    // not logged in so redirect to login page with the return url
    return <Navigate to="/" replace />;
  }

  // authorized so return child components
  return children;
}

export default PrivateRoute;
