import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import axiosInstance from "../../../../store/axios.instance";
import { useSearchParams } from "react-router-dom";
import { valeurExiste } from "../../../../utils";

const DescItem = ({ description, getDescriptions, showTags }) => {
  const [newTag, setNewTag] = useState("");
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const [loader, setLoader] = useState(false);
  const { adaccountId } = useSelector((state) => state.auth.user);

  const deleteDescription = (id) => {
    if (window.confirm("Voulez-vous vraiment supprimer cette description ?")) {
      axiosInstance
        .delete(`/${adaccountId}/description/${id}`)
        .then(() => {
          toast.success("Description supprimée avec succès");

          getDescriptions(adaccountId);

          setLoader(false);
        })
        .catch((err) => {
          console.log(err);
          toast.error(
            "y a eu une erreur lors de la création de la description"
          );
          setLoader(false);
        });
    }
  };

  const saveNewTag = (e, desc) => {
    if (e.key === "Enter") {
      if (!newTag) {
        toast.error("Veuillez insérer un tag");
        return;
      }

      setLoader(true);

      const dataToSend = { ...desc, keywords: [...desc.keywords, newTag] };

      axiosInstance
        .post(`/${adaccountId}/description/${desc.id}`, dataToSend)
        .then(() => {
          dispatch({
            type: "UPDATE_DESCRIPTION_CREATION_PACKAGE_PUBLICTE_SUCCESS",
          });

          toast.success("Tag ajouté avec succès");

          setLoader(false);

          dispatch({
            type: "ADD_TAG_DESCRIPTION_LOCALLY",
            payload: { id: desc.id, tag: newTag },
          });
        })
        .catch((err) => {
          dispatch({
            type: "UPDATE_DESCRIPTION_CREATION_PACKAGE_PUBLICTE_FAIL",
          });
          console.log(err);
          setLoader(false);
        });

      setNewTag("");
    }
  };

  const deleteTag = (desc, tag) => {
    if (window.confirm("Voulez-vous vraiment supprimer ce tag ?")) {
      setLoader(true);

      const dataToSend = {
        content: desc.content,
        keywords: desc.keywords.filter((item) => item !== tag),
      };

      axiosInstance
        .post(`/${adaccountId}/description/${desc.id}`, dataToSend)
        .then(() => {
          dispatch({
            type: "DELETE_TAG_DESCRIPTION_CREATION_PACKAGE_PUB",
            payload: {
              id: desc.id,
              tag,
            },
          });

          toast.success("Tag supprimé avec succès");

          setLoader(false);
        })
        .catch((err) => {
          dispatch({
            type: "UPDATE_DESCRIPTION_CREATION_PACKAGE_PUBLICTE_FAIL",
          });
          console.log(err);
          setLoader(false);
        });
    }
  };

  const handleChangeNewTag = (e) => {
    setNewTag(e.target.value);
  };
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();

  const tagToEdit = searchParams.get("tagToEdit");

  const { nomPackage } = useSelector((state) => state.creationPackagePublicite);

  const toggleSelectItem = (id) => {
    if (tagToEdit && description.isSelected) {
      if (valeurExiste(description.keywords, nomPackage)) {
        const dataToSend = {
          content: description.content,
          keywords: description.keywords.filter((item) => item !== nomPackage),
        };

        axiosInstance
          .post(`/${adaccountId}/description/${description.id}`, dataToSend)
          .then(() => {
            dispatch({
              type: "DELETE_TAG_DESCRIPTION_CREATION_PACKAGE_PUB",
              payload: {
                id: description.id,
                tag: nomPackage,
              },
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }

    dispatch({ type: "TOGGLE_SELECT_DESCRIPTION", payload: id });
  };

  return (
    <div
      onClick={() => toggleSelectItem(description.id)}
      className={`text ${description.isSelected ? "selected" : ""}`}
    >
      <i
        onClick={(e) => {
          e.stopPropagation();
          deleteDescription(description.id);
        }}
        className="fas close fa-times"
      ></i>
      <p className="main_para" style={{ whiteSpace: "pre-wrap" }}>
        {description.content}
      </p>
      {showTags && description.keywords && (
        <React.Fragment>
          <div className="tags">
            {description.keywords.length > 0 ? (
              description.keywords.map((tg, i) => (
                <div key={i} className="tag">
                  <p>{tg}</p>
                  <i
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteTag(description, tg);
                    }}
                    className="fas fa-times"
                  ></i>
                </div>
              ))
            ) : (
              <h6>Aucun tag pour l'instant</h6>
            )}
          </div>
          <div className="add_tag" onClick={(e) => e.stopPropagation()}>
            <input
              onKeyDown={(e) => saveNewTag(e, description)}
              value={newTag}
              onChange={handleChangeNewTag}
              type="search"
              name=""
              id=""
              placeholder="Tags"
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default DescItem;
