import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loader3 from "../../../../components/Loader3/Loader3";
import { useNavigate } from "react-router-dom";
import PostFacebook from "../../../../components/postsASponsoriser/PostFacebook/PostFacebook";
import PostInstagram from "../../../../components/postsASponsoriser/PostInstagram/PostInstagram";
import { FormattedMessage } from "react-intl";

const PostsASponsoriser = ({ nomPackage, getPosts, compagnId }) => {
  const [toggle, setToggle] = useState(false);
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [loader, setLoader] = useState(false);

  const { sponsoredPosts } = useSelector(
    state => state.creationPackagePublicite
  );

  const { adaccountId } = useSelector(state => state.auth.user);

  useEffect(() => {
    getPosts(adaccountId);

    if (nomPackage.length === 0) {
      setToggle(false);
    }
  }, []);

  const selectedPosts = sponsoredPosts.filter(post => post && post.isSelected);

  return (
    <section
      className='section section_texte section_posts_sponsorisees'
      style={
        !nomPackage
          ? { backgroundColor: "lightgrey", cursor: "not-allowed" }
          : {}
      }
    >
      <header
        style={!nomPackage ? { cursor: "not-allowed" } : {}}
        onClick={() => {
          if (nomPackage) setToggle(prevState => !prevState);
        }}
        className='section_header'
      >
        <div className='df'>
          <h3 className='section_header_title'>
            <FormattedMessage
              defaultMessage='Posts à sponsoriser'
              id='creation_package_pub_section_sponsoredposts'
            />
            {selectedPosts && selectedPosts.length > 0 && (
              <span className='nbr'>{selectedPosts.length}</span>
            )}
          </h3>
        </div>

        <i className={`fas fa-chevron-${toggle ? "up" : "down"}`}></i>
      </header>
      {toggle && (
        <div className='section_content'>
          <div className='search_tags'>
            <div className='search'>
              <i className='fas fa-search'></i>
              <input
                // onChange={filterVideos}
                type='search'
                name=''
                id=''
                placeholder='Search'
              />
            </div>
          </div>
          <div className='list_posts'>
            {loader ? (
              <Loader3 />
            ) : (
              sponsoredPosts &&
              sponsoredPosts.map(post =>
                post.hasOwnProperty("message") ? (
                  <PostFacebook key={post.id} post={post} />
                ) : (
                  <PostInstagram key={post.id} post={post} />
                )
              )
            )}

            <div
              className='add_carousel'
              onClick={() =>
                compagnId
                  ? navigate(`/creation-post?campaign_id=${compagnId}`)
                  : navigate("/creation-post")
              }
            >
              <img src='/add_carousel_icon.png' alt='' />
            </div>
          </div>
          <div className='pagination'>
            {/* <Pagination
              count={parseInt(posts.length / count)}
              onChange={handleChangePage}
            /> */}
          </div>
        </div>
      )}
    </section>
  );
};

export default PostsASponsoriser;
