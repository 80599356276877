import {
  ADD_OR_REMOVE_FROM_SELECTED_TAGS,
  APPLY_TAGS,
  DELETE_DESCRIPTION,
  DELETE_LINK,
  DELETE_TAG,
  DELETE_TAG_LOCAL_RESSOURCE,
  DELETE_TEXT,
  DELETE_TITLE,
  EXPAND_BODY_TEXT,
  GET_IMAGES_CAMPAIGN_CONFIGURATION_FILE,
  GET_VIDEOS_CAMPAIGN_CONFIGURATION_FILE,
  INSERT_DESCRIPTION,
  INSERT_LINK,
  INSERT_TAG,
  INSERT_TEXT,
  INSERT_TITLE,
  LOAD_DATA_CONFIGURATION_FILE,
  MODIFY_BODY_TEXT,
  MODIFY_DESCRIPTION_TEXT,
  MODIFY_DISPLAY_LINK_TEXT,
  MODIFY_LINK_TEXT,
  MODIFY_TITLE_TEXT,
  SAVE_TAG_LOCAL_RESSOURCE,
  SET_LOADER_MULTIMEDIA_IMAGES,
  SET_LOADER_MULTIMEDIA_VIDEOS,
  TOGGLE_CHECK_BODY,
  TOGGLE_CHECK_DESCRIPTION,
  TOGGLE_CHECK_IMAGE,
  TOGGLE_CHECK_LINK,
  TOGGLE_CHECK_TITLE,
  TOGGLE_CHECK_VIDEO,
  UPDATE_BODY_TEXT_WITH_NEW_VALUE,
  UPDATE_DESCRIPTION_TEXT_WITH_NEW_VALUE,
  UPDATE_DISPLAY_LINK_TEXT_WITH_NEW_VALUE,
  UPDATE_LINK_TEXT_WITH_NEW_VALUE,
  UPDATE_TITLE_TEXT_WITH_NEW_VALUE,
} from "../types";
import { v4 as uuidv4 } from "uuid";
import {
  filter,
  getUniqListBySimpleArr,
  getUniqueListBy,
  stringToColour,
} from "../../utils";

const initialState = {
  tags: [],
  titles: [],
  bodies: [],
  links: [],
  descriptions: [],
  filteredtitles: [],
  filteredbodies: [],
  filteredlinks: [],
  filtereddescriptions: [],

  multimedia: {
    images: [],
    filteredimages: [],
    videos: [],
    filteredvideos: [],
    loaderImages: false,
    loaderVideos: false,
  },
  originalData: {
    titles: [],
    bodies: [],
    links: [],
    descriptions: [],
    images: [],
    videos: [],
  },
};

export const campaignConfigurationFileReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case INSERT_TAG:
      if (!state.tags.includes(action.payload)) {
        const bgc = stringToColour(action.payload);

        return {
          ...state,
          tags: [
            ...state.tags,
            {
              elem: action.payload,
              isSelected: false,
              bgc,
            },
          ],
        };
      } else {
        return state;
      }
    case DELETE_TAG:
      return {
        ...state,
        tags: state.tags.filter(tag => tag.elem !== action.payload),

        filteredbodies: state.filteredbodies.map(bodie => ({
          ...bodie,
          tags: bodie.tags.filter(tg => tg.elem !== action.payload),
        })),
        filteredlinks: state.filteredlinks.map(link => ({
          ...link,
          tags: link.tags.filter(tg => tg.elem !== action.payload),
        })),
        filteredtitles: state.filteredtitles.map(title => ({
          ...title,
          tags: title.tags.filter(tg => tg.elem !== action.payload),
        })),
        filtereddescriptions: state.filtereddescriptions.map(description => ({
          ...description,
          tags: description.tags.filter(tg => tg.elem !== action.payload),
        })),
        multimedia: {
          ...state.multimedia,
          filteredimages: state.multimedia.filteredimages.map(image => ({
            ...image,
            tags: image.tags.filter(tg => tg.elem !== action.payload),
          })),
          filteredvideos: state.multimedia.filteredvideos.map(video => ({
            ...video,
            tags: video.tags.filter(tg => tg.elem !== action.payload),
          })),
        },
      };
    case INSERT_TEXT:
      if (!state.bodies.includes(action.payload)) {
        return {
          ...state,
          filteredbodies: [
            { body: action.payload, tags: [], id: uuidv4() },
            ...state.filteredbodies,
          ],
        };
      } else {
        return state;
      }
    case DELETE_TEXT:
      return {
        ...state,
        filteredbodies: state.filteredbodies.filter(
          body => body.id !== action.payload
        ),
      };

    case INSERT_TITLE:
      if (!state.titles.includes(action.payload)) {
        return {
          ...state,
          filteredtitles: [
            { title: action.payload, tags: [], id: uuidv4() },
            ...state.filteredtitles,
          ],
        };
      } else {
        return state;
      }
    case DELETE_TITLE:
      return {
        ...state,
        filteredtitles: state.filteredtitles.filter(
          title => title.id !== action.payload
        ),
      };

    case INSERT_LINK:
      if (!state.links.includes(action.payload)) {
        return {
          ...state,
          filteredlinks: [
            { link: action.payload, tags: [], id: uuidv4() },
            ...state.filteredlinks,
          ],
        };
      } else {
        return state;
      }
    case DELETE_LINK:
      return {
        ...state,
        filteredlinks: state.filteredlinks.filter(
          link => link.id !== action.payload
        ),
      };

    case INSERT_DESCRIPTION:
      if (!state.descriptions.includes(action.payload)) {
        return {
          ...state,
          filtereddescriptions: [
            { description: action.payload, tags: [], id: uuidv4() },
            ...state.filtereddescriptions,
          ],
        };
      } else {
        return state;
      }
    case DELETE_DESCRIPTION:
      return {
        ...state,
        filtereddescriptions: state.filtereddescriptions.filter(
          desc => desc.id !== action.payload
        ),
      };

    case LOAD_DATA_CONFIGURATION_FILE:
      // Get All Tags
      let allTags = [];

      const titles = action.payload.data.titles.map(title => {
        const keywords = title.type.map(elem => {
          const bgc = stringToColour(elem);

          return {
            elem,
            bgc,
          };
        });

        allTags = [...allTags, keywords];

        return {
          title: title.title,
          tags: keywords,
          id: uuidv4(),
          isSelected: false,
          modify: false,
        };
      });

      const bodies = action.payload.data.bodies.map(body => {
        const keywords = body.type.map(elem => {
          const bgc = stringToColour(elem);

          return {
            elem,
            bgc,
          };
        });

        allTags = [...allTags, keywords];

        return {
          body: body.body,
          modify: false,
          tags: keywords,
          id: uuidv4(),
          isSelected: false,
          expandBody: false,
        };
      });

      const descriptions = action.payload.data.descriptions.map(description => {
        const keywords = description.type.map(elem => {
          const bgc = stringToColour(elem);

          return {
            elem,
            bgc,
          };
        });

        allTags = [...allTags, keywords];

        return {
          description: description.description,
          tags: keywords,
          id: uuidv4(),
          isSelected: false,
          modify: false,
        };
      });
      const links = action.payload.data.links.map(link => {
        const keywords = link.type.map(elem => {
          const bgc = stringToColour(elem);

          return {
            elem,
            bgc,
          };
        });

        allTags = [...allTags, keywords];

        return {
          link: link.link,
          display_link: link.display_link,
          tags: keywords,
          id: uuidv4(),
          isSelected: false,
          modify: false,
          modifyDisplayLink: false,
        };
      });

      let finalAllTags = [];

      if (allTags) {
        if (allTags.length > 0) {
          // eslint-disable-next-line
          allTags.map(tag => {
            // eslint-disable-next-line
            tag.map(item => {
              finalAllTags.push({
                ...item,
                elem: item.elem.trim(),
                isSelected: false,
              });
            });
          });
        }
      }

      // eslint-disable-next-line
      finalAllTags = finalAllTags.sort(function (a, b) {
        if (a.elem && b.elem) {
          return a.elem.localeCompare(b.elem);
        }
      });

      finalAllTags = getUniqueListBy(finalAllTags, "elem");

      return {
        ...state,
        id: action.payload.id,
        name: action.payload.name,
        titles: titles,
        filteredtitles: titles,
        bodies: bodies,
        filteredbodies: bodies,
        links: links,
        filteredlinks: links,
        descriptions: descriptions,
        filtereddescriptions: descriptions,
        tags: finalAllTags,
        originalData: {
          titles,
          bodies,
          links,
          descriptions,
        },
      };
    case SET_LOADER_MULTIMEDIA_IMAGES:
      return {
        ...state,
        multimedia: {
          ...state.multimedia,
          loaderImages: true,
        },
      };
    case GET_IMAGES_CAMPAIGN_CONFIGURATION_FILE:
      let tagsImages = [];
      let imagesArr = [];
      // eslint-disable-next-line
      action.payload.map(image => {
        // eslint-disable-next-line
        image.keywords.map(tag => {
          const bgc = stringToColour(tag);

          tagsImages.push({
            elem: tag.trim(),
            bgc,
          });
        });
      });

      // eslint-disable-next-line
      imagesArr = action.payload.map(image => {
        return {
          ...image,
          tags: image.tags.map(tg => {
            const bgc = stringToColour(tg);

            return {
              elem: tg,
              bgc,
            };
          }),
        };
      });

      return {
        ...state,
        // eslint-disable-next-line
        tags: getUniqueListBy([...state.tags, ...tagsImages], "elem").sort(
          // eslint-disable-next-line
          function (a, b) {
            if (a.elem && b.elem) {
              return a.elem.localeCompare(b.elem);
            }
          }
        ),
        multimedia: {
          ...state.multimedia,
          images: imagesArr,
          filteredimages: imagesArr,
          loaderImages: false,
        },
        originalData: {
          ...state.originalData,
          images: imagesArr,
        },
      };
    case SET_LOADER_MULTIMEDIA_VIDEOS:
      return {
        ...state,
        multimedia: {
          ...state.multimedia,
          loaderVideos: true,
        },
      };
    case GET_VIDEOS_CAMPAIGN_CONFIGURATION_FILE:
      let tagsVideos = [];
      let videosArr = [];
      // eslint-disable-next-line
      action.payload.map(video => {
        // eslint-disable-next-line
        video.keywords.map(tag => {
          const bgc = stringToColour(tag);

          tagsVideos.push({
            elem: tag.trim(),
            bgc,
          });
        });
      });
      // eslint-disable-next-line
      videosArr = action.payload.map(video => {
        return {
          ...video,
          tags: video.tags.map(tg => {
            const bgc = stringToColour(tg);

            return {
              elem: tg,
              bgc,
            };
          }),
        };
      });

      return {
        ...state,
        // eslint-disable-next-line
        tags: getUniqueListBy([...state.tags, ...tagsVideos], "elem").sort(
          // eslint-disable-next-line
          function (a, b) {
            if (a.elem && b.elem) {
              return a.elem.localeCompare(b.elem);
            }
          }
        ),
        multimedia: {
          ...state.multimedia,
          videos: videosArr,
          filteredvideos: videosArr,
          loaderVideos: false,
        },
        originalData: {
          ...state.originalData,
          videos: videosArr,
        },
      };
    case SAVE_TAG_LOCAL_RESSOURCE:
      switch (action.payload.ressource) {
        case "title":
          return {
            ...state,
            // eslint-disable-next-line
            filteredtitles: state.filteredtitles.map(title => {
              if (title.id === action.payload.id) {
                const bgc = stringToColour(action.payload.tag);

                return {
                  ...title,
                  tags: getUniqListBySimpleArr([
                    {
                      elem: action.payload.tag,
                      bgc,
                    },
                    ...title.tags,
                  ]),
                };
              } else return title;
            }),
          };
        case "body":
          return {
            ...state, // eslint-disable-next-line
            filteredbodies: state.filteredbodies.map(body => {
              if (body.id === action.payload.id) {
                const bgc = stringToColour(action.payload.tag);

                return {
                  ...body,
                  tags: getUniqListBySimpleArr([
                    {
                      elem: action.payload.tag,
                      bgc,
                    },
                    ...body.tags,
                  ]),
                };
              } else return body;
            }),
          };
        case "link":
          return {
            ...state, // eslint-disable-next-line
            filteredlinks: state.filteredlinks.map(link => {
              if (link.id === action.payload.id) {
                const bgc = stringToColour(action.payload.tag);

                return {
                  ...link,
                  tags: getUniqListBySimpleArr([
                    {
                      elem: action.payload.tag,
                      bgc,
                    },
                    ...link.tags,
                  ]),
                };
              } else return link;
            }),
          };
        case "description":
          return {
            ...state, // eslint-disable-next-line
            filtereddescriptions: state.filtereddescriptions.map(
              description => {
                if (description.id === action.payload.id) {
                  const bgc = stringToColour(action.payload.tag);

                  return {
                    ...description,
                    tags: getUniqListBySimpleArr([
                      {
                        elem: action.payload.tag,
                        bgc,
                      },
                      ...description.tags,
                    ]),
                  };
                } else return description;
              }
            ),
          };
        case "image":
          return {
            ...state,
            multimedia: {
              ...state.multimedia, // eslint-disable-next-line
              filteredimages: state.multimedia.filteredimages.map(image => {
                if (image.id === action.payload.id) {
                  const bgc = stringToColour(action.payload.tag);

                  return {
                    ...image,
                    tags: getUniqListBySimpleArr([
                      {
                        elem: action.payload.tag,
                        bgc,
                      },
                      ...image.tags,
                    ]),
                  };
                } else return image;
              }),
            },
          };
        case "video":
          return {
            ...state,
            multimedia: {
              ...state.multimedia, // eslint-disable-next-line
              filteredvideos: state.multimedia.filteredvideos.map(video => {
                if (video.id === action.payload.id) {
                  const bgc = stringToColour(action.payload.tag);

                  return {
                    ...video,
                    tags: getUniqListBySimpleArr([
                      {
                        elem: action.payload.tag,
                        bgc,
                      },
                      ...video.tags,
                    ]),
                  };
                } else return video;
              }),
            },
          };
        default:
          break;
      }
      return {
        ...state,
      };

    case DELETE_TAG_LOCAL_RESSOURCE:
      switch (action.payload.ressource) {
        case "title":
          return {
            ...state, // eslint-disable-next-line
            filteredtitles: state.filteredtitles.map(title => {
              if (title.id === action.payload.id) {
                return {
                  ...title,
                  tags: title.tags.filter(
                    tag => tag.elem !== action.payload.tag
                  ),
                };
              } else return title;
            }),
          };
        case "body":
          return {
            ...state, // eslint-disable-next-line
            filteredbodies: state.filteredbodies.map(body => {
              if (body.id === action.payload.id) {
                return {
                  ...body,
                  tags: body.tags.filter(
                    tag => tag.elem !== action.payload.tag
                  ),
                };
              } else return body;
            }),
          };
        case "link":
          return {
            ...state, // eslint-disable-next-line
            filteredlinks: state.filteredlinks.map(link => {
              if (link.id === action.payload.id) {
                return {
                  ...link,
                  tags: link.tags.filter(
                    tag => tag.elem !== action.payload.tag
                  ),
                };
              } else return link;
            }),
          };
        case "description":
          return {
            ...state, // eslint-disable-next-line
            filtereddescriptions: state.filtereddescriptions.map(
              description => {
                if (description.id === action.payload.id) {
                  return {
                    ...description,
                    tags: description.tags.filter(
                      tag => tag.elem !== action.payload.tag
                    ),
                  };
                } else return description;
              }
            ),
          };
        // case "image":
        //   return {
        //     ...state,
        //     multimedia: {
        //       ...state.multimedia,
        //       filteredimages: state.multimedia.filteredimages.map(image => {
        //         if (image.id === action.payload.id) {
        //           return {
        //             ...image,
        //             tags: image.tags.filter(
        //               tag => tag.elem !== action.payload.tag
        //             ),
        //           };
        //         } else return image;
        //       }),
        //     },
        //   };
        // case "video":
        //   return {
        //     ...state,
        //     multimedia: {
        //       ...state.multimedia,
        //       filteredvideos: state.multimedia.filteredvideos.map(video => {
        //         if (video.id === action.payload.id) {
        //           return {
        //             ...video,
        //             tags: video.tags.filter(
        //               tag => tag.elem !== action.payload.tag
        //             ),
        //           };
        //         } else return video;
        //       }),
        //     },
        //   };
        default:
          break;
      }
      return {
        ...state,
      };
    case ADD_OR_REMOVE_FROM_SELECTED_TAGS:
      if (!action.payload.isSelected) {
        let filteredtitles = filter(state.filteredtitles, [
          action.payload.elem,
        ]);
        let filteredbodies = filter(state.filteredbodies, [
          action.payload.elem,
        ]);
        let filteredlinks = filter(state.filteredlinks, [action.payload.elem]);
        let filtereddescriptions = filter(state.filtereddescriptions, [
          action.payload.elem,
        ]);
        let filteredimages = filter(state.multimedia.filteredimages, [
          action.payload.elem,
        ]);
        let filteredvideos = filter(state.multimedia.filteredvideos, [
          action.payload.elem,
        ]);

        return {
          ...state,
          tags: state.tags.map((tag, index) => {
            if (tag.elem === action.payload.elem) {
              return {
                ...tag,
                isSelected: !tag.isSelected,
              };
            } else return tag;
          }),
          filteredtitles,
          titles: state.filteredtitles,
          filteredbodies,
          bodies: state.filteredbodies,
          filteredlinks,
          links: state.filteredlinks,
          filtereddescriptions,
          descriptions: state.filtereddescriptions,
          multimedia: {
            ...state.multimedia,
            filteredimages,
            images: state.multimedia.filteredimages,
            filteredvideos,
            videos: state.multimedia.filteredvideos,
          },
        };
      } else {
        let selectedTags = state.tags.filter(tg => tg.isSelected === true);

        if (selectedTags.length === 1) {
          return {
            ...state,
            tags: state.tags.map(tag => {
              return { ...tag, isSelected: false };
            }),
            filteredtitles: state.originalData.titles,
            filteredbodies: state.originalData.bodies,
            filteredlinks: state.originalData.links,
            filtereddescriptions: state.originalData.descriptions,
            multimedia: {
              ...state.multimedia,
              filteredimages: state.originalData.images,
              filteredvideos: state.originalData.videos,
            },
          };
        } else
          return {
            ...state,
            tags: state.tags.map(tag => {
              if (tag.elem === action.payload.elem) {
                return { ...tag, isSelected: false };
              } else return tag;
            }),
            filteredtitles: state.titles,
            filteredbodies: state.bodies,
            filteredlinks: state.links,
            filtereddescriptions: state.descriptions,
            multimedia: {
              ...state.multimedia,
              filteredimages: state.multimedia.images,
              filteredvideos: state.multimedia.videos,
            },
          };
      }
    // case FILTER_RESSOURCES:
    //   let filteredtitles = filter(state.filteredtitles, action.payload);
    //   let filteredbodies = filter(state.filteredbodies, action.payload);
    //   let filteredlinks = filter(state.filteredlinks, action.payload);
    //   let filtereddescriptions = filter(
    //     state.filtereddescriptions,
    //     action.payload
    //   );
    //   let filteredimages = filter(
    //     state.multimedia.filteredimages,
    //     action.payload
    //   );
    //   let filteredvideos = filter(
    //     state.multimedia.filteredvideos,
    //     action.payload
    //   );

    //   return {
    //     ...state,
    //     filteredtitles,
    //     titles: state.filteredtitles,
    //     filteredbodies,
    //     bodies: state.filteredbodies,
    //     filteredlinks,
    //     links: state.filteredlinks,
    //     filtereddescriptions,
    //     descriptions: state.filtereddescriptions,
    //     multimedia: {
    //       ...state.multimedia,
    //       filteredimages,
    //       images: state.multimedia.filteredimages,
    //       filteredvideos,
    //       videos: state.multimedia.filteredvideos,
    //     },
    //   };

    // case UNFILTER_RESSOURCES:
    //   return {
    //     ...state,
    //     tags: state.tags.map(tag => ({
    //       ...tag,
    //       isSelected: false,
    //       colorSelected: undefined,
    //     })),
    //     filteredtitles: state.titles,
    //     filteredbodies: state.bodies,
    //     filteredlinks: state.links,
    //     filtereddescriptions: state.descriptions,
    //     multimedia: {
    //       ...state.multimedia,
    //       filteredimages: state.multimedia.images,
    //       filteredvideos: state.multimedia.videos,
    //     },
    //   };
    case TOGGLE_CHECK_BODY:
      return {
        ...state,
        filteredbodies: state.filteredbodies.map(body => {
          if (body.id === action.payload) {
            return {
              ...body,
              isSelected: !body.isSelected,
            };
          } else return body;
        }),
      };
    case TOGGLE_CHECK_TITLE:
      return {
        ...state,
        filteredtitles: state.filteredtitles.map(title => {
          if (title.id === action.payload) {
            return {
              ...title,
              isSelected: !title.isSelected,
            };
          } else return title;
        }),
      };
    case TOGGLE_CHECK_LINK:
      return {
        ...state,
        filteredlinks: state.filteredlinks.map(link => {
          if (link.id === action.payload) {
            return {
              ...link,
              isSelected: !link.isSelected,
            };
          } else return link;
        }),
      };
    case TOGGLE_CHECK_DESCRIPTION:
      return {
        ...state,
        filtereddescriptions: state.filtereddescriptions.map(description => {
          if (description.id === action.payload) {
            return {
              ...description,
              isSelected: !description.isSelected,
            };
          } else return description;
        }),
      };

    case APPLY_TAGS:
      const tagsToApply = action.payload;

      return {
        ...state,
        tags: state.tags.map(tag => ({
          ...tag,
          isSelected: false,
        })),
        filteredtitles: state.filteredtitles.map(title => {
          if (title.isSelected) {
            return {
              ...title,
              isSelected: false,
              tags: [...title.tags, ...tagsToApply].filter((element, index) => {
                return (
                  [...title.tags, ...tagsToApply].indexOf(element) === index
                );
              }),
            };
          } else return title;
        }),
        filteredbodies: state.filteredbodies.map(body => {
          if (body.isSelected) {
            return {
              ...body,
              isSelected: false,
              tags: [...body.tags, ...tagsToApply].filter((element, index) => {
                return (
                  [...body.tags, ...tagsToApply].indexOf(element) === index
                );
              }),
            };
          } else return body;
        }),
        filteredlinks: state.filteredlinks.map(link => {
          if (link.isSelected) {
            return {
              ...link,
              isSelected: false,
              tags: [...link.tags, ...tagsToApply].filter((element, index) => {
                return (
                  [...link.tags, ...tagsToApply].indexOf(element) === index
                );
              }),
            };
          } else return link;
        }),
        filtereddescriptions: state.filtereddescriptions.map(desc => {
          if (desc.isSelected) {
            return {
              ...desc,
              isSelected: false,
              tags: [...desc.tags, ...tagsToApply].filter((element, index) => {
                return (
                  [...desc.tags, ...tagsToApply].indexOf(element) === index
                );
              }),
            };
          } else return desc;
        }),
        multimedia: {
          ...state.multimedia,
          filteredimages: state.multimedia.filteredimages.map(image => {
            if (image.isSelected) {
              return {
                ...image,
                isSelected: false,
                tags: [...image.tags, ...tagsToApply].filter(
                  (element, index) => {
                    return (
                      [...image.tags, ...tagsToApply].indexOf(element) === index
                    );
                  }
                ),
              };
            } else return image;
          }),
          filteredvideos: state.multimedia.filteredvideos.map(video => {
            if (video.isSelected) {
              return {
                ...video,
                isSelected: false,
                tags: [...video.tags, ...tagsToApply].filter(
                  (element, index) => {
                    return (
                      [...video.tags, ...tagsToApply].indexOf(element) === index
                    );
                  }
                ),
              };
            } else return video;
          }),
        },
      };

    case EXPAND_BODY_TEXT:
      return {
        ...state,
        filteredbodies: state.filteredbodies.map(body => {
          if (body.id === action.payload) {
            return { ...body, expandBody: !body.expandBody };
          } else return body;
        }),
      };
    case TOGGLE_CHECK_IMAGE:
      return {
        ...state,
        multimedia: {
          ...state.multimedia,
          filteredimages: state.multimedia.filteredimages.map(image => {
            if (image.id === action.payload) {
              return {
                ...image,
                isSelected: !image.isSelected,
              };
            } else return image;
          }),
        },
      };
    case TOGGLE_CHECK_VIDEO:
      return {
        ...state,
        multimedia: {
          ...state.multimedia,
          filteredvideos: state.multimedia.filteredvideos.map(video => {
            if (video.id === action.payload) {
              return {
                ...video,
                isSelected: !video.isSelected,
              };
            } else return video;
          }),
        },
      };

    case MODIFY_BODY_TEXT:
      return {
        ...state,
        filteredbodies: state.filteredbodies.map(body => {
          if (body.id === action.payload) {
            return {
              ...body,
              modify: !body.modify,
            };
          } else return body;
        }),
      };
    case UPDATE_BODY_TEXT_WITH_NEW_VALUE:
      return {
        ...state,
        filteredbodies: state.filteredbodies.map(body => {
          if (body.id === action.payload.id) {
            return {
              ...body,
              body: action.payload.value,
            };
          } else return body;
        }),
      };

    case MODIFY_TITLE_TEXT:
      return {
        ...state,
        filteredtitles: state.filteredtitles.map(title => {
          if (title.id === action.payload) {
            return {
              ...title,
              modify: !title.modify,
            };
          } else return title;
        }),
      };
    case UPDATE_TITLE_TEXT_WITH_NEW_VALUE:
      return {
        ...state,
        filteredtitles: state.filteredtitles.map(title => {
          if (title.id === action.payload.id) {
            return {
              ...title,
              title: action.payload.value,
            };
          } else return title;
        }),
      };

    case MODIFY_LINK_TEXT:
      return {
        ...state,
        filteredlinks: state.filteredlinks.map(link => {
          if (link.id === action.payload) {
            return {
              ...link,
              modify: !link.modify,
            };
          } else return link;
        }),
      };
    case MODIFY_DISPLAY_LINK_TEXT:
      return {
        ...state,
        filteredlinks: state.filteredlinks.map(link => {
          if (link.id === action.payload) {
            return {
              ...link,
              modifyDisplayLink: !link.modifyDisplayLink,
            };
          } else return link;
        }),
      };
    case UPDATE_LINK_TEXT_WITH_NEW_VALUE:
      return {
        ...state,
        filteredlinks: state.filteredlinks.map(link => {
          if (link.id === action.payload.id) {
            return {
              ...link,
              link: action.payload.value,
            };
          } else return link;
        }),
      };

    case UPDATE_DISPLAY_LINK_TEXT_WITH_NEW_VALUE:
      return {
        ...state,
        filteredlinks: state.filteredlinks.map(link => {
          if (link.id === action.payload.id) {
            return {
              ...link,
              display_link: action.payload.value,
            };
          } else return link;
        }),
      };

    case MODIFY_DESCRIPTION_TEXT:
      return {
        ...state,
        filtereddescriptions: state.filtereddescriptions.map(description => {
          if (description.id === action.payload) {
            return {
              ...description,
              modify: !description.modify,
            };
          } else return description;
        }),
      };
    case UPDATE_DESCRIPTION_TEXT_WITH_NEW_VALUE:
      return {
        ...state,
        filtereddescriptions: state.filtereddescriptions.map(description => {
          if (description.id === action.payload.id) {
            return {
              ...description,
              description: action.payload.value,
            };
          } else return description;
        }),
      };
    default:
      return state;
  }
};
