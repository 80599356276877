import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import "./style.css";

const MessageNotConnectedFB = () => {
  const dispatch = useDispatch();

  const hideMessage = () => {
    dispatch({ type: "HIDE_ALERT_MSG_NOT_CONNECTED_FB" });
  };

  return (
    <div className='message_not_connected_fb'>
      <div className='content'>
        <i className='fas fa-exclamation-triangle fa-2x'></i>
        <h2>
          Vous n'êtes plus connecté à Facebook, veuillez <br />
          <Link onClick={hideMessage} to='/campagne/ajouter-compte'>
            vous reconnecter
          </Link>
        </h2>
      </div>
    </div>
  );
};

export default MessageNotConnectedFB;
