import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import LeftMenu from "../../../components/LeftMenu/LeftMenu";
import axiosInstance from "../../../store/axios.instance";
import { toast } from "react-toastify";
import "./style.css";
import Loader3 from "../../../components/Loader3/Loader3";
import { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Loader from "../../../components/Loader/Loader";
import { subtractObjects } from "../../../utils";
import SelectAdAccounts from "../../../components/SelectAdAccounts/SelectAdAccounts";
import TopRightMenu from "../../../components/TopRightMenu/TopRightMenu";
import RightContainer from "../../../components/RightContainer/RightContainer";

const ChoisirCompte = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const {adaccounts} = useSelector(state => state.auth.user);

  const dispatch = useDispatch();
  const { toggleLeftMenu } = useSelector(state => state.utils);

  const setToggleLeftMenu = () => {
    dispatch({ type: "SET_TOGGLE_LEFT_MENU" });
  };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const redirectBackTo = searchParams.get("redirectBackTo");

  const { filteredIntersectClients: accounts } = useSelector(
    state => state.selectAdaccount
  );

  const nextStep = () => {
    const selectedAccounts = accounts.filter(acc => acc.isSelected);

    if (selectedAccounts && selectedAccounts.length > 0) {
      setLoader(true);

      Promise.all(
        selectedAccounts.map(async acc => {
          await axiosInstance.put(`/${acc.id}`, {
            name: acc.name,
          });
        })
      );

      setTimeout(() => {
        setLoader(false);
        toast.success("Compte(s) ajouté(s) avec succès !");

        if (redirectBackTo) {
          navigate("/creation-campagne");
        } else {
          navigate("/campagne/comptes-actifs");
        }
      }, 500);
    } else {
      toast.error("Vous devez au moins choisir un compte !");
    }

    //   if (accountChoosen) {
    //     setLoader(true);

    //     axiosInstance
    //       .put(`/${accountChoosen.id}`, {
    //         name: accountChoosen.name,
    //       })
    //       .then(() => {
    //         setTimeout(() => {
    //           setLoader(false);
    //           toast.success("Compte ajouté avec succès !");

    //           if (redirectBackTo) {
    //             navigate("/creation-campagne");
    //           } else {
    //             navigate("/campagne/comptes-actifs");
    //           }
    //         }, 500);
    //       })
    //       .catch(err => {
    //         console.log(err);
    //         setLoader(false);
    //       });
    //   }
    // }
  };

  const getIntersectAccounts = () => {
    setLoader2(true);

    axiosInstance
      .get(`/intersect_client/${user_id}`)
      .then(res => {
        const intersect_clients = res.data.map(item => {
          item.id = item.account;
          delete item.account;

          return item;
        });
        const filteredAccounts = subtractObjects(
          adaccounts,
          intersect_clients,
          "id"
        );
        
        dispatch({ type: "GET_INTERSECT_CLIENTS", payload: filteredAccounts });

        setTimeout(() => {
          setLoader2(false);
        }, 500);
      })
      .catch(err => {
        console.log(err);
        setLoader2(false);
      });
  };

  const {
    userData: { id: user_id },
  } = useSelector(state => state.auth.user);

  useEffect(() => {
    getIntersectAccounts();
  }, []);

  const intl = useIntl();

  return (
    <div className='ecran_campagne_choisir_compte'>
      <LeftMenu />
      <RightContainer>
        <header className='right_content_header'>
          <div className='back_title'>
            <h1 className='right_content_header_title'>
              <FormattedMessage
                defaultMessage='Choisissez votre compte'
                id='choose-account-main-title'
              />
            </h1>
          </div>
        </header>
        {loader && (
          <Loader
            message={intl.formatMessage({
              defaultMessage: "Ajout en cours...",
              id: "loader_message_4",
            })}
          />
        )}
        <div className='right_content_main'>
          <div className='steps'>
            <div className='step '>
              <p>
                <FormattedMessage
                  defaultMessage='Paramètres'
                  id='add-account-title'
                />
              </p>
            </div>
            <img src='/fleche-step.png' alt='Next Step' />
            <div className='step '>
              <p>
                <FormattedMessage
                  defaultMessage="Ajout d'un compte"
                  id='add-account-step-add-account'
                />
              </p>
            </div>
            <img src='/fleche-step.png' alt='Next Step' />
            <div className='step active'>
              <p>
                <FormattedMessage
                  defaultMessage='Choisir un compte'
                  id='add-account-choose-account'
                />
              </p>
            </div>
            <img src='/fleche-step.png' alt='Next Step' />
            <div className='step'>
              <p>
                <FormattedMessage
                  defaultMessage='Configuration'
                  id='add-account-configuration'
                />
              </p>
            </div>
          </div>
          <div className='choix_reseau_social'>
            <div className='choix_reseau_social_content'>
              {false ? (
                <div style={{ marginBottom: 50 }}>
                  <Loader3 />
                </div>
              ) : (
                <div className='choixcompteads_meta'>
                  <header>
                    <h5>
                      <FormattedMessage
                        defaultMessage='Choisissez un compte Ads'
                        id='choose-account-choose-ad-account'
                      />
                    </h5>
                    <img src='/meta.png' alt='meta' />
                  </header>
                  {loader2 ? (
                    <Loader3 />
                  ) : (
                    <SelectAdAccounts />
                  )}
                </div>
              )}
              <div className='navigation'>
                <button
                  onClick={() => navigate("/campagne/ajouter-compte")}
                  className='outline'
                >
                  <FormattedMessage
                    defaultMessage='Précédent'
                    id='add-account-previous'
                  />
                </button>
                <button onClick={nextStep} className='plain'>
                  <FormattedMessage
                    defaultMessage='Suivant'
                    id='add-account-next'
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </RightContainer>
    </div>
  );
};

export default ChoisirCompte;
