import React, { useState, useEffect } from "react";
import Switch from "@mui/material/Switch";
import { useDispatch, useSelector } from "react-redux";
// import Datetime from "react-datetime";
import moment from "moment";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Pagination from "@mui/material/Pagination";

import { toast } from "react-toastify";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import axiosInstance from "../../../store/axios.instance";
import Loader3 from "../../../components/Loader3/Loader3";
import axios from "axios";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ControleBudget = () => {
  const [toggle, setToggle] = useState(false);
  const [loaderConnectedClients, setLoaderConnectedClients] = useState(false);
  const [loaderAlerts, setLoaderAlerts] = useState(false);

  const [editMode, setEditMode] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const dispatch = useDispatch();

  const {
    adaccountId,
    connectedAccounts,
    userData: { id: user_id },
  } = useSelector((state) => state.auth.user);
  const {
    contrôleBudget: {
      alerts: alertList,
      alertId,
      status,
      name,
      target_budget,
      date_start,
      date_end,
      campagnes,
      threshold,
      ad_pause_threshold,
      pagination: { startIndex, endIndex, count },
    },
  } = useSelector((state) => state.alerts);

  const { userData } = useSelector((state) => state.auth.user);

  const { campaigns: campaignsList } = useSelector(
    (state) => state.configurationMonitoring
  );

  const getCampaigns = async (adacc) => {
    try {
      // setLoaderCampaigns(true);

      const response = await axios.get(
        `${process.env.REACT_APP_GRAPH_API}/${adacc}/campaigns?fields=name,status&access_token=${userData.token}&limit=1000`
      );

      if (response.status === 200) {
        dispatch({
          type: "GET_CAMPAIGNS_CONFIG_MONITORING",
          payload: response.data.data,
        });
      } else {
        setTimeout(() => {
          dispatch({
            type: "GET_CAMPAIGNS_CONFIG_MONITORING",
            payload: [],
          });
          // setLoaderCampaigns(false);
        }, 500);
      }
    } catch (error) {
      console.log(error);
      setTimeout(() => {
        dispatch({
          type: "GET_CAMPAIGNS_CONFIG_MONITORING",
          payload: [],
        });
      }, 500);
    }
  };

  const handleShowForm = () => {
    setShowForm(true);
  };

  const handleHideForm = () => {
    setShowForm(false);
  };

  const handleChange = (e) => {
    dispatch({
      type: "ADD_ALERT_DATA",
      payload: {
        name: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleChangeCampaign = (e, newValue) => {
    dispatch({ type: "SET_CAMPAIGNS", payload: newValue });
  };

  const setStatus = (alertId) => {
    dispatch({ type: "SET_STATUS", payload: { alertId } });

    setTimeout(() => {
      handleEditStatus(alertId);
    }, 500);
  };

  // const selectAccount = (e) => {
  //   // Récupère l'élément select
  //   var select = e.target;
  //   // Récupère l'index de l'option sélectionnée
  //   var index = select.selectedIndex;
  //   // Récupère l'option sélectionnée
  //   var optionSelectionnee = select.options[index];
  //   // Récupère l'objet à partir de l'attribut data-object de l'option sélectionnée
  //   var compteSelectionne = JSON.parse(
  //     optionSelectionnee.getAttribute("data-object")
  //   );

  //   dispatch({
  //     type: "SET_ADACCOUNT_CHOOSEN_AUTH",
  //     payload: compteSelectionne.account,
  //   });

  //   dispatch({
  //     type: "SAVE_NAME_COMPTE_PRINCIPAL",
  //     payload: compteSelectionne.name,
  //   });

  //   localStorage.setItem("adaccountId", compteSelectionne.account);
  //   localStorage.setItem("adaccountName", compteSelectionne.name);

  //   dispatch({ type: "CLEAR_ALL_INPUTS_CREATION_CAMPAIGN" });
  //   dispatch({ type: "CLEAR_PACKAGE_GEO_DEMO" });
  // };

  const getAllAlerts = () => {
    setLoaderAlerts(true);
    axiosInstance
      .get(`/${adaccountId}/AlertBudget`)
      .then((res) => {
        dispatch({
          type: "SET_ALERTS",
          payload: res.data,
        });

        setLoaderAlerts(false);
      })
      .catch((err) => {
        console.log(err);
        setLoaderAlerts(false);
        toast.error("Une erreur s'est produite, veuillez réessayer plus tard");
      });
  };

  const handleSaveAlert = () => {
    const objectToSend = {
      status,
      name,
      target_budget,
      date_start,
      date_end,
      threshold,
      ad_pause_threshold: ad_pause_threshold ? true : false,
    };

    axiosInstance
      .put(`/${adaccountId}/AlertBudget`, campagnes, { params: objectToSend })
      .then((res) => {
        // console.log(res.data); // setCamp(res.data)
        dispatch({
          type: "ADD_NEW_ALERT",
          payload: res.data,
        });
        toast.success("Contrôle Budget créé avec succès");
        setShowForm(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Une erreur s'est produite, veuillez réessayer plus tard");
      });
  };

  const handleEditAlert = () => {
    const objectToSend = {
      status,
      name,
      target_budget,
      date_start,
      date_end,
      threshold,
      ad_pause_threshold: ad_pause_threshold ? true : false,
    };

    axiosInstance
      .put(`/${adaccountId}/AlertBudget/${alertId}`, campagnes, {
        params: objectToSend,
      })
      .then((res) => {
        if (res.data === true) {
          toast.success("Contrôle Budget modifié avec succès");
          setShowForm(false);
          getAllAlerts();
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Une erreur s'est produite, veuillez réessayer plus tard");
      });
  };

  const handleEditStatus = (alertId) => {
    const alert = alertList.find((alert) => alert._id === alertId);
    console.log(alert);
    const status = !alert.status;

    axiosInstance
      .put(`/${adaccountId}/AlertBudget/${alertId}?&status=${status}`)
      .then((res) => {
        if (res.data === true) {
          toast.success("Statut modifié avec succès");
          // getAllAlerts();
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Une erreur s'est produite, veuillez réessayer plus tard");
      });
  };

  const handleEditCampaigns = (alertId) => {
    axiosInstance
      .post(`/${adaccountId}/AlertBudget/campaign`, campagnes, {
        params: { alert_budget_id: alertId },
      })
      .then((res) => {
        console.log(res);
        if (res.data === true) {
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Une erreur s'est produite, veuillez réessayer plus tard");
      });
  };

  const handleDeleteCampaigns = (campId, alertId) => {
    axiosInstance
      .delete(
        `/${adaccountId}/AlertBudget/${campId}?alert_budget_id=${alertId}`
      )
      .then((res) => {
        console.log(res);
        if (res.data === true) {
          dispatch({
            type: "REMOVE_CAMPAIGN_FROM_ALERT",
            payload: {
              campId,
              alertId,
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Une erreur s'est produite, veuillez réessayer plus tard");
      });
  };

  const handleDelete = (alertId) => {
    const result = window.confirm(
      "Attention, Êtes-vous sûr de vouloir supprimer cette alerte ?"
    );
    if (result) {
      axiosInstance
        .delete(`/${adaccountId}/AlertBudget?alert_budget_id=${alertId}`)
        .then((res) => {
          // console.log(res);
          if (res.data === true) {
            dispatch({
              type: "REMOVE_ALERT",
              payload: alertId,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(
            "Une erreur s'est produite, veuillez réessayer plus tard"
          );
        });
    }
  };

  const handleChangePage = (event, value) => {
    event.stopPropagation();

    dispatch({
      type: "SET_END_INDEX_PAGINATION_CONTROL_BUDGET",
      payload: value * count,
    });
  };

  const handleEdit = (index) => {
    const alert = alertList[index];
    dispatch({ type: "EDIT_ALERT", payload: alert });
    setEditMode(true);
    setShowForm(true);
  };

  const handleDuplicate = (index) => {
    const alert = alertList[index];
    dispatch({ type: "EDIT_ALERT", payload: alert });
    setShowForm(true);
  };

  const handleSave = () => {
    if (!editMode) {
      handleSaveAlert();
    } else {
      handleEditAlert();
      handleEditCampaigns(alertId);
    }
  };

  useEffect(() => {
    if (adaccountId) {
      getAllAlerts(adaccountId);
      getCampaigns(adaccountId);
      dispatch({ type: "CLEAR_CAMPAIGN_REF_VARIABLES" });
      dispatch({ type: "CLEAR_PACKAGE_GEO_DEMO" });
    }
  }, [adaccountId]);

  const alertListToShow = alertList.slice(startIndex, endIndex);
  // console.log("alertListToShow", alertListToShow);

  return (
    <div className="section">
      <header
        className="section_header"
        onClick={() => setToggle((prevState) => !prevState)}
      >
        <h3 className="section_header_title">Contrôle Budget</h3>
        <i className={`fas fa-chevron-${toggle ? "up" : "down"}`}></i>
      </header>
      {toggle && (
        <div className="section_content controle_budget">
          <div className="selecteur_compte_button">
            <div className="selecteur_compte">
              {/*{loaderConnectedClients ? (
                <Loader3 />
              ) : (
                <select value={adaccountId} onChange={selectAccount}>
                  <option value="">Sélectionner un compte</option>
                  {connectedAccounts.map((ac, i) => (
                    <option
                      key={i}
                      data-object={JSON.stringify(ac)}
                      value={ac.account}
                    >
                      {ac.name}
                    </option>
                  ))}
                </select>
                  )}*/}
            </div>
            {/*<button onClick={handleAddAlert}>{showForm ? 'Cancel' : 'Add Alert'}</button>*/}
            <button onClick={handleShowForm}>+ Ajouter une alerte</button>
          </div>
          <div className="tableau">
            <form>
              {loaderAlerts ? (
                <Loader3 />
              ) : (
                <table>
                  <thead>
                    <tr>
                      <th>On/Off</th>
                      <th>Nom</th>
                      <th>Budget cible</th>
                      <th>Date de début</th>
                      <th>Date de fin</th>
                      <th style={{ maxWidth: "180px" }}>Campagnes</th>
                      <th style={{ width: "5%" }}>Seuil Alerte</th>
                      <th style={{ width: "10%" }}>
                        Mettre en pause les pubs si limite
                      </th>
                      <th>Consommé</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {alertListToShow &&
                      alertListToShow.length > 0 &&
                      alertListToShow.map((alert, index) => (
                        <tr key={alert._id}>
                          <td>
                            <Switch
                              onClick={() => setStatus(alert._id)}
                              checked={alert.status}
                              // name="status"
                            />
                          </td>
                          <td>
                            <p>{alert.name}</p>
                          </td>
                          <td>
                            <p>{alert.target_budget}</p>
                          </td>
                          <td>
                            <p>
                              {moment(alert.date_start)
                                .utc()
                                .format("DD MMMM YYYY")}
                            </p>
                          </td>
                          <td>
                            <p>
                              {moment(alert.date_end)
                                .utc()
                                .format("DD MMMM YYYY")}
                            </p>
                          </td>
                          <td>
                            <div
                              className="campagnes"
                              style={{ maxWidth: "250px" }}
                            >
                              <div className="tags">
                                {alert.campaign &&
                                  alert.campaign.length > 0 &&
                                  alert.campaign.map((camp) => (
                                    <div
                                      key={camp.id}
                                      className="tag"
                                      style={{ maxWidth: "250px" }}
                                    >
                                      <p>{camp.name}</p>
                                      <i
                                        onClick={() =>
                                          handleDeleteCampaigns(
                                            camp.id,
                                            alert._id
                                          )
                                        }
                                        className="fas fa-times"
                                      ></i>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </td>
                          <td>
                            <p>{alert.threshold}</p>
                          </td>
                          <td className="pause_limite">
                            <input
                              type="checkbox"
                              checked={alert.ad_pause_threshold}
                            />
                          </td>
                          <td>
                            <p>
                              {alert.budget_used || 0}/{alert.target_budget} (
                              {alert.budget_percentage || 0}%)
                            </p>
                          </td>
                          <td>
                            <div className="actions">
                              <i
                                className="fas fa-pen"
                                onClick={() => handleEdit(index)}
                              ></i>
                              <i
                                className="far fa-copy"
                                onClick={() => handleDuplicate(index)}
                              ></i>
                              <i
                                className="fas fa-trash-alt"
                                onClick={() => handleDelete(alert._id)}
                              ></i>
                            </div>
                          </td>
                        </tr>
                      ))}
                    {showForm && (
                      <tr className="new">
                        <td>
                          <Switch
                            onClick={setStatus}
                            checked={status}
                            name="status"
                          />
                        </td>
                        <td>
                          <input
                            value={name}
                            name="name"
                            onChange={handleChange}
                            type="text"
                            placeholder="Nom"
                          />
                        </td>
                        <td style={{ maxWidth: "20px" }}>
                          <input
                            style={{ maxWidth: "100%" }}
                            value={target_budget}
                            name="target_budget"
                            onChange={handleChange}
                            type="number"
                            placeholder="Budget cible"
                          />
                        </td>
                        <td>
                          <input
                            value={date_start}
                            type="date"
                            name="date_start"
                            onChange={handleChange}
                            placeholder="Date de début"
                            min={new Date().toISOString().split("T")[0]}
                          />
                        </td>
                        <td>
                          <input
                            value={date_end}
                            type="date"
                            name="date_end"
                            onChange={handleChange}
                            placeholder="Date de fin"
                            min={date_start}
                          />
                        </td>
                        <td className="" style={{ maxWidth: "250px" }}>
                          <Autocomplete
                            className="autocomplete"
                            multiple
                            value={campagnes}
                            id="checkboxes-tags-demo"
                            onChange={handleChangeCampaign}
                            options={campaignsList}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.name}
                            renderOption={(props, option, { selected }) => (
                              <li {...props} style={{ flex: 1 }}>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option.name}
                              </li>
                            )}
                            style={{ width: 180 }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </td>
                        <td style={{ maxWidth: "20px" }}>
                          <input
                            style={{ maxWidth: "100%" }}
                            value={threshold}
                            name="threshold"
                            type="number"
                            onChange={handleChange}
                            placeholder="Seuil Alerte"
                          />
                        </td>
                        <td className="pause_limite">
                          <input
                            checked={ad_pause_threshold}
                            type="checkbox"
                            name="ad_pause_threshold"
                            onChange={handleChange}
                            placeholder="Mettre en pause les pubs si limite"
                          />
                        </td>
                        <td>
                          <input
                            value={threshold}
                            name="threshold"
                            type="number"
                            onChange={handleChange}
                            placeholder="Consommé"
                          />
                        </td>
                        <td>
                          <div className="actions">
                            <i
                              className="fas fa-check-circle"
                              onClick={handleSave}
                            ></i>
                            <i
                              className="fas fa-trash"
                              onClick={handleHideForm}
                            ></i>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </form>
          </div>
          <div className="pagination">
            <Pagination
              count={parseInt(alertList.length / count)}
              onChange={handleChangePage}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ControleBudget;
