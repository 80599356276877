import React from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import "./style.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

const PostInstagram = ({ post }) => {
  const {
    instagramAccountChosen: { name },
  } = useSelector(state => state.auth.user);
  const dispatch = useDispatch();

  const selectItem = () => {
    dispatch({ type: "TOGGLE_SELECT_POST_INSTAGRAM", payload: post.id });
  };

  return (
    <div
      className={`instagram_screenshot screenshot ${
        post.isSelected ? "selected" : ""
      }`}
      onClick={selectItem}
    >
      <header
        className='entete_insta'
        style={post.isSelected ? { backgroundColor: "#e83e8c" } : {}}
      >
        <h4 className='title'>Instagram</h4>
      </header>
      <div className='account'>
        <div className='left'>
          <div className='image'>
            {/* <img src='/logo.png' alt='Creadster' /> */}
            <i className='fas fa-user-circle'></i>
          </div>
          <div className='text'>
            <h5>{name}</h5>
          </div>
        </div>
        <div className='right'>
          <i className='fas fa-ellipsis-h'></i>
        </div>
      </div>
      <div className='pxt' style={{ paddingLeft: 0, paddingRight: 0 }}>
        <img className='main_img' src={post.media_url} alt='Screenshot' />
      </div>
      <div className='learn_more px'>
        <p>
          <a
            rel='noopener noreferrer'
            target='_blank'
            href={post.permalink}
            style={{ textDecoration: "none", color: "#000" }}
          >
            Learn more
          </a>
        </p>
        <ChevronRightIcon />
      </div>
      <div className='pxy'>
        <div className='actions'>
          <div className='left'>
            <img src='/heart.png' alt='' />
            <img src='/chat-bubble.png' alt='' />
            <img src='/send.png' alt='' />
          </div>
          <div className='right'>
            <img src='/save.png' alt='' />
          </div>
        </div>
        {/* <h6 className='main_title_instagram' style={{ marginTop: 5 }}>
          Titre
        </h6> */}
        <p style={{ fontSize: 8, margin: "10px 0 10px 0" }}>
          {post.caption && post.caption.length > 150
            ? post.caption.slice(0, 150) + "..."
            : post.caption}
        </p>

        <div className='comments_date'>
          <h6>Voir tous les commentaires</h6>
          <p className='date'>Il y a 5 jours</p>
        </div>
      </div>
    </div>
  );
};
export default PostInstagram;
