import React, { useState, useEffect } from "react";
import store from "../../store";
import "./style.css";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import LeftMenu from "../../components/LeftMenu/LeftMenu";
import { useNavigate, useLocation } from "react-router-dom";
import TopRightMenu from "../../components/TopRightMenu/TopRightMenu";
import { FormattedMessage, useIntl } from "react-intl";
import Loader3 from "../../components/Loader3/Loader3";
import RightContainer from "../../components/RightContainer/RightContainer";
import InformationsGenerales from "./Sections/InformationsGenerales";
import ConfigurationAdsetDetaille from "./Sections/ConfigurationAdsetDetaille";
import PackagePublicite from "./Sections/PackagePubliciteUpdate";
import { safe_load_file_configuration, get_campaign_data, 
  saveCampaignNextPage, isAdvanced } from "../../store/actions/utils/campaignCreation";

const UpdatePub = ({ mainTitle }) => {
  const [advancedMode, setAdvancedMode] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const { adaccountId } = useSelector((state) => state.auth.user);

  const dispatch = useDispatch();
  const { toggleLeftMenu } = useSelector((state) => state.utils);

  const setToggleLeftMenu = () => {
    dispatch({ type: "SET_TOGGLE_LEFT_MENU" });
  };
  
  const {
    loading_campaign,
    file_configuration_id,
    advantagePlus,
    configuration: { 
      placements, useros, devices, wireless },
    load_campaign
  } = useSelector((state) => state.creationCampagne);
  const { loading_ref_campaign, load_ref } = useSelector(state=> state.creationCampagneRef);

  const intl = useIntl();

  const {
    userData: { id: user_id },
  } = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (load_campaign && file_configuration_id){
      safe_load_file_configuration(dispatch, adaccountId, file_configuration_id);
    }
  }, [load_campaign, file_configuration_id])

  useEffect(() => {
    if (load_ref){
      get_campaign_data(dispatch, adaccountId, user_id, true);
    }
  }, [load_ref])

  useEffect(()=> {
    const state = store.getState()
    const  {
      placements, 
      useros, 
      devices, 
      wireless } = state.creationCampagne.configuration;
    const {type, attribution_spec, product_catalog_id} = state.creationCampagne.informationsGenerales;
    setAdvancedMode(isAdvanced(type, attribution_spec, product_catalog_id, placements, useros, devices, wireless))
  }, [loading_campaign])
  
  return (
    <div className="screen_creation_campagne">
      <LeftMenu />
      <RightContainer>
        <header className="right_content_header">
          <div className="back_title">
            {toggleLeftMenu ? (
              <i
                onClick={() => setToggleLeftMenu((prevState) => !prevState)}
                style={{ marginRight: 30, cursor: "pointer" }}
                className="fas fa-bars fa-2x"
              ></i>
            ) : null}
            <div
              className="back"
              onClick={() => {
                navigate("/campagne/liste");
                saveCampaignNextPage(dispatch, "");
                dispatch({ type: "CLEAR_ALL_INPUTS_CREATION_CAMPAIGN" });
              }}
            >
              <img src="/left-arrow 2.png" alt="Retour" />
            </div>
            <h1 className="right_content_header_title">{mainTitle}</h1>
          </div>
          <div className="client">
            <TopRightMenu disabledAccounts={true} />
          </div>
        </header>
        <div className="right_content_main">
          {loading_campaign || loading_ref_campaign ? (
            <Loader3 />
            ) : (
            <div className="sections">
              <InformationsGenerales
                disabledMode={true}
                advancedMode={advancedMode}
              />
              <div style={{ margin: "30px 0" }}>
                <PackagePublicite />
              </div>

              <ConfigurationAdsetDetaille
                disabledMode={advantagePlus}
              />
            </div>
          )}
          <div className="d-fe">
            <button
              disabled={false}
              style={
                false
                  ? { cursor: "not-allowed", backgroundColor: "grey" }
                  : {}
              }
              onClick={() => {
                saveCampaignNextPage(dispatch, location)
                  .then((result)=>{
                    if (result){
                      dispatch({type:"SET_STATE_PROTECT_DETAILLED_ADSETS",payload:true});
                      navigate("/campagne/update-pub/confirm/");
                    }
                    else{
                      toast.error("Fichier de configuration de campagne invalide")
                    }
                  })
              }}
            >
              <FormattedMessage
                defaultMessage="Mettre à jour"
                id="update_pub_screen_update_button"
              />
            </button>
          </div>
        </div>
      </RightContainer>
    </div>
  );
};

export default UpdatePub;
