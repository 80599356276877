import React from "react";
import "./style.css";
import { useSelector } from "react-redux";
import { inverseCleValeur } from "../../utils";

const ApercuSimple = () => {
  const {
    liens,
    titre: { titres },
    texte: { textes },
    image: { filteredImages },
    video: { filteredVideos },
    ctas,
  } = useSelector((state) => state.creationPackagePublicite);

  const selectedImage = filteredImages.filter(
    (img) => img && img.isSelected
  )[0];
  const selectedVideo = filteredVideos.filter(
    (vid) => vid && vid.isSelected
  )[0];
  const selectedTitle = titres.filter((title) => title && title.isSelected)[0];
  const selectedBody = textes.filter((body) => body && body.isSelected)[0];
  const selectedLink = liens.filter((link) => link && link.isSelected)[0];
  const selectedCta = ctas.filter((cta) => cta && cta.isSelected)[0];

  const { adaccountName: adAccountName } = useSelector(
    (state) => state.auth.user
  );

  const {
    callToActions,
  } = useSelector((state) => state.creationCampagneRef);

  const invertedCtas = inverseCleValeur(callToActions);

  return (
    <div className="single_contenu_apercu_simple">
      <div className="facebook_screenshot screenshot">
        {/* <header className='entete_fcb'>
          <h4 className='entete_title'>Facebook</h4>
        </header> */}
        <div className="account">
          <div className="left">
            <div className="image">
              {/* <img
                src='/laduree_icon.png'
                style={{ borderRadius: "50%" }}
                alt='Creadster'
              /> */}
              <i className="fas fa-user-circle"></i>
            </div>
            <div className="text">
              <h5>{adAccountName ? adAccountName : "account"}</h5>
            </div>
          </div>
          <div className="right">
            <i className="fas fa-ellipsis-h"></i>
          </div>
        </div>
        <div className="pxt">
          <h4 className="title">
            {/* {titre.length > 40 ? titre.slice(0, 40) + "..." : titre} */}
            {/* {titre} */}
          </h4>
          <div className="description" id="texte-facebook-screenshot">
            <p>
              <span
                style={{
                  width: "10%",
                }}
              >
                {selectedBody ? (
                  <pre style={{ whiteSpace: "pre-wrap" }}>
                    {selectedBody.content}{" "}
                  </pre>
                ) : (
                  "Ne ratez pas nos 2 derniers articles sur comment optimiser vos publicités Facebook appliqués à différents secteurs 🚀"
                )}
              </span>
              {/* {!extendTexteFCB && message.length > minLength && (
                <span
                  style={{ fontWeight: "bold", cursor: "pointer" }}
                  onClick={expandTexteFacebookScreenshot}
                >
                  ... Lire plus
                </span>
              )}
              {extendTexteFCB && (
                <span
                  style={{ fontWeight: "bold", cursor: "pointer" }}
                  onClick={reduceTexteFacebookScreenshot}
                >
                  &nbsp; Lire moins
                </span>
              )} */}
            </p>
          </div>
        </div>
        {selectedImage ? (
          <img className="main_img" src={selectedImage.url} alt="Screenshot" />
        ) : selectedVideo ? (
          <video
            style={{ width: "100%" }}
            controls
            src={selectedVideo.source}
          ></video>
        ) : (
          <img className="main_img" src="/apercu.jpg" alt="Screenshot" />
        )}
        {/* <img className='main_img' src='/apercu.png' alt='Screenshot' /> */}
        <footer className="screenshot_footer">
          <div className="screenshot_footer_text">
            <pre className="screenshot_footer_text_title">
              {/* {title.length > 55 ? title.substring(0, 50) + " ..." : title} */}
              {selectedTitle ? selectedTitle.content : "A partir de 29€"}
            </pre>
          </div>
          {/* {call_to_action && ( */}
          <div className="screenshot_footer_cta">
            {selectedCta ? (
              <button>
                <a href={selectedLink ? selectedLink.content : "#"}>
                  {invertedCtas[selectedCta.content]}
                </a>
              </button>
            ) : (
              <button>
                <a href="https://facebook.com">En savoir plus</a>
              </button>
            )}
            {/* <button>{call_to_action}</button> */}
          </div>
          {/* )} */}
        </footer>
        <div className="interactions">
          <p>
            <i className="far fa-thumbs-up"></i>
            J'aime
          </p>
          <p>
            <i className="far fa-comment-alt"></i>
            Commenter
          </p>
          <p>
            <i className="fas fa-share"></i>
            Partager
          </p>
        </div>
      </div>
      {/* <div className='instagram_screenshot screenshot'>
        <header className='entete_insta'>
          <h4 className='title'>Instagram</h4>
        </header>
        <div className='account'>
          <div className='left'>
            <div className='image'>
              <img src='/logo.png' alt='Creadster' />
            </div>
            <div className='text'>
              <h5>Creadster</h5>
            </div>
          </div>
          <div className='right'>
            <i className='fas fa-ellipsis-h'></i>
          </div>
        </div>
        <div className='pxt' style={{ paddingLeft: 0, paddingRight: 0 }}>
          <img className='main_img' src='/apercu.png' alt='Screenshot' />
        </div>
        <div className='learn_more px'>
          <p>
            <a
              href={link}
              target='_blank'
              style={{ textDecoration: "none", color: "#000" }}
            >
              {call_to_action ? call_to_action : "Learn more"}
            </a>
          </p>
          <ChevronRightIcon />
        </div>
        <div className='pxy'>
          <div className='actions'>
            <div className='left'>
              <img src='/heart.png' alt='' />
              <img src='/chat-bubble.png' alt='' />
              <img src='/send.png' alt='' />
            </div>
            <div className='right'>
              <img src='/save.png' alt='' />
            </div>
          </div>
          <h6 className='main_title_instagram' style={{ marginTop: 5 }}>
            {title}
          </h6>
          {message && (
            <p style={{ fontSize: 8, margin: "10px 0 10px 0" }}>
              <span
                style={{ width: "100%" }}
                dangerouslySetInnerHTML={{
                  __html: texteScreenshotInsta,
                }}
              ></span>
              {!extendTexteInsta && message.length > minLength && (
                <span
                  style={{ fontWeight: "bold", cursor: "pointer" }}
                  onClick={expandTexteInstagramScreenshot}
                >
                  ... Lire plus
                </span>
              )}
              {extendTexteInsta && (
                <span
                  style={{ fontWeight: "bold", cursor: "pointer" }}
                  onClick={reduceTexteInstagramScreenshot}
                >
                  &nbsp; Lire moins
                </span>
              )}
            </p>
          )}

          <div className='comments_date'>
            <h6>Voir tous les commentaires</h6>
            <p className='date'>Il y a 5 jours</p>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default ApercuSimple;
