import React, { useState } from "react";
import { existingEvents, getName, isValidNumber } from "../../utils";

const MoreInformationsCmp = ({ item }) => {
  const [showTags, setShowTags] = useState(false);
  return (
    <React.Fragment>
      <span
        className="toggle_span"
        onClick={() => setShowTags((prevState) => !prevState)}
      >
        {showTags ? (
          <React.Fragment>
            <h4>Voir moins</h4>
            <i className="fa fa-chevron-up"></i>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <h4>Voir plus</h4>
            <i className="fa fa-chevron-down"></i>
          </React.Fragment>
        )}
      </span>
      <div
        className={`${showTags ? "additionnal_items" : ""}`}
        style={showTags ? {} : { display: "none" }}
      >
        {[
          "inline_link_clicks",
          "omni_view_content",
          "landing_page_view",
          "omni_add_to_cart",
          "omni_purchase",
          "post_reaction",
          "Like",
          "comment",
          "onsite_conversion_post_save",
          "video_view"
        ].map((eventName, index) => (
          <div className="ROAS" key={index}>
            <h4>Coût par {getName(existingEvents, eventName)}</h4>
            <div className="value">
              <p>
                {item[`Cost per ${eventName}`] &&
                  isValidNumber(item[`Cost per ${eventName}`])
                  ? item[`Cost per ${eventName}`].toFixed(2)
                  : "-"}
              </p>
            </div>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
};
export default MoreInformationsCmp;
