import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../../../store/axios.instance";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import { valeurExiste } from "../../../../utils";

const TitleItem = ({ texte, showTags }) => {
  // eslint-disable-next-line
  const [loader, setLoader] = useState(false);
  const [newTag, setNewTag] = useState("");
  const dispatch = useDispatch();

  const { adaccountId } = useSelector((state) => state.auth.user);

  const deleteText = (title) => {
    if (window.confirm("Voulez-vous vraiment supprimer ce texte ?")) {
      axiosInstance
        .delete(`/${adaccountId}/AdTitles/${title.id}`)
        .then(() => {
          setTimeout(() => {
            dispatch({
              type: "DELETE_TITLE_CREATION_PACKAGE_PUB_SUCCESS",
              payload: title.id,
            });

            toast.success("Titre supprimé avec succès");

            setLoader(false);

            // getTitles(adaccountId)
          }, 500);
        })
        .catch((err) => {
          dispatch({
            type: "DELETE_TITLE_CREATION_PACKAGE_PUB_FAIL",
          });
          console.log(err);
          setLoader(false);
        });
    }
  };

  const handleChangeNewTag = (e) => {
    setNewTag(e.target.value);
  };

  const saveNewTag = (e, title) => {
    if (e.key === "Enter") {
      if (!newTag) {
        toast.error("Veuillez insérer un tag");
        return;
      }

      // dispatch({
      //   type: "SAVE_TAG_TEXTE_CREATION_PACKAGE_PUB",
      //   payload: { id, tag: newTag },
      // });
      setLoader(true);

      const dataToSend = {
        content: title.content,
        keywords: title.keywords ? [...title.keywords, newTag] : [newTag],
      };

      axiosInstance
        .post(`/${adaccountId}/AdTitles/addkeywords`, [dataToSend])
        .then(() => {
          dispatch({
            type: "UPDATE_TITLE_CREATION_PACKAGE_PUBLICTE_SUCCESS",
          });

          toast.success("Tag ajouté avec succès");

          setLoader(false);

          dispatch({
            type: "ADD_TAG_TITLE_LOCALLY",
            payload: { id: title.id, tag: newTag },
          });
        })
        .catch((err) => {
          dispatch({
            type: "UPDATE_TITLE_CREATION_PACKAGE_PUBLICTE_FAIL",
          });
          console.log(err);
          setLoader(false);
        });

      setNewTag("");
    }
  };

  const deleteTag = (title, tag) => {
    if (window.confirm("Voulez-vous vraiment supprimer ce tag ?")) {
      setLoader(true);

      const dataToSend = {
        content: title.content,
        keywords: title.keywords.filter((item) => item !== tag),
      };

      axiosInstance
        .post(`/${adaccountId}/AdTitles/newkeywords`, [dataToSend])
        .then(() => {
          dispatch({
            type: "DELETE_TAG_TITLE_CREATION_PACKAGE_PUB",
            payload: {
              id: title.id,
              tag: tag,
            },
          });

          toast.success("Tag supprimé avec succès");

          setLoader(false);
        })
        .catch((err) => {
          dispatch({
            type: "DELETE_TAG_TITLE_CREATION_PACKAGE_PUB",
          });
          console.log(err);
          setLoader(false);
        });
    }
  };
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();

  const tagToEdit = searchParams.get("tagToEdit");

  const { nomPackage } = useSelector((state) => state.creationPackagePublicite);

  const toggleSelectItem = (id) => {
    if (tagToEdit && texte.isSelected) {
      if (valeurExiste(texte.keywords, nomPackage)) {
        const dataToSend = {
          content: texte.content,
          keywords: texte.keywords.filter((item) => item !== nomPackage),
        };

        axiosInstance
          .post(`/${adaccountId}/AdTitles/newkeywords`, [dataToSend])
          .then(() => {
            dispatch({
              type: "DELETE_TAG_TITLE_CREATION_PACKAGE_PUB",
              payload: {
                id: texte.id,
                tag: nomPackage,
              },
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }

    dispatch({ type: "TOGGLE_SELECT_TITRE", payload: id });
  };

  return (
    <div
      className={`text ${texte.isSelected ? "selected" : ""}`}
      onClick={() => toggleSelectItem(texte.id)}
    >
      <i
        onClick={(e) => {
          e.stopPropagation();
          deleteText(texte);
        }}
        className="fas close fa-times"
      ></i>
      <h5>
        <pre style={{ whiteSpace: "pre-wrap" }}>{texte.content}</pre>
      </h5>
      {showTags && texte.keywords && (
        <React.Fragment>
          <div className="tags">
            {texte.keywords.length > 0 ? (
              texte.keywords.map((tg, i) => (
                <div key={i} className="tag">
                  <p>{tg}</p>
                  <i
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteTag(texte, tg);
                    }}
                    className="fas fa-times"
                  ></i>
                </div>
              ))
            ) : (
              <h6>Aucun tag pour l'instant</h6>
            )}
          </div>
          <div className="add_tag" onClick={(e) => e.stopPropagation()}>
            <input
              onKeyDown={(e) => saveNewTag(e, texte)}
              value={newTag}
              onChange={handleChangeNewTag}
              type="search"
              name=""
              id=""
              placeholder="Tags"
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default TitleItem;
