import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { numberWithSpaces } from "../../utils";
import MoreInformationsCmp from "./MoreInformationsCmp";

const TopPublicites = ({report}) => {
  const [showAll, setShowAll] = useState(false);
  const [sorting, setSorting] = useState({ column: "score", order: "desc" });
  const initialItemShow = 3;
  const itemShow = showAll ? report.length : initialItemShow;


  const handleSorting = (column) => {
    // Toggle sorting order if the same column is clicked again
    if (column === sorting.column) {
      setSorting({
        column,
        order: sorting.order === "asc" ? "desc" : "asc",
      });
    } else {
      setSorting({ column, order: "desc" });
    }
  };

  const sortReport = (data) => {
    if (sorting.column) {
      return data.slice().sort((a, b) => {
        if (sorting.order === "asc") {
          return a[sorting.column] > b[sorting.column] ? 1 : -1;
        } else {
          return a[sorting.column] < b[sorting.column] ? 1 : -1;
        }
      });
    }
    return data;
  };

  const renderItem = (item, index) =>{
    return (
      <div className="listing_item_campaign" key={index}>
        {item.combination === "['image_asset']" && (
          <React.Fragment>
            <div className="listing_item_title"> 
              <h3 title={item.comb_value[0].name}>
                {item.comb_value && item.comb_value[0].name
                  ? item.comb_value[0].name
                  : ""}
              </h3>
            </div>
            <img
              className="main_image"
              src={
                item.comb_value && item.comb_value[0].url
                  ? item.comb_value[0].url
                  : "/buche1.png"
              }
              alt=""
            />
          </React.Fragment>
        )}
        {item.combination === "['video_asset']" && (
          <React.Fragment>
            <div className="listing_item_title"> 
              <h3 title={item.comb_value[0].name}>
                {item.comb_value && item.comb_value[0].title
                  ? item.comb_value[0].title
                  : ""}
              </h3>
            </div>
            <video
              className="main_image" controls>
              <source src={item.comb_value[0].source} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </React.Fragment>                
        )}
        <div className="listing_item_campaign_wrapper">
          <div className="listing_item_campaign_content">
            <div className="montant">
              <h4>montant dépensé</h4>
              <div className="value">
                <p>
                  {item.spend && numberWithSpaces(item.spend.toFixed(2))}{" "}
                  €
                </p>
              </div>
            </div>
            <div className="impressions">
              <h4>impressions</h4>
              <div className="value">
                <p>
                  {item.impressions &&
                    numberWithSpaces(item.impressions.toFixed(0))}
                </p>
              </div>
            </div>
            <div className="ctr">
              <h4>CTR</h4>
              <div className="value">
                <p>
                  {item.inline_link_clicks && item.impressions
                    ? numberWithSpaces(
                        (
                          (+item.inline_link_clicks / +item.impressions) *
                          100
                        ).toFixed(2)
                      ) + " %"
                    : ""}{" "}
                </p>
              </div>
            </div>
            {item.hasOwnProperty("score") && (
              <div className="ROAS">
                <h4>Score</h4>
                <div className="value">
                  <p>{item.score && item.score.toFixed(2)}</p>
                </div>
              </div>
            )}
          </div>
          <MoreInformationsCmp item={item}/>
        </div>
      </div>
  )}

  return (
    <div className="top_publicites">
      <header>
        <h6 className="title">
          <FormattedMessage
            defaultMessage="Performance des visuels"
            id="detail_campagne_screen_7"
          />
        </h6>
        
        <div className="sorting-options">
          <div className="select-border-red">
            <select
              value={sorting.column}
              onChange={(e) => handleSorting(e.target.value)}
            >
              <option value="">Sort by...</option>
              <option value="Rate per inline_link_clicks">CTR</option>
              <option value="score">Score</option>
            </select>
          </div>
          <div className="sorting-arrow">
            {sorting.column && (
              <i
                className={`fas fa-sort-${sorting.order === "asc" ? "down" : "up"}`}
                onClick={() => handleSorting(sorting.column)}
              ></i>
            )}
          </div>
        </div>
      </header>
      {report && report.length > 0 ? (
        <div className="content">
          {sortReport(report).slice(0, itemShow).map(
            (visualItem, index) => renderItem(visualItem, index))}
          </div>
        ): (
          <p style={{ textAlign: "center" }}>Aucune donnée disponible</p>)}
      {(report.length > 3 )&& (
          <div id="visual_toggle" className="expand-button" 
            onClick={e => { 
              const element = document.getElementById('visual_toggle');
              if (showAll){
                setTimeout(()=>
                  element.scrollIntoView({
                    behavior: 'instant', // Optional: smooth scrolling animation
                    block: 'center',     // Optional: align the top of the element to the top of the visible area
                  }),50)
              }
              setShowAll(!showAll)
            }}
            >
            <i className={`fas fa-chevron-${showAll ? "up" : "down"}`}></i>
          </div>
        )}
    </div>
  );
};
export default TopPublicites;
