import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import axiosInstance from "../../../store/axios.instance";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import GenericSection from "./genericSection"

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


function getRowLang(data){
  const lang = navigator.language.substring(0, 2).toUpperCase();
  if (lang === "FR"){
      return data.find(row => row.lang === lang);
  }
  else {return data.find(row => row.lang === "EN");}
}

function transformRefDico(ref){
  return Object.keys(ref).map((key) => (
      {
        name: key,
        value: ref[key],
      })
  );
}


const getReferenceData = (var_type) => {
  return axiosInstance
    .get(`/facebook_variables_reference?var_type=${var_type}`)
    .then((res) => {
      const data = getRowLang(res.data);
      return transformRefDico(data["names"]);
     })
    .catch(() => {
      // toast.error("Une erreur s'est produite, veuillez réessayer plus tard");
      // setLoader(false);
    });
};


const getPlatformPositions = async (publisherPlatforms) => {
  if (publisherPlatforms) {
      const promises = Promise.all(
          publisherPlatforms.map((platform) => {
            return axiosInstance  
                .get(
                `/facebook_variables_reference?var_type=${(platform.value.toUpperCase())}_POSITIONS`
                )
                .then((res) => {
                    const data = getRowLang(res.data)
                    return ({[platform.value.toLowerCase()]: data["names"]});
                })
                .catch(() => {
                // toast.error("Une erreur s'est produite, veuillez réessayer plus tard");
                // setLoader(false);
                });
        }
      )
    );
    let allPositions = []
    await promises.then((results) => {
      allPositions = results.reduce((acc, cur) => {
        acc = {...acc, ...cur};
        return acc;
      }, {});
    });
    return allPositions
  }
};

const loadRefData = async (dispatch) => {
  const publisher_platforms = await getReferenceData("PUBLISHER_PLATFORMS");
  const device_platforms = await getReferenceData("DEVICE_PLATFORMS");
  const user_os = await getReferenceData("USER_OS");
  const wireless_carrier = await getReferenceData("WIRELESS_CARRIER");
  const platform_positions = await getPlatformPositions(publisher_platforms);
  const platform_positions_formatted =[].concat(
    ...Object.keys(platform_positions).map(
      (key)=>(
        (Object.values(platform_positions[key]).map((pos)=>(
          {
            name: key,//it should be the translated name of the platform
            value: key,//this is utterly wrong i'm not using anything that should be used
            position: pos //one of them should be the translated name the other the value which is wich no idea
          })
        ))
      )
    )
  );
  dispatch({
      type: "SET_CONFIGURATION_REFERENCE",
      payload: {
          publisher_platforms: publisher_platforms,
          device_platforms: device_platforms,
          user_os: user_os,
          wireless_carrier: wireless_carrier,
          platform_positions: platform_positions,
          platform_positions_formatted: platform_positions_formatted
      }
  })
}


const Configuration = ({ disabledMode }) => {
  const dispatch = useDispatch();
  const {
    loaded_data,
    publisher_platforms,
    device_platforms,
    user_os,
    wireless_carrier,
    platform_positions_formatted
  } = useSelector((state) => state.configuration);


  const {
    configuration: {
      placements,
      publisherPlateforms,
      useros,
      devices,
      wireless,
    },
    informationsGenerales: { type },
  } = useSelector((state) => state.creationCampagne);
  const [toggle, setToggle] = useState(false);

  useEffect(()=>{
    if (!loaded_data){
      loadRefData(dispatch);
    }
  }, [loaded_data]);


  const handleChangePublisherPlateforms = (e, newValue) => {
    const posi = platform_positions_formatted.filter((option) =>
      newValue.some((platform) => platform.value === option.name)
    );

    if (placements.length > 0) {
      for (let i = 0; i < posi.length; i++) {
        const positionExists = placements.some(
          (p) => p.position === posi[i].position
        );
        if (placements.some((p) => p.name === posi[i].name)) {
          if (!positionExists) {
            posi.splice(i, 1);
            i--;
          }
        }
      }
    }

    const uniqueArray = newValue.filter(
      (object, index, self) =>
        index ===
        self.findIndex(
          (obj) =>
            obj.value === object.value && obj.position === object.position
        )
    );

    dispatch({
      type: "HANDLE_CHANGE_CONFIGURATION",
      payload: {
        name: "publisherPlateforms",
        value: uniqueArray,
      },
    });

    dispatch({
      type: "HANDLE_CHANGE_CONFIGURATION",
      payload: {
        name: "placements",
        value: posi,
      },
    });
  };

  const handleChangePositions = (e, newValue) => {
    const uniqueArray = newValue.filter(
      (object, index, self) =>
        index ===
        self.findIndex(
          (obj) =>
            obj.value === object.value && obj.position === object.position
        )
    );

    // const uniqueArr = Array.from(new Set(newValue.map(JSON.stringify))).map(JSON.parse);
    if (placements.length > 0) {
      const positionExists = placements.some(
        (p) => p.position === newValue.position
      );
      if (!positionExists) {
        dispatch({
          type: "HANDLE_CHANGE_CONFIGURATION",
          payload: {
            name: "placements",
            value: uniqueArray,
          },
        });
      }
    }
  };

  const handleChangeDevices = (e, newValue) => {
    dispatch({
      type: "HANDLE_CHANGE_CONFIGURATION",
      payload: {
        name: "devices",
        value: newValue,
      },
    });
  };

  const handleChangeUserOs = (e, newValue) => {
    dispatch({
      type: "HANDLE_CHANGE_CONFIGURATION",
      payload: {
        name: "useros",
        value: newValue||"",
      },
    });
    if (!newValue || newValue.length === 0) {
      dispatch({
        type: "HANDLE_CHANGE_CONFIGURATION",
        payload: {
          name: "devices",
          value: [],
        },
      });
    } else {
      dispatch({
        type: "HANDLE_CHANGE_CONFIGURATION",
        payload: {
          name: "devices",
          value: [{ name: "mobile", value: "mobile" }],
        },
      });
    }
  };

  const handleChangeWireless = (e) => {
    dispatch({
      type: "HANDLE_CHANGE_CONFIGURATION",
      payload: {
        name: "Wifi",
        value: "wireless",
      },
    });
  };

  return (
    <GenericSection
      disabledMode={disabledMode}
      toggle={toggle}
      setToggle={setToggle}
      title={
        <FormattedMessage
          defaultMessage="Configuration"
          id="create_campaign_configuration_title"
        />}
      test_validation={placements && publisherPlateforms && useros && devices}//not actually right but w/e
      content={
        <form action="">
          <div className="row">
            <div className="input_group">
              <label htmlFor="">Publisher Platforms</label>
              <Autocomplete
                multiple
                value={publisherPlateforms}
                name="publisherPlateforms"
                id="publisherPlateforms"
                onChange={handleChangePublisherPlateforms}
                options={publisher_platforms}
                disableCloseOnSelect
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                      //defaultChecked={true}
                    />
                    {option.name}
                  </li>
                )}
                style={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField variant="standard" {...params} />
                )}
              />
            </div>
            <div className="input_group">
              <label htmlFor="">Placements</label>
              <div className="input_group centre_interet">
                <Autocomplete
                  value={placements}
                  multiple
                  id="placements"
                  name="placements"
                  options={platform_positions_formatted || []}
                  onChange={handleChangePositions}
                  groupBy={(option) => option.name}
                  getOptionLabel={(option) => option.position}
                  isOptionEqualToValue={(option, value) => (option.value === value.value)&&(option.position===value.position)}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.position}
                    </li>
                  )}
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField variant="standard" {...params} />
                  )}
                  getOptionDisabled={(option) =>
                    !publisherPlateforms.some(
                      (platform) => platform.value === option.name
                    )
                  }
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="input_group">
              <label htmlFor="">Devices</label>
              <Autocomplete
                value={devices}
                multiple
                name="devices"
                onChange={handleChangeDevices}
                options={device_platforms || []}
                //disableCloseOnSelect
                disabled={type === "APP_INSTALLS" && true}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )}
                style={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField variant="standard" {...params} />
                )}
                getOptionDisabled={(option) =>
                  Object.keys(useros).length > 0 && option.value === "desktop"
                }
              />
            </div>
            <div className="input_group">
              <label htmlFor="">User OS</label>
              <div className="input_group centre_interet">
                <Autocomplete
                  name="useros"
                  onChange={handleChangeUserOs}
                  options={user_os}
                  value={useros}
                  getOptionLabel={(option) => (option && option.name) || ''}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  )}
                  style={{
                    width: "100%",
                    borderBottom: (type === "APP_INSTALLS" && Object.keys(useros).length === 0) && "1px solid red",
                  }}
                  renderInput={(params) => (
                    <TextField variant="standard" {...params} />
                  )}
                />
              </div>
              {(type === "APP_INSTALLS" && Object.keys(useros).length === 0) && (
                <span className="messageError">
                  <FormattedMessage
                    defaultMessage="User OS obligatoire"
                    id="create_campaign_configuration_user_os_error"
                  />
                </span>
              )}
            </div>
          </div>
          <div className="input_group objectif">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleChangeWireless}
                    defaultChecked={!!wireless}
                  />
                }
                label={
                  wireless_carrier.length > 0 ? wireless_carrier[0].name : ""
                }
              />
            </FormGroup>
          </div>
        </form>
      }
    />
  );
};

export default Configuration;
