import React from "react";
import "./Loader.css";

const Loader = ({ message }) => {
  return (
    <div className='loader'>
      <div className='overlay'>
        <div className='content'>
          <div className='center'>
            <div className='lds-dual-ring'></div>
          </div>
          <h3>
            {message && message.length > 0 ? message : "Opération en cours..."}
          </h3>
        </div>
      </div>
    </div>
  );
};

export default Loader;
