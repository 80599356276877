import React from "react";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import "./style.css";
import { FormattedMessage } from "react-intl";

const SortColumn = ({ column }) => {
  const dispatch = useDispatch();

  // Menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const sortAscending = () => {
    dispatch({ type: "SORT_ASCENDING_BY_KEY_COLUMN", payload: column });
    setAnchorEl(null);
  };

  const sortDescending = () => {
    dispatch({ type: "SORT_DESCENDING_BY_KEY_COLUMN", payload: column });
    setAnchorEl(null);
  };

  return (
    <div className='sort_column'>
      <Button
        id='basic-button'
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup='true'
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <i className='fas fa-sort-down'></i>
      </Button>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={sortAscending}>
          <FormattedMessage
            defaultMessage='Tri décroissant'
            id='sort_column_cmp_desc'
          />

          <span style={{ marginLeft: 5 }}>
            <i className='fas fa-arrow-down'></i>
          </span>
        </MenuItem>
        <MenuItem onClick={sortDescending}>
          <FormattedMessage
            defaultMessage='Tri croissant'
            id='sort_column_cmp_asc'
          />

          <span style={{ marginLeft: 5 }}>
            <i className='fas fa-arrow-up'></i>
          </span>
        </MenuItem>
      </Menu>
    </div>
  );
};
export default SortColumn;
