import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import "./style.css";

const StepsBase = ({steps, activeStep}) => {
  
return (
    <div className="steps">
    {steps.map((step, index) => (
        <React.Fragment key={step.id}>
        <div className={`step ${activeStep === step.id ? 'active' : ''}`}>
            {activeStep === step.id ? (
            <p>
                <FormattedMessage
                defaultMessage={step.defaultMessage}
                id={step.labelId}
                />
            </p>
            ) : (
            <Link to={step.path} >
                <p>
                <FormattedMessage
                    defaultMessage={step.defaultMessage}
                    id={step.labelId}
                />
                </p>
            </Link>
            )}
        </div>
        {index < steps.length - 1 && (
            <img src="/fleche-step.png" alt="Next Step" />
        )}
        </React.Fragment>
    ))}
    </div>
  );
};
export default StepsBase;