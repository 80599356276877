import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { numberWithSpaces } from "../../utils";
import axiosInstance from "../../store/axios.instance";
import Loader3 from "../Loader3/Loader3";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";
import Switch from "@mui/material/Switch";

import { Menu, MenuItem } from "@mui/material";
import { FormattedMessage } from "react-intl";
import CampaignElements from "../../pages/Campagne/CampagneElements/CampagneElements";

const InsightsItem = ({
  navigateToViewCampaignDetailsScreen,
  baseNavigateToEditCampaign,
  insight,
  getSingleCampaignConfigurations,
  navigateToEditPubScreen
}) => {

  const {
    campaign_name,
    spend,
    impressions,
    campaign_id,
    singleCampaignConfigurationId = null,
    draft,
  } = insight;

  const { adaccountId } = useSelector((state) => state.auth.user);
  const [loader, setLoader] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [status, setStatus] = useState("");
  const [loaderStatus, setLoaderStatus] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [campaignIdToShow, setcampaignIdToShow] = useState(null);

  const [alerts, setAlerts] = useState([]);

  const intl = useIntl();

  const open = Boolean(anchorEl);
  const handleClickButton = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigateToEditCampaignScreen = async () => {
    handleClose();
    if (!singleCampaignConfigurationId ) {
      dispatch({
        type: "SET_ALERT_GENERAL_ERROR_MESSAGE",
        payload: "Vous ne pouvez pas modifier cette campagne !",
      });
      dispatch({ type: "SHOW_ALERT_GENERAL_ERROR_MESSAGE" });
    }

    else {
      baseNavigateToEditCampaign(singleCampaignConfigurationId);
    }
  };



  const {
    userData: { token: accessToken },
  } = useSelector((state) => state.auth.user);

  const getInsights = () => {
    dispatch({ type: "SET_LOADER_INSIGHTS" });

    axiosInstance
      .get(`/${adaccountId}/precomputed_insights?level=campaign`)
      .then((res) => {
        dispatch({ type: "GET_INSIGHTS", payload: res.data });
        dispatch({ type: "UNSET_LOADER_INSIGHTS" });
      })
      .catch((err) => {
        dispatch({ type: "UNSET_LOADER_INSIGHTS" });
        console.log(err);
        dispatch({
          type: "SET_ALERT_GENERAL_ERROR_MESSAGE",
          payload: err.message,
        });
        dispatch({ type: "SHOW_ALERT_GENERAL_ERROR_MESSAGE" });
      });
  };

  const getStatusCampaign = async () => {
    // setLoaderStatus(true);

    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_GRAPH_API
        }/${campaign_id}?fields=status&access_token=${
          accessToken
          // process.env.NODE_ENV === "development" ? dev_token : accessToken
        }`
        // `${process.env.REACT_APP_GRAPH_API}/${campaign_id}?fields=status&access_token=${dev_token}`
        // process.env.NODE_ENV === "development" ? dev_token : accessToken
      );

      if (response.status === 200) {
        setTimeout(() => {
          setStatus(response.data.status);
          // setLoaderStatus(false);
        }, 500);
      } else {
        //setTimeout(() => {
        // setLoaderStatus(false);
        //}, 500);
      }
    } catch (error) {
      console.log(error);

      //setTimeout(() => {
      // setLoaderStatus(false);
      //}, 500);
    }
  };

  const setStatusPaused = async () => {
    if (
      window.confirm(
        "Voulez-vous vraiment changer le status de cette campagne ?"
      )
    ) {
      setLoaderStatus(true);

      try {
        const response = await axios.post(
          `${
            process.env.REACT_APP_GRAPH_API
          }/${campaign_id}?status=PAUSED&access_token=${
            accessToken
            // process.env.NODE_ENV === "development" ? dev_token : accessToken
          }`
          // `${process.env.REACT_APP_GRAPH_API}/${campaign_id}?status=PAUSED&access_token=${dev_token}`
          // process.env.NODE_ENV === "development" ? dev_token : accessToken
        );

        if (response.status === 200) {
          setTimeout(() => {
            toast.success(
              intl.formatMessage({
                defaultMessage: "Statut inactif",
                id: "status_inactive_tipWsQ168",
              })
            );
            setLoaderStatus(false);

            getInsights();
          }, 500);
        } else {
          setTimeout(() => {
            setLoaderStatus(false);
          }, 500);
        }
      } catch (error) {
        console.log(error);

        setTimeout(() => {
          setLoaderStatus(false);
        }, 500);
      }
    }
  };

  const setStatusActive = async () => {
    if (
      window.confirm(
        "Voulez-vous vraiment changer le status de cette campagne ?"
      )
    ) {
      setLoaderStatus(true);

      try {
        const response = await axios.post(
          `${
            process.env.REACT_APP_GRAPH_API
          }/${campaign_id}?status=ACTIVE&access_token=${
            accessToken
            // process.env.NODE_ENV === "development" ? dev_token : accessToken
          }`
          // `${process.env.REACT_APP_GRAPH_API}/${campaign_id}?status=ACTIVE&access_token=${dev_token}`
          // process.env.NODE_ENV === "development" ? dev_token : accessToken
        );

        if (response.status === 200) {
          setTimeout(() => {
            toast.success(
              intl.formatMessage({
                defaultMessage: "Statut actif",
                id: "status_active_euL639",
              })
            );
            setLoaderStatus(false);

            getInsights();
          }, 500);
        } else {
          setTimeout(() => {
            setLoaderStatus(false);
          }, 500);
        }
      } catch (error) {
        if (error.response.data.error.error_user_title) {
          dispatch({
            type: "SET_ALERT_GENERAL_ERROR_MESSAGE",
            payload: error.response.data.error.error_user_title,
          });
          dispatch({ type: "SHOW_ALERT_GENERAL_ERROR_MESSAGE" });
        }

        setTimeout(() => {
          setLoaderStatus(false);
        }, 500);
      }
    }
  };

  const openModelCampaignElement = (cid) => {
    setcampaignIdToShow(cid);
    handleClose();
  };

  const closeModal = () => {
    setcampaignIdToShow(null);
  };

  const deleteFileCampaignConfiguration = () => {
    if (
      window.confirm(
        "Voulez-vous vraiment supprimer ce fichier de configuration ?"
      )
    ) {
      axiosInstance
        .delete(
          `/${adaccountId}/single_campaign_configuration/${singleCampaignConfigurationId}`
        )
        .then(() => {
          toast.success("Fichier de configuration supprimé");
          getInsights(adaccountId);
          getSingleCampaignConfigurations(adaccountId);
        })
        .catch((err) => console.log(err));
    }
  };

  const getAlerts = async (adacc) => {
    try {
      // /act_429278752423318/23853886964650487/alert?level=ad,adset,campaign
      const response = await axiosInstance.get(
        `/${adacc}/${campaign_id}/alert?level=ad,adset,campaign`
      );

      if (response.status === 200) {
        // console.log("Alerts ", response);
        // console.log("aaa", `/${adacc}/${campaign_id}/alert?level=ad`);
        setAlerts(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAlerts(adaccountId);
  }, [adaccountId]);

  useEffect(() => {
    getStatusCampaign();
  }, []);

  const { events } = useSelector((state) => state.insights);

  const selectedEvents = events.filter((event) => event && event.isChecked);

  if (loader)
    return (
      <tr>
        <td colSpan={8}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Loader3 />
          </div>
        </td>
      </tr>
    );

  return (
    <React.Fragment>
      <tr className="campagne">
        <td
          className="first_column"
          style={{
            background:
              singleCampaignConfigurationId && draft
                ? "rgb(242, 255, 251)"
                : "",
          }}
        >
          {loaderStatus ? (
            <Loader3 />
          ) : (
            <Switch
              onChange={status === "ACTIVE" ? setStatusPaused : setStatusActive}
              checked={status === "ACTIVE" ? true : false}
            />
          )}
        </td>
        <td
          className="first second_column"
          style={{
            background:
              singleCampaignConfigurationId && draft
                ? "rgb(242, 255, 251)"
                : "",
          }}
        >
          {singleCampaignConfigurationId && (
            <span className="brouillon">
              <i
                style={{
                  fontSize: "16px",
                  marginLeft: "3px",
                  color: "#888",
                }}
                className="fas fa-file-alt"
              ></i>
            </span>
          )}
          <p style={{ whiteSpace: "pre-wrap" }}>{campaign_name}</p>
          <div className="actions">
            <img
              src="/icons/edit.png"
              alt="edit"
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClickButton}
            />
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={() => navigateToEditCampaignScreen(campaign_id)}
                disabled={!singleCampaignConfigurationId}
              >
                <FormattedMessage
                  defaultMessage="Modifier campagne"
                  id="screen-liste-campagne-edit"
                />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigateToEditPubScreen(singleCampaignConfigurationId,campaign_id);
                }}
                disabled={!singleCampaignConfigurationId}
              >
                <FormattedMessage
                  defaultMessage="Mettre à jour les publicités"
                  id="screen-liste-campagne-edit-pub"
                />
              </MenuItem>
              <MenuItem onClick={() => openModelCampaignElement(campaign_id)}>
                <FormattedMessage
                  defaultMessage="Gérer les éléments"
                  id="screen-liste-campagne-campaign-elements"
                />
              </MenuItem>
            </Menu>
            <img
              src="/icons/view.png"
              alt="view"
              onClick={() => {
                navigateToViewCampaignDetailsScreen(campaign_id);
              }}
            />
            <img src="/icons/duplicate.png" alt="duplicate" />
            <i
              style={{
                fontSize: "16px",
                cursor:
                  singleCampaignConfigurationId && draft
                    ? "pointer"
                    : "not-allowed",
                marginLeft: "3px",
                color: singleCampaignConfigurationId && draft ? "#888" : "#bbb",
              }}
              onClick={() => {
                if (singleCampaignConfigurationId && draft) {
                  deleteFileCampaignConfiguration();
                }
              }}
              className="fas fa-trash-alt"
            ></i>
          </div>
        </td>
        <td
          style={{
            background:
              singleCampaignConfigurationId && draft
                ? "rgb(242, 255, 251)"
                : "",
          }}
        >
          {loaderStatus ? (
            <Loader3 />
          ) : alerts && alerts.length === 0 ? (
            <span className="status_ok"></span>
          ) : alerts &&
            Array.isArray(alerts) &&
            alerts.some((alert) => alert.alert === "red") ? (
            <span className="status_not_ok"></span>
          ) : (
            <span className="status_med"></span>
          )}
        </td>
        <td
          style={{
            background:
              singleCampaignConfigurationId && draft
                ? "rgb(242, 255, 251)"
                : "",
          }}
        >
          {numberWithSpaces(spend)}
        </td>
        <td
          style={{
            background:
              singleCampaignConfigurationId && draft
                ? "rgb(242, 255, 251)"
                : "",
          }}
        >
          {numberWithSpaces(impressions)}
        </td>
        {selectedEvents &&
          selectedEvents.length > 0 &&
          selectedEvents.map((event) => {
            return (
              <td
                style={{
                  background:
                    singleCampaignConfigurationId && draft
                      ? "rgb(242, 255, 251)"
                      : "",
                }}
              >
                {insight[event.id] && insight[event.id] !== undefined
                  ? event.id === "rate_per_inline_link_clicks"
                    ? `${(insight[event.id] * 100)
                        .toString()
                        .substring(0, 4)} %`
                    : numberWithSpaces(insight[event.id])
                    ? numberWithSpaces(insight[event.id])
                    : "-"
                  : "-"}
              </td>
            );
          })}
        <td
          className="column_actions"
          style={{
            background:
              singleCampaignConfigurationId && draft
                ? "rgb(242, 255, 251)"
                : "",
          }}
        >
        </td>
      </tr>
      {campaignIdToShow && (
        <CampaignElements
          campaignId={campaignIdToShow}
          campaignName={campaign_name}
          closeModal={closeModal}
        />
      )}
    </React.Fragment>
  );
};

export default InsightsItem;
