import React from "react";
import { useDispatch, useSelector } from "react-redux";
import "./style.css";

const ErrorMessage = () => {
  const dispatch = useDispatch();

  const { generalErrorMessage } = useSelector(state => state.utils);

  const fermerMessageError = () => {
    dispatch({ type: "HIDE_ALERT_GENERAL_ERROR_MESSAGE" });
  };

  return (
    <div className='error_message'>
      <div className='error_message__content'>
        <h5 className='title'>
          {generalErrorMessage ? generalErrorMessage : "Erreur ..."}
        </h5>
        <button onClick={fermerMessageError}>Fermer</button>
      </div>
    </div>
  );
};
export default ErrorMessage;
