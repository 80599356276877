import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { FormattedMessage } from "react-intl";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Switch from "@mui/material/Switch";
import Pagination from "@mui/material/Pagination";

import { toast } from "react-toastify";
import axios from "axios";

import axiosInstance from "../../../store/axios.instance";
import Loader3 from "../../../components/Loader3/Loader3";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Objectifs = () => {
  const [toggle, setToggle] = useState(false);
  const [loaderConnectedClients, setLoaderConnectedClients] = useState(false);
  const [loaderObjectifs, setLoaderObjectifs] = useState(false);

  const [editMode, setEditMode] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [campaigns, setCampaigns] = useState([]);

  const dispatch = useDispatch();

  const {
    adaccountId,
    connectedAccounts,
    userData: { id: user_id },
  } = useSelector((state) => state.auth.user);

  const {
    alertObjectifs: {
      objectifs,
      objectifId,
      target,
      threshold,
      metrics,
      all_campaigns,
      selectedCampaigns,
      status,
      pagination: { startIndex, endIndex, count },
    },
  } = useSelector((state) => state.alerts);

  const {
    userData: { token: accessToken },
  } = useSelector((state) => state.auth.user);

  const handleShowForm = () => {
    setShowForm(true);
  };

  const handleHideForm = () => {
    setShowForm(false);
  };

  const setStatus = (objectifId) => {
    dispatch({ type: "SET_STATUS_OBJECTIF", payload: { objectifId } });

    setTimeout(() => {
      handleEditStatus(objectifId);
    }, 500);
  };

  const setCampaignsSelection = () => {
    dispatch({ type: "SET_CAMPAIGNS_SELECTION_OBJECTIF" });
  };

  const handleChange = (e) => {
    dispatch({
      type: "ADD_OBJECTIF_DATA",
      payload: {
        name: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleChangeCampaign = (e, newValue) => {
    dispatch({ type: "SET_CAMPAIGNS_OBJECTIF", payload: newValue });
  };

  const getActiveCampaigns = async (ad_acc) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_GRAPH_API}/${ad_acc}/campaigns?
        filtering=[{"field": "effective_status", "operator": "IN", "value": ["ACTIVE"]}]&fields=name&access_token=${accessToken}`
      );
      // console.log("response",response.data);
      setCampaigns([]);
      setCampaigns(response.data.data);
      // dispatch({ type: "UNSET_LOADER_INSIGHTS" });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "SET_ALERT_GENERAL_ERROR_MESSAGE",
        payload: error.message,
      });
      dispatch({ type: "SHOW_ALERT_GENERAL_ERROR_MESSAGE" });
    }
  };

  const handleSaveAlertObjectif = () => {
    const objectToSend = {
      status,
      target,
      metrics,
      threshold,
      all_campaigns,
    };

    axiosInstance
      .put(`/${adaccountId}/AlertObjective`, selectedCampaigns, {
        params: objectToSend,
      })
      .then((res) => {
        // console.log(res.data); // setCamp(res.data)
        dispatch({
          type: "ADD_NEW_ALERT_OBJECTIF",
          payload: res.data,
        });
        toast.success("Contrôle Budget créé avec succès");
        setShowForm(false);
        dispatch({ type: "CLEAR_ALERT_OBJECTIF" });
      })
      .catch((err) => {
        console.log(err);
        toast.error("Une erreur s'est produite, veuillez réessayer plus tard");
      });
  };

  const getAllAlertsObjectif = () => {
    setLoaderObjectifs(true);
    axiosInstance
      .get(`/${adaccountId}/AlertObjective`)
      .then((res) => {
        dispatch({
          type: "SET_ALERTS_OBJECTIF",
          payload: res.data,
        });

        setLoaderObjectifs(false);
      })
      .catch((err) => {
        console.log(err);
        setLoaderObjectifs(false);
        toast.error("Une erreur s'est produite, veuillez réessayer plus tard");
      });
  };

  const handleEditAlertObjectif = () => {
    const objectToSend = {
      status,
      target,
      metrics,
      threshold,
      all_campaigns,
    };

    axiosInstance
      .put(`/${adaccountId}/AlertObjective/${objectifId}`, selectedCampaigns, {
        params: objectToSend,
      })
      .then((res) => {
        if (res.data === true) {
          toast.success("Objectif modifié avec succès");
          setShowForm(false);
          getAllAlertsObjectif();
          dispatch({ type: "CLEAR_ALERT_OBJECTIF" });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Une erreur s'est produite, veuillez réessayer plus tard");
      });
  };

  const handleEditCampaigns = (objectifId) => {
    axiosInstance
      .post(`/${adaccountId}/AlertObjective/campaign`, selectedCampaigns, {
        params: { alert_objective_id: objectifId },
      })
      .then((res) => {
        console.log(res);
        if (res.data === true) {
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Une erreur s'est produite, veuillez réessayer plus tard");
      });
  };

  const handleDeleteCampaigns = (campId, objectifId) => {
    axiosInstance
      .delete(
        `/${adaccountId}/AlertObjective/${campId}?alert_objective_id=${objectifId}`
      )
      .then((res) => {
        // console.log(res);
        if (res.data === true) {
          dispatch({
            type: "REMOVE_CAMPAIGN_FROM_OBJECTIF",
            payload: {
              campId,
              objectifId,
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Une erreur s'est produite, veuillez réessayer plus tard");
      });
  };

  const handleEditStatus = (objectifId) => {
    const objectif = objectifs.find((objectif) => objectif._id === objectifId);
    const status = !objectif.status;

    axiosInstance
      .put(`/${adaccountId}/AlertObjective/${objectifId}?&status=${status}`)
      .then((res) => {
        if (res.data === true) {
          toast.success("Statut modifié avec succès");
          // getAllAlerts();
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Une erreur s'est produite, veuillez réessayer plus tard");
      });
  };

  const handleChangePage = (event, value) => {
    event.stopPropagation();

    dispatch({
      type: "SET_END_INDEX_PAGINATION_ALERTS_OBJECTIF",
      payload: value * count,
    });
  };

  const handleEdit = (index) => {
    const objectif = objectifs[index];
    dispatch({ type: "EDIT_ALERT_OBJECTIF", payload: objectif });
    setEditMode(true);
    setShowForm(true);
  };

  const handleDublicate = (index) => {
    const objectif = objectifs[index];
    dispatch({ type: "EDIT_ALERT_OBJECTIF", payload: objectif });
    setShowForm(true);
  };

  const handleSave = () => {
    if (!editMode) {
      handleSaveAlertObjectif();
    } else {
      handleEditAlertObjectif();
      handleEditCampaigns(objectifId);
    }
  };

  useEffect(() => {
    if (adaccountId) {
      getActiveCampaigns(adaccountId);
      getAllAlertsObjectif(adaccountId);
      dispatch({ type: "CLEAR_CAMPAIGN_REF_VARIABLES" });
      dispatch({ type: "CLEAR_PACKAGE_GEO_DEMO" });
    }
  }, [adaccountId]);

  const objectifsToShow = objectifs.slice(startIndex, endIndex);

  return (
    <div className="section">
      <header
        className="section_header"
        onClick={() => setToggle((prevState) => !prevState)}
      >
        <h3 className="section_header_title">Objectifs</h3>
        <i className={`fas fa-chevron-${toggle ? "up" : "down"}`}></i>
      </header>
      {toggle && (
        <div className="section_content objectifs">
          <div className="selecteur_compte_button">
            <div className="selecteur_compte">
              {/*{loaderConnectedClients ? (
                <Loader3 />
              ) : (
                <select value={adaccountId} onChange={selectAccount}>
                  <option value="">Sélectionner un compte</option>
                  {connectedAccounts.map((ac) => (
                    <option data-object={JSON.stringify(ac)} value={ac.account}>
                      {ac.name}
                    </option>
                  ))}
                </select>
                  )}*/}
            </div>
            {/*<button>+ Ajouter un objectif</button>*/}
            <button onClick={handleShowForm}>+ Ajouter un objectif</button>
          </div>
          <div className="tableau">
            <form>
              {loaderObjectifs ? (
                <Loader3 />
              ) : (
                <table>
                  <thead>
                    <tr>
                      <th>Statut</th>
                      <th>Métriques</th>
                      <th>Cible</th>
                      <th>Campagnes</th>
                      <th>Seuil Alerte</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {objectifsToShow &&
                      objectifsToShow.length > 0 &&
                      objectifsToShow.map((objectif, index) => (
                        <tr key={objectif._id}>
                          <td>
                            <Switch
                              onClick={() => setStatus(objectif._id)}
                              checked={objectif.status}
                              name="status"
                            />
                          </td>
                          <td>
                            <p>{objectif.metrics}</p>
                          </td>
                          <td>
                            <p>{objectif.target}</p>
                          </td>
                          <td>
                            <div
                              className="campagnes"
                              style={{ maxWidth: "250px" }}
                            >
                              <div className="tags">
                                {objectif.campaigns &&
                                  objectif.campaigns.length > 0 &&
                                  objectif.campaigns.map((camp) => (
                                    <div
                                      key={camp.id}
                                      className="tag"
                                      style={{ maxWidth: "250px" }}
                                    >
                                      <p>{camp.name}</p>
                                      <i
                                        onClick={() =>
                                          handleDeleteCampaigns(
                                            camp.id,
                                            objectif._id
                                          )
                                        }
                                        className="fas fa-times"
                                      ></i>
                                    </div>
                                  ))}

                                {objectif.all_campaigns
                                  ? objectif.all_campaigns && (
                                      <p>
                                        <FormattedMessage
                                          id="alerts_screen_objectif_section"
                                          defaultMessage="Toutes les campagnes"
                                        />
                                      </p>
                                    )
                                  : ""}
                              </div>
                            </div>
                          </td>
                          <td>
                            <p>{objectif.threshold}</p>
                          </td>
                          <td>
                            <div className="actions">
                              <i
                                className="fas fa-pen"
                                onClick={() => handleEdit(index)}
                              ></i>
                              <i
                                className="far fa-copy"
                                onClick={() => handleDublicate(index)}
                              ></i>
                            </div>
                          </td>
                        </tr>
                      ))}
                    {showForm && (
                      <tr className="new">
                        <td>
                          <Switch
                            onClick={setStatus}
                            checked={status}
                            name="status"
                          />
                        </td>
                        <td>
                          <select
                            name="metrics"
                            value={metrics}
                            onChange={handleChange}
                          >
                            <option value="ROAS">ROAS</option>
                            <option value="CTR">CTR</option>
                            <option value="CPC">CPC</option>
                            <option value="CPM">CPM</option>
                          </select>
                        </td>
                        <td>
                          <input
                            value={target}
                            type="number"
                            name="target"
                            onChange={handleChange}
                          />
                        </td>
                        <td>
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={setCampaignsSelection}
                                checked={all_campaigns}
                              />
                            }
                            label={
                              <FormattedMessage
                                id="alerts_screen_objectif_section"
                                defaultMessage="Toutes les campagnes"
                              />
                            }
                          />
                          <Autocomplete
                            className="autocomplete"
                            multiple
                            disabled={all_campaigns}
                            value={selectedCampaigns}
                            id="checkboxes-tags-demo"
                            onChange={handleChangeCampaign}
                            options={campaigns}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.name}
                            renderOption={(props, option, { selected }) => (
                              <li {...props} style={{ flex: 1 }}>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option.name}
                              </li>
                            )}
                            style={{ width: 180 }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </td>
                        <td>
                          <input
                            value={threshold}
                            type="number"
                            name="threshold"
                            onChange={handleChange}
                          />
                        </td>
                        <td>
                          <div className="actions">
                            <i
                              className="fas fa-check-circle"
                              onClick={handleSave}
                            ></i>
                            <i
                              className="fas fa-trash"
                              onClick={handleHideForm}
                            ></i>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </form>
          </div>

          <div className="pagination">
            <Pagination
              count={parseInt(objectifs.length / count)}
              onChange={handleChangePage}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Objectifs;
