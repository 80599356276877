import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

const Loader2 = () => {
  return (
    <div style={{ width: "100%", marginTop: 5 }}>
      <CircularProgress />
    </div>
  );
};

export default Loader2;
