import React, { useEffect, useState } from "react";
import Slider from "@mui/material/Slider";
// import TextField from "@mui/material/TextField";
// import Autocomplete from "@mui/material/Autocomplete";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Loader3 from "../../components/Loader3/Loader3";
import { FormattedMessage } from "react-intl";
import axiosInstance from "../../store/axios.instance";
import { toast } from "react-toastify";

const SingleMonitoring = ({
  item: { id, name, strategy, equivalency, default_apply_on },
  getMonitorings,
}) => {
  const [disabled, setDisabled] = useState(false);
  const [disabledBtnSave, setDisabledBtnSave] = useState(true);
  const [loaderUpdate, setLoaderUpdate] = useState(false);

  const dispatch = useDispatch();

  const { evenements } = useSelector((state) => state.priorisationEvenements);

  const handleChangeSlider = (e, newValue) => {
    setDisabledBtnSave(false);

    dispatch({
      type: "UPDATE_SINGLE_MONITORING_EQUIVALENCY_ITEM",
      payload: {
        id,
        name: e.target.name,
        value: newValue,
      },
    });
  };

  const { adaccountId } = useSelector((state) => state.auth.user);


  const addEvent = () => {
    setDisabledBtnSave(false);

    dispatch({ type: "UPDATE_SINGLE_MONITORING_ADD_EVENT", payload: id });
  };

  const deleteEquivalency = (key) => {
    setDisabledBtnSave(false);

    dispatch({
      type: "DELETE_SINGLE_EQUIVALENCY_MONITORING",
      payload: { key, id },
    });
  };

  const handleChangeName = (e) => {
    setDisabledBtnSave(false);

    dispatch({
      type: "UPDATE_SINGLE_MONITORING_CHANGE_NAME",
      payload: {
        id,
        value: e.target.value,
      },
    });
  };

  const handleChangeEventSelect = (e) => {

    setDisabledBtnSave(false);

    dispatch({
      type: "UPDATE_SINGLE_MONITORING_CHANGE_EVENT",
      payload: {
        id,
        name: e.target.name,
        value: e.target.value,
      },
    });
  };

  const updateSingleMonitoring = async () => {
    if (!adaccountId) {
      toast.error("Vous devez sélectionner un compte avant de continuer");
      return;
    }

    // choisir un évènement

    try {
      setLoaderUpdate(true);
      const updatedMonitoring = {
        name,
        equivalency,
        default_apply_on,
      };
      const updateResponse = await axiosInstance.post(
        `${adaccountId}/monitoring/${id}`,
        updatedMonitoring
      );
      if (updateResponse.status === 200) {
        setTimeout(() => {
          setLoaderUpdate(false);
          toast.success("Stratégie modifiée");
          getMonitorings(adaccountId);
        }, 500);
      } else {
        setTimeout(() => {
          setLoaderUpdate(false);
        }, 500);
      }
    } catch (error) {
      setTimeout(() => {
        setLoaderUpdate(false);
      }, 500);
    }
  };

  const deleteSingleMonitoring = async () => {
    if (!adaccountId) {
      toast.error("Vous devez sélectionner un compte avant de continuer");
      return;
    }

    if (window.confirm("Voulez-vous vraiment supprimer ce monitoring ?")) {
      try {
        const deleteResponse = await axiosInstance.delete(
          `${adaccountId}/monitoring/${id}`
        );

        if (deleteResponse.status === 200) {
          setTimeout(() => {
            setLoaderUpdate(false);
            toast.success("Stratégie supprimée avec succès");
            getMonitorings(adaccountId);
          }, 500);
        } else {
          setTimeout(() => {
            setLoaderUpdate(false);
          }, 500);
        }
      } catch (error) {
        setTimeout(() => {
          setLoaderUpdate(false);
        }, 500);
      }
    }
  };

  useEffect(() => {
    if (strategy !== "manual") {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [strategy, setDisabled]);

  return (
    <div className="single_monitoring">
      <div className="details">
        <div className="search_add">
          <div className="left">
            <h3 className="title">
              <FormattedMessage
                defaultMessage="Nom de la stratégie"
                id="single_monitoring_kjhuyt852"
              />
            </h3>
            <input type="text" value={name} onChange={handleChangeName} />
          </div>
          <div className="right">
            <h4>
              <FormattedMessage
                defaultMessage="Stratégie"
                id="single_monitoring_1"
              />
            </h4>
            <p>{strategy}</p>
          </div>
        </div>
        <table>
          <button
            disabled={strategy !== "manual"}
            style={strategy !== "manual" ? { cursor: "not-allowed" } : {}}
            onClick={addEvent}
            className="add_btn_table"
          >
            +{" "}
            <FormattedMessage
              defaultMessage="Ajouter"
              id="single_monitoring_4"
            />
          </button>
          <thead>
            <tr>
              <th style={{ textAlign: "center" }}>
                <FormattedMessage
                  defaultMessage="Nom"
                  id="single_monitoring_5"
                />
              </th>
              <th style={{ textAlign: "center" }}>
                <FormattedMessage
                  defaultMessage="Poids"
                  id="single_monitoring_6"
                />
              </th>
              {strategy === "manual" && <th style={{ width: "85px" }}></th>}
            </tr>
          </thead>
          <tbody>
            {Object.keys(equivalency).length > 0 &&
              Object.keys(equivalency).map((key, index) => {
                return (
                  <tr>
                    <td>
                      <span className="circle"></span>
                      <select
                        disabled={strategy !== "manual"}
                        name={key}
                        onChange={handleChangeEventSelect}
                        value={key}
                      >
                        <option value="">
                          <FormattedMessage
                            defaultMessage="Choisissez un évènement"
                            id="single_monitoring_hgtERZA1t4"
                          />
                        </option>
                        {evenements &&
                          evenements.length > 0 &&
                          evenements.map((event) => (
                            <option value={event.id}>{event.name}</option>
                          ))}
                      </select>
                    </td>
                    <td className="color_green">
                      <Slider
                        name={key}
                        onChange={handleChangeSlider}
                        style={
                          disabled || Object.values(equivalency)[index] === 0
                            ? { color: "grey" }
                            : {}
                        }
                        disabled={disabled}
                        aria-label="range slider"
                        value={Object.values(equivalency)[index]}
                      />
                    </td>
                    {strategy === "manual" && (
                      <td style={{ width: "85px" }}>
                        <i
                          onClick={() => deleteEquivalency(key)}
                          className="fas fa-trash"
                          style={{ color: "#e83e8c" }}
                        ></i>
                      </td>
                    )}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>

      <div className="actions">
        <button className="outline" onClick={deleteSingleMonitoring}>
          <FormattedMessage
            defaultMessage="Supprimer"
            id="single_monitoring_delete_rtyuj8624"
          />
        </button>
        {loaderUpdate ? (
          <Loader3 />
        ) : (
          <button
            onClick={updateSingleMonitoring}
            disabled={disabledBtnSave}
            style={
              disabledBtnSave
                ? { cursor: "not-allowed", backgroundColor: "#ccc" }
                : {}
            }
          >
            <FormattedMessage
              defaultMessage="Enregistrer"
              id="single_monitoring_save_123_dfhkjrtyhjk"
            />
          </button>
        )}
      </div>
    </div>
  );
};
export default SingleMonitoring;
