import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LeftMenu from "../../components/LeftMenu/LeftMenu";
import Loader3 from "../../components/Loader3/Loader3";
import TopRightMenu from "../../components/TopRightMenu/TopRightMenu";
import axiosInstance from "../../store/axios.instance";
import "./style.css";
import RightContainer from "../../components/RightContainer/RightContainer";
import axios from "axios";

import StepsMonitoring from "../../components/StepsComponents/StepsMonitoring";
import Monitoring from "../../components/MonitoringBranchementMonitoringCampagne/Monitoring";
import { toast } from "react-toastify";
import PopupFenetreAvertissementMonitoringCampagne from "../../components/PopupFenetreAvertissementMonitoringCampagne/PopupFenetreAvertissementMonitoringCampagne";

const BranchementMonitoringCampagne = () => {
  const [loader, setLoader] = useState(false);
  const [loaderCampaigns, setLoaderCampaigns] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { toggleLeftMenu } = useSelector((state) => state.utils);

  const setToggleLeftMenu = () => {
    dispatch({ type: "SET_TOGGLE_LEFT_MENU" });
  };

  const { adaccountId } = useSelector((state) => state.auth.user);

  const { strategiesCampagnes: monitorings, startSetting } = useSelector(
    (state) => state.monitoringCampagne
  );
  const { userData } = useSelector((state) => state.auth.user);

  const getMonitorings = async (adaccount) => {
    try {
      setLoader(true);

      const response = await axiosInstance.get(`/${adaccount}/monitoring`);

      if (response.status === 200) {
        dispatch({ type: "GET_STRATEGIES_CAMPAGNES", payload: response.data });

        getCampaigns(adaccount);

        setTimeout(() => {
          setLoader(false);
        }, 500);
      }
    } catch (error) {
      setTimeout(() => {
        setLoader(false);
      }, 500);
    }
  };

  useEffect(() => {
    if (!startSetting) {
      getMonitorings(adaccountId);
    }
  }, [adaccountId]);

  const getCampaigns = async (adacc) => {
    try {
      setLoaderCampaigns(true);

      const response = await axios.get(
        `${
          process.env.REACT_APP_GRAPH_API
        }/${adacc}/campaigns?fields=name,effective_status&access_token=${
          userData.token
          // process.env.NODE_ENV === "development"
          //   ? userData.dev_token
          //   : userData.token
        }&limit=1000`
      );

      if (response.status === 200) {
        // console.log("response", response);
        dispatch({
          type: "GET_CAMPAIGNS_CONFIG_MONITORING",
          payload: response.data.data,
        });

        setTimeout(() => {
          setLoaderCampaigns(false);
        }, 500);
      } else {
        setTimeout(() => {
          dispatch({
            type: "GET_CAMPAIGNS_CONFIG_MONITORING",
            payload: [],
          });

          setLoaderCampaigns(false);
        }, 500);
      }
    } catch (error) {
      console.log(error);
      setTimeout(() => {
        dispatch({
          type: "GET_CAMPAIGNS_CONFIG_MONITORING",
          payload: [],
        });

        setLoader(false);
      }, 500);
    }
  };

  const saveMonitorings = async () => {
    try {
      const monitoringsToSave = monitorings.map((item) => ({
        id: item.id,
        campaigns: item.campaigns,
      }));

      console.log(JSON.stringify(monitoringsToSave, null, 1));

      const response = await axiosInstance.post(
        `${adaccountId}/monitoring`,
        monitoringsToSave
      );

      if (response.status === 200) {
        dispatch({type: "UNSET_START_SETTING_STRATEGY_MONITORING_CAMPAGNE"});
        getMonitorings(adaccountId);
        toast.success("Enregistrement réussi");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (adaccountId) {
      getMonitorings(adaccountId);
    }

    document.addEventListener("click", () => {
      dispatch({
        type: "HIDE_TOP_SECTION_CAMPAIGNS_BRANCHEMENT_MONITORING_CAMPAGNE",
      });
    });
  }, []);

  const { showPopupFenetreAvertissementMonitoringCampagne } = useSelector(
    (state) => state.utils
  );

  const navigateToPrevSreen = () => {
    if (startSetting) {
      dispatch({
        type: "SHOW_POPUP_AVERTISSEMENT_MONITORING_CAMPAGNE_WITH_NAVIGATING_TO_ANOTHER_SCREEN",
        payload: "/configuration-monitoring",
      });
    } else {
      navigate("/configuration-monitoring");
    }
  };

  return (
    <div className="ecran_branchement_monitoring_campagne">
      {showPopupFenetreAvertissementMonitoringCampagne && (
        <PopupFenetreAvertissementMonitoringCampagne />
      )}
      <LeftMenu />
      <RightContainer>
        <header className="right_content_header">
          <div className="back_title">
            {toggleLeftMenu ? (
              <i
                onClick={() => setToggleLeftMenu((prevState) => !prevState)}
                style={{ marginRight: 30, cursor: "pointer" }}
                className="fas fa-bars fa-2x"
              ></i>
            ) : null}
            <h1 className="right_content_header_title">
              Branchement des stratégies
            </h1>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <TopRightMenu />
          </div>
        </header>
        <div className="right_content_main">
          <StepsMonitoring activeStep="branchement" />
          <div className="configurer_strategie">
            <div className="configurer_strategie_content">
              <div className="evenements_a_optimiser">
                <div className="evenements_a_optimiser_content">
                  <div className="events">
                    <div className="details">
                      {loader ? (
                        <Loader3 />
                      ) : (
                        <table>
                          <thead>
                            <tr>
                              <th>
                                <FormattedMessage
                                  defaultMessage="Stratégies"
                                  id="branchement_monitoring_campagne_qwf567"
                                />
                              </th>
                              <th>
                                <FormattedMessage
                                  defaultMessage="Campagnes"
                                  id="branchement_monitoring_campagne_jh732"
                                />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {monitorings &&
                              monitorings.length > 0 &&
                              monitorings.map((monitoring) => (
                                <Monitoring
                                  key={monitoring.id}
                                  loaderCampaigns={loaderCampaigns}
                                  monitoring={monitoring}
                                />
                              ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="buttons">
            <button onClick={navigateToPrevSreen} >
              <FormattedMessage
                defaultMessage="Précédent"
                id="add-account-previous"
              />
            </button>
            <button onClick={saveMonitorings} >
              <FormattedMessage
                defaultMessage="Enregistrer"
                id="create_campaign_screen_save_button"
              />
            </button>
          </div>
        </div>
      </RightContainer>
    </div>
  );
};

export default BranchementMonitoringCampagne;
