import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import "./style.css";
import { useIntl } from "react-intl";
import {getInstagramAccounts} from "../../store/actions/utils/campaignCreation"; 

const ChoicePageFacebookAndPageInstagram = ({disabled=false}) => {
  const dispatch = useDispatch();

  // eslint-disable-next-line
  const [disabledInputInsta, setDisabledInputInsta] = useState(true);
  const {
    accounts,
    instagramAccounts,
  } = useSelector ((state) => state.creationCampagneRef);
  const {
    facebookAccountChosen,
    instagramAccountChosen,
  } = useSelector((state) => state.creationCampagne);

  const intl = useIntl();

  const onChangeFacebookAccount = (e, newValue) => {
    if (newValue) {
      // Store Facebook Account Choosen
      dispatch({ type: "STORE_INSTAGRAM_ACCOUNT_CHOSEN", payload: null });
      dispatch({ type: "STORE_FACEBOOK_ACCOUNT_CHOSEN", payload: newValue });

      // activate instagram account input
      setDisabledInputInsta(false);

      // Get id of facebook page
      const pageId = newValue.id;

      getInstagramAccounts(dispatch, pageId);

      // setTimeout(() => {
      //   setLoader(false);
      // }, 500);
    } else {
      setDisabledInputInsta(true);
    }
  };

  const onChangeInstagramAccount = (e, newValue) => {
    if (newValue) {
      dispatch({ type: "STORE_INSTAGRAM_ACCOUNT_CHOSEN", payload: newValue });
      
    } else {
      dispatch({ type: "STORE_INSTAGRAM_ACCOUNT_CHOSEN", payload: null });
    }
  };

  useEffect(() => {
    if (facebookAccountChosen) setDisabledInputInsta(false);
  }, []);


  return (
    <div className="choix_compte">
      <div className="row">
        <div className="input_group">
          {/* <h3>Choisissez un compte facebook</h3> */}
          <Autocomplete
            value={facebookAccountChosen}
            onChange={onChangeFacebookAccount}
            disablePortal
            id="combo-box-demo"
            disabled={disabled}
            // options={facebookAccounts}
            options={accounts}
            sx={{ width: 300 }}
            getOptionLabel={(option) => option.global_brand_page_name}
            renderInput={(params) => (
              <TextField
                placeholder={intl.formatMessage({
                  id: "choose_pages_fb_insta_placeholder_choose_page_fb",
                  defaultMessage: "Page facebook",
                })}
                {...params}
              />
            )}
          />
        </div>
        <div className="input_group">
          <Autocomplete
            value={instagramAccountChosen}
            onChange={onChangeInstagramAccount}
            disabled={disabledInputInsta||disabled}
            disablePortal
            id="combo-box-demo"
            options={instagramAccounts}
            sx={{ width: 300 }}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                placeholder={intl.formatMessage({
                  id: "choose_pages_fb_insta_placeholder_choose_page_insta",
                  defaultMessage: "Page instagram",
                })}
                {...params}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default ChoicePageFacebookAndPageInstagram;
