import React from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Loader3 from "../../components/Loader3/Loader3";

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

const Event = ({
  item: { id, priority, pixel_custom_conversion, event_name, uniq_id },
  loader,
  getPriorisation,
  index,
  length,
  liste,
}) => {
  const dispatch = useDispatch();
  const { evenements } = useSelector(state => state.priorisationEvenements);

  const deleteItem = id => {
    if (window.confirm("Voulez-vous vraiment supprimer cet élément ?")) {
      dispatch({
        type: "DELETE_ITEM_LISTE_PRIORISATION_EVENEMENTS",
        payload: id,
      });
    }
  };

  const setEventName = (event, newValue) => {
    dispatch({
      type: "SET_EVENT_NAME_ITEM_LISTE_PRIORISATION_EVENEMENTS",
      payload: { id: uniq_id, value: newValue },
    });
  };

  return (
    <div className='item'>
      <p>{priority}</p>
      <div className='pixel_conversion'>
        <Autocomplete
          disabled
          value={pixel_custom_conversion}
          id='checkboxes-tags-demo'
          options={evenements}
          disableCloseOnSelect
          getOptionLabel={option =>
            option.name === "all_pixels" ? "Any Pixel" : option.name
          }
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
            </li>
          )}
          style={{ width: "100%" }}
          renderInput={params => (
            <TextField
              variant='standard'
              {...params}
              label='Pixel/event conversion'
            />
          )}
        />
      </div>
      <div className='event_name'>
        {loader ? (
          <Loader3 />
        ) : (
          <Autocomplete
            onChange={setEventName}
            value={event_name}
            id='checkboxes-tags-demo'
            options={evenements}
            disableCloseOnSelect
            getOptionLabel={option => option.name}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </li>
            )}
            style={{ width: "100%" }}
            renderInput={params => (
              <TextField variant='standard' {...params} label='Event name' />
            )}
          />
        )}
      </div>
      <div className='actions'>
        <i onClick={() => deleteItem(uniq_id)} className='fas fa-trash-alt'></i>
      </div>
    </div>
  );
};
export default Event;
