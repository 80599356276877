import React from "react";
import RightContainer from "../../components/RightContainer/RightContainer";
import TopRightMenu from "../../components/TopRightMenu/TopRightMenu";
import { useDispatch } from "react-redux";

import "./style.css";
import { useSelector } from "react-redux";
import LeftMenu from "../../components/LeftMenu/LeftMenu";
import ControleBudget from "./Sections/ControleBudget";
import Objectifs from "./Sections/Objectifs";
import Parametrages from "./Sections/Parametrages";

const Alertes = () => {
  const dispatch = useDispatch();
  const { toggleLeftMenu } = useSelector(state => state.utils);
  const setToggleLeftMenu = () => {
    dispatch({ type: "SET_TOGGLE_LEFT_MENU" });
  };

  return (
    <div className='screen_alerts'>
      <LeftMenu />
      <RightContainer>
        <header className='right_content_header'>
          <div className='back_title'>
            {toggleLeftMenu ? (
              <i
                onClick={() => setToggleLeftMenu(prevState => !prevState)}
                style={{ marginRight: 30, cursor: "pointer" }}
                className='fas fa-bars fa-2x'
              ></i>
            ) : null}
            <div
              className='back'
              onClick={() => {
                dispatch({ type: "SHOW_ALERT_MSG_PACKAGE_PUB_BACK" });
              }}
            >
              <img src='/left-arrow 2.png' alt='Retour' />
            </div>
            <h1 className='right_content_header_title'>
              {/* <FormattedMessage defaultMessage='' id='gdfgdfgtyu678' /> */}
              Alertes
            </h1>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className='client'>
              <TopRightMenu />
            </div>
          </div>
        </header>
        <div className='right_content_main'>
          <div className='sections'>
            <ControleBudget />
            <Objectifs />
            <Parametrages />
          </div>

          <div className='dfe'>
            <button>Enregistrer</button>
          </div>
        </div>
      </RightContainer>
    </div>
  );
};
export default Alertes;
