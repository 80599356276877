import React from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";

const ApercuSimple = ({
  pub: {
    id,
    name,
    preview_shareable_link,
    creative: { thumbnail_url },
    isSelected,
  },
}) => {
  const dispatch = useDispatch();

  const setSelectedPub = () => {
    dispatch({
      type: "TOGGLE_SELECT_PUBLICITE_PUBLICITES_EXISTANTES",
      payload: id,
    });
  };

  return (
    <div
      onClick={setSelectedPub}
      className={`apercu_simple ${isSelected ? "selected" : ""}`}
    >
      <h4 className="publicite_existante_title">Nom: {name}</h4>
      <h5 className="publicite_existante_title">Post ID: {id}</h5>
      <h6 className="publicite_existante_title">
        <a
          className="link_preview"
          rel="noopener noreferrer"
          target="_blank"
          href={preview_shareable_link}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <FormattedMessage
            defaultMessage="Prévisualiser la publicité"
            id="selectionner_publicites_existantes_esw654"
          />
        </a>
      </h6>
      <div className="image_container">
        <img src={thumbnail_url} alt="Patisserie blanc" />
      </div>
      {/* <div className='interactions'>
        <p>
          <i className='far fa-thumbs-up'></i>
          J'aime
        </p>
        <p>
          <i className='far fa-comment-alt'></i>
          Commenter
        </p>
        <p>
          <i className='fas fa-share'></i>
          Partager
        </p>
      </div> */}
    </div>
  );
};
export default ApercuSimple;
