import { useDispatch, useSelector } from "react-redux";
import { estObjetVide } from "../../utils";
import { useNavigate } from "react-router-dom";

const RedirectIfNotFBConnected = ({ children }) => {
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.auth.user);
  const navigate = useNavigate();

  if (!estObjetVide(userData)) {
    return children;
  } else {
    dispatch({
      type: "SET_ALERT_GENERAL_ERROR_MESSAGE",
      payload:
        "Vous n'avez pas accès ! Vous devez d'abord vous inscrire ou vous connecter.",
    });
    dispatch({ type: "SHOW_ALERT_GENERAL_ERROR_MESSAGE" });
    navigate("/campagne/ajouter-compte");
  }
};

export default RedirectIfNotFBConnected;
