import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;
const token = process.env.REACT_APP_TOKEN_API;

const instance = axios.create({
  baseURL: `${apiUrl}`,
  params: {
    token,
  },
});

export default instance;
