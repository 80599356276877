import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import GenericSection from "./genericSection"
import RenderTagDict from "../../../components/RenderTagOption/RenderTagDict";
import { saveCampaignNextPage } from "../../../store/actions/utils/campaignCreation";

const TargetingInputGeneric = ({options, value, onChange, editTag, label, targetPage}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  
  return (
  <div>
    <div style={{ display: "flex", alignItems: "center" }}>
      <i
        style={{ marginRight: 5, color: "#E83E8C" }}
        className="fas fa-search"
      ></i>
      <Autocomplete
        multiple
        value={value}
        onChange={onChange}
        id="checkboxes-tags-demo"
        options={options}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        disableCloseOnSelect
        getOptionLabel={(option) => option.name}
        renderTags={(options) =>
          options.map((option) => (
            <RenderTagDict
              value={value}
              option={option}
              onChange={onChange}
              editTag={editTag}
            />
          ))
        }
        renderOption={(props, option, { selected }) => (
          <li {...props} key={option.id}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 5 }}
              checked={selected}
            />
            {option.name}
          </li>
        )}
        style={{ width: 500 }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
          />
        )}
      />
    </div>
    <div className="add" style={{ marginTop: 0 }}>
      <div className="fe">
        <button
          onClick={() =>{
            saveCampaignNextPage(dispatch, location);
            navigate(
              targetPage
            );
          }
          }
        >
          <i className="fas fa-plus"></i>
          <FormattedMessage
            defaultMessage="Ajouter"
            id="creation_campagne_package_add_btn"
          />
        </button>
      </div>
    </div>
  </div>
 )
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const Ciblage = ({
  disabledMode
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [toggle, setToggle] = useState(false);
  const intl = useIntl();

  const {
    audiences_packages, excluded_audiences_packages, large, 
    interests_packages, excluded_interests_packages,
  } = useSelector((state) => state.creationCampagne);

  const editTagAudience = (option, exclusion=false) => {
    dispatch({type:"SET_STATE_PROTECT_DETAILLED_ADSETS",payload:true});            
    saveCampaignNextPage(dispatch, location);
    navigate(
      `/creation-audience/edit/${option.id}?${exclusion?"audience_type=exclusion":""}`
    );
    //not sure about the purpose of this one
    dispatch({
      type: "SET_TAGS_TO_EDIT",
      payload: option,
    });
  }

  const handleChangeAudience = (event, newValue) => {
    dispatch({
      type: "HANDLE_CHANGE_AUDIENCE",
      payload: newValue,
    });
  };

  const editTagInterests = (option, exclusion=false) => {
    dispatch({type:"SET_STATE_PROTECT_DETAILLED_ADSETS",payload:true});
    saveCampaignNextPage(dispatch, location)
    navigate(`/creation-campagne/package-centre-interet/edit/${option.id}?${exclusion?"interests_type=exclusion":""}`);
    dispatch({
      type: "SET_TAGS_TO_EDIT",
      payload: option,
    });
  }

  const handleChangeInteret = (event, newValue) => {
    dispatch({
      type: "HANDLE_CHANGE_CENTRE_INTERET",
      payload: newValue,
    });
  };

  const handleChangeExclusionAudience = (event, newValue) => {
    //We probably should use only setting the package and manage in store for inclusion exclusion
    let new_excl_audiences = new Set(newValue);
    const removed = excluded_audiences_packages.filter(
      audience => !new_excl_audiences.has(audience));
    //all the if else if for detailled_adsets
    if (removed.length){
      dispatch({
        type:"REMOVE_PACKAGE_AUDIENCE_EXCLUSION", 
        payload:removed[0]});
    }
    else {
      new_excl_audiences = new Set(excluded_audiences_packages);
      const added = newValue.filter(audience => !new_excl_audiences.has(audience));
      if(added.length){
        dispatch({
          type:"ADD_PACKAGE_AUDIENCE_EXCLUSION", 
          payload:added[0]});
      }
      else {
        console.log("for somes reasons onChange called without any changes ?");
        console.log(excluded_audiences_packages);
        console.log(newValue);
      }
    }
  };

  const handleChangeExclusionInteret = (event, newValue) => {
    let new_excl_interests = new Set(newValue);
    const removed = excluded_interests_packages.filter(
      interests => !new_excl_interests.has(interests));
    if (removed.length){
      dispatch({
        type:"REMOVE_PACKAGE_INTEREST_EXCLUSION", 
        payload:removed[0]});
    }
    else {
      new_excl_interests = new Set(excluded_interests_packages);
      const added = newValue.filter(audience => !new_excl_interests.has(audience));
      if(added.length){
        dispatch({
          type: "ADD_PACKAGE_INTEREST_EXCLUSION", 
          payload: added[0]});
      }
      else {
        console.log("for somes reasons onChange called without any changes ?");
        console.log(excluded_audiences_packages);
        console.log(newValue);
      }
    }
  };

  const handleChangeCiblageLarge = (event) => {
    const isChecked = event.target.checked;
    dispatch({
      type: "HANDLE_CHANGE_CIBLAGE_LARGE",
      payload: isChecked,
    });
  };

  const { audiencesPackagePub, centresInteretPackagePub} = useSelector(
    (state) => state.creationCampagneRef
  );

  const updateDictElements = (refDict, currentDict) => {
    const updatedDict = [];
    currentDict.forEach(item=>{
      const itemFound = refDict.find(refItem=>refItem.id===item.id);
      if(itemFound){
        updatedDict.push(itemFound);
      }
      else {
        updatedDict.push(item);
      }  
    })
    return updatedDict;
  }

  useEffect(()=>{
    if(audiencesPackagePub.length){
      dispatch({
          type: "HANDLE_CHANGE_AUDIENCE_WITH_PROTECTION",
          payload: updateDictElements(audiencesPackagePub, audiences_packages),
        });
      dispatch({
        type: "HANDLE_CHANGE_EXCLUSION_AUDIENCE",
        payload: updateDictElements(audiencesPackagePub, excluded_audiences_packages),
      });
    }
  }, [audiencesPackagePub])

  useEffect(()=>{
    if(centresInteretPackagePub.length){
      // dispatch({
      //   type: "HANDLE_CHANGE_CENTRE_INTERET_WITH_PROTECTION",
      //   payload: updateDictElements(centresInteretPackagePub, interests_packages),
      // });
      dispatch({
        type: "HANDLE_CHANGE_EXCLUSION_CENTRE_INTERET",
        payload: updateDictElements(centresInteretPackagePub, excluded_interests_packages),
      });
    }
  }, [centresInteretPackagePub])


  return (
    <GenericSection
      disabledMode={disabledMode}
      toggle={toggle}
      setToggle={setToggle}
      title={
        <FormattedMessage
        defaultMessage="Ciblage"
        id="create_campaign_ciblage_title"
        />}
      test_validation={large || audiences_packages.length > 0 || interests_packages.length > 0}
      content={
        <form action="">
          <div className="input_group objectif">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleChangeCiblageLarge}
                    defaultChecked={large}
                  />
                }
                label="Ciblage large"
              />
            </FormGroup>
          </div>
          <div className="">
            <div style={{ marginBottom: "1.5rem" }}>
              <label htmlFor="" style={{ color: "#E83E8C" }}>
                Audiences
              </label>
            </div>
            <TargetingInputGeneric 
              options={audiencesPackagePub}
              value={audiences_packages}
              onChange={handleChangeAudience}
              label={intl.formatMessage({
                      id: "creation_campagne_package_audience_search_package",
                      defaultMessage: "Chercher un package audience",
                      })}
              editTag={editTagAudience}
              targetPage="/creation-audience"                    
            />
          </div>
          <div className="">
            <div style={{ marginBottom: "1.5rem" }}>
              <label htmlFor="" style={{ color: "#E83E8C" }}>
                Intérêts
              </label>
            </div>
            <TargetingInputGeneric 
              options={centresInteretPackagePub}
              value={interests_packages}
              onChange={handleChangeInteret}
              label={intl.formatMessage({
                id: "creation_campagne_package_interest_search_package",
                defaultMessage: "Chercher un package centre intérêt",
              })}
              editTag={editTagInterests}
              targetPage="/creation-campagne/package-centre-interet"                    
            />
          </div>
          <div className="">
            <div style={{ marginBottom: "1.5rem" }}>
              <label htmlFor="" style={{ color: "#E83E8C" }}>
                Exclusions
              </label>
            </div>
            <TargetingInputGeneric 
              options={audiencesPackagePub}
              value={excluded_audiences_packages}
              onChange={handleChangeExclusionAudience}
              label={intl.formatMessage({
                id: "creation_campagne_exclusions_package_audience_search_package",
                defaultMessage: "Audiences",
              })}
              editTag={(option)=>editTagAudience(option, true)}
              targetPage="
              /creation-audience?audience_type=exclusion"                    
            />
            <TargetingInputGeneric 
              options={centresInteretPackagePub}
              value={excluded_interests_packages}
              onChange={handleChangeExclusionInteret}
              label={intl.formatMessage({
                id: "creation_campagne_exclusions_package_interest_search_package",
                defaultMessage: "Intérêts",
              })}
              editTag={(option)=>editTagInterests(option, true)}
              targetPage="/creation-campagne/package-centre-interet?interests_type=exclusion"                    
            />
          </div>
        </form>
      }
    />
  );
};

export default Ciblage;
