import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import axiosInstance from "../../store/axios.instance";
import { useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import Loader3 from "../Loader3/Loader3";

const OptimisationRealisee = ({ campaignId }) => {
  // const [alerts, setAlerts] = useState([]);
  const [alertsFormated, setAlertsFormated] = useState([]);
  const [loader, setLoader] = useState(false);
  const [columns, setColumns] = useState([]);
  const [alertsTable, setAlertsTable] = useState([]);

  const { adaccountId } = useSelector((state) => state.auth.user);

  const { alerts } = useSelector(
    (state) => state.rapportStatistiquesDetailllees
  );

  // const getAlerts = async (adacc) => {
  //   try {
  //     setLoader(true);
  //     // /act_429278752423318/23853886964650487/alert?level=ad,adset,campaign
  //     // console.log("aaa", `/${adacc}/${campaignId}/alert?level=ad`);
  //     const response = await axiosInstance.get(
  //       `/${adacc}/${campaignId}/alert?level=ad,adset,campaign`
  //     );

  //     if (response.status === 200) {
  //       // console.log(response);
  //       setAlerts(response.data);
  //       setLoader(false);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setLoader(false);
  //   }
  // };

  function replaceVideoWithLink(text, videoUrls, len) {
    let indexx = 0;
    return text.replace(/video/g, (match, index) => {
      const videoUrl = videoUrls[indexx];
      indexx++; // Increment the index for the next occurrence
      return indexx <= len
        ? `<a href='${videoUrl}' target='_blank'>video${indexx}</a>`
        : "video";
    });
  }

  useEffect(() => {
    if (alerts.length > 0) {
      const renderedData = alerts.map((item) => ({
        ...item,
        sentence_EN: replaceVideoWithLink(
          item.sentence_EN,
          item.video_url,
          item.video_url.length
        ),
      }));
      setAlertsFormated(renderedData);
    }

    if (alerts && alerts.length > 0) {
      const desiredOrder = [
        "created_time",
        "priority",
        "campaign_name",
        "adset_name",
        "ad_name",
        "sentence_EN",
      ];
      const sortedKeys = desiredOrder.concat(
        Object.keys(alerts).filter((key) => !desiredOrder.includes(key))
      );

      const allColumns = sortedKeys.map((key) => {
        return {
          name: key,
          selector: (row) => row[key],
          sortable: true,
        };
      });

      allColumns.push({
        name: "Action",
        sortable: false,
        selector: (row) => row["Action"],
      });

      // Filter to only include certain columns
      const includedColumns = [
        "Alert",
        "Date",
        "Priorité",
        "Adset",
        "Ad",
        "Action",
      ];

      const columns = allColumns.filter((column) => {
        if (column.name === "created_time") {
          column.name = "Date";
          column.width = "70px";
        }
        if (column.name === "adset_name") {
          column.name = "Adset";
          column.width = "70px";
        }
        if (column.name === "ad_name") {
          column.name = "Ad";
          column.width = "70px";
        }
        if (column.name === "sentence_EN") {
          column.name = "Alert";
          column.cell = (row) => (
            <div
              dangerouslySetInnerHTML={{
                __html: row.sentence_EN,
              }}
            />
          );
        }
        if (column.name === "priority") {
          column.name = "Priorité";
          column.width = "70px";
        }
        if (column.name === "Action") {
          column.name = "Action";
          column.width = "70px";
        }
        return includedColumns.includes(column.name);
      });

      setColumns(columns);

      const data = alerts.map((alert) => {
        const newDate = new Date(alert.created_time);
        // Get month and day as two-digit strings
        const month = (newDate.getMonth() + 1).toString().padStart(2, "0");
        const day = newDate.getDate().toString().padStart(2, "0");
        // Combine them to create the "mm/dd" format
        const formattedDate = `${month}/${day}`;
        return {
          created_time: formattedDate,
          priority: alert.priority,
          sentence_EN: replaceVideoWithLink(
            alert.sentence_EN,
            alert.video_url,
            alert.video_url.length
          ),
          ad_name: alert.ad_name.map((c) => <span title={c}> {c} </span>),
          adset_name: alert.adset_name.map((c) => <span title={c}> {c} </span>),
          Action: (
            <div
              style={{
                width: "70px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "10px",
              }}
            >
              <i
                onClick={evaluateAlert}
                style={{
                  color: "#e83e8c",
                  cursor: "pointer",
                  fontSize: "16px",
                }}
                className="fas fa-thumbs-up"
              ></i>
              <i
                onClick={evaluateAlert}
                style={{
                  color: "#e83e8c",
                  cursor: "pointer",
                  fontSize: "16px",
                }}
                className="fas fa-thumbs-down"
              ></i>
              <i
                onClick={removeAlert}
                style={{
                  color: "#e83e8c",
                  cursor: "pointer",
                  fontSize: "16px",
                }}
                className="fa fa-close"
              ></i>
            </div>
          ),
        };
      });
      // console.log(data);
      setAlertsTable(data);
    }
    // console.log("alert", alerts);
  }, [alerts]);

  useEffect(() => {
    // if (adaccountId) getAlerts(adaccountId);
  }, [adaccountId]);

  const removeAlert = () => {
    console.log("remove");
  };

  const evaluateAlert = () => {
    console.log("evaluate");
  };

  return (
    <div className="optimisation_realisee">
      <header>
        <h6 className="title">
          <FormattedMessage
            defaultMessage="OPTIMISATION RÉALISÉE"
            id="detail_campagne_screen_5"
          />
        </h6>
      </header>
      <div className="content">
        {loader ? (
          <Loader3 />
        ) : alertsFormated.length !== 0 ? (
          // <ul>
          //   {alertsFormated.map((alert) => (
          //     <li>
          //       <p dangerouslySetInnerHTML={{ __html: alert.sentence_EN }}></p>
          //     </li>
          //   ))}
          // </ul>
          <DataTable
            columns={columns}
            data={alertsTable}
            pagination
            // customStyles={customStyles}
            striped
            highlightOnHover
            // dense
            fixedHeader
            fixedHeaderScrollHeight
          />
        ) : (
          <p style={{ textAlign: "center" }}>Aucune alerte pour l'instant</p>
        )}

        {/* <div className='dfe'>
          <button>
            <FormattedMessage
              defaultMessage='Voir plus'
              id='detail_campagne_screen_6'
            />

            <i className='fas fa-chevron-circle-right'></i>
          </button>
        </div> */}
      </div>
    </div>
  );
};
export default OptimisationRealisee;
