import React, { useEffect, useState } from "react";
import Loader from "../../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import Apercu from "../../components/Apercu/Apercu";
import "./style.css";
import Image from "./sections/Image/Image";
import Video from "./sections/Video/Video";
import ImageApparairerVisuels from "../../components/ApparairerVisuels/SectionImage";
import VideoApparairerVisuels from "../../components/ApparairerVisuels/SectionVideo";
import Texte from "./sections/Texte/Texte";
import Description from "./sections/Description/Description";
import LeftMenu from "../../components/LeftMenu/LeftMenu";
import Titre from "./sections/Titre/Titre";
import UTM from "./sections/UTM/UTM";
import CallToAction from "./sections/CallToAction/CallToAction";
import Lien from "./sections/Lien/Lien";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import axiosInstance from "../../store/axios.instance";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Loader3 from "../../components/Loader3/Loader3";
import TopRightMenu from "../../components/TopRightMenu/TopRightMenu";
import Carousels from "./sections/Carousels/Carousels";
import PostsASponsoriser from "./sections/PostsASponsoriser/PostsASponsoriser";
import { FormattedMessage, useIntl } from "react-intl";
import { estObjetVide, valeurExiste } from "../../utils";
import BaseAlertMessage from "../../components/AlertMessages/BaseAlertMessage";
import RightContainer from "../../components/RightContainer/RightContainer";
import Switch from "@mui/material/Switch";

import { useLocation } from "react-router-dom";
import PublicitesExistantes from "./sections/PublicitesExistantes/PublicitesExistantes";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { getSafeLastPage, forgetLastPage } from "../../store/actions/utils/usualDispatches";
import { getTags } from "../../store/actions/utils/campaignCreation";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CreationPackagePubScreen = () => {
  const [loader, setLoader] = useState(false);
  const [loaderImages, setLoaderImages] = useState(false);
  const [loaderVideos, setLoaderVideos] = useState(false);
  const [loaderTitles, setLoaderTitles] = useState(false);
  const [loaderTextes, setLoaderTextes] = useState(false);
  const [loaderDescriptions, setLoaderDescriptions] = useState(false);
  const [loaderLiens, setLoaderLiens] = useState(false);
  const [loaderUtms, setLoaderUtms] = useState(false);
  const [loaderCtas, setLoaderCtas] = useState(false);
  const [loaderVariablesReference, setLoaderVariablesReference] =
    useState(false);
  const [loaderCarousels, setLoaderCarousels] = useState(false);
  const [loaderPosts, setLoaderPosts] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const navigate = useNavigate();
  const [pubCatalogue, setPubCatalogue] = useState(false);
  const [alertQuit, setAltertQuit] = useState(false);

  const location = useLocation();
  const edit = location.state ? location.state.edit : false;

  const dispatch = useDispatch();
  const { toggleLeftMenu, lastPage } = useSelector((state) => state.utils);
  const { file_configuration_id } = useSelector((state)=> state.creationCampagne);
  const { tags: allTags, catalogFormats } = useSelector((state) => state.creationCampagneRef);
  //it shouldn't be done this way but to be cleaned later
  const [formattedCatalogFormats, setFormattedCatalogFormats] = useState([])
  useEffect(()=>{
    setFormattedCatalogFormats(Object.entries(catalogFormats).map(([key, value])=> ({label:key, value})));
  }, [catalogFormats])
  const getParams = new URLSearchParams(window.location.search);
  const updatePub = getParams.get("updatePub");
  const setToggleLeftMenu = () => {
    dispatch({ type: "SET_TOGGLE_LEFT_MENU" });
  };

  const {
    nomPackage,
    emptyNameError,
    emptyImageError,
    emptyVideoError,
    emptyCorousetError,
    emptyCtasError,
    emptyPasError,
    catalogs,
    catalog,
    product_sets,
    product_set,
    catalogformats,
    catalog_crea_id,
  } = useSelector((state) => state.creationPackagePublicite);
  
  const {
    utms,
    liens,
    titre: { titres },
    descriptions,
    texte: { textes },
    image: { filteredImages, selectedImages },
    video: { filteredVideos, selectedVideos },
    ctas,
    sponsoredPosts,
    carousels,
  } = useSelector((state) => state.creationPackagePublicite);

  const {
    originalData: { publicitesExistantes },
  } = useSelector((state) => state.creationPackagePublicite);

  const { adaccountId } = useSelector((state) => state.auth.user);

  
  // const { tagToEdit } = useSelector(state => state.tag);
  // const params = useParams();
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();

  const tagToEdit = searchParams.get("tagToEdit");

  const intl = useIntl();

  const savePackagePub = async () => {
    if (nomPackage.includes("/")) {
      toast.error(
        intl.formatMessage({
          defaultMessage: "Le caractère / est interdit !",
          id: "character_slash_forbidden_WDy529",
        })
      );
      return;
    }

    let selectedutms = [];
    let selectedliens = [];
    let selectedtitres = [];
    let selectedtextes = [];
    let selecteddescriptions = [];
    let selectedimages = [];
    let selectedvideos = [];
    let selectedctas = [];
    // eslint-disable-next-line
    utms.map((item) => {
      if (item.isSelected) {
        selectedutms.push(item);
      }
    });
    // eslint-disable-next-line
    liens.map((item) => {
      if (item.isSelected) {
        selectedliens.push(item);
      }
    });
    // eslint-disable-next-line
    titres.map((item) => {
      if (item.isSelected) {
        selectedtitres.push(item);
      }
    });
    // eslint-disable-next-line
    textes.map((item) => {
      if (item.isSelected) {
        selectedtextes.push(item);
      }
    });
    // eslint-disable-next-line
    descriptions.map((item) => {
      if (item.isSelected) {
        selecteddescriptions.push(item);
      }
    });
    // eslint-disable-next-line
    filteredImages.map((item) => {
      if (item.isSelected) {
        selectedimages.push(item);
      }
    });
    // console.log("selectedimages", selectedimages);
    // eslint-disable-next-line
    filteredVideos.map((item) => {
      if (item.isSelected) {
        selectedvideos.push(item);
      }
    });
    // eslint-disable-next-line
    ctas.map((item) => {
      if (item.isSelected) {
        selectedctas.push(item);
      }
    });

    const catalog_crea = {
      account_id: adaccountId.slice(4),
      catalog_id: catalog,
      product_set_id: product_set,
      keyword: nomPackage,
      formats: catalogformats,
    };

    if (!nomPackage) {
      dispatch({ type: "SET_EMPTY_NAME" });
      toast.error("Le nom du package obligatoire");
      return;
    } else {
      dispatch({ type: "CLEAN_EMPTY_NAME" });
    }

    // selectedtitres.length !== 0 &&
    // selectedtextes.length !== 0 &&

    if (
      pubCatalogue ||
      selectedimages.length > 0 ||
      selectedvideos.length > 0 ||
      sponsoredPosts.length > 0 ||
      selectedctas.length > 0 ||
      carousels.length > 0 ||
      publicitesExistantes.length > 0
    ) {
 
      dispatch({
        type: "CLEAN_ERROR_MSG",
        payload: { name: "emptyImageError" },
      });
      dispatch({
        type: "CLEAN_ERROR_MSG",
        payload: { name: "emptyVideoError" },
      });
      dispatch({
        type: "CLEAN_ERROR_MSG",
        payload: { name: "emptyCorousetError" },
      });
      dispatch({
        type: "CLEAN_ERROR_MSG",
        payload: { name: "emptyPasError" },
      });

      if (pubCatalogue) {
        if (!catalog) {
          toast.error("Vous devez choisir un catalogue");
          return;
        }
        if (!product_set) {
          toast.error("Vous devez choisir un ensemble produits");
          return;
        }
        if (catalogformats.length === 0) {
          toast.error("Vous devez au moins choisir un format");
          return;
        }
      }

      setLoader(true);

      if (selectedutms.length > 0) {
        await Promise.all(
          selectedutms.map(async (elem) => {
            // Call api add name package to the keywords of each element
            await axiosInstance.post(`/${adaccountId}/utm/${elem.id}`, {
              ...elem,
              keywords: elem.keywords
                ? [...elem.keywords, nomPackage]
                : [nomPackage],
            });
          })
        );
      }
      if (selectedliens.length > 0) {
        await Promise.all(
          selectedliens.map(async (elem) => {
            // Call api add name package to the keywords of each element
            await axiosInstance.post(`/${adaccountId}/link/${elem.id}`, {
              ...elem,
              keywords: elem.keywords
                ? [...elem.keywords, nomPackage]
                : [nomPackage],
            });
          })
        );
      }
      if (selectedtitres.length > 0) {
        await Promise.all(
          selectedtitres.map(async (elem) => {
            // console.log(elem);
            // Call api add name package to the keywords of each element
            await axiosInstance.post(
              `/${adaccountId}/AdTitles/newkeywords`,
              [
                {
                  ...elem,
                  keywords: elem.keywords
                    ? [...elem.keywords, nomPackage]
                    : [nomPackage],
                },
              ]
            );
          })
        );
      } else {
        // dispatch({
        //   type: "SET_ALERT_GENERAL_ERROR_MESSAGE",
        //   payload: "Vous devez au moins choisir un titre !",
        // });
        // dispatch({ type: "SHOW_ALERT_GENERAL_ERROR_MESSAGE" });
      }
      if (selectedtextes.length > 0) {
        await Promise.all(
          selectedtextes.map(async (elem) => {
            // Call api add name package to the keywords of each element
            await axiosInstance.post(
              `/${adaccountId}/AdBodies/newkeywords`,
              [
                {
                  ...elem,
                  keywords: elem.keywords
                    ? [...elem.keywords, nomPackage]
                    : [nomPackage],
                },
              ]
            );
          })
        );
      } else {
        // dispatch({
        //   type: "SET_ALERT_GENERAL_ERROR_MESSAGE",
        //   payload: "Vous devez au moins choisir un texte !",
        // });
        // dispatch({ type: "SHOW_ALERT_GENERAL_ERROR_MESSAGE" });
      }
      if (selecteddescriptions.length > 0) {
        await Promise.all(
          selecteddescriptions.map(async (elem) => {
            // Call api add name package to the keywords of each element
            await axiosInstance.post(
              `/${adaccountId}/description/${elem.id}`,
              {
                ...elem,
                keywords: elem.keywords
                  ? [...elem.keywords, nomPackage]
                  : [nomPackage],
              }
            );
          })
        );
      }

      if (selectedimages.length > 0) {
        await Promise.all(
          selectedimages.map(async (elem) => {
            // Call api add name package to the keywords of each element
            await axiosInstance.post(
              `/${adaccountId}/AdImages/newkeywords`,
              [
                {
                  name: elem.name,
                  hash: elem.hash,
                  keywords: elem.keywords
                    ? [...elem.keywords, nomPackage]
                    : [nomPackage],
                },
              ]
            );
          })
        );
      }

      if (selectedvideos.length > 0) {
        await Promise.all(
          selectedvideos.map(async (elem) => {
            // Call api add name package to the keywords of each element
            await axiosInstance.post(
              `/${adaccountId}/AdVideos/newkeywords`,
              [
                {
                  id: elem.id,
                  title: elem.title,
                  keywords: elem.keywords
                    ? [...elem.keywords, nomPackage]
                    : [nomPackage],
                },
              ]
            );
          })
        );
      }

      if (selectedctas.length > 0) {
        await Promise.all(
          selectedctas.map(async (elem) => {
            await axiosInstance.post(
              `/${adaccountId}/call_to_action/${elem.id}`,
              { ...elem, keywords: [...elem.keywords, nomPackage] }
            );
          })
        );
      }

      if (pubCatalogue) {
        await axiosInstance.put(
          `/${adaccountId}/CatalogCreative`,
          catalog_crea
        );
      }

      if (!pubCatalogue && catalog_crea_id) {
        await axiosInstance.delete(
          `/${adaccountId}/CatalogCreative/${catalog_crea_id}`
        );
        // console.log("res", res);
      }

     
      toast.success("Tous les éléments ont été mis à jour");
      let goTo = getSafeLastPage(lastPage, file_configuration_id);
      if (!edit) {
        if (goTo !== -1){
          if (goTo.includes("update-pub")) {
            dispatch({
              type: "ADD_PACKAGE_PUB_UPDATE",
              payload: nomPackage,
            });
          }
          else {
            dispatch({
              type: "ADD_PACKAGE_PUB",
              payload: nomPackage,
            });
          }
        }
      }
      dispatch({
        type: "CHANGE_NOM_PACKAGE_CREATION_PACKAGE_PUB",
        payload: "",
      });
      dispatch({ type: "CLEAN_EMPTY_IMAGE" });
      dispatch({ type: "CLEAN_EMPTY_VIDEO" });
      dispatch({ type: "CLEAN_EMPTY_CAROUSEL" });
      dispatch({ type: "CLEAN_EMPTY_PAS" });
      dispatch({ type: "CLEAR_CREATION_PACKAGE_PUB" });

      setTimeout(() => {
        setLoader(false);
        getTags(dispatch, adaccountId);
        dispatch(forgetLastPage());
        navigate(goTo);
      }, 500);
    }
  };

  const afficherSectionApparairerVisuels = () => {
    dispatch({
      type: "SHOW_SELECTED_ITEMS_APPAIRER_VISUELS",
      payload: {
        selectedImages,
        selectedVideos,
      },
    });
    dispatch({ type: "SHOW_SECTION_APPAIRER_VISUELS" });
  };

  const {
    carre,
    story,
    showSection: showSectionAppairerVisuels,
  } = useSelector((state) => state.appairerVisuels);

  const apparairerDesVisuels = async () => {
    if (carre !== null && story !== null) {
      try {
        // Faire les traitements
        const carreId = carre.id;
        const storyId = story.id;

        setLoader(true);

        let carreToSend = {};
        let storyToSend = {};
        let associatedAssetsObj = {};

        associatedAssetsObj["9_16"] = storyId;

        if (carre.type === "image") {
          // modifier une image

          carreToSend = {
            hash: carre.hash,
            name: carre.name,
            keywords: carre.keywords,
            associated_assets: associatedAssetsObj,
          };
        } else {
          // modifier une vidéo
          carreToSend = {
            id: carreId,
            title: carre.title,
            keywords: carre.keywords,
            associated_assets: associatedAssetsObj,
          };
        }

        associatedAssetsObj = {};
        associatedAssetsObj["1_1"] = carreId;

        if (story.type === "image") {
          storyToSend = {
            hash: story.hash,
            name: story.name,
            keywords: story.keywords,
            associated_assets: associatedAssetsObj,
          };
        } else {
          storyToSend = {
            id: storyId,
            title: story.title,
            keywords: story.keywords,
            associated_assets: associatedAssetsObj,
          };
        }

        if ("title" in carreToSend) {
          // Si le champ title existe ça veut dire que c'est une vidéo
          await axiosInstance.post(
            `/${adaccountId}/AdVideos/update/${carreId}`,
            carreToSend
          );
        } else {
          // sinon c'est une image
          await axiosInstance.post(
            `/${adaccountId}/AdImages/update/${carreId}`,
            carreToSend
          );
        }

        if ("name" in storyToSend) {
          // Si le champ name existe ça veut dire que c'est une image
          await axiosInstance.post(
            `/${adaccountId}/AdImages/update/${storyId}`,
            storyToSend
          );
        } else {
          // sinon c'est une vidéo
          await axiosInstance.post(
            `/${adaccountId}/AdVideos/update/${storyId}`,
            storyToSend
          );
        }

        // Opération réussie
        setTimeout(() => {
          setLoader(false);

          dispatch({
            type: "SET_ALERT_GENERAL_ERROR_MESSAGE",
            payload: "Opération réussie !",
          });

          dispatch({ type: "SHOW_ALERT_GENERAL_ERROR_MESSAGE" });

          dispatch({ type: "CLEAR_ALL_APPAIRER_VISUELS" });

          // Get images and videos
          getImages(adaccountId);
          getVideos(adaccountId);
        }, 500);
      } catch (error) {
        dispatch({
          type: "SET_ALERT_GENERAL_ERROR_MESSAGE",
          payload:
            error.message ||
            "Il y a eu une erreur, veuillez réessayer plus tard !",
        });
        dispatch({ type: "SHOW_ALERT_GENERAL_ERROR_MESSAGE" });
        setLoader(false);
      }
    } else {
      // Afficher une erreur générale:
      dispatch({
        type: "SET_ALERT_GENERAL_ERROR_MESSAGE",
        payload: "Vous devez remplir les deux emplacements carré et story !",
      });
      dispatch({ type: "SHOW_ALERT_GENERAL_ERROR_MESSAGE" });
    }
  };

  const deleteCarre = () => {
    dispatch({ type: "DELETE_CARRE_APPAIRER_VISUELS" });
  };

  const deleteStory = () => {
    dispatch({ type: "DELETE_STORY_APPAIRER_VISUELS" });
  };

  useEffect(() => {
    getTags(dispatch, adaccountId);
    document.addEventListener("keydown", function (event) {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    });
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const onInputChangeNomPackage = (event, newValue) => {
    // console.log(newValue);
    dispatch({
      type: "CHANGE_NOM_PACKAGE_CREATION_PACKAGE_PUB",
      payload: newValue,
    });
  };

  function proceedAlertQuit(result, e){
    if (result) {
      dispatch({ type: "CLEAR_CREATION_PACKAGE_PUB" });
      let goTo = getSafeLastPage(lastPage, file_configuration_id);
      if (goTo === -1){
        //ensure we're not going to uploading videos or creating carousels
        goTo = "/campagne/liste";
      }
      dispatch(forgetLastPage());
      navigate(goTo);
    }
    setAltertQuit(false);
  }

  const handleBlurNomPackage = (e) => {
    const newValue = e.target.value;
    if (newValue) {
      if (valeurExiste(allTags, newValue) && !searchParams.get("keep-data")) {
        // Un select all assets
        dispatch({ type: "UNSELECT_ALL_ASSETS" });

        // Show warning message
        dispatch({
          type: "SET_ALERT_GENERAL_ERROR_MESSAGE",
          payload: "Vous allez modifier un package de publicités existant !",
        });
        dispatch({ type: "SHOW_ALERT_GENERAL_ERROR_MESSAGE" });

        dispatch({
          type: "CHANGE_NOM_PACKAGE_CREATION_PACKAGE_PUB",
          payload: newValue,
        });

        // Select all assets with this tag
        dispatch({
          type: "SELECT_ALL_ASSETS_WHICH_HAS_ENTERED_TAG_IN_KEYWORDS",
          payload: newValue,
        });

        // Show selected elements first
        setTimeout(() => {
          dispatch({ type: "SHOW_SELECTED_CTAS_FIRST" });
          dispatch({ type: "SHOW_SELECTED_DESCRIPTIONS_FIRST" });
          dispatch({ type: "SHOW_SELECTED_LIENS_FIRST" });
          dispatch({ type: "SHOW_SELECTED_UTMS_FIRST" });
          dispatch({ type: "SHOW_SELECTED_BODIES_FIRST" });
          dispatch({ type: "SHOW_SELECTED_TITLES_FIRST" });
          dispatch({ type: "SHOW_SELECTED_IMAGES_FIRST" });
          dispatch({ type: "SHOW_SELECTED_VIDEOS_FIRST" });
          getPackageCatalogIfExists(adaccountId, newValue);
        }, 500);
      }
    } else {
      dispatch({ type: "UNSELECT_ALL_ASSETS" });
    }
  };

  const handleEditPackage = (namPackage) => {
    const newValue = namPackage;
    if (newValue) {
      if (valeurExiste(allTags, newValue) && !searchParams.get("keep-data")) {
        // Un select all assets
        dispatch({ type: "UNSELECT_ALL_ASSETS" });

        // Show warning message
        // dispatch({
        //   type: "SET_ALERT_GENERAL_ERROR_MESSAGE",
        //   payload: "Vous allez modifier un package de publicités existant !",
        // });
        // dispatch({ type: "SHOW_ALERT_GENERAL_ERROR_MESSAGE" });

        dispatch({
          type: "CHANGE_NOM_PACKAGE_CREATION_PACKAGE_PUB",
          payload: newValue,
        });

        // Select all assets with this tag
        // dispatch({
        //   type: "SELECT_ALL_ASSETS_WHICH_HAS_ENTERED_TAG_IN_KEYWORDS",
        //   payload: newValue,
        // });

        // Show selected elements first
        setTimeout(() => {
          dispatch({ type: "SHOW_SELECTED_CTAS_FIRST" });
          dispatch({ type: "SHOW_SELECTED_DESCRIPTIONS_FIRST" });
          dispatch({ type: "SHOW_SELECTED_LIENS_FIRST" });
          dispatch({ type: "SHOW_SELECTED_UTMS_FIRST" });
          dispatch({ type: "SHOW_SELECTED_BODIES_FIRST" });
          dispatch({ type: "SHOW_SELECTED_TITLES_FIRST" });
          dispatch({ type: "SHOW_SELECTED_IMAGES_FIRST" });
          dispatch({ type: "SHOW_SELECTED_VIDEOS_FIRST" });
          getPackageCatalogIfExists(adaccountId, newValue);
        }, 1000);
      }
    }
  };

  const getImages = (adacc) => {
    setLoaderImages(true);

    axiosInstance
      .get(
        `/${adacc}/AdImages?fields=url&fields=keywords&fields=hash&fields=name&fields=id&fields=associated_assets`
      )
      .then((result) => {
        let images = result.data.filter((img) => !estObjetVide(img));

        if (tagToEdit) {
          images = images.map((image) => {
            if (image.hasOwnProperty("keywords")) {
              if (valeurExiste(image.keywords, tagToEdit)) {
                return {
                  ...image,
                  isSelected: true,
                };
              } else return { ...image, isSelected: false };
            } else return { ...image, isSelected: false };
          });
        }

        dispatch({
          type: "GET_IMAGES_CREATION_PACKAGE_PUBLICTE_SUCCESS",
          payload: images,
        });

        if (selectedImages.length > 0) {
          dispatch({
            type: "GET_SELECTED_IMAGES_AND_SHOW_THEM_IN_LIST_IMAGES",
            payload: images,
          });
        }

        dispatch({
          type: "GET_IMAGES_CAROUSEL_SECTION",
          payload: images,
        });

        const imagesTab = result.data
          .filter((img) => !estObjetVide(img))
          .map((element) =>
            selectedImages.includes(element.id)
              ? { ...element, isSelected: true }
              : { ...element, isSelected: false }
          );
        const selectedImagesTab = imagesTab.filter(
          (img) => img && img.isSelected
        );
        const notSelectedImagesTab = imagesTab.filter(
          (img) => img && !img.isSelected
        );

        dispatch({
          type: "GET_IMAGES_APPARIEMENT_VISUELS_SUCCESS",
          payload: [...selectedImagesTab, ...notSelectedImagesTab],
        });

        // dispatch({ type: "CLEAR_SELECTED_IMAGES" });

        setLoaderImages(false);
      })
      .catch((err) => {
        dispatch({ type: "GET_IMAGES_CREATION_PACKAGE_PUBLICTE_FAIL" });
        console.log(err);
        setLoaderImages(false);
      });
  };

  const getVideos = (adacc) => {
    setLoaderVideos(true);

    axiosInstance
      .get(
        `/${adacc}/AdVideos?fields=title&fields=permalink_url&fields=keywords&fields=id&fields=source&language=fr&fields=associated_assets`
      )
      .then((result) => {
        let videos = result.data.filter((vid) => !estObjetVide(vid));
        // console.log("videos", videos);
        if (tagToEdit) {
          videos = videos.map((image) => {
            if (image.hasOwnProperty("keywords")) {
              // console.log("keywords", image.keywords);
              if (valeurExiste(image.keywords, tagToEdit)) {
                // console.log("tagToEdit", tagToEdit);
                return {
                  ...image,
                  isSelected: true,
                };
              } else return { ...image, isSelected: false };
            } else return { ...image, isSelected: false };
          });
        }

        dispatch({
          type: "GET_VIDEOS_CREATION_PACKAGE_PUBLICTE_SUCCESS",
          payload: videos,
        });

        // console.log("selectedVideos", selectedVideos);
        if (selectedVideos.length > 0) {
          dispatch({
            type: "GET_SELECTED_VIDEOS_AND_SHOW_THEM_IN_LIST_VIDEOS",
            payload: videos,
          });
        }

        const videosTab = result.data
          .filter((vid) => !estObjetVide(vid))
          .map((element) =>
            selectedVideos.includes(element.id)
              ? { ...element, isSelected: true }
              : { ...element, isSelected: false }
          );
        const selectedVideosTab = videosTab.filter(
          (vid) => vid && vid.isSelected
        );
        const notSelectedVideosTab = videosTab.filter(
          (vid) => vid && !vid.isSelected
        );

        dispatch({
          type: "GET_VIDEOS_APPARIEMENT_VISUELS_SUCCESS",
          payload: [...selectedVideosTab, ...notSelectedVideosTab],
        });

        dispatch({
          type: "GET_VIDEOS_CAROUSEL_SECTION",
          payload: videos,
        });

        // dispatch({ type: "CLEAR_SELECTED_VIDEOS" });

        setLoaderVideos(false);
      })
      .catch((err) => {
        console.log(err);
        setLoaderVideos(false);
      });
  };

  const getTitles = (adacc) => {
    setLoaderTitles(true);

    axiosInstance
      .get(`/${adacc}/AdTitles?fields=keywords&fields=content&fields=id`)
      .then((result) => {
        let titles = result.data;

        if (tagToEdit) {
          titles = titles.map((image) => {
            if (image.hasOwnProperty("keywords")) {
              if (valeurExiste(image.keywords, tagToEdit)) {
                return {
                  ...image,
                  isSelected: true,
                };
              } else return { ...image, isSelected: false };
            } else return { ...image, isSelected: false };
          });
        }

        setTimeout(() => {
          dispatch({
            type: "GET_TITLES_CREATION_PACKAGE_PUBLICTE_SUCCESS",
            payload: titles,
          });

          setLoaderTitles(false);
        }, 500);
      })
      .catch((err) => {
        dispatch({ type: "GET_TITLES_CREATION_PACKAGE_PUBLICTE_FAIL" });
        console.log(err);
        setLoaderTitles(false);
      });
  };

  const getBodies = (adacc) => {
    setLoaderTextes(true);

    axiosInstance
      .get(`/${adacc}/AdBodies?fields=content&fields=keywords&fields=id`)
      .then((result) => {
        let bodies = result.data;

        if (tagToEdit) {
          bodies = bodies.map((image) => {
            if (image.hasOwnProperty("keywords")) {
              if (valeurExiste(image.keywords, tagToEdit)) {
                return {
                  ...image,
                  isSelected: true,
                };
              } else return { ...image, isSelected: false };
            } else return { ...image, isSelected: false };
          });
        }

        setTimeout(() => {
          dispatch({
            type: "GET_BODIES_CREATION_PACKAGE_PUBLICTE_SUCCESS",
            payload: bodies,
          });

          setLoaderTextes(false);
        }, 500);
      })
      .catch((err) => {
        dispatch({ type: "GET_BODIES_CREATION_PACKAGE_PUBLICTE_FAIL" });
        console.log(err);
        setLoaderTextes(false);
      });
  };

  const getDescriptions = (adacc) => {
    setLoaderDescriptions(true);

    axiosInstance
      .get(`/${adacc}/description`)
      .then((result) => {
        let descriptions = result.data;

        if (tagToEdit) {
          descriptions = descriptions.map((item) => {
            if (item.hasOwnProperty("keywords")) {
              if (valeurExiste(item.keywords, tagToEdit)) {
                return {
                  ...item,
                  isSelected: true,
                };
              } else return { ...item, isSelected: false };
            } else return { ...item, isSelected: false };
          });
        }

        setTimeout(() => {
          dispatch({
            type: "GET_DESCRIPTIONS_CREATION_PACKAGE_PUBLICTE_SUCCESS",
            payload: descriptions,
          });

          setLoaderDescriptions(false);
        }, 500);
      })
      .catch((err) => {
        dispatch({ type: "GET_DESCRIPTIONS_CREATION_PACKAGE_PUBLICTE_FAIL" });
        console.log(err);
        setLoaderDescriptions(false);
      });
  };

  const getLiens = (adacc) => {
    setLoaderLiens(true);

    axiosInstance
      .get(`/${adacc}/link`)
      .then((result) => {
        let liens = result.data;

        if (tagToEdit) {
          liens = liens.map((item) => {
            if (item.hasOwnProperty("keywords")) {
              if (valeurExiste(item.keywords, tagToEdit)) {
                return {
                  ...item,
                  isSelected: true,
                };
              } else return { ...item, isSelected: false };
            } else return { ...item, isSelected: false };
          });
        }

        setTimeout(() => {
          dispatch({
            type: "GET_LIENS_CREATION_PACKAGE_PUBLICTE_SUCCESS",
            payload: liens,
          });

          setLoaderLiens(false);
        }, 500);
      })
      .catch((err) => {
        dispatch({ type: "GET_LIENS_CREATION_PACKAGE_PUBLICTE_FAIL" });
        console.log(err);
        setLoaderLiens(false);
      });
  };

  const getUtms = (adacc) => {
    setLoaderUtms(true);

    axiosInstance
      .get(`/${adacc}/utm`)
      .then((result) => {
        let utms = result.data;

        if (tagToEdit) {
          utms = utms.map((item) => {
            if (item.hasOwnProperty("keywords")) {
              if (valeurExiste(item.keywords, tagToEdit)) {
                return {
                  ...item,
                  isSelected: true,
                };
              } else return { ...item, isSelected: false };
            } else return { ...item, isSelected: false };
          });
        }

        setTimeout(() => {
          dispatch({
            type: "GET_UTMS_CREATION_PACKAGE_PUBLICTE_SUCCESS",
            payload: utms,
          });

          setLoaderUtms(false);
        }, 500);
      })
      .catch((err) => {
        dispatch({ type: "GET_UTMS_CREATION_PACKAGE_PUBLICTE_FAIL" });
        console.log(err);
        setLoaderUtms(false);
      });
  };

  const getCtas = (adacc) => {
    setLoaderCtas(true);

    axiosInstance
      .get(`/${adacc}/call_to_action`)
      .then((result) => {
        let ctas = result.data;

        if (tagToEdit) {
          ctas = ctas.map((item) => {
            if (item.hasOwnProperty("keywords")) {
              if (valeurExiste(item.keywords, tagToEdit)) {
                return {
                  ...item,
                  isSelected: true,
                };
              } else return { ...item, isSelected: false };
            } else return { ...item, isSelected: false };
          });
        }

        setTimeout(() => {
          dispatch({
            type: "GET_CTAS_CREATION_PACKAGE_PUBLICTE_SUCCESS",
            payload: ctas,
          });

          setLoaderCtas(false);
        }, 500);
      })
      .catch((err) => {
        dispatch({ type: "GET_CTAS_CREATION_PACKAGE_PUBLICTE_FAIL" });
        console.log(err);
        setLoaderCtas(false);
      });
  };

  const getVariablesReference = () => {
    setLoaderVariablesReference(true);

    axiosInstance
      .get(`/facebook_variables_reference`)
      .then((res) => {
        setTimeout(() => {
          dispatch({
            type: "GET_FACEBOOK_VARIABLES_REFERENCE",
            payload: res.data,
          });

          setLoaderVariablesReference(false);
        }, 500);
      })
      .catch((err) => {
        console.log(err);
        setLoaderVariablesReference(false);
      });
  };

  const getCarousels = (adacc) => {
    setLoaderCarousels(true);

    axiosInstance
      .get(`/${adacc}/AdCarousels`)
      .then((res) => {
        dispatch({
          type: "GET_CAROUSELS_CREATION_PACKAGE_PUB",
          payload:
            nomPackage.length > 0
              ? res.data
                  .filter((item) => item.keywords.includes(nomPackage))
                  .map((item) => ({ ...item, isSelected: true }))
              : res.data.map((item) => ({ ...item, isSelected: false })),
        });

        setTimeout(() => {
          setLoaderCarousels(false);
        }, 500);
      })
      .catch((err) => {
        console.log(err);
        setLoaderCarousels(false);
      });
  };

  const getPosts = (adacc) => {
    setLoaderPosts(true);

    axiosInstance
      .get(`/${adacc}/SponsoredPosts`)
      .then((result) => {
        let posts = result.data;

        if (tagToEdit) {
          posts = posts.map((item) => {
            if (item.hasOwnProperty("keywords")) {
              if (valeurExiste(item.keywords, tagToEdit)) {
                return {
                  ...item,
                  isSelected: true,
                };
              } else return { ...item, isSelected: false };
            } else return { ...item, isSelected: false };
          });
        }

        dispatch({
          type: "GET_SPONSORED_POSTS_CREATION_PACKAGE_PUB",
          payload: posts,
        });

        setLoaderPosts(false);
      })
      .catch((err) => {
        console.log(err);
        setLoaderPosts(false);
      });
  };

  const getCatalogs = (adacc) => {
    setLoaderPosts(true);
    axiosInstance
      .get(`/${adacc}/catalogs?user_id=${user_id}`)
      .then((result) => {
        let catalogs = result.data;
        if (catalogs) {
          dispatch({
            type: "GET_CATALOGS_PACKAGE_PUB",
            payload: catalogs,
          });
        }
        setLoaderPosts(false);
      })
      .catch((err) => {
        console.log(err);
        setLoaderPosts(false);
      });
  };

  const token = process.env.REACT_APP_TOKEN_API;

  const getProductSets = (catalog_id) => {
    setLoaderPosts(true);

    axiosInstance
      .get(`/catalogs/${catalog_id}/product_sets?token=${token}`)
      .then((result) => {
        let productSets = result.data;

        if (productSets) {
          // console.log("productSets", productSets);
          dispatch({
            type: "GET_PRODUCT_SETS_PACKAGE_PUB",
            payload: productSets,
          });
        }
        setLoaderPosts(false);
      })
      .catch((err) => {
        console.log(err);
        setLoaderPosts(false);
      });
  };

  const getPackageCatalogIfExists = async (catalog_id, packageName) => {
    dispatch({ type: "CLEAR_CATALOG_FORMATS_PACKAGE_PUB" });
    await axiosInstance
      .get(`/${catalog_id}/CatalogCreative?keyword=${packageName}`)
      .then(({ data }) => {
        if (data) {
          setPubCatalogue(true);
          // console.log("data", data);
          dispatch({
            type: "SET_CATALOG_PACKAGE_PUB",
            payload: data.catalog_id,
          });
          dispatch({ type: "SET_CATALOG_PACKAGE_PUB_ID", payload: data.id });
          dispatch({
            type: "SET_PRODUCT_SET_PACKAGE_PUB",
            payload: data.product_set_id,
          });
          if (data.formats && data.formats.length > 0) {
            data.formats.map((format) =>
              dispatch({
                type: "SET_CATALOG_FORMATS_PACKAGE_PUB",
                payload: format,
              })
            );
          }
          setTimeout(() => {
            getProductSets(data.catalog_id);
          }, 500);
        } else {
          setPubCatalogue(false);
        }
      })
      .catch(() => {
        // toast.error("Une erreur s'est produite, veuillez réessayer plus tard");
        // setLoader(false);
      });
  };

  const handleChangeCatalog = (e) => {
    const catalog_id = e.target.value;
    dispatch({ type: "SET_CATALOG_PACKAGE_PUB", payload: catalog_id });
    setTimeout(() => {
      getProductSets(catalog_id);
    }, 500);
  };

  const handleCatalogFormats = (e) => {
    const catalogFormat = e.target.value;
    dispatch({
      type: "SET_CATALOG_FORMATS_PACKAGE_PUB",
      payload: catalogFormat,
    });
    // setTimeout(() => {
    //   getProductSets(catalog_id)
    // }, 500)
  };

  const handleChangeProductSets = (e) => {
    const product_set_id = e.target.value;
    dispatch({ type: "SET_PRODUCT_SET_PACKAGE_PUB", payload: product_set_id });
  };
  
  const {
    userData: { id: user_id },
  } = useSelector((state) => state.auth.user);

  useEffect(() => {
    getTags(dispatch, adaccountId);
    getImages(adaccountId);
    getVideos(adaccountId);
    getTitles(adaccountId);
    getBodies(adaccountId);
    getDescriptions(adaccountId);
    getLiens(adaccountId);
    getUtms(adaccountId);
    getCtas(adaccountId);
    getCarousels(adaccountId);
    getPosts(adaccountId);
    getCatalogs(adaccountId);
  }, [adaccountId]);

  useEffect(() => {
    getCatalogs(adaccountId);
  }, [pubCatalogue]);

  useEffect(() => {
    tagToEdit && handleEditPackage(tagToEdit);
  }, []);

  return (
    <div className="creation_package_pub_screen">
      <LeftMenu />
      <ul className="ul_parent">
        <li></li>
        <li></li>
        <li></li>
        <li>
          <ul>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </li>
      </ul>
      <RightContainer>
        {alertQuit && (
          <BaseAlertMessage
           message={[
              intl.formatMessage({
                defaultMessage: "Souhaitez-vous quitter la création du package créas ?",
                id: "alert_message_package_pub_back_1",
              }),
              intl.formatMessage({
                defaultMessage: "Vos modifications seront perdues",
                id: "alert_message_package_pub_back_2",
              })
            ]
           }
           callback={proceedAlertQuit}
          />
        )}
        <header className="right_content_header">
          <div className="back_title">
            {toggleLeftMenu ? (
              <i
                onClick={() => setToggleLeftMenu((prevState) => !prevState)}
                style={{ marginRight: 30, cursor: "pointer" }}
                className="fas fa-bars fa-2x"
              ></i>
            ) : null}
            <div
              className="back"
              onClick={() => {
                setAltertQuit(true);
              }}
            >
              <img src="/left-arrow 2.png" alt="Retour" />
            </div>
            <h1 className="right_content_header_title">
              {" "}
              <FormattedMessage
                defaultMessage="Créer un package de publicité"
                id="creation_package_pub_main_title"
              />
            </h1>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="client">
              <TopRightMenu />
            </div>
          </div>
        </header>
        {loader && (
          <Loader
            message={intl.formatMessage({
              defaultMessage: "Opération en cours...",
              id: "loader_message_10",
            })}
          />
        )}
        <div className="right_content_main">
          <div className="contenu_principale">
            <div className="wrapper_compte_cat_pub">
              {/*<div className="choix_compte_publicitaire">
                <p>
                  <FormattedMessage
                    defaultMessage="Sélectionner un compte"
                    id="screen_priorisation_events_3"
                  />
                </p>
                {loaderConnectedClients ? (
                  <Loader3 />
                ) : (
                  <select value={adaccountId} onChange={selectAccount}>
                    {connectedAccounts.map((ac) => (
                      <option
                        data-object={JSON.stringify(ac)}
                        value={ac.account}
                      >
                        {ac.name}
                      </option>
                    ))}
                  </select>
                )}
                    </div>*/}
              <div className="cata_pub">
                <p>Publicités catalogue</p>
                <Switch
                  onChange={() => setPubCatalogue((prevState) => !prevState)}
                  checked={pubCatalogue}
                />
              </div>
            </div>
            <div className="row row_1">
              <div
                className="left"
                style={{ width: !toggleLeftMenu ? "80%" : "95%" }}
              >
                <div className="title_inputs">
                  <h2 className="title">
                    <FormattedMessage
                      defaultMessage="Nom de package "
                      id="creation_package_pub_title"
                    />
                  </h2>
                  {loader2 ? (
                    <Loader3 />
                  ) : (
                    <Autocomplete
                      onBlur={handleBlurNomPackage}
                      freeSolo
                      value={nomPackage}
                      // onChange={(event, newValue) => {
                      //   console.log(newValue);
                      // }}
                      onInputChange={onInputChangeNomPackage}
                      id="checkboxes-tags-demo"
                      options={allTags}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option}
                        </li>
                      )}
                      style={{
                        width: 400,
                        borderBottom: emptyNameError && "1px solid red",
                      }}
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          label={intl.formatMessage({
                            id: "creation_package_pub_name_package_placeholder",
                            defaultMessage: "A renseigner",
                          })}
                        />
                      )}
                    />
                  )}
                  {loader2 ? (
                    <Loader3 />
                  ) : (
                    <Autocomplete
                      multiple
                      onChange={(event, newValue) => {
                        dispatch({
                          type: "FILTER_ALL_ASSETS_BY_KEYWORDS",
                          payload: newValue,
                        });
                      }}
                      id="checkboxes-tags-demo"
                      options={allTags}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option}
                        </li>
                      )}
                      style={{ width: 400 }}
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          label={intl.formatMessage({
                            id: "creation_package_pub_filter_tags",
                            defaultMessage: "Filtrer par tags",
                          })}
                        />
                      )}
                    />
                  )}
                </div>

                {pubCatalogue && (
                  <React.Fragment>
                    <div className="catalog_product">
                      <div className="input_catalog">
                        <label htmlFor="" style={{ marginBottom: 0 }}>
                          <FormattedMessage
                            defaultMessage="Catalogue"
                            id="create_packege_pub_catalogue"
                          />
                        </label>
                        <FormControl fullWidth>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            fullWidth
                            value={catalog}
                            onChange={handleChangeCatalog}
                            variant="standard"
                            name="catalog"
                            placeholder=""
                          >
                            {catalogs.map((item) => (
                              <MenuItem key={item.id} value={item.id}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>

                      <div className="input_product">
                        <label htmlFor="" style={{ marginBottom: 0 }}>
                          <FormattedMessage
                            defaultMessage="Ensemble produits"
                            id="create_packege_pub_ensemble_produits"
                          />
                        </label>
                        <FormControl fullWidth>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            fullWidth
                            value={product_set}
                            onChange={handleChangeProductSets}
                            variant="standard"
                            name="product_set"
                            disabled={!catalog}
                            placeholder=""
                          >
                            {product_sets.map((product) => (
                              <MenuItem value={product.id}>
                                {product.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>

                    <div className="checkbox_container">
                      <label
                        htmlFor=""
                        className="text_label"
                        style={{ marginBottom: 0 }}
                      >
                        <FormattedMessage
                          defaultMessage="Format"
                          id="create_packege_pub_format"
                        />
                      </label>
                      <div className="checkbox_content">
                        <div className="checkbox_left">
                          {formattedCatalogFormats.length ? formattedCatalogFormats.slice(0, 3).map((format) => (
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        onChange={handleCatalogFormats}
                                        checked={catalogformats.includes(
                                          format.value
                                        )}
                                        value={format.value}
                                        style={{
                                          color: "#E83E8C",
                                        }}
                                      />
                                    }
                                    label={format.label}
                                  />
                                </FormGroup>
                              ))
                            : ""}
                        </div>
                        <div className="checkbox_right">
                          {formattedCatalogFormats.length
                            ? formattedCatalogFormats.slice(3, 5).map((format) => (
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        onChange={handleCatalogFormats}
                                        checked={catalogformats.includes(
                                          format.value
                                        )}
                                        value={format.value}
                                        style={{
                                          color: "#E83E8C",
                                        }}
                                      />
                                    }
                                    label={format.label}
                                  />
                                </FormGroup>
                              ))
                            : ""}
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )}

                <div
                  className="sections"
                  style={{ width: toggleLeftMenu ? "100%" : "100%" }}
                >
                  {!pubCatalogue && (
                    <React.Fragment>
                      <div
                        className={`${emptyImageError && "blockError"}`}
                        style={{ margin: "30px 0" }}
                      >
                        <Image
                          getImages={getImages}
                          loader={loaderImages}
                          compagnId={file_configuration_id}
                          toggleLeftMenu={toggleLeftMenu}
                        />
                      </div>
                      <div className={`${emptyVideoError && "blockError"}`}>
                        <Video
                          getVideos={getVideos}
                          loader={loaderVideos}
                          compagnId={file_configuration_id}
                          toggleLeftMenu={toggleLeftMenu}
                        />
                      </div>
                    </React.Fragment>
                  )}
                  <Titre getTitles={getTitles} loaderTitles={loaderTitles} />
                  <Texte getBodies={getBodies} loaderBodies={loaderTextes} />
                  <Description
                    getDescriptions={getDescriptions}
                    loaderDescriptions={loaderDescriptions}
                  />
                  <Lien getLiens={getLiens} loaderLiens={loaderLiens} />
                  <UTM getUtms={getUtms} loaderUtms={loaderUtms} />
                  <div
                    className={`${emptyCtasError && "blockError"}`}
                    style={{ margin: "30px 0" }}
                  >
                    <CallToAction
                      getCtas={getCtas}
                      getVariablesReference={getVariablesReference}
                      loaderCtas={loaderCtas}
                      loaderVariablesReference={loaderVariablesReference}
                    />
                  </div>
                  {!pubCatalogue && (
                    <React.Fragment>
                      <div
                        className={`${emptyCorousetError && "blockError"}`}
                        style={{ margin: "30px 0" }}
                      >
                        <Carousels
                          getCarousels={getCarousels}
                          loaderCarousels={loaderCarousels}
                          nomPackage={nomPackage}
                          compagnId={file_configuration_id}
                        />
                      </div>
                      <div className={`${emptyPasError && "blockError"}`}>
                        <PostsASponsoriser
                          getPosts={getPosts}
                          loaderPosts={loaderPosts}
                          nomPackage={nomPackage}
                          compagnId={file_configuration_id}
                        />
                      </div>
                      <PublicitesExistantes
                        nomPackage={nomPackage}
                        compagnId={file_configuration_id}
                      />
                    </React.Fragment>
                  )}
                </div>
              </div>
              {!pubCatalogue && (
                <div className="right" style={{ position: "relative" }}>
                  <h2
                    className="title"
                    style={{
                      position: "sticky",
                      top: 100,
                      left: 0,
                      zIndex: 999,
                    }}
                  >
                    <FormattedMessage
                      defaultMessage="Aperçu"
                      id="creation_package_pub_aperçu"
                    />
                  </h2>
                  <div
                    className="apercu"
                    style={{
                      position: "sticky",
                      top: 150,
                      left: 0,
                      zIndex: 999,
                    }}
                  >
                    <Apercu />
                  </div>
                </div>
              )}
            </div>
          </div>

          <div
            style={
              showSectionAppairerVisuels
                ? { display: "block", width: !toggleLeftMenu ? "80%" : "95%" }
                : { display: "none" }
            }
            className="section_appairer_des_visuels"
          >
            <div className="boxed">
              <header className="main__header">
                <p>
                  <FormattedMessage
                    defaultMessage="Appairer des visuels"
                    id="section_appairer_des_visuels_title"
                  />
                </p>
                <i
                  style={{ cursor: "pointer" }}
                  className="fas fa-times"
                  onClick={() => {
                    dispatch({ type: "HIDE_SECTION_APPAIRER_VISUELS" });
                    dispatch({ type: "CLEAR_ALL_APPAIRER_VISUELS" });
                  }}
                ></i>
              </header>
              <div className="main__content">
                <div className="main__content_left">
                  <ImageApparairerVisuels hideTags hideAddButton />
                  <VideoApparairerVisuels hideTags hideAddButton />
                </div>
                <div className="main__content_right">
                  <div className="carre">
                    <h4 className="title">
                      <FormattedMessage
                        defaultMessage="Format/Carré"
                        id="section_appairer_des_visuels_title_carre"
                      />
                    </h4>
                    <div className="element">
                      {carre && carre.url ? (
                        <div style={{ position: "relative" }}>
                          <img
                            style={{
                              height: "100%",
                              width: "100%",
                              objectFit: "cover",
                            }}
                            src={carre.url}
                            alt=""
                          />
                          <i
                            style={{
                              position: "absolute",
                              right: 10,
                              top: 10,
                              fontSize: 30,
                              cursor: "pointer",
                            }}
                            className="fas fa-times"
                            onClick={deleteCarre}
                          ></i>
                        </div>
                      ) : carre && carre.source ? (
                        <div style={{ position: "relative" }}>
                          <video
                            style={{
                              width: "100%",
                            }}
                            controls
                            src={carre.source}
                          ></video>
                          <i
                            style={{
                              position: "absolute",
                              right: 10,
                              top: 10,
                              fontSize: 30,
                              cursor: "pointer",
                            }}
                            className="fas fa-times"
                            onClick={deleteCarre}
                          ></i>
                        </div>
                      ) : (
                        <div className="center">
                          <img
                            className="default"
                            src="/drag_asset.png"
                            alt=""
                          />
                          <h5>Asset 1:1</h5>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="story">
                    <h4 className="title">
                      <FormattedMessage
                        defaultMessage="Format/Story"
                        id="section_appairer_des_visuels_title_story"
                      />
                    </h4>
                    <div className="element element_story">
                      {story && story.url ? (
                        <div style={{ position: "relative", height: "100%" }}>
                          <img
                            style={{
                              height: "100%",
                              width: "100%",
                              objectFit: "cover",
                            }}
                            src={story.url}
                            alt=""
                          />
                          <i
                            style={{
                              position: "absolute",
                              right: 10,
                              top: 10,
                              fontSize: 30,
                              cursor: "pointer",
                            }}
                            className="fas fa-times"
                            onClick={deleteStory}
                          ></i>
                        </div>
                      ) : story && story.source ? (
                        <div style={{ position: "relative", height: "100%" }}>
                          <video
                            style={{
                              width: "100%",
                            }}
                            controls
                            src={story.source}
                          ></video>
                          <i
                            style={{
                              position: "absolute",
                              right: 10,
                              top: 10,
                              fontSize: 30,
                              cursor: "pointer",
                            }}
                            className="fas fa-times"
                            onClick={deleteStory}
                          ></i>
                        </div>
                      ) : (
                        <div className="center">
                          <img
                            className="default"
                            src="/drag_asset.png"
                            alt=""
                          />
                          <h5>Asset 9:16</h5>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="button_container">
                    <button
                      disabled={carre === null || story === null}
                      onClick={apparairerDesVisuels}
                      className={`${
                        carre !== null && story !== null ? "active" : ""
                      }`}
                    >
                      <FormattedMessage
                        defaultMessage="Valider"
                        id="screen_package_pub_screen_valider_btn_title"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-sb buttons_appairer_save">
            {!pubCatalogue ? (
              <button
                className="outline"
                style={{ marginRight: 10 }}
                onClick={afficherSectionApparairerVisuels}
              >
                <FormattedMessage
                  defaultMessage="Apparairer des visuels"
                  id="creation_package_pub_appairer_visuels"
                />
              </button>
            ) : (
              <div></div>
            )}
            <button className="standard" onClick={savePackagePub}>
              <FormattedMessage
                defaultMessage="Enregistrer"
                id="screen_package_pub_screen_save_button_title"
              />
            </button>
          </div>
        </div>
      </RightContainer>
    </div>
  );
};

export default CreationPackagePubScreen;

// <div style={{ marginRight: 20 }}>
//     <img src='/meta.png' alt='Meta' />
// </div>
