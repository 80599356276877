import React, {useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation} from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { FormattedMessage, useIntl } from "react-intl";
import RenderGeoDemoOption from "../../../components/RenderGeoDemoOption/RenderGeoDemoOption";
import GenericSection from "./genericSection"
import { saveCampaignNextPage } from "../../../store/actions/utils/campaignCreation";

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

const PackageGeodemographique = ({
  disabledMode
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [toggle, setToggle] = React.useState(false);
  const targeting_packages = useSelector(
    state => state.creationCampagne.targeting_packages
  );
  const geoDemoPackages = useSelector(
    state => state.creationCampagneRef.geoDemoPackages)

  // eslint-disable-next-line
  const handleChange = (event, newValue) => {
    // const {
    //   target: { value },
    // } = event;

    dispatch({
      type: "SET_PACKAGE_GEO_DEMO",
      payload: newValue,
    });
  };

  const intl = useIntl();

  const updateDictElements = (refDict, currentDict) => {
    const updatedDict = [];
    currentDict.forEach(item=>{
      const itemFound = refDict.find(refItem=>refItem.id===item.id);
      if(itemFound){
        updatedDict.push(itemFound);
      }
      else {
        updatedDict.push(item);
      }  
    })
    return updatedDict;
  }
  
  useEffect(()=>{
    if(geoDemoPackages.length){
      dispatch({
        type: "SET_PACKAGE_GEO_DEMO_WITH_PROTECTION",
        payload: updateDictElements(geoDemoPackages, targeting_packages),
      });
    }
  }, [geoDemoPackages])


  return (
    <GenericSection
      disabledMode={disabledMode}
      toggle={toggle}
      setToggle={setToggle}
      title={
        <FormattedMessage
          defaultMessage='Package Géo-démographique'
          id='create_campaign_package_geo_demo_title'
        />
      }
      test_validation={targeting_packages.length > 0}
      content = {
        <form action=''>
        <div className='row' style={{ marginBottom: 0 }}>
          <div className='input_group' style={{ width: "100%" }}>
            <div
              className='input_group centre_interet'
              style={{ marginTop: 10 }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <i
                  style={{ marginRight: 5, color: "#E83E8C" }}
                  className='fas fa-search'
                ></i>
                <Autocomplete
                  noOptionsText='Aucun résultat'
                  multiple
                  id='checkboxes-tags-demo'
                  onChange={handleChange}
                  //onBlur={() => updateSingleCompaign()}
                  value={targeting_packages}
                  options={geoDemoPackages}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  disableCloseOnSelect
                  renderTags={options =>
                    options.map(option => (
                      <RenderGeoDemoOption
                        option={option}
                      />
                    ))
                  }
                  getOptionLabel={option => option.name}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  )}
                  style={{ width: "100%" }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label={intl.formatMessage({
                        id: "creation_campagne_package_geo_demo_search_package",
                        defaultMessage:
                          "Chercher un package géo démographique",
                      })}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='add' style={{ marginTop: 0 }}>
          <div className='fe'>
            <button
              onClick={() =>{
                saveCampaignNextPage(dispatch, location)
                navigate(`/creation-package-ciblage-demographique`);
                }}
            >
              <i className='fas fa-plus'></i>
              <FormattedMessage
                defaultMessage='Ajouter'
                id='creation_campagne_package_add_btn'
              />
            </button>
          </div>
        </div>
      </form>
      }
    />
  );
};

export default PackageGeodemographique;
