import {
  CHECK_IF_CLIENT_EXISTS_IN_DB_FAIL,
  CHECK_IF_CLIENT_EXISTS_IN_DB_SUCCESS,
  GET_CLIENTS,
  SET_LOADER_CLIENTS,
  SUBMIT_CLIENT_DATA_FAIL,
  SUBMIT_CLIENT_DATA_LOADER,
  SUBMIT_CLIENT_DATA_SUCCESS,
} from "../types";

const initialState = {
  clients: [],
  clientExists: false,
  error: "",
  loaderClients: false,
  loaderInitiliazeData: false,
  errorInitliazeData: null,
  successInitliazeData: false,
  successMsgInitliazeData: "",
};

export const clientsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CLIENTS:
      return {
        ...state,
        clients: action.payload,
      };
    case SUBMIT_CLIENT_DATA_LOADER:
      return {
        ...state,
        loaderInitiliazeData: true,
      };
    case SUBMIT_CLIENT_DATA_SUCCESS:
      return {
        ...state,
        loaderInitiliazeData: false,
        successInitliazeData: true,
      };
    case SUBMIT_CLIENT_DATA_FAIL:
      return {
        ...state,
        loaderInitiliazeData: false,
        errorInitliazeData: action.payload,
      };
    case CHECK_IF_CLIENT_EXISTS_IN_DB_SUCCESS:
      return {
        ...state,
        clientExists: action.payload,
        loaderClients: false,
      };
    case CHECK_IF_CLIENT_EXISTS_IN_DB_FAIL:
      return {
        ...state,
        error: action.payload,
        loaderClients: false,
      };
    case SET_LOADER_CLIENTS:
      return {
        ...state,
        loaderClients: true,
      };
    default:
      return state;
  }
};
