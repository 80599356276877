import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LeftMenu from "../../../components/LeftMenu/LeftMenu";
import Loader3 from "../../../components/Loader3/Loader3";
import TopRightMenu from "../../../components/TopRightMenu/TopRightMenu";
import axiosInstance from "../../../store/axios.instance";
import "./style.css";
import RightContainer from "../../../components/RightContainer/RightContainer";

const ComptesActifsTiktok = () => {
  const [loader, setLoader] = useState(true);
  const dispatch = useDispatch();
  const { toggleLeftMenu } = useSelector((state) => state.utils);

  const setToggleLeftMenu = () => {
    dispatch({ type: "SET_TOGGLE_LEFT_MENU" });
  };

  // const { connectedAccounts } = useSelector(state => state.auth.user);

  const navigate = useNavigate();

  const {
    userData: { id: user_id },
  } = useSelector((state) => state.auth.user);

  const nextStep = () => {
    navigate("/campagne/liste");
  };

  const selectAccount = (account) => {
    dispatch({
      type: "SET_ADACCOUNT_CHOOSEN_AUTH",
      payload: account.id,
    });
    localStorage.setItem("adaccountId", account.id);
    localStorage.setItem("adaccountName", account.name);

    dispatch({
      type: "SAVE_NAME_COMPTE_PRINCIPAL",
      payload: account.name,
    });

    navigate("/campagne/liste");
  };

  const navigateToPriorisationEvents = () => {
    navigate("/priorisation-evenements");
  };

  return (
    <div className="ecran_campagne_comptes_actifs">
      <LeftMenu />
      <RightContainer>
        <header className="right_content_header">
          <div className="back_title">
            {toggleLeftMenu ? (
              <i
                onClick={() => setToggleLeftMenu((prevState) => !prevState)}
                style={{ marginRight: 30, cursor: "pointer" }}
                className="fas fa-bars fa-2x"
              ></i>
            ) : null}
            <h1 className="right_content_header_title">
              <FormattedMessage
                defaultMessage="Paramètres"
                id="screen_comptes_actifs_1"
              />
            </h1>
          </div>
          <div style={{ display: "flex" }}>
            <TopRightMenu />
          </div>
        </header>
        <div className="right_content_main">
          <div className="steps">
            <div className="step active">
              <p>
                <FormattedMessage
                  defaultMessage="Paramètres"
                  id="add-account-title"
                />
              </p>
            </div>
            <img src="/fleche-step.png" alt="Next Step" />
            <div className="step">
              <p>
                <FormattedMessage
                  defaultMessage="Ajout d'un compte"
                  id="add-account-step-add-account"
                />
              </p>
            </div>
            <img src="/fleche-step.png" alt="Next Step" />
            <div className="step">
              <p>
                <FormattedMessage
                  defaultMessage="Choisir un compte"
                  id="add-account-choose-account"
                />
              </p>
            </div>
            <img src="/fleche-step.png" alt="Next Step" />
            <div className="step">
              <p>
                <FormattedMessage
                  defaultMessage="Configuration"
                  id="add-account-configuration"
                />
              </p>
            </div>
          </div>
          <div className="configurer_strategie">
            <div className="configurer_strategie_content">
              <div className="evenements_a_optimiser">
                <div className="evenements_a_optimiser_title_addbtn">
                  <h2 className="evenements_a_optimiser_title">
                    <FormattedMessage
                      defaultMessage="Compte Actif"
                      id="screen_comptes_actifs_2"
                    />
                  </h2>
                  <div className="row">
                    <button
                      onClick={() =>
                        navigate("/campagne/ajouter-compte?reconnect=true")
                      }
                    >
                      <FormattedMessage
                        defaultMessage="Reconnecter Meta"
                        id="reconnect"
                      />
                    </button>
                    <button onClick={() => navigate("/choisir-page")}>
                      +{" "}
                      <FormattedMessage
                        defaultMessage="Ajouter Page"
                        id="add_page_Ct97u"
                      />
                    </button>
                    <button
                      onClick={() => navigate("/campagne/choisir-compte")}
                    >
                      +{" "}
                      <FormattedMessage
                        defaultMessage="Ajouter Compte"
                        id="add_account_fR3s2"
                      />
                    </button>
                  </div>
                </div>
                <div className="evenements_a_optimiser_content">
                  <div className="events">
                    <div className="details">
                      <table>
                        <thead>
                          <tr>
                            <th>
                              <FormattedMessage
                                defaultMessage="Nom de compte"
                                id="screen_comptes_actifs_3"
                              />
                            </th>
                            <th></th>
                            {/* <th>Date de création</th> */}
                            <th>
                              <FormattedMessage
                                defaultMessage="Opération"
                                id="screen_comptes_actifs_4"
                              />
                            </th>
                          </tr>
                        </thead>
                        {loader ? (
                          <tr>
                            <td colSpan={3}>
                              <div
                                style={{
                                  flex: 1,
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Loader3 />
                              </div>
                            </td>
                          </tr>
                        ) : (
                          <tbody>
                            <tr>Compte actifs tiktok</tr>
                          </tbody>
                        )}
                      </table>
                    </div>
                  </div>
                </div>
                <div className="dfe dfe_2">
                  <button
                    style={{ marginRight: 20 }}
                    onClick={navigateToPriorisationEvents}
                  >
                    Prioriser des évènements
                  </button>
                  <button onClick={nextStep}>
                    <FormattedMessage defaultMessage="Suivant" id="next" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </RightContainer>
    </div>
  );
};

export default ComptesActifsTiktok;
