import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../store/axios.instance";
import { toast } from "react-toastify";
import { Button, Menu, MenuItem } from "@mui/material";
import { FormattedMessage } from "react-intl";
import CampaignElements from "../../pages/Campagne/CampagneElements/CampagneElements";

const SingleCampaignConfiguration = ({
  campaign,
  baseNavigateToEditCampaign,
  getInsights,
  getSingleCampaignConfigurations,
  navigateToEditPubScreen
}) => {
  const { adaccountId } = useSelector((state) => state.auth.user);
  const { openModel } = useSelector((state) => state.creationCampagne);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const handleClickButton = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { events } = useSelector((state) => state.insights);

  const selectedEvents = events.filter((event) => event && event.isChecked);

  const deleteFileCampaignConfiguration = () => {
    if (
      window.confirm(
        "Voulez-vous vraiment supprimer ce fichier de configuration ?"
      )
    ) {
      axiosInstance
        .delete(`/${adaccountId}/single_campaign_configuration/${campaign.id}`)
        .then(() => {
          toast.success("Fichier de configuration supprimé");
          getInsights(adaccountId);
          getSingleCampaignConfigurations(adaccountId);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <React.Fragment>
      {openModel && <CampaignElements campaign={campaign} />}
      <tr
        className={`brouillon ${
          campaign.hasOwnProperty("draft")
            ? campaign.draft && campaign.campaign_id
              ? "troisieme_cas"
              : ""
            : ""
        }`}
      >
        <td
          className="first_column"
          style={{
            border: "none",
            borderBottom: "0.5px solid rgba(0, 0, 0, 0.13)",
          }}
        ></td>
        <td className="first second_column" style={{ maxWidth: "600px" }}>
          <p>{campaign.name}</p>
          <div className="actions">
            <img
              src="/icons/edit.png"
              alt="edit"
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClickButton}
            />
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={()=>baseNavigateToEditCampaign(campaign.id)}>
                <FormattedMessage
                  defaultMessage="Modifier campagne"
                  id="screen-liste-campagne-edit"
                />
              </MenuItem>
              <MenuItem onClick={() => {
                navigateToEditPubScreen(campaign.id, campaign.facebook_campaign_id)
              } 
                }>
                <FormattedMessage
                  defaultMessage="Mettre à jour les publicités"
                  id="screen-liste-campagne-edit-pub"
                />
              </MenuItem>
            </Menu>
            {/* <img
              src='/icons/view.png'
              alt='view'
              onClick={() => navigateToViewCampaignDetailsScreen(campaign.id)}
            />
            <img src='/icons/duplicate.png' alt='duplicate' />
            <img src='/icons/line-chart.png' alt='line-chart' /> */}
            {/*<i
              onClick={openModelCampaignElement}
              className='fas fa-trash-alt'
            ></i>*/}
            <i
              style={{
                fontSize: "16px",
                cursor: "pointer",
                marginLeft: "3px",
                color: "#888",
              }}
              onClick={deleteFileCampaignConfiguration}
              className="fas fa-trash-alt"
            ></i>
          </div>
        </td>
        <td>
          {/* <span className='status_not_ok'></span> */}
          <p>Brouillon</p>
        </td>
        {/* <td>-</td> */}
        {/* <td>-</td> */}
        <td>-</td>
        <td>-</td>
        {selectedEvents &&
          selectedEvents.length > 0 &&
          selectedEvents.map((event) => <td>-</td>)}
        <td className="column_actions"></td>
      </tr>
    </React.Fragment>
  );
};

export default SingleCampaignConfiguration;
