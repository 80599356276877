import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { estObjetVide } from "../../utils";
import AssociatedAssetsCmp from "../AssociatedAssetsCmp/AssociatedAssetsCmp";

const VideoItem = ({ video, showTags }) => {
  const dispatch = useDispatch();

  const { visuels, contenuParDefaut } = useSelector(state => state.carousel);

  const selectVideo = id => {
    if (visuels.length < 10) {
      if (!video.isSelected) {
        dispatch({ type: "ADD_VISUEL_CAROUSEL", payload: video, "contenuParDefaut": contenuParDefaut });
      } else {
        dispatch({ type: "DELETE_VISUEL_CAROUSEL", payload: id });
      }

      dispatch({
        type: "TOGGLE_SELECTED_VIDEO_CAROUSEL",
        payload: id,
      });

      dispatch({ type: "SHOW_SELECTED_VIDEOS_FIRST_CAROUSEL" });
    } else {
      dispatch({
        type: "SET_ALERT_GENERAL_ERROR_MESSAGE",
        payload: "Vous avez atteint le nombre max de visuels permis !",
      });
      dispatch({ type: "SHOW_ALERT_GENERAL_ERROR_MESSAGE" });
    }
  };

  return (
    <div
      onClick={() => selectVideo(video.id)}
      className={`video_item ${video.isSelected && "selected"}`}
      style={showTags ? { width: "48%" } : {}}
    >
      <div className='left' style={!showTags ? { width: "100%" } : {}}>
        <div className='image_title'>
          <h4 className='title'>
            {video.title.length < 25
              ? video.title
              : `${video.title.slice(0, 25)}...`}
          </h4>
          {video.associated_assets && !estObjetVide(video.associated_assets) ? (
            <AssociatedAssetsCmp />
          ) : null}
        </div>
        <div className='video'>
          <video controls src={video.source}></video>
        </div>
      </div>
    </div>
  );
};

export default VideoItem;
