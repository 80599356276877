import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import "./style.css";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { saveCurrentPage } from "../../store/actions/utils/usualDispatches";

const RenderGeoDemoOption = ({ option }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const deleteGeoDemo = () => {
    dispatch({
      type: "DELETE_GEODEMO_PACKAGE_CREATION_CAMP",
      payload: option.id,
    });
  };

  return (
    <div className='render_geodemo_option'>
      <p>{option.name}</p>
      <span
        className='circle'
        onClick={() => {
          dispatch(saveCurrentPage(location));
          navigate(`/edit-package-ciblage-demographique/edit/${option._id}`);
          dispatch({ type: "SET_TAGS_TO_EDIT", payload: option });
        }}
      >
        <EditIcon fontSize='30' />
      </span>
      <span className='circle' onClick={deleteGeoDemo}>
        <CloseIcon fontSize='30' />
      </span>
    </div>
  );
};
export default RenderGeoDemoOption;
